import React, { Component } from "react";
import Swal from "sweetalert2";
import { AsyncPaginate } from "react-select-async-paginate";
import ImportTemplate from "./../ImportTemplate/ImportTemplate";

import {
  API_TEMPLATE_LIST,
  API_VDP,
  API_UPLOAD_CSV,
  API_USERS,
} from "../../config/Api";
import "./VDP.css";
import VDPMergeList from "./VDPMergeList";
const samplevdp =
  process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
  "media/sample/sample_vdp_csv.csv";

class VDP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      csv_path: "",
      template_id: "",
      marge_process_type: "",
      marge_process: [
        { marge_id: 1, marge_process_type: "Production Merge" },
        { marge_id: 2, marge_process_type: "Proof Merge" },
        { marge_id: 3, marge_process_type: "Partial Proof " },
      ],
      upload_btn_disabled: true,
      csvData: null, // SampleData
      user: "",
      userList: [],
    };
    this.upload_csv = this.upload_csv.bind(this);
    this.onChangeHandeller = this.onChangeHandeller.bind(this);
    this.validationAction = this.validationAction.bind(this);
    this.resetAction = this.resetAction.bind(this);
  }

  validationAction = () => {
    if (
      "template_id" in this.state &&
      this.state.template_id !== "" &&
      "marge_process_type" in this.state &&
      this.state.marge_process_type !== ""
    ) {
      this.setState({ upload_btn_disabled: false });
    }
  };

  onChangeHandeller = (event) => {
    this.setState(
      { [event.target.name]: event.target.value },
      this.validationAction
    );
  };

  componentDidMount() {
    this.fetchUsers();
  }

  loadOptions = async (search, prevOptions, { page }) => {
    const row_per_page = 10;
    const is_master = 2;
    const url =
      API_TEMPLATE_LIST +
      "?page_number=" +
      page +
      "&template_name=" +
      search +
      "&is_master=" +
      is_master;
    const res = await this.props.callRequest("GET", `${url}`, true);
    const data = await res.data.data;
    const total_records = await res.data.totla_records;
    const page_size = page * row_per_page;
    const hasMore = page_size < total_records;

    const templates = data.map((obj) => {
      obj.label = obj.template_name;
      obj.value = obj.template_id;
      delete obj.template_name;
      delete obj.template_id;
      return obj;
    });

    return {
      options: templates,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  onChangeTemplate = (e) => {
    this.setState({ template_id: e.value });
  };

  SubmitVdp = (event) => {
    event.preventDefault();
    if (this.state.template_id === "" || this.state.marge_process_type === "") {
      if (this.state.template_id === "") {
        this.setState({ err_template: "Please select template." });
      } else {
        this.setState({ err_template: "" });
      }

      if (this.state.marge_process_type === "") {
        this.setState({ err_marge_process: "Please select Marge Process." });
      } else {
        this.setState({ err_marge_process: "" });
      }
    } else {
      this.setState({ err_marge_process: "" });
      this.setState({ err_template: "" });
      this.props
        .callRequest("POST", API_VDP, true, {
          template_id: this.state.template_id,
          marge_process_type: this.state.marge_process_type,
          csv_path: this.state.csv_path,
        })
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title:
                "Vdp merge process has been started. System will be send email after processing.",
            });

            this.setState({
              csv_path: "",
              template_id: "",
              marge_process_type: "",
            });
          }
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Something Wrong",
          });
        });
    }
  };

  upload_csv(event) {
    Swal.fire({
      title: "Uploading CSV",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let csvsrc;
    event.preventDefault();
    var file = event.target.files[0];
    const formData = new FormData();
    formData.append("uplodedFile", file, file.name);

    this.props
      .callRequest("POST", API_UPLOAD_CSV, true, formData)
      .then((res) => {
        Swal.close();
        this.setState({
          csvData: res.data,
        });
        event.target.value = "";
      });
  }

  resetAction() {
    this.setState({
      csvData: null,
      upload_btn_disabled: true,
      template_id: "",
      marge_process_type: "",
    });
  }

  fetchUsers = async () => {
    this.props.callRequest("GET", API_USERS, true).then((res) => {
      this.setState({
        userList: res.data,
      });
    });
  };

  render() {
    return (
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-single-copy-04"></i>
            </span>
            Vdp Merge
          </h1>
        </div>
        <div className="App">
          {this.state.csvData == null && (
            <div className="container">
              <div className="card">
                <div className="card-body">
                  <div className="table-vdp-merge">
                    <form onSubmit={this.SubmitVdp}>
                      <div className="form-group">
                        <div className="sample-csv-class">
                          <label>Select Template: </label>{" "}
                          <span>
                            <a
                              style={{ padding: "5px" }}
                              title="Download csv"
                              href={samplevdp}
                              download
                            >
                              <u>Download sample CSV</u>
                            </a>
                          </span>
                        </div>
                        <AsyncPaginate
                          value={this.state.temp_id}
                          loadOptions={this.loadOptions}
                          onChange={this.onChangeTemplate}
                          additional={{
                            page: 1,
                          }}
                        />
                        <label className="red_err">
                          {this.state.err_template}
                        </label>
                      </div>

                      <div className="form-group">
                        <label>Select Merge Process: </label>
                        <select
                          id="marge_process_type"
                          name="marge_process_type"
                          value={this.state.marge_process_type}
                          onChange={this.onChangeHandeller}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value={""}>Select Merge Process</option>
                          {this.state.marge_process.map((marge) => (
                            <option key={marge.marge_id} value={marge.marge_id}>
                              {marge.marge_process_type}
                            </option>
                          ))}
                        </select>

                        <label className="red_err">
                          {this.state.err_marge_process}
                        </label>
                      </div>

                      <div className="form-group">
                        <label>Select User Profile: </label>
                        <select
                          id="user"
                          name="user"
                          value={this.state.user}
                          onChange={this.onChangeHandeller}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value={""}>Select User Profile</option>
                          {this.state.userList.map((usr) => (
                            <option key={usr.user_id} value={usr.user_id}>
                              {usr.username}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group upload-block">
                        <label>Upload Mailing List: </label>
                        <div className="upload-button">
                          <button
                            className="btn btn-primary"
                            disabled={this.state.upload_btn_disabled}
                          >
                            Upload a csv file
                          </button>
                          <input
                            type="file"
                            id="csv_file"
                            name="csv_file"
                            accept=".csv"
                            onChange={this.upload_csv}
                            className="btn custom-file-input"
                            disabled={this.state.upload_btn_disabled}
                          ></input>
                        </div>

                        <label className="red_err"></label>
                      </div>

                      {this.state.csv_path !== "" ? (
                        <div className="form-group d-flex justify-content-center">
                          <button className="btn btn-primary" type="submit">
                            Start Processing
                          </button>
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {this.state.csvData && (
            <ImportTemplate
              {...this.props}
              csvData={this.state.csvData}
              template_id={this.state.template_id}
              marge_process_type={this.state.marge_process_type}
              resetAction={this.resetAction}
              user={this.state.user}
            />
          )}
          <div className="App">
            <VDPMergeList {...this.props} />
          </div>
        </div>
      </main>
    );
  }
}

export default VDP;
