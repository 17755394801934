import React, { Component } from "react";
import "./assets/css/TemplateDesign.css";
import {
  RiImageAddFill,
} from 'react-icons/ri';

export default class LeftImageFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customs: props.imageProps,
    };
  }

  onTrigger = (event) => {
    this.props.parentCallbackImage();
    event.preventDefault();
  };

  render() {
    return (
      <div className="template-fill-menu-item">
        <button
          className="menu-item-btn"
          value="Add Custom"
          onClick={this.onTrigger}
        >
          <div className='menu-item'>
            <span className='icon'><RiImageAddFill /></span>
            <span className='name'>Add Image</span>
          </div>
        </button>
      </div>
    );
  }
}
