import React, { useEffect, useState } from "react";
import ResizableRect from "react-resizable-rotatable-draggable";
import CKEditor from "ckeditor4-react";
import FontCss from "./assets/css/font.css";
import { Textfit } from 'react-textfit';

const TextEditor = ({ editorData, styleck, handleEditorStateChange, text_autofit }) => {
  return (
    <>
      {text_autofit ?
        <Textfit style={styleck}>
          <CKEditor
            config={{
              extraPlugins: "letterspacing, lineheight, placeholder_select, texteffectsliders, texttransform",
              uiColor: "#AADC6E",
              contentsCss: FontCss,
              format_tags: "p;h1;h2;h3;h4;h5;h6",
              fontSize_sizes:
                `6/6px;7/7px;8/8px;9/9px;10/10px;11/11px;12/12px;13/13px;14/14px;15/15px;16/16px;17/17px;18/18px;19/19px;20/20px;21/21px;22/22px;23/23px;24/24px;25/25px;26/26px;27/27px;28/28px;29/29px;30/30px;31/31px;32/32px;33/33px;34/34px;35/35px;36/36px;37/37px;38/38px;39/39px;40/40px;41/41px;42/42px;43/43px;44/44px;45/45px;46/46px;47/47px;48/48px;49/49px;50/50px;51/51px;52/52px;53/53px;54/54px;55/55px;56/56px;57/57px;58/58px;59/59px;60/60px;61/61px;62/62px;63/63px;64/64px;65/65px;66/66px;67/67px;68/68px;69/69px;70/70px;71/71px;72/72px;74/74px;76/76px;78/78px;80/80px;82/82px;84/84px;86/86px;88/88px;90/90px;92/92px;94/94px;96/96px;98/98px;100/100px;`,
              allowedContent: true,
              font_names:
                "Arial;" +
                // "Arial Bold;" +
                "Arial Black;" +
                "ArialMT;" +
                "A Safe Place to Fall;" +
                "American Typewriter;" +
                "american-typewriter;" +
                "brianberkawfont3;" +
                "Broetown Signature;" +
                "Cambria;" +
                "Cambria Bold;" +
                "Cooper Black;" +
                "Dakota;" +
                "Danzo;" +
                "Futura Bold;" +
                "Gill Sans Std;" +
                "Gill Sans Ultra Bold Condensed;" +
                "Helvetica Neue Medium;" +
                "Hobo Std (OT1);" +
                "Hoffmanhand;" +
                "Impact;" +
                "IDAutomation2D;" +
                "kirbyshand;" +
                "Lithos Pro Black;" +
                "Minion Pro (OTT);" +
                "Own Written;" +
                "Permanent Marker;" +
                "Playfair Display;" +
                "PlayfairDisplay-Bold;" +
                "PremiumUltra16;" +
                "Rock Salt;" +
                "Swis721 Blk BT;" +
                "Times;" +
                "Times Bold;" +
                "Times New Roman;" +
                "Tahoma Bold;" +
                "Tragic Marker TT;" +
                "Trebuchet MS;" +
                "Tw Cen MT;" +
                "Ubuntu;" +
                "USPSIMBStandard;",
              // "dotsfont;",
              toolbarGroups: [
                { name: "Basic Styles", groups: ["basicstyles"] },
                {
                  name: "Paragraph",
                  groups: [
                    "list",
                    "indent",
                    "blocks",
                    "align",
                    "bidi",
                    "paragraph",
                  ],
                },
                { name: "Font Styles", groups: ["styles", "colors", "others"] },
                { name: "Clipboard", groups: ["clipboard", "undo"] },
                { name: "Tools", groups: ["tools"] },
              ],
              removeButtons:
                "FontSize,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Source,Save,NewPage,Print,Templates,PasteText,PasteFromWord,Link,Unlink,Anchor,Image,Flash,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About,SelectAll,Find,Replace,Scayt,CreateDiv,Language,Format",
            }}
            //style={styleck}
            type="inline"
            data={editorData}
            onBlur={handleEditorStateChange}
            onBeforeLoad={(CKEDITOR) => {
              CKEDITOR.plugins.addExternal(
                "letterspacing",
                process.env.PUBLIC_URL + "/plugin/ckeditor-letterspacing-0.1.2/",
                "plugin.js"
              );
              CKEDITOR.plugins.addExternal(
                "lineheight",
                process.env.PUBLIC_URL + "/plugin/lineheight/",
                "plugin.js"
              );
              CKEDITOR.plugins.addExternal(
                "placeholder_select",
                process.env.PUBLIC_URL + "/plugin/placeholder_select/",
                "plugin.js"
              );
              CKEDITOR.plugins.addExternal(
                "texteffectsliders",
                process.env.PUBLIC_URL + "/plugin/texteffectsliders/",
                "plugin.js"
              );
              CKEDITOR.plugins.addExternal(
                "texttransform",
                process.env.PUBLIC_URL + "/plugin/texttransform/",
                "plugin.js"
              );
            }}
          >
          </CKEditor>
        </Textfit>
        :
        <CKEditor
          config={{
            extraPlugins: "letterspacing, lineheight, placeholder_select, texteffectsliders, texttransform",
            uiColor: "#AADC6E",
            contentsCss: FontCss,
            format_tags: "p;h1;h2;h3;h4;h5;h6",
            fontSize_sizes:
              `6/6px;7/7px;8/8px;9/9px;10/10px;11/11px;12/12px;13/13px;14/14px;15/15px;16/16px;17/17px;18/18px;19/19px;20/20px;21/21px;22/22px;23/23px;24/24px;25/25px;26/26px;27/27px;28/28px;29/29px;30/30px;31/31px;32/32px;33/33px;34/34px;35/35px;36/36px;37/37px;38/38px;39/39px;40/40px;41/41px;42/42px;43/43px;44/44px;45/45px;46/46px;47/47px;48/48px;49/49px;50/50px;51/51px;52/52px;53/53px;54/54px;55/55px;56/56px;57/57px;58/58px;59/59px;60/60px;61/61px;62/62px;63/63px;64/64px;65/65px;66/66px;67/67px;68/68px;69/69px;70/70px;71/71px;72/72px;74/74px;76/76px;78/78px;80/80px;82/82px;84/84px;86/86px;88/88px;90/90px;92/92px;94/94px;96/96px;98/98px;100/100px;`,
            allowedContent: true,
            font_names:
              "Arial;" +
              // "Arial Bold;" +
              "Arial Black;" +
              "ArialMT;" +
              "A Safe Place to Fall;" +
              "American Typewriter;" +
              "american-typewriter;" +
              "brianberkawfont3;" +
              "Broetown Signature;" +
              "Cambria;" +
              "Cambria Bold;" +
              "Cooper Black;" +
              "Dakota;" +
              "Danzo;" +
              "Futura Bold;" +
              "Gill Sans Std;" +
              "Gill Sans Ultra Bold Condensed;" +
              "Helvetica Neue Medium;" +
              "Hobo Std (OT1);" +
              "Hoffmanhand;" +
              "Impact;" +
              "IDAutomation2D;" +
              "kirbyshand;" +
              "Lithos Pro Black;" +
              "Minion Pro (OTT);" +
              "Own Written;" +
              "Permanent Marker;" +
              "Playfair Display;" +
              "PlayfairDisplay-Bold;" +
              "PremiumUltra16;" +
              "Rock Salt;" +
              "Swis721 Blk BT;" +
              "Times;" +
              "Times Bold;" +
              "Times New Roman;" +
              "Tahoma Bold;" +
              "Tragic Marker TT;" +
              "Trebuchet MS;" +
              "Tw Cen MT;" +
              "Ubuntu;" +
              "USPSIMBStandard;",
            // "dotsfont;",
            toolbarGroups: [
              { name: "Basic Styles", groups: ["basicstyles"] },
              {
                name: "Paragraph",
                groups: [
                  "list",
                  "indent",
                  "blocks",
                  "align",
                  "bidi",
                  "paragraph",
                ],
              },
              { name: "Font Styles", groups: ["styles", "colors", "others"] },
              { name: "Clipboard", groups: ["clipboard", "undo"] },
              { name: "Tools", groups: ["tools"] },
            ],
            removeButtons:
              "Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Source,Save,NewPage,Print,Templates,PasteText,PasteFromWord,Link,Unlink,Anchor,Image,Flash,Smiley,SpecialChar,PageBreak,Iframe,Maximize,ShowBlocks,About,SelectAll,Find,Replace,Scayt,CreateDiv,Language,Format",
          }}
          style={styleck}
          type="inline"
          data={editorData}
          onBlur={handleEditorStateChange}
          onBeforeLoad={(CKEDITOR) => {
            CKEDITOR.plugins.addExternal(
              "letterspacing",
              process.env.PUBLIC_URL + "/plugin/ckeditor-letterspacing-0.1.2/",
              "plugin.js"
            );
            CKEDITOR.plugins.addExternal(
              "lineheight",
              process.env.PUBLIC_URL + "/plugin/lineheight/",
              "plugin.js"
            );
            CKEDITOR.plugins.addExternal(
              "placeholder_select",
              process.env.PUBLIC_URL + "/plugin/placeholder_select/",
              "plugin.js"
            );
            CKEDITOR.plugins.addExternal(
              "texteffectsliders",
              process.env.PUBLIC_URL + "/plugin/texteffectsliders/",
              "plugin.js"
            );
            CKEDITOR.plugins.addExternal(
              "texttransform",
              process.env.PUBLIC_URL + "/plugin/texttransform/",
              "plugin.js"
            );
          }}
        >
        </CKEditor>
      }
    </>
  );
};

const ResizableComponent = (props) => {
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const [top, setTop] = useState(props.top);
  const [left, setLeft] = useState(props.left);
  const [rotateAngle, setRotateAngle] = useState(props.rotateAngle);
  const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
  const [roundedCorner, setRoundedCorner] = useState(props.roundedCorner);
  const [margin_top, setMarginTop] = useState(props.margin_top);
  const [margin_left, setMarginLeft] = useState(props.margin_left);
  const [padding_top, setPaddingTop] = useState(props.padding_top);
  const [padding_right, setPaddingRight] = useState(props.padding_right);
  const [padding_bottom, setPaddingBottom] = useState(props.padding_bottom);
  const [padding_left, setPaddingLeft] = useState(props.padding_left);
  const [custom_text_lock, setCustomTextLock] = useState(props.custom_text_lock);
  const [font_min_size, setFontMinSize] = useState(props.font_min_size);
  const [font_max_size, setFontMaxSize] = useState(props.font_max_size);
  const [scalling_class, setScallingClass] = useState(props.scalling_class);
  const [transparency, setTransparency] = useState(props.transparency);
  const [text_autofit, setTextAutofit] = useState(props.text_autofit);
  const [id] = useState(props.id);
  let topy;
  let leftx;
  let pass_data;

  useEffect(() => {
    setBackgroundColor(props.backgroundColor);
    setRoundedCorner(props.roundedCorner);
    setMarginTop(props.margin_top);
    setMarginLeft(props.margin_left);
    setPaddingTop(props.padding_top);
    setPaddingRight(props.padding_right);
    setPaddingBottom(props.padding_bottom);
    setPaddingLeft(props.padding_left);
    setCustomTextLock(props.custom_text_lock);
    setFontMinSize(props.font_min_size);
    setFontMaxSize(props.font_max_size);
    setScallingClass(props.scalling_class);
    setTransparency(props.transparency);
    setTextAutofit(props.text_autofit);
  }, [
    props.backgroundColor,
    props.roundedCorner,
    props.margin_top,
    props.margin_left,
    props.padding_top,
    props.padding_right,
    props.padding_bottom,
    props.padding_left,
    props.custom_text_lock,
    props.font_min_size,
    props.font_max_size,
    props.scalling_class,
    props.transparency,
    props.text_autofit,
  ]);

  const contentStyle = {
    top,
    left,
    width,
    height,
    position: "absolute",
    transform: `rotate(${rotateAngle}deg)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0",
    margin: "0",
    zIndex: 600,
    backgroundColor: backgroundColor,
    borderRadius: roundedCorner + "px",
  };

  let styleck = {
    position: "absolute",
    top: "0",
    left: "0",
    visibility: "visible",
    zIndex: "602",
    width: width,
    height: height,
    overflow: "hidden",
    //textAlign: "center",
    lineHeight: "normal",
    display: "block",
    //justifyContent: "center",
    //alignItems: "flex-start",
    padding: "0",
    margin: "0",
    //flexWrap: "wrap",
    minHeight: "10px",
    borderRadius: roundedCorner + "px",
    paddingTop: padding_top + "px",
    paddingRight: padding_right + "px",
    paddingBottom: padding_bottom + "px",
    paddingLeft: padding_left + "px",
    wordBreak: "break-word",
    boxSizing: "border-box",
  };

  const handleResize = (style, isShiftKey, type) => {
    const { top, left, width, height } = style;
    setWidth(Math.round(width));
    setHeight(Math.round(height));
    setTop(Math.round(top));
    setLeft(Math.round(left));

    props.parentsetCustomDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      custom_text_lock,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );

    styleck = {
      position: "absolute",
      top: "0",
      left: "0",
      visibility: "visible",
      zIndex: "602",
      width: width,
      height: height,
      overflow: "hidden",
      //textAlign: "center",
      lineHeight: "normal",
      display: "block",
      //justifyContent: "center",
      //alignItems: "flex-start",
      padding: "0",
      margin: "0",
      //flexWrap: "wrap",
      minHeight: "10px",
      borderRadius: roundedCorner + "px",
      paddingTop: padding_top + "px",
      paddingRight: padding_right + "px",
      paddingBottom: padding_bottom + "px",
      paddingLeft: padding_left + "px",
      //wordBreak: "break-all",
      boxSizing: "border-box",
    };
  };

  const handleRotate = (rotateAngle) => {
    setRotateAngle(rotateAngle);

    props.parentsetCustomDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      custom_text_lock,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );
  };

  const handleDrag = (deltaX, deltaY) => {
    leftx = left + deltaX;
    topy = top + deltaY;
    setTop(topy);
    setLeft(leftx);
    props.parentsetCustomDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      custom_text_lock,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );
  };

  pass_data = props.title;
  const [editorData, setEditorData] = useState(pass_data);

  const handleEditorStateChange = (evt) => {
    let temp_class_name = "";
    evt.editor.dataProcessor.htmlFilter.addRules({
      elements: {
        //p tag
        p: function (el) {
          let pattern_match = el.getHtml().match(/\{{(.*?)\}}/g);
          if (pattern_match !== null) {
            let class_name = pattern_match[0].replaceAll("{{", "");
            class_name = class_name.replaceAll(" ", "");
            class_name = class_name.replaceAll("&nbsp;", "");
            class_name = class_name.replaceAll(",", "");
            class_name = class_name.replaceAll("}}", "");

            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            el.addClass(
              class_name + "_" + evt.editor.id.replace("cke", `kgstar_${id}`)
            );
            temp_class_name = evt.editor.id.replace("cke", `kgstar_${id}`);

            el.addClass("white-space-nowrap");
            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          }

          if (typeof el.children[0].name === "undefined") {

          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                el.children[0].removeClass(split_arr[0]);
              }
            }
          }
        },
        //h1
        h1: function (el) {
          let pattern_match = el.getHtml().match(/\{{(.*?)\}}/g);
          if (pattern_match !== null) {
            let class_name = pattern_match[0].replaceAll("{{", "");
            class_name = class_name.replaceAll(" ", "");
            class_name = class_name.replaceAll("&nbsp;", "");
            class_name = class_name.replaceAll(",", "");
            class_name = class_name.replaceAll("}}", "");

            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            el.addClass(
              class_name + "_" + evt.editor.id.replace("cke", `kgstar_${id}`)
            );
            temp_class_name = evt.editor.id.replace("cke", `kgstar_${id}`);

            el.addClass("white-space-nowrap");
            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          }

          if (typeof el.children[0].name === "undefined") {
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                el.children[0].removeClass(split_arr[0]);
              }
            }
          }
        },
        //h2
        h2: function (el) {
          let pattern_match = el.getHtml().match(/\{{(.*?)\}}/g);
          if (pattern_match !== null) {
            let class_name = pattern_match[0].replaceAll("{{", "");
            class_name = class_name.replaceAll(" ", "");
            class_name = class_name.replaceAll("&nbsp;", "");
            class_name = class_name.replaceAll(",", "");
            class_name = class_name.replaceAll("}}", "");

            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            el.addClass(
              class_name + "_" + evt.editor.id.replace("cke", `kgstar_${id}`)
            );
            temp_class_name = evt.editor.id.replace("cke", `kgstar_${id}`);

            el.addClass("white-space-nowrap");
            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          }

          if (typeof el.children[0].name === "undefined") {
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                el.children[0].removeClass(split_arr[0]);
              }
            }
          }
        },
        //h3
        h3: function (el) {
          let pattern_match = el.getHtml().match(/\{{(.*?)\}}/g);
          if (pattern_match !== null) {
            let class_name = pattern_match[0].replaceAll("{{", "");
            class_name = class_name.replaceAll(" ", "");
            class_name = class_name.replaceAll("&nbsp;", "");
            class_name = class_name.replaceAll(",", "");
            class_name = class_name.replaceAll("}}", "");

            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            el.addClass(
              class_name + "_" + evt.editor.id.replace("cke", `kgstar_${id}`)
            );
            temp_class_name = evt.editor.id.replace("cke", `kgstar_${id}`);

            el.addClass("white-space-nowrap");
            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          }

          if (typeof el.children[0].name === "undefined") {
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                el.children[0].removeClass(split_arr[0]);
              }
            }
          }
        },
        //h4
        h4: function (el) {
          let pattern_match = el.getHtml().match(/\{{(.*?)\}}/g);
          if (pattern_match !== null) {
            let class_name = pattern_match[0].replaceAll("{{", "");
            class_name = class_name.replaceAll(" ", "");
            class_name = class_name.replaceAll("&nbsp;", "");
            class_name = class_name.replaceAll(",", "");
            class_name = class_name.replaceAll("}}", "");

            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            el.addClass(
              class_name + "_" + evt.editor.id.replace("cke", `kgstar_${id}`)
            );
            temp_class_name = evt.editor.id.replace("cke", `kgstar_${id}`);

            el.addClass("white-space-nowrap");
            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          }

          if (typeof el.children[0].name === "undefined") {
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                el.children[0].removeClass(split_arr[0]);
              }
            }
          }
        },
        //h5
        h5: function (el) {
          let pattern_match = el.getHtml().match(/\{{(.*?)\}}/g);
          if (pattern_match !== null) {
            let class_name = pattern_match[0].replaceAll("{{", "");
            class_name = class_name.replaceAll(" ", "");
            class_name = class_name.replaceAll("&nbsp;", "");
            class_name = class_name.replaceAll(",", "");
            class_name = class_name.replaceAll("}}", "");

            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            el.addClass(
              class_name + "_" + evt.editor.id.replace("cke", `kgstar_${id}`)
            );
            temp_class_name = evt.editor.id.replace("cke", `kgstar_${id}`);

            el.addClass("white-space-nowrap");
            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          }

          if (typeof el.children[0].name === "undefined") {
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                el.children[0].removeClass(split_arr[0]);
              }
            }
          }
        },
        //h6
        h6: function (el) {
          let pattern_match = el.getHtml().match(/\{{(.*?)\}}/g);
          if (pattern_match !== null) {
            let class_name = pattern_match[0].replaceAll("{{", "");
            class_name = class_name.replaceAll(" ", "");
            class_name = class_name.replaceAll("&nbsp;", "");
            class_name = class_name.replaceAll(",", "");
            class_name = class_name.replaceAll("}}", "");

            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            el.addClass(
              class_name + "_" + evt.editor.id.replace("cke", `kgstar_${id}`)
            );
            temp_class_name = evt.editor.id.replace("cke", `kgstar_${id}`);

            el.addClass("white-space-nowrap");
            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                for (let i = 0; i < split_arr.length; i++) {
                  if (split_arr[i].includes("kgstar")) {
                    el.removeClass(split_arr[i]);
                  }
                }
              }
            }

            if (el.parent.children.length === 1) {
              el.addClass("width_height_inherit");
            } else {
              el.removeClass("width_height_inherit");
            }
          }

          if (typeof el.children[0].name === "undefined") {
          } else {
            if (typeof el.attributes.class !== "undefined") {
              if (el.attributes.class.includes("kgstar")) {
                let split_arr = el.attributes.class.split(" ");
                el.children[0].removeClass(split_arr[0]);
              }
            }
          }
        },
      },
    });

    evt.editor.updateElement();
    const raw = evt.editor.getData();
    setEditorData(raw);
    props.parentEditorData(
      id,
      left,
      top,
      width,
      height,
      raw,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      custom_text_lock,
      font_min_size,
      font_max_size,
      temp_class_name,
      transparency,
      text_autofit,
    );
  };

  useEffect(() => {
    setTimeout(() => {
      const elements = document.getElementsByClassName("cke_voice_label").length;
      for (let i = 0; i < elements; i++) {
        document.getElementsByClassName("cke_voice_label")[i].textContent = document.getElementsByClassName("cke_voice_label")[i].textContent.replace('Press ALT 0 for help', '');
        document.getElementsByClassName("cke_voice_label")[i].textContent = document.getElementsByClassName("cke_voice_label")[i].textContent.replace('Editor toolbars', '');
      }
    }, 1000);
  }, []);

  return (
    <>
      <div style={contentStyle}>
        <TextEditor
          editorData={editorData}
          styleck={styleck}
          handleEditorStateChange={handleEditorStateChange}
          text_autofit={text_autofit}
        />
        <div className="align-element-position" style={{ display: "none" }}>
          <span>
            <i className="fa fa-arrow-down" aria-hidden="true"></i>{Math.round(props.top) + 26}
          </span>
          <span>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>{Math.round(props.left) + 26}
          </span>
        </div>
      </div>

      <ResizableRect
        top={top}
        rotatable
        left={left}
        //aspectRatio
        minWidth={10}
        width={width}
        minHeight={10}
        height={height}
        onDrag={!custom_text_lock ? handleDrag : null}
        onRotate={!custom_text_lock ? handleRotate : null}
        onResize={!custom_text_lock ? handleResize : null}
        zoomable={!custom_text_lock ? "n, w, s, e, nw, ne, se, sw" : ""}
        rotateAngle={rotateAngle}
      />
    </>
  );
};

export default ResizableComponent;
