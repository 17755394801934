import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import {
    Button,
    Input,
} from "reactstrap";
import "./Orders.css";
import {
    API_GET_BATCH_PREVIEW_DETAILS,
    API_DELETE_DROP_PREVIEW_BATCH
} from "../../config/Api";
import moment from "moment"

const BatchPreviewDetails = (props) => {
    const [batchPreviewDetail, setBatchPreviewDetail] = useState([]);
    const [checkDrop, setCheckDrop] = useState([]);

    useEffect(() => {
        getBatchPreviewDetail();
    }, []);

    const dateFormater = (inputValue) => {
        let d = new Date(inputValue * 1000);
        return moment(d).format('MM/DD/YYYY')
        // return (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear();
    };

    const getBatchPreviewDetail = () => {
        let url = API_GET_BATCH_PREVIEW_DETAILS + props.data.batch_id;
        props.callRequest("GET", url, true, null).then((res) => {
            setBatchPreviewDetail(res.data);
        });
    };

    const handleChange = (e, mailing_date_id) => {
        if (e.target.checked) {
            setCheckDrop([...checkDrop, mailing_date_id]);
        } else {
            setCheckDrop(checkDrop.filter((c) => c !== mailing_date_id));
        }
    };

    const onDropDelete = () => {
        Swal.fire({
            icon: "question",
            title: "Do you want to delete drop?",
            showDenyButton: true,
            confirmButtonText: `Yes`,
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                props.callRequest("POST", API_DELETE_DROP_PREVIEW_BATCH, true, {
                    batch_id: props.data.batch_id,
                    mailing_date_id: checkDrop
                }).then((res) => {
                    if (res.status) {
                        getBatchPreviewDetail();
                        setCheckDrop([]);
                        props.fetchBatchPreview();
                        Swal.fire("Drop has been deleted!", "", "success");
                    }
                });
            }
        });
    };

    return (
        <>
            {
                checkDrop.length > 0 ?
                    <div className="delete-drop-btn"><Button onClick={onDropDelete}><i className="fas fa-trash"></i>Delete Drop</Button></div>
                    : null
            }
            <div className="orderlist-details-block">
                {batchPreviewDetail &&
                    batchPreviewDetail.map((item, index) => {
                        return (
                            <div className="orderlist-item-box" key={item.mailing_date_id}>
                                <div className="list-item orderid">
                                    <Input
                                        name="check"
                                        type="checkbox"
                                        onChange={(e) => handleChange(e, item.mailing_date_id)}
                                    />
                                </div>
                                <div className="list-item orderid">
                                    <h5 className="item-titel">Order ID</h5>
                                    <p>{item.orderid}</p>
                                </div>
                                <div className="list-item drop-name">
                                    <h5 className="item-titel">Drop</h5>
                                    <p>{item.drop}</p>
                                </div>
                                <div className="list-item username">
                                    <h5 className="item-titel">Username</h5>
                                    <p>{item.username}</p>
                                </div>
                                <div className="list-item template-name">
                                    <h5 className="item-titel">Template Name</h5>
                                    <p>{item.template}</p>
                                </div>
                                <div className="list-item quantity">
                                    <h5 className="item-titel">Quantity</h5>
                                    <p>{item.quantity}</p>
                                </div>
                                <div className="list-item date-in">
                                    <h5 className="item-titel">Date In</h5>
                                    <p>{dateFormater(item.date_in)}</p>
                                </div>
                                <div className="list-item date-due">
                                    <h5 className="item-titel">Date Due</h5>
                                    <p>{item.due_date == "" || item.due_date == null ? "--/--/--" : dateFormater(item.due_date)}</p>
                                </div>
                                <div className="list-item status">
                                    <h5 className="item-titel">Status</h5>
                                    <p>{item.status}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    )
};

export default BatchPreviewDetails;
