import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import {
    MdOutlineAddPhotoAlternate,
} from 'react-icons/md';

const LeftLogoUploaderFields = (props) => {
    const [count, setCount] = useState(props.logo_uploader.length);

    useEffect(() => {
        setCount(props.logo_uploader.length);
    }, [props.logo_uploader.length]);

    const onTrigger = (event) => {
        props.parentAddLogoUploader();
        event.preventDefault();
    };

    return (
        <div className="template-fill-menu-item">
            <button
                className="menu-item-btn"
                value="Add Logo"
                hidden={count >= 1 ? true : false}
                onClick={onTrigger}
            >
                <div className='menu-item'>
                    <span className='icon'><MdOutlineAddPhotoAlternate /></span>
                    <span className='name'>Add Logo</span>
                </div>

            </button>
        </div>
    );

}

export default LeftLogoUploaderFields;
