import React, { useState, useEffect } from 'react';
import "../../assets/css/data-table.css";
import Swal from "sweetalert2";
import {
    Button,
    Input,
    FormGroup,
    Form, Label
} from "reactstrap";

import { API_TEMPLATE_SIZE } from "../../config/Api";

const TemplateSizeForm = (props) => {
    const [size_name, setSizeName] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (props.templateSizeId !== 0) {
            fetchTemplateSizeById();
        }
    }, [props]);

    const fetchTemplateSizeById = async () => {
        props.callRequest("GET", API_TEMPLATE_SIZE + props.templateSizeId, true).then((res) => {
            setSizeName(res.data.size_name);
            setWidth(res.data.width);
            setHeight(res.data.height);
        })
    };

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};
        if (size_name === '') {
            formIsValid = false;
            errors["err_size_name"] = "Please provide size name";
        }
        if (width === '') {
            formIsValid = false;
            errors["err_width"] = "Please provide width";
        }
        if (height === '') {
            formIsValid = false;
            errors["err_height"] = "Please provide height";
        }
        setErrors(errors);
        return formIsValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validateForm()) {
            setErrors({ err_size_name: '' });
            setErrors({ err_width: '' });
            setErrors({ err_height: '' });
            const formData = new FormData();
            formData.append("size_name", size_name);
            formData.append("width", width);
            formData.append("height", height);
            if (props.templateSizeId !== 0) {
                props.callRequest("PUT", API_TEMPLATE_SIZE + props.templateSizeId + "/", true, formData).then((res) => {
                    if (res.status) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Updated successfully",
                        });
                        setTimeout(
                            function () {
                                setSizeName('');
                                setWidth('');
                                setHeight('');
                                props.modalToggle();
                                props.fetchTemplateSize();
                            }.bind(),
                            3000
                        );
                    }
                }).catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: "error",
                        title: "Something Went Wrong!",
                    });
                });
            } else {
                props.callRequest("POST", API_TEMPLATE_SIZE, true, formData).then((res) => {
                    if (res.status) {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener("mouseenter", Swal.stopTimer);
                                toast.addEventListener("mouseleave", Swal.resumeTimer);
                            },
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Saved successfully",
                        });
                        setTimeout(
                            function () {
                                setSizeName('');
                                setWidth('');
                                setHeight('');
                                props.modalToggle();
                                props.fetchTemplateSize();
                            }.bind(),
                            3000
                        );
                    }
                }).catch((error) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    });

                    Toast.fire({
                        icon: "error",
                        title: "Template size with this size name already exists!",
                    });
                });
            }
        }
    };

    return (
        <>
            <div className="popup-form-block">
                <Form>
                    <FormGroup>
                        <Label for="sizeName">Size Name: *</Label>
                        <Input
                            type="text"
                            autoComplete="off"
                            name="size_name"
                            id="size_name"
                            className="form-control"
                            value={size_name}
                            onChange={e => setSizeName(e.target.value)}
                        />
                        <label className="red_err">{errors.err_size_name}</label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="width">Width: *</Label>
                        <Input
                            type="number"
                            autoComplete="off"
                            name="width"
                            id="width"
                            className="form-control"
                            value={width}
                            onChange={e => setWidth(e.target.value)}
                        />
                        <label className="red_err">{errors.err_width}</label>
                    </FormGroup>
                    <FormGroup>
                        <Label for="width">Height: *</Label>
                        <Input
                            type="number"
                            autoComplete="off"
                            name="height"
                            id="height"
                            className="form-control"
                            value={height}
                            onChange={e => setHeight(e.target.value)}
                        />
                        <label className="red_err">{errors.err_height}</label>
                    </FormGroup>

                    <div className="popup-button-block">
                        <Button onClick={handleSubmit}>Save Changes <i className="ni ni-bold-right"></i></Button>
                    </div>
                </Form>
            </div>
        </>
    )
}

export default TemplateSizeForm;