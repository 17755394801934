import React, { useRef, useEffect, useState } from "react";
import Guides from "@scena/react-guides";

const RulerVertical = (props) => {
    const rulerCanvasStyle = {
        width: `30px`,
        height: props.is_bleed === "1" ? `100%` : `calc(100% - 12px)`,
        position: `absolute`,
        top: props.is_bleed === "1" ? "0px" : "12px",
        left: "-30px",
        zIndex: `9999`,
        borderLeft: `1px solid #727272`
    };
    const vertical = useRef(null);
    const [vertical_line, setVerticalLine] = useState(props.vertical_line);

    useEffect(() => {
        vertical.current.resize();
    });

    const setVerticalGuide = (guides) => {
        setVerticalLine(guides);
        props.setVerticalGuide(guides);
    };

    return (
        <Guides
            ref={e => {
                vertical.current = e;
            }}
            type="vertical"
            style={rulerCanvasStyle}
            direction="start"
            backgroundColor="transparent"
            textColor="#000000"
            lineColor="#595959"
            displayDragPos
            zoom={96}
            unit={1}
            segment={8}
            defaultGuides={vertical_line}
            onChangeGuides={({ guides }) => {
                setVerticalGuide(guides);
            }}
        />
    );
}

export default RulerVertical;