import React, { useState, useRef, useCallback, useEffect } from "react";
import "./assets/css/TemplateDesign.css";
import axios from "axios";
import ContextMenuImage from "../ContextMenu/ContextMenuImage";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

const ImageComponentLeft = (props) => {
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, height: 30 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [modal, setModal] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [lock, setLock] = useState(props.custom_image_lock);

  useEffect(() => {
    setLock(props.custom_image_lock);
  }, [props.custom_image_lock]);

  const toggle = () => {
    setModal(!modal);
  };

  const handleImageUpload = (e) => {
    e.preventDefault();
    setIsLoader(true);
    let imagesrc;
    const [file] = e.target.files;

    if (file) {
      const formData = new FormData();
      formData.append("uplodedFile", file, file.name);
      axios
        .post(process.env.REACT_APP_VDP_API + "api/saveFile", formData, {
          timeout: 0,
        })
        .then((res) => {
          imagesrc = process.env.REACT_APP_VDP_API + "api" + res.data;

          setUpImg(URL.createObjectURL(e.target.files[0]));
          toggle();

          const reader = new FileReader();
          const { current } = uploadedImage;
          current.file = file;

          reader.onload = (e) => {
            current.src = imagesrc;
          };

          let allCustomImages_array = props.allCustomImages;
          let detect_latest_order_exclude_21 = 0;
          let top = 0;
          let left = 0;
          allCustomImages_array.forEach(function (elem) {
            if (
              detect_latest_order_exclude_21 < elem.order &&
              elem.id !== 0 &&
              elem.order < 21
            ) {
              detect_latest_order_exclude_21 = elem.order;
              top = elem.top;
              left = elem.left;
            }
          });

          let image_box_id_with_order_21;
          let image_box_max_order;

          if (detect_latest_order_exclude_21 !== 0) {
            allCustomImages_array.forEach(function (customImage, index) {
              if (customImage.id !== 0 && customImage.order === 21) {
                image_box_id_with_order_21 = customImage.id;
                image_box_max_order = detect_latest_order_exclude_21;
              }
            });
          }

          setTimeout(function () {
            var img = new Image();
            img.src = imagesrc;
            let original_image_width = img.width;
            let original_image_height = img.height;

            if (original_image_width >= 100) {
              original_image_height =
                (original_image_height / original_image_width) * 100;
              original_image_width = 100;
            }

            if (original_image_height >= 100) {
              original_image_width =
                (original_image_width / original_image_height) * 100;
              original_image_height = 100;
            }

            props.getChildImageData(
              imagesrc,
              props.imageBox.id,
              21,
              image_box_id_with_order_21,
              image_box_max_order,
              true,
              props.imageBox.order,
              original_image_width,
              original_image_height,
              top,
              left,
            );
            if (props.imageBox.id === 0) {
              props.parentBackGroundColorSection("#FFFFFF");
            }
            setIsLoader(false);
          }, 3000);
          reader.readAsDataURL(file);

          if (e.target.files) {
            imageUploader.current.value = "";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const getSrcLength = () => {
    let arrLen = [];
    let allCustomImages_array = props.allCustomImages;
    for (let i = 0; i < allCustomImages_array.length; i++) {
      if (
        allCustomImages_array[i].img_src !== "" &&
        allCustomImages_array[i].id !== 0
      ) {
        arrLen.push(allCustomImages_array[i].img_src);
      }
    }
    return arrLen;
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const getCroppedImg = async () => {
    try {
      if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
        return;
      }

      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = canvas.getContext("2d");
      const pixelRatio = window.devicePixelRatio;

      canvas.width = crop.width * pixelRatio * scaleX;
      canvas.height = crop.height * pixelRatio * scaleY;

      ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY
      );
      const base64Image = canvas.toDataURL("image/webp", 0.7);
      // const base64Image = canvas.toDataURL("image/png");

      let allCustomImages_array = props.allCustomImages;
      let detect_latest_order_exclude_21 = 0;
      allCustomImages_array.forEach(function (elem) {
        if (
          detect_latest_order_exclude_21 < elem.order &&
          elem.id !== 0 &&
          elem.order < 21
        ) {
          detect_latest_order_exclude_21 = elem.order;
        }
      });

      let image_box_id_with_order_21;
      let image_box_max_order;
      if (detect_latest_order_exclude_21 !== 0) {
        allCustomImages_array.forEach(function (customImage, index) {
          if (customImage.id !== 0 && customImage.order === 21) {
            image_box_id_with_order_21 = customImage.id;
            image_box_max_order = detect_latest_order_exclude_21;
          }
        });
      }

      props.getChildImageData(
        base64Image,
        props.imageBox.id,
        21,
        image_box_id_with_order_21,
        image_box_max_order,
        true,
        props.imageBox.order,
        Math.round(completedCrop?.width ?? 0),
        Math.round(completedCrop?.height ?? 0),
      );
      toggle();
      setCrop({ unit: "%", width: 30, height: 30 });
    } catch (e) {
      console.log(e);
    }
  };

  const cancelImageCrop = () => {
    toggle();
  };

  const handleImageUploadshow = () => {
    if (props.imageBox.img_src !== "") {
      return (
        <img
          alt=""
          src={props.imageBox.img_src}
          ref={uploadedImage}
          style={{
            width: "100%",
            height: "100%",
            position: "acsolute",
          }}
        />
      );
    } else {
      return (
        <img
          alt=""
          ref={uploadedImage}
          style={{
            width: "100%",
            height: "100%",
            position: "acsolute",
            opacity: "0",
          }}
        />
      );
    }
  };

  const deleteImage = (deleteImageId) => {
    props.ParentDeleteImageElement(deleteImageId);
  };

  const handleImageDeleteButton = () => {
    if (
      props.imageBox.img_src !== "" &&
      typeof props.imageBox.img_src !== "undefined" &&
      props.imageBox.img_src !== null
    ) {
      return (
        <button
          className="btn-image-delete"
          onClick={() =>
            deleteImage(props.id)
          }
        >
          X
        </button>
      );
    } else {
      return null;
    }
  };

  const toggleLock = (e) => {
    setLock(!lock);
    props.handleImageLockUnlock(props.imageBox.id, !lock, "custom_image_lock");
  };

  const handleImageLockButton = () => {
    if (
      props.imageBox.img_src !== "" &&
      typeof props.imageBox.img_src !== "undefined" &&
      props.imageBox.img_src !== null
    ) {
      return (
        <button
          style={{
            padding: "0",
            margin: "0",
            fontSize: "14px",
            position: "absolute",
            bottom: "8px",
            left: "8px",
            background: "#ffffff",
            borderRadius: "100%",
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          value={lock}
          onClick={(e) => toggleLock(e)}
        >
          {lock ? (
            <i style={{ color: "#66707d" }} className="fas fa-lock"></i>
          ) : (
            <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
          )}
        </button>
      );
    } else {
      return null;
    }
  };

  function updateImageCustomsZIndex(
    img_box_title,
    img_src,
    order,
    current_id,
    imageType,
    currentZIndex
  ) {
    props.getChildImageZIndex(
      img_box_title,
      img_src,
      order,
      current_id,
      imageType,
      currentZIndex
    );
  }

  return (
    <>
      {isLoader === true ? (
        <div className="loader_main">
          <div className="loade_box">
            <div className="loader"></div>
            <p>Please Wait...</p>
          </div>
        </div>
      ) : null}
      {/* {props.imageBox.id === 0 ? (
        <div className={"img_box_" + props.imageBox.id + " image_upload_box"}>
          <div className="image_box_area">
            <span>{props.imageBox.img_box_title}</span>
            <input
              type="file"
              accept=".jpg, .png"
              onChange={handleImageUpload}
              ref={imageUploader}
              style={{
                display: "none",
              }}
            />
            <div
              className="each_image"
              onClick={() => imageUploader.current.click()}
            >
              {handleImageUploadshow()}
            </div>

            {handleImageDeleteButton()}
          </div>
        </div>
      ) : null} */}
      {props.imageBox.id && props.imageBox.img_src !== "" ? (
        <div className={"img_box_" + props.imageBox.id + " image_upload_box"}>
          <div className="image_box_area">
            {/* <span>{props.imageBox.img_box_title}</span> */}
            <span>{"Custom Image"}</span>
            {getSrcLength().length > 1 &&
              props.imageBox.img_src !== "" &&
              props.imageBox.id !== 0 &&
              typeof props.imageBox.id !== "undefined" ? (
              <ContextMenuImage
                customMyProps={props}
                parentUpdateImageCustomsZIndex={updateImageCustomsZIndex}
                currentImgBoxId={props.imageBox.id}
                currentImgBoxSrc={props.imageBox.img_src}
                allCustomImages={props.allCustomImages}
              />
            ) : null}

            {!lock ? (
              <input
                type="file"
                accept=".jpg, .png"
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                  display: "none",
                }}
              />
            ) : null}
            <div
              className="each_image"
              onClick={!lock ? () => imageUploader.current.click() : null}
            >
              {handleImageUploadshow()}
            </div>

            {handleImageLockButton()}
            {handleImageDeleteButton()}
          </div>
        </div>
      ) : null}
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
        className="crop-image-modal"
      >
        <ModalHeader toggle={toggle}>Crop Image</ModalHeader>
        <ModalBody>
          <div>
            {upImg && (
              <>
                {props.imageBox.img_src !== "" ? (
                  <div className="image-crop-body">
                    <ReactCrop
                      src={upImg}
                      onImageLoaded={onLoad}
                      crop={crop}
                      onChange={(c) => setCrop(c)}
                      onComplete={(c) => setCompletedCrop(c)}
                    />
                    <div
                      style={{
                        paddingTop: "5px",
                        fontSize: "12px",
                        lineHeight: "18px",
                      }}
                    >
                      <p>
                        Width: {Math.round(completedCrop?.width ?? 0) + "px"}
                        {", "}
                        Height: {Math.round(completedCrop?.height ?? 0) + "px"}
                      </p>
                    </div>
                    <div style={{ display: "none" }}>
                      <canvas
                        ref={previewCanvasRef}
                        style={{
                          width: Math.round(completedCrop?.width ?? 0),
                          height: Math.round(completedCrop?.height ?? 0),
                        }}
                      />
                    </div>
                    <div className="crop-image-btn-block">
                      <Button onClick={getCroppedImg}>Crop</Button>
                      <Button
                        onClick={cancelImageCrop}
                        className="btn btn-danger"
                        color="#ff0000"
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ImageComponentLeft;
