import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { AdminNavbar, AdminFooter, Sidebar } from "../config/Components";
import { useSelector } from "react-redux";
import routes from "../routes.js";

const Admin = (props) => {
  const userType = useSelector((store) => store.getUserType);
  const mainContent = React.useRef(null);
  const location = useLocation();
  const permissions = useSelector((state) => state.getUserRolePermission);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const checkPermission = (rl) => {
    return permissions && permissions.find((per) => per.role_name === rl);
  };
  const getRoutes = (routes) => {
    if (!localStorage.getItem("token")) {
      props.history.push("/");
    }
    return routes.map((prop, key) => {
      // parent
      if (prop.hasOwnProperty("role") && !prop.hasOwnProperty("sub")) {
        if (checkPermission(prop.role)) {
          prop.is_menu = true;
        } else if (checkPermission(prop.role) && prop.hasOwnProperty("sub")) {
          prop.is_menu = false;
        } else {
          prop.is_menu = false;
        }
      }

      //children
      if (prop.hasOwnProperty("submenue") && prop.submenue.length > 0) {
        for (let i = 0; i < prop.submenue.length; i++) {
          if (prop.submenue[i].hasOwnProperty("role")) {
            if (checkPermission(prop.submenue[i].role)) {
              prop.submenue[i].is_menu = true;
            } else {
              prop.submenue[i].is_menu = false;
            }
          } else {
            if (userType == 2) {
              prop.submenue[i].is_menu = false;
            } else {
              prop.submenue[i].is_menu = true;
            }
          }
        }
      }

      if (prop.layout === "/") {
        // if (prop.layout === "/admin") {
        let global = { ...props };
        if (typeof prop.submenue !== "undefined") {
          return prop.submenue.map((sub, k) => {
            let Comp = sub.component;
            return (
              <Route
                path={sub.layout + sub.path}
                key={k}
                render={(props) => {
                  return prop.hasOwnProperty("role") ? (
                    checkPermission(prop.role) !== undefined ? (
                      <Comp
                        {...props}
                        {...global}
                        permissions={permissions}
                        checkPermission={checkPermission}
                      />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/",
                        }}
                      />
                    )
                  ) : (
                    <Comp
                      {...props}
                      {...global}
                      permissions={permissions}
                      checkPermission={checkPermission}
                    />
                  );
                }}
              />
            );
          });
        } else {
          let Comp = prop.component;
          return (
            <Route
              path={prop.layout + prop.path}
              render={(props) => {
                return prop.hasOwnProperty("role") ? (
                  checkPermission(prop.role) !== undefined ? (
                    <Comp
                      {...props}
                      {...global}
                      permissions={permissions}
                      checkPermission={checkPermission}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/",
                      }}
                    />
                  )
                ) : (
                  <Comp
                    {...props}
                    {...global}
                    permissions={permissions}
                    checkPermission={checkPermission}
                  />
                );
              }}
              key={key}
            />
          );
        }
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar {...props} routes={routes} />
      <div className="main-content" ref={mainContent}>
        <div className="main-top-header">
          <div className="main-top-header-left">
            <a href="/dashboard">
              {/* <a href="/admin/dashboard"> */}
              <img
                alt="reiprintmail"
                src={require("../assets/img/logo.png").default}
              />
            </a>
          </div>
          <div className="main-top-header-right">
            <AdminNavbar
              {...props}
              brandText={getBrandText(props.location.pathname)}
            />
          </div>
        </div>

        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/dashboard" />
          {/* <Redirect from="*" to="/admin/dashboard" /> */}
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
