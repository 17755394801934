import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const AffilateModal = (props) => {
  const [modal, setModal] = useState(false);
  const [checked, setChecked] = useState([]);
  const [is_all_affiliate, setAllAffiliates] = useState(props.is_all_affiliate);
  const [product_info, setProductInfo] = useState(props.product_info);
  const [bundle_info, setBundleInfo] = useState(props.bundle_info);

  const toggle = () => {
    setModal(!modal);
    props.toggle(modal);
  };

  useEffect(() => {
    setModal(props.modal);
    setChecked(props.affiliate);
    setAllAffiliates(props.is_all_affiliate);
  }, [props.modal, props.affiliate, props.is_all_affiliate]);

  const getAffiliateValues = () => {
    let array = [];
    let checkboxes = document.querySelectorAll(
      "input.affiliate-cls[type=checkbox]:checked"
    );

    for (let i = 0; i < checkboxes.length; i++) {
      array.push(checkboxes[i].value);
    }

    if (array.length !== checked.length) {
      setAllAffiliates("0");
    }
    return array;
  };

  const checkAll = (e) => {
    let array = [];
    let checkboxes = document.querySelectorAll(
      "input.affiliate-cls[type=checkbox]"
    );

    for (let i = 0; i < checkboxes.length; i++) {
      if (e.target.checked) {
        checkboxes[i].checked = true;
        array.push(checkboxes[i].value);
      } else {
        checkboxes[i].checked = false;
      }
    }

    setChecked(array);
    if (e.target.checked) {
      setAllAffiliates("1");
    } else {
      setAllAffiliates("0");
    }
  };

  const ProductFunction = () => {
    toggle();
    props.setProductInfo({
      ...product_info,
      is_all_affiliate: is_all_affiliate,
      affiliate: getAffiliateValues(),
    });
  };

  const BundleFunction = () => {
    toggle();
    props.setBundleInfo({
      ...bundle_info,
      is_all_affiliate: is_all_affiliate,
      affiliate: getAffiliateValues(),
    });
  };

  return (
    <Modal isOpen={modal}>
      <ModalHeader toggle={toggle}>Affiliate</ModalHeader>
      <ModalBody>
        <div className="affiliate-popup-block">
          <div className="affiliate-all-list">
            {props.affiliates.map((aff, index) => {
              return (
                <FormGroup check key={index}>
                  <Input
                    type="checkbox"
                    name={aff.affiliate_id.name}
                    id={aff.affiliate_id.name}
                    value={aff.affiliate_id.id}
                    className="affiliate-cls"
                    defaultChecked={
                      checked.includes(aff.affiliate_id.id) ? true : false
                    }
                    onChange={() => {
                      setChecked(getAffiliateValues());
                    }}
                  />
                  <Label for={aff.affiliate_id.name} check>
                    {aff.affiliate_id.name}
                  </Label>
                </FormGroup>
              );
            })}
          </div>
          <div className="select-all-affiliates">
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="is_all_affiliate"
                  onChange={checkAll}
                  // checked={
                  //   props.affiliates.length === checked.length ? true : false
                  // }
                  checked={is_all_affiliate === "1" ? true : false}
                />{" "}
                Select All Affiliates
              </Label>
            </FormGroup>
          </div>
          <div className="submit-button-block">
            <Button
              onClick={!props.is_bundle ? ProductFunction : BundleFunction}
            >
              Next
            </Button>{" "}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AffilateModal;
