import React, { useState, useEffect, useMemo } from "react";
import { BreadCrumb } from "../../config/Components";
import "./Orders.css";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import "../../assets/css/data-table.css";
import { API_GET_PRODUCTION_BATCH } from "../../config/Api";
import { ProductionBatchDetails } from "../../config/Components";
import moment from "moment"

const ProductionBatches = (props) => {
  const [productionBatch, setProductionBatch] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [rows, setRows] = useState(10);
  const userType = useSelector((store) => store.getUserType);
  const searchParam = [
    "batch_number",
    "category",
    "envelope",
    "ink",
    "paper",
    "postage",
    "quantity",
    "size",
  ];

  useEffect(() => {
    fetchProductionBatch();
  }, []);

  const fetchProductionBatch = () => {
    props
      .callRequest("GET", API_GET_PRODUCTION_BATCH, true, null)
      .then((res) => {
        setProductionBatch(res.data);
      });
  };

  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return moment(d).format('MM/DD/YYYY');
    // return d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear();
  };

  const columns = [
    {
      name: "Batch ID",
      selector: "batch_number",
      sortable: true,
    },
    {
      name: "Category",
      selector: "category",
      sortable: true,
    },
    {
      name: "Size",
      selector: "size",
      sortable: true,
    },
    {
      name: "Paper Description",
      selector: "paper",
      sortable: true,
    },
    {
      name: "Ink",
      selector: "ink",
      sortable: true,
    },
    {
      name: "Postage",
      selector: "postage",
      sortable: true,
    },
    {
      name: "Envelope Description",
      selector: "envelope",
      sortable: true,
    },
    {
      name: "Quantity",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "Date In",
      selector: "date_in",
      sortable: true,
      cell: (row) => {
        return dateFormater(row.date_in);
      },
    },
    {
      name: "Date Due",
      selector: "due_date",
      sortable: true,
      cell: (row) => {
        return row.due_date == "" || row.due_date == null
          ? "--/--/--"
          : dateFormater(row.due_date);
      },
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <div>
          {row.status == 0
            ? "Ready for production"
            : row.status == 1
            ? "Completed"
            : row.status == 2
            ? "Cancelled"
            : row.status == 3
            ? "Preview"
            : "Deleted"}
        </div>
      ),
    },
    {
      name: userType && userType == 2 ? null : "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) =>
        userType && userType == 2 ? null : (
          <div className="action-div batch-action">
            <span className="csv-download-btn">
              <a title="Download csv" href={row.csv_file} download>
                <i className="fas fa-file-download"></i>
              </a>
            </span>
            <span className="csv-download-btn">
              <a title="Download Zip" href={row.vdp_zip_file} download>
                <i className="fas fa-file-archive"></i>
              </a>
            </span>
            <span className="csv-download-btn">
              <a title="Download html" href={row.vdp_html_zip_file} download>
                <i className="fas fa-file-code"></i>
              </a>
            </span>
          </div>
        ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Product"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          autoComplete="off"
        />
      </FormGroup>
    );
  }, [filterText]);

  const export_column = [
    "batch_number",
    "category",
    "envelope",
    "ink",
    "paper",
    "postage",
    "quantity",
    "size",
  ];

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      {/* {userType && userType == 2 ? null : (
                <Button onClick={() => props.history.push("/order-batching")}>
                    <i className="ni ni-fat-add"></i> Create Batch
                </Button>
            )}
            <UncontrolledDropdown>
                <DropdownToggle caret>
                    <i className="bi-filter"></i> Filter
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem>Filter Name 1</DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
            {userType && userType == 2 ? null : (
                <UncontrolledDropdown disabled={batchPreview.length > 0 ? false : true}>
                    <DropdownToggle caret>
                        <i className="ni ni-ungroup"></i> Export
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem onClick={(e) => onExportView(e.target.value)}>
                            Export View
                        </DropdownItem>
                        <DropdownItem onClick={(e) => onExport(e.target.value)}>
                            Export All
                        </DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )} */}
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            productionBatch,
            export_column,
            props.generateUniqueId()
          )
        }
        onExportView={() =>
          DataTableSettings.downloadCSV(
            productionBatch.slice(0, rows),
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [productionBatch]
  );

  return (
    <div>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order Batching"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            Production Batches
          </h1>
        </div>
        <div className="listingtable-main-block order-tabile-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                <DataTable
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    productionBatch,
                    searchParam,
                    filterText
                  )}
                  pagination
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  actions={actionsExport}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  expandableRows
                  expandableRowsComponent={
                    <ProductionBatchDetails
                      {...props}
                      fetchProductionBatch={fetchProductionBatch}
                    />
                  }
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default ProductionBatches;
