import React, { useState, useEffect } from 'react';
import Swal from "sweetalert2";
import {
    Button
} from "reactstrap";
import {
    API_GET_PRODUCTION_BATCH_DETAILS,
    API_COMPLETE_BATCH,
    API_CANCEL_BATCH
} from "../../config/Api";
import moment from "moment"

const ProductionBatchDetails = (props) => {
    const [productionBatcheDetail, setProductionBatcheDetail] = useState([]);

    useEffect(() => {
        getProductionBatcheDetail();
    }, []);

    const dateFormater = (inputValue) => {
        let d = new Date(inputValue * 1000);
        return moment(d).format('MM/DD/YYYY')
        // return (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear();
    };

    const getProductionBatcheDetail = () => {
        let url = API_GET_PRODUCTION_BATCH_DETAILS + props.data.batch_id;
        props.callRequest("GET", url, true, null).then((res) => {
            setProductionBatcheDetail(res.data);
            console.log(res.data)
        });
    };

    const onCompleteBatch = () => {
        props.callRequest("POST", API_COMPLETE_BATCH, true, {
            batch_id: props.data.batch_id
        }).then((res) => {
            if (res.status) {
                props.fetchProductionBatch();
                Swal.fire("Batch has been completed.", "", "success");
            }
        });
    };

    const onCancelBatch = () => {
        Swal.fire({
            icon: "question",
            title: "Do you want to cancel batch?",
            showDenyButton: true,
            confirmButtonText: `Yes`,
            denyButtonText: `Cancel`,
        }).then((result) => {
            if (result.isConfirmed) {
                props.callRequest("POST", API_CANCEL_BATCH, true, {
                    batch_id: props.data.batch_id
                }).then((res) => {
                    if (res.status) {
                        props.fetchProductionBatch();
                        Swal.fire("Batch has been cancelled.", "", "success");
                    }
                });
            }
        });
    };

    return (
        <>
            <div className="complete-batch-btn">
                <Button onClick={onCompleteBatch}>Complete Batch<i className="ni ni-bold-right"></i></Button>
                <Button className="cancel-batch-btn" onClick={onCancelBatch}>Cancel Batch<i className="ni ni-bold-right"></i></Button>
            </div>
            <div className="orderlist-details-block">
                {productionBatcheDetail &&
                    productionBatcheDetail.map((item, index) => {
                        return (
                            <div className="orderlist-item-box" key={index}>
                                <div className="list-item orderid">
                                    <h5 className="item-titel">Order ID</h5>
                                    <p>{item.orderid}</p>
                                </div>
                                <div className="list-item drop-name">
                                    <h5 className="item-titel">Drop</h5>
                                    <p>{item.drop}</p>
                                </div>
                                <div className="list-item username">
                                    <h5 className="item-titel">Username</h5>
                                    <p>{item.username}</p>
                                </div>
                                <div className="list-item template-name">
                                    <h5 className="item-titel">Template Name</h5>
                                    <p>{item.template}</p>
                                </div>
                                <div className="list-item quantity">
                                    <h5 className="item-titel">Quantity</h5>
                                    <p>{item.quantity}</p>
                                </div>
                                <div className="list-item date-in">
                                    <h5 className="item-titel">Date In</h5>
                                    <p>{dateFormater(item.date_in)}</p>
                                </div>
                                <div className="list-item date-due">
                                    <h5 className="item-titel">Date Due</h5>
                                    <p>{item.due_date == "" || item.due_date == null ? "--/--/--" : dateFormater(item.due_date)}</p>
                                </div>
                                <div className="list-item status">
                                    <h5 className="item-titel">Status</h5>
                                    <p>{item.status}</p>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    )
};

export default ProductionBatchDetails;
