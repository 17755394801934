import React, { useState, useEffect } from "react";
import "../Users/UserForm.css";
import { Button, Table, Input, Label } from "reactstrap";

const ProductPrice = (props) => {
  const initPriceInfo = {
    tier1_price:
      props.editPriceRange.tier1_price !== undefined &&
      props.editPriceRange.tier1_price !== ""
        ? props.editPriceRange.tier1_price
        : "",
    tier2_price:
      props.editPriceRange.tier2_price !== ""
        ? props.editPriceRange.tier2_price
        : "",
    tier3_price:
      props.editPriceRange.tier3_price !== ""
        ? props.editPriceRange.tier3_price
        : "",
    probate_tier:
      props.editPriceRange.probate_tier !== ""
        ? props.editPriceRange.probate_tier
        : "",
    priceArr: [
      // {
      //   index: 1,
      //   label: "1",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      //   value4: "",
      // },
      // {
      //   index: 2,
      //   label: "2",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      //   value4: "",
      // },
      // {
      //   index: 3,
      //   label: "3",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      //   value4: "",
      // },
      // { index: 4, label: "4", value1: "", value2: "", value3: "", value4: "" },
      // { index: 5, label: "5", value1: "", value2: "", value3: "", value4: "" },
      // { index: 6, label: "6", value1: "", value2: "", value3: "", value4: "" },
      // { index: 7, label: "7", value1: "", value2: "", value3: "", value4: "" },
      // { index: 8, label: "8", value1: "", value2: "", value3: "", value4: "" },
      // { index: 9, label: "9", value1: "", value2: "", value3: "", value4: "" },
      // {
      //   index: 10,
      //   label: "10",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      //   value4: "",
      // },
      // {
      //   index: 11,
      //   label: "10 > - < 25",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      //   value4: "",
      // },
      // {
      //   index: 12,
      //   label: "25 >= - < 50",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      //   value4: "",
      // },
      // {
      //   index: 13,
      //   label: "50 >= - < 100",
      //   value1: "",
      //   value2: "",
      //   value3: "",
      //   value4: "",
      // },
      {
        index: 1,
        label: "1 >= - < 200",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 2,
        label: "200 >= - < 350",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 3,
        label: "350 >= - < 500",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 4,
        label: "500 >= - < 1000",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 5,
        label: "1000 >= - < 2500",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 6,
        label: "2500 >= - < 5000",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 7,
        label: "5000 >= - < 10000",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 8,
        label: "10000 >= - < 25000",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 9,
        label: "25000 >= - < 50000",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
      {
        index: 10,
        label: "50000+",
        value1: "",
        value2: "",
        value3: "",
        value4: "",
      },
    ],
  };
  const [priceRange, setPriceRange] = useState(initPriceInfo);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.editPriceRange.price_id === 1) {
      let price_arr1 = priceRange.tier1_price.split("|");
      let price_arr2 = priceRange.tier2_price.split("|");
      let price_arr3 = priceRange.tier3_price.split("|");
      let probate_tier = priceRange.probate_tier.split("|");

      let prd_inf = { ...priceRange };
      for (let i = 0; i < price_arr1.length; i++) {
        prd_inf.priceArr[i].value1 = price_arr1[i];
      }
      for (let i = 0; i < price_arr2.length; i++) {
        prd_inf.priceArr[i].value2 = price_arr2[i];
      }
      for (let i = 0; i < price_arr3.length; i++) {
        prd_inf.priceArr[i].value3 = price_arr3[i];
      }
      for (let i = 0; i < probate_tier.length; i++) {
        prd_inf.priceArr[i].value4 = probate_tier[i];
      }
      setPriceRange({ ...priceRange, prd_inf });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  const changeHandler = (e, index) => {
    if (index !== "undefined") {
      priceRange.priceArr[index][e.target.name] = e.target.value;
    }
    if (e.target.name === "value1") {
      let price_str = "";
      for (let i = 0; i < priceRange.priceArr.length; i++) {
        price_str += priceRange.priceArr[i].value1 + "|";
      }
      price_str = price_str.slice(0, -1);

      setPriceRange({ ...priceRange, tier1_price: price_str });
    }
    if (e.target.name === "value2") {
      let price_str = "";
      for (let i = 0; i < priceRange.priceArr.length; i++) {
        price_str += priceRange.priceArr[i].value2 + "|";
      }
      price_str = price_str.slice(0, -1);

      setPriceRange({ ...priceRange, tier2_price: price_str });
    }
    if (e.target.name === "value3") {
      let price_str = "";
      for (let i = 0; i < priceRange.priceArr.length; i++) {
        price_str += priceRange.priceArr[i].value3 + "|";
      }
      price_str = price_str.slice(0, -1);

      setPriceRange({ ...priceRange, tier3_price: price_str });
    }
    if (e.target.name === "value4") {
      let price_str = "";
      for (let i = 0; i < priceRange.priceArr.length; i++) {
        price_str += priceRange.priceArr[i].value4 + "|";
      }
      price_str = price_str.slice(0, -1);

      setPriceRange({ ...priceRange, probate_tier: price_str });
    }
  };

  const validateForm = () => {
    let { tier1_price, tier2_price, tier3_price, probate_tier } = priceRange;

    let formIsValid = true;
    let errors = {};

    let price1_flag = true;
    if (tier1_price) {
      let price_arr = tier1_price.split("|");
      for (let i = 0; i < price_arr.length; i++) {
        if (!price_arr[i]) {
          price1_flag = false;
        }
      }
    } else {
      price1_flag = false;
    }

    if (!price1_flag) {
      formIsValid = false;
      errors["price_range1"] = "This field is mandatory";
    }
    // let price2_flag = true;
    // if (tier2_price) {
    //   let price_arr = tier2_price.split("|");
    //   for (let i = 0; i < price_arr.length; i++) {
    //     if (!price_arr[i]) {
    //       price2_flag = false;
    //     }
    //   }
    // } else {
    //   price2_flag = false;
    // }

    // if (!price2_flag) {
    //   formIsValid = false;
    //   errors["price_range2"] = "This field is mandatory";
    // }
    // let price3_flag = true;
    // if (tier3_price) {
    //   let price_arr = tier3_price.split("|");
    //   for (let i = 0; i < price_arr.length; i++) {
    //     if (!price_arr[i]) {
    //       price3_flag = false;
    //     }
    //   }
    // } else {
    //   price3_flag = false;
    // }

    // if (!price3_flag) {
    //   formIsValid = false;
    //   errors["price_range3"] = "This field is mandatory";
    // }
    // let price4_flag = true;
    // if (probate_tier) {
    //   let price_arr = probate_tier.split("|");
    //   for (let i = 0; i < price_arr.length; i++) {
    //     if (!price_arr[i]) {
    //       price4_flag = false;
    //     }
    //   }
    // } else {
    //   price4_flag = false;
    // }

    // if (!price4_flag) {
    //   formIsValid = false;
    //   errors["probate_tier"] = "This field is mandatory";
    // }
    setErrors(errors);
    return formIsValid;
  };
  const receivePriceRange = (priceRange) => {
    props.receivePriceRange(priceRange);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return false;
    }
    let price2 =
      priceRange.tier2_price != "" ? priceRange.tier2_price : "|||||||||";
    let price3 =
      priceRange.tier3_price !== "" ? priceRange.tier3_price : "|||||||||";
    let price4 =
      priceRange.probate_tier !== "" ? priceRange.probate_tier : "|||||||||";
    setPriceRange({
      ...priceRange,
      tier2_price: price2,
      tier3_price: price3,
      probate_tier: price4,
    });
    receivePriceRange({
      ...priceRange,
      tier2_price: price2,
      tier3_price: price3,
      probate_tier: price4,
    });
    props.modalToggle();
  };

  const handleClose = () => {
    props.modalToggle();
  };

  return (
    <>
      <main role="main" className="pt-3 px-4 scroll-bar">
        <Table className="popup-form-block">
          <thead>
            <tr className="bg-primary">
              <th className="text-white">
                <b>Quantity Range</b>
              </th>
              <th className="text-white">
                <b>Tier 1</b>
              </th>
              <th className="text-white">
                <b>Tier 2</b>
              </th>
              <th className="text-white">
                <b>Tier 3</b>
              </th>
              <th className="text-white">
                <b>
                  Probate Tier &nbsp;
                  <button
                    className="float-right bg-primary"
                    onClick={handleClose}
                  >
                    <i className="fas fa-window-close"></i>
                  </button>
                </b>
              </th>
            </tr>
          </thead>
          <tbody>
            {priceRange.priceArr.map((prc, index) => {
              return (
                <tr key={prc.index}>
                  <td>{prc.label}</td>
                  {/* <Label>
                  <span className="text-danger">*</span>
                </Label> */}
                  <td>
                    <Input
                      className="text-dark form-control box-size"
                      type="text"
                      id={prc.label}
                      autoComplete="off"
                      name="value1"
                      placeholder={prc.label}
                      onChange={(e) => changeHandler(e, index)}
                      value={prc.value1}
                    />
                    <span className="red_err">{errors.price_range1}</span>
                  </td>

                  <td>
                    <Input
                      className="text-dark form-control box-size"
                      type="text"
                      id={prc.label}
                      autoComplete="off"
                      name="value2"
                      placeholder={prc.label}
                      onChange={(e) => changeHandler(e, index)}
                      value={prc.value2}
                    />
                    <span className="red_err">{errors.price_range2}</span>
                  </td>
                  <td>
                    <Input
                      className="text-dark form-control box-size"
                      type="text"
                      id={prc.label}
                      name="value3"
                      autoComplete="off"
                      placeholder={prc.label}
                      onChange={(e) => changeHandler(e, index)}
                      value={prc.value3}
                    />
                    <span className="red_err">{errors.price_range3}</span>
                  </td>
                  <td>
                    <Input
                      className="text-dark form-control box-size"
                      type="text"
                      id={prc.label}
                      autoComplete="off"
                      name="value4"
                      placeholder={prc.label}
                      onChange={(e) => changeHandler(e, index)}
                      value={prc.value4}
                    />
                    <span className="red_err">{errors.probate_tier}</span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Button onClick={handleClose}>cancel</Button>
        <Button color="danger" onClick={handleSubmit}>
          save
        </Button>
      </main>
    </>
  );
};

export default ProductPrice;
