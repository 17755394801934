import React from 'react';
import { BreadCrumb } from "../../config/Components";
import Swal from "sweetalert2";
import "./Orders.css";
import {
    Button,
    Card,
    CardHeader,
    CardTitle,
    CardBody,
    Form,
} from "reactstrap";
import {
    API_BATCH_CREATE
} from "../../config/Api";

const OrderBatching = (props) => {
    const handleSubmit = (event) => {
        event.preventDefault();
        props
            .callRequest("POST", API_BATCH_CREATE, true)
            .then((res) => {
                if (res.status) {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: "top-end",
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener("mouseenter", Swal.stopTimer);
                            toast.addEventListener("mouseleave", Swal.resumeTimer);
                        },
                    });
                    Toast.fire({
                        icon: "success",
                        title: "Order batch processing has been started.",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <main role="main" className="pt-3 px-4">
                <div className="main-heading-block">
                    <BreadCrumb links={["Order Batching"]} />

                    <h1 className="header-title">
                        <span className="header-icon">
                            <i className="ni ni-cart"></i>
                        </span>
                        Order Batching
                    </h1>
                </div>
                <div className="order-main-block">
                    <div className="row">
                        <div className="col-sm-12">
                            <Card>
                                <CardHeader>
                                    <CardTitle tag="h2">
                                        Create Batch Preview
                                    </CardTitle>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="submit-button-block create-batch-btn">
                                            <Button onClick={handleSubmit}>Create Batch<i className="ni ni-bold-right"></i></Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default OrderBatching;