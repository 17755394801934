import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../config/Components";
import "./Orders.css";
// var converter = require("number-to-words");
import converter from "number-to-words";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardSubtitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Swal from "sweetalert2";

import {
  API_GET_AFFILIATE,
  API_GET_USER_PERMISSION,
  API_GET_CAMPAIGN,
  API_USERS,
  API_POST_CAMPAIGN,
  API_GET_MAILINGLIST,
  API_SEARCH_USER,
  API_GET_MAILINGLIST_BY_USER,
  API_GET_ORDER_DETAIL,
  API_ATTRIBUTES,
  API_COPY_CAMPAIGN_DATA_TO_MAILING_DATES_RECORD,
  API_GET_SALES_REP,
  API_GET_ATTRIBUTE_TYPE_LIST,
  API_CART_ORDER_MULTIPLE_UPLOAD_FILES,
  DELETE_CART_ORDER_UPLOAD_FILE,
} from "../../config/Api";
import CommissionOverrideForm from "./CommissionOverrideForm";
import CrmContacts from "../CRM/CrmContacts";
import UserFrom from "../Users/UserForm";
import SelectUserForm from "../Subscriptions/SelectUserForm";
import CreateOrderApproval from "./CreateOrderApproval";
import AddProductOrder from "./AddProductOrder";
import ProductDetail from "./ProductDetail";
import AddMailingList from "./AddMailingList";
import { withRouter } from "react-router-dom";
import { ModalTitle } from "react-bootstrap";

const CreateOrder = (props) => {
  const [orderId, setOrderId] = useState(
    "orderId" in props.match.params && props.match.params.orderId
  );
  const [orderStatus, setOrderStatus] = useState(
    "status" in props.match.params && props.match.params.status
  );
  const [orderApproval, setOrderApproval] = useState(false);
  const [modal, setModal] = useState(false);
  const [Affiliate, setAffiliate] = useState([]);
  const [mailing_list, setMailingList] = useState(false);
  const [selectedUserDetail, setSelectedUserDetail] = useState();
  const [savedCampaignData, setSavedCampaignData] = useState();
  const [createUserModal, setCreateUserModal] = useState(false);
  const [permissions, setPermissions] = useState();
  const [campaigns, setCampaigns] = useState([]);
  const [mailingLists, setMailingLists] = useState([]);
  const [addMailingListModal, setaddMailingListModal] = useState(false);
  const [addProductOrderModal, setAddProductOrderModal] = useState(false);
  const [productDetailModal, setProductDetailModal] = useState(false);
  const [userList, setUserList] = useState();
  const [showCsvMap, setShowCsvMap] = useState(false);
  const [productList, setProductList] = useState([]);
  const [editProductFlag, setEditProductFlag] = useState(false);
  const [edittedProductList, setEdittedProductList] = useState({});
  const [image, setImage] = useState();
  const [childCategory, setChildCategory] = useState();
  const [childCategoryId, setChildCategoryId] = useState();
  const [singleProduct, setSingleProduct] = useState([]);
  const [SinglePrd, setSinglePrd] = useState();
  const [receivedPrd, setReceivedPrd] = useState([]);
  const [users, setUsers] = useState([]);
  const [profile, setProfile] = useState();
  const [campaignDetail, setCampaignDetail] = useState({
    campaign_id: "",
    campaign_file_path: "",
    uplodedFile: "",
    campaign_name: null,
    campaign_target: 1,
    campaign_type: 0,
    Probate: 2,
    selectedUser: null,
    user: null,
    is_proceed_without_mailinglist: 0,
  });
  const [mailingListDetail, setMailingListDetail] = useState({
    mailinglist_id: "",
    mailinglist_file_path: "",
    mailinglist_name: "",
    total_records: 0,
  });
  const [addMailList, setAddMailList] = useState({
    mailinglist_attr_id: "",
    desired_quantity: 0,
    mailing_list_type: "",
    price_amount: "",
  });

  const [prdId, setPrdId] = useState(0);
  const [draftOrderDetail, setDraftOrderDetail] = useState();
  const [salesRep, setSalesRep] = useState([]);

  const [attributeTypes, setAttributeTypes] = useState({
    postage_id: null,
    ink_type_id: null,
    paper_type_id: null,
    envelope_type_id: null,
    accuTXT_type_id: null,
    accuPIX_type_id: null,
  });

  const [UploadedFiles, setUploadedFiles] = useState([]);
  useEffect(() => {
    if (UploadedFiles && UploadedFiles.length > 0) {
      setCampaignDetail({
        ...campaignDetail,
        is_proceed_without_mailinglist: 0,
        schedule_consultation: 0,
      });
    }
  }, [UploadedFiles]);

  React.useEffect(() => {
    props.callRequest("GET", API_GET_ATTRIBUTE_TYPE_LIST).then((res) => {
      var tempAttrTypeId = {
        postage_id: null,
        ink_type_id: null,
        paper_type_id: null,
        envelope_type_id: null,
        accuTXT_type_id: null,
        accuPIX_type_id: null,
      };

      for (let i = 0; i < res.data.length; i++) {
        if (res.data[i]["type_name"].toLowerCase() == "postage") {
          tempAttrTypeId.postage_id = res.data[i]["type_id"];
        }

        if (res.data[i]["type_name"].toLowerCase().includes("ink")) {
          tempAttrTypeId.ink_type_id = res.data[i]["type_id"];
        }

        if (res.data[i]["type_name"].toLowerCase().includes("paper")) {
          tempAttrTypeId.paper_type_id = res.data[i]["type_id"];
        }

        if (res.data[i]["type_name"].toLowerCase().includes("envelope")) {
          tempAttrTypeId.envelope_type_id = res.data[i]["type_id"];
        }

        //accuTXT_type_id

        if (res.data[i]["type_name"].toLowerCase().includes("accutxt")) {
          tempAttrTypeId.accuTXT_type_id = res.data[i]["type_id"];
        }

        if (res.data[i]["type_name"].toLowerCase().includes("accupix")) {
          tempAttrTypeId.accuPIX_type_id = res.data[i]["type_id"];
        }
      }
      setAttributeTypes(tempAttrTypeId);
    });
  }, []);

  useEffect(() => {
    getOrderDetail();
    fetchAffiliates();
    getUserList();
    fetchUserRolePermission();
    // fetchAttributesType();
  }, []);

  useEffect(() => {
    fetchAttributesType();
  }, [attributeTypes]);

  const getOrderDetail = () => {
    if (orderId) {
      props
        .callRequest("GET", API_GET_ORDER_DETAIL + orderId, true)
        .then((res) => {
          setSavedCampaignData({
            ...res.data["OrderDetails"]["campaign"],
            csv_file:
              res.data["OrderDetails"]["campaign"]["campaign_file_path"],
          });
          for (let i = 0; i < res.data.Product.length; i++) {
            res.data.Product[i]["category_id"] =
              "sub_category_id" in res.data.Product[i]
                ? res.data.Product[i]["sub_category_id"]
                : 0;
            res.data.Product[i]["category_name"] =
              "sub_category_name" in res.data.Product[i]
                ? res.data.Product[i]["sub_category_name"]
                : "";
          }
          setDraftOrderDetail(res.data);
          setSelectedUserDetail({ ...res.data["OrderDetails"]["user"] });

          setProductList([...res.data["Product"].reverse()]);
          setAddMailList(
            res.data["Mailinglist"] && res.data["Mailinglist"].length > 0
              ? {
                price_amount: parseFloat(
                  res.data["Mailinglist"][0].per_mailer
                ),
                desired_quantity: res.data["Mailinglist"][0].mailingqty,
                ...res.data["Mailinglist"][0],
              }
              : {
                mailinglist_attr_id: "",
                desired_quantity: 0,
                mailing_list_type: "",
                price_amount: "",
              }
          );
          setOrderApproval(true);
          setUploadedFiles(res.data["UploadFiles"]);
          //setSingleProduct(res.data["Product"][0]);
        });
    }
  };

  // useEffect(() => {
  //   if (
  //     editProductFlag === true &&
  //     Object.keys(edittedProductList).length !== 0
  //   ) {
  //     index();
  //   }
  // }, [editProductFlag, edittedProductList]);
  const toggle = () => setModal(!modal);

  const addMailingList = (mailing_list) => {
    //setAddMailList(mailing_list);
    setaddMailingListModal(!addMailingListModal);
  };

  const setMailingListData = (mailing_list) => {
    console.log(mailing_list, "mailing_list");
    setAddMailList(mailing_list);
    mailing_list && setaddMailingListModal(!addMailingListModal);
  };

  const addProductOrder = () => setAddProductOrderModal(!addProductOrderModal);
  const chooseDesignDetails = () => setProductDetailModal(!productDetailModal);
  const oppupCreateUser = () => setCreateUserModal(!createUserModal);

  const toggleChange = (e) => {
    let campaign = { ...campaignDetail };
    campaign.is_proceed_without_mailinglist = e.target.checked ? 1 : 0;
    setCampaignDetail(campaign);

    // setMailingList(!is_proceed_without_mailinglist);
  };

  const toggleCommissions = () => {
    toggle();
  };

  const handleNext = (value = false) => {
    let mailinglist_attr_id =
      "mailinglist_attr_id" in addMailList && addMailList.mailinglist_attr_id
        ? addMailList.mailinglist_attr_id
        : "";
    let desired_quantity =
      "mailinglist_attr_id" in addMailList && addMailList.desired_quantity
        ? addMailList.desired_quantity
        : "";

    // if (
    //   campaignDetail.is_proceed_without_mailinglist == 1 &&
    //   (desired_quantity == "" || desired_quantity < 1)
    // ) {
    //   Swal.fire(
    //     "Validation!",
    //     "Please enter Mailing List 'Quantity', if you want to Proceed without a mailing list",
    //     "error"
    //   );
    //   return false;
    // }

    if (
      UploadedFiles.length < 1 &&
      !mailingListDetail.mailinglist_id &&
      campaignDetail.is_proceed_without_mailinglist == 0
    ) {
      Swal.fire(
        "Validation!",
        "Please upload an CSV or select a valid mailing list, Or Proceed without a mailing list.",
        "error"
      );
      return false;
    }

    if (validateForm() != true) return false;
    createCampaign();

    // setOrderApproval(value);
  };

  const upload_csv = async (event) => {
    event.preventDefault();

    if (validateForm() != true) {
      document.getElementById("uplodedFile").value = "";
      return false;
    }
    let fileTypeArr = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    let type = event.target.files[0]["type"];

    if (fileTypeArr.indexOf(type) < 0) {
      Swal.fire(
        "Validation!",
        "Please select a valid csv or excel file",
        "error"
      );
      return false;
    }

    Swal.fire({
      title: "File is uploading...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let folderName = await getFolderName();
    let fData = new FormData();
    for (let i = 0; i < event.target.files.length; i++) {
      // fData.append("uploaded_files["+i+"][filename]", folderName+"_mailinglist_"+(i+1));
      fData.append("upload_file", event.target.files[i]);
    }

    fData.append("folder_name", folderName);

    props
      .callRequest("POST", API_CART_ORDER_MULTIPLE_UPLOAD_FILES, true, fData)
      .then(async (res) => {
        setUploadedFiles([...UploadedFiles, ...res.data]);
        Swal.close();
        document.getElementById("uplodedFile").value = null;
      })
      .catch((e) => {
        console.log("Error=>>>>>>>>>>>>>>>>>", e);
        Swal.close();
        let er =
          e.response.data &&
            "Message" in e.response.data &&
            e.response.data.Message
            ? e.response.data.Message
            : "Something went wrong, while processing your file.";
        console.log(er);
        Swal.fire("Error", er, "error");
      });

    // let campDetail = { ...campaignDetail };
    // campDetail.uplodedFile = event.target.files[0];
    // setCampaignDetail({ ...campDetail });
  };

  const getFolderName = async () => {
    let folderName = new Date().valueOf();
    try {
      if (UploadedFiles.length > 0) {
        //Add a logic to extract the folder name
        let filepath = UploadedFiles[0].filepath;
        let temp = filepath.split("/");
        folderName = temp[temp.length - 2];
      }
      return folderName;
    } catch (e) {
      return folderName;
    }
  };

  const deleteFile = (filePath, index) => {
    try {
      let pathArr = filePath.split("/");
      let temUploadedFiles = UploadedFiles;
      // ADDED A CONFIRMATION BEFORE DELETING A FILE ----------
      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this item?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          props
            .callRequest("POST", DELETE_CART_ORDER_UPLOAD_FILE, true, {
              folder_name: pathArr[pathArr.length - 2],
              filename: pathArr[pathArr.length - 1],
            })
            .then(async (res) => {
              Swal.close();
              Swal.fire("Success", "File deleted successfully", "success");
              temUploadedFiles.splice(index, 1);
              console.log(temUploadedFiles);
              setUploadedFiles([...temUploadedFiles]);
            })
            .catch((e) => {
              Swal.fire(
                "Error",
                "Something went wrong, while processing your file.",
                "error"
              );
            });
        }
      });
    } catch (e) {
      console.log("Delete options", e);
    }
  };

  useEffect(() => {
    /*if (!orderApproval) {
      campaignDetail.uplodedFile && createCampaign();
    }*/
    fetchUsers();
  }, [campaignDetail.uplodedFile]);

  const onChangeAction = (event) => {
    let campDetail = {
      ...campaignDetail,
      [event.target.name]: event.target.value,
    };
    setCampaignDetail({ ...campDetail });
  };

  const onChangeMailingList = (event) => {
    let mailListDetail = {
      ...mailingListDetail,
      [event.target.name]: event.target.value,
    };
    setMailingListDetail({ ...mailListDetail });
  };

  const createCampaign = () => {
    if (validateForm() != true) return false;

    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();

    formData.append("campaign_name", campaignDetail.campaign_name);
    formData.append("campaign_target", campaignDetail.campaign_target);
    formData.append("campaign_type", campaignDetail.campaign_type);
    formData.append("Probate", campaignDetail.Probate);
    formData.append("user", campaignDetail.user);

    if (campaignDetail.is_proceed_without_mailinglist == 1) {
      formData.append(
        "is_proceed_without_mailinglist",
        campaignDetail.is_proceed_without_mailinglist
      );

      let mailinglist_attr_id =
        "mailinglist_attr_id" in addMailList && addMailList.mailinglist_attr_id
          ? addMailList.mailinglist_attr_id
          : "";
      let desired_quantity =
        "mailinglist_attr_id" in addMailList && addMailList.desired_quantity
          ? addMailList.desired_quantity
          : "";
      formData.append("attribute", mailinglist_attr_id);
      formData.append("quantity", desired_quantity);
    } else {
      formData.append("mailinglist_id", mailingListDetail.mailinglist_id);
      campaignDetail.uplodedFile &&
        campaignDetail.uplodedFile.name &&
        formData.append(
          "uplodedFile",
          campaignDetail.uplodedFile,
          campaignDetail.uplodedFile.name
        );
    }

    props
      .callRequest("POST", API_POST_CAMPAIGN, true, formData)
      .then((res) => {
        Swal.close();
        setSavedCampaignData({
          ...campaignDetail,
          ...res.data,
        });

        setOrderApproval(true);

        // if (
        //   campaignDetail.is_proceed_without_mailinglist == 1 ||
        //   mailingListDetail.mailinglist_id
        // ) {
        //   setOrderApproval(true);
        // } else if (
        //   campaignDetail.uplodedFile &&
        //   campaignDetail.uplodedFile.name
        // ) {
        //   setShowCsvMap(true);
        // }
      })
      .catch((er) => {
        Swal.close();
        // console.log(er.response.data.join(','));
        //Swal.fire("Response!", er.response.data.join(","), "error");
      });
  };
  const validateForm = () => {
    let returnVal = true;

    let formEle = {
      campaign_name: "Campaign name is required",
      campaign_target: "Campaign target is required",
      campaign_type: "Campaign type is required",
      //uplodedFile: "Please Select a valid CSV file",
      user: "Please select a valid user",
    };

    for (var it in formEle) {
      if (campaignDetail[it] == null) {
        Swal.fire("Validation!", formEle[it], "error");
        returnVal = false;
        break;
      }
    }

    if (returnVal && productList.length === 0) {
      Swal.fire("Select at least one product!");
      returnVal = false;
    }

    return returnVal;
  };

  const getUserList = () => {
    props.callRequest("GET", API_USERS, true, null).then((res) => {
      let tempData = [];
      if (res.data.length > 0) {
        res.data.map((item) => {
          tempData.push({ ...item, name: item.username, value: item.user_id });
        });
      }
      setUserList(tempData);
      if (selectedUserDetail && selectedUserDetail.user_id) {
        let userDetail = tempData.filter(
          (ele) => ele.user_id == selectedUserDetail.user_id
        )[0];
        setSelectedUserDetail({ ...userDetail });
      }
    });
  };

  const fetchAffiliates = () => {
    props
      .callRequest("GET", API_GET_AFFILIATE, true)
      .then((res) => {
        setAffiliate(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const affUsr = Affiliate.filter((aff) => {
    return (
      aff.affiliate_id.id ==
      (selectedUserDetail && selectedUserDetail.affiliate_id)
    );
  });
  const sendUpdatedProfile = (updatedProfile) => {
    setProfile(updatedProfile);
  };

  const getMailingList = (id) => {
    //API_GET_MAILINGLIST_BY_USER + "?user_id=" + props.getUserInfo().user_id,
    props
      .callRequest("GET", API_GET_MAILINGLIST + "?user_id=" + id, true, null)
      .then((res) => {
        let mailList = res.data.filter((ele) => ele.is_active === "1");
        setMailingLists(mailList);
      });
  };

  const fetchUsers = () => {
    props
      .callRequest("GET", API_SEARCH_USER, true)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const changeSelectedUser = () => {
    setSelectedUserDetail(null);
    setCampaignDetail({ ...campaignDetail, user: null, selectedUser: null });
  };
  // const index = () => {
  //   for (let i = 0; i < productList.length; i++) {
  //     if (
  //       productList[i].prod_id == edittedProductList.prod_id &&
  //       productList[i].sizeid == edittedProductList.sizeid
  //     ) {
  //       console.log(productList[i].prod_id, edittedProductList.prod_id, "a");
  //       console.log(productList[i].sizeid, productList[i].sizeid, "b");
  //       return i;
  //     } else {
  //       console.log(productList[i].prod_id, edittedProductList.prod_id, "c");
  //       console.log(productList[i].sizeid, productList[i].sizeid, "d");
  //     }
  //   }
  // };

  const selectedProduct = (productInfo) => {
    if (productInfo && !("category_id" in productInfo)) {
      productInfo.category_id = childCategoryId;
      productInfo.category_name = childCategory;
    }

    if (editProductFlag === true) {
      setEdittedProductList((prevedittedProductList) => productInfo);
    } else {
      let tempproductList = [...productList];
      // for (let i = 0; i < tempproductList.length; i++) {
      //   if (!"drop" in tempproductList[i]) {
      //     tempproductList[i]["drop"] = "DROP " + (i + 1);
      //   }
      // }
      productInfo.drop =
        "drop" in productInfo && productInfo.drop
          ? productInfo.drop
          : "DROP " + (tempproductList.length + 1);
      productInfo.is_proof_approved =
        "is_proof_approved" in productInfo ? productInfo.is_proof_approved : 0;
      productInfo.is_send_without_return_address =
        "is_send_without_return_address" in productInfo
          ? productInfo.is_send_without_return_address
          : 0;

      if (!"is_lockout" in productInfo) {
        productInfo.is_lockout = 0;
      }

      tempproductList.push({ ...productInfo });
      setProductList([...tempproductList]);
    }
  };
  const edittedProductFlag = (editProduct) => {
    setEditProductFlag(editProduct);
  };
  const resetSavedCampaign = () => {
    // setSavedCampaignData(null);
    // setSelectedUserDetail(null);
    // setCampaignDetail({
    //     campaign_id: '',
    //     campaign_file_path: '',
    //     uplodedFile: '',
    //     campaign_name: null,
    //     campaign_target: null,
    //     campaign_type: null,
    //     Probate: 2,
    //     selectedUser: null,
    //     user: null
    // });
    setShowCsvMap(false);
    setOrderApproval(true);
    //campaign_id, order_id
    processCopyCamDataToMailingDateRecord();
  };

  const refreshList = () => {
    getUserList();
    oppupCreateUser();
  };

  const sendEdittedUser = (user) => {
    fetchUsers();
  };

  const fetchUserRolePermission = () => {
    props
      .callRequest(
        "GET",
        API_GET_USER_PERMISSION + props.getUserInfo().user_id,
        true
      )
      .then((res) => {
        setPermissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editUserPop = () => {
    oppupCreateUser();
  };

  const receiveUserId = (selectUsr) => {
    setSelectedUserDetail({ ...selectUsr });
    setCampaignDetail({ ...campaignDetail, user: selectUsr.user_id });
    getMailingList(selectUsr.user_id);
  };

  const sendImage = (image) => {
    setImage(image);
  };
  const [selectedProductForCart, setSelectedProductForCart] = useState();
  const sendSingleProduct = (item) => {
    console.log(item, "item");
    setSelectedProductForCart({ ...item });
    setSinglePrd(item);
    singleProduct.push(item);
    setSingleProduct(singleProduct);
  };

  useEffect(() => {
    console.log(
      selectedProductForCart,
      "selectedProductForCartselectedProductForCart"
    );
  }, [selectedProductForCart]);

  const sendCategory = (id, name) => {
    setChildCategory(name);
    setChildCategoryId(id);
  };
  const setSingleProductId = (id) => {
    setPrdId(id);
  };
  const [postage, setPostage] = useState([]);
  const [envelope, setEnvelope] = useState([]);
  const [paper, setPaper] = useState([]);
  const [ink, setInk] = useState([]);
  const [accTXT, setAccTXT] = useState([]);
  const [accuPIX, setAccuPIX] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const fetchAttributesType = () => {
    props.callRequest("GET", API_ATTRIBUTES, true).then((res) => {
      setAttributes(res.data);
      const ink_type = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.ink_type_id;
      });
      const paper_type = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.paper_type_id;
      });
      const postage_type = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.postage_id;
      });
      const envelope_type = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.envelope_type_id;
      });

      const AccTXT = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.accuTXT_type_id;
      });

      const AccuPIX = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.accuPIX_type_id;
      });

      setAccTXT(AccTXT);
      setAccuPIX(AccuPIX);

      setInk(ink_type);
      setPaper(paper_type);
      setPostage(postage_type);
      setEnvelope(envelope_type);
    });
  };

  const addProductToOrderValidation = () => {
    if (!selectedUserDetail) {
      Swal.fire("Error !", "Please search and select a valid user", "error");
    } else if (!campaignDetail.campaign_name) {
      Swal.fire("Error !", "Please enter valid campaign name", "error");
    } else {
      addProductOrder();
    }
  };

  const removeItem = (index) => {
    if (index > -1) {
      Swal.fire({
        title: "Do you want remove this item from cart ?",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Yes, remove it",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let list = [...productList];
          list.splice(index, 1);
          setProductList([...list]);
        }
      });
    }
  };

  const removeMailingList = () => {
    Swal.fire({
      title: "Do you want remove mailing list?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Yes, remove it",
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        setAddMailList({
          mailinglist_attr_id: "",
          desired_quantity: 0,
          mailing_list_type: "",
          price_amount: "",
        });
      }
    });
  };

  const updateCampaign = (campaignDetail) => {
    setCampaignDetail(campaignDetail);
    setSavedCampaignData(campaignDetail);
    setShowCsvMap(true);
    setOrderApproval(false);
  };

  const processCopyCamDataToMailingDateRecord = () => {
    if (
      orderId &&
      savedCampaignData &&
      "campaign_id" in savedCampaignData &&
      savedCampaignData.campaign_id
    ) {
      props
        .callRequest(
          "POST",
          API_COPY_CAMPAIGN_DATA_TO_MAILING_DATES_RECORD,
          true,
          {
            campaign_id: Number(savedCampaignData.campaign_id),
            order_id: Number(orderId),
          }
        )
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchSalesRep();
  }, []);

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  const fetchSalesRepName = (id) => {
    const salesRepName = salesRep.filter((item) => {
      return item.user_id == id;
    });
    if (salesRepName.length > 0) {
      return salesRepName[0].name;
    }
  };

  function formatUSNumber(entry = "") {
    let cleaned = ('' + entry).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order Create"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            {orderStatus ? "Edit Order" : "Create Order"}
          </h1>
        </div>
        <div className="create-order-main-block">
          {showCsvMap && (
            <CrmContacts
              {...props}
              csvData={savedCampaignData}
              resetSavedCampaign={resetSavedCampaign}
              stopRedirection={true}
            />
          )}
          {orderApproval && (
            <CreateOrderApproval
              {...props}
              campaignDetail={campaignDetail}
              campaignData={savedCampaignData}
              mailingListDetail={mailingListDetail}
              selectedUserDetail={selectedUserDetail}
              affiliateDetail={Affiliate}
              permissionDetail={permissions}
              selectedProduct={selectedProduct}
              edittedProductFlag={edittedProductFlag}
              productList={productList}
              setProductList={setProductList}
              edittedProductList={edittedProductList}
              singleProduct={singleProduct}
              addMailList={addMailList}
              draftOrderDetail={draftOrderDetail}
              redirectPath={"/orders"}
              addProductOrder={() => addProductOrder()}
              postage={postage}
              envelope={envelope}
              paper={paper}
              ink={ink}
              accTXT={accTXT}
              accuPIX={accuPIX}
              attributes={attributes}
              setMailingListData={setMailingListData}
              orderId={orderId}
              updateCampaign={updateCampaign}
              orderStatus={orderStatus}
              getOrderDetail={getOrderDetail}
              UploadedFiles={UploadedFiles}

            // redirectPath={"/admin/orders"}
            />
          )}
          {!showCsvMap && !orderApproval && (
            <div className="row">
              <div className="col-sm-8">
                <Form>
                  <div className="create-order-block">
                    <Card>
                      <CardBody>
                        <div className="form-box">
                          {/* <div className="header-title-block">
                            <CardTitle tag="h5">Select Campaign</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">
                              Select a Campaign
                            </CardSubtitle>
                          </div>

                          <FormGroup>
                            <Label for="campaignName">Campaign Name:</Label>
                            <Input
                              type="select"
                              name="campaign_id"
                              id="campaign_id"
                              value={campaignDetail.campaign_id}
                              onChange={(e) => onChangeCampaign(e)}
                              placeholder="Campaign Name"
                            >
                              <option value="">Select Campaign Name</option>
                              {campaigns.map((camp, index) => (
                                <option key={index} value={camp.campaign_id}>
                                  {camp.campaign_name}
                                </option>
                              ))}
                            </Input>
                          </FormGroup> */}
                          {/* {campaignDetail &&
                            campaignDetail.campaign_id === "" ? ( */}
                          <div>
                            {/* <div className="orblock">
                                <span>OR</span>
                              </div> */}

                            <div className="header-title-block">
                              <CardTitle tag="h5">
                                Create New Campaign
                              </CardTitle>
                              <CardSubtitle
                                tag="h6"
                                className="mb-2 text-muted"
                              >
                                Name your Campaign, then choose its target and
                                type
                              </CardSubtitle>
                            </div>

                            <FormGroup>
                              <Label for="campaign_name">
                                Campaign Name:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                name="campaign_name"
                                id="campaign_name"
                                value={campaignDetail.campaign_name}
                                placeholder="Enter Campaign Name"
                                onChange={(e) => onChangeAction(e)}
                              />
                            </FormGroup>

                            <Row form>
                              {/* <Col md={6}>
                                <FormGroup>
                                  <Label for="campaign_target">
                                    Campaign Target:
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="select"
                                    name="campaign_target"
                                    value={campaignDetail.campaign_target}
                                    id="campaign_target"
                                    onChange={(e) => onChangeAction(e)}
                                  >
                                    <option value="">
                                      Select Campaign Target
                                    </option>
                                    <option value="0">Seller</option>
                                    <option value="1">Buyer</option>
                                  </Input>
                                </FormGroup>
                              </Col> */}
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="campaign_type">
                                    Campaign Type:
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="select"
                                    name="campaign_type"
                                    value={campaignDetail.campaign_type}
                                    id="campaign_type"
                                    onChange={(e) => onChangeAction(e)}
                                  >
                                    {/* <option value="">
                                      Select Campaign Type
                                    </option> */}
                                    <option value="0">Direct Mail</option>
                                    {/* <option value="1">Probate</option>
                                    <option value="2">Micro-Campaigns</option> */}
                                  </Input>
                                </FormGroup>
                              </Col>
                            </Row>
                          </div>
                          {/* ) : null} */}
                        </div>

                        <div className="form-box">
                          <div className="header-title-block">
                            <CardTitle tag="h5">Add Products</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">
                              Add a product or mailing list to the order.
                            </CardSubtitle>
                          </div>
                          {productList && productList.length > 0 && (
                            <div className="order-products-listblock">
                              <div className="products-list-block">
                                <ul className="products-list">
                                  {productList.map((itm, idx) => {
                                    return (
                                      <li className="new-products">
                                        <div className="products-box">
                                          <div className="product-image">
                                            <img
                                              alt="reiprintmail"
                                              src={
                                                itm.FullSizeImagePath
                                                  ? process.env
                                                    .REACT_APP_PUBLIC_URL_PHOTOPATH +
                                                  itm.FullSizeImagePath
                                                  : "item_img_url" in itm &&
                                                    itm.item_img_url
                                                    ? process.env
                                                      .REACT_APP_PUBLIC_URL_PHOTOPATH +
                                                    itm.item_img_url
                                                    : require("../../assets/img/product3.png")
                                                      .default
                                              }
                                            />
                                            <div className="removed-item">
                                              <a
                                                className="btn delete-button"
                                                onClick={() => removeItem(idx)}
                                              >
                                                <i className="bi bi-x-lg"></i>
                                              </a>
                                            </div>
                                          </div>
                                          <div className="product-details">
                                            <h4 className="titel">
                                              {"item" in itm
                                                ? itm.item
                                                : itm.product_name}
                                            </h4>
                                            <p className="size">
                                              {itm.size_name}
                                            </p>
                                            <p className="details">
                                              {itm.description}
                                            </p>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}

                                  {addMailList &&
                                    "desired_quantity" in addMailList &&
                                    addMailList.desired_quantity > 0 && (
                                      <li>
                                        <div className="products-box">
                                          <div
                                            className="product-image"
                                            style={{
                                              padding: "0px 5px 0px 5px",
                                            }}
                                          >
                                            <img
                                              alt="reiprintmail"
                                              src={
                                                require("../../assets/img/email-icon.png")
                                                  .default
                                              }
                                            />
                                            <div className="removed-item">
                                              <a
                                                className="btn delete-button"
                                                onClick={removeMailingList}
                                              >
                                                <i className="bi bi-x-lg"></i>
                                              </a>
                                            </div>
                                          </div>
                                          <div className="product-details">
                                            <h4 className="titel">
                                              Mailing List
                                            </h4>
                                            <p className="size">
                                              Qty:{" "}
                                              {addMailList.desired_quantity}
                                            </p>
                                            <p className="details"></p>
                                          </div>
                                        </div>
                                      </li>
                                    )}
                                </ul>
                              </div>
                            </div>
                          )}

                          <div className="add-product-button-block">
                            <Button
                              color="secondary"
                              onClick={addProductToOrderValidation}
                            >
                              Add Product to Campaign{" "}
                              <i className="ni ni-fat-add"></i>
                            </Button>
                            {campaignDetail.is_proceed_without_mailinglist ==
                              1 || true ? (
                              <Button color="danger" onClick={addMailingList}>
                                Add Mailing List{" "}
                                <i className="ni ni-fat-add"></i>
                              </Button>
                            ) : null}
                          </div>
                        </div>

                        {campaignDetail &&
                          campaignDetail.campaign_file_path === "" ? (
                          <div className="form-box">
                            <div className="header-title-block">
                              <CardTitle tag="h5">
                                Upload Mailing List
                              </CardTitle>
                              <CardSubtitle
                                tag="h6"
                                className="mb-2 text-muted"
                              >
                                Choose from the options bellow to provide a
                                mailing list.
                              </CardSubtitle>
                            </div>
                            <Row form>
                              <Col md={6}>
                                <div className="upload-mailing-list">
                                  <div className="number-block">
                                    <span className="number">1</span>
                                  </div>
                                  <div className="form-item upload-map-block">
                                    <h4>
                                      Upload{" "}
                                      {/* <span
                                        className="d-inline-block"
                                        tabIndex="0"
                                        data-toggle="tooltip"
                                        title="Description of the hovericon to explain something specific that is not currently common knowledge."
                                      >
                                        tooltip
                                      </span> */}
                                      <span className="text-danger">*</span>
                                    </h4>
                                    <p>
                                      Click the button to upload a mailing
                                      list(CSV format only)
                                    </p>
                                    <div className="upload-button-block">
                                      <div className="form-group upload-block">
                                        <div className="upload-button">
                                          <button
                                            className="btn btn-danger"
                                            disabled={
                                              campaignDetail.is_proceed_without_mailinglist ==
                                                1
                                                ? true
                                                : false
                                            }
                                          >
                                            Upload{" "}
                                            <i className="ni ni-cloud-upload-96"></i>
                                          </button>

                                          <input
                                            type="file"
                                            id="uplodedFile"
                                            name="uplodedFile"
                                            accept=".csv,.xls,.xlsx"
                                            className="btn custom-file-input"
                                            multiple
                                            onChange={(e) => upload_csv(e)}
                                            disabled={
                                              campaignDetail.is_proceed_without_mailinglist ==
                                                1
                                                ? true
                                                : false
                                            }
                                          ></input>
                                          {/* <span>
                                            {campaignDetail.uplodedFile &&
                                              campaignDetail.uplodedFile.name}
                                          </span> */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  {UploadedFiles &&
                                    UploadedFiles.map((item, index) => {
                                      return (
                                        <>
                                          <div
                                            className="col-sm-8"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            {item.original_filename}
                                          </div>
                                          <div
                                            className="col-sm-4"
                                            style={{ marginBottom: "5px" }}
                                          >
                                            <Button
                                              variant="link"
                                              onClick={() =>
                                                deleteFile(item.filepath, index)
                                              }
                                              style={{ zIndex: "9999" }}
                                            >
                                              <i className="far fa-trash-alt"></i>
                                            </Button>
                                          </div>
                                        </>
                                      );
                                    })}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="upload-mailing-list">
                                  <div className="number-block">
                                    <span className="number">2</span>
                                  </div>
                                  <div className="form-item choose-saved-lis-block">
                                    <h4>
                                      Choose a saved list{" "}
                                      <span
                                        className="d-inline-block"
                                        tabIndex="0"
                                        data-toggle="tooltip"
                                        title="Description of the hovericon to explain something specific that is not currently common knowledge."
                                      >
                                        tooltip
                                      </span>
                                    </h4>
                                    <p>
                                      Select a list that has already been
                                      uploaded in clients Account Page
                                    </p>
                                    <FormGroup>
                                      <Input
                                        type="select"
                                        name="mailinglist_id"
                                        id="mailinglist_id"
                                        value={mailingListDetail.mailinglist_id}
                                        onChange={(e) => onChangeMailingList(e)}
                                        disabled={
                                          campaignDetail.is_proceed_without_mailinglist ==
                                            1
                                            ? true
                                            : false
                                        }
                                      >
                                        <option value="">
                                          Select Mailing List
                                        </option>
                                        {mailingLists.map((mail, index) => (
                                          <option
                                            key={index}
                                            value={mail.mailinglist_id}
                                          >
                                            {mail.mailinglist_name}
                                          </option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="upload-mailing-list">
                                  <div className="number-block">
                                    <span className="number">3</span>
                                  </div>
                                  <div className="form-item upload-map-block">
                                    <h4>Proceed without a mailing list </h4>

                                    <FormGroup check>
                                      <Label check>
                                        <Input
                                          type="checkbox"
                                          id="upload-mailing-list"
                                          checked={
                                            campaignDetail.is_proceed_without_mailinglist ==
                                              1
                                              ? "checked"
                                              : ""
                                          }
                                          onChange={(e) => toggleChange(e)}
                                          disabled={
                                            UploadedFiles &&
                                              UploadedFiles.length > 0
                                              ? true
                                              : false
                                          }
                                        />{" "}
                                        Upload a mailing list after starting the
                                        campaign
                                      </Label>
                                    </FormGroup>
                                    <p className="info-text">
                                      * please note, this option will delay the
                                      mail drops everyday a list is not
                                      provided.
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ) : null}

                        <div className="button-block next-button-block">
                          <Button
                            color="primary"
                            onClick={(e) => handleNext(true)}
                          >
                            Next <i className="ni ni-bold-right"></i>
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Form>
              </div>
              <div className="col-sm-4">
                <div className="right-block">
                  <div className="select-user-box">
                    {!selectedUserDetail && (
                      <Card>
                        <CardHeader>Search User </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <SelectUserForm
                              {...props}
                              receiveUserId={receiveUserId}
                              permissions={permissions}
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                    )}

                    {selectedUserDetail && (
                      <Card>
                        <CardHeader>
                          User
                          <UncontrolledDropdown>
                            <DropdownToggle>
                              <i className="fas fa-ellipsis-v"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => changeSelectedUser()}
                              >
                                Change User
                              </DropdownItem>
                              <DropdownItem onClick={() => editUserPop()}>
                                Edit User Profile
                              </DropdownItem>
                              {/* {permissions && permissions.length !== 0 ? (
                                <DropdownItem onClick={toggleCommissions}>
                                  Commissions Override
                                </DropdownItem>
                              ) : (
                                ""
                              )} */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <div className="details-text-block">
                              <p>
                                <strong>Name:</strong>{" "}
                                {selectedUserDetail &&
                                  selectedUserDetail.username}
                              </p>
                              <p>
                                <strong>Email:</strong>{" "}
                                {selectedUserDetail && selectedUserDetail.email}
                              </p>
                              <p>
                                <strong>Phone:</strong>{" "}
                                {selectedUserDetail &&
                                  formatUSNumber(selectedUserDetail.phone)}
                              </p>
                              <p>
                                <strong>Direct Marketing Coach 1:</strong>{" "}
                                {selectedUserDetail &&
                                  fetchSalesRepName(
                                    selectedUserDetail.sales_rep_1
                                  )}
                              </p>
                              <p>
                                <strong>Direct Marketing Coach 2:</strong>{" "}
                                {selectedUserDetail &&
                                  fetchSalesRepName(
                                    selectedUserDetail.sales_rep_2
                                  )}
                              </p>
                              <p>
                                <strong>CSR:</strong>{" "}
                                {selectedUserDetail && selectedUserDetail.csr}
                              </p>
                              <p>
                                <strong>Affiliate:</strong>&nbsp;
                                {selectedUserDetail &&
                                  selectedUserDetail.affiliate_id &&
                                  affUsr
                                  ? affUsr[0].affiliate_id.name
                                  : selectedUserDetail.affiliate_id}
                              </p>
                            </div>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    )}
                  </div>
                  <div className="ordedr-details-box">
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h2">Order Details</CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div className="ordedr-details-listbox"></div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Create user */}
        <Modal
          isOpen={createUserModal}
          toggle={oppupCreateUser}
          className="create-user-modal"
        >
          <ModalHeader toggle={oppupCreateUser}></ModalHeader>
          {/* <ModalHeader toggle={oppupCreateUser}>
            <div className="search-box">
              <FormGroup>
                <Label for="listSearch">Edit User</Label>
              </FormGroup>
            </div>
          </ModalHeader> */}
          <ModalBody>
            <UserFrom
              {...props}
              isInPopUp={true}
              refreshList={refreshList}
              userId={selectedUserDetail && selectedUserDetail.user_id}
            />

            {/* <CreateUserForm
              {...props}
              selectedUser={selectedUserDetail && selectedUserDetail.user_id}
              sendEdittedUser={sendEdittedUser}
              sendUpdatedProfile={sendUpdatedProfile}
              modalToggle={oppupCreateUser}
            /> */}
          </ModalBody>
        </Modal>
        {/* End of create user */}

        {/* Commissions Override */}
        <Modal
          isOpen={modal}
          toggle={toggle}
          className="create-user-modal commissions-override-modal"
        >
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <CommissionOverrideForm
              {...props}
              isInPopUp={true}
              refreshList={refreshList}
              selectedUser={selectedUserDetail && selectedUserDetail.user_id}
              modalToggle={toggle}
            />
          </ModalBody>
        </Modal>
        {/* End of Commissions Override */}

        <Modal
          isOpen={addMailingListModal}
          toggle={addMailingList}
          className="add-mailing-list-modal"
        >
          <ModalHeader toggle={addMailingList}>Add Mailing List</ModalHeader>
          <ModalBody>
            <AddMailingList
              {...props}
              addMailingList={setMailingListData}
              addMailListData={addMailList}
            />
          </ModalBody>
        </Modal>

        {/* Add Product Order */}
        <Modal
          isOpen={addProductOrderModal}
          toggle={addProductOrder}
          className="add-product-to-order-modal"
        >
          <ModalHeader toggle={addProductOrder}></ModalHeader>
          <ModalBody>
            <AddProductOrder
              {...props}
              sendImage={sendImage}
              sendSingleProduct={sendSingleProduct}
              setSingleProductId={setSingleProductId}
              addProductOrder={addProductOrder}
              sendCategory={sendCategory}
              chooseDesignDetails={chooseDesignDetails}
              SelectedUserId={selectedUserDetail && selectedUserDetail.user_id}
            />
            <div className="row">
              <div className="col-sm-12 text-right">
                <Button color="secondary" onClick={addProductOrder}>
                  Close
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* End of Add Product Order */}

        {/* Product Detail */}
        <Modal
          isOpen={productDetailModal}
          toggle={chooseDesignDetails}
          className="show-product-details-modal"
        >
          <ModalHeader toggle={chooseDesignDetails}>
            Manage Cart Item
          </ModalHeader>
          <ModalBody>
            <ProductDetail
              {...props}
              invImage={image}
              sendImage={sendImage}
              sendSingleProduct={sendSingleProduct}
              SinglePrd={SinglePrd}
              prdId={prdId}
              chooseDesignDetails={() => chooseDesignDetails()}
              selectedProduct={(item) => selectedProduct({ ...item })}
              edittedProductFlag={edittedProductFlag}
              editProductFlag={editProductFlag}
              modalToggle={() => setAddProductOrderModal(!addProductOrderModal)}
              selectedUserDetail={selectedUserDetail}
              postage={postage}
              envelope={envelope}
              paper={paper}
              ink={ink}
              accTXT={accTXT}
              accuPIX={accuPIX}
              attributes={attributes}
              orderStatus={orderStatus}
              childCategory={childCategory}
              campaignName={
                campaignDetail &&
                  "campaign_name" in campaignDetail &&
                  campaignDetail.campaign_name
                  ? campaignDetail.campaign_name
                  : null
              }
            />
          </ModalBody>
        </Modal>
        {/*End Product Detail */}
      </main>
    </>
  );
};

export default withRouter(CreateOrder);
