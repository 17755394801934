import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import {
  API_GET_PAYMENT_HISTORY,
  API_ADD_BALANE_PAYPAL,
} from "../../config/Api";
import Swal from "sweetalert2";
import PaypalExpressBtn from "react-paypal-express-checkout";
import payPalConfig from "./../../payPalConfig";

const WalletBalanceBox = (props) => {
  const { className } = props;
  const [walletBalance, setWalletBalance] = useState(props.walletBalance);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [savedCardList, setSavedCardList] = useState(props.savedCardList);
  const [defaultSelectedCard, setDefaultSelectedCard] = useState(
    props.savedCardList &&
      props.savedCardList.filter((item) => item.is_primary === "1")[0]
  );
  const [amountForBalance, setAmountForBalance] = useState();
  const [formError, setFormError] = useState();
  const [btnDisable, setBtnDisabled] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setWalletBalance(props.walletBalance);
  }, [props.walletBalance]);

  useEffect(() => {
    setSavedCardList(props.savedCardList);
    setDefaultSelectedCard(
      props.savedCardList &&
        props.savedCardList.filter((item) => item.is_primary === "1")[0]
    );
  }, [props.savedCardList]);

  useEffect(() => {
    setDefaultSelectedCard(defaultSelectedCard);
  }, [defaultSelectedCard]);

  const selectCardForPayment = (el, item) => {
    setDefaultSelectedCard(item);
  };

  const formInput = (el) => {
    let value = el.target.value;
    setAmountForBalance(value);
  };
  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (amountForBalance === undefined) {
      formIsValid = false;
      errors["amountForBalance"] = "Please provide a valid amount";
    }

    setErrors(errors);
    return formIsValid;
  };
  const addPayment = () => {
    if (!validateForm()) {
      return false;
    }
    setBtnDisabled(true);
    props
      .callRequest("POST", `${API_GET_PAYMENT_HISTORY}`, true, {
        user: props.userId ? props.userId : props.getUserInfo().user_id,
        info_id: defaultSelectedCard.info_id,
        amount: amountForBalance && parseFloat(amountForBalance),
      })
      .then((res) => {
        setBtnDisabled(false);
        setWalletBalance(res.data.wallet_balance);
        toggle();
        Swal.fire({
          icon: "success",
          title: "Transaction",
          text: "Wallet transaction request has been successfully processed",
        });
        setAmountForBalance();
        setFormError();
        window.location.reload();
      })
      .catch((error) => {
        setBtnDisabled(false);
        setFormError(error.response.data);
        Swal.fire({
          icon: "error",
          title: "Transaction",
          text: "Something went wrong, Please try again later.",
        });
      });
  };

  //------------Paypal---------------

  const processPaypalTransaction = (payment) => {
    const formData = new FormData();
    formData.append(
      "user_id",
      props.userId ? props.userId : props.getUserInfo().user_id
    );
    formData.append("amount", amountForBalance && parseFloat(amountForBalance));
    formData.append("transaction_id", payment.paymentToken);
    formData.append("paymentID", payment.paymentID);
    formData.append("payerID", payment.payerID);

    props
      .callRequest("POST", `${API_ADD_BALANE_PAYPAL}`, true, formData)
      .then((res) => {
        props.getWalletBalance();
        toggle();
        Swal.fire({
          icon: "success",
          title: "Transaction",
          text: "Wallet transaction request has been successfully processed",
        });
        setAmountForBalance();
        setFormError();
      })
      .catch((error) => {
        setFormError(error.response.data);
      });
  };

  const onSuccess = (payment) => {
    console.log(payment, "Transaction Detail");
    // 1, 2, and ... Poof! You made it, everything's fine and dandy!
    console.log("Payment successful!", payment);
    processPaypalTransaction(payment);
    // You can bind the "payment" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onCancel = (data) => {
    // The user pressed "cancel" or closed the PayPal popup
    console.log("Payment cancelled!", data);
    // You can bind the "data" object's value to your state or props or whatever here, please see below for sample returned data
  };

  const onError = (err) => {
    // The main Paypal script could not be loaded or something blocked the script from loading
    console.log("Error!", err);
    // Because the Paypal's main script is loaded asynchronously from "https://www.paypalobjects.com/api/checkout.js"
    // => sometimes it may take about 0.5 second for everything to get set, or for the button to appear
  };

  return (
    <>
      <div>
        <Card>
          <CardHeader>
            <CardTitle tag="h2">Wallet Balance:</CardTitle>
          </CardHeader>

          <CardBody>
            <div className="wallet-balance">
              <CardTitle tag="h5">
                ${" "}
                {Number(walletBalance).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                {/* ${parseFloat(walletBalance).toFixed(2)} */}
              </CardTitle>
            </div>
          </CardBody>

          <CardFooter>
            <div className="add-wallet-button">
              <Button color="success" onClick={() => toggle()}>
                Add to Wallet
              </Button>
            </div>
          </CardFooter>
        </Card>

        <Modal isOpen={modal} toggle={toggle} className={className}>
          <ModalHeader toggle={toggle}>Wallet</ModalHeader>
          <ModalBody>
            <div className="add-card-popup-form">
              <Form>
                <div className="popup-payment-source">
                  <FormGroup tag="fieldset">
                    <legend>Payment Source:</legend>
                    {savedCardList &&
                      savedCardList.map((item) => {
                        return (
                          <FormGroup check key={Math.random()}>
                            <Label check>
                              <Input
                                type="radio"
                                name="savedPayment"
                                checked={
                                  defaultSelectedCard &&
                                  defaultSelectedCard.info_id == item.info_id
                                    ? true
                                    : false
                                }
                                onChange={(el) =>
                                  selectCardForPayment(el, item)
                                }
                              />{" "}
                              <div className="payment-method-name">
                                <span>
                                  {item.payment_method == "cc"
                                    ? "Credit Card"
                                    : "ACH"}{" "}
                                  - {item.maskedNumber}
                                </span>
                                <span>
                                  Expires:{" "}
                                  {item.expirationDate !== ""
                                    ? [
                                        item.expirationDate.slice(0, 2),
                                        "/",
                                        item.expirationDate.slice(2),
                                      ].join("")
                                    : ""}
                                </span>
                              </div>
                            </Label>
                          </FormGroup>
                        );
                      })}
                    {/* <FormGroup check>
                                    <Label check>
                                      <Input type="radio" name="savedPayment" />{' '}
                                      <div className="payment-method-name">
                                        <span>Credit Card - 2299</span>
                                        <span>Expires 04/23</span>
                                      </div>
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check>
                                    <Label check>
                                      <Input type="radio" name="savedPayment" />{' '}
                                      <div className="payment-method-name">
                                        <span>Credit Card - 1234</span>
                                        <span>Expires 06/22</span>
                                      </div>
                                    </Label>
                                  </FormGroup>

                                  <FormGroup check>
                                    <Label check>
                                      <Input type="radio" name="savedPayment" />{' '}
                                      <div className="payment-method-name">
                                        <span>ACH</span>
                                      </div>
                                    </Label>
                                  </FormGroup> */}
                  </FormGroup>
                </div>

                <FormGroup>
                  {savedCardList !== null ? (
                    <FormGroup>
                      <Label for="amountFund">Amount to Fund:</Label>
                      <Input
                        type="number"
                        name="amount"
                        id="amountFund"
                        placeholder="$0.00"
                        value={amountForBalance}
                        onChange={formInput}
                        invalid={
                          formError &&
                          formError.amount &&
                          formError &&
                          formError.amount.length > 0
                            ? true
                            : false
                        }
                      />
                      <label className="red_err">
                        {errors.amountForBalance}
                      </label>
                      <FormFeedback>
                        {formError && formError.amount
                          ? formError.amount.join(",")
                          : ""}
                      </FormFeedback>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <Label for="amountFund">
                        Please add card details in payment methods or use
                        PayPal.{" "}
                      </Label>
                    </FormGroup>
                  )}

                  <FormFeedback>
                    {formError && formError.amount
                      ? formError.amount.join(",")
                      : ""}
                  </FormFeedback>
                </FormGroup>

                <div className="or-block">
                  <span>OR</span>
                </div>
                {savedCardList === null ? (
                  <FormGroup>
                    <Label for="amountFund">Amount to Fund:</Label>
                    <Input
                      type="number"
                      name="amount"
                      id="amountFund"
                      placeholder="$0.00"
                      value={amountForBalance}
                      onChange={formInput}
                      invalid={
                        formError &&
                        formError.amount &&
                        formError &&
                        formError.amount.length > 0
                          ? true
                          : false
                      }
                    />
                    <label className="red_err">{errors.amountForBalance}</label>
                    <FormFeedback>
                      {formError && formError.amount
                        ? formError.amount.join(",")
                        : ""}
                    </FormFeedback>
                  </FormGroup>
                ) : (
                  ""
                )}

                <div className="paypale-button">
                  <PaypalExpressBtn
                    env={payPalConfig.env}
                    client={payPalConfig.client}
                    currency={"USD"}
                    total={amountForBalance}
                    style={{ color: "silver", shape: "pill", size: "large" }}
                    onError={onError}
                    onSuccess={onSuccess}
                    onCancel={onCancel}
                  />

                  {/* <Button id="paypal-button"> <img  src={require("../../assets/img/paypal-logo.png").default} alt="paypal" /> </Button> */}
                </div>

                <div className="card-popup-button-block">
                  <Button color="secondary" onClick={toggle}>
                    Back
                  </Button>
                  <Button
                    color="primary"
                    disabled={btnDisable}
                    onClick={addPayment}
                  >
                    Add
                  </Button>{" "}
                </div>
              </Form>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default WalletBalanceBox;
