import React, {useState, useEffect, useLayoutEffect} from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    CardFooter,
    Button,
    Form, 
    FormGroup, 
    Label, 
    Input,
    FormFeedback
} from "reactstrap";
import Swal from "sweetalert2";
import { API_SAVE_AUTO_PAYMENT } from "../../config/Api";

const AutoPaymentBox = (props) => {
    const [autoPaymentValue, setAutoPaymentValue] = useState(props.autoPaymentValue);
    const [formError, setFormError] = useState()
    const [savedCardList, setSavedCardList] = useState(props.savedCardList);

    useEffect(()=>{
        setSavedCardList(props.savedCardList);
    },[props.savedCardList]);

    useLayoutEffect(()=>{
        setAutoPaymentValue(props.autoPaymentValue)
    },[props.autoPaymentValue]);

    const validateNumberField = myNumber => {
        const numberRegEx = /\-?\d*\.?\d{1,2}/;
        return numberRegEx.test(String(myNumber).toLowerCase());
      };

    const onInputchange = (el)=>{
        let formVal = {...autoPaymentValue};
        let value = el.target.value;
        const isValid = !value || validateNumberField(value);
        if(!isValid) return false;

        formVal[el.target.name] = value;
        setAutoPaymentValue(formVal);
    }


    const onSubmitUpdate = ()=>{
        let paymentData = {...autoPaymentValue};
        paymentData.funding_threshold = parseFloat(paymentData.funding_threshold);
        paymentData.amount_to_fund = parseFloat(paymentData.amount_to_fund);

        props
      .callRequest("POST", `${API_SAVE_AUTO_PAYMENT}`, true,{
          user:props.getUserInfo().user_id,
          funding_threshold:parseFloat(paymentData.funding_threshold),
          amount_to_fund:parseFloat(paymentData.amount_to_fund),
          is_auto_funding:paymentData.is_auto_funding
      })
      .then((res) => {
        props.getAutoPaymentDetail();
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Auto Payment configuration has been saved successfully.'
          })
      })
      .catch((error) => {
        setFormError(error.response.data);
      });
    }

    const isAutofindingAction=()=>{
        let formVal = {...autoPaymentValue};
        formVal.is_auto_funding = formVal.is_auto_funding =="1" ? "0" :"1"
        setAutoPaymentValue(formVal);
    }


    return (
        <>
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h2">Auto Payment</CardTitle>
                    </CardHeader>

                    <CardBody>
                        <div className="auto-payment-form-box">
                            <Form>
                                <FormGroup>
                                    <Label for="fundingThreshold">Funding Threshold:*</Label>
                                    <Input type="number" name="funding_threshold" id="fundingThreshold" invalid={formError && formError.funding_threshold && formError && formError.funding_threshold.length>0 ? true:false} placeholder="$0.00" onChange={(el)=>onInputchange(el)} value={autoPaymentValue.funding_threshold}/>
                                    <FormFeedback>{formError && formError.funding_threshold ? formError.funding_threshold.join(','):""}</FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="amountFund">Amount to Fund:*</Label>
                                    <Input type="number" name="amount_to_fund" id="amountFund" invalid={formError && formError.amount_to_fund && formError && formError.amount_to_fund.length>0 ? true:false}  placeholder="$0.00" onChange={(el)=>onInputchange(el)}  value={autoPaymentValue.amount_to_fund}/>
                                    <FormFeedback>{formError && formError.amount_to_fund ? formError.amount_to_fund.join(','):""}</FormFeedback>
                                </FormGroup>
                                <FormGroup check>
                                    <Input type="checkbox" name="is_auto_funding" id="exampleCheck" checked={autoPaymentValue.is_auto_funding=="1"?true:false} onChange={isAutofindingAction}/>
                                    <Label for="exampleCheck" check>Auto Funding Enabled</Label>
                                </FormGroup>
                            </Form>
                        </div>
                    </CardBody>
                    <CardFooter>
                        <div className="add-wallet-button">
                            <Button color="success" onClick={onSubmitUpdate}>Update Settings</Button>
                        </div>
                    </CardFooter>
                </Card>
            </div>
        </>
    );
};

export default AutoPaymentBox;
