import React from "react";
import { API_FORGOT_PASSWORD } from "../../config/Api";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
} from "reactstrap";
import Swal from "sweetalert2";

const ForgotPassword = (props) => {
  const [email, setEmail] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const [modal, setModal] = React.useState(true);

  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!email) {
      formIsValid = false;
      errors["email"] = "Please provide email address";
    }
    if (!props.validateEmail(email)) {
      formIsValid = false;
      errors["err_email"] = "Please enter valid email address";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value);
  };

  const handleForgotPwd = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    props
      .callRequest("POST", API_FORGOT_PASSWORD, false, {
        email: email,
      })
      .then((res) => {
        props.toggle();
        Swal.fire({
          icon: "success",
          title:
            "Password recovered successfully..check your email for new password !",
        });
      })
      .catch((error) => {
        // props.toggle();
        Swal.fire({
          showConfirmButton: false,
          timer: 1000,
          icon: "error",
          title: error.response.data[0],
        });
      });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="login-box">
          <Form>
            <h2 className="text-center">
              Forgot Password
              <span>
                <i
                  style={{ cursor: "pointer" }}
                  onClick={props.toggle}
                  className="fas fa-times-circle popup-close"
                ></i>
              </span>
            </h2>
            <FormGroup size="lg">
              <Input
                type="text"
                placeholder="enter your email address"
                value={email}
                name="email"
                onChange={handleChange}
              />

              {errors.email ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.email}
                </label>
              ) : errors.err_email ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.err_email}
                </label>
              ) : (
                ""
              )}
            </FormGroup>

            <div className=" row d-flex justify-content-center align-content-center ">
              <Button
                style={{ cursor: "pointer" }}
                onClick={handleForgotPwd}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
