import React, { Fragment, useEffect, useState } from "react";
import ResizableRect from "react-resizable-rotatable-draggable";

const ResizableDataMatrix = (props) => {
  const [width, setWidth] = useState(Math.round(props.width));
  const [height, setHeight] = useState(Math.round(props.height));
  const [top, setTop] = useState(Math.round(props.top));
  const [left, setLeft] = useState(Math.round(props.left));

  const [id] = useState(props.id);
  let topy;
  let leftx;

  useEffect(() => {
    setTop(props.top);
    setLeft(props.left);
  }, [props.top, props.left]);

  const contentStyle = {
    top,
    left,
    width,
    height,
    position: "absolute",
    // display: "flex",
    // justifyContent: "center",
    // alignItems: "flex-start",
    padding: "0",
    margin: "0 0 2px 0",
    zIndex: 999,
    textAlign: "left",
    fontSize: "5pt",
    fontFamily: "IDAutomation2D",
    transform: props.data_matrix_swap ? "rotate(180deg)" : "",
  };
  const handleResize = (style, isShiftKey, type) => {
    const { top, left, width, height } = style;
    setWidth(Math.round(width));
    setHeight(Math.round(height));
    setTop(Math.round(top));
    setLeft(Math.round(left));

    props.parentSetDataMatrixDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height
    );
  };

  const handleRotate = (rotateAngle) => {
    //setRotateAngle(rotateAngle);

    props.parentSetDataMatrixDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height
    );
  };

  const handleDrag = (deltaX, deltaY) => {
    leftx = left + deltaX;
    topy = top + deltaY;

    setTop(topy);
    setLeft(leftx);

    props.parentSetDataMatrixDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height
    );
  };

  return (
    <Fragment>
      <div style={contentStyle}>{props.title}</div>

      {/* <ResizableRect
        top={top}
        rotatable
        left={left}
        minWidth={10}
        width={width}
        minHeight={10}
        height={height}
        onDrag={handleDrag}
        onResize={handleResize}
        zoomable="n, w, s, e, nw, ne, se, sw"
      /> */}
    </Fragment>
  );
};

export default ResizableDataMatrix;
