import React, { useState, useEffect, useMemo } from "react";
import classnames from "classnames";
import "./dragDrop.css";
import {
  Button,
  Card,
  CardBody,
  TabContent,
  CardTitle,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import PrimaryData from "./PrimaryData";

const DragDrop = (props) => {
  const [activeTab, setActiveTab] = useState(
    props.activeTab ? props.activeTab : "Property Information"
  );
  const [csvData, setCsvData] = useState(props.csvData);
  const [primaryDataState, setPrimaryDataState] = useState(null);
  const [matchInProcess, setMatchInProcess] = useState(false);
  const [csvValidationSection, setCsvValidationSection] = useState(
    props.csvValidationSection
  );

  useEffect(() => {
    setActiveTab(props.activeTab);
  }, [props.activeTab]);

  useEffect(() => {
    PrimaryData.map((item, key) => {
      item.nav_data.map(
        (inItem, inKey) =>
          (PrimaryData[key]["nav_data"][inKey]["item_value"] = null)
      );
    });
    setPrimaryDataState([...PrimaryData]);
  }, []);

  useEffect(() => {
    setCsvValidationSection({ ...props.csvValidationSection });
  }, [props.csvValidationSection]);

  useEffect(() => {
    props.getMapData(primaryDataState);
  }, [primaryDataState]);

  useEffect(() => {
    if (primaryDataState && props.processTemplateData) {
      let tempprimaryDataState = [...primaryDataState];
      tempprimaryDataState.map((item, key) => {
        item.nav_data.map(
          (inItem, inKey) =>
            (tempprimaryDataState[key]["nav_data"][inKey]["item_value"] =
              inItem.api_key in props.processTemplateData &&
              props.processTemplateData[inItem.api_key] != ""
                ? props.processTemplateData[inItem.api_key]
                : null)
        );
      });
      setPrimaryDataState([...tempprimaryDataState]);
    }
  }, [props.processTemplateData]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dragStart = (e, id) => {
    e.dataTransfer.setData("item_value", id);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const onDropEvent = (e) => {
    try {
      let id = e.dataTransfer.getData("item_value");
      let dataItem = e.target.getAttribute("data-item");
      let dataName = e.target.getAttribute("data-name");
      let localPrimaryData = [...primaryDataState];
      let tempcsvData =
        "selected_header" in csvData ? csvData["selected_header"] : [];

      localPrimaryData.map((el, i) => {
        if (el.nav_title == dataItem) {
          el.nav_data.map((elm, ind) => {
            if (elm.item_name == dataName) {
              localPrimaryData[i]["nav_data"][ind]["item_value"] = id;
              tempcsvData.push(id);
            }
          });
        }
      });

      setCsvData({ ...csvData, selected_header: tempcsvData });
      setPrimaryDataState([...localPrimaryData]);
    } catch (e) {
      console.log(e);
    }
  };

  const removeItem = (nav_title, item_name) => {
    let tempcsvData1 = [...csvData["headers"]];
    let localPrimaryData1 = Object.assign([], [...primaryDataState]);

    localPrimaryData1.map((el, i) => {
      if (el.nav_title == nav_title) {
        el.nav_data.map((elm, ind) => {
          if (elm.item_name == item_name) {
            let index = tempcsvData1.indexOf(
              localPrimaryData1[i]["nav_data"][ind]["item_value"]
            );
            localPrimaryData1[i]["nav_data"][ind]["item_value"] = null;
            tempcsvData1.splice(index, 1);
          }
        });
      }
    });

    setCsvData({ ...csvData, selected_header: tempcsvData1 });
    setPrimaryDataState([...localPrimaryData1]);
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="import-template-main-block">
          <div className="crm-contacts-mainblock">
            <Card>
              <CardBody>
                <CardTitle>File : {csvData && csvData.csv_file}</CardTitle>
                <div className="crm-contacts_block">
                  <div className="crm-tabcontacts_block  property-information-box">
                    <div className="left-crm-contacts-block">
                      <Nav tabs>
                        {primaryDataState &&
                          primaryDataState.map((item) => {
                            return (
                              <NavItem>
                                <NavLink
                                  className={classnames({
                                    active: activeTab === item.nav_title,
                                  })}
                                  onClick={() => {
                                    toggle(item.nav_title);
                                  }}
                                >
                                  {item.nav_title}
                                </NavLink>
                              </NavItem>
                            );
                          })}
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        {primaryDataState &&
                          primaryDataState.map((item) => {
                            return (
                              <>
                                <TabPane tabId={item.nav_title}>
                                  <div className="crm-contacts-list">
                                    {item.nav_data.map((innerItem) => {
                                      return (
                                        <div className="contacts-list-item">
                                          <label className="item-name">
                                            {innerItem.item_name}:
                                          </label>
                                          <div
                                            className={`drag-drop-place-box droppable ${
                                              innerItem.item_value != null
                                                ? "active"
                                                : ""
                                            }`}
                                            onDragOver={(e) => dragOver(e)}
                                            onDrop={(e) => onDropEvent(e)}
                                          >
                                            <div
                                              className={`drag-drop-item ${
                                                innerItem.api_key in
                                                  csvValidationSection &&
                                                csvValidationSection[
                                                  innerItem.api_key
                                                ] == true
                                                  ? "validation-error"
                                                  : ""
                                              }`}
                                              data-item={item.nav_title}
                                              data-name={innerItem.item_name}
                                              style={{ zIndex: "99999" }}
                                            >
                                              {innerItem.item_value
                                                ? innerItem.item_value
                                                : innerItem.api_key in
                                                    csvValidationSection &&
                                                  csvValidationSection[
                                                    innerItem.api_key
                                                  ] == true
                                                ? "Required Field"
                                                : "Empty"}
                                            </div>
                                          </div>
                                          {innerItem.item_value != null ? (
                                            <a
                                              className="close-button"
                                              onClick={() =>
                                                removeItem(
                                                  item.nav_title,
                                                  innerItem.item_name
                                                )
                                              }
                                            >
                                              <i className="ni ni-fat-remove"></i>
                                            </a>
                                          ) : (
                                            <a className="close-button">
                                              <i className="ni ni-fat-remove"></i>
                                            </a>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </TabPane>
                              </>
                            );
                          })}
                      </TabContent>
                    </div>

                    <div className="drag-and-drop-block">
                      {console.log(csvData)}
                      <h2 className="titel">Drag and Drop</h2>
                      <p className="short-text">
                        Drag any unmatched CSV fields to their corresponding
                        destination fields. Once they have been matched they
                        will not be able to be used again.
                      </p>
                      <div className="drag-drop-all-items">
                        <ul className="drag-drop-item-list">
                          {csvData &&
                            "headers" in csvData &&
                            csvData.headers.map((item) => {
                              return (
                                <li key={Math.random()}>
                                  <div
                                    onDragStart={(e) => dragStart(e, item)}
                                    draggable={
                                      true /*!getSelectedHeader(item)*/
                                    }
                                    className={`draggable drag-drop-item handle`}
                                  >
                                    <span>{item}</span>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </main>
    </>
  );
};

export default DragDrop;
