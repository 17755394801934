const PrimaryData = [
  {
    nav_title: "Property Information",
    nav_data: [
      // {
      //   item_name: "APN",
      //   item_value: null,
      //   api_key: "apn",
      // },
      // {
      //   item_name: "Property Address",
      //   item_value: null,
      //   api_key: "property_address",
      // },
      {
        item_name: "Property Street",
        item_value: null,
        api_key: "property_street",
      },
      {
        item_name: "Property Street 2",
        item_value: null,
        api_key: "property_street2",
      },
      {
        item_name: "Property City",
        item_value: null,
        api_key: "property_city",
      },
      {
        item_name: "Property County",
        item_value: null,
        api_key: "property_county",
      },
      {
        item_name: "Property State",
        item_value: null,
        api_key: "property_state",
      },
      {
        item_name: "Property Zip",
        item_value: null,
        api_key: "property_zip",
      },
      // {
      //   item_name: "Property Size Sq. Ft",
      //   item_value: null,
      //   api_key: "property_size_sq_ft",
      // },
      // {
      //   item_name: "Short Legal Description",
      //   item_value: null,
      //   api_key: "short_leagal_description",
      // },
      {
        item_name: "Offer One",
        item_value: null,
        api_key: "offer_one",
      },
      {
        item_name: "Offer Two",
        item_value: null,
        api_key: "offer_two",
      },
      {
        item_name: "Offer Percentage",
        item_value: null,
        api_key: "offer_percentage",
      },
      {
        item_name: "Offer Code",
        item_value: null,
        api_key: "offer_code",
      },
      {
        item_name: "Return Code",
        item_value: null,
        api_key: "return_code",
      },
      // {
      //   item_name: "Offer Value Name",
      //   item_value: null,
      //   api_key: "offer_value_name",
      // },
      // {
      //   item_name: "Market Value",
      //   item_value: null,
      //   api_key: "market_value",
      // },
      {
        item_name: "Marketplace Value",
        item_value: null,
        api_key: "marketplace_value",
      },

      {
        item_name: "Response Date",
        item_value: null,
        api_key: "response_date",
      },
      {
        item_name: "Date",
        item_value: null,
        api_key: "date",
      },
      {
        item_name: "Day",
        item_value: null,
        api_key: "day",
      },
      {
        item_name: "Month",
        item_value: null,
        api_key: "month",
      },
      {
        item_name: "Year",
        item_value: null,
        api_key: "year",
      },
      {
        item_name: "Tray",
        item_value: null,
        api_key: "tray",
      },
      // {
      //   item_name: "Back Taxes",
      //   item_value: null,
      //   api_key: "black_texes",
      // },
      // {
      //   item_name: "Zoning",
      //   item_value: null,
      //   api_key: "zoning",
      // },
      // {
      //   item_name: "Offer Accept By",
      //   item_value: null,
      //   api_key: "offer_accept_by",
      // },
      // {
      //   item_name: "Offer Expires on (Close of Escrow)",
      //   item_value: null,
      //   api_key: "offer_expire_on",
      // },
      // {
      //   item_name: "Stage",
      //   item_value: null,
      //   api_key: "stage",
      // },
      // {
      //   item_name: "Address Status",
      //   item_value: null,
      //   api_key: "address_status",
      // },
    ],
  },
  {
    nav_title: "Primary Contact",
    nav_data: [
      // {
      //   item_name: "Type",
      //   item_value: null,
      //   api_key: "Type",
      // },
      {
        item_name: "Full Name",
        item_value: null,
        api_key: "full_name",
      },
      {
        item_name: "First Name",
        item_value: null,
        api_key: "first_name",
      },
      {
        item_name: "Last Name",
        item_value: null,
        api_key: "last_name",
      },
      // {
      //   item_name: "Company",
      //   item_value: null,
      //   api_key: "company",
      // },
      // {
      //   item_name: "Mailing Address",
      //   item_value: null,
      //   api_key: "mailing_address",
      // },
      // {
      //   item_name: "Mailing Address 2",
      //   item_value: null,
      //   api_key: "mailing_address2",
      // },
      {
        item_name: "Mailing Street",
        item_value: null,
        api_key: "mailing_street",
      },
      {
        item_name: "Mailing Street 2",
        item_value: null,
        api_key: "mailing_street2",
      },
      {
        item_name: "Mailing City",
        item_value: null,
        api_key: "mailing_city",
      },
      {
        item_name: "Mailing State",
        item_value: null,
        api_key: "mailing_state",
      },
      {
        item_name: "Mailing Zip",
        item_value: null,
        api_key: "mailing_Zip",
      },
      // {
      //   item_name: "Email 1",
      //   item_value: null,
      //   api_key: "email1",
      // },
      // {
      //   item_name: "Email 2",
      //   item_value: null,
      //   api_key: "email2",
      // },
      // {
      //   item_name: "Email 3",
      //   item_value: null,
      //   api_key: "email3",
      // },
      // {
      //   item_name: "Email 4",
      //   item_value: null,
      //   api_key: "email4",
      // },
      // {
      //   item_name: "Email 5",
      //   item_value: null,
      //   api_key: "email5",
      // },
      // {
      //   item_name: "Phone 1",
      //   item_value: null,
      //   api_key: "phone1",
      // },
      // {
      //   item_name: "Phone 2",
      //   item_value: null,
      //   api_key: "phone2",
      // },
      // {
      //   item_name: "Phone 3",
      //   item_value: null,
      //   api_key: "phone3",
      // },
      // {
      //   item_name: "Phone 4",
      //   item_value: null,
      //   api_key: "phone4",
      // },
      // {
      //   item_name: "Phone 5",
      //   item_value: null,
      //   api_key: "phone5",
      // },
      // {
      //   item_name: "Fax",
      //   item_value: null,
      //   api_key: "fax",
      // },
    ],
  },
  // {
  //   nav_title: "Custom Fields",
  //   nav_data: [
  //     {
  //       item_name: "Custom field 1",
  //       item_value: null,
  //       api_key: "custom_field_one",
  //     },
  //     {
  //       item_name: "Custom field 2",
  //       item_value: null,
  //       api_key: "custom_field_two",
  //     },
  //     {
  //       item_name: "Custom field 3",
  //       item_value: null,
  //       api_key: "custom_field_three",
  //     },
  //     {
  //       item_name: "Custom field 4",
  //       item_value: null,
  //       api_key: "custom_field_four",
  //     },
  //     {
  //       item_name: "Custom field 5",
  //       item_value: null,
  //       api_key: "custom_field_five",
  //     },
  //     {
  //       item_name: "Custom field 6",
  //       item_value: null,
  //       api_key: "custom_field_six",
  //     },
  //     {
  //       item_name: "Custom field 7",
  //       item_value: null,
  //       api_key: "custom_field_seven",
  //     },
  //     {
  //       item_name: "Custom field 8",
  //       item_value: null,
  //       api_key: "custom_field_eight",
  //     },
  //     {
  //       item_name: "Custom field 9",
  //       item_value: null,
  //       api_key: "custom_field_nine",
  //     },
  //     {
  //       item_name: "Custom field 10",
  //       item_value: null,
  //       api_key: "custom_field_ten",
  //     },
  //   ],
  // },
];

export default PrimaryData;

// const PrimaryData = [
//   {
//     nav_title: "Property Information",
//     nav_data: [
//       {
//         item_name: "APN",
//         item_value: null,
//         api_key: "apn",
//       },
//       {
//         item_name: "Property Address",
//         item_value: null,
//         api_key: "property_address",
//       },
//       {
//         item_name: "Property City",
//         item_value: null,
//         api_key: "property_city",
//       },
//       {
//         item_name: "Property County",
//         item_value: null,
//         api_key: "property_county",
//       },
//       {
//         item_name: "Property State",
//         item_value: null,
//         api_key: "property_state",
//       },
//       {
//         item_name: "Property Zip",
//         item_value: null,
//         api_key: "property_zip",
//       },
//       {
//         item_name: "Property Size Sq. Ft",
//         item_value: null,
//         api_key: "property_size_sq_ft",
//       },
//       {
//         item_name: "Short Leagal Description",
//         item_value: null,
//         api_key: "short_leagal_description",
//       },
//       {
//         item_name: "Offer Amount",
//         item_value: null,
//         api_key: "offer_amount",
//       },
//       {
//         item_name: "Offer Value Name",
//         item_value: null,
//         api_key: "offer_value_name",
//       },
//       {
//         item_name: "Market Value",
//         item_value: null,
//         api_key: "mark_value",
//       },
//       {
//         item_name: "Back Taxes",
//         item_value: null,
//         api_key: "black_texes",
//       },
//       {
//         item_name: "Zoning",
//         item_value: null,
//         api_key: "zoning",
//       },
//       {
//         item_name: "Offer Accept By",
//         item_value: null,
//         api_key: "offer_accept_by",
//       },
//       {
//         item_name: "Offer Expires on (Close of Escrow)",
//         item_value: null,
//         api_key: "offer_expire_on",
//       },
//       {
//         item_name: "Stage",
//         item_value: null,
//         api_key: "stage",
//       },
//       {
//         item_name: "Address Status",
//         item_value: null,
//         api_key: "address_status",
//       },
//     ],
//   },
//   {
//     nav_title: "Primary Contact",
//     nav_data: [
//       {
//         item_name: "Type",
//         item_value: null,
//         api_key: "Type",
//       },
//       {
//         item_name: "Full Name",
//         item_value: null,
//         api_key: "full_name",
//       },
//       {
//         item_name: "First Name",
//         item_value: null,
//         api_key: "first_name",
//       },
//       {
//         item_name: "Last Name",
//         item_value: null,
//         api_key: "last_name",
//       },
//       {
//         item_name: "Company",
//         item_value: null,
//         api_key: "company",
//       },
//       {
//         item_name: "Email 1",
//         item_value: null,
//         api_key: "email1",
//       },
//       {
//         item_name: "Email 2",
//         item_value: null,
//         api_key: "email2",
//       },
//       {
//         item_name: "Email 3",
//         item_value: null,
//         api_key: "email3",
//       },
//       {
//         item_name: "Email 4",
//         item_value: null,
//         api_key: "email4",
//       },
//       {
//         item_name: "Email 5",
//         item_value: null,
//         api_key: "email5",
//       },
//       {
//         item_name: "Phone 1",
//         item_value: null,
//         api_key: "phone1",
//       },
//       {
//         item_name: "Phone 2",
//         item_value: null,
//         api_key: "phone2",
//       },
//       {
//         item_name: "Phone 3",
//         item_value: null,
//         api_key: "phone3",
//       },
//       {
//         item_name: "Phone 4",
//         item_value: null,
//         api_key: "phone4",
//       },
//       {
//         item_name: "Phone 5",
//         item_value: null,
//         api_key: "phone5",
//       },
//       {
//         item_name: "Fax",
//         item_value: null,
//         api_key: "fax",
//       },
//       {
//         item_name: "Mailing Address",
//         item_value: null,
//         api_key: "mailing_address",
//       },
//       {
//         item_name: "Mailing Address 2",
//         item_value: null,
//         api_key: "mailing_address2",
//       },
//       {
//         item_name: "Mailing City",
//         item_value: null,
//         api_key: "mailing_city",
//       },
//       {
//         item_name: "Mailing State",
//         item_value: null,
//         api_key: "mailing_state",
//       },
//       {
//         item_name: "Mailing Zip",
//         item_value: null,
//         api_key: "mailing_Zip",
//       },
//     ],
//   },
//   {
//     nav_title: "Custom Fields",
//     nav_data: [
//       {
//         item_name: "Custom field 1",
//         item_value: null,
//         api_key: "custom_field_one",
//       },
//       {
//         item_name: "Custom field 2",
//         item_value: null,
//         api_key: "custom_field_two",
//       },
//       {
//         item_name: "Custom field 3",
//         item_value: null,
//         api_key: "custom_field_three",
//       },
//       {
//         item_name: "Custom field 4",
//         item_value: null,
//         api_key: "custom_field_four",
//       },
//       {
//         item_name: "Custom field 5",
//         item_value: null,
//         api_key: "custom_field_five",
//       },
//       {
//         item_name: "Custom field 6",
//         item_value: null,
//         api_key: "custom_field_six",
//       },
//       {
//         item_name: "Custom field 7",
//         item_value: null,
//         api_key: "custom_field_seven",
//       },
//       {
//         item_name: "Custom field 8",
//         item_value: null,
//         api_key: "custom_field_eight",
//       },
//       {
//         item_name: "Custom field 9",
//         item_value: null,
//         api_key: "custom_field_nine",
//       },
//       {
//         item_name: "Custom field 10",
//         item_value: null,
//         api_key: "custom_field_ten",
//       },
//     ],
//   },
// ];

// export default PrimaryData;
