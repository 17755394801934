import React, { useState, useEffect } from 'react';

import {
    API_COMPLETE_BATCH_DETAILS,
    API_GET_HOLIDAY_LIST,
    API_POST_DROP
} from "../../config/Api";
import moment from "moment"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Row, Col, FormGroup, Label, Input, Modal } from "reactstrap";
import Swal from "sweetalert2";


const CompleteBatchDetails = (props) => {
    const [completeBatcheDetail, setCompleteBatcheDetail] = useState([]);
    const [holidayList, setHolidayList] = useState();
    const [shipping_date,setShippingDate] = useState(new Date());
    

    useEffect(() => {
        getCompleteBatcheDetail();
        fetchHolidayList();
    }, []);

   
    
 
    const dateFormater = (inputValue) => {
        let d = new Date(inputValue * 1000);
        return moment(d).format('MM/DD/YYYY')
        // return (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear();
    };

    const getCompleteBatcheDetail = () => {
        let url = API_COMPLETE_BATCH_DETAILS + props.data.batch_id;
        props.callRequest("GET", url, true, null).then((res) => {
            setCompleteBatcheDetail(res.data);
        });
    };

    const fetchHolidayList = () => {
        props.callRequest("GET", API_GET_HOLIDAY_LIST, true).then((res) => {
          setHolidayList(res.data);
        });
    };

    let generated_holiday_list;

    if (holidayList !== undefined) {
        generated_holiday_list = holidayList.map((item) => {
        return item.split("-");
        });
    }

    var holiday_list = generated_holiday_list;


    function showHolidaysDisableWeekends(date) {
        var thisMonth = date.getMonth();
        var thisDate = date.getDate();
        var thisDay = date.getDay();
        var thisYear = date.getFullYear();
        var returnedArr = [true, ""]; // Normal day
    
        if (thisDay == 0 || thisDay == 6) {
          returnedArr = [false, "", "Cannot choose a weekend day"]; // Weekend day
        } else {
          for (let i = 0; i < holiday_list.length; i++) {
            if (
              thisMonth == holiday_list[i][0] - 1 &&
              thisDate == holiday_list[i][1] &&
              thisYear == holiday_list[i][2]
            ) {
              returnedArr = [false, "holiday"]; // Weekend holiday
            }
          }
        }
        return returnedArr[0];
      }

      const saveShippingDate = (itemDetail)=>{
        props.callRequest("PUT", API_POST_DROP+itemDetail.mailing_date_id+"/", true,{shipped_date:moment(itemDetail.shipped_date).format("MM-DD-YYYY")}).then((res) => {
            Swal.fire("Success", res.data.success, "success");
        })
      }

      const onChangeDate = (date, index)=>{
        let tempComp = [...completeBatcheDetail];
        tempComp[index]["shipped_date"] = date;
        setCompleteBatcheDetail(tempComp);
      }

    return (
        <>
            <div className="orderlist-details-block">
                {console.log(shipping_date, "shipping_date")}
                {completeBatcheDetail &&
                    completeBatcheDetail.map((item, index) => {
                        return (
                            <div className="orderlist-item-box" key={index}>
                                <div className="list-item orderid">
                                    <h5 className="item-titel">Order ID</h5>
                                    <p>{item.orderid}</p>
                                </div>
                                <div className="list-item drop-name">
                                    <h5 className="item-titel">Drop</h5>
                                    <p>{item.drop}</p>
                                </div>
                                <div className="list-item username">
                                    <h5 className="item-titel">Username</h5>
                                    <p>{item.username}</p>
                                </div>
                                <div className="list-item template-name">
                                    <h5 className="item-titel">Template Name</h5>
                                    <p>{item.template}</p>
                                </div>
                                <div className="list-item quantity">
                                    <h5 className="item-titel">Quantity</h5>
                                    <p>{item.quantity}</p>
                                </div>
                                <div className="list-item date-in">
                                    <h5 className="item-titel">Date In</h5>
                                    <p>{dateFormater(item.date_in)}</p>
                                </div>
                                <div className="list-item date-due">
                                    <h5 className="item-titel">Date Due</h5>
                                    <p>{dateFormater(item.due_date)}</p>
                                </div>
                                <div className="list-item status">
                                    <h5 className="item-titel">Status</h5>
                                    <p>{item.status}</p>
                                </div>

                                <div className="list-item status">
                                    <h5 className="item-titel">
                                        Shipping Date
                                    
                                    </h5>
                                    <div>
                                        <DatePicker
                                        dateFormat="MM-dd-yyyy"
                                        selected={"shipped_date" in item && item.shipped_date ? moment(item.shipped_date,"MM-DD-YYYY").toDate() : new Date() }
                                        name="shipped_date"
                                        id="shipped_date"
                                        onChange={(date) => {onChangeDate(date,index)}}
                                        // filterDate={isWeekday}
                                        filterDate={showHolidaysDisableWeekends}
                                        minDate={new Date()}
                                        autoComplete="off"
                                        disabled={"is_history" in item && item.is_history ==1 ? true : false }
                                        
                                    />
                                    <Button onClick={()=>saveShippingDate(item)} disabled={"is_history" in item && item.is_history ==1 ? true : false }>
                                            Save
                                    </Button>
                                    </div>
                                </div>

                                <div className="list-item status">
                                    <h5 className="item-titel">
                                    
                                    </h5>
                                    <div>
                                    
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    )
}

export default CompleteBatchDetails;