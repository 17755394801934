import React, { useState, useMemo, useEffect, useLayoutEffect } from "react";
import "./Billing.css";
import "../../assets/css/data-table.css";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import {
  API_GET_ADMIN_AFFILIATE_DETAILS,
  // API_USERS,
  API_GET_PAY_HISTORY,
} from "../../config/Api";

const PaymentHistory = (props) => {
  const paymentStatusDetail = ["Failure", "Success", "Pending ACH"];
  const [userType, SetUserType] = useState("");
  const cardDetail = [
    "--",
    "--",
    "--",
    "Amex",
    "Visa",
    "Master Card",
    "Discover",
  ];
  const [paymentHistoryData, setPaymentHistoryData] = useState(null);
  const [paymentHistoryList, setPaymentHistoryList] = useState(null);
  const [loading, setLoading] = useState(true);

  const [rows, setRows] = useState(10);
  // const [isOpen, setIsOpen] = React.useState(false);
  // const toggle = () => setIsOpen(prevState => !prevState);

  useLayoutEffect(() => {
    fetchUserType();
    //getShortPaymentDetail();
    fetchPayHisList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCardDetail = (cType, payment_mode) => {
    cType = cType && cType !== "" ? parseInt(cType) : "";
    if (cType === "") return payment_mode;
    return cardDetail[cType];
  };

  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return   d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
  };

  const fetchUserType = () => {
    props
      .callRequest("GET", API_GET_ADMIN_AFFILIATE_DETAILS, true)
      .then((res) => {
        SetUserType(res.data.user_type);
      });
  };
  const fetchPayHisList = () => {
    props
      .callRequest("GET", `${API_GET_PAY_HISTORY}`, true)
      .then((res) => {
        let allRecords = [];
        res.data.map((item) => {
          item.status_val = paymentStatusDetail[item.payment_status];
          item.amount =
            "$" +
            Number(item.amount).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          item.payment_method = getCardDetail(item.c_type, item.payment_mode);
          item.formated_date = dateFormater(item.tran_date);
          item.customer = item.user.username;
          allRecords.push(item);
        });
        setPaymentHistoryList(allRecords);
        setPaymentHistoryData(allRecords);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };
  // console.log(paymentHisList, "paymentHisList");
  // const getShortPaymentDetail = () => {
  //   props
  //     .callRequest("GET", `${API_GET_PAYMENT_HISTORY}`, true)
  //     .then((res) => {
  //       let allRecords = [];
  //       res.data.map((item) => {
  //         item.status_val = paymentStatusDetail[item.payment_status];
  //         item.amount = "$" + item.amount;
  //         item.payment_method = getCardDetail(item.c_type);
  //         item.formated_date = dateFormater(item.tran_date);
  //         allRecords.push(item);
  //       });
  //       setPaymentHistoryList(allRecords);
  //       setPaymentHistoryData(allRecords);
  //     })
  //     .catch((error) => {});
  // };
  const columns = [
    {
      name: "Date",
      selector: "formated_date",
      sortable: true,
      //cell: (row) => dateFormater(row.tran_date),
    },
    {
      name: "Payment Method",
      selector: "payment_method",
      sortable: true,
      // cell: (row) => getCardDetail(row.c_type),
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
    },
    {
      name: "Status",
      selector: "status_val",
      sortable: false,
      //cell: (row) => paymentStatusDetail[row.payment_status],
    },
    {
      name: "Card Number",
      selector: "card_no",
      sortable: true,
    },
    {
      name: "Card Holder Name",
      selector: "nameoncard",
      sortable: true,
    },
    {
      name: "Customer",
      selector: "customer",
      sortable: true,
    },
    // {
    //   name: "Actions",
    //   selector: "actions",
    //   sortable: false,
    //   cell: () => (
    //     <UncontrolledDropdown>
    //       <DropdownToggle>
    //         <i className="fas fa-ellipsis-v"></i>
    //       </DropdownToggle>
    //       <DropdownMenu>
    //         {/* <DropdownItem header>Header</DropdownItem>
    //                 <DropdownItem disabled>Action</DropdownItem>
    //                 <DropdownItem>Another Action</DropdownItem>
    //                 <DropdownItem divider /> */}
    //         <DropdownItem>View Detail</DropdownItem>
    //       </DropdownMenu>
    //     </UncontrolledDropdown>
    //   ),
    // },
  ];

  const [filterText, setFilterText] = useState("");
  const searchParam = [
    //"payment_method",
    "amount",
    "status_val",
    "card_no",
    "nameoncard",
    "customer",
    "formated_date",
  ];
  // const filteredItems =
  //   paymentHistoryData &&
  //   paymentHistoryData.filter((item) => {
  //     return Object.keys(item).some((key) =>
  //       searchParam.includes(key)
  //         ? item[key]
  //             .toString()
  //             .toLowerCase()
  //             .includes(filterText.toLowerCase())
  //         : false
  //     );
  //   });

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search by card holder name"
          aria-label="Search Input"
          autoComplete="off"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const changeRowPerPage = (perPage) => {
    setRows((prevState) => {
      return perPage;
    });
  };

  const HandleFilter = (index) => {
    if (index === 1) {
      return setPaymentHistoryData(paymentHistoryList);
    }

    if (index === 2) {
      let filteredSub = paymentHistoryList.filter((sub) => {
        return sub.payment_status == 1;
      });
      return setPaymentHistoryData(filteredSub);
    }
    if (index === 3) {
      let filteredSub = paymentHistoryList.filter((sub) => {
        return sub.payment_status == 0;
      });
      return setPaymentHistoryData(filteredSub);
    }
    if (index === 4) {
      let filteredSub = paymentHistoryList.filter((sub) => {
        return sub.payment_status == 2;
      });
      return setPaymentHistoryData(filteredSub);
    }
  };
  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={HandleFilter.bind(this, 2)}>
            Success
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 3)}>
            Failure
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 4)}>
            Pending ACH
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 1)}>
            Remove Filter
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {userType && userType == 2 ? null : (
        <UncontrolledDropdown
          disabled={paymentHistoryData.length > 0 ? false : true}
        >
          <DropdownToggle caret>
            <i className="ni ni-ungroup"></i> Export
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) => onExportView(e.target.value)}>
              Export View
            </DropdownItem>
            <DropdownItem onClick={(e) => onExport(e.target.value)}>
              Export All
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );
  const export_column = [
    "payment_method",
    "amount",
    "status_val",
    "card_no",
    "nameoncard",
    "customer",
  ];
  const actionsExport = (
    <Export
      onExport={() =>
        DataTableSettings.downloadCSV(
          paymentHistoryData,
          export_column,
          props.generateUniqueId()
        )
      }
      onExportView={() =>
        DataTableSettings.downloadCSV(
          paymentHistoryData.slice(0, rows),
          export_column,
          props.generateUniqueId()
        )
      }
    />
  );

  // const convertArrayOfObjectsToCSV = (array) => {
  //   let result;
  //   const columnDelimiter = ",";
  //   const lineDelimiter = "\n";
  //   const keys = Object.keys(data[0]);

  //   result = "";
  //   result += keys.join(columnDelimiter);
  //   result += lineDelimiter;

  //   array.forEach((item) => {
  //     let ctr = 0;
  //     columns.forEach((key) => {
  //       console.log(key.name);
  //       if (ctr > 0) result += columnDelimiter;
  //       result += item[key.name];
  //       ctr++;
  //     });
  //     result += lineDelimiter;
  //   });
  //   return result;
  // };

  // const downloadCSV = (array) => {
  //   const link = document.createElement("a");
  //   let csv = convertArrayOfObjectsToCSV(array);
  //   if (csv == null) return;
  //   const filename = props.generateUniqueId() + ".csv";
  //   if (!csv.match(/^data:text\/csv/i)) {
  //     csv = `data:text/csv;charset=utf-8,${csv}`;
  //   }

  //   link.setAttribute("href", encodeURI(csv));
  //   link.setAttribute("download", filename);
  //   link.click();
  // };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <div className="breadcrumb-block">
            <Breadcrumb>
              <BreadcrumbItem>Billing</BreadcrumbItem>
              <BreadcrumbItem active>Payment History</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-credit-card"></i>
            </span>
            Payment History
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {paymentHistoryData == null && loading === false ? (
                  <h2>No Records found !!</h2>
                ) : (
                  ""
                )}
                {loading === true ? <h3>Loading...</h3> : null}
                {paymentHistoryData && loading === false ? (
                  <DataTable
                    title="Payment History"
                    columns={columns}
                    data={DataTableSettings.filterItems(
                      paymentHistoryData,
                      searchParam,
                      filterText
                    )}
                    pagination
                    paginationPerPage={10}
                    actions={actionsExport}
                    onChangeRowsPerPage={changeRowPerPage}
                    paginationRowsPerPageOptions={[10, 25, 50, 75, 100]}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default PaymentHistory;
