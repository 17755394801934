import React, { useState, useEffect } from "react";
import "../../assets/css/data-table.css";
import Swal from "sweetalert2";
import { Button, Input, FormGroup, Form, Label } from "reactstrap";

import { API_ATTRIBUTE_TYPE } from "../../config/Api";

const AttributeTypeForm = (props) => {
  const [type_name, setTypeName] = useState("");
  const [section_name, setSectionName] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.attributeTypeId !== 0) {
      fetchAttributeTypeById();
    }
  }, [props]);

  const fetchAttributeTypeById = async () => {
    props
      .callRequest("GET", API_ATTRIBUTE_TYPE + props.attributeTypeId, true)
      .then((res) => {
        setTypeName(res.data.type_name);
        setSectionName(res.data.attribute_section.split(","));
      });
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (type_name === "") {
      formIsValid = false;
      errors["err_type_name"] = "Please provide type name";
    }
    // if (section_name.length === 0) {
    //   formIsValid = false;
    //   errors["err_section_name"] = "Please section name";
    // }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setErrors({ err_type_name: "" });
      const formData = new FormData();
      formData.append("type_name", type_name);
      if (section_name.length == 0) {
        formData.append("attribute_section", `""`);
      } else {
        formData.append("attribute_section", section_name.join());
      }

      if (props.attributeTypeId !== 0) {
        props
          .callRequest(
            "PUT",
            API_ATTRIBUTE_TYPE + props.attributeTypeId + "/",
            true,
            formData
          )
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Updated successfully",
              });
              setTimeout(
                function () {
                  setTypeName("");
                  props.modalToggle();
                  props.fetchAttributeType();
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      } else {
        props
          .callRequest("POST", API_ATTRIBUTE_TYPE, true, formData)
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Saved successfully",
              });
              setTimeout(
                function () {
                  setTypeName("");
                  props.modalToggle();
                  props.fetchAttributeType();
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            }); //Attribute Type List
            if (
              error.response.data.type_name[0] ==
              "attribute type with this type name already exists."
            ) {
              Toast.fire({
                icon: "error",
                title: "Attribute type with this type name already exists!",
              });
            } else {
              Toast.fire({
                icon: "error",
                title: "Something went wrong!",
              });
            }
            //  console.log(error.response.data.type_name[0], "err");
          });
      }
    }
  };

  return (
    <>
      <div className="popup-form-block">
        <Form>
          <FormGroup>
            <Label for="typeName">
              Name <span className="text-danger">*</span>
            </Label>
            <Input
              type="text"
              autoComplete="off"
              name="type_name"
              id="type_name"
              className="form-control"
              value={type_name}
              onChange={(e) => setTypeName(e.target.value)}
              readOnly={props.attributeTypeId ? true : false}
            />
            <label className="red_err">{errors.err_type_name}</label>
          </FormGroup>
          <FormGroup>
            <Label for="section">
              Section Used: <span className="text-danger">*</span>
            </Label>
            <Input
              type="select"
              name="attribute_section"
              id="section"
              className="form-control"
              value={section_name}
              onChange={(e) => {
                var options = e.target.options;
                var value = [];
                for (var i = 0, l = options.length; i < l; i++) {
                  if (options[i].selected) {
                    value.push(options[i].value);
                  }
                }
                setSectionName(value);
              }}
              multiple
            >
              <option value="Reiprintmail_Default">
                Reiprintmail Default Products
              </option>
              <option value="Api_Order_Default">
                Api Order Default Products
              </option>
              <option value="Probate_Order_Default">
                Probate Order Default Products
              </option>
            </Input>
            <label className="red_err">{errors.err_section_name}</label>
          </FormGroup>

          <div className="popup-button-block">
            <Button onClick={handleSubmit}>
              Save Changes <i className="ni ni-bold-right"></i>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AttributeTypeForm;
