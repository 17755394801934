import React, { useEffect, useState } from "react";
import { BreadCrumb } from "../../config/Components";
import classnames from "classnames";
import "../../assets/css/importTemplate.css";
import Swal from "sweetalert2";
import {
  PropertyInformation,
  PrimaryContact,
  SecondaryContact,
} from "../../config/Components";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  Form,
  CardImg,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import {
  API_CART_DETAILS,
  API_GET_ORDER_DETAIL,
  API_ATTRIBUTES,
  API_GET_ATTRIBUTE_TYPE_LIST
} from "../../config/Api";
import CreateOrderApproval from "./CreateOrderApproval";
import AddProductOrder from "./AddProductOrder";
import ProductDetail from "./ProductDetail";
import AddMailingList from "./AddMailingList";

const EditCartApproval = (props) => {
  const [cartId, setCartId] = useState(props.match.params.cartId);
  const [activeTab, setActiveTab] = useState("1");
  const [createcampaignModal, setcreatecampaignModal] = useState(false);
  const [addMailingListModal, setaddMailingListModal] = useState(false);
  const [createcampaignModal2, setcreatecampaignModal2] = useState(false);
  const [cartDetail, setCartDetail] = useState(null);
  const [orderDetail, setOrderDetail] = useState();

  const [prdId, setPrdId] = useState(0);

  const [postage, setPostage] = useState([]);
  const [envelope, setEnvelope] = useState([]);
  const [paper, setPaper] = useState([]);
  const [ink, setInk] = useState([]);
  const [accTXT, setAccTXT] = useState([]);
  const [accuPIX, setAccuPIX] = useState([]);
  const [attributes, setAttributes] = useState([]);

  const [addProductOrderModal, setAddProductOrderModal] = useState(false);
  const [productDetailModal, setProductDetailModal] = useState(false);

  const addProductOrder = () => setAddProductOrderModal(!addProductOrderModal);
  const chooseDesignDetails = () => setProductDetailModal(!productDetailModal);

  const [editProductFlag, setEditProductFlag] = useState(false);

  const [SinglePrd, setSinglePrd] = useState([]);

  const [image, setImage] = useState();

  const [selectedUserDetail, setSelectedUserDetail] = useState();

  const [addMailList, setAddMailList] = useState({
    mailinglist_attr_id: "",
    desired_quantity: 0,
    mailing_list_type: "",
    price_amount: "",
  });
  const [attributeTypes ,setAttributeTypes] = useState({
    postage_id: null,
    ink_type_id: null,
    paper_type_id: null,
    envelope_type_id: null,
    accuTXT_type_id:null,
    accuPIX_type_id:null
  }
  )
  const [productList, setProductList] = useState([]);

  React.useEffect(()=>{
    props.callRequest("GET",API_GET_ATTRIBUTE_TYPE_LIST).then(res=>{
      var tempAttrTypeId = {
        postage_id: null,
        ink_type_id: null,
        paper_type_id: null,
        envelope_type_id: null,
        accuTXT_type_id:null,
        accuPIX_type_id:null
      }
      
      for(let i=0;i<res.data.length;i++){
        if(res.data[i]["type_name"].toLowerCase() == "postage"){
          tempAttrTypeId.postage_id = res.data[i]["type_id"] 
        }

        if(res.data[i]["type_name"].toLowerCase().includes("ink")){
          tempAttrTypeId.ink_type_id = res.data[i]["type_id"] 
        }

        if(res.data[i]["type_name"].toLowerCase().includes("paper")){
          tempAttrTypeId.paper_type_id = res.data[i]["type_id"] 
        }

        if(res.data[i]["type_name"].toLowerCase().includes("envelope")){
          tempAttrTypeId.envelope_type_id = res.data[i]["type_id"] 
        }

        //accuTXT_type_id

        if(res.data[i]["type_name"].toLowerCase().includes("accutxt")){
          tempAttrTypeId.accuTXT_type_id = res.data[i]["type_id"] 
        }

        if(res.data[i]["type_name"].toLowerCase().includes("accupix")){
          tempAttrTypeId.accuPIX_type_id = res.data[i]["type_id"] 
        }

      }
      setAttributeTypes({...tempAttrTypeId})
    })
  },[]);

  const sendImage = (image) => {
    setImage(image);
  };

  useEffect(() => {
    getCartDetail();
    // getOrderDetail(3);
    // fetchAttributesType();
  }, []);

  useEffect(() => {
    fetchAttributesType();
  }, [attributeTypes]);

  const fetchAttributesType = () => {
    props.callRequest("GET", API_ATTRIBUTES, true).then((res) => {
      setAttributes(res.data);
      
      const ink_type = res.data.filter((attr) => {
        return Number(attr.attributetype_id) ==attributeTypes.ink_type_id;;
      });
      const paper_type = res.data.filter((attr) => {
        return Number(attr.attributetype_id) ==attributeTypes.paper_type_id;
      });
      const postage_type = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.postage_id;
      });
      const envelope_type = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.envelope_type_id;
      });

      const AccTXT = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.accuTXT_type_id;
      });

      const AccuPIX = res.data.filter((attr) => {
        return Number(attr.attributetype_id) == attributeTypes.accuPIX_type_id;
      });
      
      setAccTXT(AccTXT);
      setAccuPIX(AccuPIX);

      setInk(ink_type);
      setPaper(paper_type);
      setPostage(postage_type);
      setEnvelope(envelope_type);
    });
  };
  // console.log(selectedUserDetail, "userDetail");
  const getCartDetail = () => {
    Swal.fire({
      title: "Cart is loading....",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    props
      .callRequest("GET", API_CART_DETAILS + cartId, true, null)
      .then((res) => {
        res.data.Product = res.data.Product.reverse(); 
        for(let i=0;i<res.data.Product.length;i++){
          res.data.Product[i]['category_id'] = 'sub_category_id' in res.data.Product[i] ? res.data.Product[i]['sub_category_id']:0 
          res.data.Product[i]['category_name'] = 'sub_category_name' in res.data.Product[i] ? res.data.Product[i]['sub_category_name']:"" 
          
        }

        setCartDetail(res.data);


        if (
          res.data &&
          "Mailinglist" in res.data &&
          res.data.Mailinglist &&
          res.data.Mailinglist.length > 0
        ) {
          setAddMailList({
            mailinglist_attr_id: res.data.Mailinglist[0].mailinglist_attr_id,
            desired_quantity: res.data.Mailinglist[0].mailingqty,
            mailing_list_type: "",
            price_amount: res.data.Mailinglist[0].per_mailer,
          });
        }
        res.data &&
          "CartDetails" in res.data &&
          res.data["CartDetails"] &&
          "orderid" in res.data["CartDetails"] &&
          res.data["CartDetails"]["orderid"] != "" &&
          getOrderDetail(res.data["CartDetails"]["orderid"]);

        let userDetail =
          "CartDetails" in res.data &&
          "user" in res.data.CartDetails &&
          res.data.CartDetails.user;
        setSelectedUserDetail(userDetail);
        Swal.close();
      })
      .catch((e) => {
        Swal.close();
      });
  };

  const getOrderDetail = (orderId) => {
    props
      .callRequest("GET", API_GET_ORDER_DETAIL + orderId, true, null)
      .then((res) => {
        setOrderDetail(res.data);
      });
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleNext = () => {
    props.history.push("/crm-contacts");
    // props.history.push("/admin/crm-contacts");
  };

  const popupCreateOrderApproval = () =>
    setcreatecampaignModal(!createcampaignModal);
  const addMailingList = () => setaddMailingListModal(!addMailingListModal);
  const popupCreateOrderApproval2 = () =>
    setcreatecampaignModal2(!createcampaignModal2);

  const handleNext1 = () => {
    popupCreateOrderApproval();
    addMailingList();
  };

  const handleNext2 = () => {
    addMailingList();
    popupCreateOrderApproval2();
  };

  const selectedProduct = (productInfo) => {
    if(!("sub_category_id" in productInfo)){
      productInfo.sub_category_id = childCategoryId;
      productInfo.sub_category_name = childCategory;
      productInfo.category_id = childCategoryId;
      productInfo.category_name = childCategory;
    }
    let detail = { ...cartDetail };
    detail.Product.push({
      mailer: "DROP " + (detail.Product.length + 1),
      ...productInfo,
    });

    // console.log("detail", detail);
    setCartDetail(detail);
  };

  const setSingleProductId = (id) => {
    setPrdId(id);
  };

  const setMailingListData = (mailing_list) => {
    setAddMailList(mailing_list);
    mailing_list && setaddMailingListModal(!addMailingListModal);
  };
  const [selectedProductForCart,setSelectedProductForCart] = useState();
  const [singleProduct, setSingleProduct] = useState([]);
  const [childCategory, setChildCategory] = useState();
  const [childCategoryId, setChildCategoryId] = useState();

  const sendSingleProduct = (item) => {
    setSelectedProductForCart({...item})
    setSinglePrd(item);
    singleProduct.push(item);
    setSingleProduct(singleProduct);
  };

  const sendCategory = (id,name) => {
    setChildCategory(name);
    setChildCategoryId(id);
  };

  useEffect(()=>{
    productList && productList.length>0 && setCartDetail({...cartDetail,Product:productList})
  },[productList])

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          {/* <BreadCrumb links={["Cart Detail"]} /> */}

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            Cart Detail
          </h1>
        </div>
        <div className="create-order-main-block">
          {cartDetail != null && (
            <CreateOrderApproval
              {...props}
              campaignDetail={cartDetail.CartDetails.campaign}
              campaignData={cartDetail.CartDetails.campaign}
              mailingListDetail={addMailList}
              selectedUserDetail={cartDetail.CartDetails.user}
              affiliateDetail={null}
              permissionDetail={props.permissions}
              selectedProduct={cartDetail.Product}
              edittedProductFlag={false}
              productList={cartDetail.Product}
              edittedProductList={cartDetail.Product}
              singleProduct={null}
              addMailList={addMailList}
              draftOrderDetail={null}
              redirectPath={"/cart-list"}
              postage={postage}
              envelope={envelope}
              paper={paper}
              ink={ink}
              accTXT={accTXT}
              accuPIX={accuPIX}
              attributes={attributes}
              addProductOrder={() => addProductOrder()}
              setMailingListData={setMailingListData}
              eventType={"edit-cart"}
              orderStatus={false}
              setProductList={setProductList}
              // redirectPath={"/admin/cart-list"}
            />
          )}
        </div>

        {/* Add Product Order */}
        <Modal
          isOpen={addProductOrderModal}
          toggle={addProductOrder}
          className="add-product-to-order-modal"
        >
          <ModalBody>
            <AddProductOrder
              {...props}
              sendImage={sendImage}
              sendSingleProduct={sendSingleProduct}
              setSingleProductId={setSingleProductId}
              addProductOrder={addProductOrder}
              chooseDesignDetails={chooseDesignDetails}
              SelectedUserId={selectedUserDetail && selectedUserDetail.user_id}
              sendCategory={sendCategory}
            />
            <div className="row">
              <div className="col-sm-12 text-right">
                <Button color="secondary" onClick={addProductOrder}>
                  Close
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* End of Add Product Order */}
        <Modal
          isOpen={productDetailModal}
          toggle={chooseDesignDetails}
          className="show-product-details-modal"
        >
          <ModalHeader toggle={chooseDesignDetails}>
            Manage Cart Item
          </ModalHeader>
          <ModalBody>
            
            <ProductDetail
              {...props}
              invImage={image}
              sendImage={sendImage}
              sendSingleProduct={sendSingleProduct}
              SinglePrd={SinglePrd}
              prdId={prdId}
              chooseDesignDetails={chooseDesignDetails}
              selectedProduct={selectedProduct}
              edittedProductFlag={(editProduct) =>
                setEditProductFlag(editProduct)
              }
              editProductFlag={editProductFlag}
              modalToggle={() => setAddProductOrderModal(!addProductOrderModal)}
              selectedUserDetail={selectedUserDetail}
              postage={postage}
              envelope={envelope}
              paper={paper}
              ink={ink}
              accTXT={accTXT}
              accuPIX={accuPIX}
              attributes={attributes}
              campaignName ={cartDetail && "CartDetails" in cartDetail && cartDetail.CartDetails.campaign && "campaign_name" in cartDetail.CartDetails.campaign && cartDetail.CartDetails.campaign.campaign_name ? cartDetail.CartDetails.campaign.campaign_name : null}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={addMailingListModal}
          toggle={addMailingList}
          className="add-mailing-list-modal"
        >
          <ModalHeader toggle={addMailingList}>Add Mailing List</ModalHeader>
          <ModalBody>
            <AddMailingList
              {...props}
              addMailingList={setMailingListData}
              addMailListData={addMailList}
            />
          </ModalBody>
        </Modal>
      </main>
    </>
  );
};

export default withRouter(EditCartApproval);
