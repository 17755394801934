import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { BreadCrumb } from "../../config/Components";
import {
  API_PRODUCTS,
  API_GET_AFFILIATE,
  API_PARENT_CATEGORY,
  API_GET_PRODUCT,
} from "../../config/Api";

import DataTableSettings from "../../utils/DataTableSettings";

import "../../assets/css/data-table.css";

const Products = (props) => {
  const userType = useSelector((store) => store.getUserType);
  const [products, setProducts] = useState([]);
  // const [userType, SetUserType] = useState("");
  const [productList, setProductList] = useState([]);
  const [parent_categories, setParentCategory] = useState([]);
  //const [modal, setModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [affiliates, setAffiliates] = useState([]);
  const [loading, setLoading] = useState(true);
  const searchParam = [
    "product_name",
    "description",
    "template_code",
    "product_id",
    "affiliate",
  ];
  const [rows, setRows] = useState(10);
  useEffect(() => {
    //fetchUserType();
    fetchProducts();
    fetchAffiliates();
    fetchParentCategory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const fetchUserType = () => {
  //   props
  //     .callRequest("GET", API_GET_ADMIN_AFFILIATE_DETAILS, true)
  //     .then((res) => {
  //       SetUserType(res.data.user_type);
  //     });
  // };
  const fetchProducts = () => {
    props.callRequest("GET", API_PRODUCTS, true).then((res) => {
      setProductList(res.data);
      setProducts(res.data);
      setLoading(false);
    });
  };
  const fetchAffiliates = () => {
    props.callRequest("GET", API_GET_AFFILIATE, true).then((res) => {
      setAffiliates(res.data);
    });
  };
  const fetchParentCategory = () => {
    props.callRequest("GET", API_PARENT_CATEGORY, true).then((res) => {
      setParentCategory(res.data);
    });
  };
  const onDelete = (product_id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("DELETE", API_PRODUCTS + product_id, true)
          .then((res) => {
            // if (res.statusText === "No Content") {
            if (res.status) {
              fetchProducts();
              Swal.fire("Deleted!", "", "success");
            }
          });
      }
    });
  };

  const onDuplicate = (product_id) => {
    props.callRequest("GET", API_GET_PRODUCT + product_id, true).then((res) => {
      let duplicate_product_name =
        res.data.product_name +
        " duplicate " +
        Math.random().toString(36).substr(2, 3);
      props
        .callRequest("POST", API_PRODUCTS, true, {
          FullSizeImageName: res.data.FullSizeImageName,
          FullSizeImagePath: res.data.FullSizeImagePath,
          affiliate: res.data.affiliate,
          api_product: res.data.api_product,
          attribute_api: res.data.attribute_api,
          attribute_default: res.data.attribute_default,
          attribute_probate: res.data.attribute_probate,
          date_added: res.data.date_added,
          description: res.data.description,
          image_name: res.data.image_name,
          image_option: res.data.image_option,
          imagepathname: res.data.imagepathname,
          is_active: res.data.is_active,
          is_all_affiliate: res.data.is_all_affiliate,
          is_micro_campaign: res.data.is_micro_campaign,
          lockout_date: res.data.lockout_date,
          mailing_date_limit: res.data.mailing_date_limit,
          micro_campaign_price: res.data.micro_campaign_price,
          min_qnty: res.data.min_qnty,
          min_qnty_txt: res.data.min_qnty_txt,
          ordering: res.data.ordering,
          parent_category: res.data.parent_category,
          price: res.data.price,
          probate_product: res.data.probate_product,
          probate_tier: res.data.probate_tier,
          product_code: res.data.product_code,
          product_name: duplicate_product_name,
          product_type: res.data.product_type,
          reiprintmail_product: res.data.reiprintmail_product,
          sub_category: res.data.sub_category,
          template: res.data.template,
          sub_category: [res.data.sub_category[0].category_id],
          template: [res.data.template[0].template_id],
          template_code: res.data.template_code,
          tier2_price: res.data.tier2_price,
          tier3_price: res.data.tier3_price,
          user: res.data.user,
          user_photo: res.data.user_photo,
        })
        .then((res) => {
          if (res.status) {
            fetchProducts();
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title:
                "Duplicate product has been created successfully. Please check top on first page.",
            });
          }
        });
    });
  };

  const HandleFilter = (index) => {
    if (index === 1) {
      setProducts(productList);
    }

    if (index === 2) {
      let filteredSub = productList.filter((sub) => {
        return sub.is_active === "1";
      });
      setProducts(filteredSub);
    }
    if (index === 3) {
      let filteredSub = productList.filter((sub) => {
        return sub.is_active === "0";
      });
      setProducts(filteredSub);
    }
  };

  const columns = [
    {
      name: "Product Id",
      selector: "product_id",
      sortable: true,
    },
    {
      name: "Product Name",
      selector: "product_name",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
    },
    // {
    //   name: "Category",
    //   selector: "category",
    //   sortable: true,
    //   cell: (row) => (
    //     <div>
    //       {row.category.map((rowCat) => {
    //         if (rowCat === row.category[row.category.length - 1]) {
    //           return rowCat.category_name;
    //         } else {
    //           return rowCat.category_name + " , ";
    //         }
    //       })}
    //     </div>
    //   ),
    // },
    {
      name: "Category",
      selector: "parent_category",
      sortable: true,
      cell: (row) => (
        <div>
          {parent_categories.map((rowCat) => {
            if (rowCat.category_id === row.parent_category) {
              return rowCat.category_name;
            }
          })}
        </div>
      ),
    },
    {
      name: "Template Code",
      selector: "template_code",
      sortable: true,
    },
    {
      name: "Sort Order",
      selector: "ordering",
      sortable: true,
    },
    {
      name: "Affiliate",
      selector: "affiliate",
      sortable: true,
      cell: (row) => (
        <div>
          {row.is_all_affiliate === "1"
            ? "All Affiliates"
            : row.affiliate.length !== 0 &&
            row.affiliate.map((rowAff) => {
              return affiliates.map((aff) => {
                if (aff.affiliate_id.id === rowAff) {
                  if (rowAff === row.affiliate[row.affiliate.length - 1]) {
                    return aff.affiliate_id.name;
                  } else {
                    return aff.affiliate_id.name + " , ";
                  }
                }
              });
            })}
        </div>
      ),
    },
    {
      name: "Image",
      selector: "imagepathname",
      sortable: false,
      cell: (row) => (
        <img
          alt=""
          src={
            row.imagepathname
              ? process.env.REACT_APP_PUBLIC_URL_PHOTOPATH + row.imagepathname
              : row.FullSizeImagePath
                ? process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
                row.FullSizeImagePath
                : require("../../assets/img/product-default-image.png").default
          }
          width="20"
        />
      ),
    },
    {
      name: "Status",
      selector: "is_active",
      sortable: true,
      cell: (row) => <div>{row.is_active === "1" ? "Active" : "Inactive"}</div>,
    },

    {
      name: userType && userType == 2 ? null : "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) =>
        userType && userType == 2 ? null : (
          <UncontrolledDropdown>
            <DropdownToggle>
              <i className="fas fa-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu positionFixed={true}>
              <DropdownItem
                onClick={() => {
                  props.history.push("/edit-product/" + row.product_id);
                }}
              >
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => onDelete(row.product_id)}>
                Delete
              </DropdownItem>
              <DropdownItem onClick={() => onDuplicate(row.product_id)}>
                Duplicate
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
    },
  ];

  const export_column = [
    "product_name",
    "product_name",
    "description",
    "template_code",
    "ordering",
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Product"
          value={filterText}
          autoComplete="off"
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      {userType && userType == 2 ? null : (
        <Button onClick={() => props.history.push("/add-product")}>
          <i className="ni ni-fat-add"></i> Add Product
        </Button>
      )}
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={HandleFilter.bind(this, 2)}>
            Active
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 3)}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 1)}>
            Remove Filter
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {userType && userType == 2 ? null : (
        <UncontrolledDropdown disabled={products.length > 0 ? false : true}>
          <DropdownToggle caret>
            <i className="ni ni-ungroup"></i> Export
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) => onExportView(e.target.value)}>
              Export View
            </DropdownItem>
            <DropdownItem onClick={(e) => onExport(e.target.value)}>
              Export All
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            products,
            export_column,
            props.generateUniqueId()
          )
        }
        onExportView={() =>
          DataTableSettings.downloadCSV(
            products.slice(0, rows),
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [products]
  );
  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };
  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Store Front", "Product"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-bag-17"></i>
            </span>
            Products
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <DataTable
                    columns={columns}
                    data={DataTableSettings.filterItems(
                      products,
                      searchParam,
                      filterText
                    )}
                    pagination
                    paginationPerPage={DataTableSettings.paginationPerPage}
                    paginationRowsPerPageOptions={
                      DataTableSettings.paginationRowsPerPageOptions
                    }
                    actions={actionsExport}
                    subHeader
                    onChangeRowsPerPage={changeRowPerPage}
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default Products;

/* import * as React from "react";
import { XGrid } from "@material-ui/x-grid";
import { useDemoData } from "@material-ui/x-grid-data-generator";

export default function Products() {
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 200,
    maxColumns: 6,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        id: 1,
        columnField: "commodity",
        operatorValue: "contains",
        value: "rice",
      },
      { id: 2, columnField: "quantity", operatorValue: ">=", value: "20000" },
    ],
  });

  return (
    <div style={{ height: 400, width: "100%", marginTop: "100px" }}>
      <XGrid
        {...data}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
      />
    </div>
  );
} */
