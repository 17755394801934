import React, { useState, useEffect } from "react";
import "./AddProduct.css";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";
import { BreadCrumb } from "../../config/Components";
import {
  API_COUPONS,
  API_GET_COUPON,
  API_GET_AFFILIATE,
} from "../../config/Api";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const AddCoupon = (props) => {
  const couponInfo = {
    coupon_id:
      props.match.params.couponid !== 0 ? props.match.params.couponid : "",
    coupon_code: "",
    discount: "",
    min_qty: "",
    down_price: "",
    up_price: "",
    is_active: 0,
    start_date: new Date(),
    expiration_date: new Date(),
    discount_type: "",
    coupon_type: "",
    affiliate: "",
  };
  const [affiliates, setAffiliates] = useState([]);
  const [coupons, setCoupons] = useState(couponInfo);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (typeof coupons.coupon_id !== "undefined" && coupons.coupon_id > 0) {
      fetchCouponById();
    }
    fetchAffiliates();
  }, [props]);

  const fetchCouponById = async () => {
    props
      .callRequest("GET", API_GET_COUPON + coupons.coupon_id, true)
      .then((res) => {
        setCoupons({
          ...coupons,
          coupon_code: res.data.coupon_code,
          discount: res.data.discount,
          min_qty: res.data.min_qty,
          down_price: res.data.down_price,
          up_price: res.data.up_price,
          is_active: res.data.is_active,
          discount_type: res.data.discount_type,
          coupon_type: res.data.coupon_type,
          affiliate: res.data.affiliate,
          start_date: setUtcDateFormat(res.data.start_date),
          expiration_date: setUtcDateFormat(res.data.expiration_date),
        });
      });
  };
  const fetchAffiliates = () => {
    props.callRequest("GET", API_GET_AFFILIATE, true).then((res) => {
      setAffiliates(res.data);
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoupons({ ...coupons, [name]: value });
  };

  const handleDateChange = (e, date) => {
    if (date === "start_date") {
      setCoupons({ ...coupons, start_date: e });
    }
    if (date === "expiration_date") {
      setCoupons({ ...coupons, expiration_date: e });
    }
  };

  const formattedDate = (date) => {
    return (
      date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
    );
  };
  const setUtcDateFormat = (timestamp) => {
    let date = new Date(timestamp * 1000);
    return date;
  };

  const validateForm = () => {
    let {
      coupon_code,
      discount,
      min_qty,
      down_price,
      up_price,
      discount_type,
      coupon_type,
      start_date,
      expiration_date,
    } = coupons;

    let formIsValid = true;
    let errors = {};
    if (coupon_code === "") {
      formIsValid = false;
      errors["err_coupon_code"] = "Please provide coupon code";
    }
    if (discount === "") {
      formIsValid = false;
      errors["err_discount"] = "Please provide discount";
    }
    if (min_qty === "") {
      formIsValid = false;
      errors["err_min_qty"] = "Please provide minimum quantity";
    }

    if (discount_type === "") {
      formIsValid = false;
      errors["err_discount_type"] = "Please provide discount type";
    }
    if (discount_type == 2) {
      if (down_price === "") {
        formIsValid = false;
        errors["err_down_price"] = "Please provide down price";
      }
      if (up_price === "") {
        formIsValid = false;
        errors["err_up_price"] = "Please provide up price";
      }
    }
    if (coupon_type === "") {
      formIsValid = false;
      errors["err_coupon_type"] = "Please provide coupon type";
    }
    if (start_date === null) {
      formIsValid = false;
      errors["err_start_date"] = "Please provide start date";
    }
    if (expiration_date === null) {
      formIsValid = false;
      errors["err_expiration_date"] = "Please provide expiration date";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      formData.append("coupon_code", coupons.coupon_code);
      formData.append("discount", coupons.discount);
      formData.append("min_qty", coupons.min_qty);

      formData.append("is_active", coupons.is_active);
      formData.append("discount_type", coupons.discount_type);
      formData.append("coupon_type", coupons.coupon_type);
      formData.append("start_date", formattedDate(coupons.start_date));
      formData.append(
        "expiration_date",
        formattedDate(coupons.expiration_date)
      );
      if (coupons.discount_type == 2) {
        formData.append("down_price", coupons.down_price);
        formData.append("up_price", coupons.up_price);
      } else {
        formData.append("down_price", 0);
        formData.append("up_price", 0);
      }

      if (coupons.affiliate !== "") {
        formData.append("affiliate", coupons.affiliate);
      }
      if (
        (coupons.affiliate == null) | (coupons.affiliate == "") &&
        coupons.coupon_id > 0
      ) {
        formData.append("affiliate", `''`);
      }
      // for (var key in coupons) {
      //   if (key === "start_date") {
      //     formData.append(key, formattedDate(coupons[key]));
      //   } else if (key === "expiration_date") {
      //     formData.append(key, formattedDate(coupons[key]));
      //   } else {
      //     formData.append(key, coupons[key]);
      //   }
      // }
      if (coupons.coupon_id > 0) {
        props
          .callRequest(
            "PUT",
            API_COUPONS + coupons.coupon_id + "/",
            true,
            formData
          )
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Updated successfully",
              });
              setTimeout(
                function () {
                  props.history.push("/coupons");
                  // props.history.push("/admin/coupons");
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      } else {
        props
          .callRequest("POST", API_COUPONS, true, formData)
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Saved successfully",
              });
              setTimeout(
                function () {
                  props.history.push("/coupons");
                  // props.history.push("/admin/coupons");
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      }
    }
  };

  const handleClose = () => {
    props.history.push("/coupons");
    // props.history.push("/admin/coupons");
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          {coupons.coupon_id > 0 ? (
            <BreadCrumb links={["Store Front", "Coupon", "Edit Coupon"]} />
          ) : (
            <BreadCrumb links={["Store Front", "Coupon", "Add Coupon"]} />
          )}
          {coupons.coupon_id > 0 ? (
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-bag-17"></i>
              </span>
              Edit Coupon
            </h1>
          ) : (
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-bag-17"></i>
              </span>
              Add Coupon
            </h1>
          )}
        </div>

        <div className="add-Product-block payment-processing-block">
          <Row>
            <Col sm="8" lg="9">
              <Card>
                <CardBody>
                  <Form autocomplete="off">
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="couponCode">
                            Coupon Code:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="coupon_code"
                            id="coupon_code"
                            autoComplete="off"
                            value={coupons.coupon_code}
                            onChange={handleChange}
                            placeholder="Enter Coupon Code"
                          />
                          <label className="red_err">
                            {errors.err_coupon_code}
                          </label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="discount">
                            Discount:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            name="discount"
                            id="discount"
                            autoComplete="off"
                            value={coupons.discount}
                            onChange={handleChange}
                            placeholder="Enter Discount"
                          />
                          <label className="red_err">
                            {errors.err_discount}
                          </label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="minimumQuantity">
                            Minimum Quantity:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            name="min_qty"
                            id="min_qty"
                            autoComplete="off"
                            placeholder="0"
                            value={coupons.min_qty}
                            onChange={handleChange}
                          />
                          <label className="red_err">
                            {errors.err_min_qty}
                          </label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="couponType">
                            Coupon Type
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            id="coupon_type"
                            name="coupon_type"
                            value={coupons.coupon_type}
                            onChange={handleChange}
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="">Select Coupon Type</option>
                            <option value="0">One Time Only</option>
                            <option value="1">Repeat</option>
                          </select>
                          <label className="red_err">
                            {errors.err_coupon_type}
                          </label>
                        </FormGroup>
                      </Col>
                      {/* <Col md={6}>
                        <FormGroup>
                          <Label for="minimumQuantity">Select Affiliate:</Label>
                          <select
                            id="affiliate"
                            name="affiliate"
                            value={coupons.affiliate}
                            onChange={handleChange}
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="">Select Affiliate</option>
                            {affiliates &&
                              affiliates.map((aff) => {
                                return (
                                  <option value={aff.affiliate_id.id}>
                                    {aff.affiliate_id.name}
                                  </option>
                                );
                              })}
                          </select>
                        </FormGroup>
                      </Col> */}
                    </Row>
                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="discountType">
                            Discount Type:
                            <span className="text-danger">*</span>
                          </Label>
                          <select
                            id="discount_type"
                            name="discount_type"
                            value={coupons.discount_type}
                            onChange={handleChange}
                            className="form-control"
                            aria-label="Default select example"
                          >
                            <option value="">Select Discount Type</option>
                            <option value="0">Printing Only</option>
                            <option value="1">Printing & Postage</option>
                            <option value="2">Flat Rate</option>
                            <option value="3">Price Override</option>
                          </select>
                          <label className="red_err">
                            {errors.err_discount_type}
                          </label>
                        </FormGroup>
                      </Col>

                      {coupons.discount_type && coupons.discount_type == 2 ? (
                        <>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="down_price">
                                Price Range Low:
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                name="down_price"
                                id="down_price"
                                autoComplete="off"
                                placeholder="Enter Down Price"
                                value={coupons.down_price}
                                onChange={handleChange}
                              />
                              <label className="red_err">
                                {errors.err_down_price}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col md={3}>
                            <FormGroup>
                              <Label for="up_price">
                                Price Range High:
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="number"
                                name="up_price"
                                id="up_price"
                                autoComplete="off"
                                placeholder="Enter Up Price"
                                value={coupons.up_price}
                                onChange={handleChange}
                              />
                              <label className="red_err">
                                {errors.err_up_price}
                              </label>
                            </FormGroup>
                          </Col>
                        </>
                      ) : null}

                      <Col md={6}>
                        <FormGroup>
                          <Label for="startDate">
                            Start Date:
                            <span className="text-danger">*</span>
                          </Label>
                          <DatePicker
                            selected={coupons.start_date}
                            onChange={(e) => handleDateChange(e, "start_date")}
                            name="start_date"
                            dateFormat="MM/dd/yyyy"
                            isClearable
                            fixedHeight
                            placeholderText="mm/dd/yyyy"
                          />
                          <label className="red_err">
                            {errors.err_start_date}
                          </label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="expirationDate">
                            Expiration Date:
                            <span className="text-danger">*</span>
                          </Label>
                          <DatePicker
                            selected={coupons.expiration_date}
                            onChange={(e) =>
                              handleDateChange(e, "expiration_date")
                            }
                            name="expiration_date"
                            dateFormat="MM/dd/yyyy"
                            isClearable
                            fixedHeight
                            placeholderText="mm/dd/yyyy"
                          />
                          <label className="red_err">
                            {errors.err_expiration_date}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <div className="status-form-box">
                      <div className="oneline-redio-block">
                        <FormGroup>
                          <Label for="status" className="oneline-left-titel">
                            Status
                          </Label>
                          <div className="oneline-redio-list">
                            <CustomInput
                              type="radio"
                              id="is_active"
                              name="is_active"
                              value="1"
                              checked={coupons.is_active === "1" ? true : false}
                              onChange={handleChange}
                              label="Active"
                            />
                            <CustomInput
                              type="radio"
                              id="is_inactive"
                              name="is_active"
                              value="0"
                              checked={coupons.is_active !== "1" ? true : false}
                              onChange={handleChange}
                              label="Inactive"
                            />
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="submit-button-block">
                      <Button className="cancel-button" onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button onClick={handleSubmit}>
                        Save Changes <i className="ni ni-bold-right"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default withRouter(AddCoupon);
