import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";
import {
  API_USERS,
  API_USER_GROUPS,
  API_GET_AFFILIATE,
  API_GET_ROLES,
  API_GET_ADMIN_AFFILIATE_DETAILS,
  API_GET_SALES_REP,
} from "../../config/Api";

const UserList = (props) => {
  const [users, setUsers] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [userGroups, setUserGroups] = useState([]);
  // const [userRoles, setRoles] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [rows, setRows] = useState(10);
  const [salesRep, setSalesRep] = useState([]);
  // const [userType, SetUserType] = useState("");
  const searchParam = ["firstname", "lastname", "email", "username"];
  const userType = useSelector((store) => store.getUserType);
  useEffect(() => {
    //fetchUserType();
    fetchUsers();
    fetchUserGroups();
    fetchAffiliates();
    // fetchRoles();
    fetchSalesRep();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const fetchUserType = () => {
  //   props
  //     .callRequest("GET", API_GET_ADMIN_AFFILIATE_DETAILS, true)
  //     .then((res) => {
  //       SetUserType(res.data.user_type);
  //     });
  // };
  const fetchUsers = async () => {
    props.callRequest("GET", API_USERS, true).then((res) => {
      setUserList(res.data);
      setUsers(res.data);
      setLoading(false);
    });
  };
  const fetchUserGroups = async () => {
    props.callRequest("GET", API_USER_GROUPS, true).then((res) => {
      setUserGroups(res.data);
    });
  };

  const fetchAffiliates = () => {
    props.callRequest("GET", API_GET_AFFILIATE, true).then((res) => {
      setAffiliates(res.data);
    });
  };
  // const fetchRoles = async () => {
  //   props.callRequest("GET", API_GET_ROLES, true).then((res) => {
  //     setRoles(res.data);
  //   });
  // };
  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  const fetchSalesRepName = (id) => {
    const salesRepName = salesRep.filter((item) => {
      return item.user_id == id;
    });
    if (salesRepName.length > 0) {
      return salesRepName[0].name;
    }
  };

  const columns = [
    {
      name: "Full Name",
      selector: "firstname",
      sortable: true,
      cell: (row) => <div>{row.firstname + " " + row.lastname}</div>,
    },
    {
      name: "User Name",
      selector: "username",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
      sortable: true,
    },
    // {
    //   name: "Role",
    //   selector: "roles",
    //   sortable: true,
    //   cell: (row) => {
    //     if (
    //       row.roles &&
    //       row.roles.length !== 0 &&
    //       userRoles &&
    //       userRoles.length !== 0
    //     ) {
    //       return row.roles.map((role) => {
    //         return userRoles.map((usrRol) => {
    //           if (usrRol.role_id === role) {
    //             if (role === row.roles[row.roles.length - 1]) {
    //               return usrRol.role_name;
    //             } else {
    //               return usrRol.role_name + " , ";
    //             }
    //           }
    //         });
    //       });
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    {
      name: "Group Name",
      selector: "group",
      sortable: true,
      cell: (row) => {
        if (userGroups && userGroups.length !== 0 && row.group !== undefined) {
          let abc = userGroups.filter(
            (userGroups) => Number(userGroups.group_id) === Number(row.group)
          );
          if (abc.length !== 0) {
            return abc[0]["group_name"];
          } else {
            return "";
          }
        }
      },
    },
    {
      name: "Marketing Direct Coach 1",
      selector: "sales_rep_1",
      sortable: true,
      cell: (row) => {
        return fetchSalesRepName(row.sales_rep_1);
      },
    },
    {
      name: "Affiliate",
      selector: "affiliate_id",
      sortable: true,
      cell: (row) => {
        if (affiliates && affiliates.length !== 0 && row.affiliate_id !== "") {
          let abc = affiliates.filter(
            (aff) =>
              Number(aff.affiliate_id.id) === Number(row.affiliate_id)
          );
          if (abc.length !== 0) {
            return abc[0]['affiliate_id']["name"];
          } else {
            return "";
          }
        }
      },
    },

    {
      name: userType && userType == 2 ? null : "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) =>
        userType && userType == 2 ? null : (
          <UncontrolledDropdown>
            <DropdownToggle>
              <i className="fas fa-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => editUser(row.user_id)}>
                Edit
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
    },
  ];

  const editUser = (user_id) => {
    props.history.push("/edit-user/" + user_id);
  };

  const export_column = [
    "firstname",
    "lastname",
    "username",
    "email",
    "roles",
    "group",
    "affiliate_id",
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          autoComplete="off"
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const HandleFilter = (index) => {
    if (index === 1) {
      return setUsers(userList);
    }

    if (index === 2) {
      let filteredSub = userList.filter((sub) => {
        return sub.is_active === "1";
      });
      return setUsers(filteredSub);
    }
    if (index === 3) {
      let filteredSub = userList.filter((sub) => {
        return sub.is_active === "0";
      });
      return setUsers(filteredSub);
    }
  };
  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      {userType && userType == 2 ? null : (
        <Button onClick={() => props.history.push("/create-user")}>
          <i className="ni ni-fat-add"></i> New User
        </Button>
      )}
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={HandleFilter.bind(this, 2)}>
            Active
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 3)}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 1)}>
            Remove Filter
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {userType && userType == 2 ? null : (
        <UncontrolledDropdown disabled={users.length > 0 ? false : true}>
          <DropdownToggle caret>
            <i className="ni ni-ungroup"></i> Export
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) => onExportView(e.target.value)}>
              Export View
            </DropdownItem>
            <DropdownItem onClick={(e) => onExport(e.target.value)}>
              Export All
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );

  const actionsExport = (
    <Export
      onExport={() =>
        DataTableSettings.downloadCSV(
          users,
          export_column,
          props.generateUniqueId()
        )
      }
      onExportView={() =>
        DataTableSettings.downloadCSV(
          users.slice(0, rows),
          export_column,
          props.generateUniqueId()
        )
      }
    />
  );

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Users", "User List"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-circle-08"></i>
            </span>
            User List
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <div className="list-filtered-table-block">
                    <DataTable
                      columns={columns}
                      data={DataTableSettings.filterItems(
                        users,
                        searchParam,
                        filterText
                      )}
                      pagination
                      paginationPerPage={DataTableSettings.paginationPerPage}
                      paginationRowsPerPageOptions={
                        DataTableSettings.paginationRowsPerPageOptions
                      }
                      actions={actionsExport}
                      onChangeRowsPerPage={changeRowPerPage}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                    />
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default UserList;
