import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";

const ImbarcodeComponentLeft = (props) => {
  const [lock, setLock] = useState(props.imbarcode_lock);

  useEffect(() => {
    setLock(props.imbarcode_lock);
  }, [props.imbarcode_lock]);

  const toggleLock = (e) => {
    setLock(!lock);
    props.handleImbarcodeLockUnlock(props.id, !lock, "imbarcode_lock");
  };

  return (
    <div className="custom_text_full">
      <p
        dangerouslySetInnerHTML={{ __html: props.title }}
        onClick={() => props.parentImbarcode(props.id, "imbarcode_component")}
      ></p>
      <button
        style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
        value={lock}
        onClick={(e) => toggleLock(e)}
      >
        {lock ? (
          <i style={{ color: "#66707d" }} className="fas fa-lock"></i>
        ) : (
          <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
        )}
      </button>
      <button
        className="btn btn-delete"
        onClick={() => props.ParentDeleteImbarcode(props.id)}
      >
        Delete
      </button>
    </div>
  );
};

export default ImbarcodeComponentLeft;
