import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";

import { BreadCrumb } from "../../config/Components";
import { API_DROP_HISTORY } from "../../config/Api";
import DataTableSettings from "../../utils/DataTableSettings";
import "../../assets/css/data-table.css";
import DatePicker from "react-datepicker";

const OrderHistory = (props) => {
  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [rows, setRows] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [clearSearch, setClearSearch] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    start_date: "",
    end_date: "",
  });

  const searchParam = ["orderid", "mailer", "item", "postage", "envelope"];
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    let reqParam =
      API_DROP_HISTORY + "?page_number=" + pageNo + "&page_size=" + rows;
    let sDate = dateFilter.start_date
      ? moment(dateFilter.start_date).format("MM/DD/YYYY")
      : "";
    let eDate = dateFilter.start_date
      ? moment(dateFilter.end_date).format("MM/DD/YYYY")
      : "";

    if (sDate != "") {
      reqParam = reqParam + "&start_date=" + sDate;
    }

    if (eDate != "") {
      reqParam = reqParam + "&end_date=" + eDate;
    }

    props
      .callRequest("GET", reqParam, true, null)
      .then((res) => {
        let tempData = [];
        setTotalRows(res.data.total_records);
        setProducts(res.data.data);
      })
      .catch((e) => {
        if (
          e.response &&
          e.response.data &&
          e.response.data.message == "No record found"
        ) {
          setTotalRows(0);
          setProducts([]);
        }
      });
  };

  const columns = [
    {
      name: "Order Id",
      selector: "orderid",
      sortable: true,
      maxWidth: 400,
      minWidth: 140,
      width: 200,
    },
    {
      name: "Drop",
      selector: "mailer",
      sortable: true,
    },
    {
      name: "Item",
      selector: "item",
      sortable: true,
    },
    {
      name: "Postage",
      selector: "postage",
      sortable: true,
    },
    {
      name: "Envelop",
      selector: "envelope",
      sortable: true,
    },
    {
      name: "Quantity",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "Date In",
      selector: "date_in",
      sortable: true,
      cell: (row) => {
        return (
          row &&
          row.date_in &&
          moment(new Date(row.date_in * 1000)).format("MM/DD/YYYY")
        );
      },
    },
    {
      name: "Due Date",
      selector: "due_date",
      sortable: true,
      cell: (row) => {
        return (
          row &&
          row.due_date &&
          moment(new Date(row.due_date * 1000)).format("MM/DD/YYYY")
        );
      },
    },
    {
      name: "Per Price",
      selector: (row) => "$" + row.per,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) =>
        "$" +
        Number(row.total).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
  ];

  const export_column = [
    "job_number",
    "customer",
    "product_description",
    "job_type",
    "date_in",
    "date_due",
    "date_ship_mail",
    "subtotal",
    "discount",
    "shipping",
    "postage",
    "tax",
    "cc_processing_fee",
    "commissionable_total",
    "total_order",
    "paid",
    "balance",
    "main_sales_rep",
    "sales_rep2",
    "affiliate",
    "status",
    "ship_by",
    "job_tag",
    "csr",
    "po",
  ];

  const clearfetchCompleteBatch = () => {
    setDateFilter({ start_date: "", end_date: "" });
    setClearSearch(Math.random());
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <div className="row">
        <div className="col-sm-4">
          <FormGroup>
            <Label for="mailing_date">Search By value</Label>
            <Input
              id="search"
              type="text"
              placeholder="Search"
              aria-label="Search Product"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              autoComplete="off"
            />
          </FormGroup>
        </div>

        <div className="col-sm-4">
          <FormGroup>
            <Label for="mailing_date">Start Date</Label>

            <DatePicker
              dateFormat="MM-dd-yyyy"
              selected={dateFilter.start_date}
              name="start_date"
              id="start_date"
              onChange={(date) => {
                setDateFilter({ ...dateFilter, start_date: date });
              }}
              autoComplete="off"
              disabled={false}
            />
          </FormGroup>
        </div>

        <div className="col-sm-4">
          <FormGroup>
            <Label for="mailing_date">End Date</Label>

            <DatePicker
              dateFormat="MM-dd-yyyy"
              selected={dateFilter.end_date}
              name="start_date"
              id="start_date"
              onChange={(date) => {
                setDateFilter({ ...dateFilter, end_date: date });
              }}
              autoComplete="off"
              disabled={false}
            />
          </FormGroup>
        </div>

        <div
          className="col-sm-12"
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              fetchProducts();
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearfetchCompleteBatch();
            }}
          >
            Clear
          </Button>
        </div>
      </div>
    );
  }, [filterText, dateFilter]);

  const Export = ({ onExport }) => (
    <div className="right-button-block">
      <Button onClick={() => props.history.push("/create-order")}>
        {/* <Button onClick={() => props.history.push("/admin/create-order")}> */}
        <i className="ni ni-fat-add"></i> Create Order
      </Button>
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>Filter Name 1</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Button>
        <i className="bi-pencil-square"></i> Edit Columns
      </Button>
      {/* <Button
        disabled={products.length > 0 ? false : true}
        onClick={(e) => onExport(e.target.value)}
      >
        <i className="ni ni-ungroup"></i> Export
      </Button> */}
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            products,
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [products]
  );

  useEffect(() => {
    clearSearch != null && fetchProducts();
  }, [clearSearch]);

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order History"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            Order History
          </h1>
        </div>

        <div className="listingtable-main-block orderhistory-tabile-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block order-history-list">
                <DataTable
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    products,
                    searchParam,
                    filterText
                  )}
                  pagination
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default OrderHistory;
