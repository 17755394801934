export default class DataTableSettings {
  static paginationPerPage = 10;
  static paginationRowsPerPageOptions = [10, 25, 50];

  static filterItems = (items, searchParam, filterText) => {
    return items.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key] !== null && searchParam.includes(key)
          ? item[key]
              .toString()
              .toLowerCase()
              .includes(filterText.toLowerCase())
          : false
      );
    });
  };
  static downloadCSV = (array, keys, name) => {
    const link = document.createElement("a");
    let csv = this.convertArrayOfObjectsToCSV(array, keys);
    if (csv == null) return;
    const filename = name + ".csv";
    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  };
  static convertArrayOfObjectsToCSV = (array, keys) => {
    let result;
    const columnDelimiter = ",";
    const lineDelimiter = "\n";

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;
        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });
    return result;
  };
}
