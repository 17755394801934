import React, { useState, useEffect } from "react";
import "../Users/UserForm.css";
//import Swal from "sweetalert2";
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";

const CommissionOverrideForm = (props) => {
  const commissionsInfo = {
    is_commissions_override: 0,
    sales_rep_1: "",
    sales_rep_2: "",
    sales_rep_1_commission_type: 1,
    sales_rep_1_commission_percentage: parseFloat(0.0),
    sales_rep_1_commission_flat_rate: parseFloat(0.0),
    sales_rep_2_commission_type: 1,
    sales_rep_2_commission_percentage: parseFloat(0.0),
    sales_rep_2_commission_flat_rate: parseFloat(0.0),
  };

  const [commissions, setCommissions] = useState(commissionsInfo);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    setCommissions({ ...commissions, [name]: value });
    // if (name === "is_commissions_override") {
    //   setCommissions({ ...commissions, [name]: checked });
    // }
  };
  const sendUpdatedCommissions = (commissions) => {
    props.sendUpdatedCommissions(commissions);
  };

  const validateForm = () => {
    let { sales_rep_1, sales_rep_2 } = commissions;

    let formIsValid = true;
    let errors = {};

    if (sales_rep_1 === "") {
      formIsValid = false;

      errors["rep_1"] = "This field is mandatory";
    }
    if (sales_rep_2 === "") {
      formIsValid = false;

      errors["rep_2"] = "This field is mandatory";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return false;
    } else {
      let updatedCommissions = commissions;
      updatedCommissions.is_commissions_override = 1;
      setCommissions(updatedCommissions);
    }
    sendUpdatedCommissions(commissions);
    props.modalToggle();
  };
  const handleClose = () => {
    props.modalToggle();
  };
  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <h1 className="header-title">Commissions Override</h1>
        </div>

        <div className="create-user-formblock">
          <Form>
            {/* <Row form>
              <FormGroup check>
                <Label check>
                  Do you really want to override commissions ?
                  <Input
                    type="checkbox"
                    name="is_commissions_override"
                    defaultChecked={commissions.is_commissions_override}
                    //checked={commissions.is_commissions_override}
                    //value={commissions.is_commissions_override}
                    onChange={handleChange}
                  />{" "}
                </Label>
              </FormGroup>
            </Row> */}
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="sales_rep_1">Sales Rep 1/ID:</Label>
                  <Input
                    type="text"
                    name="sales_rep_1"
                    id="sales_rep_1"
                    autoComplete="off"
                    value={commissions.sales_rep_1}
                    onChange={handleChange}
                    placeholder="Enter Sales Rep"
                  />
                  <Label className="red_err">{errors.rep_1}</Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="sales_rep_2">Sales Rep 2/ID:</Label>
                  <Input
                    type="text"
                    name="sales_rep_2"
                    id="sales_rep_2"
                    autoComplete="off"
                    value={commissions.sales_rep_2}
                    onChange={handleChange}
                    placeholder="Enter Sales Rep ID"
                  />
                  <Label className="red_err">{errors.rep_2}</Label>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <label>Set Sales Rep 1 Commission</label>
                  <div className="set-sales-formbox">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_1_commission_type"
                          id="sales_rep_1_commission_type"
                          value="1"
                          checked={
                            commissions.sales_rep_1_commission_type === "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Percentage
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_1_commission_percentage"
                        id="sales_rep_1_commission_percentage"
                        placeholder="Enter %"
                        autoComplete="off"
                        value={commissions.sales_rep_1_commission_percentage}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_1_commission_type"
                          id="sales_rep_1_commission_type1"
                          value="0"
                          checked={
                            commissions.sales_rep_1_commission_type !== "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Flat Rate
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_1_commission_flat_rate"
                        id="sales_rep_1_commission_flat_rate"
                        placeholder="1-100"
                        autoComplete="off"
                        value={commissions.sales_rep_1_commission_flat_rate}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <label>Set Sales Rep 2 Commission</label>
                  <div className="set-sales-formbox">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_2_commission_type"
                          id="sales_rep_2_commission_type"
                          value="1"
                          checked={
                            commissions.sales_rep_2_commission_type === "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Percentage
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_2_commission_percentage"
                        id="sales_rep_2_commission_percentage"
                        placeholder="Enter %"
                        autoComplete="off"
                        value={commissions.sales_rep_2_commission_percentage}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_2_commission_type"
                          id="sales_rep_2_commission_type2"
                          value="0"
                          checked={
                            commissions.sales_rep_2_commission_type !== "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Flat Rate
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_2_commission_flat_rate"
                        id="sales_rep_2_commission_flat_rate"
                        placeholder="1-100"
                        autoComplete="off"
                        value={commissions.sales_rep_2_commission_flat_rate}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <div className="submit-button-block">
              <Button className="cancel-button" onClick={handleClose}>
                Back
              </Button>
              <Button
                color="Secondary"
                className="create-User-button"
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </div>
          </Form>
        </div>
      </main>
    </>
  );
};

export default CommissionOverrideForm;
