import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useDispatch } from "react-redux";

import { API_PROFILE } from "../../config/Api";
import { setProfile } from "../../store/actions/UserActions";
import { states_hash } from "../../data/countries";

const ContactInformation = (props) => {
  const dispatch = useDispatch();

  const initContactInfo = {
    contact_first_name: "",
    contact_last_name: "",
    contact_address: "",
    contact_city: "",
    contact_state: "",
    contact_zip: "",
    contact_phone: "",
    contact_cell: "",
    contact_email: "",
  };
  const [user, setUser] = useState(initContactInfo);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setUser(props.userDetails);
  }, [props.userDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });

    if (e.target.name === "contact_phone") {
      if (e.target.value.match(/[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi)) {
        e.target.value = e.target.value.replace(
          /[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi,
          ""
        );
      }
      setUser({ ...user, [name]: value });
    }
    if (e.target.name === "contact_cell") {
      if (e.target.value.match(/[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi)) {
        e.target.value = e.target.value.replace(
          /[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi,
          ""
        );
      }
      setUser({ ...user, [name]: value });
    }
    validateForm();
  };

  const validateForm = () => {
    let {
      contact_first_name,
      contact_last_name,
      contact_email,
      contact_address,
      contact_city,
      contact_state,
      contact_zip,
      contact_phone,
    } = user;
    let errors = {};
    let formIsValid = true;

    if (contact_first_name === "") {
      formIsValid = false;
      errors["contact_first_name"] = "Please enter first name";
    }
    if (contact_last_name === "") {
      formIsValid = false;
      errors["contact_last_name"] = "Please enter last name";
    }
    if (contact_phone === "") {
      formIsValid = false;
      errors["contact_phone"] = "Please enter valid phone number";
    }
    if (contact_email === "") {
      formIsValid = false;
      errors["contact_email"] = "Please enter valid email address";
    }
    if (contact_email) {
      if (!props.validateEmail(contact_email)) {
        formIsValid = false;
        errors["contact_email"] = "Please enter valid email address";
      }
    }
    if (contact_address === "") {
      formIsValid = false;
      errors["contact_address"] = "Please enter valid address";
    }
    if (contact_state === "") {
      formIsValid = false;
      errors["contact_state"] = "Please enter valid state";
    }
    if (contact_city === "") {
      formIsValid = false;
      errors["contact_city"] = "Please enter valid city";
    }
    if (contact_zip === "") {
      formIsValid = false;
      errors["contact_zip"] = "Please enter valid zip";
    }
    if (contact_zip) {
      if (!isUSAZipCode(contact_zip)) {
        formIsValid = false;
        errors["contact_zip"] = "Please enter valid zip code";
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    const formData = new FormData();
    formData.append("contact_first_name", user.contact_first_name);
    formData.append("contact_last_name", user.contact_last_name);
    formData.append("contact_address", user.contact_address);
    formData.append("contact_city", user.contact_city);
    formData.append("contact_state", user.contact_state);
    formData.append("contact_zip", user.contact_zip);
    formData.append(
      "contact_phone",
      user.contact_phone.replace(
        /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
        ""
      )
    );
    formData.append(
      "contact_cell",
      user.contact_cell.replace(
        /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
        ""
      )
    );
    formData.append("contact_email", user.contact_email);
    formData.append("main_profile", 1);

    props
      .callRequest(
        "POST",
        API_PROFILE + props.getUserInfo().user_id + "/",
        true,
        formData
      )
      .then((res) => {
        if (res.status) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Updated successfully",
          });

          dispatch(setProfile(user));
        }
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: `${error.response.data.error}`,
        });
      });
  };

  function formatUSNumber(entry = "") {
    let cleaned = ("" + entry).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  function isUSAZipCode(str) {
    return /^\d{5}(\d{4})?$/.test(str);
  }

  function formatZip(str) {
    let formattedStr;
    const regex = /(\d{5})(\d{4})/;
    if (str.length === 5) {
      formattedStr = str;
    } else if (str.length === 9) {
      formattedStr = str.replace(regex, "$1-$2");
    } else {
      formattedStr = str;
    }
    return formattedStr;
  }

  return (
    <>
      <div className="information-form-box ">
        <Card>
          <CardHeader>
            <CardTitle tag="h2">Contact Information</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="form-box">
              <Form>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="contact_first_name">First Name: *</Label>
                      <Input
                        type="text"
                        name="contact_first_name"
                        id="contact_first_name"
                        placeholder="First Name"
                        value={user.contact_first_name}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                      />
                    </FormGroup>
                    <label className="red_err">
                      {errors.contact_first_name}
                    </label>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="contact_last_name">Last Name: *</Label>
                      <Input
                        type="text"
                        name="contact_last_name"
                        id="contact_last_name"
                        placeholder="Last Name"
                        value={user.contact_last_name}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                      />
                    </FormGroup>
                    <label className="red_err">
                      {errors.contact_last_name}
                    </label>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="contact_address">Address: *</Label>
                      <Input
                        type="text"
                        name="contact_address"
                        id="contact_address"
                        placeholder="1234 Main St"
                        value={user.contact_address}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                      />
                    </FormGroup>
                    <label className="red_err">{errors.contact_address}</label>
                  </Col>
                </Row>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="contact_city">City: *</Label>
                      <Input
                        type="text"
                        name="contact_city"
                        id="contact_city"
                        value={user.contact_city}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                      />
                    </FormGroup>
                    <label className="red_err">{errors.contact_city}</label>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="contact_state">State: *</Label>
                      <select
                        id="contact_state"
                        name="contact_state"
                        value={user.contact_state}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        className="form-control"
                        aria-label="Default select example"
                      >
                        <option></option>
                        {states_hash.map((state_info, index) => (
                          <option key={index} value={state_info.abbreviation}>
                            {state_info.abbreviation}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                    <label className="red_err">{errors.contact_state}</label>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="contact_zip">Zip: *</Label>
                      <Input
                        type="text"
                        name="contact_zip"
                        id="contact_zip"
                        value={user.contact_zip && formatZip(user.contact_zip)}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                      />
                    </FormGroup>
                    <label className="red_err">{errors.contact_zip}</label>
                  </Col>
                </Row>
                {/* {console.log(
                  user.contact_phone.replace(
                    /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                    ""
                  ).length,
                  "len"
                )} */}
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="contact_phone">Phone: *</Label>
                      <Input
                        type="text"
                        name="contact_phone"
                        id="contact_phone"
                        value={
                          user.contact_phone &&
                          formatUSNumber(user.contact_phone)
                        }
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        maxlength={
                          user.contact_phone &&
                          user.contact_phone.replace(
                            /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                            ""
                          ).length === 10
                            ? 10
                            : 14
                        }
                      />
                    </FormGroup>
                    <label className="red_err">{errors.contact_phone}</label>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="contact_cell">Cell: *</Label>
                      <Input
                        type="text"
                        name="contact_cell"
                        id="contact_cell"
                        value={
                          user.contact_cell && formatUSNumber(user.contact_cell)
                        }
                        onChange={handleChange}
                        onKeyUp={handleChange}
                        maxlength={
                          user.contact_cell &&
                          user.contact_cell.replace(
                            /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                            ""
                          ).length === 10
                            ? 10
                            : 14
                        }
                      />
                    </FormGroup>
                    <label className="red_err">{errors.contact_cell}</label>
                  </Col>
                </Row>
                <Row form>
                  <Col md={12}>
                    <FormGroup>
                      <Label for="contact_email">Email: *</Label>
                      <Input
                        type="email"
                        name="contact_email"
                        id="contact_email"
                        placeholder="example@email.com"
                        value={user.contact_email}
                        onChange={handleChange}
                        onKeyUp={handleChange}
                      />
                    </FormGroup>
                    <label className="red_err">{errors.contact_email}</label>
                  </Col>
                </Row>
                <div className="update-button-block">
                  <Button onClick={handleUpdate}>Update</Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ContactInformation;
