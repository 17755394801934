import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import { AiFillFileText, AiOutlineFileText } from "react-icons/ai";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";

const NonEditableTextComponentLeft = (props) => {
  const [lock, setLock] = useState(props.non_editable_text_lock);
  const [transparency, setTransparency] = useState(props.transparency);
  const [text_autofit, setTextAutofit] = useState(props.text_autofit);

  useEffect(() => {
    setLock(props.non_editable_text_lock);
    setTransparency(props.transparency);
    setTextAutofit(props.text_autofit);
  }, [props.non_editable_text_lock, props.transparency, props.text_autofit]);

  const toggleLock = (e) => {
    setLock(!lock);
    props.handleNonEditableTextLockUnlock(props.id, !lock, "non_editable_text_lock");
  };

  const toggleTransparency = (e) => {
    setTransparency(!transparency);
    props.handleTextTransparency(props.id, !transparency, "non_editable_text");
  };

  const toggleTextAutofit = (e) => {
    setTextAutofit(!text_autofit);
    props.handleTextFit(props.id, !text_autofit, "non_editable_text");
    setTimeout(() => {
      props.ParentNonEditableCustomText(props.id, "non_editable_text");
    }, 200);
  };

  return (
    <>
      <div className="custom_text_full font-family-inherit">
        <p
          dangerouslySetInnerHTML={{ __html: props.title }}
          onClick={() =>
            props.ParentNonEditableCustomText(props.id, "non_editable_text")
          }
        ></p>
        <div className="text-btn-block">
          <button
            style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
            value={text_autofit}
            onClick={(e) => toggleTextAutofit(e)}
          >
            {text_autofit ? (
              <BsToggleOn title={'Remove Text Fit'} />
            ) : (
              <BsToggleOff title={'Apply Text Fit'} />
            )}
          </button>
          <button
            style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
            value={transparency}
            onClick={(e) => toggleTransparency(e)}
          >
            {transparency ? (
              <AiOutlineFileText title={'Remove Transparency'} />
            ) : (
              <AiFillFileText title={'Add Transparency'} />
            )}
          </button>
          <button
            style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
            value={lock}
            onClick={(e) => toggleLock(e)}
          >
            {lock ? (
              <i style={{ color: "#66707d" }} className="fas fa-lock"></i>
            ) : (
              <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
            )}
          </button>
          <button
            className="btn btn-delete"
            onClick={() => props.ParentNonEditableDeleteCustomText(props.id)}
          >
            Delete
          </button>
        </div>
      </div>
    </>
  );
};

export default NonEditableTextComponentLeft;
