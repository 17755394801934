import React, { Component } from "react";
import Singlepannel from "./Singlepannel";
import Bothpannel from "./BothPannel";
import { withRouter } from "react-router-dom";
import { API_TEMPLATE_SIZE } from "../../config/Api";

class Designer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template_name: "",
      width: "",
      height: "",
      bleed: "0.125",
      margin: "0.125",
      template_type: "",
      size_id: "",
      sizes: [],
      user_id: this.props.getUserInfo().user_id,
      is_bleed: "0"
    };
    this.onChangeHandeller = this.onChangeHandeller.bind(this);
  }

  componentDidMount() {
    this.props.callRequest("GET", API_TEMPLATE_SIZE, true).then((res) => {
      this.setState({ sizes: res.data });
    });
  }

  onChangeHandeller = (event) => {
    if (event.target.name === "size_id") {
      let size_id_value = event.target.value;
      let found = this.state.sizes.find(
        (size) => size.size_id == size_id_value
      );
      if (found && found.size_id !== 0) {
        this.setState({ width: found.width, height: found.height });
        if (found.width === "11" && found.height === "17") {
          this.setState({ template_type: "Both" });
        } else {
          this.setState({ template_type: "" });
        }
      } else {
        this.setState({ width: "", height: "" });
        this.setState({ template_type: "" });
      }
    }
    this.setState({ [event.target.name]: event.target.value });
    if (
      this.state.showSinglepannel === true ||
      this.state.showBothpannel === true
    ) {
      this.OnUpdateState.onChangeHandeller(event);
    }
  };

  getDesigner = (event) => {
    event.preventDefault();
    if (
      this.state.template_name !== "" &&
      this.state.size_id !== 0 &&
      this.state.template_type !== ""
    ) {
      if (this.state.template_type === "Single") {
        this.setState({ showSinglepannel: true });
        this.setState({ showBothpannel: false });
      } else {
        this.setState({ showSinglepannel: false });
        this.setState({ showBothpannel: true });
      }

      if (this.state.template_name === "") {
        this.setState({ err_template_name: "Please provide template name." });
      } else {
        this.setState({ err_template_name: "" });
      }

      if (this.state.size_id === "") {
        this.setState({ err_size: "Please select size." });
      } else {
        this.setState({ err_size: "" });
      }

      if (this.state.template_type === "") {
        this.setState({ err_template_type: "Please provide template type." });
      } else {
        this.setState({ err_template_type: "" });
      }
    } else {
      if (this.state.template_name === "") {
        this.setState({ err_template_name: "Please provide template name." });
      } else {
        this.setState({ err_template_name: "" });
      }

      if (this.state.size_id === "") {
        this.setState({ err_size: "Please select size." });
      } else {
        this.setState({ err_size: "" });
      }

      if (this.state.template_type === "") {
        this.setState({ err_template_type: "Please provide template type." });
      } else {
        this.setState({ err_template_type: "" });
      }
    }
  };

  handleBleedNoBleed = (e) => {
    if (e.target.checked === true) {
      this.setState({ is_bleed: "1" });
    } else {
      this.setState({ is_bleed: "0" });
    }
  };

  render() {
    const { width, height } = this.state;

    return (
      <div className="App">
        <div className="container">
          <form onSubmit={this.getDesigner}>
            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <div className="table-template-form-block">
                      <div className="form-group">
                        <label>Template Name: </label>
                        <input
                          type="text"
                          name="template_name"
                          id="template_name"
                          className="form-control"
                          value={this.state.template_name}
                          onChange={this.onChangeHandeller}
                          autoComplete="off"
                        />
                        <label className="red_err">
                          {this.state.err_template_name}
                        </label>
                      </div>
                      <div className="form-group">
                        <label>Template Size: </label>
                        <select
                          id="size_id"
                          name="size_id"
                          value={this.state.size_id}
                          onChange={this.onChangeHandeller}
                          className="form-control"
                          aria-label="Default select example"
                        >
                          <option value="">Select Size</option>
                          {this.state.sizes.map((size) => (
                            <option key={size.size_id} value={size.size_id}>
                              {size.size_name}
                            </option>
                          ))}
                        </select>
                        <label className="red_err">{this.state.err_size}</label>
                      </div>

                      <div className="form-group">
                        <label>Template Type: </label>
                        <select
                          name="template_type"
                          id="template_type"
                          className="form-control"
                          onChange={this.onChangeHandeller}
                          value={this.state.template_type}
                          disabled={width === "11" && height === "17" ? true : false}
                        >
                          <option value="">Select Type</option>
                          <option value="Single">Single</option>
                          {/* <option value="Both">Both</option> */}
                          <option value="Both">Double Sided</option>
                        </select>
                        <label className="red_err">
                          {this.state.err_template_type}
                        </label>
                      </div>
                      <div className="form-group d-flex justify-content-center bleed-button-block">
                        <div className="bleed_no_bleed">
                          <input
                            type="checkbox"
                            id="is_bleed"
                            name="is_bleed"
                            value={this.state.is_bleed}
                            onChange={this.handleBleedNoBleed}
                          />
                          <label htmlFor="is_bleed">No Bleed</label>
                        </div>
                        <button
                          className="btn btn-primary button-scale"
                          value="click"
                          type="submit"
                        >
                          Get Designer
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div id="scaleBoxLayout" className="">
                  {this.state.showSinglepannel ? (
                    <Singlepannel
                      {...this.props}
                      myProps={this.state}
                      onRef={(ref) => (this.OnUpdateState = ref)}
                    />
                  ) : null}
                  {this.state.showBothpannel ? (
                    <Bothpannel
                      {...this.props}
                      myProps={this.state}
                      onRef={(ref) => (this.OnUpdateState = ref)}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(Designer);
