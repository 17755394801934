import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import {
  GrChapterAdd,
} from 'react-icons/gr';
import {
  MdDeleteForever,
} from 'react-icons/md';
import { BsFileEarmarkArrowDown, BsFileEarmarkArrowUp } from 'react-icons/bs';

const LeftDataMatrixFields = (props) => {
  const [count, setCount] = useState(2);
  const [dataSequenceSwap, setDataSequenceSwap] = useState(props.data_sequence_component.length > 0 ? props.data_sequence_component[0].data_sequence_swap : false);
  const [dataMatrixSwap, setDataMatrixSwap] = useState(props.data_matrix_component.length > 0 ? props.data_matrix_component[0].data_matrix_swap : false);

  useEffect(() => {
    setCount(
      props.data_sequence_component.length + props.data_matrix_component.length
    );
    setDataSequenceSwap(props.data_sequence_component.length > 0 ? props.data_sequence_component[0].data_sequence_swap : false)
    setDataMatrixSwap(props.data_matrix_component.length > 0 ? props.data_matrix_component[0].data_matrix_swap : false)
  }, [props.data_sequence_component, props.data_matrix_component]);

  const onTrigger = (event) => {
    props.parentAddDataSequence();
    props.parentAddDataMatrix();
    event.preventDefault();
    setDataSequenceSwap(!dataSequenceSwap);
    setDataMatrixSwap(!dataMatrixSwap);

  };
  const handleUpDownMatrix = (event) => {
    setDataSequenceSwap(!dataSequenceSwap);
    setDataMatrixSwap(!dataMatrixSwap);
    props.handleUpDownMatrix(!dataSequenceSwap, !dataMatrixSwap);
  };

  const onDelete = (event) => {
    props.parentDeleteDataSequenceMatrix(0);
  };

  return (
    <div className="template-fill-menu-item data-matrix-swap">
      <button
        className="menu-item-btn"
        value="Add Data Matrix"
        hidden={count === 2 ? true : false}
        onClick={onTrigger}
      >
        <div className='menu-item'>
          <span className='icon'><GrChapterAdd /></span>
          <span className='name'>Add Data Matrix</span>
        </div>
      </button>
      <div className="data-matrix-swap" hidden={count !== 2 ? true : false}>
        <div className="data-matrix-header">
          <span>Data Matrix:</span>
        </div>
        <div className="data-matrix-rightbutton">
          <button
            className="menu-item-btn"
            hidden={count !== 2 ? true : false}
            onClick={(e) => handleUpDownMatrix(e)}
          >
            {!dataSequenceSwap && !dataMatrixSwap ? (
              <div className='menu-item'>
                <span className='icon'><BsFileEarmarkArrowDown /></span>
              </div>
            ) : (
              <div className='menu-item'>
                <span className='icon'><BsFileEarmarkArrowUp /></span>
              </div>
            )
            }
          </button>
          <button
            className="menu-item-btn"
            value="Delete"
            hidden={count !== 2 ? true : false}
            onClick={onDelete}
            style={{ color: "red" }}
          >
            <div className='menu-item'>
              <span className='icon color-red'><MdDeleteForever /></span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default LeftDataMatrixFields;
