import React from "react";
import "./Subscriptions.css";
import {
  Breadcrumb, BreadcrumbItem, Button, Form, FormGroup, Label, Input, Card, CardImg, CardImgOverlay,
  Row, Col
} from "reactstrap";

const TemplatePreview = (props) => {
  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <div className="breadcrumb-block">
            <Breadcrumb>
              <BreadcrumbItem>Subsciptions</BreadcrumbItem>
              <BreadcrumbItem active>Template Preview</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-album-2"></i>
            </span>
            Template Preview
          </h1>
        </div>

        <div className="template-preview-block">
          <div className="row">
            <div className="col-sm-8 col-lg-10">

              <div className="template-preview-main-box">
                <Card body>

                  <div className="template-name">
                    <Form>
                      <FormGroup>
                        <Label for="exampleText">Template Name:</Label>
                        <Input type="text" name="text" id="exampleText" />
                      </FormGroup>
                    </Form>
                  </div>

                  <div className="template-block">
                    <Row xs="2">
                      <Col>
                        <div className="template-box template-front">
                          <h4 className="titel">Front</h4>
                          <Card inverse>
                            <CardImg width="100%" src={require("../../assets/img/36_front.png").default} alt="Card image cap" />
                            <CardImgOverlay>
                              <div className="upload-button">
                                <Button color="primary">Upload File</Button>
                                <Input type="file" class="custom-file-input" name="file" id="exampleFile" />
                              </div>
                              <Button color="primary">Edit</Button>
                            </CardImgOverlay>
                          </Card>
                        </div>
                      </Col>
                      <Col>
                        <div className="template-box template-back">
                          <h4 className="titel">Back</h4>
                          <Card inverse>
                            <CardImg width="100%" src={require("../../assets/img/36_back.png").default} alt="Card image cap" />
                            <CardImgOverlay>
                              <div className="upload-button">
                                <Button color="primary">Upload File</Button>
                                <Input type="file" class="custom-file-input" name="file" id="exampleFile" />
                              </div>
                              <Button color="primary">Edit</Button>
                            </CardImgOverlay>
                          </Card>
                        </div>
                      </Col>
                    </Row>





                  </div>

                </Card>
                <div className="place-order-block">
                  <Button color="primary">Place Order <i class="ni ni-bold-right"></i></Button>
                </div>
              </div>


            </div>
            <div className="col-sm-4 col-lg-2">
              <div className="select-template-right-block">Right Sidebar</div>
            </div>
          </div>
        </div>


      </main>
    </>

  );
};

export default TemplatePreview;
