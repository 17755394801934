import React, { useState, useEffect } from "react";
import "./Subscriptions.css";
import Swal from "sweetalert2";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import {
  API_GET_AFFILIATE,
  API_GET_STATES,
  API_GET_COUNTY,
  API_SUBSCRIPTION_PRICE,
  API_SUBSCRIPTION,
  API_MICRO_CAMPAIGN_PRICE,
  API_GET_USER_PERMISSION,
  API_GET_USER,
  API_GET_SALES_REP,
} from "../../config/Api";
import SelectUserForm from "./SelectUserForm";
import CreateUserForm from "./CreateUserForm";
import UserFrom from "../Users/UserForm";
import CommissionOverrideForm from "./CommissionOverrideForm";
const subscrptn_info = {
  sub_name: "",
  state: "",
  county: "",
  override_amount: "",
  pkg: [],
  amount: "",
  avgMonLeads: "",
  interval_type: "",
};
const button_desc = {
  button_name: "ADD TO USER CART",
  button_color: "danger",
};

const PrintProfile = (props) => {
  const [Affiliate, setAffiliate] = useState([]);
  const [selectedUser, setSelectedtUser] = useState([]);
  const [edittedUser, setEdittedtUser] = useState([]);
  const [edittedCommissions, setEdittedCommissions] = useState([]);
  const [Subscription, setSubscription] = useState(subscrptn_info);
  const [State, setState] = useState();
  const [County, setCounty] = useState([]);
  const [result, setResult] = useState();
  const [microCampaignPrice, setMicroCampaignPrice] = useState(0);
  const [errors, setErrors] = useState({});
  const [leadFusion, setLeadFusion] = useState({});
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [modal, setModal] = useState(false);
  const [comm, setComm] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [perm, setPerm] = useState();
  const [button, setButton] = useState(button_desc);
  const [subComm, setSubComm] = useState(false);
  const [profile, setProfile] = useState();
  const [overrideAmount, setOverrideAmount] = useState("");
  const [availablePckg, setAvailablePckg] = useState([]);
  const [err, setErr] = useState({});
  const [salesRep, setSalesRep] = useState([]);

  const [userInfo, setUserInfo] = useState([]);
  const closeComm = () => setSubComm(!subComm);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    fetchAffiliates();
    fetchUserRolePermission();

    if (Subscription.sub_name && Subscription.sub_name === "Probate") {
      fetchStates();
    }
    if (
      Subscription.sub_name &&
      Subscription.sub_name === "Probate" &&
      Subscription.state !== "" &&
      Subscription.county !== ""
    ) {
      fetchSubscriptionPrice(
        Subscription.sub_name,
        Subscription.state,
        Subscription.county
      );
    }
    if (Subscription.sub_name && Subscription.sub_name === "Micro Campaign") {
      fetchMicroCampaignPrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Subscription]);
  useEffect(() => {
    if (result && result.length > 1) {
      MultipleCountyData();
    }
  }, [result]);
  const fetchUserRolePermission = () => {
    props
      .callRequest(
        "GET",
        API_GET_USER_PERMISSION + props.getUserInfo().user_id,
        true
      )
      .then((res) => {
        setPermissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchAffiliates = () => {
    props
      .callRequest("GET", API_GET_AFFILIATE, true)
      .then((res) => {
        setAffiliate(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchStates = () => {
    props.callRequest("GET", API_GET_STATES, true).then((res) => {
      setState(res.data);
    });
  };
  const fetchCounty = (state) => {
    props
      .callRequest("GET", API_GET_COUNTY + state, true)
      .then((res) => {
        setCounty(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setErrMsg("No County found!!");
      });
  };

  const fetchMicroCampaignPrice = () => {
    props.callRequest("GET", API_MICRO_CAMPAIGN_PRICE, true).then((res) => {
      setMicroCampaignPrice(res.data.amount);
      setLoading(false);
    });
  };
  const validateForm = () => {
    let { state, county } = Subscription;

    let formIsValid = true;
    let errors = {};

    if (state === "") {
      formIsValid = false;
      errors["state"] = "This field is required";
    }
    if (county === "") {
      formIsValid = false;
      errors["county"] = "This field is required";
    }
    if (selectedUser.length === 0) {
      formIsValid = false;
      errors["selecteduser"] = "This field is required";
    }
    setErrors(errors);
    return formIsValid;
  };
  const validateOverrideForm = () => {
    let formIsValid = true;
    let err = {};

    if (overrideAmount === "") {
      formIsValid = false;
      errors["amt"] = "This field is required";
    }
    setErr(errors);
    return formIsValid;
  };
  const validatedForm = () => {
    let formIsValid = true;
    let errors = {};

    if (selectedUser.length === 0) {
      formIsValid = false;
      errors["selecteduser"] = "This field is required";
    }
    setErrors(errors);
    return formIsValid;
  };
  const fetchSubscriptionPrice = (sub_name, state, county) => {
    props
      .callRequest(
        "GET",
        API_SUBSCRIPTION_PRICE +
        sub_name +
        "&state=" +
        state +
        "&county=" +
        county,
        true
      )
      .then((res) => {
        setLeadFusion(JSON.stringify(res.data));
        setResult(res.data["counties_data"]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const MultipleCountyData = () => {
    if (result.length > 1) {
      let pckg = result.map((res) => {
        return (
          res.avgmonthlyleads +
          " Leads, Monthly Lead Drops, $" +
          res.price +
          " " +
          res.frequency +
          " Subscription"
        );
      });
      setAvailablePckg(pckg);
    }
  };
  // console.log(
  //   multiplePrice,
  //   "<Price>",
  //   multipleLeads,
  //   "<leads>",
  //   multipleFrequency,
  //   "<freq>"
  // );
  const receiveUserId = (selectUsr) => {
    setSelectedtUser(selectUsr);
  };
  const sendEdittedUser = (user) => {
    setEdittedtUser(user);
  };
  const sendUpdatedCommissions = (commissions) => {
    setEdittedCommissions(commissions);
  };
  const sendUpdatedProfile = (updatedProfile) => {
    setProfile(updatedProfile);
  };
  const affUsr = Affiliate.filter((aff) => {
    return (
      Number(aff.affiliate_id.id) === Number(selectedUser.affiliate_id)
    );
  });
  const edtAffUsr = Affiliate.filter((aff) => {
    return Number(aff.affiliate_id.id) === Number(userInfo.affiliate_id);
  });

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "state") {
      setErrMsg("Loading...");
      setLoading(true);
      fetchCounty(value);
    }
    if (name === "county") {
      setOverrideAmount("");
      let abc = Subscription;
      abc.override_amount = "";
      abc.county = value;
      setSubscription(abc);
    }

    if (name === "pkg") {
      setOverrideAmount("");
      let abc = Subscription;
      abc.override_amount = "";
      abc.pkg = value;
      let split = abc.pkg.split(" ");
      abc.avgMonLeads = split[0];
      abc.amount = split[5].replace("$", "");
      abc.interval_type = split[6];
      setSubscription(abc);
    }
    setSubscription({ ...Subscription, [name]: value });
  };
  const changeOverrideAmt = (e) => {
    const { name, value } = e.target;
    setOverrideAmount(value);
  };
  const confirmOverride = (e) => {
    if (!validateOverrideForm()) {
      return false;
    }

    setSubscription({ ...Subscription, override_amount: overrideAmount });
    closeComm();
  };
  const checkPermissions = () => {
    let newPer = [...permissions];
    let currPer = newPer.filter((per) => {
      return per.role_name === "User";
    });
    setPerm(currPer);
  };
  //console.log(Subscription.amount, "ss");
  const modifyUser = () => {
    setSelectedtUser([]);
    setEdittedtUser([]);
  };
  const toggleEditUser = () => {
    setComm(false);
    toggle();
  };
  const toggleCommissions = () => {
    setComm(true);
    toggle();
  };
  const SubscriptionCommissions = () => {
    setSubComm(true);
  };
  const handleNext = (e) => {
    e.preventDefault();
    if (
      Subscription.sub_name === "Probate" &&
      Subscription.state !== "" &&
      Subscription.county !== ""
    ) {
      if (!validateForm()) {
        return false;
      }
      // else {
      //   setButton({
      //     button_name: "ADDED TO USER CART",
      //     button_color: "warning",
      //   });
      // }
    }
    if (Subscription.sub_name === "Micro Campaign") {
      if (!validatedForm()) {
        return false;
      }
      //  else {
      //   setButton({
      //     button_name: "ADDED TO USER CART",
      //     button_color: "warning",
      //   });
      // }
    }
    if (selectedUser.length !== 0 && Subscription.sub_name !== "") {
      let post_data = {
        user: selectedUser.user_id,
        subscription_name: "",
        subscription_type:
          Subscription.sub_name === "Probate"
            ? 0
            : Subscription.sub_name === "Micro Campaign"
              ? 1
              : "",
        state: Subscription.sub_name === "Probate" ? Subscription.state : "",
        county: Subscription.sub_name === "Probate" ? Subscription.county : "",
        interval_type:
          Subscription.interval_type !== ""
            ? Subscription.interval_type
            : "Monthly",
        avgmonthlyleads:
          Subscription.sub_name === "Probate" && result
            ? result.length > 1
              ? Subscription.avgMonLeads
              : result[0].avgmonthlyleads
            : 0,
        // totalqty:
        //   Subscription.sub_name === "Probate" && result
        //     ? Number(result.totalqty)
        //     : 0,
        totalqty: 0,
        amount:
          Subscription.sub_name === "Probate" && result
            ? result.length > 1
              ? Number(Subscription.amount)
              : Number(result[0].price)
            : 0,
        override_amount: Number(Subscription.override_amount),
        // avgmonthlyleads:
        //   Subscription.sub_name === "Probate" && result
        //     ? result.data.avgmonthlyleads
        //     : 0,
        // totalqty:
        //   Subscription.sub_name === "Probate" && result
        //     ? Number(result.data.totalqty)
        //     : 0,
        // amount:
        //   Subscription.sub_name === "Probate" && result
        //     ? Number(result.data.group_price)
        //     : 0,
        is_draft: 1,
        is_active: 1,
        lead_fusion_json: Subscription.sub_name === "Probate" ? leadFusion : "",
        is_commissions_override:
          edittedCommissions &&
            edittedCommissions.is_commissions_override === true
            ? 0
            : "1",
        sales_rep_1:
          edittedCommissions && edittedCommissions.sales_rep_1
            ? edittedCommissions.sales_rep_1
            : selectedUser.sales_rep_1,
        sales_rep_2:
          edittedCommissions && edittedCommissions.sales_rep_2
            ? edittedCommissions.sales_rep_2
            : selectedUser.sales_rep_2,
        sales_rep_1_commission_type: 1,
        sales_rep_1_commission_percentage:
          edittedCommissions.sales_rep_1_commission_percentage,
        sales_rep_1_commission_flat_rate:
          edittedCommissions.sales_rep_1_commission_flat_rate,
        sales_rep_2_commission_type: 1,
        sales_rep_2_commission_percentage:
          edittedCommissions.sales_rep_2_commission_percentage,
        sales_rep_2_commission_flat_rate:
          edittedCommissions.sales_rep_2_commission_flat_rate,
      };
      //console.log(post_data, "post");
      props
        .callRequest("POST", API_SUBSCRIPTION, true, post_data)
        .then((res) => {
          if (res.status) {
            setButton({
              button_name: "ADDED TO USER CART",
              button_color: "warning",
            });

            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Saved successfully",
            });
            setTimeout(
              function () {
                props.history.push("/my-subscription/");
                // props.history.push("/admin/my-subscription/");
              }.bind(),
              3000
            );
          }
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Something Went Wrong!",
          });
          // setButton({
          //   button_name: "ADD TO USER CART",
          //   button_color: "danger",
          // });
        });
    }
  };

  useEffect(() => {
    fetchSalesRep();
  }, []);

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  const fetchSalesRepName = (id) => {
    const salesRepName = salesRep.filter((item) => {
      return item.user_id == id;
    });
    if (salesRepName.length > 0) {
      return salesRepName[0].name;
    }
  };

  const fetchUserById = (id) => {
    props.callRequest("GET", API_GET_USER + id, true).then((res) => {
      setUserInfo(res.data);
    });
  };

  const refreshList = () => {
    fetchUserById(selectedUser.user_id);
    toggle();
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <div className="breadcrumb-block">
            <Breadcrumb>
              <BreadcrumbItem>Subscription</BreadcrumbItem>
              <BreadcrumbItem active>Add Subscription</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-calendar-grid-58"></i>
            </span>
            New Subscription
          </h1>
        </div>
        <div className="add-subscription-block">
          <div className="row">
            <div className="col-sm-8">
              <Card>
                <CardHeader>
                  <CardTitle tag="h2">
                    <div className="subName">Subscription Order Form </div>
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleNext}>
                    <FormGroup>
                      <Label for="sub_name">Subscription:</Label>
                      <Input
                        required
                        type="select"
                        name="sub_name"
                        id="sub_name"
                        autoComplete="off"
                        value={Subscription.sub_name}
                        onChange={changeHandler}
                      >
                        <option value="">Select Subscription</option>
                        <option value="Probate">Probate</option>
                        <option value="Micro Campaign">Micro Campaign</option>
                      </Input>
                    </FormGroup>
                    {Subscription.sub_name === "Probate" ? (
                      <div>
                        <Row>
                          <Col md={6} lg={6}>
                            <FormGroup>
                              <Label for="state">State:</Label>
                              <Input
                                required
                                type="select"
                                name="state"
                                id="state"
                                value={Subscription.state}
                                onChange={changeHandler}
                              >
                                <option value="">Select State</option>
                                {State &&
                                  State.map((state, index) => {
                                    return (
                                      <option
                                        value={state.abbreviation}
                                        key={index}
                                      >
                                        {state.name}
                                      </option>
                                    );
                                  })}
                              </Input>
                              <label className="red_err">{errors.state}</label>
                            </FormGroup>
                          </Col>
                          {Subscription.state !== "" ? (
                            <Col md={6} lg={6}>
                              <FormGroup>
                                <Label for="county">County:</Label>
                                <Input
                                  required
                                  type="select"
                                  name="county"
                                  id="county"
                                  value={Subscription.county}
                                  onChange={changeHandler}
                                >
                                  <option value="">Select County</option>
                                  {loading === true ? (
                                    <option value="">{errMsg}</option>
                                  ) : (
                                    County &&
                                    County.map((cnt, index) => {
                                      return (
                                        <option value={cnt.County} key={index}>
                                          {cnt.County}
                                        </option>
                                      );
                                    })
                                  )}
                                </Input>
                                <label className="red_err">
                                  {errors.county}
                                </label>
                              </FormGroup>
                            </Col>
                          ) : (
                            ""
                          )}
                        </Row>
                        {result && result.length > 1 ? (
                          <Row form>
                            <Col md={4} lg={4}>
                              <FormGroup>
                                <Label for="package">Select Pkg:</Label>
                                <Input
                                  required
                                  type="select"
                                  name="pkg"
                                  id="pkg"
                                  value={Subscription.pkg}
                                  onChange={changeHandler}
                                >
                                  <option value="">Available Packages</option>
                                  {availablePckg &&
                                    availablePckg.map((prc, index) => {
                                      return (
                                        <option value={prc} key={index}>
                                          {prc}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </FormGroup>
                            </Col>
                            {/* <Col md={4} lg={4}>
                              <FormGroup>
                                <Label for="Lead">Avg Monthly Leads:</Label>
                                <Input
                                  required
                                  type="select"
                                  name="avgMonLeads"
                                  id="avgMonLeads"
                                  value={Subscription.avgMonLeads}
                                  onChange={changeHandler}
                                >
                                  <option value="">
                                    Select Avg Monthly Leads
                                  </option>
                                  {multipleLeads &&
                                    multipleLeads.map((prc, index) => {
                                      return (
                                        <option value={prc} key={index}>
                                          {prc}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md={4} lg={4}>
                              <FormGroup>
                                <Label for="frequency">Frequency:</Label>
                                <Input
                                  required
                                  type="select"
                                  name="interval_type"
                                  id="frequency"
                                  value={Subscription.interval_type}
                                  onChange={changeHandler}
                                >
                                  <option value="">Select Frequency</option>
                                  {multipleFrequency &&
                                    multipleFrequency.map((prc, index) => {
                                      return (
                                        <option value={prc} key={index}>
                                          {prc}
                                        </option>
                                      );
                                    })}
                                </Input>
                              </FormGroup>
                            </Col> */}
                          </Row>
                        ) : (
                          ""
                        )}

                        {Subscription.sub_name === "Probate" &&
                          Subscription.state !== "" &&
                          Subscription.county !== "" ? (
                          <div>
                            <Button
                              color={button.button_color}
                              className="m-auto"
                            >
                              {button.button_name}{" "}
                              <i className="fa fa-shopping-cart "></i>
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : Subscription.sub_name === "Micro Campaign" ? (
                      <div>
                        <Button color={button.button_color} className="m-auto">
                          {button.button_name}{" "}
                          <i className="fa fa-shopping-cart "></i>
                        </Button>
                      </div>
                    ) : (
                      ""
                    )}
                  </Form>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-4">
              <div className="right-block">
                <div className="select-user-box">
                  <Card>
                    {selectedUser.length === 0 ? (
                      <CardHeader>
                        <CardTitle tag="h2">Search User</CardTitle>
                      </CardHeader>
                    ) : (
                      <CardHeader>
                        <CardTitle tag="h2">User</CardTitle>

                        <UncontrolledDropdown>
                          <DropdownToggle onClick={checkPermissions}>
                            <i className="fas fa-ellipsis-v"></i>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem onClick={modifyUser}>
                              Change User
                            </DropdownItem>

                            {perm && perm.length !== 0 ? (
                              <DropdownItem onClick={toggleEditUser}>
                                Edit User Profile
                              </DropdownItem>
                            ) : (
                              ""
                            )}
                            {/* {perm && perm.length !== 0 ? (
                              <DropdownItem onClick={toggleCommissions}>
                                Commissions Override
                              </DropdownItem>
                            ) : (
                              ""
                            )} */}
                            {comm === false ? (
                              <Modal
                                isOpen={modal}
                                toggle={toggle}
                                className="create-user-modal"
                              >
                                <UserFrom
                                  {...props}
                                  isInPopUp={true}
                                  refreshList={refreshList}
                                  userId={selectedUser && selectedUser.user_id}
                                // sendEdittedUser={sendEdittedUser}
                                // sendUpdatedProfile={sendUpdatedProfile}
                                />
                                {/* <UserForm
                                  {...props}
                                  selectedUser={selectedUser.user_id}
                                  sendEdittedUser={sendEdittedUser}
                                  sendUpdatedProfile={sendUpdatedProfile}
                                  modalToggle={toggle}
                                /> */}
                              </Modal>
                            ) : (
                              <Modal
                                isOpen={modal}
                                toggle={toggle}
                                className="create-user-modal commissions-override-modal"
                              >
                                <CommissionOverrideForm
                                  {...props}
                                  //selectedUser={selectedUser.user_id}
                                  sendUpdatedCommissions={
                                    sendUpdatedCommissions
                                  }
                                  modalToggle={toggle}
                                />
                              </Modal>
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </CardHeader>
                    )}
                    <CardBody>
                      {selectedUser.length === 0 ? (
                        <div>
                          <SelectUserForm
                            {...props}
                            receiveUserId={receiveUserId}
                            permissions={permissions}
                          />
                          <label className="red_err">
                            {errors.selecteduser}
                          </label>
                        </div>
                      ) : userInfo && userInfo.length !== 0 ? (
                        <div>
                          <p>
                            <strong>Name:</strong> {userInfo.firstname}{" "}
                            {userInfo.lastname}
                          </p>
                          <p>
                            <strong>Email:</strong> {userInfo.email}
                          </p>
                          <p>
                            <strong>Phone:</strong> {userInfo.phone}
                          </p>

                          <p>
                            <strong>Direct Marketing Coach 1:</strong>{" "}
                            {fetchSalesRepName(
                              edittedCommissions &&
                                edittedCommissions.sales_rep_1
                                ? edittedCommissions.sales_rep_1
                                : userInfo.sales_rep_1
                            )}
                          </p>
                          <p>
                            <strong>Direct Marketing Coach 2:</strong>{" "}
                            {fetchSalesRepName(
                              edittedCommissions &&
                                edittedCommissions.sales_rep_2
                                ? edittedCommissions.sales_rep_2
                                : userInfo.sales_rep_2
                            )}
                          </p>

                          {/* <p>
                            <strong>Sales Rep 1:</strong>{" "}
                            {edittedCommissions &&
                            edittedCommissions.sales_rep_1
                              ? edittedCommissions.sales_rep_1
                              : userInfo.sales_rep_1}
                          </p>
                          <p>
                            <strong>Sales Rep 2:</strong>{" "}
                            {edittedCommissions &&
                            edittedCommissions.sales_rep_2
                              ? edittedCommissions.sales_rep_2
                              : userInfo.sales_rep_2}
                          </p> */}
                          <p>
                            <strong>CSR:</strong> {userInfo.csr}
                          </p>

                          <p>
                            <strong>Affiliate:</strong>&nbsp;
                            {userInfo &&
                              userInfo.affiliate_id &&
                              edtAffUsr !== undefined
                              ? edtAffUsr[0].affiliate_id.name
                              : userInfo.affiliate_id}
                          </p>
                        </div>
                      ) : (
                        <div>
                          <p>
                            <strong>Name:</strong> {selectedUser.firstname}{" "}
                            {selectedUser.lastname}
                          </p>
                          <p>
                            <strong>Email:</strong> {selectedUser.email}
                          </p>
                          <p>
                            <strong>Phone:</strong> {selectedUser.phone}
                          </p>

                          <p>
                            <strong>Direct Marketing Coach 1:</strong>{" "}
                            {fetchSalesRepName(
                              edittedCommissions &&
                                edittedCommissions.sales_rep_1
                                ? edittedCommissions.sales_rep_1
                                : selectedUser.sales_rep_1
                            )}
                          </p>

                          <p>
                            <strong>Direct Marketing Coach 2:</strong>{" "}
                            {fetchSalesRepName(
                              edittedCommissions &&
                                edittedCommissions.sales_rep_2
                                ? edittedCommissions.sales_rep_2
                                : selectedUser.sales_rep_2
                            )}
                          </p>

                          <p>
                            <strong>CSR:</strong> {selectedUser.csr}
                          </p>

                          <p>
                            <strong>Affiliate:</strong>&nbsp;
                            {selectedUser && selectedUser.affiliate_id && affUsr
                              ? affUsr[0].affiliate_id.name
                              : selectedUser.affiliate_id}
                          </p>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                  {load && loading === true ? (
                    <h3>Loading...</h3>
                  ) : Subscription.sub_name === "Probate" &&
                    Subscription.state !== "" &&
                    Subscription.county !== "" &&
                    result !== undefined ? (
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h2" className="warning">
                          Subscription Details
                        </CardTitle>
                        <span className="float-right">
                          {" "}
                          <i
                            onClick={SubscriptionCommissions}
                            className="ni ni-money-coins warning"
                          ></i>
                        </span>
                      </CardHeader>
                      <Modal
                        isOpen={subComm}
                        toggle={closeComm}
                        className="price-override-modal"
                      >
                        <ModalHeader className="header-leftright-block warning">
                          Price Override
                          <span className="float-right">
                            <i
                              onClick={closeComm}
                              className="far fa-times-circle popup-close"
                            ></i>
                          </span>
                        </ModalHeader>
                        <ModalBody>
                          <div className="subscription-price-box">
                            <FormGroup>
                              <Label for="subscriptionPrice">
                                Subscription Price
                              </Label>
                            </FormGroup>
                          </div>
                          <Input
                            type="text"
                            placeholder="Enter new subscription price"
                            name="overrideAmount"
                            value={overrideAmount}
                            autoComplete="off"
                            onChange={changeOverrideAmt}
                          ></Input>
                          <label className="red_err">{err.amt}</label>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            onClick={confirmOverride}
                            type="submit"
                            color="warning"
                          >
                            Confirm Override
                          </Button>
                        </ModalFooter>
                      </Modal>
                      <CardBody>
                        <div>
                          <p>
                            <strong>Subscription Type:</strong>{" "}
                            {Subscription.sub_name}{" "}
                          </p>
                          <p>
                            <strong>State:</strong> &nbsp;
                            {State.map((st) => {
                              if (st.abbreviation === Subscription.state) {
                                return st.name;
                              } else return "";
                            })}
                          </p>
                          <p>
                            <strong>County:</strong> {Subscription.county}
                          </p>
                          {result && result.length > 1 ? (
                            <div>
                              <p>
                                <strong>AVG Monthly Leads:</strong>{" "}
                                {Subscription.avgMonLeads}{" "}
                              </p>
                              <p>
                                <strong>Price:</strong>$
                                {Subscription.override_amount !== ""
                                  ? Subscription.override_amount
                                  : Subscription.amount}
                              </p>
                            </div>
                          ) : (
                            <div>
                              <p>
                                <strong>AVG Monthly Leads:</strong>{" "}
                                {result[0].avgmonthlyleads}{" "}
                              </p>
                              <p>
                                <strong>Price:</strong> $
                                {Subscription.override_amount !== ""
                                  ? Subscription.override_amount
                                  : Number(result[0].price)}
                              </p>
                            </div>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  ) : Subscription.sub_name === "Micro Campaign" &&
                    microCampaignPrice !== 0 ? (
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h2" className="warning">
                          Subscription Details{" "}
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <div>
                          <p>
                            <strong>Subscription Type:</strong>{" "}
                            {Subscription.sub_name}{" "}
                          </p>
                          <p>
                            <strong>Price:</strong> ${microCampaignPrice}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default PrintProfile;
