import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Modal, ModalHeader } from "reactstrap";
import textiumLogo from "../../assets/img/textiumLogo.png";
import { useDispatch } from "react-redux";
import {
  API_CART_DETAILS,
  API_GET_ORDER_DETAIL,
  API_DROP_HOLD_OR_RESUME,
} from "../../config/Api";
import AccutxtInformation from "../Orders/AccutxtInformation";
import moment from "moment";
const CartListDetails = (props) => {
  const [cartDetail, setCartDetails] = useState();

  // const listStatus = ["red", "green", "orange"];
  const listStatus = ["red", "orange", "#800080", "green"];
  const [accuTxtFlag, setAccutxtFlag] = useState(false);
  const [accutxt_details, setAccutxtDetail] = useState({});

  const dropStatus = [
    "Proof is approved",
    "Proof aproved Awaiting mail Drop",
    "Mail Sent",
    "Cancelled",
    "Saved As Draft",
    "Mailer Created",
    "Preview",
    "Mailer In Production",
    "Mailer Complete",
    "Pending",
    "Hold",
  ];

  useEffect(() => {
    getProductDetail();
  }, []);

  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return d.getDate() + "/" + (d.getMonth() + 1) + "/" + d.getFullYear();
  };

  const getProductDetail = () => {
    let ur =
      props.sectionType == "order"
        ? API_GET_ORDER_DETAIL + props.data.order_id
        : API_CART_DETAILS + props.data.cart_id;
    props.callRequest("GET", ur, true, null).then((res) => {
      res.data.Product = res.data.Product.reverse();
      setCartDetails(res.data);
    });
  };

  const holdResumeDrop = (mailing_date_id, status) => {
    //API_DROP_HOLD_OR_RESUME

    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let tempStatus = status == 10 ? 0 : 10;

    //API_ORDER_HOLD_OR_RESUME
    const formData = new FormData();
    formData.append("mailing_date_id", mailing_date_id);
    formData.append("status", tempStatus);

    props
      .callRequest("POST", API_DROP_HOLD_OR_RESUME, true, formData)
      .then((res) => {
        Swal.close();
        Swal.fire(
          "Success",
          "DROP status has been updated successfully",
          "success"
        );

        if (props.payAsYouGo === "Yes") {
          props.fetchPayAsYouGoOrders();
        } else {
          props.fetchProducts();
        }
        getProductDetail();
      })
      .catch((e) => {
        let msg = e.response.data && e.response.data.join();
        Swal.fire("Error", msg, "error");
      });
  };

  const newDateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return (
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2) +
      "/" +
      d.getFullYear()
    );
  };

  const textiumLogoHandler = (item) => {
    setAccutxtFlag(true);
    setAccutxtDetail({ ...item });
  };

  const closeTextiumHandler = () => {
    setAccutxtFlag(false);
  };

  return (
    <>
      <div className="orderlist-details-block">
        {cartDetail &&
          cartDetail.Product.map((item) => {
            return (
              <div key={item.mailing_date_id} className="orderlist-item-box">
                <div className="list-item product-name">
                  <h5 className="item-titel">Product Name</h5>
                  <p>{item.item}</p>
                </div>
                <div className="list-item quantity">
                  <h5 className="item-titel">Quantity</h5>
                  <p>{item.quantity}</p>
                </div>
                <div className="list-item postage">
                  <h5 className="item-titel">Postage</h5>
                  <p>{item.postage}</p>
                </div>
                <div className="list-item envelope">
                  <h5 className="item-titel">Envelope</h5>
                  <p>{item.envelope_name == "" ? "N/A" : item.envelope_name}</p>
                </div>
                <div className="list-item desired-mail-date">
                  <h5 className="item-titel">Desired Mail Date</h5>
                  <p>{moment(item.date, "MM-DD-YYYY").format("MM/DD/YYYY")}</p>
                </div>

                {props.isDraft === 1 ? null : (
                  <div className="list-item desired-mail-date">
                    <h5 className="item-titel">Lockout Date</h5>
                    <p>
                      {"drop_lockout_date" in item &&
                      item.drop_lockout_date &&
                      item.status != 4
                        ? newDateFormater(item.drop_lockout_date)
                        : "--"}
                    </p>
                  </div>
                )}
                <div className="list-item per-piece">
                  <h5 className="item-titel">Per Piece</h5>
                  <p>
                    ${" "}
                    {Number(parseFloat(item.per)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                {props.sectionType == "order" ? (
                  <div className="list-item per-piece">
                    <h5 className="item-titel">Status</h5>
                    <p>{dropStatus[item.status]}</p>
                  </div>
                ) : null}
                {props.payAsYouGo === "Yes" ? (
                  <div className="list-item per-piece">
                    <h5 className="item-titel">Payment Status</h5>
                    <p>
                      {item.status == 4
                        ? "--"
                        : item.paymentStatus == 0
                        ? "Pending"
                        : item.paymentStatus == 1
                        ? "Success"
                        : "Failure"}
                    </p>
                  </div>
                ) : null}
                <div className="list-item desired-mail-date">
                  <h5 className="item-titel">Proof</h5>
                  <p>
                    <i
                      className="fas fa-circle"
                      style={{
                        color: item.is_proof_approved == 1 ? "green" : "orange",
                      }}
                    ></i>
                  </p>
                </div>

                <div className="list-item desired-mail-date">
                  <h5 className="item-titel">List</h5>
                  <p>
                    <i
                      className="fas fa-circle"
                      style={{
                        color: listStatus[item.list_status],
                      }}
                    ></i>
                  </p>
                </div>
                {props.sectionType == "order" &&
                props.isDraft === 1 ? null : props.sectionType == "order" ? (
                  <div className="list-item desired-mail-date">
                    <h5 className="item-titel">Batch Status</h5>
                    <p>
                      <i
                        className="fas fa-circle"
                        style={{
                          color:
                            item.mailer_ready_to_batch == 1 ? "blue" : "orange",
                        }}
                      ></i>
                    </p>
                  </div>
                ) : null}

                <div className="list-item total-piece">
                  <h4 className="total-piece">
                    $
                    {Number(parseFloat(item.total)).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h4>
                </div>
                {item.is_lockout != 1 && (
                  <div className="list-item total-piece">
                    <h4 className="total-piece">
                      {props.sectionType !==
                      "order" ? null : item.textResponse1 == null &&
                        item.responsetxt1 == null &&
                        item.textEmail1 == null &&
                        item.textEmail2 == null &&
                        item.textEmail3 == null ? null : item.textResponse1 !==
                          "" ||
                        item.responsetxt1 !== "" ||
                        item.textEmail1 !== "" ||
                        item.textEmail2 !== "" ||
                        item.textEmail3 !== "" ? (
                        <span>
                          {" "}
                          <img
                            src={textiumLogo}
                            alt="textium logo"
                            onClick={() => textiumLogoHandler(item)}
                            className="fas fa-comment"
                            style={{ height: "23px", width: "23px" }}
                          />
                          &nbsp;
                        </span>
                      ) : null}
                      <span
                        title={item.status == 10 ? "Resume" : "Hold"}
                        onClick={() =>
                          holdResumeDrop(item.mailing_date_id, item.status)
                        }
                      >
                        <i
                          className={
                            item.status == 10 ? "fas fa-play" : "fas fa-pause"
                          }
                        ></i>
                      </span>
                    </h4>
                  </div>
                )}
              </div>
            );
          })}

        {cartDetail &&
          cartDetail.Mailinglist.map((item) => {
            return (
              <div className="orderlist-item-box">
                <div className="list-item product-name">
                  <p>Mailing List </p>
                </div>
                <div className="list-item quantity">
                  <h5 className="item-titel">Quantity</h5>
                  <p>{item.mailingqty}</p>
                </div>
                <div className="list-item postage"></div>
                <div className="list-item envelope deactive"></div>
                <div className="list-item desired-mail-date"></div>
                {/* <div className="list-item status">
                  <h5 className="item-titel">Status</h5>
                  <p>In-Production</p>
                </div> */}
                <div className="list-item per-piece">
                  <h5 className="item-titel">Per Piece</h5>
                  <p>
                    $
                    {Number(
                      parseFloat(
                        Number(item.mailinglist_price) * Number(item.mailingqty)
                      )
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </div>
                <div className="list-item total-piece">
                  <h4 className="total-piece">
                    $
                    {Number(
                      parseFloat(
                        Number(item.mailinglist_price) * Number(item.mailingqty)
                      )
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </h4>
                </div>
              </div>
            );
          })}
      </div>
      {cartDetail !== undefined &&
      accuTxtFlag === true &&
      props.sectionType === "order" ? (
        <Modal isOpen={accuTxtFlag} toggle={closeTextiumHandler}>
          <div className="accutxt-info-modal">
            <h2
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "23px",
              }}
            >
              <span style={{ color: "#e30613" }}>AccuTXT</span> Information
            </h2>
            <span style={{ cursor: "pointer" }}>
              <i
                onClick={closeTextiumHandler}
                className="fas fa-times-circle"
              ></i>
            </span>
          </div>

          <AccutxtInformation
            {...props}
            viewAccutxt={1}
            item={accutxt_details}
            closeTextiumHandler={closeTextiumHandler}
            fetchProducts={getProductDetail}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default CartListDetails;
