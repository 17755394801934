import React, { useState } from "react";
import "./assets/css/TemplateDesign.css";

const LogoUploaderComponentLeft = (props) => {
    const [lock, setLock] = useState((props.logo_uploader_lock === undefined || props.logo_uploader_lock === false) ? false : true);

    const toggleLock = (e) => {
        setLock(!lock);
        props.handleLogoLockUnlock(props.id, !lock, "logo_uploader_lock");
    };

    return (
        <>
            {
                props.title == "{{logo}}" ?
                    <div className="custom_text_full">
                        <p
                            dangerouslySetInnerHTML={{ __html: props.title }}
                        ></p>
                        <button
                            style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
                            value={lock}
                            onClick={(e) => toggleLock(e)}
                        >
                            {lock ? (
                                <i style={{ color: "#66707d" }} className="fas fa-lock"></i>
                            ) : (
                                <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
                            )}
                        </button>
                        <button
                            className="btn btn-delete"
                            onClick={() => props.parentDeleteLogoUploader(props.id)}
                        >
                            Delete
                        </button>
                    </div>
                    :
                    <div className={"img_box_" + props.id + " image_upload_box"}>
                        <div className="image_box_area">
                            <img
                                alt="logo"
                                src={props.title}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    position: "acsolute",
                                }}
                            />
                            <button
                                style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
                                value={lock}
                                onClick={(e) => toggleLock(e)}
                            >
                                {lock ? (
                                    <i style={{ color: "#66707d" }} className="fas fa-lock"></i>
                                ) : (
                                    <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
                                )}
                            </button>
                            <button
                                className="btn-image-delete"
                                onClick={() => props.parentDeleteLogoUploader(props.id)}

                            >
                                X
                            </button>
                        </div>
                    </div>
            }
        </>
    );
};

export default LogoUploaderComponentLeft;