import React, { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

//var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();

  // verifies if routeName is the one active (in browser input)
  // const activeRoute = (routeName) => {
  //   return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = (key, main_menu_flag = false, routes) => {
    activeMenuClass(key, routes);
    setCollapseOpen(false);
  };

  const activeMenuClass = (key, routes) => {
    routes.forEach((prop, index) => {
      if (typeof prop.submenue === "undefined") {
        if (
          document.getElementById("active_menu_link_class_" + index) !== null
        ) {
          document
            .getElementById("active_menu_link_class_" + index)
            .classList.remove("active");
        }
      } else {
        if (index !== key) {
          if (document.getElementById("active_menu_class_" + index)) {
            document
              .getElementById("active_menu_class_" + index)
              .classList.remove("active-menu-class");
          }
          if (document.getElementById("active_menu_link_class_" + index)) {
            document
              .getElementById("active_menu_link_class_" + index)
              .classList.remove("active");
          }
        }
      }
    });
    document
      .getElementById("active_menu_class_" + key)
      .classList.toggle("active-menu-class");
    document
      .getElementById("active_menu_link_class_" + key)
      .classList.add("active");
  };

  const get_sub_menue = (submenue) => {
    return submenue.map((sub, k) => {
      if (sub.is_menu) {
        return (
          <li key={k} className="submenu-item">
            <NavLink
              to={sub.layout + sub.path}
              tag={NavLinkRRD}
              activeClassName="active"
            >
              {sub.name}
            </NavLink>
          </li>
        );
      }
    });
  };

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (typeof prop.submenue === "undefined") {
        if (prop.is_menu === true) {
          return (
            <NavItem key={key} id={"active_menu_class_" + key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={() => closeCollapse(key, false, routes)}
                id={"active_menu_link_class_" + key}
                activeClassName="active"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
            </NavItem>
          );
        }
      } else {
        if (prop.is_menu === true) {
          return (
            <NavItem
              key={key}
              id={"active_menu_class_" + key}
              className="dropdown"
            >
              <NavLink
                to="#"
                tag={NavLinkRRD}
                onClick={() => closeCollapse(key, true, routes)}
                id={"active_menu_link_class_" + key}
                activeClassName="active1"
              >
                <i className={prop.icon} />
                {prop.name}
              </NavLink>
              <ul className="submenu">{get_sub_menue(prop.submenue)}</ul>
            </NavItem>
          );
        }
      }
    });
  };

  const { routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {logo ? (
          <Nav className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
              width={180}
            />
          </Nav>
        ) : null}
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Nav navbar>{createLinks(routes)}</Nav>
          <hr className="my-3" />
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
