import React from 'react';
import { AiOutlineFontSize } from 'react-icons/ai';
import { BsCardImage, BsFillXDiamondFill, BsRulers, BsArrowLeft, } from 'react-icons/bs';
import { CgColorBucket } from 'react-icons/cg';
import { FaShapes } from 'react-icons/fa';

const MenuItem = (props) => {
    const handleMenu = (e, type) => {
        e.preventDefault();
        props.handleMenu(type);
    }

    return (
        <>
            <div className="main-menu-section">
                {props.back_menu ?
                    <div className='main-menu'>
                        <button
                            className="template-back-button"
                            onClick={(e) => handleMenu(e, 'back_menu')}

                        >
                            <div className='back-button'>
                                <span className='icon'><BsArrowLeft /></span>
                                <span className='name'>Back</span>
                            </div>
                        </button>
                    </div>
                    :
                    <div className='menu-item-block'>
                        <button
                            className="menu-item-btn"
                            onClick={(e) => handleMenu(e, 'text_menu')}
                        > <div className='menu-item'>
                                <span className='icon'><AiOutlineFontSize /></span>
                                <span className='name'>Text</span>
                            </div>
                        </button>
                        <button
                            className="menu-item-btn"
                            onClick={(e) => handleMenu(e, 'image_menu')}
                        >
                            <div className='menu-item'>
                                <span className='icon'><BsCardImage /></span>
                                <span className='name'>Images</span>
                            </div>
                        </button>
                        <button
                            className="menu-item-btn"
                            onClick={(e) => handleMenu(e, 'background_menu')}
                        >
                            <div className='menu-item'>
                                <span className='icon'><CgColorBucket /></span>
                                <span className='name'>Background</span>
                            </div>
                        </button>
                        <button
                            className="menu-item-btn"
                            onClick={(e) => handleMenu(e, 'component_menu')}
                        >
                            <div className='menu-item'>
                                <span className='icon'><BsFillXDiamondFill /></span>
                                <span className='name'>Components</span>
                            </div>
                        </button>
                        <button
                            className="menu-item-btn"
                            onClick={(e) => handleMenu(e, 'ruler_menu')}
                        >
                            <div className='menu-item'>
                                <span className='icon'><BsRulers /></span>
                                <span className='name'>Ruler</span>
                            </div>
                        </button>
                        <button
                            className="menu-item-btn"
                            onClick={(e) => handleMenu(e, 'shape_menu')}
                        >
                            <div className='menu-item'>
                                <span className='icon'><FaShapes /></span>
                                <span className='name'>Shapes</span>
                            </div>
                        </button>
                    </div>
                }
            </div>
        </>
    );
};

export default MenuItem;