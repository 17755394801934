import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import { AlphaPicker } from "react-color";

const TemplateColorPicker = (props) => {
  const [color, setColor] = useState("#ffffff");
  const [colorAlpha, setColorAlpha] = useState("#ffffff");
  const [selectedColor, setSelectedColor] = useState(props.selectedColor);

  useEffect(() => {
    if (props.selectedColor) {
      const rgba = props.selectedColor
        .replace(/^rgba?\(|\s+|\)$/g, "")
        .split(",");
      setSelectedColor(rgba);
    }
  }, [props.selectedColor]);

  const handleChange = (color) => {
    setColor(color);
    let hexToRgbA = props.hexToRgba(color);
    let rgb = {
      r: hexToRgbA[0],
      g: hexToRgbA[1],
      b: hexToRgbA[2],
      a: hexToRgbA[3],
    };
    props.parentCallBackgroundColorPicker(rgb, props.imageType);
  };

  const onChangeAlpha = (color) => {
    setColorAlpha(color.rgb);
    props.parentCallBackgroundColorPicker(color.rgb, props.imageType);
  };

  return (
    <div className="template-color-btn left-ui-section">
      <div className="color-picker-header left-ui-header">
        <span>Template Background</span>
      </div>
      <div className="left-ui-body-section">
        <input
          className="color-picker-input"
          type="color"
          value={
            props.selectedColor ? props.rgbaToHex(props.selectedColor) : color
          }
          onChange={(e) => handleChange(e.target.value)}
          style={{ opacity: selectedColor[3] ? selectedColor[3] : 1 }}
        />
        <AlphaPicker
          color={props.selectedColor ? props.selectedColor : colorAlpha}
          onChange={onChangeAlpha}
        />
      </div>
    </div>
  );
};

export default TemplateColorPicker;
