import React, { Component } from "react";
import "./assets/css/TemplateDesign.css";
import { BreadCrumb } from "../../config/Components";
import Designer from "./Designer";

export default class TemplateDesign extends Component {
  render() {
    return (
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Template List", "Add Template"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-album-2"></i>
            </span>
            Add Template
          </h1>
        </div>
        <Designer {...this.props} />
      </main>
    );
  }
}
