import React, { useState, useEffect } from "react";
import "./assets/css/TemplateDesign.css";
import ResizableImageComponent from "./ResizableImageComponent";

let default_style = {
  width: "100%",
  height: "100%",
  display: "flex",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  padding: "0",
  margin: "0",
  border: "none",
};

const ImageComponent = (props) => {
  const [top, setTop] = useState(props.top);
  const [left, setLeft] = useState(props.left);
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const [modalImageSrc] = useState(props.modalImageSrc);
  const [custom_image_lock, setCustomImageLock] = useState(props.custom_image_lock);

  useEffect(() => {
    setTop(props.top);
    setLeft(props.left);
    setWidth(props.width);
    setHeight(props.height);
    setCustomImageLock(props.custom_image_lock);
  }, [props.top, props.left, props.width, props.height, props.custom_image_lock]);

  function setImageDesign(
    htmldata,
    id,
    left,
    top,
    width,
    height,
    modalImageSrc,
  ) {
    props.parentSetImageDesign(
      htmldata,
      id,
      left,
      top,
      width,
      height,
      props.title,
      modalImageSrc,
      props.order,
    );
  }
  return (
    <ResizableImageComponent
      top={top}
      left={left}
      width={width}
      height={height}
      modalImageSrc={modalImageSrc}
      parentSetImageDesign={setImageDesign}
      id={props.id}
      original_image_width={props.original_image_width}
      original_image_height={props.original_image_height}
      custom_image_lock={custom_image_lock}
    >
      <div style={default_style}>
        <img alt="" src={props.modalImageSrc} style={default_style}></img>
      </div>
    </ResizableImageComponent>
  );
};

export default ImageComponent;
