import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";

const NonEditablePostageComponentLeft = (props) => {
  const [lock, setLock] = useState(props.non_editable_postage_lock);

  useEffect(() => {
    setLock(props.non_editable_postage_lock);
  }, [props.non_editable_postage_lock]);

  const toggleLock = (e) => {
    setLock(!lock);
    props.handleNonEditablePostageLockUnlock(props.id, !lock, "non_editable_postage_lock");
  };

  return (
    <>
      {props.title == "{{postage}}" ?
        <div className="custom_text_full">
          <p
            dangerouslySetInnerHTML={{ __html: props.title }}
            onClick={() =>
              props.ParentNonEditableCustomPostage(props.id, "non_editable_postage")
            }
          ></p>
          <button
            style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
            value={lock}
            onClick={(e) => toggleLock(e)}
          >
            {lock ? (
              <i style={{ color: "#66707d" }} className="fas fa-lock"></i>
            ) : (
              <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
            )}
          </button>
          <button
            className="btn btn-delete"
            onClick={() => props.ParentNonEditableDeleteCustomPostage(props.id)}
          >
            Delete
          </button>
        </div>
        :
        <div className={"img_box_" + props.id + " image_upload_box"}>
          <div className="image_box_area">
            <img
              alt="logo"
              src={props.title}
              style={{
                width: "100%",
                height: "100%",
                position: "acsolute",
              }}
            />
            <button
              style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
              value={lock}
              onClick={(e) => toggleLock(e)}
            >
              {lock ? (
                <i style={{ color: "#66707d" }} className="fas fa-lock"></i>
              ) : (
                <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
              )}
            </button>
            <button
              className="btn-image-delete"
              onClick={() => props.ParentNonEditableDeleteCustomPostage(props.id)}

            >
              X
            </button>
          </div>
        </div>
      }
    </>
  );
};

export default NonEditablePostageComponentLeft;
