import React, { useState, useEffect } from "react";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

const ContextMenuImage = (props) => {
  const [currentZIndex, setCurrentZIndex] = useState(props.allCustomImages);
  const MENU_ID = props.currentImgBoxId;

  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
  });

  useEffect(() => {
    setCurrentZIndex(props.allCustomImages);
  }, [setCurrentZIndex, props.allCustomImages]);

  function handleContextMenu(event) {
    event.preventDefault();
    show(event, {
      props: {
        key: "value",
      },
    });
  }

  const getIdByOrderUp = (order) => {
    hideAll();
    let allCustomImages = props.allCustomImages;
    let customImageId = "";

    let looping = "";
    allCustomImages.forEach(function (customImage, index) {
      if (customImage.order === order && looping === "") {
        customImageId = customImage.id;
        looping = customImageId;
      }
    });
    return customImageId;
  };

  const getIdByOrderDown = (order, z_index_21 = false) => {
    hideAll();
    let allCustomImages = props.allCustomImages;
    let customImageId = "";
    allCustomImages.forEach(function (customImage, index) {
      if (z_index_21 === true) {
        let detect_latest_order_exclude_21 = 0;
        allCustomImages.forEach(function (elem) {
          if (
            detect_latest_order_exclude_21 < elem.order &&
            elem.id !== 0 &&
            elem.order < 21
          ) {
            detect_latest_order_exclude_21 = elem.order;
          }
        });
        customImageId = getIdByOrderDown(detect_latest_order_exclude_21, false);
      } else {
        if (customImage.order === order) {
          customImageId = customImage.id;
        }
      }
    });
    return customImageId;
  };

  const handleItemUpAll = (event) => {
    hideAll();
    let allCustomImages = props.allCustomImages;
    let temp_state = [...currentZIndex];
    let temp_element = { ...temp_state[props.currentImgBoxId] };
    temp_element.img_box_title =
      temp_state[props.currentImgBoxId].img_box_title;
    temp_element.img_src = temp_state[props.currentImgBoxId].img_src;
    if (typeof temp_element.img_src === "undefined") {
      temp_element.img_src = props.currentImgBoxSrc;
    }
    temp_element.order = allCustomImages.length - 1;
    temp_state[props.currentImgBoxId] = temp_element;

    let current_order;
    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].id > 0) {
        if (currentZIndex[i].id === props.currentImgBoxId) {
          current_order = allCustomImages[i].order;
          currentZIndex[i].order = allCustomImages.length;
        }
      }
    }
    // detect missing order start

    let if_exist_21_zindex = false;
    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].order === 21) {
        if_exist_21_zindex = true;
        currentZIndex[i].order = currentZIndex.length - 1;
      }
    }

    // detect missing order start
    if (if_exist_21_zindex === true) {
      var a = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ];

      let check_data = [];
      for (let i = 0; i < currentZIndex.length - 1; i++) {
        check_data.push(a[i]);
      }
      let image_data = [];
      for (let i = 0; i < currentZIndex.length; i++) {
        if (currentZIndex[i].id > 0) {
          image_data.push(currentZIndex[i].order);
        }
      }

      let notPresentInData = check_data.filter(
        (val) => !image_data.includes(val)
      );
      current_order = notPresentInData[0];
    }
    // detect missing order end
    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].id > 0 && currentZIndex[i].order >= current_order) {
        currentZIndex[i].order = currentZIndex[i].order - 1;
      }
    }
    setCurrentZIndex(currentZIndex);
    props.parentUpdateImageCustomsZIndex(
      temp_element.img_box_title,
      temp_element.img_src,
      temp_element.order,
      props.currentImgBoxId,
      props.customMyProps.imageType,
      currentZIndex
    );
  };

  const handleItemUp = () => {
    hideAll();
    let temp_state = [...currentZIndex];
    let temp_element = { ...temp_state[props.currentImgBoxId] };
    temp_element.img_box_title =
      temp_state[props.currentImgBoxId].img_box_title;
    temp_element.img_src = props.currentImgBoxSrc;
    if (typeof temp_element.img_src === "undefined") {
      temp_element.img_src = props.currentImgBoxSrc;
    }
    temp_element.order = temp_element.order + 1;
    temp_state[props.currentImgBoxId] = temp_element;

    let just_prevous_id;
    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].id > 0) {
        if (currentZIndex[i].id === props.currentImgBoxId) {
          just_prevous_id = getIdByOrderUp(currentZIndex[i].order + 1);
          let current_order = currentZIndex[i].order + 1;
          currentZIndex[i].order = current_order;
        }
      }
    }

    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].id > 0) {
        if (currentZIndex[i].id === just_prevous_id) {
          currentZIndex[i].order = currentZIndex[i].order - 1;
        }
      }
    }
    // if order = 21, then adjust zindex start
    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].order === 21) {
        let detect_just_before_max_id = getIdByOrderDown("", true);
        if (
          detect_just_before_max_id === props.currentImgBoxId &&
          just_prevous_id === ""
        ) {
          currentZIndex[i].order =
            currentZIndex[detect_just_before_max_id].order - 1;
        }
      }
    }
    setCurrentZIndex(currentZIndex);
    props.parentUpdateImageCustomsZIndex(
      temp_element.img_box_title,
      temp_element.img_src,
      temp_element.order,
      props.currentImgBoxId,
      props.customMyProps.imageType,
      currentZIndex
    );
  };

  const handleItemDown = (event) => {
    hideAll();
    let temp_state = [...currentZIndex];
    let temp_element = { ...temp_state[props.currentImgBoxId] };
    temp_element.img_box_title =
      temp_state[props.currentImgBoxId].img_box_title;
    temp_element.img_src = temp_state[props.currentImgBoxId].img_src;
    if (typeof temp_element.img_src === "undefined") {
      temp_element.img_src = props.currentImgBoxSrc;
    }
    if (temp_element.order === 21) {
      temp_element.order = currentZIndex.length - 1;
    }

    temp_element.order = temp_element.order - 1;
    temp_state[props.currentImgBoxId] = temp_element;

    let just_after_id;
    let there_is_order_with_21 = false;
    let current_order;
    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].id > 0) {
        if (currentZIndex[i].id === props.currentImgBoxId) {
          if (currentZIndex[i].order === 21) {
            just_after_id = getIdByOrderDown(currentZIndex[i].order - 1, true);
            current_order = currentZIndex.length - 1;
            currentZIndex[i].order = current_order;
            there_is_order_with_21 = true;
          } else {
            just_after_id = getIdByOrderDown(currentZIndex[i].order - 1, false);
            current_order = currentZIndex[i].order - 1;
            currentZIndex[i].order = current_order;
          }
        }
      }
    }

    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].id > 0) {
        if (
          currentZIndex[i].id === just_after_id &&
          there_is_order_with_21 === false
        ) {
          currentZIndex[i].order = currentZIndex[i].order + 1;
        }

        if (
          currentZIndex[i].id === just_after_id &&
          there_is_order_with_21 === true
        ) {
          if (currentZIndex[i].order < currentZIndex.length - 1) {
            currentZIndex[i].order = currentZIndex[i].order + 1;
          }
        }

        if (
          currentZIndex[i].id !== just_after_id &&
          there_is_order_with_21 === true
        ) {
          if (
            currentZIndex[i].order !== 1 &&
            currentZIndex[i].order >= current_order - 1
          ) {
            currentZIndex[i].order = currentZIndex[i].order - 1;
          }
        }
      }
    }
    setCurrentZIndex(currentZIndex);
    props.parentUpdateImageCustomsZIndex(
      temp_element.img_box_title,
      temp_element.img_src,
      temp_element.order,
      props.currentImgBoxId,
      props.customMyProps.imageType,
      currentZIndex
    );
  };

  const handleItemDownAll = (event) => {
    hideAll();
    let temp_state = [...currentZIndex];
    let temp_element = { ...temp_state[props.currentImgBoxId] };
    temp_element.img_box_title =
      temp_state[props.currentImgBoxId].img_box_title;
    temp_element.img_src = temp_state[props.currentImgBoxId].img_src;
    if (typeof temp_element.img_src === "undefined") {
      temp_element.img_src = props.currentImgBoxSrc;
    }
    temp_element.order = 1;
    temp_state[props.currentImgBoxId] = temp_element;

    let current_order = "";
    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].id > 0) {
        if (currentZIndex[i].id === props.currentImgBoxId) {
          current_order = currentZIndex[i].order;
          currentZIndex[i].order = 0; // allCustomImages.length;
        }
      }
    }

    // detect missing order start
    if (current_order === 21) {
      var a = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ];

      let check_data = [];
      for (let i = 0; i < currentZIndex.length - 1; i++) {
        check_data.push(a[i]);
      }
      let image_data = [];
      for (let i = 0; i < currentZIndex.length; i++) {
        if (currentZIndex[i].id > 0) {
          image_data.push(currentZIndex[i].order);
        }
      }

      let notPresentInData = check_data.filter(
        (val) => !image_data.includes(val)
      );
      current_order = notPresentInData[0];
    }
    // detect missing order end

    for (let i = 0; i < currentZIndex.length; i++) {
      if (currentZIndex[i].id > 0 && currentZIndex[i].order < current_order) {
        currentZIndex[i].order = currentZIndex[i].order + 1;
      }
    }
    setCurrentZIndex(currentZIndex);
    props.parentUpdateImageCustomsZIndex(
      temp_element.img_box_title,
      temp_element.img_src,
      temp_element.order,
      props.currentImgBoxId,
      props.customMyProps.imageType,
      currentZIndex
    );
  };

  return (
    <div>
      {props.customMyProps.imageBox.id !== 0 &&
        props.customMyProps.imageBox.img_src !== "" &&
        typeof props.customMyProps.imageBox.img_src !== "undefined" ? (
        <span
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            textIndent: -999,
            width: "100%",
            opacity: -0,
          }}
          onContextMenu={handleContextMenu}
        >
          x
        </span>
      ) : null}
      {props.customMyProps.imageBox.id !== 0 ? (
        <Menu id={MENU_ID}>
          {props.allCustomImages[props.currentImgBoxId].order <
            props.allCustomImages.length - 1 ? (
            <Item
              onClick={() => {
                handleItemUpAll();
              }}
            >
              Up All
            </Item>
          ) : null}
          {props.allCustomImages[props.currentImgBoxId].order <
            props.allCustomImages.length - 1 ? (
            <Item
              onClick={() => {
                handleItemUp();
              }}
            >
              Up
            </Item>
          ) : null}
          {props.allCustomImages[props.currentImgBoxId].order > 1 ? (
            <Item
              onClick={() => {
                handleItemDown();
              }}
            >
              Down
            </Item>
          ) : null}
          {props.allCustomImages[props.currentImgBoxId].order > 1 ? (
            <Item
              onClick={() => {
                handleItemDownAll();
              }}
            >
              Down All
            </Item>
          ) : null}
        </Menu>
      ) : null}
    </div>
  );
};

export default ContextMenuImage;
