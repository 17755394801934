import React, { useState, useEffect } from "react";
import { Menu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";

const ContextMenuAccupix = (props) => {
  const [accupix_satellite, setAccupixSatellite] = useState(
    props.nonEditableAccupixs
  );
  const [accupix_streetview, setAccupixStreetview] = useState(
    props.nonEditableAccupixStreetview
  );
  const MENU_ID = props.title.includes("accupix") ? "satellite" : "streetview";
  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
  });

  useEffect(() => {
    setAccupixSatellite(props.nonEditableAccupixs);
    setAccupixStreetview(props.nonEditableAccupixStreetview);
  }, [props.nonEditableAccupixs, props.nonEditableAccupixStreetview]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    show(event, {
      props: {
        key: "value",
      },
    });
  };

  const handleItemUpSatellite = () => {
    hideAll();
    if (MENU_ID == "satellite") {
      let temp_state = [...accupix_satellite];
      let temp_element = { ...temp_state[props.accupix_id] };
      temp_element.z_index = 1000;
      temp_state[props.accupix_id] = temp_element;
      setAccupixSatellite(temp_state);
      props.parentUpdateNonEditableAccupixzIndex(
        temp_element.z_index,
        "satellite"
      );
    }
  };

  const handleItemUpStreetview = () => {
    hideAll();
    let temp_state = [...accupix_streetview];
    let temp_element = { ...temp_state[props.accupix_id] };
    temp_element.z_index = 1000;
    temp_state[props.accupix_id] = temp_element;
    setAccupixStreetview(temp_state);
    props.parentUpdateNonEditableStreetviewAccupixzIndex(
      temp_element.z_index,
      "streetview"
    );
  };

  const handleItemDownSatellite = () => {
    hideAll();
    if (MENU_ID == "satellite") {
      let temp_state = [...accupix_satellite];
      let temp_element = { ...temp_state[props.accupix_id] };
      temp_element.z_index = 10;
      temp_state[props.accupix_id] = temp_element;
      setAccupixSatellite(temp_state);
      props.parentUpdateNonEditableAccupixzIndex(
        temp_element.z_index,
        "satellite"
      );
    }
  };

  const handleItemDownStreetview = () => {
    hideAll();
    let temp_state = [...accupix_streetview];
    let temp_element = { ...temp_state[props.accupix_id] };
    temp_element.z_index = 10;
    temp_state[props.accupix_id] = temp_element;
    setAccupixStreetview(temp_state);
    props.parentUpdateNonEditableStreetviewAccupixzIndex(
      temp_element.z_index,
      "streetview"
    );
  };

  return (
    <div>
      <span
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          textIndent: -999,
          width: "100%",
          height: "100%",
          opacity: -0,
          zIndex: 5,
        }}
        onContextMenu={handleContextMenu}
      >
        x
      </span>
      {MENU_ID == "satellite" ? (
        <Menu id={MENU_ID}>
          <Item
            onClick={() => {
              handleItemUpSatellite();
            }}
          >
            Up
          </Item>

          <Item
            onClick={() => {
              handleItemDownSatellite();
            }}
          >
            Down
          </Item>
        </Menu>
      ) : (
        <Menu id={MENU_ID}>
          <Item
            onClick={() => {
              handleItemUpStreetview();
            }}
          >
            Up
          </Item>

          <Item
            onClick={() => {
              handleItemDownStreetview();
            }}
          >
            Down
          </Item>
        </Menu>
      )}
    </div>
  );
};

export default ContextMenuAccupix;
