import React, { useState, useEffect, useMemo } from "react";
import "./assets/css/TemplateDesign.css";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";

import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";
import {
  API_TEMPLATES,
  API_TEMPLATE_SIZE,
  API_TEMPLATE_LIST,
} from "../../config/Api";

const TemplateList = (props) => {
  const [templates, setTemplates] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterSelection, setfilterSelection] = useState({
    type: "Select Type",
    size: "Select Size",
    temp_list: 2,
  });
  const searchParam = ["template_name", "type", "width", "height"];
  const [page] = useState(1);
  const [newPerPage, setNewPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    getSizes();
    fetchTemplate(1, 10);
  }, []);

  useEffect(() => {
    filteredTemplates();
  }, [filterSelection, filterText]);

  const getUrlQueryParam = (newPerPage, page) => {
    let url_query_param =
      API_TEMPLATE_LIST + "?page_number=" + page + "&page_size=" + newPerPage;
    const template_name = filterText && `&template_name=${filterText}`;
    const size =
      filterSelection.size !== "Select Size" && `&size=${filterSelection.size}`;
    const type =
      filterSelection.type !== "Select Type" && `&type=${filterSelection.type}`;
    setLoadingIndicator(true);
    const temp_list = `&is_master=${filterSelection.temp_list}`;

    if (template_name && !size && !type && !temp_list) {
      url_query_param += template_name;
      setLoadingIndicator(true);
    } else if (!template_name && size && !type && !temp_list) {
      url_query_param += size;
      setLoadingIndicator(true);
    } else if (!template_name && !size && type && !temp_list) {
      url_query_param += type;
      setLoadingIndicator(true);
    } else if (template_name && size && !type && temp_list) {
      url_query_param += template_name + size + temp_list;
      setLoadingIndicator(true);
    } else if (!template_name && size && type && temp_list) {
      url_query_param += size + type + temp_list;
      setLoadingIndicator(true);
    } else if (template_name && !size && type && temp_list) {
      url_query_param += template_name + type + temp_list;
      setLoadingIndicator(true);
    } else if (template_name && size && type && !temp_list) {
      url_query_param += template_name + size + type;
      setLoadingIndicator(true);
    } else if (!template_name && !size && !type && temp_list) {
      url_query_param += temp_list;
      setLoadingIndicator(true);
    } else if (template_name && size && type && temp_list) {
      url_query_param += template_name + size + type + temp_list;
      setLoadingIndicator(true);
    } else if (!template_name && size && !type && temp_list) {
      url_query_param += size + temp_list;
      setLoadingIndicator(true);
    } else if (!template_name && !size && type && temp_list) {
      url_query_param += type + temp_list;
      setLoadingIndicator(true);
    } else if (template_name && !size && !type && temp_list) {
      url_query_param += template_name + temp_list;
      setLoadingIndicator(true);
    }

    return url_query_param;
  };

  const fetchTemplate = (page) => {
    const url_query_param = getUrlQueryParam(newPerPage, page);
    props.callRequest("GET", `${url_query_param}`, true).then((res) => {
      setTemplates(res.data.data);
      setTotalRows(res.data.totla_records);
      setLoading(false);
      setLoadingIndicator(false);
    });
  };

  const handlePageChange = (page) => {
    fetchTemplate(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    const url_query_param = getUrlQueryParam(newPerPage, page);
    props.callRequest("GET", `${url_query_param}`, true).then((res) => {
      setTemplates(res.data.data);
      setNewPerPage(newPerPage);
      setLoadingIndicator(false);
    });
  };

  const templateFilter = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setfilterSelection({ ...filterSelection, [name]: value });
  };

  const searchTeamplate = (e) => {
    setFilterText(e.target.value);
  };

  const filteredTemplates = () => {
    const url_query_param = getUrlQueryParam(newPerPage, page);
    props.callRequest("GET", `${url_query_param}`, true).then((res) => {
      setTemplates(res.data.data);
      setTotalRows(res.data.totla_records);
      setLoadingIndicator(false);
    });
  };

  const getSizes = () => {
    props.callRequest("GET", API_TEMPLATE_SIZE, true).then((res) => {
      setSizes(res.data);
    });
  };

  const getSizeName = (size_id) => {
    const found = sizes.find((size) => size.size_id === size_id);
    let sizeName = "";
    if (found && found.size_id !== 0) {
      sizeName = found.size_name;
    }
    return sizeName;
  };

  const columns = [
    {
      name: "Template Name",
      selector: (row) => row.template_name,
      sortable: true,
    },
    {
      name: "Type",
      selector: "type",
      sortable: true,
      cell: (row) => (row.type == "Both" ? "Double Sided" : "Single"),
    },
    {
      name: "Size Name",
      selector: (row) => row.size,
      sortable: true,
      cell: (row) => getSizeName(row.size),
    },
    {
      name: "Width",
      selector: (row) => row.width,
      sortable: true,
    },
    {
      name: "Height",
      selector: (row) => row.height,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.interval_id,
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            <DropdownItem onClick={() => editTemplate(row.template_id)}>
              Edit
            </DropdownItem>
            <DropdownItem onClick={() => onDelete(row.template_id)}>
              Delete
            </DropdownItem>
            <DropdownItem onClick={() => onDuplicate(row.template_id)}>
              Duplicate
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const editTemplate = (template_id) => {
    props.history.push("/edit-template/" + template_id);
  };

  const onDuplicate = (template_id) => {
    props.callRequest("GET", API_TEMPLATES + template_id, true).then((res) => {
      let duplicate_template_name =
        res.data.template_name +
        " duplicate " +
        Math.random().toString(36).substr(2, 3);
      props
        .callRequest("POST", API_TEMPLATES, true, {
          template_name: duplicate_template_name,
          type: res.data.type,
          height: res.data.height.split("in")[0],
          width: res.data.width.split("in")[0],
          bleed: res.data.bleed.split("in")[0],
          margin: res.data.margin.split("in")[0],
          template_json: res.data.template_json,
          html_front: res.data.html_front,
          html_back: res.data.html_back,
          png_front: res.data.png_front,
          png_back: res.data.png_back,
          size: res.data.size,
          //user: res.data.user,
          accupix_type_front: res.data.accupix_type_front,
          accupix_type_back: res.data.accupix_type_back,
          accupix_aspect_ratio_front: res.data.accupix_aspect_ratio_front,
          accupix_aspect_ratio_back: res.data.accupix_aspect_ratio_back,
          accupix_type_front_two: res.data.accupix_type_front_two,
          accupix_type_back_two: res.data.accupix_type_back_two,
          accupix_aspect_ratio_front_two:
            res.data.accupix_aspect_ratio_front_two,
          accupix_aspect_ratio_back_two: res.data.accupix_aspect_ratio_back_two,
          is_bleed: res.data.is_bleed,
          is_master: res.data.is_master,
          is_front_img_transparent: res.data.is_front_img_transparent,
          is_back_img_transparent: res.data.is_back_img_transparent,
        })
        .then((res) => {
          if (res.status) {
            fetchTemplate(page, newPerPage);
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title:
                "Duplicate template has been created successfully. Please check top on first page.",
            });
          }
        });
    });
  };

  const onDelete = (template_id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("DELETE", API_TEMPLATES + template_id, true)
          .then((res) => {
            if (res.status) {
              Swal.fire("Deleted!", "", "success");
              fetchTemplate(page, newPerPage);
            }
          });
      }
    });
  };

  const subHeaderComponentMemo = (
    <Row>
      <Col sm={3}>
        <FormGroup>
          <Input
            id="search"
            type="text"
            placeholder="Search"
            aria-label="Search Input"
            value={filterText}
            onChange={(e) => searchTeamplate(e)}
            autoComplete="off"
          />
        </FormGroup>
      </Col>
      <Col sm={3}>
        <FormGroup>
          <Input
            type="select"
            name="size"
            id="size"
            onChange={(e) => templateFilter(e)}
          >
            <option value="Select Size">Select Size</option>
            {sizes.map((size, index) => {
              return (
                <option key={index} value={size.size_id}>
                  {size.size_name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
      </Col>

      <Col sm={3}>
        <FormGroup>
          <Input
            type="select"
            name="type"
            id="type"
            onChange={(e) => templateFilter(e)}
          >
            <option value="Select Type">Select Type</option>
            <option value="Single">Single</option>
            <option value="Both">Double Sided</option>
          </Input>
        </FormGroup>
      </Col>
      <Col sm={3}>
        <FormGroup>
          <Input
            type="select"
            name="temp_list"
            id="temp_list"
            onChange={(e) => templateFilter(e)}
          >
            <option value={2}>All Templates</option>
            <option value={1}>Master Templates</option>
            <option value={0}>Copy Templates</option>
          </Input>
        </FormGroup>
      </Col>
    </Row>
  );

  const Export = ({ onExport }) => (
    <div className="right-button-block">
      <Button onClick={() => props.history.push("/template-design")}>
        <i className="ni ni-fat-add"></i> Add Template
      </Button>
    </div>
  );

  const export_column = ["template_name", "type", "width", "height"];

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            templates,
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [templates]
  );

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Templates", "Template List"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-album-2"></i>
            </span>
            Template List
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <div className="list-filtered-table-block">
                    <DataTable
                      columns={columns}
                      data={DataTableSettings.filterItems(
                        templates,
                        searchParam,
                        filterText
                      )}
                      pagination
                      paginationPerPage={DataTableSettings.paginationPerPage}
                      paginationRowsPerPageOptions={
                        DataTableSettings.paginationRowsPerPageOptions
                      }
                      actions={actionsExport}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                      progressPending={loadingIndicator}
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                    />
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default TemplateList;
