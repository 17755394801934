import React, { useState } from "react";

const TextFontSize = (props) => {
    const [font_min_size, setFontMinSize] = useState(props.font_min_size);
    const [font_max_size, setFontMaxSize] = useState(props.font_max_size);
    const [errors, setErrors] = useState({});

    const validateSize = (val, size) => {
        let errors = {};
        let formIsValid = true;
        if (size === 'min_size') {
            if (val < 0 || val > 100) {
                formIsValid = false;
                errors["min"] = "Min value should be between 7 to 100!";
            }
        } else {
            if (val < 0 || val > 100) {
                formIsValid = false;
                errors["max"] = "Max value should be between 0 to 100!";
            }
        }
        setErrors(errors);
        return formIsValid;
    }

    function handleMinSize(e) {
        if (!validateSize(e.target.value, 'min_size')) {
            return false;
        }
        setFontMinSize(e.target.value);
        props.handleTextMinMaxSize(e.target.value, font_max_size);
    };

    function handleMaxSize(e) {
        if (!validateSize(e.target.value, 'max_size')) {
            return false;
        }
        setFontMaxSize(e.target.value);
        props.handleTextMinMaxSize(font_min_size, e.target.value);
    };

    return (
        <div className="inputbox-value text-font-size-box left-ui-section">
            <div className="text-font-size-header left-ui-header">
                <span>Text Scaling Size</span>
            </div>
            <div className="inputbox text-font-size-min left-ui-body-section">
                <div className="text-font-size-header left-ui-header">
                    Min
                </div>
                <input
                    type="number"
                    name="font_min_size"
                    id="font_min_size"
                    className="form-control"
                    autoComplete="off"
                    value={font_min_size}
                    onChange={handleMinSize}
                />
                <label className="red_err">{errors.min}</label>
            </div>
            <div className="inputbox text-font-size-max">
                <div className="text-font-size-header left-ui-header">
                    Max
                </div>
                <input
                    type="number"
                    name="font_max_size"
                    id="font_max_size"
                    className="form-control"
                    autoComplete="off"
                    value={font_max_size}
                    onChange={handleMaxSize}
                />
                <label className="red_err">{errors.max}</label>
            </div>
        </div>
    );
};

export default TextFontSize;