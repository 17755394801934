import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { CategoryForm, BreadCrumb } from "../../config/Components";
import {
  API_CATEGORY,
  API_GET_ADMIN_AFFILIATE_DETAILS,
} from "../../config/Api";

import { useSelector } from "react-redux";
const CategoryList = (props) => {
  const { className } = props;
  const [modal, setModal] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const toggle = () => {
    setModal(!modal);
    setCategoryId(0);
  };
  const [categories, setCategory] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const searchParam = ["category_name", "code", "is_active"];
  const [rows, setRows] = useState(10);
  const userType = useSelector((store) => store.getUserType);
  useEffect(() => {
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCategory = async () => {
    props.callRequest("GET", API_CATEGORY, true).then((res) => {
      setCategoryList(res.data);
      setCategory(res.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      name: "Category Name",
      selector: "category_name",
      sortable: true,
    },
    {
      name: "Code",
      selector: "code",
      sortable: true,
    },
    {
      name: "Status",
      selector: "is_active",
      sortable: true,
      cell: (row) => <div>{row.is_active === "1" ? "Active" : "Inactive"}</div>,
    },
    {
      name: "Product Category",
      selector: "parent_category_id",
      sortable: true,
      cell: (row) => (
        <div>{row.parent_category_id == 0 ? "Parent" : "Child"}</div>
      ),
    },
    {
      name: userType && userType == 2 ? null : "Actions",
      selector: "category_id",
      sortable: false,
      cell: (row) =>
        userType && userType == 2 ? null : (
          <UncontrolledDropdown>
            <DropdownToggle>
              <i className="fas fa-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu positionFixed={true}>
              <DropdownItem onClick={() => editCategory(row.category_id)}>
                Edit
              </DropdownItem>
              <DropdownItem onClick={() => onDelete(row.category_id)}>
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        ),
    },
  ];

  const HandleFilter = (index) => {
    if (index === 1) {
      setCategory(categoryList);
    }

    if (index === 2) {
      let filteredSub = categoryList.filter((sub) => {
        return sub.is_active === "1";
      });
      setCategory(filteredSub);
    }
    if (index === 3) {
      let filteredSub = categoryList.filter((sub) => {
        return sub.is_active === "0";
      });
      setCategory(filteredSub);
    }
  };

  const editCategory = (category_id) => {
    toggle();
    setCategoryId(category_id);
  };

  const onDelete = (category_id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        let found = categories.find((cat) => cat.category_id === category_id);
        if (found && found.parent_category_id !== 0) {
          props
            .callRequest("DELETE", API_CATEGORY + category_id, true)
            .then((res) => {
              // if (res.statusText === "No Content") {
              if (res.status) {
                Swal.fire("Deleted!", "", "success");
                fetchCategory();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          Swal.fire("Category can not be deleted!!", "", "info");
        }
      }
    });
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          autoComplete="off"
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      {userType && userType == 2 ? null : (
        <Button onClick={toggle}>
          <i className="ni ni-fat-add"></i> Add Category
        </Button>
      )}
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={HandleFilter.bind(this, 2)}>
            Active
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 3)}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 1)}>
            Remove Filter
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {userType && userType == 2 ? null : (
        <UncontrolledDropdown disabled={categories.length > 0 ? false : true}>
          <DropdownToggle caret>
            <i className="ni ni-ungroup"></i> Export
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) => onExportView(e.target.value)}>
              Export View
            </DropdownItem>
            <DropdownItem onClick={(e) => onExport(e.target.value)}>
              Export All
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );

  const export_column = ["category_name", "code", "is_active"];

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            categories,
            export_column,
            props.generateUniqueId()
          )
        }
        onExportView={() =>
          DataTableSettings.downloadCSV(
            categories.slice(0, rows),
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [categories]
  );
  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };
  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Store Front", "Product Categories"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-bag-17"></i>
            </span>
            Product Categories
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <Modal isOpen={modal} toggle={toggle} className={className}>
                {categoryId === 0 ? (
                  <ModalHeader toggle={toggle}>Add Category</ModalHeader>
                ) : (
                  <ModalHeader toggle={toggle}>Edit Category</ModalHeader>
                )}
                <ModalBody>
                  <CategoryForm
                    {...props}
                    modalToggle={toggle}
                    fetchCategory={fetchCategory}
                    categoryId={categoryId}
                  />
                </ModalBody>
              </Modal>

              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <DataTable
                    columns={columns}
                    data={DataTableSettings.filterItems(
                      categories,
                      searchParam,
                      filterText
                    )}
                    pagination
                    onChangeRowsPerPage={changeRowPerPage}
                    paginationPerPage={DataTableSettings.paginationPerPage}
                    paginationRowsPerPageOptions={
                      DataTableSettings.paginationRowsPerPageOptions
                    }
                    actions={actionsExport}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default CategoryList;
