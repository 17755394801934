import React, { useState } from "react";
import { BreadCrumb } from "../../config/Components";

import "./Orders.css";

import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
} from "reactstrap";

const OrderDetails = (props) => {
  const { buttonLabel, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order History"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            Create Order
          </h1>
        </div>
        <div className="order-main-block">
          <div className="row">
            <div className="col-sm-8">
              <div className="create-order-box">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h2">Campaign</CardTitle>
                  </CardHeader>
                  <CardBody>Text</CardBody>
                </Card>
              </div>
              <div className="create-order-box">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h2">Mailings</CardTitle>
                  </CardHeader>
                  <CardBody>Text</CardBody>
                </Card>
              </div>
              <div className="create-order-box">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h2">Payment Method</CardTitle>
                  </CardHeader>
                  <CardBody>Text</CardBody>
                </Card>
              </div>
              <div className="add-to-cart-block">
                <Button>
                  Add to Cart <i className="ni ni-fat-add"></i>
                </Button>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="right-block">
                <div className="select-user-box">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h2">Select User</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="button-block">
                        <Button onClick={toggle}>Select User</Button>
                      </div>

                      <Modal
                        isOpen={modal}
                        toggle={toggle}
                        className="campaign-modal"
                      >
                        <ModalHeader toggle={toggle}>Select User</ModalHeader>
                        <ModalBody>
                          <Form>
                            <FormGroup>
                              <Label for="searchUser">Search User</Label>
                              <div className="search-input">
                                <Input
                                  type="search"
                                  name="search"
                                  id="searchUser"
                                  placeholder="User Name - User Email"
                                />
                                <Button>
                                  <i className="fas fa-search"></i>
                                </Button>
                              </div>
                            </FormGroup>
                            <div className="modal-button-block confirm-button">
                              <Button>Confirm</Button>
                            </div>
                            <div className="or-block">
                              <span>OR</span>
                            </div>
                            <div className="modal-button-block">
                              <Button
                                onClick={() =>
                                  props.history.push("/create-user")
                                }
                              >
                                Create New User{" "}
                                <i className="ni ni-bold-right"></i>
                              </Button>
                              {/* <Button onClick={() => props.history.push('/admin/create-user')}>Create New User <i className="ni ni-bold-right"></i></Button> */}
                            </div>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </CardBody>
                  </Card>
                </div>
                <div className="ordedr-details-box">
                  <Card>
                    <CardHeader>
                      <CardTitle tag="h2">Order Details</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="ordedr-details-listbox"></div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default OrderDetails;
