import React, { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { BreadCrumb } from "../../config/Components";
import { API_BUNDLE, API_CATEGORY } from "../../config/Api";

import DataTableSettings from "../../utils/DataTableSettings";

import "../../assets/css/data-table.css";
const ProductBundles = (props) => {
  const [bundles, setBundles] = useState([]);
  const [bundleList, setBundleList] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(true);
  const [categories, setCategory] = useState([]);
  const [rows, setRows] = useState(10);
  const searchParam = [
    "bundle_name",
    "description",
    "category",
    "sort_order",
    "statusxx",
  ];
  //const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchBundles();
    fetchCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //added api call
  const fetchBundles = () => {
    props.callRequest("GET", API_BUNDLE, true).then((res) => {
      setBundleList(res.data);
      setBundles(res.data);
      setLoading(false);
    });
  };

  const fetchCategory = () => {
    props.callRequest("GET", API_CATEGORY, true).then((res) => {
      setCategory(res.data);
    });
  };
  const columns = [
    {
      name: "Bundle Name",
      selector: "bundle_name",
      sortable: true,
    },
    {
      name: "Description",
      selector: "bundle_description",
      sortable: true,
    },
    {
      name: "Category",
      selector: "category",
      sortable: true,
      cell: (row) => (
        <div>
          {row.category.map((rowCat) => {
            return categories.map((cat) => {
              if (cat.category_id === rowCat) {
                if (rowCat === row.category[row.category.length - 1]) {
                  return cat.category_name;
                } else {
                  return cat.category_name + " , ";
                }
              }
            });
          })}
        </div>
      ),
    },
    {
      name: "Sort Order",
      selector: "ordering",
      sortable: true,
    },
    {
      name: "Image",
      selector: "imagepathname",
      sortable: true,
      cell: (row) => (
        <img
          src={
            row.imagepathname
              ? process.env.REACT_APP_PUBLIC_URL_PHOTOPATH + row.imagepathname
              : require("../../assets/img/product-default-image.png").default
          }
          alt=""
          width="20"
        />
      ),
    },
    {
      name: "Status",
      selector: "is_active",
      sortable: true,
      cell: (row) => <div>{row.is_active === "1" ? "Active" : "Inactive"}</div>,
    },
    {
      name: "Actions",
      selector: "bundle_id", //changed
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            <DropdownItem
              onClick={() => {
                props.history.push("/edit-bundle/" + row.bundle_id);
              }}
            >
              Edit
            </DropdownItem>{" "}
            {/* added onclick */}
            <DropdownItem onClick={() => onDelete(row.bundle_id)}>
              Delete
            </DropdownItem>{" "}
            {/* added onClick */}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  // added deleteBundle method
  const onDelete = (bundle_id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        let found = bundles.find((prd) => prd.bundle_id === bundle_id);
        if (found) {
          //if (found && found.parent_category_id !== 0) { //doubt
          props
            .callRequest("DELETE", API_BUNDLE + bundle_id, true)
            .then((res) => {
              // if (res.statusText === "No Content") {
              if (res.status) {
                Swal.fire("Deleted!", "", "success");
                fetchBundles();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          Swal.fire("Category can not be deleted!!", "", "info");
        }
      }
    });
  };

  const export_column = [
    "bundle_name",
    "bundle_description",
    "ordering",
    "is_active",
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Product"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const HandleFilter = (index) => {
    if (index === 1) {
      setBundles(bundleList);
    }

    if (index === 2) {
      let filteredSub = bundleList.filter((sub) => {
        return sub.is_active === "1";
      });
      setBundles(filteredSub);
    }
    if (index === 3) {
      let filteredSub = bundleList.filter((sub) => {
        return sub.is_active === "0";
      });
      setBundles(filteredSub);
    }
  };

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      <Button onClick={() => props.history.push("/add-bundle")}>
        {/* <Button onClick={() => props.history.push("/admin/add-bundle")}> */}
        <i className="ni ni-fat-add"></i> Add Bundles
      </Button>
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={HandleFilter.bind(this, 2)}>
            Active
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 3)}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 1)}>
            Remove Filter
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown disabled={bundles.length > 0 ? false : true}>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExportView(e.target.value)}>
            Export View
          </DropdownItem>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export All
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            bundles,
            export_column,
            props.generateUniqueId()
          )
        }
        onExportView={() =>
          DataTableSettings.downloadCSV(
            bundles.slice(0, rows),
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bundles]
  );
  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Store Front", "Product Bundles"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-bag-17"></i>
            </span>
            Product Bundles
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <DataTable
                    columns={columns}
                    data={DataTableSettings.filterItems(
                      bundles,
                      searchParam,
                      filterText
                    )}
                    pagination
                    paginationPerPage={DataTableSettings.paginationPerPage}
                    paginationRowsPerPageOptions={
                      DataTableSettings.paginationRowsPerPageOptions
                    }
                    onChangeRowsPerPage={changeRowPerPage}
                    actions={actionsExport}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default ProductBundles;

/* import * as React from "react";
import { XGrid } from "@material-ui/x-grid";
import { useDemoData } from "@material-ui/x-grid-data-generator";

export default function Products() {
  const { data } = useDemoData({
    dataSet: "Commodity",
    rowLength: 200,
    maxColumns: 6,
  });

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {
        id: 1,
        columnField: "commodity",
        operatorValue: "contains",
        value: "rice",
      },
      { id: 2, columnField: "quantity", operatorValue: ">=", value: "20000" },
    ],
  });

  return (
    <div style={{ height: 400, width: "100%", marginTop: "100px" }}>
      <XGrid
        {...data}
        filterModel={filterModel}
        onFilterModelChange={(model) => setFilterModel(model)}
      />
    </div>
  );
} */
