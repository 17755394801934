const initialState = null;

const setProfile = (state = initialState, action) => {
  switch (action.type) {
    case "SETPROFILE":
      return action.payload;
    default:
      return state;
  }
};

export default setProfile;
