import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";
import {
  Card,
  CardBody,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";
import { API_GET_AFFILIATE_PAGINATION } from "../../config/Api";

const AffiliateList = (props) => {
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [Affiliate, setAffiliate] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchUri, setSearchUrl] = useState("");
  const [searchInputs, setSerachInputs] = useState({
    email: null,
    url_key: null,
    name: null,
    unbounce_url: null,
    launch_url: null,
    url: null,
  });

  const searchParam = [
    "email",
    "url_key",
    "name",
    "unbounce_url",
    "launch_url",
    "url",
  ];

  useEffect(() => {
    fetchAffiliates(1, 10);
  }, []);

  const fetchAffiliates = (page) => {
    props
      .callRequest(
        "GET",
        `${API_GET_AFFILIATE_PAGINATION}?page_size=${perPage}&page_number=${page}`,
        true,
        null
      )
      .then((res) => {
        setAffiliate(res.data.affiliates);
        setTotalRows(res.data.total_records);
        setLoading(false);
      });
  };

  const handlePageChange = (page) => {
    fetchAffiliates(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    const url_query_param = `${API_GET_AFFILIATE_PAGINATION}?page_size=${newPerPage}&page_number=${page}${searchUri}`;
    props.callRequest("GET", `${url_query_param}`, true).then((res) => {
      setAffiliate(res.data.affiliates);
      setPerPage(newPerPage);
    });
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Url",
      selector: (row) => row.url,
      sortable: true,
    },
    {
      name: "Launch Url",
      selector: (row) => row.launch_url,
      sortable: true,
    },
    {
      name: "Commission Rate",
      selector: (row) => row.commission_rate,
      sortable: true,
    },
    {
      name: "Discount Rate",
      selector: (row) => row.discount,
      sortable: true,
    },
    {
      name: "Page Views",
      selector: (row) => row.page_views,
      sortable: false,
    },
    {
      name: "Logged In Views",
      selector: (row) => row.logged_in_users_view,
      sortable: false,
    },
    {
      name: "Anonymous User",
      selector: (row) => row.anonymous_view,
      sortable: false,
    },
    {
      name: "Success",
      selector: (row) => row.success,
      sortable: false,
    },
    {
      name: "Fail",
      selector: (row) => row.fail,
      sortable: false,
    },
    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            <DropdownItem onClick={() => editAffiliate(row.id)}>
              Edit
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const editAffiliate = (id) => {
    props.history.push("/edit-affiliate/" + id);
  };

  const searchOnChange = (e) => {
    let tempVal = { ...searchInputs, [e.target.name]: e.target.value };
    setSerachInputs({ ...tempVal });
  };

  const searchAction = () => {
    let temUrl = "";
    Object.keys(searchInputs).map((k) => {
      if (searchInputs[k]) {
        temUrl += "&" + k + "=" + searchInputs[k];
      }
    });
    setSearchUrl(temUrl);
    props
      .callRequest(
        "GET",
        `${API_GET_AFFILIATE_PAGINATION}?page_size=${perPage}&page_number=1${temUrl}`,
        true,
        null
      )
      .then((res) => {
        setAffiliate(res.data.affiliates);
        setTotalRows(res.data.total_records);
        setLoading(false);
      });
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <>
        <div
          id="row-0"
          role="row"
          className="sc-jrQzAO OTlLy rdt_TableRowc search_tablerow affiliate-search-list-block"
        >
          <div
            id="cell-zTt6Avwrg-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW affiliate-search-input"
          >
            <label for="email">Email:</label>
            <Input
              id="email"
              name="email"
              placeholder="Search By"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.email}
            />
          </div>
          <div
            id="cell-6BcI2ll_vS-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW affiliate-search-input"
          >
            <label for="url_key">Url Key:</label>
            <Input
              id="url_key"
              name="url_key"
              placeholder="Search By"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.url_key}
            />
          </div>
          <div
            id="cell-9s9_jL4wzy-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW affiliate-search-input"
          >
            <label for="name">Name:</label>
            <Input
              id="name"
              name="name"
              placeholder="Search By"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.name}
            />
          </div>
          <div
            id="cell-N-rKjtRaPW-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW affiliate-search-input"
          >
            <label for="unbounce_url">Unbounce Url:</label>
            <Input
              id="unbounce_url"
              name="unbounce_url"
              placeholder="Search By"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.unbounce_url}
            />
          </div>
          <div
            id="cell-NlvjReDqTn-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW affiliate-search-input"
          >
            <label for="launch_url">Launch Url:</label>
            <Input
              id="launch_url"
              name="launch_url"
              placeholder="Search By"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.launch_url}
            />
          </div>
          <div
            id="cell-QhIJDofkqq-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW affiliate-search-input"
          >
            <label for="url">Url:</label>
            <Input
              id="url"
              name="url"
              placeholder="Search By"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.url}
            />
          </div>
          <div
            id="cell-rbCMEtiO9Wc-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW affiliate-search-input"
          >
            <button
              className="btn btn-sm btn-primary affiliate-search-btn"
              onClick={() => searchAction()}
            >
              Search
            </button>
          </div>
        </div>
      </>
    );
  }, [searchInputs]);

  const Export = ({ onExport }) => (
    <div className="right-button-block">
      <Button onClick={() => props.history.push("/create-affiliate")}>
        <i className="ni ni-fat-add"></i> Add Affiliate
      </Button>
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export />
    ),
    [Affiliate]
  );

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Affiliate", "Affiliate List"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-circle-08"></i>
            </span>
            Affiliate User List
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block attribute-table-setting affiliate-data-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <div className="list-filtered-table-block">
                    <DataTable
                      columns={columns}
                      data={DataTableSettings.filterItems(
                        Affiliate,
                        searchParam,
                        filterText
                      )}
                      pagination
                      paginationPerPage={DataTableSettings.paginationPerPage}
                      paginationRowsPerPageOptions={
                        DataTableSettings.paginationRowsPerPageOptions
                      }
                      actions={actionsExport}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                      progressPending={loading}
                      paginationServer
                      paginationTotalRows={totalRows}
                      onChangePage={handlePageChange}
                      onChangeRowsPerPage={handlePerRowsChange}
                    />
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default AffiliateList;
