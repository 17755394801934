import React, { useState, useEffect } from "react";
import { API_PROFILE } from "../../config/Api";
import Swal from "sweetalert2";
import {
  Card,
  Button,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { setProfile } from "../../store/actions/UserActions";
const SocialInformation = (props) => {
  const dispatch = useDispatch();
  const initSocialInfo = {
    social_facebook: "",
    social_linkedin: "",
    social_youtube: "",
    social_website: "",
  };
  const [userSocialInfo, setUserSocialInfo] = useState(initSocialInfo);

  useEffect(() => {
    setUserSocialInfo(props.userDetails);
  }, [props.userDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserSocialInfo({ ...userSocialInfo, [name]: value });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("social_facebook", userSocialInfo.social_facebook);
    formData.append("social_linkedin", userSocialInfo.social_linkedin);
    formData.append("social_youtube", userSocialInfo.social_youtube);
    formData.append("social_website", userSocialInfo.social_website);

    props
      .callRequest(
        "POST",
        API_PROFILE + props.getUserInfo().user_id + "/",
        true,
        formData
      )
      .then((res) => {
         if (res.status) {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Updated successfully",
        });
        dispatch(setProfile(userSocialInfo));
        }
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Something Went Wrong!",
        });
      });
  };

  return (
    <>
      <div className="information-form-box">
        <Card>
          <CardHeader>
            <CardTitle tag="h2">Social</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="form-box">
              <Form>
                <FormGroup>
                  <Label for="social_facebook">Facebook:</Label>
                  <Input
                    type="url"
                    name="social_facebook"
                    id="social_facebook"
                    placeholder=""
                    value={userSocialInfo.social_facebook}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="social_linkedin">Linkedin:</Label>
                  <Input
                    type="url"
                    name="social_linkedin"
                    id="social_linkedin"
                    placeholder=""
                    value={userSocialInfo.social_linkedin}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="social_youtube">YouTube:</Label>
                  <Input
                    type="url"
                    name="social_youtube"
                    id="social_youtube"
                    placeholder=""
                    value={userSocialInfo.social_youtube}
                    onChange={handleChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="social_website">Website:</Label>
                  <Input
                    type="url"
                    name="social_website"
                    id="social_website"
                    placeholder=""
                    value={userSocialInfo.social_website}
                    onChange={handleChange}
                  />
                </FormGroup>

                <div className="update-button-block">
                  <Button onClick={handleUpdate}>Update</Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default SocialInformation;
