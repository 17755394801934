export const setProfile = (data) => {
  return {
    type: "SETPROFILE",
    payload: data,
  };
};

export const getUserApp = (data) => {
  return {
    type: "GETUSERAPP",
    payload: data,
  };
};

export const getUserRolePermission = (data) => {
  return {
    type: "GET_USER_ROLE_PERMISSION",
    payload: data,
  };
};

export const getUserType = (data) => {
  return {
    type: "GETUSERTYPE",
    payload: data,
  };
};
