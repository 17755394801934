import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import { BreadCrumb } from "../../config/Components";
import { API_INTERVALS } from "../../config/Api";

class CategoryEdit extends Component {
  state = {
    interval_id: this.props.match.params.intervalid,
    interval_name: "",
    errors: {},
  };

  componentDidMount() {
    this.props
      .callRequest("GET", API_INTERVALS + this.state.interval_id, true)
      .then((res) => {
        this.setState({ interval_name: res.data.interval_name });
      });
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    this.validateForm();
  };

  validateForm() {
    let { interval_name } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!interval_name) {
      formIsValid = false;
      errors["interval_name"] = "Please provide interval name";
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateForm()) {
      const formData = new FormData();
      for (var key in this.state) {
        formData.append(key, this.state[key]);
      }

      this.props
        .callRequest(
          "PUT",
          API_INTERVALS + this.state.interval_id + "/",
          true,
          formData
        )
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Updated successfully",
            });
            setTimeout(() => {
              this.props.history.push("/interval-list");
              // this.props.history.push("/admin/interval-list");
            }, 3000);
          }
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Something Went Wrong!",
          });
        });
    }
  };

  render() {
    const { interval_name, errors } = this.state;

    return (
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Interval List", "Edit Interval"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-time-alarm"></i>
            </span>
            Edit Interval
          </h1>
        </div>
        <div className="App">
          <div className="container">
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-sm-8">
                  <div className="card">
                    <div className="card-body">
                      <div className="table-category-form-block">
                        <div className="form-group">
                          <label>
                            <span className="text-danger">*</span> Interval
                            Name:{" "}
                          </label>
                          <input
                            type="text"
                            autoComplete="off"
                            name="interval_name"
                            id="interval_name"
                            className="form-control"
                            value={interval_name}
                            onChange={this.handleChange}
                          />
                          <label className="red_err">
                            {errors.interval_name}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="save-change-button-block">
                    <button className="btn btn-primary button-scale">
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(CategoryEdit);
