import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import jwt_decode from "jwt-decode";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Container,
  Media,
  Modal,
} from "reactstrap";

import {
  API_PROFILE,
  API_GET_USER_PERMISSION,
  API_GET_ADMIN_AFFILIATE_DETAILS,
} from "../../config/Api";
import { useSelector, useDispatch } from "react-redux";
import {
  setProfile,
  getUserApp,
  getUserRolePermission,
  getUserType,
} from "../../store/actions/UserActions";
import ChangePassword from "../Login/ChangePassword";
const AdminNavbar = (props) => {
  const loggedInUser = useSelector((state) => state.setProfile);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    fetchUser();
    fetchUserRolePermission();
    fetchUserType();
  }, []);

  const fetchUser = () => {
    props
      .callRequest("GET", API_PROFILE + props.getUserInfo().user_id, true)
      .then((res) => {
        dispatch(setProfile(res.data.user_profile));
        dispatch(getUserApp(res.data.user_app));
      });
  };

  const fetchUserRolePermission = () => {
    props
      .callRequest(
        "GET",
        API_GET_USER_PERMISSION + props.getUserInfo().user_id,
        true
      )
      .then((res) => {
        dispatch(getUserRolePermission(res.data));
      });
  };
  const fetchUserType = () => {
    props
      .callRequest("GET", API_GET_ADMIN_AFFILIATE_DETAILS, true)
      .then((res) => {
        dispatch(getUserType(res.data.user_type));
      });
  };
  const handleLogout = () => {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
    } catch (e) {
      console.log(e);
    }
  };

  const handleModalClose = () => {
    setModal(false);
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"></Form>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="ni ni-single-02 text-blue"></span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">
                    Welcome{" "}
                    {loggedInUser === null
                      ? "Guest"
                      : loggedInUser.contact_first_name}
                  </h6>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem to={window.location.pathname} tag={Link}>
                  <span onClick={(e) => setModal(true)}>Change Password</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem to="/" onClick={handleLogout} tag={Link}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
      <Modal isOpen={modal} toggle={handleModalClose}>
        <ChangePassword {...props} toggle={handleModalClose} />
      </Modal>
    </>
  );
};

export default AdminNavbar;
