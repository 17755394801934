import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import ResizableLogoUploader from "./ResizableLogoUploader";

let default_style = {
    width: "100%",
    height: "100%",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    padding: "0",
    margin: "0",
    border: "none"
};

const LogoUploaderComponent = (props) => {
    const [top, setTop] = useState(props.top);
    const [left, setLeft] = useState(props.left);
    const [width] = useState(props.width);
    const [height] = useState(props.height);
    const [rotateAngle] = useState(props.rotateAngle);
    const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
    const [roundedCorner, setRoundedCorner] = useState(props.rounded_corner);
    const [margin_top, setMarginTop] = useState(props.margin_top);
    const [margin_left, setMarginLeft] = useState(props.margin_left);
    const [padding_top, setPaddingTop] = useState(props.padding_top);
    const [padding_right, setPaddingRight] = useState(props.padding_right);
    const [padding_bottom, setPaddingBottom] = useState(props.padding_bottom);
    const [padding_left, setPaddingLeft] = useState(props.padding_left);
    const [logo_uploader_lock, setLogoUploaderLock] = useState(props.logo_uploader_lock);

    useEffect(() => {
        setBackgroundColor(props.backgroundColor);
        setRoundedCorner(props.rounded_corner);
        setMarginTop(props.margin_top);
        setMarginLeft(props.margin_left);
        setPaddingTop(props.padding_top);
        setPaddingRight(props.padding_right);
        setPaddingBottom(props.padding_bottom);
        setPaddingLeft(props.padding_left);
        setTop(props.top);
        setLeft(props.left);
        setLogoUploaderLock(props.logo_uploader_lock);
    }, [
        props.backgroundColor,
        props.rounded_corner,
        props.margin_top,
        props.margin_left,
        props.padding_top,
        props.padding_right,
        props.padding_bottom,
        props.padding_left,
        props.top,
        props.left,
        props.logo_uploader_lock,
    ]);

    function setLogoUploaderDesign(
        htmldata,
        id,
        left,
        top,
        width,
        height,
        rotateAngle,
        backgroundColor,
        roundedCorner,
        resize = false,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left
    ) {
        props.parentSetLogoUploaderDesign(
            htmldata,
            id,
            left,
            top,
            width,
            height,
            props.title,
            rotateAngle,
            backgroundColor,
            roundedCorner,
            resize,
            margin_top,
            margin_left,
            padding_top,
            padding_right,
            padding_bottom,
            padding_left
        );
    }

    return (
        <>
            {
                props.title == "{{logo}}" ?
                    <ResizableLogoUploader
                        top={top}
                        left={left}
                        width={width}
                        height={height}
                        rotateAngle={rotateAngle}
                        backgroundColor={backgroundColor}
                        roundedCorner={roundedCorner}
                        margin_top={margin_top}
                        margin_left={margin_left}
                        padding_top={padding_top}
                        padding_right={padding_right}
                        padding_bottom={padding_bottom}
                        padding_left={padding_left}
                        logo_uploader_lock={logo_uploader_lock}
                        parentSetLogoUploaderDesign={setLogoUploaderDesign}
                        id={props.id}
                        title={props.title}
                    ></ResizableLogoUploader>
                    : <ResizableLogoUploader
                        top={top}
                        left={left}
                        width={width}
                        height={height}
                        rotateAngle={rotateAngle}
                        backgroundColor={backgroundColor}
                        roundedCorner={roundedCorner}
                        margin_top={margin_top}
                        margin_left={margin_left}
                        padding_top={padding_top}
                        padding_right={padding_right}
                        padding_bottom={padding_bottom}
                        padding_left={padding_left}
                        logo_uploader_lock={logo_uploader_lock}
                        parentSetLogoUploaderDesign={setLogoUploaderDesign}
                        id={props.id}
                        title={props.title}
                    >
                        <div style={default_style}>
                            <img alt="" src={props.title} style={default_style}></img>
                        </div>
                    </ResizableLogoUploader>
            }
        </>
    );
}

export default LogoUploaderComponent