import axios from "axios";
import { ACCESS_TOKEN } from "../config/Api";

function saveToken(access_token) {
  localStorage.setItem("token", access_token);
}
function destroyToken() {
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
}
export const refresh = (error) => {
  return new Promise((resolve, reject) => {
    axios
      .post(ACCESS_TOKEN, {
        refreshtoken: localStorage.getItem("refresh_token"),
      })
      .then((response) => {
        saveToken(response.data.access_token);
        error.response.config.headers["Authorization"] =
          "Bearer " + response.data.access_token;
        axios(error.response.config).then(function (res) {
          return resolve(res);
        });
      })
      .catch((error) => {
        destroyToken();
        window.location.replace("/");
        return reject(error);
      });
  });
};
