import React from "react";
import { withRouter } from "react-router-dom";
import {
  Table,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  CardTitle,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const MicroCampaign = (props) => {
  return (
    <>
      <div>
        <Card>
          <CardHeader>
            <CardTitle tag="h2">Micro Campaign</CardTitle>
            <Button>
              <i className="bi-pencil-square"></i> Edit Campaign
            </Button>
          </CardHeader>

          <CardBody>
            <Table striped>
              <thead>
                <tr>
                  <th>Mailer</th>
                  <th>Type</th>
                  <th>Product</th>
                  <th>Intervel</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Mailer1</th>
                  <td>Letter</td>
                  <td>AP-1</td>
                  <td>Same Day</td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <i className="fas fa-ellipsis-v"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>Header</DropdownItem>
                        <DropdownItem disabled>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Another Action</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
                <tr>
                  <th>Mailer1</th>
                  <td>Letter</td>
                  <td>AP-1</td>
                  <td>Same Day</td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <i className="fas fa-ellipsis-v"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>Header</DropdownItem>
                        <DropdownItem disabled>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Another Action</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
                <tr>
                  <th>Mailer1</th>
                  <td>Letter</td>
                  <td>AP-1</td>
                  <td>Same Day</td>
                  <td>
                    <UncontrolledDropdown>
                      <DropdownToggle>
                        <i className="fas fa-ellipsis-v"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>Header</DropdownItem>
                        <DropdownItem disabled>Action</DropdownItem>
                        <DropdownItem>Another Action</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Another Action</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </td>
                </tr>
              </tbody>
            </Table>
          </CardBody>
          <CardFooter className="add-drop-button-block">
            <Button onClick={() => props.history.push("/mailer-options")}>
              {/* <Button onClick={()=>props.history.push('/admin/mailer-options')}> */}
              <i className="ni ni-fat-add"></i> Add Drop
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default withRouter(MicroCampaign);
