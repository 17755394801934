import React, { useState, useEffect, useMemo } from "react";
import { BreadCrumb } from "../../config/Components";
import classnames from "classnames";
import "./importTemplate.css";
import {
  PropertyInformation,
  PrimaryContact,
  SecondaryContact,
} from "../../config/Components";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import PrimaryData from "./PrimaryData";
import {
  API_MAP_CSV,
  API_GET_BLANK_FIELD,
  API_GET_DUPLICATE,
  API_DUPLICATE_CHECK_BY,
  API_START_VDP,
} from "./../../config/Api";
import Swal from "sweetalert2";

const ImportTemplate = (props) => {
  const [activeTab, setActiveTab] = useState("Property Information");
  const [csvData, setCsvData] = useState(props.csvData);
  const [primaryDataState, setPrimaryDataState] = useState([...PrimaryData]);
  const [matchInProcess, setMatchInProcess] = useState(false);
  const [csvValidationSection, setCsvValidationSection] = useState({
    full_name: false,
    // first_name: false,
    // last_name: false,
    // company: false,
    mailing_street: false,
    property_address: false,
    property_city: false,

    //property_county: false,
    property_street: false,
    property_state: false,
    property_zip: false,
    property_city: false,
    // property_country: true,
    // offer_amount: false,
    // marketplace_value: false,
    // offer_percentage: false,
    // offer_code: false,
    // response_date: false,

    mailing_city: false,
    mailing_state: false,
    mailing_Zip: false,
    mailing_address: false,
    mailing_address2: false,
  });
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const dragStart = (e, id) => {
    e.dataTransfer.setData("item_value", id);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };
  const onDropEvent = (e) => {
    try {
      let id = e.dataTransfer.getData("item_value");
      let dataItem = e.target.getAttribute("data-item");
      let dataName = e.target.getAttribute("data-name");
      let localPrimaryData = [...primaryDataState];
      let tempcsvData =
        "selected_header" in csvData ? csvData["selected_header"] : [];

      localPrimaryData.map((el, i) => {
        if (el.nav_title == dataItem) {
          el.nav_data.map((elm, ind) => {
            if (elm.item_name == dataName) {
              localPrimaryData[i]["nav_data"][ind]["item_value"] = id;
              tempcsvData.push(id);
            }
          });
        }
      });

      setCsvData({ ...csvData, selected_header: tempcsvData });
      setPrimaryDataState([...localPrimaryData]);
    } catch (e) {
      console.log(e);
    }
  };

  const showLoadingProcess = () => {
    Swal.fire({
      title: "Processing ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const showError = () => {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
  };
  const getSelectedHeader = (item) => {
    return "selected_header" in csvData &&
      csvData.selected_header.length > 0 &&
      csvData.selected_header.includes(item)
      ? true
      : false;
  };

  const removeItem = (nav_title, item_name) => {
    let tempcsvData1 = [...csvData["selected_header"]];
    let localPrimaryData1 = Object.assign([], [...primaryDataState]);

    localPrimaryData1.map((el, i) => {
      if (el.nav_title == nav_title) {
        el.nav_data.map((elm, ind) => {
          if (elm.item_name == item_name) {
            let index = tempcsvData1.indexOf(
              localPrimaryData1[i]["nav_data"][ind]["item_value"]
            );
            localPrimaryData1[i]["nav_data"][ind]["item_value"] = null;
            tempcsvData1.splice(index, 1);
          }
        });
      }
    });

    setCsvData({ ...csvData, selected_header: tempcsvData1 });
    setPrimaryDataState([...localPrimaryData1]);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const matchFielAction = () => {
    if (matchInProcess) return false;
    let tempcsvValidationSection = { ...csvValidationSection };
    const formData = new FormData();
    //  formData.append("mailinglist_id", csvData.mailinglist_id);
    let validationStatus = true;
    let notMappedField = "";
    primaryDataState.map((item) => {
      item.nav_data.map((inItem) => {
        if (
          inItem.item_value &&
          csvData["headers"].indexOf(inItem.item_value) < 0
        ) {
          notMappedField += "<li>" + inItem.item_value + "</li>";
        }

        if (
          inItem.api_key in tempcsvValidationSection &&
          inItem.item_value == null
        ) {
          tempcsvValidationSection[inItem.api_key] = true;
        } else if (
          inItem.api_key in tempcsvValidationSection &&
          inItem.item_value != null
        ) {
          tempcsvValidationSection[inItem.api_key] = false;
        }

        formData.append(
          inItem.api_key,
          inItem.item_value == null ? "" : inItem.item_value
        );
      });
    });

    setCsvValidationSection({ ...tempcsvValidationSection });
    validationStatus =
      Object.values(tempcsvValidationSection).filter(
        (item) => item == true
      )[0] == true
        ? false
        : true;

    if (notMappedField != "") {
      Swal.fire({
        title: "VDP Maping Error !!",
        icon: "error",
        html:
          "<div style='text-align:left'><p>Please remove all the below invalid header and mapped with the correct one from right side of the Drag and Drop section.</p>" +
          "<ul style='margin-top:10px; margin-left:20px;'>" +
          notMappedField +
          "</ul></div>",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
      });
      return false;
    }

    if (validationStatus == false) {
      let tmpError = "<ul style='margin-top:5px'>";
      Object.keys(tempcsvValidationSection).map((it) => {
        tmpError +=
          "<li>" + capitalizeFirstLetter(it.replace("_", " ")) + "</li>";
      });
      tmpError += "</ul>";

      setActiveTab("Property Information");
      Swal.close();
      // Swal.fire("Validation", "Please fill all the required value", "error");
      Swal.fire({
        title: "Validation Error !!",
        icon: "error",
        html:
          "<div style='text-align:left'><p>Please fill all the below required field from 'Property Information' and 'Primary Contact' .</p>" +
          tmpError +
          "</div>",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
      });
      return false;
    }

    Swal.fire({
      title: "CSV Mapping is processing, Please wait....",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    // const formData = new FormData();
    formData.append("csv_id", csvData.csv_id);

    // primaryDataState.map((item) => {
    //   item.nav_data.map((inItem) => {
    //     formData.append(
    //       inItem.api_key,
    //       inItem.item_value == null ? "" : inItem.item_value
    //     );
    //   });
    // });

    props
      .callRequest("POST", `${API_MAP_CSV}`, true, formData)
      .then((res) => {
        setMatchInProcess(true);
        getBlankField();
        //Swal.close();
      })
      .catch((error) => {
        Swal.close();
      });
  };

  const getBlankField = () => {
    Swal.fire({
      title: "Processing your request, Please wait....",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    var letInterval = setInterval(function () {
      props
        .callRequest("GET", `${API_GET_BLANK_FIELD}${csvData.csv_id}/`, true)
        .then((res) => {
          clearInterval(letInterval);
          if (res.status === 200) {
            Swal.close();
          }
          if (res.data.length > 0) {
            showRemaningfieldNotification(res.data);
          } else {
            showDupliicateCheckNotification();
          }
        })
        .catch((error) => {});
    }, 3000);
  };

  const showRemaningfieldNotification = (data) => {
    let ulList = '<ul style="margin-left:33px;">';
    data.map((item) => {
      ulList += "<li>" + item + "</li>";
    });
    ulList += "</ul>";
    Swal.fire({
      title: "<strong>Notification</strong>",
      html:
        '<div style="text-align:left;"><p>Your CSV file is missing data for the following fields: </p>' +
        ulList +
        "<p>Please be adviseed if there is missing data for the mailing address  or property addresses this will  be reflected in your mailing pices. If you are OKay with this click proceed. Otherwise, you will need to choose a new list with all data compleed for those fields</p></div>",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Agree and Proceed",
      cancelButtonText: `Back`,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        showDupliicateCheckNotification();
      } else {
        Swal.close();
      }
    });
  };

  const showDupliicateCheckNotification = () => {
    Swal.fire({
      title: "<strong>Notification</strong>",
      html: "<p><b>Please choose duplicate check option.</b></p>",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Check Property Addresses",
      cancelButtonText: `Check Mailing Addresses`,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        getDuplicate(1);
      } else {
        getDuplicate(0);
      }
    });
  };

  const getDuplicate = (duplicate_check_by) => {
    showLoadingProcess();
    props
      .callRequest(
        "GET",
        `${API_GET_DUPLICATE}?csv_id=${csvData.csv_id}&duplicate_check_by=${duplicate_check_by}`,
        true
      )
      .then((res) => {
        Swal.close();
        showDupliicateNotification(res.data, duplicate_check_by);
      })
      .catch((error) => {
        Swal.close();
        showError();
      });
  };

  const showDupliicateNotification = (data, duplicate_check_by) => {
    let addr = duplicate_check_by == 0 ? "mailing" : "property";
    Swal.fire({
      title: "<strong>Notification</strong>",
      html:
        '<div style="text-align:left"><p><b>Your list has duplicate ' +
        addr +
        "addresses</b></p>" +
        '<ul style="margin-left:33px;">' +
        "<li><b> Original Records: </b>" +
        data.total_records +
        "</li>" +
        "<li><b> Duplicate Records: </b>" +
        data.duplicate_records +
        "</li>" +
        "<li><b> Unique Records: </b>" +
        data.unique_records +
        "</li>" +
        "</ul>" +
        "<p>Would you like to remove theme?</p></div>",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "No",
      cancelButtonText: `Yes`,
      allowEscapeKey: false,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        startVdp(0, duplicate_check_by);
      } else {
        startVdp(1, duplicate_check_by);
      }
    });
  };

  const startVdp = (is_removed, duplicate_check_by) => {
    showLoadingProcess();
    let formData = new FormData();
    formData.append("csv_id", csvData.csv_id);
    formData.append("template_id", props.template_id);
    formData.append("marge_process_type", props.marge_process_type);
    formData.append("duplicate_check_by", duplicate_check_by);
    formData.append("is_removed", is_removed);
    if (props.user !== "") {
      formData.append("user_id", props.user);
    }
    props
      .callRequest("POST", `${API_START_VDP}`, true, formData)
      .then((res) => {
        Swal.close();
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "VDP process has been completed successfully",
        });
        setTimeout(function () {
          window.location.reload();
        }, 800);
        props.resetAction();
        //props.fetchMergeList();
      })
      .catch((error) => {
        Swal.close();
        showError();
      });
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="import-template-main-block">
          <div className="crm-contacts-mainblock">
            <Card>
              <CardBody>
                <div className="crm-contacts_block">
                  <div className="crm-tabcontacts_block  property-information-box">
                    <div className="left-crm-contacts-block">
                      <Nav tabs>
                        {primaryDataState.map((item) => {
                          return (
                            <NavItem>
                              <NavLink
                                className={classnames({
                                  active: activeTab === item.nav_title,
                                })}
                                onClick={() => {
                                  toggle(item.nav_title);
                                }}
                              >
                                {item.nav_title}
                              </NavLink>
                            </NavItem>
                          );
                        })}
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        {primaryDataState.map((item) => {
                          return (
                            <>
                              <TabPane tabId={item.nav_title}>
                                <div className="crm-contacts-list">
                                  {item.nav_data.map((innerItem) => {
                                    return (
                                      <div className="contacts-list-item">
                                        <label className="item-name">
                                          {innerItem.item_name}:
                                        </label>
                                        <div
                                          className={`drag-drop-place-box droppable ${
                                            innerItem.item_value != null
                                              ? "active"
                                              : ""
                                          }`}
                                          onDragOver={(e) => dragOver(e)}
                                          onDrop={(e) => onDropEvent(e)}
                                        >
                                          <div
                                            className={`drag-drop-item ${
                                              innerItem.api_key in
                                                csvValidationSection &&
                                              csvValidationSection[
                                                innerItem.api_key
                                              ] == true
                                                ? "validation-error"
                                                : ""
                                            }`}
                                            data-item={item.nav_title}
                                            data-name={innerItem.item_name}
                                            style={{ zIndex: "99999" }}
                                          >
                                            {innerItem.item_value
                                              ? innerItem.item_value
                                              : innerItem.api_key in
                                                  csvValidationSection &&
                                                csvValidationSection[
                                                  innerItem.api_key
                                                ] == true
                                              ? "Required Field"
                                              : "Empty"}
                                          </div>
                                        </div>
                                        {innerItem.item_value != null ? (
                                          <a
                                            href="#"
                                            className="close-button"
                                            onClick={() =>
                                              removeItem(
                                                item.nav_title,
                                                innerItem.item_name
                                              )
                                            }
                                          >
                                            <i className="ni ni-fat-remove"></i>
                                          </a>
                                        ) : (
                                          <a href="#" className="close-button">
                                            <i className="ni ni-fat-remove"></i>
                                          </a>
                                        )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </TabPane>
                            </>
                          );
                        })}
                      </TabContent>
                    </div>

                    <div className="drag-and-drop-block">
                      <h2 className="titel">Drag and Drop</h2>
                      <p className="short-text">
                        Drag any unmatched CSV fields to their corresponding
                        destination fields. Once they have been matched they
                        will not be able to be used again.
                      </p>
                      <div className="drag-drop-all-items">
                        <ul className="drag-drop-item-list">
                          {csvData &&
                            "headers" in csvData &&
                            csvData.headers.map((item) => {
                              return (
                                <li key={Math.random()}>
                                  <div
                                    onDragStart={(e) => dragStart(e, item)}
                                    draggable={
                                      true /*!getSelectedHeader(item)*/
                                    }
                                    className={`draggable drag-drop-item handle`}
                                  >
                                    <span>{item}</span>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="contacts-button-block">
            <div className="button-box">
              <Button color="primary" disabled={true}>
                Upload CSV
              </Button>
              <Button
                color="secondary"
                disabled={matchInProcess}
                onClick={() => matchFielAction()}
              >
                Match Fields
              </Button>
              <Button color="primary" disabled={!matchInProcess}>
                Preview Import
              </Button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ImportTemplate;
