import React, { useState, useEffect } from "react";
import "../Users/UserForm.css";
import Swal from "sweetalert2";
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import {
  API_GET_SALES_REP,
  API_COMMISSIONS_OVERRIDE,
  API_GET_ORDER_DETAIL,
} from "../../config/Api";
import { withRouter } from "react-router-dom";

const CommissionOverrideForm = (props) => {
  const intialInfo = {
    order_id: props.orderDetails.OrderDetails.order_id,
    sales_rep_1: props.orderDetails.OrderDetails.sales_rep_1,
    sales_rep_1_commission_type:
      props.orderDetails.OrderDetails.sales_rep_1_commission_type,
    sales_rep_1_commission_percentage:
      props.orderDetails.OrderDetails.sales_rep_1_commission_percentage,
    sales_rep_1_commission_flat_rate:
      props.orderDetails.OrderDetails.sales_rep_1_commission_flat_rate,
    sales_rep_2: props.orderDetails.OrderDetails.sales_rep_2,
    sales_rep_2_commission_type:
      props.orderDetails.OrderDetails.sales_rep_2_commission_type,
    sales_rep_2_commission_percentage:
      props.orderDetails.OrderDetails.sales_rep_2_commission_percentage,
    sales_rep_2_commission_flat_rate:
      props.orderDetails.OrderDetails.sales_rep_2_commission_flat_rate,
  };

  const [commissionData, setCommissionData] = useState(intialInfo);
  const [salesRep, setSalesRep] = useState([]);

  useEffect(() => {
    // getOrderDetails();
    fetchSalesRep();
  }, [props]);

  // const getOrderDetails = (id) => {
  //   if (id) {
  //     props
  //       .callRequest("GET", API_GET_ORDER_DETAIL + id + "/", true)
  //       .then((res) => {
  //         setOrderDetails(res.data);
  //       });
  //   }
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommissionData({ ...commissionData, [name]: value });
  };

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  // console.log(commissionData.sales_rep_1, "commissionData.sales_rep_1");
  // console.log(commissionData.sales_rep_2, "commissionData.sales_rep_2");
  const handleSubmit = (event) => {
    event.preventDefault();
    let post_data = {
      ...commissionData,
      sales_rep_1:
        commissionData.sales_rep_1 === "" ||
        commissionData.sales_rep_1 === "None" ||
        commissionData.sales_rep_1 === "Select Direct Marketing Coach 1"
          ? null
          : commissionData.sales_rep_1,
      sales_rep_2:
        commissionData.sales_rep_2 === "" ||
        commissionData.sales_rep_2 === "None" ||
        commissionData.sales_rep_1 === "Select Direct Marketing Coach 2"
          ? null
          : commissionData.sales_rep_2,
    };

    // console.log(post_data, "post_data");

    props
      .callRequest("POST", API_COMMISSIONS_OVERRIDE, true, post_data)
      .then((res) => {
        if (res.status) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          props.getOrderDetails(commissionData.order_id);
          Toast.fire({
            icon: "success",
            title: "Updated successfully",
          });
          setTimeout(
            function () {
              props.modalToggle();
              // sendUpdatedUser(user);
            }.bind(),
            200
          );
        }
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        console.log(error);

        Toast.fire({
          icon: "error",
          title: "Something Went Wrong!",
        });
      });
  };

  const handleClose = () => {
    props.modalToggle();
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <h1 className="header-title">Commissions Override</h1>
        </div>

        <div className="create-user-formblock">
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="sales_rep_1">Direct Marketing Coach 1</Label>
                  <select
                    id="sales_rep_1"
                    name="sales_rep_1"
                    value={commissionData.sales_rep_1}
                    onChange={handleChange}
                    className="form-control"
                    aria-label="Default select example"
                  >
                    <option value="">Select Direct Marketing Coach 1</option>

                    {salesRep.map((rep, index) => {
                      return (
                        <option key={rep.user_id} value={rep.user_id}>
                          {rep.name}
                        </option>
                      );
                    })}
                  </select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="sales_rep_2">Direct Marketing Coach 2:</Label>
                  <select
                    id="sales_rep_2"
                    name="sales_rep_2"
                    value={commissionData.sales_rep_2}
                    onChange={handleChange}
                    className="form-control"
                    aria-label="Default select example"
                  >
                    <option value="">Select Direct Marketing Coach 2</option>

                    {salesRep.map((rep, index) => {
                      return <option value={rep.user_id}>{rep.name}</option>;
                    })}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <label>Set Sales Rep 1 Commission</label>
                  <div className="set-sales-formbox">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_1_commission_type"
                          id="sales_rep_1_commission_type"
                          value="1"
                          checked={
                            commissionData.sales_rep_1_commission_type === "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Percentage
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_1_commission_percentage"
                        id="sales_rep_1_commission_percentage"
                        placeholder="Enter %"
                        value={commissionData.sales_rep_1_commission_percentage}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_1_commission_type"
                          id="sales_rep_1_commission_type1"
                          value="0"
                          checked={
                            commissionData.sales_rep_1_commission_type !== "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Flat Rate
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_1_commission_flat_rate"
                        id="sales_rep_1_commission_flat_rate"
                        placeholder="1-100"
                        value={commissionData.sales_rep_1_commission_flat_rate}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <label>Set Sales Rep 2 Commission</label>
                  <div className="set-sales-formbox">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_2_commission_type"
                          id="sales_rep_2_commission_type"
                          value="1"
                          checked={
                            commissionData.sales_rep_2_commission_type === "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Percentage
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_2_commission_percentage"
                        id="sales_rep_2_commission_percentage"
                        placeholder="Enter %"
                        value={commissionData.sales_rep_2_commission_percentage}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_2_commission_type"
                          id="sales_rep_2_commission_type2"
                          value="0"
                          checked={
                            commissionData.sales_rep_2_commission_type !== "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Flat Rate
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_2_commission_flat_rate"
                        id="sales_rep_2_commission_flat_rate"
                        placeholder="1-100"
                        value={commissionData.sales_rep_2_commission_flat_rate}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <div className="submit-button-block">
              <Button className="cancel-button" onClick={handleClose}>
                Back
              </Button>
              <Button
                color="Secondary"
                className="create-User-button"
                onClick={handleSubmit}
              >
                Confirm
              </Button>
            </div>
          </Form>
        </div>
      </main>
    </>
  );
};

export default withRouter(CommissionOverrideForm);
