import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { AttributeTypeForm, BreadCrumb } from "../../config/Components";
import { API_ATTRIBUTE_TYPE } from "../../config/Api";

const AttributeTypeList = (props) => {
  const { className } = props;
  const [modal, setModal] = useState(false);
  const [attributeTypeId, setAttributeTypeId] = useState(0);
  const toggle = () => {
    setModal(!modal);
    setAttributeTypeId(0);
  };
  const [attributeTypes, setAttributeTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const searchParam = ["type_name"];

  useEffect(() => {
    fetchAttributeType();
  }, []);

  const fetchAttributeType = async () => {
    props.callRequest("GET", API_ATTRIBUTE_TYPE, true).then((res) => {
      setAttributeTypes(res.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      name: "Type Name",
      selector: "type_name",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "type_id",
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            <DropdownItem onClick={() => editAttributeType(row.type_id)}>
              Edit
            </DropdownItem>
            {/* <DropdownItem onClick={() => onDelete(row.type_id)}>
              Delete
            </DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const editAttributeType = (type_id) => {
    toggle();
    setAttributeTypeId(type_id);
  };

  const onDelete = (type_id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("DELETE", API_ATTRIBUTE_TYPE + type_id, true)
          .then((res) => {
            // if (res.statusText === "No Content") {
            if (res.status) {
              Swal.fire("Deleted!", "", "success");
              fetchAttributeType();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = ({ onExport }) => (
    <div className="right-button-block">
      <Button onClick={toggle}>
        <i className="ni ni-fat-add"></i> Add Type
      </Button>
      {/* <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>Filter Name 1</DropdownItem>
          <DropdownItem>Filter Name 2</DropdownItem>
          <DropdownItem>Filter Name 3</DropdownItem>
          <DropdownItem>Filter Name 4</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Button>
        <i className="bi-pencil-square"></i> Edit Columns
      </Button> */}
      {/* <Button
        disabled={attributeTypes.length > 0 ? false : true}
        onClick={(e) => onExport(e.target.value)}
      >
        <i className="ni ni-ungroup"></i> Export
      </Button> */}
    </div>
  );

  const export_column = ["type_name"];

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            attributeTypes,
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [attributeTypes]
  );

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Attribute Type", "Attribute Type List"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-bag-17"></i>
            </span>
            Attribute Type List
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <Modal isOpen={modal} toggle={toggle} className={className}>
                {attributeTypeId === 0 ? (
                  <ModalHeader toggle={toggle}>Add Attribute Type</ModalHeader>
                ) : (
                  <ModalHeader toggle={toggle}>Edit Attribute Type</ModalHeader>
                )}
                <ModalBody>
                  <AttributeTypeForm
                    {...props}
                    modalToggle={toggle}
                    fetchAttributeType={fetchAttributeType}
                    attributeTypeId={attributeTypeId}
                  />
                </ModalBody>
              </Modal>

              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <DataTable
                    columns={columns}
                    data={DataTableSettings.filterItems(
                      attributeTypes,
                      searchParam,
                      filterText
                    )}
                    pagination
                    paginationPerPage={DataTableSettings.paginationPerPage}
                    paginationRowsPerPageOptions={
                      DataTableSettings.paginationRowsPerPageOptions
                    }
                    actions={actionsExport}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default AttributeTypeList;
