import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  Row,
  Col,
} from "reactstrap";
import DataTable from "react-data-table-component";

import { BreadCrumb } from "../../config/Components";
import {
  API_GET_CART_LIST,
  API_GET_ADMIN_AFFILIATE_DETAILS,
} from "../../config/Api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTableSettings from "../../utils/DataTableSettings";

import { CartListDetails } from "../../config/Components";

import "../../assets/css/data-table.css";
import moment from "moment"

const CartList = (props) => {
  const [products, setProducts] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [modal, setModal] = useState(false);
  const [userType, SetUserType] = useState("");
  const [rows, setRows] = useState(10);
  const [open, setOpen] = useState(false);

  const [loading, setLoading] = useState(true);
  const [loadingIndicator, setLoadingIndicator] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterSelection, setfilterSelection] = useState({
    type: "Select Campaign Type",
    start_date: "",
    end_date: "",
  });
  const [page, setPerPage] = useState(1);
  const [newPerPage, setNewPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  const searchParam = [
    "cartId",
    "campaign_name",
    "campaign_type",
    "firstname",
    "lastname",
    "cart_status",
    "date_added",
  ];
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchProducts(1, 10);
    fetchUserType();
  }, []);

  const fetchUserType = () => {
    props
      .callRequest("GET", API_GET_ADMIN_AFFILIATE_DETAILS, true)
      .then((res) => {
        SetUserType(res.data.user_type);
      });
  };
  const handleCalendlyPopUp = (user) => {
    setOpen(true);
  };
  const closeCalendlyHandler = (user) => {
    setOpen(false);
  };

  const getUrlQueryParam = (newPerPage, page) => {
    let url_query_param =
      API_GET_CART_LIST + "?page_number=" + page + "&page_size=" + newPerPage;
    const campaign_name = filterText && `&campaign_name=${filterText}`;
    const user_name = filterText && `&username=${filterText}`;
    const type =
      filterSelection.type !== "Select Campaign Type" &&
      `&campaign_type=${filterSelection.type}`;

    let temp_start_date = dateformatter(filterSelection.start_date);

    let temp_end_date = dateformatter(filterSelection.end_date);

    const start_date =
      filterSelection.start_date !== "" && temp_start_date !== null
        ? `&start_date=${temp_start_date}`
        : "";

    const end_date =
      filterSelection.end_date !== "" && temp_end_date !== null
        ? `&end_date=${temp_end_date}`
        : "";

    setLoadingIndicator(true);

    if (start_date && !end_date) {
      url_query_param += start_date;
      setLoadingIndicator(true);
    } else if (!start_date && end_date) {
      url_query_param += end_date;
      setLoadingIndicator(true);
    } else if (start_date && end_date) {
      url_query_param += start_date + end_date;
      setLoadingIndicator(true);
    } else if (!start_date && !end_date) {
      setLoadingIndicator(true);
    }

    return url_query_param;
  };

  const fetchProducts = (page) => {
    const url_query_param = getUrlQueryParam(newPerPage, page);
    props.callRequest("GET", `${url_query_param}`, true).then((res) => {
      let tempData = [];
      res.data.orders &&
        res.data.orders.map((item) => {
          item.status == "1" &&
            tempData.push({
              ...item,
              firstname:
                item && item.user && item.user.firstname
                  ? item.user.firstname
                  : "",
              lastname:
                item && item.user && item.user.lastname
                  ? item.user.lastname
                  : "",
              campaign_name:
                item && item.campaign && item.campaign.campaign_name
                  ? item.campaign.campaign_name
                  : "",
              campaign_type:
                item && item.campaign && item.campaign.campaign_type == "0"
                  ? "Direct Mail"
                  : item && item.campaign && item.campaign.campaign_type == "1"
                  ? "Probate"
                  : "Micro-Campaigns",
              cart_status: item.status == "1" ? "Active" : "Inactive",
            });
        });
      setProducts(tempData);
      setCartList(tempData);
      setTotalRows(res.data.total_records);
      setLoading(false);
      setLoadingIndicator(false);
    });
  };

  useEffect(() => {
    filteredcartList();
  }, [filterSelection, filterText]);

  const filteredcartList = () => {
    const url_query_param = getUrlQueryParam(newPerPage, page);
    props.callRequest("GET", `${url_query_param}`, true).then((res) => {
      let tempData = [];
      res.data.orders &&
        res.data.orders.map((item) => {
          item.status == "1" &&
            tempData.push({
              ...item,
              firstname:
                item && item.user && item.user.firstname
                  ? item.user.firstname
                  : "",
              lastname:
                item && item.user && item.user.lastname
                  ? item.user.lastname
                  : "",
              campaign_name:
                item && item.campaign && item.campaign.campaign_name
                  ? item.campaign.campaign_name
                  : "",
              campaign_type:
                item && item.campaign && item.campaign.campaign_type == "0"
                  ? "Direct Mail"
                  : item && item.campaign && item.campaign.campaign_type == "1"
                  ? "Probate"
                  : "Micro-Campaigns",
              cart_status: item.status == "1" ? "Active" : "Inactive",
            });
        });
      setProducts(tempData);
      setCartList(tempData);
      setTotalRows(res.data.total_records);
      setLoading(false);
      setLoadingIndicator(false);
    });
  };

  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };

  const columns = [
    {
      name: "First Name",
      selector: "firstname",
      sortable: true,
    },
    {
      name: "Last Name",
      selector: "lastname",
      sortable: true,
    },
    {
      name: "Campaign Name",
      selector: "campaign_name",
      sortable: true,
    },
    {
      name: "Campaign Type",
      selector: "campaign_type",
      sortable: true,
    },

    {
      name: "Status",
      selector: "cart_status",
      sortable: true,
    },
    {
      name: "Created Date",
      selector: "date_added",
      sortable: true,
      cell: (row) => {
        return dateFormater(row.date_added);
      },
    },
    {
      name: userType && userType == 2 ? null : "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) =>
        userType && userType == 2 ? null : (
          <>
            <span>
              <i
                className="fas fa-edit"
                title="Edit Cart"
                onClick={() => redirectPage(row.cart_id)}
              ></i>
            </span>{" "}
            &nbsp;
            {/* {row.is_calendly == 1 && (
              <span>
                <i
                  className="fas fa-flag"
                  title="calendly flag"
                  onClick={() => handleCalendlyPopUp(row.user)}
                ></i>
              </span>
            )} */}
            <Modal isOpen={open} toggle={closeCalendlyHandler}>
              <div className="accutxt-info-modal">
                <h2>Calendly Information</h2>
                <span style={{ cursor: "pointer" }}>
                  <i
                    onClick={closeCalendlyHandler}
                    className="fas fa-times-circle"
                  ></i>
                </span>
              </div>
              <ModalBody>
                {row.user.firstname} {row.user.lastname} has booked for free
                consultation.
              </ModalBody>
            </Modal>
          </>
        ),
    },
  ];

  const export_column = [
    "firstname",
    "lastname",
    "campaign_name",
    "campaign_type",
    "cart_status",
  ];

  const redirectPage = (cart_id) => {
    props.history.push("/edit-cart/" + cart_id);
  };

  const cartListFilter = (e) => {
    let value = e.target.value;
    let name = e.target.name;
    setfilterSelection({ ...filterSelection, [name]: value });
  };

  const searchTeamplate = (e) => {
    setFilterText(e.target.value);
  };

  const formattedDate = (inputValue, type) => {
    if (type === "start_date") {
      setfilterSelection({ ...filterSelection, start_date: inputValue });
    }
    if (type === "end_date") {
      setfilterSelection({ ...filterSelection, end_date: inputValue });
    }
  };

  const dateformatter = (inputValue) => {
    if (inputValue === null) {
      return null;
    } else {
      let d = new Date(inputValue);
      let dateSelected =
        ("0" + (d.getMonth() + 1)).slice(-2) +
        "/" +
        ("0" + d.getDate()).slice(-2) +
        "/" +
        d.getFullYear();
      return dateSelected;
    }
  };

  const subHeaderComponentMemo = (
    <Row>
      <Col sm={4}>
        <FormGroup>
          <Input
            id="search"
            type="text"
            placeholder="Search"
            aria-label="Search Input"
            value={filterText}
            onChange={(e) => searchTeamplate(e)}
            autoComplete="off"
          />
        </FormGroup>
      </Col>

      {/* <Col sm={6}>
        <FormGroup>
          <Input
            type="select"
            name="type"
            id="type"
            onChange={(e) => cartListFilter(e)}
          >
            <option value="Select Campaign Type">Select Campaign Type</option>
            <option value="0">Direct Mail</option>
            <option value="1">Probate</option>
            <option value="2">Micro-Campaigns</option>
          </Input>
        </FormGroup>
      </Col> */}

      <Col sm={4}>
        <FormGroup>
          {/* <label>Start Date:</label> */}
          <DatePicker
            dateFormat="MM/dd/yyyy"
            placeholderText="Start Date"
            selected={filterSelection.start_date}
            name="start_date"
            id="start_date"
            onChange={(date) => {
              formattedDate(date, "start_date");
            }}
            autoComplete="off"
            isClearable
          />
        </FormGroup>
      </Col>
      <Col sm={4}>
        <FormGroup>
          <DatePicker
            dateFormat="MM/dd/yyyy"
            placeholderText="End Date"
            selected={filterSelection.end_date}
            name="end_date"
            id="end_date"
            onChange={(date) => {
              formattedDate(date, "end_date");
            }}
            autoComplete="off"
            isClearable
          />
        </FormGroup>
      </Col>
    </Row>
  );

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      <UncontrolledDropdown disabled={products.length > 0 ? false : true}>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExportView(e.target.value)}>
            Export View
          </DropdownItem>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export All
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );

  const actionsExport = (
    <Export
      onExport={() =>
        DataTableSettings.downloadCSV(
          products,
          export_column,
          props.generateUniqueId()
        )
      }
      onExportView={() =>
        DataTableSettings.downloadCSV(
          products.slice(0, rows),
          export_column,
          props.generateUniqueId()
        )
      }
    />
  );

  const onRowClicked = (b, row) => {
    console.log(b, row);
  };

  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return moment(d).format('MM/DD/YYYY')
    //return d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear();
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order History"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            Cart List
          </h1>
        </div>

        <div className="listingtable-main-block orderhistory-tabile-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                <DataTable
                  className="table-min-height"
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    cartList,
                    searchParam,
                    filterText
                  )}
                  pagination
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  actions={actionsExport}
                  onChangeRowsPerPage={changeRowPerPage}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  expandableRows
                  expandableRowsComponent={
                    <CartListDetails {...props} sectionType={"cart"} />
                  }
                  onRowExpandToggled={onRowClicked}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default CartList;
