import React from 'react';
import "./assets/css/TemplateDesign.css";
import {
    MdFormatShapes,
} from 'react-icons/md';

const LeftLineShapeFields = (props) => {
    const onAdd = (event) => {
        props.addLineShape();
        event.preventDefault();
    };

    return (
        <div className="template-fill-menu-item">
            <button
                className="menu-item-btn"
                value="Add Line"
                onClick={onAdd}
            >
                <div className='menu-item'>
                    <span className='icon'><MdFormatShapes /></span>
                    <span className='name'>Add Shape</span>
                </div>
            </button>
        </div>
    );
};

export default LeftLineShapeFields;