import React, { useState } from "react";
import "./assets/css/TemplateDesign.css";
import {
    BsRulers,
} from 'react-icons/bs';
const RulerComponent = (props) => {
    const [ruler, setRuler] = useState(props.ruler);

    const toggleRuler = () => {
        setRuler(!ruler);
        props.toggleRuler(!ruler);
    };

    return (
        <div className="template-fill-menu-item">
            <button
                className="menu-item-btn"
                onClick={toggleRuler}
            >
                <div className='menu-item'>
                    <span className='icon'><BsRulers /></span>
                    <span className='name'>{!ruler ? "Show Ruler" : "Hide Ruler"}</span>
                </div>
            </button>
        </div>
    )
};

export default RulerComponent;