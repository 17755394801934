import React, { useState } from "react";
import "./assets/css/TemplateDesign.css";
import ContextMenuAccupix from "../ContextMenu/ContextMenuAccupix";

const NonEditableAccupixComponentLeft = (props) => {
  const [accupix_type_front, setAccupixTypeFront] = useState(
    props.accupix_type_front
  );
  const [accupix_aspect_ratio_front, setAccupixAspectRatioFront] = useState(
    props.accupix_aspect_ratio_front
  );
  const [accupix_type_front_two, setAccupixTypeFrontTwo] = useState(
    props.accupix_type_front_two
  );
  const [accupix_aspect_ratio_front_two, setAccupixAspectRatioFrontTwo] =
    useState(props.accupix_aspect_ratio_front_two);

  const [accupix_type_back, setAccupixTypeBack] = useState(
    props.accupix_type_back
  );
  const [accupix_aspect_ratio_back, setAccupixAspectRatioBack] = useState(
    props.accupix_aspect_ratio_back
  );
  const [accupix_type_back_two, setAccupixTypeBackTwo] = useState(
    props.accupix_type_back_two
  );
  const [accupix_aspect_ratio_back_two, setAccupixAspectRatioBackTwo] =
    useState(props.accupix_aspect_ratio_back_two);

  const [lockSatellite, setLockSatellite] = useState(props.accupix_satellite_lock);
  const [lockStreetview, setLockStreetview] = useState(props.accupix_streetview_lock);

  const handleChangeFront = (type) => {
    if (type == 1) {
      setAccupixTypeFront(type);
      props.parentGetAccupixTypeFront(type);
      props.parentGetAccupixStreetviewAspectRatioFront("3:2", true);
      props.parentAddNonEditableAccupixOnchange("satellite", props.id);
    } else {
      setAccupixTypeFrontTwo(type);
      props.parentGetAccupixStreetviewTypeFront(type);
      props.parentGetAccupixAspectRatioFront("3:2", true);
      props.parentAddNonEditableAccupixOnchange("streetview", props.id);
    }
  };

  const handleChangeAspectRatioFront = (ratio, accupixType) => {
    if (accupixType == "satellite") {
      setAccupixAspectRatioFront(ratio);
      props.parentGetAccupixAspectRatioFront(ratio, true);
      props.parentHandleAspectRatioState(true);
    } else {
      setAccupixAspectRatioFrontTwo(ratio);
      props.parentGetAccupixStreetviewAspectRatioFront(ratio, true);
      props.parentHandleAspectRatioState(true);
    }
  };

  const handleChangeAspectRatioBack = (ratio, accupixType) => {
    if (accupixType == "satellite") {
      setAccupixAspectRatioBack(ratio);
      props.parentGetAccupixAspectRatioBack(ratio, true);
      props.parentHandleAspectRatioState(true);
    } else {
      setAccupixAspectRatioBackTwo(ratio);
      props.parentGetAccupixStreetviewAspectRatioBack(ratio, true);
      props.parentHandleAspectRatioState(true);
    }
  };

  const updateNonEditableAccupixzIndex = (z_index, accupixType, id) => {
    if (accupixType == "satellite") {
      props.parentUpdateNonEditableAccupixZIndex(z_index, props.id);
    }
  };

  const updateNonEditableAccupixStreetviewzIndex = (
    z_index,
    accupixType,
    id
  ) => {
    props.parentUpdateNonEditableAccupixStreetviewZIndex(z_index, props.id);
  };

  const handleAccupixSatelliteLockUnlock = (e) => {
    setLockSatellite(!lockSatellite);
    props.handleAccupixSatelliteLockUnlock(props.id, !lockSatellite, "accupix_satellite_lock");
  };

  const handleAccupixStreetviewLockUnlock = (e) => {
    setLockStreetview(!lockStreetview);
    props.handleAccupixStreetviewLockUnlock(props.id, !lockStreetview, "accupix_streetview_lock");
  };

  return (
    <>
      <div className="custom_text_full">
        <div className={"img_box_" + props.id + " image_upload_box"}>
          <div className="image_box_area">
            <ContextMenuAccupix
              {...props}
              nonEditableAccupixs={props.nonEditableAccupixs}
              nonEditableAccupixStreetview={props.nonEditableAccupixStreetview}
              accupix_id={props.id}
              title={props.title}
              accupixType={
                props.title.includes("accupix") ? "satellite" : "streetview"
              }
              parentUpdateNonEditableAccupixzIndex={
                updateNonEditableAccupixzIndex
              }
              parentUpdateNonEditableStreetviewAccupixzIndex={
                updateNonEditableAccupixStreetviewzIndex
              }
            />
            <div className="each_image">
              {props.title.includes("accupix") ? (
                <img
                  alt="accupix.png"
                  src={props.title}
                  title="accupix"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "acsolute",
                  }}
                />
              ) : (
                <img
                  alt="streetview.png"
                  src={props.title}
                  title="accupix"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "acsolute",
                  }}
                />
              )}
            </div>
            {props.title.includes("accupix") ? (
              <>
                <button
                  style={{
                    padding: "1px 8px",
                    margin: "2px",
                    fontSize: "16px",
                    background: "transparent",
                    position: "absolute",
                    zIndex: "99",
                    bottom: "5px",
                    left: "8px"
                  }}
                  value={lockSatellite}
                  onClick={(e) => handleAccupixSatelliteLockUnlock(e)}
                >
                  {lockSatellite ? (
                    <i style={{ color: "#5394f5" }} className="fas fa-lock"></i>
                  ) : (
                    <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
                  )}
                </button>
                <button
                  className="btn-image-delete"
                  onClick={() =>
                    props.ParentNonEditableDeleteCustomAccupix(
                      props.id,
                      "non_editable_accupix"
                    )
                  }
                >
                  X
                </button>
              </>
            ) : (
              <>
                <button
                  style={{
                    padding: "1px 8px",
                    margin: "2px",
                    fontSize: "16px",
                    background: "transparent",
                    position: "absolute",
                    zIndex: "99",
                    bottom: "5px",
                    left: "8px"
                  }}
                  value={lockStreetview}
                  onClick={(e) => handleAccupixStreetviewLockUnlock(e)}
                >
                  {lockStreetview ? (
                    <i style={{ color: "#5394f5" }} className="fas fa-lock"></i>
                  ) : (
                    <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
                  )}
                </button>
                <button
                  className="btn-image-delete"
                  onClick={() =>
                    props.parentNonEditableDeleteStreetviewAccupix(
                      props.nonEditableAccupixStreetview[0].id,
                      "non_editable_accupix_streetview"
                    )
                  }
                >
                  X
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="accupix-box">
        <select
          id="accupix_type_front"
          name="accupix_type_front"
          value={props.title.includes("accupix") ? "1" : "2"}
          onChange={(e) => handleChangeFront(e.target.value)}
          className="form-control"
          aria-label="Default select example"
          disabled={
            (props.nonEditableAccupixs.length > 0 &&
              props.nonEditableAccupixStreetview.length > 0) || (lockSatellite || lockStreetview)
              ? true
              : false
          }
        >
          <option value="1">Satellite</option>
          <option value="2">Street View</option>
        </select>
      </div>
      {props.title.includes("accupix") ? (
        <div className="accupix-box">
          <select
            id="accupix_aspect_ratio_front"
            name={
              props.imageType === "front"
                ? "accupix_aspect_ratio_front"
                : "accupix_aspect_ratio_back"
            }
            value={
              props.imageType === "front"
                ? accupix_aspect_ratio_front
                : accupix_aspect_ratio_back
            }
            onChange={(e) =>
              props.imageType === "front"
                ? handleChangeAspectRatioFront(e.target.value, "satellite")
                : handleChangeAspectRatioBack(e.target.value, "satellite")
            }
            className="form-control"
            aria-label="Default select example"
            disabled={lockSatellite ? true : false}
          >
            <option value="1:1">1:1</option>
            <option value="3:2">3:2</option>
            <option value="3:1">3:1</option>
            <option value="4:1">4:1</option>
          </select>
        </div>
      ) : (
        <div className="accupix-box">
          <select
            id="accupix_aspect_ratio_front"
            name={
              props.imageType === "front"
                ? "accupix_aspect_ratio_front_two"
                : "accupix_aspect_ratio_back_two"
            }
            value={
              props.imageType === "front"
                ? accupix_aspect_ratio_front_two
                : accupix_aspect_ratio_back_two
            }
            onChange={(e) =>
              props.imageType === "front"
                ? handleChangeAspectRatioFront(e.target.value, "streetview")
                : handleChangeAspectRatioBack(e.target.value, "streetview")
            }
            className="form-control"
            aria-label="Default select example"
            disabled={lockStreetview ? true : false}
          >
            <option value="1:1">1:1</option>
            <option value="3:2">3:2</option>
            <option value="3:1">3:1</option>
            <option value="4:1">4:1</option>
          </select>
        </div>
      )}
    </>
  );
};

export default NonEditableAccupixComponentLeft;
