import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";
import { API_COUPONS } from "../../config/Api";

const Coupons = (props) => {
  const [coupons, setCoupons] = useState([]);
  const [couponsList, setCouponsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [rows, setRows] = useState(10);
  const searchParam = [
    "coupon_code",
    "discount",
    "DiscountType",
    "min_qty",
    "CouponType",
    "StartDate",
    "ExpiryDate",
  ];

  useEffect(() => {
    fetchCoupons();
  }, []);

  const fetchCoupons = async () => {
    props.callRequest("GET", API_COUPONS, true).then((res) => {
      let allRecords = [];
      res.data.map((item) => {
        item.DiscountType =
          item.discount_type === "1"
            ? "Printing & Postage"
            : item.discount_type === "2"
            ? "Flat Rate"
            : item.discount_type === "3"
            ? "Price Override"
            : "Printing Only";
        item.CouponType = item.coupon_type === "1" ? "Repeat" : "One Time Only";
        item.StartDate = dateFormater(item.start_date);
        item.ExpiryDate = dateFormater(item.expiration_date);

        allRecords.push(item);
      });
      setCouponsList(res.data);
      setCoupons(res.data);
      setLoading(false);
    });
  };
  const HandleFilter = (index) => {
    // console.log(index);
    if (index === 1) {
      setCoupons(couponsList);
    }

    if (index === 2) {
      let filteredSub = couponsList.filter((sub) => {
        return sub.is_active === "1";
      });
      setCoupons(filteredSub);
    }
    if (index === 3) {
      let filteredSub = couponsList.filter((sub) => {
        return sub.is_active === "0";
      });
      setCoupons(filteredSub);
    }
  };

  const newDateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return (
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2) +
      "/" +
      d.getFullYear()
    );
  };

  const columns = [
    {
      name: "Coupon Code",
      selector: "coupon_code",
      sortable: true,
    },
    {
      name: "Discount (%)",
      selector: "discount",
      sortable: true,
    },
    {
      name: "Minimum Quantity",
      selector: "min_qty",
      sortable: true,
    },
    {
      name: "Discount Type",
      selector: "discount_type",
      sortable: true,
      cell: (row) => (
        <div>
          {row.discount_type === "1"
            ? "Printing & Postage"
            : row.discount_type === "2"
            ? "Flat Rate"
            : row.discount_type === "3"
            ? "Price Override"
            : "Printing Only"}
        </div>
      ),
    },
    {
      name: "Coupon Type",
      selector: "coupon_type",
      sortable: true,
      cell: (row) => (
        <div>{row.coupon_type === "1" ? "Repeat" : "One Time Only"}</div>
      ),
    },
    {
      name: "Start Date",
      selector: "start_date",
      sortable: true,
      cell: (row) => newDateFormater(row.start_date),
    },
    {
      name: "End Date",
      selector: "expiration_date",
      sortable: true,
      cell: (row) => newDateFormater(row.expiration_date),
    },
    {
      name: "Status",
      selector: "is_active",
      sortable: true,
      cell: (row) => (row.is_active == 1 ? "Active" : "Inactive"),
    }, //is_active
    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            <DropdownItem onClick={() => editCoupon(row.coupon_id)}>
              Edit
            </DropdownItem>
            <DropdownItem onClick={() => onDelete(row.coupon_id)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const getFormattedDate = (timestamp) => {
    let date = new Date(timestamp * 1000);
    return (
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      "-" +
      date.getFullYear()
    );
  };
  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear();
  };

  const editCoupon = (coupon_id) => {
    props.history.push("/edit-coupon/" + coupon_id);
    // props.history.push("/admin/edit-coupon/" + coupon_id);
  };

  const onDelete = (coupon_id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("DELETE", API_COUPONS + coupon_id, true)
          .then((res) => {
            // if (res.statusText === "No Content") {
            if (res.status) {
              Swal.fire("Deleted!", "", "success");
              fetchCoupons();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const export_column = [
    "coupon_code",
    "discount",
    "DiscountType",
    "min_qty",
    "CouponType",
    "StartDate",
    "ExpiryDate",
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Product"
          value={filterText}
          autoComplete="off"
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      {/* <Button>Totals</Button>
      <Button>Orders</Button> */}

      <Button onClick={() => props.history.push("/add-coupon")}>
        {/* <Button onClick={() => props.history.push("/admin/add-coupon")}> */}
        <i className="ni ni-fat-add"></i> Add Coupon
      </Button>
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={HandleFilter.bind(this, 2)}>
            Active
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 3)}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 1)}>
            Remove Filter
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown disabled={coupons.length > 0 ? false : true}>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExportView(e.target.value)}>
            Export View
          </DropdownItem>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export All
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );

  const actionsExport = (
    <Export
      onExport={() =>
        DataTableSettings.downloadCSV(
          coupons,
          export_column,
          props.generateUniqueId()
        )
      }
      onExportView={() =>
        DataTableSettings.downloadCSV(
          coupons.slice(0, rows),
          export_column,
          props.generateUniqueId()
        )
      }
    />
  );
  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };
  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Store Front", "Coupons"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-bag-17"></i>
            </span>
            Coupons
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <div className="list-filtered-table-block">
                    <DataTable
                      columns={columns}
                      data={DataTableSettings.filterItems(
                        coupons,
                        searchParam,
                        filterText
                      )}
                      pagination
                      paginationPerPage={DataTableSettings.paginationPerPage}
                      paginationRowsPerPageOptions={
                        DataTableSettings.paginationRowsPerPageOptions
                      }
                      onChangeRowsPerPage={changeRowPerPage}
                      actions={actionsExport}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                    />
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default Coupons;
