import React, { useEffect, useRef, useState } from "react";
import Guides from "@scena/react-guides";

const RulerHorizontal = (props) => {
    const rulerCanvasStyle = {
        width: props.is_bleed === "1" ? `100%` : `calc(100% - 12px)`,
        height: `30px`,
        position: `absolute`,
        top: "-30px",
        left: props.is_bleed === "1" ? "0px" : "12px",
        zIndex: `9999`,
        borderTop: `1px solid #727272`
    };
    const horizontal = useRef(null);
    const [horizontal_line, setHorizontalLine] = useState(props.horizontal_line);

    useEffect(() => {
        horizontal.current.resize();
    });

    const setHorizontalGuide = (guides) => {
        setHorizontalLine(guides);
        props.setHorizontalGuide(guides);
    };

    return (
        <Guides
            ref={e => {
                horizontal.current = e;
            }}
            type="horizontal"
            style={rulerCanvasStyle}
            direction="start"
            backgroundColor="transparent"
            textColor="#000000"
            lineColor="#595959"
            displayDragPos
            zoom={96}
            unit={1}
            segment={8}
            defaultGuides={horizontal_line}
            onChangeGuides={({ guides }) => {
                setHorizontalGuide(guides);
            }}
        />
    );
}

export default RulerHorizontal;