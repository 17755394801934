import { Breadcrumb, BreadcrumbItem } from "reactstrap";

const BreadCrumb = (props) => {
  return (
    <div className="breadcrumb-block">
      <Breadcrumb>
        {props.links.map((link, index) => {
          return index === props.links.length - 1 ? (
            <BreadcrumbItem active key={index}>
              {link}
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem key={index}>{link}</BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </div>
  );
};

export default BreadCrumb;
