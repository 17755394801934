import React, { useState } from "react";
import { BreadCrumb } from "../../config/Components";
import classnames from "classnames";
import "../../assets/css/importTemplate.css";
import {
  PropertyInformation,
  PrimaryContact,
  SecondaryContact,
} from "../../config/Components";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";

const ListPreview = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [notificationModal, setNotificationModal] = useState(false);
  const [notificationModal1, setNotificationModal1] = useState(false);
  const [notificationModal2, setNotificationModal2] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleNext = () => {
    props.history.push("/order-details");
    // props.history.push('/admin/order-details');
  };

  const toggleNotification = () => setNotificationModal(!notificationModal);
  const toggleNotification1 = () => setNotificationModal1(!notificationModal1);
  const toggleNotification2 = () => setNotificationModal2(!notificationModal2);

  const handleNext1 = () => {
    toggleNotification();
    toggleNotification1();
  };

  const handleNext2 = () => {
    toggleNotification1();
    toggleNotification2();
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order History"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-books"></i>
            </span>
            List Preview
          </h1>
        </div>
        <div className="import-template-main-block">
          <div className="crm-contacts-mainblock">
            <Card>
              <CardBody>sssss</CardBody>
            </Card>
          </div>

          <Modal
            isOpen={notificationModal}
            toggle={toggleNotification}
            className="notification-modal"
          >
            <ModalHeader toggle={toggleNotification}>Notification</ModalHeader>
            <ModalBody>
              <div className="notification-popup-block">
                <h4 className="sub-titel">
                  Your CSV file is missing data for the following fieldss
                </h4>
                <ul className="missing-list">
                  <li>Mailing Address Line 1 or Mailing</li>
                  <li>Address Line 2</li>
                  <li>Mailing City</li>
                  <li>Mailing State</li>
                  <li>Mailing Zip</li>
                  <li>Property City</li>
                  <li>Property Zip</li>
                  <li>Offer Code</li>
                </ul>
                <p className="info-text">
                  Please be advised if there is missing data for the mailing
                  address or property addresses this will be reflected on your
                  mail pieces. If you are oky with this click proceed.
                  Otherwise, you will need to choose a new list with all data
                  completed for those fields.
                </p>
              </div>
              <div className="modal-button-block">
                <Button color="secondary" onClick={toggleNotification}>
                  Back
                </Button>
                <Button color="primary" onClick={handleNext1}>
                  Agree and Proceed <i className="ni ni-bold-right"></i>
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={notificationModal1}
            toggle={toggleNotification1}
            className="notification-modal"
          >
            <ModalHeader toggle={toggleNotification1}>Notification</ModalHeader>
            <ModalBody>
              <div className="notification-popup-block">
                <h4 className="sub-titel">
                  Please choose duplicate check option.
                </h4>
              </div>
              <div className="modal-button-block">
                <Button color="primary" onClick={handleNext2}>
                  Check Property Addresses <i className="ni ni-bold-right"></i>
                </Button>
                <Button color="primary" onClick={handleNext2}>
                  Check Property Addresses <i className="ni ni-bold-right"></i>
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            isOpen={notificationModal2}
            toggle={toggleNotification2}
            className="notification-modal"
          >
            <ModalHeader toggle={toggleNotification2}>Notification</ModalHeader>
            <ModalBody>
              <div className="notification-popup-block">
                <h4 className="sub-titel">
                  Your list has duplicate mailing addresses.
                </h4>
                <ul className="list">
                  <li>
                    <b>Original Records:</b> 4500
                  </li>
                  <li>
                    <b>Duplicate Records:</b> 3
                  </li>
                  <li>
                    <b>Unique Records:</b> 4497
                  </li>
                </ul>
                <p className="sub-titel">Would you like to remove them?</p>
              </div>
              <div className="modal-button-block">
                <Button color="primary" onClick={handleNext}>
                  Yes{" "}
                </Button>
                <Button color="secondary" onClick={handleNext}>
                  No
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <div className="contacts-button-block">
            <div className="button-box">
              <Button color="primary">Upload CSV</Button>
              <Button color="secondary">Match Fields</Button>
              <Button color="primary" onClick={toggleNotification}>
                Preview Import
              </Button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ListPreview;
