export const API_HOST = process.env.REACT_APP_API;

export const ACCESS_TOKEN = API_HOST + "api/get_access_token";
export const API_LOGIN = API_HOST + "api/login";
export const API_TEMPLATES = API_HOST + "api/templates/";
export const API_MASTER_TEMPLATES =
  API_HOST + "api/template_listing/?page_number=1&page_size=2000&is_master=1";
export const API_HTML_TO_IMAGE = API_HOST + "api/htmltoimage/";
export const API_VDP = API_HOST + "api/vdp/";
export const API_UPLOAD_CSV = API_HOST + "api/map_upload_csv/";
export const API_PARENT_CATEGORY = API_HOST + "api/parent_category/";
export const API_CATEGORY = API_HOST + "api/category/";
export const API_GET_CATEGORY = API_HOST + "api/get_category/";
export const API_INTERVALS = API_HOST + "api/intervals/";
export const API_PROFILE = API_HOST + "api/profile/";
export const API_PROFILE_PICTURE = API_HOST + "api/profile_picture/";
export const API_PRODUCTS = API_HOST + "api/products/";
export const API_GET_PRODUCT = API_HOST + "api/get_product/";
export const API_TEMPLATE_SIZE = API_HOST + "api/templateSize/";
export const API_ATTRIBUTE_TYPE = API_HOST + "api/attributeType/";
export const API_ATTRIBUTES = API_HOST + "api/attribute/";
export const API_ATTRIBUTE_SEARCH = API_HOST + "api/attributeSearch/";
export const API_USERS = API_HOST + "api/users/";
export const API_GET_USER = API_HOST + "api/get_user/";
export const API_USER_GROUPS = API_HOST + "api/groups/";
export const API_GET_USER_GROUP = API_HOST + "api/get_group/";
export const API_GET_ROLES = API_HOST + "api/get_roles/";
export const API_GET_USER_PERMISSION = API_HOST + "api/get_user_permission/";
export const API_PAYMENT_SETTINGS = API_HOST + "api/payment_settings/";
export const API_PRODUCT_FULL_IMAGE = API_HOST + "api/product_full_image/";
export const API_PRODUCT_THUMB_IMAGE = API_HOST + "api/product_thumb_image/";
export const API_COUPONS = API_HOST + "api/coupons/";
export const API_GET_COUPON = API_HOST + "api/get_coupon/";
export const API_GET_AFFILIATE = API_HOST + "api/affiliate/";
export const API_GET_AFFILIATE_PAGINATION = API_HOST + "api/affiliate_pagination/";
export const API_GET_WALLET_BALANCE = API_HOST + "api/user_wallet_balance/";
export const API_GET_ALL_SAVED_CARD = API_HOST + "api/user_card_list/";
export const API_GET_AUTO_PAYMENT = API_HOST + "api/get_auto_payment/";
export const API_SAVE_AUTO_PAYMENT = API_HOST + "api/save_auto_payment/";
export const API_GET_PAYMENT_HISTORY = API_HOST + "api/wallet_tran/";
export const API_GET_PAY_HISTORY =
  API_HOST + "api/wallet_trans_admin_or_affiliate/";
export const API_ADD_BALANE_PAYPAL = API_HOST + "api/add_balance_paypal/";
export const API_PAYMENT_ACCOUNT_STORAGE_FORM =
  API_HOST + "api/payment_account_storage_form/";
export const API_ADD_CREDIT_CARD = API_HOST + "api/add_credit_card/";
// Bundle APIs
export const API_BUNDLE = API_HOST + "api/productBundle/"; //retrieves bundle list
export const API_GET_BUNDLE = API_HOST + "api/productBundleSearch/"; //retrieves single bundle
export const API_PRODUCT_BUNDLE = API_HOST + "api/productBundleCreate/"; //creates new bundle
export const API_PRODUCT_BUNDLE_UPDATE = API_HOST + "api/productBundleUpdate/"; //edits product bundle
export const API_GET_PRODUCT_BY_CAT = API_HOST + "api/category_products/"; //retrieves products by category
//VDP
export const API_MAP_CSV = API_HOST + "api/map_csv/";
export const API_GET_BLANK_FIELD = API_HOST + "api/get_blank_fields/";
export const API_GET_DUPLICATE = API_HOST + "api/get_duplicate/";
export const API_DUPLICATE_CHECK_BY = API_HOST + "api/duplicate_check_by/";
export const API_START_VDP = API_HOST + "api/start_vdp/";
export const API_VDP_LISTING = API_HOST + "api/get_vdp_list/?page_number=";
export const API_DELETE_VDP = API_HOST + "api/delete_vdp_list/";

export const API_GET_STATES = API_HOST + "api/leadfuzion_state/";
export const API_GET_COUNTY = API_HOST + "api/leadfuzion_county?state=";
export const API_SUBSCRIPTION = API_HOST + "api/subscription_from_admin/";
export const API_SUBSCRIPTION_PRICE =
  API_HOST + "api/leadfuzion_catalog_lookup?lead_type=";
export const API_MICRO_CAMPAIGN_PRICE = API_HOST + "api/micro_campaign_lookup/";
export const API_SEARCH_USER = API_HOST + "api/user_search/";
export const API_POST_CAMPAIGN = API_HOST + "api/campaign/";
export const API_MAP_CAMPAIGN = API_HOST + "api/map_campaign/";
export const API_GET_CAMPAIGN_BLANK_FIELD =
  API_HOST + "api/get_campaign_blank_fields/";
export const API_GET_CAMPAIGN_CONTRACTS =
  API_HOST + "api/get_campaign_contacts/";
export const API_CAMPAIGN_DUPLICATE_REMOVE =
  API_HOST + "api/campaign_duplicate_remove/";
export const API_GET_CAMPAIGN_DUPLICATE_RECORD =
  API_HOST + "api/get_campaign_duplicate/";
export const API_POST_CAMPAIGN_CONTACT_REMOVE =
  API_HOST + "api/campaign_contact_remove/";
export const API_GET_CAMPAIGN = API_HOST + "api/get_campaigns/";
export const API_UPLOAD_CAMPAIGN_CSV = API_HOST + "api/upload_campaign_csv/";
export const API_GET_CONTACTS = API_HOST + "api/get_contacts/";
export const API_CAMPAIGN_STATUS_UPDATE =
  API_HOST + "api/campaign_status_update/";
export const API_GET_CHILD_CATEGORY =
  API_HOST + "api/get_child_category_by_parent/";
export const API_DUPLICATE_CAMPAIGN = API_HOST + "api/duplicate_campaign/";
export const API_GET_CONTACT_DETAILS_BY_MAP_ID =
  API_HOST + "api/get_contact_details/";

export const API_GET_CAMPAIGN_TEMPLATE = API_HOST + "api/get_templates/";
export const API_GET_MAP_META = API_HOST + "api/get_map_meta/";
export const API_UPDATE_CAMPAIGN = API_HOST + "api/campaign_update/";

//MailingList
export const API_GET_MAILINGLIST = API_HOST + "api/get_mailinglist/";
export const API_POST_MAILINGLIST = API_HOST + "api/mailinglist/";
export const API_MAP_MAILINGLIST = API_HOST + "api/map_mailinglist/";
export const API_GET_MAILINGLIST_BLANK_FIELD =
  API_HOST + "api/get_mailinglist_blank_fields/";
export const API_GET_MAILINGLIST_CONTRACTS =
  API_HOST + "api/get_mailinglist_contacts/";
export const API_MAILINGLIST_DUPLICATE_REMOVE =
  API_HOST + "api/mailinglist_duplicate_remove/";
export const API_GET_MAILINGLIST_DUPLICATE_RECORD =
  API_HOST + "api/get_mailinglist_duplicate/";
export const API_POST_MAILINGLIST_CONTACT_REMOVE =
  API_HOST + "api/mailinglist_contact_remove/";
export const API_MAILINGLIST_STATUS_UPDATE =
  API_HOST + "api/mailinglist_status_update/";
export const API_GET_MAILING_DETAILS_BY_MAP_ID =
  API_HOST + "api/get_mailinglist_details/";
export const API_GET_MAILINGLIST_TEMPLATE =
  API_HOST + "api/get_mailinglist_templates/";
export const API_GET_MAILINGLIST_MAP_META =
  API_HOST + "api/get_mailinglist_map_meta/";
export const API_GET_MAILINGLIST_BY_USER =
  API_HOST + "api/get_mailinglist_by_user/";

//Order
export const API_GET_FILTERED_PROUCTS = API_HOST + "api/get_filtered_products/";
export const API_GET_FILTERED_NEW_PRODUCTS =
  API_HOST + "api/get_filtered_new_products/";
export const API_GET_SIZE_WISE_ALL_PRODUCTS =
  API_HOST + "api/get_size_wise_all_products/";
export const API_GET_CART_LIST = API_HOST + "api/cartlist/";
export const API_ADD_TO_CART = API_HOST + "api/addtocart/";
export const API_UPDATE_CART = API_HOST + "api/updatecart/";
export const API_CART_DETAILS = API_HOST + "api/cartdetails/";
export const API_ORDER_DETAILS = API_HOST + "api/orderlist/";
export const API_PAY_AS_YOU_GO_ORDERS =
  API_HOST + "api/pay_as_you_go_orderlist/";
export const API_GET_ORDER_DETAIL = API_HOST + "api/orderdetails/";
export const API_ADD_TO_ORDER = API_HOST + "api/addtoorder/";
export const API_UPDATE_ORDER = API_HOST + "api/updateorder/";
export const API_CREATE_CONFIRM_ORDER = API_HOST + "api/createconfirmorder/";
export const API_UPDATE_CONFIRM_ORDER = API_HOST + "api/updateconfirmorder/";
export const API_DOWNLOAD_ZIP_ORDER =
  API_HOST + "api/order_all_files_zip_download/";
export const API_UPDATE_ORDER_ACCUTXT =
  API_HOST + "api/update_order_mailer_gcpower_mail/";

export const API_GET_USER_TEMPLATE_PRODUCT =
  API_HOST + "api/get_user_template_product/";
export const API_CHILD_CATEGORY = API_HOST + "api/child_category/";
export const API_GET_PROOF = API_HOST + "api/get_edit_order_proof/";
export const API_GET_ORDER_CONFIRM_PROOF =
  API_HOST + "api/get_order_confirm_proof/";
export const API_BATCH_CREATE = API_HOST + "api/batch_create/";
export const API_GET_BATCH_PREVIEW = API_HOST + "api/get_batch_preview/";
export const API_GET_BATCH_CSV_UPLOAD =
  API_HOST + "api/batch_preview_csv_import/";
export const API_GET_BATCH_PREVIEW_DETAILS =
  API_HOST + "api/get_batch_preview_details/";
export const API_DELETE_DROP_PREVIEW_BATCH =
  API_HOST + "api/delete_drop_preview_batch/";
export const API_DELETE_PREVIEW_BATCH = API_HOST + "api/delete_preview_batch/";
export const API_CREATE_BATCH_PRODUCTION =
  API_HOST + "api/create_batch_production/";
export const API_GET_PRODUCTION_BATCH = API_HOST + "api/get_production_batch/";
export const API_GET_PRODUCTION_BATCH_DETAILS =
  API_HOST + "api/get_production_batch_details/";
export const API_COMPLETE_BATCH = API_HOST + "api/complete_batch/";
export const API_COMPLETE_BATCH_LISTING =
  API_HOST + "api/complete_batch_listing/";
export const API_COMPLETE_BATCH_DETAILS =
  API_HOST + "api/complete_batch_details/";
export const API_CANCEL_BATCH = API_HOST + "api/cancel_batch/";
export const API_CANCEL_BATCH_LISTING = API_HOST + "api/cancel_batch_listing/";

//admin forgot pwd
export const API_FORGOT_PASSWORD = API_HOST + "api/admin_forget_password/";
export const API_UPDATE_USER_PASSWORD = API_HOST + "api/user_update_password/";
export const API_GET_ADMIN_AFFILIATE_DETAILS =
  API_HOST + "api/admin_loggedin_get_user/";

// cms
export const API_GET_CMS = API_HOST + "api/cms/";
export const API_GET_SECTION_BY_CMS = API_HOST + "api/cms_sections_detail/";
export const API_GET_SECTION = API_HOST + "api/cms_sections/";
export const API_GET_CMS_PATTERN = API_HOST + "api/cms_pattern/";

export const API_USER_LOGO_UPLOAD = API_HOST + "api/profile_logo/";
export const API_GET_USER_LOGO_UPLOAD = API_HOST + "api/profile_logo_get/";
export const API_APPLY_DISCOUNT = API_HOST + "api/apply_discount/";
export const API_CANCEL_ORDER = API_HOST + "api/cancel_order/";
export const API_CANCEL_MAILER = API_HOST + "api/cancel_mailer/";
export const API_ORDER_HOLD_OR_RESUME = API_HOST + "api/order_hold_or_resume/";
export const API_DROP_HOLD_OR_RESUME = API_HOST + "api/drop_hold_or_resume/";
export const API_ORDER_EDIT_EXPORT_CSV =
  API_HOST + "api/order_edit_export_csv/";
export const API_ORDER_EDIT_IMPORT_CSV =
  API_HOST + "api/order_edit_import_csv/";

export const API_MANUALLY_UPDATE_WALLET =
  API_HOST + "api/manually_change_user_wallet_balance/";
export const API_UPDATE_IS_PROOF_APPROVED =
  API_HOST + "api/update_is_proof_approved/";
export const API_UPDATE_ORDER_TEMPLATE =
  API_HOST + "api/update_order_template/";
export const API_COPY_CAMPAIGN_DATA_TO_MAILING_DATES_RECORD =
  API_HOST + "api/copy_campaign_data_to_mailing_dates_record/";

//Mailer wise mapping
export const API_ORDER_MAILER = API_HOST + "api/order_mailer/";

export const API_MAP_ORDER_MAILER = API_HOST + "api/map_order_mailer/";
export const API_GET_ORDER_MAILER_MAP_META =
  API_HOST + "api/get_order_mailer_map_meta/";
export const API_GET_ORDER_MAILER_BLANK_FIELDS_CHECK =
  API_HOST + "api/get_order_mailer_blank_fields_check/";

export const API_GET_ORDER_MAILER_DUPLICATE =
  API_HOST + "api/get_order_mailer_duplicate/";
export const API_ORDER_MAILER_DUPLICATE_REMOVE =
  API_HOST + "api/order_mailer_duplicate_remove/";
export const API_ORDER_MAILER_CONTACT_REMOVE =
  API_HOST + "api/order_mailer_contact_remove/";
export const API_ORDER_MAILER_CONTACT_DETAILS =
  API_HOST + "api/get_order_mailer_contact_details/";
export const API_COPY_ORDER_MAILER_DATA_TO_MAILING_DATE_RECORD =
  API_HOST + "api/copy_order_mailer_data_to_mailing_dates_record/";

export const GET_ORDER_MAILER_CONTACTS =
  API_HOST + "api/get_order_mailer_contacts/";

//template listing pagination
export const API_TEMPLATE_LIST = API_HOST + "api/template_listing/";
export const UPDATE_DESIRED_MAILING_DATE =
  API_HOST + "api/update_desired_mailing_date/";
export const API_CHANGE_MAILER_PAYMENT_STATUS =
  API_HOST + "api/change_mailer_payment_status/";

export const API_CHANGE_NORMAL_ORDER_PAYMENT_STATUS =
  API_HOST + "api/change_normal_order_payment_status/";

//inactive user
export const API_INACTIVE_A_USER = API_HOST + "api/user_status_update/";
export const API_GET_SALES_REP = API_HOST + "api/get_direct_marketing_coach/";
export const API_GET_ATTRIBUTE_TYPE_LIST = API_HOST + "api/attributeType/";
export const API_CART_ORDER_MULTIPLE_UPLOAD_FILES =
  API_HOST + "api/cart_order_multiple_upload_files/";
export const DELETE_CART_ORDER_UPLOAD_FILE =
  API_HOST + "api/delete_cart_order_upload_file/";

export const API_COMMISSIONS_OVERRIDE = API_HOST + "api/commissions_override/";

export const API_GET_HOLIDAY_LIST = API_HOST + "api/get_holiday_list/";
export const API_POST_DROP = API_HOST + "api/post_drop/";
export const API_DROP_HISTORY = API_HOST + "api/drop_history/";
export const API_POST_ORDERS = API_HOST + "api/post_drop_list/";
export const API_POSTED_ORDERS = API_HOST + "api/post_drop_history/";
export const API_DRAFT_DELETE = API_HOST + "api/draft_delete/";
export const API_DRAFT_LIST = API_HOST + "api/admin_draft_list/";
export const API_ORDER_LIST = API_HOST + "api/admin_order_list/";

//drop_history/
