import React, { useState, useEffect } from "react";
import "./UserForm.css";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { BreadCrumb } from "../../config/Components";
import {
  API_USER_GROUPS,
  API_GET_USER_GROUP,
  API_GET_ROLES,
} from "../../config/Api";
import { withRouter } from "react-router-dom";

const UserGroupFrom = (props) => {
  const initGroupInfo = {
    group_id:
      props.match.params.groupId !== 0 ? props.match.params.groupId : "",
    group_name: "",
  };
  const [group, setGroup] = useState(initGroupInfo);
  const [multipleRoles, setMultipleRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (typeof group.group_id !== "undefined" && group.group_id > 0) {
      fetchGroupById();
    }
    fetchRoles();
  }, [props]);

  const fetchGroupById = async () => {
    props
      .callRequest("GET", API_GET_USER_GROUP + group.group_id, true)
      .then((res) => {
        setGroup(res.data);
        setMultipleRoles(res.data.roles);
      });
  };

  const fetchRoles = async () => {
    props.callRequest("GET", API_GET_ROLES, true).then((res) => {
      setRoles(res.data);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setGroup({ ...group, [name]: value });
  };

  const handleMultipleRoleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setMultipleRoles(value);
  };

  const validateForm = () => {
    let { group_name } = group;

    let formIsValid = true;
    let errors = {};
    if (group_name === "") {
      formIsValid = false;
      errors["err_group_name"] = "Please provide groupname";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      let value = "";
      const multipleRolesArr = multipleRoles.filter((item) => item !== value);
      if (group.group_id > 0) {
        props
          .callRequest("PUT", API_USER_GROUPS + group.group_id + "/", true, {
            group_name: group.group_name,
            roles: multipleRolesArr,
          })
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Updated successfully",
              });
              setTimeout(
                function () {
                  props.history.push("/user-groups");
                  // props.history.push('/admin/user-groups');
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      } else {
        props
          .callRequest("POST", API_USER_GROUPS, true, {
            group_name: group.group_name,
            roles: multipleRolesArr,
          })
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Saved successfully",
              });
              setTimeout(
                function () {
                  props.history.push("/user-groups");
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      }
    }
  };

  const handleClose = () => {
    props.history.push("/user-groups");
    // props.history.push('/admin/user-groups');
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          {group.group_id > 0 ? (
            <BreadCrumb links={["Group List", "Edit Group"]} />
          ) : (
            <BreadCrumb links={["Group List", "Create Group"]} />
          )}
          {group.group_id > 0 ? (
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-circle-08"></i>
              </span>
              Edit Group
            </h1>
          ) : (
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-circle-08"></i>
              </span>
              Create Group
            </h1>
          )}
        </div>
        <div className="create-user-mainblock">
          <Row>
            <Col sm="8" lg="9">
              <Card>
                <CardBody>
                  <div className="create-user-formblock">
                    <Form>
                      <FormGroup>
                        <Label for="group_name">Group Name: *</Label>
                        <Input
                          type="text"
                          autoComplete="off"
                          name="group_name"
                          id="group_name"
                          value={group.group_name}
                          onChange={handleChange}
                          placeholder="Enter Groupname"
                        />
                        <label className="red_err">
                          {errors.err_group_name}
                        </label>
                      </FormGroup>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="roles">Group Role:</Label>
                            <select
                              id="roles"
                              name="roles"
                              multiple={true}
                              value={multipleRoles}
                              onChange={handleMultipleRoleChange}
                              className="form-control"
                              aria-label="Default select example"
                            >
                              <option value="">Select Role</option>
                              {roles.map((role) => (
                                <option key={role.role_id} value={role.role_id}>
                                  {role.role_name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <div className="submit-button-block">
                        <Button className="cancel-button" onClick={handleClose}>
                          Cancel
                        </Button>
                        <Button
                          className="create-User-button"
                          onClick={handleSubmit}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default withRouter(UserGroupFrom);
