import React, { useState, useEffect } from "react";
import "../../assets/css/data-table.css";
import Swal from "sweetalert2";
import {
  Button,
  Input,
  Row,
  Col,
  FormGroup,
  Form,
  Label,
  FormText,
  CustomInput,
} from "reactstrap";

import {
  API_ATTRIBUTE_TYPE,
  API_ATTRIBUTES,
  API_PRODUCT_THUMB_IMAGE,
  API_PRODUCT_FULL_IMAGE,
} from "../../config/Api";
import UploadImage from "../StoreFront/UploadImage";
import { AlphaPicker } from "react-color";
import { SketchPicker } from "react-color";

const AttributeTypeForm = (props) => {
  const initAttributeInfo = {
    attribute_name: "",
    price: parseFloat(0.0),
    code: "",
    description: "",
    template_code: "",
    attributetype_id: 0,
    min_qnty: 0,
    full_image_path: "",
    thumb_image_path: "",
    uploadThumbImage: "",
    uploadImage: "",
    paper_color: "#FFFFFF",
    is_envelope: "",
    is_paper: "",
    is_postage: "",
    priceArr: [
      {
        label: "1 >= - < 200",
        value: "",
        min: 1,
        max: 199,
      },
      {
        label: "200 >= - < 350",
        value: "",
        min: 200,
        max: 349,
      },
      {
        label: "350 >= - < 500",
        value: "",
        min: 350,
        max: 499,
      },
      {
        label: "500 >= - < 1000",
        value: "",
        min: 500,
        max: 999,
      },
      {
        label: "1000 >= - < 2500",
        value: "",
        min: 1000,
        max: 2499,
      },
      {
        label: "2500 >= - < 5000",
        value: "",
        min: 2500,
        max: 4999,
      },
      {
        label: "5000 >= - < 10000",
        value: "",
        min: 5000,
        max: 9999,
      },
      {
        label: "10000 >= - < 25000",
        value: "",
        min: 10000,
        max: 24999,
      },
      {
        label: "25000 >= - < 50000",
        value: "",
        min: 25000,
        max: 49999,
      },
      {
        label: "50000+",
        value: "",
        min: 50000,
        max: "",
      },
    ],
  };
  const [attributes, setAttributes] = useState(initAttributeInfo);
  const [attribute_names, setAttributesNames] = useState("");
  const [attributeTypes, setAttributeTypes] = useState([]);
  const [errors, setErrors] = useState({});
  const selected_attr_type = ["3"];
  // const [color, setColor] = useState("#FFFFFF");

  useEffect(() => {
    fetchAttributeTypes();
    if (props.attributeId !== 0) {
      fetchAttributeById();
    }
  }, [props]);

  const fetchAttributeById = async () => {
    props
      .callRequest("GET", API_ATTRIBUTES + props.attributeId, true)
      .then((res) => {
        let price_arr = res.data.price.split("|");
        let attr_inf = { ...attributes };
        for (let i = 0; i < price_arr.length; i++) {
          if (attr_inf.priceArr[i]) attr_inf.priceArr[i].value = price_arr[i];
        }
        let is_paper = res.data.paper_color !== "" ? "yes" : "";
        let is_postage = res.data.min_qnty !== 0 ? "yes" : "";
        let is_envelope =
          (res.data.thumb_image_path !== "" ||
            res.data.full_image_path !== "") &&
          res.data.min_qnty == 0
            ? "yes"
            : "";

        setAttributes({
          ...attributes,
          is_paper: is_paper,
          is_envelope: is_envelope,
          is_postage: is_postage,
          attr_inf,
          ...res.data,
        });

        // setAttributes({ ...attributes, attr_inf });

        // setAttributes({ ...attributes, ...res.data });
      });
  };

  const fetchAttributeTypes = async () => {
    props.callRequest("GET", API_ATTRIBUTE_TYPE, true).then((res) => {
      setAttributeTypes(res.data);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAttributes({ ...attributes, [name]: value });
  };
  const handleAttrNames = (e) => {
    var index = e.nativeEvent.target.selectedIndex;
    var temp = e.nativeEvent.target[index].text;
    if (temp) {
      if (temp.toLowerCase().includes("envelope")) {
        setAttributes({
          ...attributes,
          is_envelope: "yes",
          is_postage: "",
          is_paper: "",
          paper_color: "",
          min_qnty: 0,
          [e.target.name]: e.target.value,
        });
      } else if (temp.toLowerCase().includes("postage")) {
        setAttributes({
          ...attributes,
          is_postage: "yes",
          is_envelope: "",
          is_paper: "",
          paper_color: "",
          [e.target.name]: e.target.value,
        });
      } else if (temp.toLowerCase().includes("paper")) {
        setAttributes({
          ...attributes,
          is_paper: "yes",
          is_envelope: "",
          is_postage: "",
          min_qnty: 0,
          thumb_image_path: "",
          full_image_path: "",
          [e.target.name]: e.target.value,
        });
      } else {
        setAttributes({
          ...attributes,
          is_paper: "",
          is_envelope: "",
          is_postage: "",
          min_qnty: 0,
          thumb_image_path: "",
          full_image_path: "",
          paper_color: "",
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  const validateForm = () => {
    let {
      attribute_name,
      price,
      description,
      template_code,
      attributetype_id,
    } = attributes;

    let formIsValid = true;
    let errors = {};
    if (attribute_name === "") {
      formIsValid = false;
      errors["err_attribute_name"] = "Please provide attribute name";
    }
    let price_flag = true;
    if (price) {
      let price_arr = price.split("|");
      for (let i = 0; i < price_arr.length; i++) {
        if (!price_arr[i]) {
          price_flag = false;
        }
      }
    } else {
      price_flag = false;
    }

    if (!price_flag) {
      formIsValid = false;
      errors["err_price"] = "All prices must be filled";
    }
    if (description === "") {
      formIsValid = false;
      errors["err_description"] = "Please provide description";
    }
    if (template_code === "") {
      formIsValid = false;
      errors["err_template_code"] = "Please provide template code";
    }
    if (attributetype_id === 0) {
      formIsValid = false;
      errors["err_attribute_type"] = "Please select attribute type";
    }
    setErrors(errors);

    return formIsValid;
  };
  const setImage = (e) => {
    var file = e.target.files[0];
    setAttributes({ ...attributes, uploadImage: file });
  };
  const setThumbImage = (e) => {
    var file = e.target.files[0];
    setAttributes({ ...attributes, uploadThumbImage: file });
    // let reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = () => {
    //   setAttributes({ ...attributes, uploadThumbImage: reader.result });
    // };
    // reader.onerror = function (error) {
    //   console.log("Error: ", error);
    // };
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = new FormData();

      if (attributes.is_paper === "yes") {
        formData.append("paper_color", attributes.paper_color);
        formData.append("min_qnty", 0);
        formData.append("uploadImage", "");
        formData.append("uploadThumbImage", "");
      } else if (attributes.is_envelope == "yes") {
        formData.append("paper_color", "");
        formData.append("min_qnty", 0);
        if (attributes.uploadImage && attributes.uploadImage !== undefined) {
          formData.append(
            "uploadImage",
            attributes.uploadImage,
            attributes.uploadImage.name
          );
        }
        if (
          attributes.uploadThumbImage &&
          attributes.uploadThumbImage !== undefined
        ) {
          formData.append(
            "uploadThumbImage",
            attributes.uploadThumbImage,
            attributes.uploadThumbImage.name
          );
        }
      } else if (attributes.is_postage == "yes") {
        formData.append("paper_color", "");
        formData.append("min_qnty", attributes.min_qnty);
        if (attributes.uploadImage && attributes.uploadImage !== undefined) {
          formData.append(
            "uploadImage",
            attributes.uploadImage,
            attributes.uploadImage.name
          );
        }
        if (
          attributes.uploadThumbImage &&
          attributes.uploadThumbImage !== undefined
        ) {
          formData.append(
            "uploadThumbImage",
            attributes.uploadThumbImage,
            attributes.uploadThumbImage.name
          );
        }
      } else {
        formData.append("paper_color", "");
        formData.append("min_qnty", 0);
        formData.append("uploadImage", "");
        formData.append("uploadThumbImage", "");
      }

      formData.append("attribute_name", attributes.attribute_name);
      formData.append("attributetype_id", attributes.attributetype_id);
      formData.append("description", attributes.description);
      formData.append("price", attributes.price);
      formData.append("template_code", attributes.template_code);

      if (props.attributeId !== 0) {
        props
          .callRequest(
            "PUT",
            API_ATTRIBUTES + props.attributeId + "/",
            true,
            formData
          )
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Updated successfully",
              });
              setTimeout(
                function () {
                  props.modalToggle();
                  props.fetchAttributes();
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      } else {
        props
          .callRequest("POST", API_ATTRIBUTES, true, formData)
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Saved successfully",
              });
              setTimeout(
                function () {
                  props.modalToggle();
                  props.fetchAttributes();
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      }
    }
  };

  const changeHandler = (e, index) => {
    if (index !== "undefined") {
      attributes.priceArr[index].value = e.target.value;
    }
    let price_str = "";
    for (let i = 0; i < attributes.priceArr.length; i++) {
      price_str += attributes.priceArr[i].value + "|";
    }
    price_str = price_str.slice(0, -1);

    setAttributes({ ...attributes, price: price_str });
  };

  const onChangeAlpha = (color) => {
    setAttributes({ ...attributes, paper_color: color.rgb });
  };

  const hexToRgba = (color) => {
    if (!color) return;
    if (color[0] === "#") {
      if (color.length < 7) {
        color =
          "#" +
          color[1] +
          color[1] +
          color[2] +
          color[2] +
          color[3] +
          color[3] +
          (color.length > 4 ? color[4] + color[4] : "");
      }
      return [
        parseInt(color.substr(1, 2), 16),
        parseInt(color.substr(3, 2), 16),
        parseInt(color.substr(5, 2), 16),
        color.length > 7 ? parseInt(color.substr(7, 2), 16) / 255 : 1,
      ];
    }
    if (color.indexOf("rgba") === -1) color += ",1";
    return color.match(/[\.\d]+/g).map(function (a) {
      return +a;
    });
  };

  const rgbaToHex = (color) => {
    if (/^rgb/.test(color)) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      let hex = `#${(
        (1 << 24) +
        (parseInt(rgba[0], 10) << 16) +
        (parseInt(rgba[1], 10) << 8) +
        parseInt(rgba[2], 10)
      )
        .toString(16)
        .slice(1)}`;
      return hex;
    }
    return color;
  };

  const handleColorChange = (color) => {
    setAttributes({ ...attributes, paper_color: color });
    // let hexToRgbA = hexToRgba(color);
    // let rgb = {
    //   r: hexToRgbA[0],
    //   g: hexToRgbA[1],
    //   b: hexToRgbA[2],
    //   a: hexToRgbA[3],
    // };
  };
  return (
    <>
      <div className="popup-form-block">
        <Form>
          <Row form>
            <Col md={12}>
              <FormGroup>
                <Label for="typeName">
                  Attribute Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  autoComplete="off"
                  name="attribute_name"
                  id="attribute_name"
                  className="form-control"
                  value={attributes.attribute_name}
                  onChange={handleChange}
                />
                <label className="red_err">{errors.err_attribute_name}</label>
              </FormGroup>
            </Col>
            <Col md={12} className="mt-3">
              <FormGroup>
                <FormText className="red-color">{errors.err_price}</FormText>
              </FormGroup>
            </Col>
            {attributes.priceArr.map((prc, index) => {
              return (
                <Col md={6} key={index}>
                  <FormGroup>
                    <Label for={prc.label}>
                      {prc.label} <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      autoComplete="off"
                      name="price"
                      id={prc.label}
                      className="form-control"
                      value={prc.value}
                      onChange={(e) => changeHandler(e, index)}
                    />
                  </FormGroup>
                </Col>
              );
            })}
            <Col md={6}>
              <FormGroup>
                <Label for="typeName">
                  Description <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  autoComplete="off"
                  name="description"
                  id="description"
                  className="form-control"
                  value={attributes.description}
                  onChange={handleChange}
                />
                <label className="red_err">{errors.err_description}</label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="typeName">
                  Template Code <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  autoComplete="off"
                  name="template_code"
                  id="template_code"
                  className="form-control"
                  value={attributes.template_code}
                  onChange={handleChange}
                />
                <label className="red_err">{errors.err_template_code}</label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <div className="form-group">
                <label>
                  Select Attribute Type: <span className="text-danger">*</span>
                </label>
                <select
                  id="attributetype_id"
                  name="attributetype_id"
                  value={attributes.attributetype_id}
                  onChange={handleAttrNames}
                  // onChange={handleChange}
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option value="">Select Attribute Type</option>
                  {attributeTypes.map((attributeType) => (
                    // let temp = attributeType.type_name + "_" + attributeType.type_id
                    <option
                      key={attributeType.type_id}
                      // value={
                      //   attributeType.type_name + "_" + attributeType.type_id
                      // }
                      value={attributeType.type_id}
                    >
                      {attributeType.type_name}
                    </option>
                  ))}
                </select>
                <label className="red_err">{errors.err_attribute_type}</label>
              </div>
            </Col>
            {attributes && attributes.is_paper === "yes" ? (
              <Col md={6}>
                <div className="form-group">
                  <label>
                    Choose Color <span className="text-danger">*</span>
                  </label>
                  <input
                    className="color-picker-attribute"
                    type="color"
                    value={attributes.paper_color}
                    onChange={(e) => handleColorChange(e.target.value)}
                    // style={{
                    //   opacity: attributes.paper_color[3]
                    //     ? attributes.paper_color[3]
                    //     : 1,
                    // }}
                  />

                  {/* <AlphaPicker
                    color={attributes.paper_color}
                    onChange={onChangeAlpha}
                  /> */}
                </div>
              </Col>
            ) : null}
            {attributes && attributes.is_postage === "yes" && (
              <Col md={6}>
                <FormGroup>
                  <Label for="typeName">Minimum Quantity</Label>
                  <Input
                    type="number"
                    autoComplete="off"
                    name="min_qnty"
                    className="form-control"
                    value={attributes.min_qnty}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            )}
            {(attributes && attributes.is_postage === "yes") ||
            (attributes && attributes.is_envelope === "yes") ? (
              <Row>
                <Col md={6}>
                  <div className="form-group">
                    {(attributes.is_postage === "yes" ||
                      attributes.is_envelope === "yes") &&
                    attributes.full_image_path &&
                    attributes.full_image_path !== undefined ? (
                      <label>Change Full Image: </label>
                    ) : (
                      <label>Upload Full Image: </label>
                    )}
                    <CustomInput
                      type="file"
                      id="uploadImage"
                      //accept="html"
                      onChange={setImage}
                    />
                    {(attributes.is_postage === "yes" ||
                      attributes.is_envelope === "yes") &&
                    attributes.full_image_path &&
                    attributes.full_image_path !== undefined ? (
                      <Label>
                        Full Image&nbsp;&nbsp;
                        <img
                          width={150}
                          height={150}
                          alt="full path image"
                          src={
                            process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
                            attributes.full_image_path
                          }
                        />
                      </Label>
                    ) : null}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form-group">
                    {(attributes.is_postage === "yes" ||
                      attributes.is_envelope === "yes") &&
                    attributes.full_image_path &&
                    attributes.full_image_path !== undefined ? (
                      <label>Change Thumbnail Image: </label>
                    ) : (
                      <label>Upload Thumbnail Image: </label>
                    )}
                    <CustomInput
                      type="file"
                      id="uploadThumbImage"
                      //accept="html"
                      onChange={setThumbImage}
                    />
                    {(attributes.is_postage === "yes" ||
                      attributes.is_envelope === "yes") &&
                    attributes.thumb_image_path &&
                    attributes.thumb_image_path !== undefined ? (
                      <Label>
                        Thumbnail Image
                        <img
                          width={150}
                          height={150}
                          alt="thumb path image"
                          src={
                            process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
                            attributes.thumb_image_path
                          }
                        />
                      </Label>
                    ) : null}
                  </div>
                </Col>
              </Row>
            ) : null}

            {/* {attributes.uploadThumbImage | attributes.uploadImage ? (
              <div className="add-Product-block">
                <div id="scaleTemplateBoxLayout">
                  <div className="design-thum-block">
                    <img
                      width={150}
                      height={150}
                      alt="full path image"
                      src={attributes.uploadImage}
                    />

                    <img
                      width={150}
                      height={150}
                      alt="thumb path image"
                      src={attributes.uploadThumbImage}
                    />
                  </div>
                </div>
              </div>
            ) : null} 
            <div className="add-Product-block">
              <div id="scaleTemplateBoxLayout">
                <div className="design-thum-block">
                  {attributes.full_image_path &&
                  attributes.full_image_path !== undefined ? (
                    <Label>
                      Full Image
                      <img
                        width={150}
                        height={150}
                        alt="full path image"
                        src={
                          process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
                          attributes.full_image_path
                        }
                      />
                    </Label>
                  ) : null}
                  {attributes.thumb_image_path &&
                  attributes.thumb_image_path !== undefined ? (
                    <Label>
                      Thumbnail Image
                      <img
                        width={150}
                        height={150}
                        alt="thumb path image"
                        src={
                          process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
                          attributes.thumb_image_path
                        }
                      />
                    </Label>
                  ) : null}
                </div>
              </div>
            </div>*/}
          </Row>

          <div className="popup-button-block">
            <Button onClick={handleSubmit}>
              Save Changes <i className="ni ni-bold-right"></i>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default AttributeTypeForm;
