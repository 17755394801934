import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import ResizableComponent from "./ResizableComponent";

const TextComponent = (props) => {
  const [top, setTop] = useState(props.top);
  const [left, setLeft] = useState(props.left);
  const [width] = useState(props.width);
  const [height] = useState(props.height);
  const [rotateAngle] = useState(props.rotateAngle);
  const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
  const [roundedCorner, setRoundedCorner] = useState(props.rounded_corner);
  const [margin_top, setMarginTop] = useState(props.margin_top);
  const [margin_left, setMarginLeft] = useState(props.margin_left);
  const [padding_top, setPaddingTop] = useState(props.padding_top);
  const [padding_right, setPaddingRight] = useState(props.padding_right);
  const [padding_bottom, setPaddingBottom] = useState(props.padding_bottom);
  const [padding_left, setPaddingLeft] = useState(props.padding_left);
  const [custom_text_lock, setCustomTextLock] = useState(props.custom_text_lock);
  const [font_min_size, setFontMinSize] = useState(props.font_min_size);
  const [font_max_size, setFontMaxSize] = useState(props.font_max_size);
  const [scalling_class, setScallingClass] = useState(props.scalling_class);
  const [transparency, setTransparency] = useState(props.transparency);
  const [text_autofit, setTextAutofit] = useState(props.text_autofit);

  useEffect(() => {
    setBackgroundColor(props.backgroundColor);
    setRoundedCorner(props.rounded_corner);
    setMarginTop(props.margin_top);
    setMarginLeft(props.margin_left);
    setPaddingTop(props.padding_top);
    setPaddingRight(props.padding_right);
    setPaddingBottom(props.padding_bottom);
    setPaddingLeft(props.padding_left);
    setCustomTextLock(props.custom_text_lock);
    setTop(props.top);
    setLeft(props.left);
    setFontMinSize(props.font_min_size);
    setFontMaxSize(props.font_max_size);
    setScallingClass(props.scalling_class);
    setTransparency(props.transparency);
    setTextAutofit(props.text_autofit);
  }, [
    props.backgroundColor,
    props.rounded_corner,
    props.margin_top,
    props.margin_left,
    props.padding_top,
    props.padding_right,
    props.padding_bottom,
    props.padding_left,
    props.custom_text_lock,
    props.top,
    props.left,
    props.font_min_size,
    props.font_max_size,
    props.scalling_class,
    props.transparency,
    props.text_autofit,
  ]);

  function setCustomDesign(
    htmldata,
    id,
    left,
    top,
    width,
    height,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    custom_text_lock,
    font_min_size,
    font_max_size,
    scalling_class,
    transparency,
    text_autofit,
  ) {
    props.parentsetCustomDesign(
      htmldata,
      id,
      left,
      top,
      width,
      height,
      props.title,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      resize,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      custom_text_lock,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );
  }

  function textEditorData(
    id,
    left,
    top,
    width,
    height,
    raw,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    custom_text_lock,
    font_min_size,
    font_max_size,
    scalling_class,
    transparency,
    text_autofit,
  ) {
    props.parentEditorData(
      id,
      left,
      top,
      width,
      height,
      raw,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      resize,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      custom_text_lock,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );
  }
  return (
    <ResizableComponent
      top={top}
      left={left}
      width={width}
      height={height}
      rotateAngle={rotateAngle}
      backgroundColor={backgroundColor}
      roundedCorner={roundedCorner}
      margin_top={margin_top}
      margin_left={margin_left}
      padding_top={padding_top}
      padding_right={padding_right}
      padding_bottom={padding_bottom}
      padding_left={padding_left}
      custom_text_lock={custom_text_lock}
      font_min_size={font_min_size}
      font_max_size={font_max_size}
      scalling_class={scalling_class}
      transparency={transparency}
      text_autofit={text_autofit}
      parentsetCustomDesign={setCustomDesign}
      parentEditorData={textEditorData}
      id={props.id}
      title={props.title}
      double_click={props.double_click}
    ></ResizableComponent>
  );
};

export default TextComponent;
