import Dashboard from "./components/Dashboard/Dashboard";
import TemplateDesign from "./components/TemplateDesign/TemplateDesign";
import TemplateList from "./components/TemplateDesign/TemplateList";
import EditTemplate from "./components/TemplateDesign/edit/EditTemplate";
import VDP from "./components/VDP/VDP";
import SubscriptionList from "./components/Subscriptions/SubscriptionList";
import NewSubscription from "./components/Subscriptions/NewSubscription";
import ProfileInformation from "./components/Profile/ProfileInformation";
import MailerOptions from "./components/Subscriptions/MailerOptions";
import SelectTemplate from "./components/Subscriptions/SelectTemplate";
import TemplatePreview from "./components/Subscriptions/TemplatePreview";
import Products from "./components/StoreFront/Products";
import AddProduct from "./components/StoreFront/AddProduct";
import CategoryList from "./components/StoreFront/CategoryList";
//import CategoryForm from "./components/StoreFront/CategoryForm";
import ProductBundles from "./components/StoreFront/ProductBundles";
import AddBundle from "./components/StoreFront/AddBundle";
import PaymentProcessing from "./components/StoreFront/PaymentProcessing";
import Coupons from "./components/StoreFront/Coupons";
import AddCoupon from "./components/StoreFront/AddCoupon";

import Orders from "./components/Orders/Orders";
import PayAsYouGoOrders from "./components/Orders/PayAsYouGoOrders";
import OrderHistory from "./components/Orders/OrderHistory";
import OrderBatching from "./components/Orders/OrderBatching";
import CreateOrder from "./components/Orders/CreateOrder";
import OrderDetails from "./components/Orders/OrderDetails";
import CartList from "./components/Orders/CartList";
import CreateOrderApproval from "./components/Orders/CreateOrderApproval";
import EditCartApproval from "./components/Orders/EditCartApproval";
import BatchPreview from "./components/Orders/BatchPreview";
import ProductionBatches from "./components/Orders/ProductionBatches";
import CompleteBatches from "./components/Orders/CompleteBatches";
import CancelBatches from "./components/Orders/CancelBatches";
import PostOrders from "./components/Orders/PostOrders";

import Campaigns from "./components/CRM/Campaigns";
import Contacts from "./components/CRM/Contacts";
import CreateCampaign from "./components/CRM/CreateCampaign";
import ListPreview from "./components/CRM/ListPreview";
import CrmContacts from "./components/CRM/CrmContacts";

import ImportTemplate from "./components/ImportTemplate/ImportTemplate";
import PaymentMethods from "./components/Billing/PaymentMethods";
import PaymentHistory from "./components/Billing/PaymentHistory";
import UserList from "./components/Users/UserList.js";
import UserGroups from "./components/Users/UserGroups.js";
import UserFrom from "./components/Users/UserForm.js";
import UserGroupForm from "./components/Users/UserGroupForm";

import IntervalList from "./components/Interval/IntervalList";
import IntervalAdd from "./components/Interval/IntervalAdd";
import IntervalEdit from "./components/Interval/IntervalEdit";

import TemplateSizeList from "./components/TemplateSize/TemplateSizeList";
import AttributeTypeList from "./components/AttributeType/AttributeTypeList";
import AttributeList from "./components/Attribute/AttributeList";
import AffiliateList from "./components/Affiliate/AffiliateList";
import AffiliateForm from "./components/Affiliate/AffiliateForm";

import MailingList from "./components/CRM/MailingList";
import CreateMailingList from "./components/CRM/CreateMailingList";
import ContentManagement from "./components/CMS/contentManagement";
import SectionLists from "./components/CMS/SectionLists";
import AddSection from "./components/CMS/AddSection";
import DraftOrders from "./components/Orders/DraftOrders";
var routes = [
  {
    path: "dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/",
    is_menu: true,
  },
  {
    path: "profile-information",
    name: "Profile",
    icon: "ni ni-badge",
    component: ProfileInformation,
    layout: "/",
    is_menu: true,
  },
  {
    path: "#",
    name: "Affiliate",
    icon: "ni ni-circle-08",
    component: AffiliateList,
    layout: "/",
    is_menu: true,
    role: "Affiliate_List",
    submenue: [
      {
        path: "affiliate-list",
        name: "Affiliate List",
        icon: "ni ni-collection",
        component: AffiliateList,
        layout: "/",
        is_menu: true,
        role: "Affiliate_List",
      },
    ],
  },
  {
    path: "create-affiliate",
    name: "Add Affiliate",
    icon: "ni ni-collection",
    component: AffiliateForm,
    layout: "/",
    is_menu: false,
    sub: "",
    // role: "Affiliate_Add",
  },
  {
    path: "edit-affiliate/:afflId",
    name: "Edit Affiliate List",
    icon: "ni ni-collection",
    component: AffiliateForm,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Affiliate_Edit",
  },
  {
    path: "mailer-options",
    component: MailerOptions,
    layout: "/",
    is_menu: false,
  },
  {
    path: "select-template",
    component: SelectTemplate,
    layout: "/",
    is_menu: false,
  },
  {
    path: "template-preview",
    component: TemplatePreview,
    layout: "/",
    is_menu: false,
  },
  {
    path: "#",
    name: "Templates",
    icon: "ni ni-album-2 text-red",
    component: TemplateDesign,
    layout: "/",
    is_menu: true,
    role: "Template",
    submenue: [
      {
        path: "template-list",
        name: "Templates List",
        icon: "ni ni-album-2 text-red",
        component: TemplateList,
        layout: "/",
        is_menu: false,
        role: "Template_List",
      },
      {
        path: "template-size-list",
        name: "Template Size List",
        icon: "ni ni-ruler-pencil",
        component: TemplateSizeList,
        layout: "/",
        is_menu: true,
        role: "Template_Size_List",
      },
    ],
  },
  {
    path: "#",
    name: "Attributes",
    icon: "ni ni-books",
    component: AttributeTypeList,
    layout: "/",
    is_menu: true,
    role: "Attributes",
    submenue: [
      {
        path: "attribute-type-list",
        name: "Attribute Type List",
        icon: "ni ni-books",
        component: AttributeTypeList,
        layout: "/",
        is_menu: true,
        role: "Attribute_Type_List",
      },
      {
        path: "attribute-list",
        name: "Attribute List",
        icon: "ni ni-book-bookmark",
        component: AttributeList,
        layout: "/",
        is_menu: true,
        role: "Attribute_Listing",
      },
    ],
  },
  {
    path: "vdp-merge-interface",
    name: "VDP Merge Engine",
    icon: "ni ni-single-copy-04 text-blue",
    component: VDP,
    layout: "/",
    is_menu: true,
    role: "VDP",
  },
  {
    path: "template-design",
    name: "Templates Add",
    icon: "ni ni-album-2 text-red",
    component: TemplateDesign,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Template_Create",
  },
  {
    path: "edit-template/:templateid",
    name: "Edit Template",
    icon: "ni ni-single-copy-04 text-blue",
    component: EditTemplate,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Template_Update",
  },
  {
    path: "my-subscription",
    name: "Subscriptions",
    icon: "ni ni-calendar-grid-58",
    component: SubscriptionList,
    layout: "/",
    is_menu: true,
    role: "Subscriptions",
    submenue: [
      {
        path: "my-subscription",
        name: "My Subscriptions",
        icon: "ni ni-calendar-grid-58",
        component: SubscriptionList,
        layout: "/",
        is_menu: true,
        role: "My_Subscription",
      },
      {
        path: "new-subscription",
        name: "New Subscription",
        icon: "ni ni-calendar-grid-58",
        component: NewSubscription,
        layout: "/",
        is_menu: true,
        role: "New_Subscription",
      },
    ],
  },
  {
    path: "#",
    name: "CRM",
    icon: "ni ni-money-coins",
    component: Campaigns,
    layout: "/",
    is_menu: true,
    role: "CRM",
    submenue: [
      {
        path: "campaigns",
        name: "Campaigns",
        component: Campaigns,
        layout: "/",
        is_menu: true,
        role: "Campaigns",
      },
      {
        path: "mailing-list",
        name: "Mailing List",
        component: MailingList,
        layout: "/",
        is_menu: true,
        role: "Campaigns",
      },
      {
        path: "contacts",
        name: "Contacts",
        component: Contacts,
        layout: "/",
        is_menu: true,
        role: "Contacts",
      },
    ],
  },
  {
    path: "crm-contacts",
    component: CrmContacts,
    layout: "/",
    is_menu: false,
  },
  {
    path: "list-preview",
    component: ListPreview,
    layout: "/",
    is_menu: false,
  },
  {
    path: "create-campaign",
    component: CreateCampaign,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "CreateCampaign",
  },
  {
    path: "create-mailinglist",
    component: CreateMailingList,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "CreateCampaign",
  },
  {
    path: "#",
    name: "Store Front",
    icon: "ni ni-archive-2",
    component: CategoryList,
    layout: "/",
    is_menu: true,
    role: "Store_Front",
    submenue: [
      {
        path: "category-list",
        name: "Product Categories",
        component: CategoryList,
        layout: "/",
        is_menu: true,
        role: "Category_Listing",
      },
      {
        path: "products",
        name: "Products",
        component: Products,
        layout: "/",
        is_menu: true,
        role: "Product_Listing",
      },
      {
        path: "product-bundles",
        name: "Product Bundles",
        component: ProductBundles,
        layout: "/",
        is_menu: true,
        role: "Product_Bundle_Listing",
      },
      {
        path: "payment-processing",
        name: "Payment Processing",
        component: PaymentProcessing,
        layout: "/",
        is_menu: true,
        role: "Payment_Processing",
      },
      {
        path: "coupons",
        name: "Coupons",
        component: Coupons,
        layout: "/",
        is_menu: true,
        role: "Product_Coupons",
      },
    ],
  },
  // {
  //   path: "category-list",
  //   component: CategoryForm,
  //   layout: "/",
  //   is_menu: false,
  //   sub: "",
  //   role: "Add_Category",
  // },
  {
    path: "add-product",
    component: AddProduct,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Product_Create",
  },
  {
    path: "edit-product/:id",
    component: AddProduct,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Product_Update",
  },
  {
    path: "add-bundle",
    component: AddBundle,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "CreateBundle",
  },
  {
    path: "edit-bundle/:id",
    component: AddBundle,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "UpdateBundle",
  },
  {
    path: "add-coupon",
    component: AddCoupon,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "AddCoupon",
  },
  {
    path: "edit-coupon/:couponid",
    component: AddCoupon,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "EditCoupon",
  },
  {
    path: "orders",
    name: "Orders",
    icon: "ni ni-cart",
    component: Orders,
    layout: "/",
    is_menu: true,
    role: "Orders",
    submenue: [
      {
        path: "create-order",
        name: "Create Order",
        component: CreateOrder,
        layout: "/",
        is_menu: true,
        role: "Create_Order",
      },
      {
        path: "cart-list",
        name: "Cart",
        component: CartList,
        icon: "ni ni-single-copy-04 text-blue",
        layout: "/",
        is_menu: true,
        role: "CartList",
      },
      {
        path: "orders",
        name: "Orders",
        component: Orders,
        layout: "/",
        is_menu: true,
        role: "Orders",
      },
      {
        path: "draft_orders",
        name: "Draft Orders",
        component: DraftOrders,
        layout: "/",
        is_menu: true,
        role: "DraftOrders",
      },
      {
        path: "pay-as-you-go-orders",
        name: "Pay as you go Orders",
        component: PayAsYouGoOrders,
        layout: "/",
        is_menu: true,
        role: "Orders",
      },
      {
        path: "order-history",
        name: "Order History",
        component: OrderHistory,
        layout: "/",
        is_menu: true,
        role: "Order_History",
      },
      {
        path: "order-batching",
        name: "Order Batching",
        component: OrderBatching,
        layout: "/",
        is_menu: true,
        //role: "Order_Batching",
      },
      {
        path: "batch-preview",
        name: "Batch Preview",
        component: BatchPreview,
        layout: "/",
        is_menu: true,
        //role: "Preview_Batching",
      },
      {
        path: "production-batches",
        name: "Production Batches",
        component: ProductionBatches,
        layout: "/",
        is_menu: true,
        //role: "Production_Batching",
      },
      {
        path: "complete-batches",
        name: "Complete Batches",
        component: CompleteBatches,
        layout: "/",
        is_menu: true,
        //role: "Complete_Batches",
      },
      {
        path: "post-orders",
        name: "Post Orders",
        component: PostOrders,
        layout: "/",
        is_menu: true,
        role: "Post_Orders",
      },
      {
        path: "cancel-batches",
        name: "Cancel Batches",
        component: CancelBatches,
        layout: "/",
        is_menu: true,
        //role: "Cancel_Batches",
      },
      // {
      //   path: "edit-order/:orderId",
      //   //name: "Edit Order",
      //   component: CreateOrder,
      //   layout: "/",
      //   is_menu: false,
      //   role: "Create_Order",
      // },
    ],
  },
  {
    path: "edit-order/:orderId/:status/:edit",
    component: CreateOrder,
    layout: "/",
    sub: "",
    is_menu: false,
  },
  {
    path: "order-details",
    component: OrderDetails,
    layout: "/",
    sub: "",
    is_menu: false,
  },
  {
    path: "create-order-approval",
    component: CreateOrderApproval,
    layout: "/",
    sub: "",
    is_menu: false,
  },
  {
    path: "edit-cart/:cartId",
    component: EditCartApproval,
    layout: "/",
    sub: "",
    is_menu: false,
  },
  {
    path: "import-template",
    component: ImportTemplate,
    layout: "/",
    sub: "",
    is_menu: false,
  },
  {
    path: "interval-list",
    name: "Intervals",
    icon: "ni ni-time-alarm",
    component: IntervalList,
    layout: "/",
    is_menu: true,
    role: "Interval",
    submenue: [
      {
        path: "interval-list",
        name: "Interval List",
        icon: "ni ni-collection",
        component: IntervalList,
        layout: "/",
        is_menu: true,
        role: "Interval_List",
      },
    ],
  },
  {
    path: "interval-add",
    name: "Interval Add",
    icon: "ni ni-collection",
    component: IntervalAdd,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Interval_Create",
  },
  {
    path: "edit-interval/:intervalid",
    name: "Edit Interval",
    icon: "ni ni-collection",
    component: IntervalEdit,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Interval_Update",
  },
  {
    path: "payment-methods",
    name: "Billing",
    icon: "ni ni-credit-card",
    component: PaymentMethods,
    layout: "/",
    is_menu: true,
    role: "Billing_Section",
    submenue: [
      {
        path: "payment-methods",
        name: "Payment Methods",
        icon: "ni ni-album-2 text-red",
        component: PaymentMethods,
        layout: "/",
        is_menu: true,
        role: "Payment_Methods",
      },
      {
        path: "payment-history",
        name: "Payment History",
        icon: "ni ni-album-2 text-red",
        component: PaymentHistory,
        layout: "/",
        is_menu: true,
        role: "Payment_History_Page",
      },
    ],
  },
  {
    path: "#",
    name: "Users",
    icon: "ni ni-circle-08",
    component: UserList,
    layout: "/",
    is_menu: true,
    role: "User",
    submenue: [
      {
        path: "user-list",
        name: "User List",
        icon: "ni ni-collection",
        component: UserList,
        layout: "/",
        is_menu: true,
        role: "User_Listing",
      },
      {
        path: "user-groups",
        name: "User Groups",
        icon: "ni ni-collection",
        component: UserGroups,
        layout: "/",
        is_menu: true,
        role: "Group_Listing",
      },
    ],
  },
  {
    path: "create-user",
    component: UserFrom,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "User_Create",
  },
  {
    path: "edit-user/:userId",
    component: UserFrom,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "User_Update",
  },
  {
    path: "create-group",
    component: UserGroupForm,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Group_Create",
  },
  {
    path: "edit-group/:groupId",
    component: UserGroupForm,
    layout: "/",
    is_menu: false,
    sub: "",
    role: "Group_Update",
  },
  {
    path: "#",
    name: "Content Management",
    icon: "ni ni-circle-08",
    component: ContentManagement,
    layout: "/",
    is_menu: true,
    role: "content-management",
    submenue: [
      {
        path: "cms",
        name: "CMS",
        icon: "ni ni-collection",
        component: ContentManagement,
        layout: "/",
        is_menu: true,
        role: "content-management",
      },
      {
        path: "sections/:id",
        name: "Sections",
        component: SectionLists,
        layout: "/",
        is_menu: true,
        //role: "Section_Management",
      },
    ],
  },
  {
    path: "add-section",
    component: AddSection,
    layout: "/",
    is_menu: false,
    sub: "",
    //role: "CreateBundle",
  },
  {
    path: "edit-section/:id",
    component: AddSection,
    layout: "/",
    is_menu: false,
    sub: "",
    //role: "UpdateBundle",
  },
];

export default routes;
