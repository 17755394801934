import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

const UploadImage = (props) => {
  const [preview_img, setPreviewImage] = useState(null);
  const [selected_file, setSelectedFile] = useState(null);
  const [original_img, setOriginalImage] = useState(null);

  useEffect(() => {
    setOriginalImage(props.original_img);
  }, [props]);

  const fileChangedHandler = (event, mode) => {
    if (event.target.files[0]) {
      setPreviewImage(URL.createObjectURL(event.target.files[0]));
      setSelectedFile(event.target.files[0]);
    }
  };

  const saveImage = (e) => {
    const formData = new FormData();
    formData.append("uplodedFile", selected_file, selected_file.name);

    props
      .callRequest("POST", props.url, true, formData)
      .then((res) => {
        props.setImage(res.data);
        setPreviewImage(null);
        setOriginalImage(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="upload-btn-box">
      {preview_img && (
        <Button
          className="remove-image-button"
          onClick={() => {
            setPreviewImage(null);
          }}
        >
          <i className="ni ni-fat-remove"></i>
        </Button>
      )}

      <div id="back_image_preview" className="thum-box single-box">
        <img
          src={
            preview_img
              ? preview_img
              : original_img
              ? process.env.REACT_APP_PUBLIC_URL_PHOTOPATH + original_img
              : require("../../assets/img/product-default-image.png").default
          }
        />
      </div>
      <div className="upload-btn-wrapper">
        <div className="upload-button">
          {preview_img ? (
            <button className="btn" onClick={saveImage}>
              Save
            </button>
          ) : (
            <React.Fragment>
              <button className="btn">{props.btn_name}</button>
              <input
                type="file"
                accept="image/png, image/jpeg, .pdf"
                onChange={(e) => fileChangedHandler(e, "thumb")}
                onClick={(event) => {
                  event.currentTarget.value = null;
                }}
                className="btn custom-file-input"
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
