import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";
import "./Subscriptions.css";
import { withRouter } from "react-router-dom";
import Swal from "sweetalert2";
import SimpleDateTime from "react-simple-timestamp-to-date";
import imagePath from "../../assets/img/checkmark.png";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";
import { API_SUBSCRIPTION, API_USERS } from "../../config/Api";

const SubscriptionList = (props) => {
  const [subscriptionList, setSubcriptionList] = useState([]);
  const [subscriptions, setSubcriptions] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [rows, setRows] = useState(10);

  const searchParam = [
    "subType",
    "state",
    "county",
    "status",
    "interval_type",
    "subscription_id",
    "amount",
    "override_amount",
    "userEmail",
    "formattedDate",
  ];
  useEffect(() => {
    fetchSubscriptions();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear();
  };

  const fetchSubscriptions = () => {
    props
      .callRequest("GET", API_SUBSCRIPTION, true)
      .then((res) => {
        let allRecords = [];
        res.data.map((item) => {
          item.status = item.is_active == "1" ? "Active" : " Inacti";
          item.subType =
            item.subscription_type === "0" ? "Probate" : "Micro Campaign";
          /* try {
          item["userName"] = users.filter((usr) => {
            if (usr.user_id === item.user) {
              return usr.username;
            } else {
              return "";
            }
          })[0].username;
        } catch (err) {
          console.log(err);
        }
        try {
          item["userEmail"] = users.filter((usr) => {
            if (usr.user_id === item.user) {
              return usr.email;
            } else {
              return "";
            }
          })[0].email;
        } catch (err) {
          console.log(err);
        } */
          item.formattedDate = dateFormater(item.added_date);

          allRecords.push(item);
        });
        setSubcriptionList(allRecords);
        setSubcriptions(allRecords);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.error === "No Record Found") {
          setSubcriptionList([]);
          setSubcriptions([]);
          setLoading(false);
        }
      });
  };
  const ExportHandler = () => {
    if (subscriptions.length !== 0) {
      let currSub = [...subscriptions];
      let abc = currSub.map((sub) => {
        sub["userEmail"] = users.filter((usr) => {
          if (usr.user_id === sub.user) {
            return usr.email;
          } else {
            return "";
          }
        })[0].email;

        return sub;
      });

      setSubcriptions(abc);
    }
  };
  const searchHandler = (e) => {
    ExportHandler();
    setFilterText(e.target.value);
  };
  const fetchUsers = () => {
    props.callRequest("GET", API_USERS, true).then((res) => {
      setUsers(res.data);
    });
  };
  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };

  const clickHandler = (subscription_id) => {
    Swal.fire({
      //icon: "question",
      imageHeight: 120,
      imageUrl: imagePath,
      imageWidth: 120,
      title: `<h1 style='color:red'>Are you sure?</h1>`,
      html: `<h3 style='color:red'>Are you sure you want to cancel? Once cancelled, subscription cannot be restarted. A new one will need to be placed.</h3>`,
      showDenyButton: true,
      confirmButtonText: `<i class = "fas fa-check"></i>`,
      denyButtonText: `<i class = "fas fa-times"></i>`,
    }).then((result) => {
      if (result.isConfirmed) {
        let found = subscriptions.find(
          (sub) => sub.subscription_id === subscription_id
        );
        if (found) {
          let post_data = {
            user: subscriptions.filter((usr) => {
              return usr.subscription_id === subscription_id;
            })[0].user,
            is_active: 0,
          };
          props
            .callRequest(
              "PUT",
              API_SUBSCRIPTION + subscription_id + "/",
              true,
              post_data
            )
            .then((res) => {
              fetchSubscriptions();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          Swal.fire("Subscription cannot be inactivated!!", "", "info");
        }
      }
    });
  };
  const columns = [
    {
      name: "Order ID",
      selector: "order_id",
      sortable: true,
    },
    {
      name: "Subscription ID",
      selector: "subscription_id",
      sortable: true,
    },
    {
      name: "Email Address",
      selector: "user",
      sortable: true,
      cell: (row) => (
        <div>
          {users.map((usr) => {
            if (usr.user_id === row.user) {
              return usr.email;
            } else {
              return "";
            }
          })}
        </div>
      ),
    },
    {
      name: "Subscription",
      selector: "subscription_type",
      sortable: true,
      cell: (row) => (
        <div>
          {row.subscription_type === "1"
            ? "Micro Campaign"
            : row.subscription_type === "0"
            ? "Probate"
            : ""}
        </div>
      ),
    },

    {
      name: "State",
      selector: "state",
      sortable: true,
      cell: (row) => <div>{row.state !== "" ? row.state : "--"}</div>,
    },
    {
      name: "County",
      selector: "county",
      sortable: true,
      cell: (row) => <div>{row.county !== "" ? row.county : "--"}</div>,
    },
    {
      name: "Type",
      selector: "interval_type",
      sortable: true,
    },
    {
      name: "Start Date",
      selector: "added_date",
      sortable: true,
      cell: (row) => (
        <SimpleDateTime dateSeparator="-" dateFormat="MDY" showTime="0">
          {row.added_date}
        </SimpleDateTime>
      ),
    },
    {
      name: "Price",
      selector: "amount",
      sortable: true,
      cell: (row) => (
        <div>
          ${row.override_amount != 0 ? row.override_amount : row.amount}
        </div>
      ),
    },
    {
      name: "Status",
      selector: "is_active",
      sortable: true,
      cell: (row) => (
        <div>{row.is_active === "1" ? "Active" : "Inactive "}</div>
      ),
    },
    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          {row.is_active === "1" ? (
            <Button onClick={() => clickHandler(row.subscription_id)}>
              <i className="fas fa-times-circle subName"></i>
            </Button>
          ) : (
            <Button onClick={() => props.history.push("/new-subscription/")}>
              <i className="fas fa-plus-circle warning"></i>
            </Button>
          )}
        </UncontrolledDropdown>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          autoComplete="off"
          onChange={searchHandler}
        />
      </FormGroup>
    );
  }, [filterText]);

  const HandleFilter = (index) => {
    if (index === 2) {
      setSubcriptions(subscriptionList);
    }

    if (index === 1) {
      let filteredSub = subscriptionList.filter((sub) => {
        return sub.is_active === "1";
      });
      setSubcriptions(filteredSub);
    }
    if (index === 0) {
      let filteredSub = subscriptionList.filter((sub) => {
        return sub.is_active === "0";
      });
      setSubcriptions(filteredSub);
    }
  };

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      <Button onClick={() => props.history.push("/new-subscription")}>
        {/* <Button onClick={() => props.history.push("/admin/new-subscription")}> */}
        <i className="ni ni-fat-add"></i> Add Subscription
      </Button>
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={HandleFilter.bind(this, 1)}>
            Active
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 0)}>
            Inactive
          </DropdownItem>
          <DropdownItem onClick={HandleFilter.bind(this, 2)}>
            Remove Filter
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <UncontrolledDropdown disabled={subscriptions.length > 0 ? false : true}>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExportView(e.target.value)}>
            Export View
          </DropdownItem>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export All
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );

  const actionsExport = useMemo(() => {
    const export_column = [
      "subscription_id",
      /* "userEmail", */
      "subType",
      "state",
      "county",
      "interval_type",
      "status",
      "amount",
      "override_amount",
      "formattedDate",
    ];
    return (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            subscriptions,
            export_column,
            props.generateUniqueId()
          )
        }
        onExportView={() =>
          DataTableSettings.downloadCSV(
            subscriptions.slice(0, rows),
            export_column,
            props.generateUniqueId()
          )
        }
      />
    );
  }, [props, subscriptions, rows]);

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Subscriptions", "Subscription List"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-circle-08"></i>
            </span>
            Subscription List
          </h1>
        </div>
        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <div className="list-filtered-table-block">
                    <DataTable
                      columns={columns}
                      data={DataTableSettings.filterItems(
                        subscriptions,
                        searchParam,
                        filterText
                      )}
                      pagination
                      paginationPerPage={DataTableSettings.paginationPerPage}
                      onChangeRowsPerPage={changeRowPerPage}
                      paginationRowsPerPageOptions={
                        DataTableSettings.paginationRowsPerPageOptions
                      }
                      actions={actionsExport}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                    />
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default withRouter(SubscriptionList);
