import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  API_GET_ALL_SAVED_CARD,
  API_PROFILE,
  API_PAYMENT_ACCOUNT_STORAGE_FORM,
} from "../../config/Api";
import AddNewPaymentMethod from "./AddNewPaymentMethod";
import * as card1 from "../../assets/img/card1.png";
import * as card2 from "../../assets/img/card2.png";
import * as card3 from "../../assets/img/card3.png";
import * as carddefault from "../../assets/img/carddefault.png";
import CCForm from "./CCForm";
const SavedPaymentMethod = (props) => {
  const { className } = props;
  const [savedCardList, setSavedCardList] = useState(props.savedCardList);
  const [modal, setModal] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState();
  const [clickType, setClickType] = useState();
  const [profileInfo, setProfileInfo] = useState();
  const [IframeUrl, setIframeUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    payment_method: null,
    company_name: null,
    billing_city: null,
    billing_state: null,
  });
  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    setSavedCardList(props.savedCardList);
  }, [props.savedCardList]);

  const HandleCardType = (cardType, payment_method) => {
    if (payment_method == "ach") {
      return "ACH";
    } else {
      if (cardType == 3) {
        return "AMEX";
      } else if (cardType == 4) {
        return "VISA";
      } else if (cardType == 5) {
        return "Mastercard";
      } else if (cardType == 6) {
        return "Discover";
      } else {
        return "CC-" + cardType;
      }
    }
  };
  const HandleCardImage = (cardType, payment_method) => {
    if (payment_method == "ach") {
      return <img alt="reiprintmail" src={card3.default} />;
    } else {
      if (cardType == 3) {
        return (
          <img
            alt="reiprintmail"
            className={
              selectedPayment && "info_id" in selectedPayment && selectedPayment
                ? "selected"
                : ""
            }
            src={carddefault.default}
          />
        );
      } else if (cardType == 4) {
        return <img alt="reiprintmail" src={carddefault.default} />;
      } else if (cardType == 5) {
        return <img alt="reiprintmail" src={card2.default} />;
      } else if (cardType == 6) {
        return <img alt="reiprintmail" src={card1.default} />;
      } else {
        return <img alt="reiprintmail" src={carddefault.default} />;
      }
    }
  };

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const fetchProfileInfo = () => {
    props
      .callRequest("GET", API_PROFILE + props.getUserInfo().user_id, true)
      .then((res) => {
        setProfileInfo(res.data);
        setFormData({
          ...formData,
          company_name: res.data.user_profile.imprint_company_name,
          billing_city: res.data.user_profile.imprint_return_city,
          billing_state: res.data.user_profile.imprint_return_state,
        });
      });
  };

  const setPrimaryCard = (item) => {
    Swal.fire({
      html: `<h4 style='color:red'>Do you want to set this your primary card ?</h4>`,
      showDenyButton: true,
      confirmButtonText: "yes",
      denyButtonText: "no",
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("PUT", `${API_GET_ALL_SAVED_CARD}`, true, {
            user_id: props.getUserInfo().user_id,
            info_id: item.info_id,
          })
          .then((res) => {
            Swal.fire({
              showConfirmButton: false,
              timer: 1000,
              icon: "success",
              title: "Updated successfully",
              // confirmButtonText: "yes",
              // denyButtonText: "no",
            });
            props.getAllSavedCard();
            setSelectedPayment();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  const setPaymentMethod = (detail) => {
    setSelectedPayment({ ...detail });
  };

  const getPaymentUI = (detail) => {
    if (detail.payment_method !== "ach") {
      let arr = [
        <li
          className={
            selectedPayment &&
            "info_id" in selectedPayment &&
            selectedPayment.info_id == detail.info_id
              ? "payment-selected"
              : ""
          }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <img
            alt="reiprintmail"
            className={
              selectedPayment && "info_id" in selectedPayment && selectedPayment
                ? "selected"
                : ""
            }
            src={carddefault.default}
          />
          <div>
            <h5>AMEX</h5>
            <p>Card ending in ... {detail.maskedNumber}</p>
            <p>Expires {detail.expirationDate}</p>
          </div>
        </li>,
        <li
          className={
            selectedPayment &&
            "info_id" in selectedPayment &&
            selectedPayment.info_id == detail.info_id
              ? "payment-selected"
              : ""
          }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <img alt="reiprintmail" src={carddefault.default} />
          <div>
            <h5>VISA</h5>
            <p>Card ending in ... {detail.maskedNumber}</p>
            <p>Expires {detail.expirationDate}</p>
          </div>
        </li>,
        <li
          className={
            selectedPayment &&
            "info_id" in selectedPayment &&
            selectedPayment.info_id == detail.info_id
              ? "payment-selected"
              : ""
          }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <img alt="reiprintmail" src={card2.default} />
          <div>
            <h5>MASTERCARD</h5>
            <p>Card ending in ... {detail.maskedNumber}</p>
            <p>Expires {detail.expirationDate}</p>
          </div>
        </li>,
        <li
          className={
            selectedPayment &&
            "info_id" in selectedPayment &&
            selectedPayment.info_id == detail.info_id
              ? "payment-selected"
              : ""
          }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <img alt="reiprintmail" src={card1.default} />
          <div>
            <h5>DISCOVER</h5>
            <p>Card ending in ... {detail.maskedNumber}</p>
            <p>Expires {detail.expirationDate}</p>
          </div>
        </li>,
      ];

      return parseInt(detail.cardType) - 3 in arr ? (
        arr[parseInt(detail.cardType) - 3]
      ) : (
        <li
          className={
            selectedPayment &&
            "info_id" in selectedPayment &&
            selectedPayment.info_id == detail.info_id
              ? "payment-selected"
              : ""
          }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <img alt="reiprintmail" src={carddefault.default} />
          <div>
            <h5>CC - {detail.cardType}</h5>
            <p>Card ending in ... {detail.maskedNumber}</p>
            <p>Expires {detail.expirationDate}</p>
          </div>
        </li>
      );
    }
  };

  const getPaymentAchUI = (detail) => {
    if (detail.payment_method == "ach") {
      return (
        <li
          className={
            selectedPayment &&
            "info_id" in selectedPayment &&
            selectedPayment.info_id == detail.info_id
              ? "payment-selected"
              : ""
          }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <img alt="reiprintmail" src={card3.default} />
          <div>
            <h5>ACH</h5>
            <p>Account ending in ... {detail.maskedNumber}</p>
            <p className="yellowColor">Pending</p>
          </div>
        </li>
      );
    }
  };

  const onClickAddCard = (clickType) => {
    setLoading(true);
    setClickType(clickType);
    let latestFormdata = {
      ...formData,
      user_id: props.getUserInfo().user_id,
      payment_method: clickType,
    };
    if (
      // latestFormdata.company_name !== "" &&
      latestFormdata.billing_state !== "" &&
      latestFormdata.billing_city !== ""
    ) {
      props
        .callRequest(
          "POST",
          API_PAYMENT_ACCOUNT_STORAGE_FORM,
          true,
          latestFormdata
        )
        .then((res) => {
          // window.addEventListener("message", receiveMessage, false);
          setIframeUrl(res.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });

      toggle();
    } else {
      Swal.fire({
        showConfirmButton: true,
        icon: "error",
        title: "Please set up billing address details in profile first.",
      });
      return false;
    }
  };

  const handleRemoveCard = (infoId, userId) => {
    Swal.fire({
      icon: "question",
      //title: `<h1 style='color:red'>Are you sure?</h1>`,
      html: `<h3 style='color:red'>Are you sure you want to remove this payment method?</h3>`,
      showDenyButton: true,
      confirmButtonText: "yes",
      denyButtonText: "no",
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest(
            "DELETE",
            `${API_GET_ALL_SAVED_CARD}?user_id=${userId}&&info_id=${infoId}`,
            true
          )
          .then((res) => {
            props.getAllSavedCard();
            setSelectedPayment();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };
  return (
    <>
      <div className="payment-methods-box">
        <Card>
          <CardHeader>
            <CardTitle tag="h2">Saved Payment Method</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="form-box">
              <Form>
                <FormGroup tag="fieldset">
                  {props.userBal && props.userBal == 1 ? (
                    <>
                      {savedCardList &&
                        savedCardList.map((item) => {
                          return (
                            <FormGroup key={Math.random()}>
                              <Label className="delete-saved-card-setting">
                                <div className="payment-method-name">
                                  <span>
                                    {item.payment_method == "cc"
                                      ? "Credit Card"
                                      : "ACH"}{" "}
                                    - {item.maskedNumber}
                                  </span>{" "}
                                  &nbsp;{" "}
                                </div>{" "}
                                <Button
                                  className="delete-user-cards"
                                  onClick={() =>
                                    handleRemoveCard(item.info_id, item.user)
                                  }
                                >
                                  <i className="fas fa-trash"></i>
                                </Button>
                              </Label>
                            </FormGroup>
                          );
                        })}
                      {savedCardList === null ? (
                        <Label>No Payment Method saved</Label>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    <>
                      <div className="paymentMethod">
                        <div
                          style={{
                            display: "flex",
                            padding: "5px",
                            margin: "5px",
                          }}
                        >
                          <div className="pmbox">
                            <ul>
                              {savedCardList &&
                                savedCardList.map((item) => {
                                  return getPaymentUI(item);
                                })}
                            </ul>
                            <div className="paymentfooter">
                              <Button onClick={() => onClickAddCard("cc")}>
                                <i className="fas fa-plus"></i> &nbsp; Add new
                                card
                              </Button>
                            </div>
                          </div>
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          <div className="pmbox">
                            <ul>
                              {savedCardList &&
                                savedCardList.map((item) => {
                                  return getPaymentAchUI(item);
                                })}
                            </ul>
                            <div className="paymentfooter">
                              <Button onClick={() => onClickAddCard("ach")}>
                                <i className="fas fa-plus"></i> &nbsp; Add new
                                bank
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {selectedPayment && (
                        <div className="pmbox payment-conform-box">
                          <div className="header-block">
                            <h4>Saved Payment Details</h4>
                            <div className="removed-button">
                              <Button
                                onClick={() =>
                                  handleRemoveCard(
                                    selectedPayment.info_id,
                                    selectedPayment.user
                                  )
                                }
                              >
                                <i className="fas fa-trash"></i>
                                <span>Remove</span>
                              </Button>
                            </div>
                          </div>
                          <div className="payment-card-details">
                            {HandleCardImage(
                              selectedPayment.cardType,
                              selectedPayment.payment_method
                            )}

                            <div>
                              <h5>
                                {HandleCardType(
                                  selectedPayment.cardType,
                                  selectedPayment.payment_method
                                )}
                              </h5>
                              <p>
                                Card ending in{" "}
                                {selectedPayment &&
                                  selectedPayment.maskedNumber}
                              </p>
                              <p className="yellowColor">
                                Expires{" "}
                                {selectedPayment &&
                                  selectedPayment.expirationDate}
                              </p>
                            </div>
                          </div>
                          <div className="payment-billing-address">
                            <h5>Billing Address</h5>
                            <div className="billing-form-block">
                              <Input
                                id="address"
                                name="Address"
                                placeholder="123 Street Name"
                                type="text"
                                value={
                                  selectedPayment &&
                                  selectedPayment.billing_address
                                }
                                readOnly
                              />
                              <div className="row">
                                <div className="col-sm-4">
                                  <Input
                                    id="cityName"
                                    name="cityName"
                                    placeholder="City Name"
                                    type="text"
                                    value={
                                      selectedPayment &&
                                      selectedPayment.billing_city
                                    }
                                    readOnly
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <Input
                                    id="State"
                                    name="State"
                                    placeholder="City Name"
                                    type="text"
                                    value={
                                      selectedPayment &&
                                      selectedPayment.billing_state
                                    }
                                    readOnly
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <Input
                                    id="pincode"
                                    name="pincode"
                                    placeholder="12345-123"
                                    type="text"
                                    value={
                                      selectedPayment &&
                                      selectedPayment.zip_code
                                    }
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <br />
                          {selectedPayment.is_primary == 1 ? (
                            <div>
                              <strong>Your Primary Card</strong>
                            </div>
                          ) : (
                            <div>
                              <label>
                                <input
                                  type="checkbox"
                                  onClick={() =>
                                    setPrimaryCard(selectedPayment)
                                  }
                                />
                                &nbsp; Set as primary card
                              </label>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className={className}
                    size="lg"
                  >
                    <ModalHeader toggle={toggle}>
                      {clickType == "ach" ? (
                        <div className="card-payment-header">
                          <span>ACH</span>
                          <span style={{ fontSize: "13px", color: "red" }}>
                            &nbsp; *please note that ACH takes 7 days to process
                            and may delay your mailing
                          </span>
                        </div>
                      ) : (
                        <div className="card-payment-header">
                          <span>Add Card </span> &nbsp;
                          <span style={{ fontSize: "13px", color: "red" }}>
                            *user agrees to a 3% processing fee
                          </span>
                        </div>
                      )}
                    </ModalHeader>
                    <ModalBody>
                      {loading ? (
                        <div>Loading...</div>
                      ) : (
                        <CCForm
                          {...props}
                          getAllSavedCard={props.getAllSavedCard}
                          formData={formData}
                          IframeUrl={IframeUrl}
                          clickType={clickType}
                          paymentToggle={toggle}
                        />
                      )}
                    </ModalBody>
                  </Modal>

                  {/* <Modal isOpen={modal} toggle={toggle}>
                    <AddNewPaymentMethod {...props} userToggle={toggle} />
                  </Modal> */}
                </FormGroup>
              </Form>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default SavedPaymentMethod;
