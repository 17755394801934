import React from "react";
import "./Subscriptions.css";
import {
  Breadcrumb,
  BreadcrumbItem,
  Card,
  Media,
  CardImg,
  CardBody,
  CardTitle,
} from "reactstrap";

const SelectTemplate = (props) => {
  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <div className="breadcrumb-block">
            <Breadcrumb>
              <BreadcrumbItem>Subsciptions</BreadcrumbItem>
              <BreadcrumbItem active>Select Template</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-active-40"></i>
            </span>
            Select Template
          </h1>
        </div>
        <div className="select-template-block">
          <div className="row">
            <div className="col-sm-8 col-lg-10">
              <div className="template-list-block">
                <div className="row no-gutters">
                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media
                        href="#"
                        onClick={() => {
                          props.history.push("/template-preview");
                        }}
                      >
                        {/* <Media href="#" onClick={()=>{props.history.push('/admin/template-preview')}}> */}
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template1.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS2</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template2.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS3</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template3.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS4</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template4.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS5</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template5.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS6</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template6.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS7</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template7.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS8</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template1.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS2</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template2.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS3</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template3.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS4</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template4.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS5</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template5.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS6</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template6.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS7</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>

                  <div className="col-sm-4 col-lg-3">
                    <div className="template-box">
                      <Media href="#">
                        <Card>
                          <CardImg
                            top
                            width="100%"
                            src={
                              require("../../assets/img/template7.png").default
                            }
                            alt="template"
                          />
                          <CardBody>
                            <CardTitle tag="h5">TAPCS8</CardTitle>
                          </CardBody>
                        </Card>
                      </Media>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-4 col-lg-2">
              <div className="select-template-right-block">Right Sidebar</div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SelectTemplate;
