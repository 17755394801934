import setProfile from "./user";
import getUserApp from "./userApp";
import getUserRolePermission from "./userRolePermission";
import getUserType from "./userType";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
  setProfile,
  getUserApp,
  getUserRolePermission,
  getUserType,
});

export default rootReducer;
