import React, { useEffect, useState, useRef } from "react";
import { BreadCrumb } from "../../config/Components";
import classnames from "classnames";
import "../../assets/css/importTemplate.css";
import {
  PropertyInformation,
  PrimaryContact,
  SecondaryContact,
} from "../../config/Components";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Table,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
} from "reactstrap";
import DragDrop from "./../DragDrop/DragDrop";
import Swal from "sweetalert2";
import {
  API_MAP_MAILINGLIST,
  API_GET_MAILINGLIST_BLANK_FIELD,
  API_GET_MAILINGLIST_CONTRACTS,
  API_MAILINGLIST_DUPLICATE_REMOVE,
  API_GET_MAILINGLIST_DUPLICATE_RECORD,
  API_POST_MAILINGLIST_CONTACT_REMOVE,
  API_GET_MAILING_DETAILS_BY_MAP_ID,
  API_GET_MAILINGLIST_MAP_META,
  API_GET_MAILINGLIST_TEMPLATE,
} from "./../../config/Api";

const MailingListCrmContacts = (props) => {
  const [contractPageNo, setContractPageNo] = useState(1);
  const [stopRedirection, setStopRedirection] = useState(props.stopRedirection);
  const onscrollRef = useRef();
  const [activeTab, setActiveTab] = useState("Property Information");
  const [notificationModal, setNotificationModal] = useState(false);
  const [notificationModal1, setNotificationModal1] = useState(false);
  const [notificationModal2, setNotificationModal2] = useState(false);
  const [notificationModal3, setNotificationModal3] = useState(false);
  const [notificationModal4, setNotificationModal4] = useState(false);
  const [csvData, setCsvData] = useState(props.csvData);
  const [mappedData, setMappedData] = useState();
  const [campaignContractList, setCampaignContractList] = useState();
  const [campaignBlankFieldList, setCampaignBlankFieldList] = useState();
  const [duplicateCheckBy, setDuplicateCheckBy] = useState();
  const [campaignDuplicateDetail, setCampaignDuplicateDetail] = useState();
  const [campaignTotalRecord, setCampaignTotalRecord] = useState(0);
  const [formCampaignTotalRecord, setFormCampaignTotalRecord] = useState(0);
  const [csvValidationSection, setCsvValidationSection] = useState({
    // property_address: false,
    // property_city: false,
    // //property_county: false,
    // property_state: false,
    // property_zip: false,
    // mailing_city: false,
    // mailing_state: false,
    // mailing_Zip: false,
    // mailing_address: false,
    // mailing_address2: false,
    // first_name: false,
    // last_name: false,
    // company: false,
    full_name: false,
    mailing_street: false,
    property_address: false,
    property_city: false,

    //property_county: false,
    property_street: false,
    property_state: false,
    property_zip: false,
    // property_country: true,
    // offer_amount: false,
    // marketplace_value: false,
    // offer_percentage: false,
    // offer_code: false,
    // response_date: false,

    mailing_city: false,
    mailing_state: false,
    mailing_Zip: false,
    mailing_address: false,
    mailing_address2: false,
  });
  const [loadMoreStatus, setLoadMoreStatus] = useState(true);
  const [propertyLeadPreview, setPropertyLeadPreview] = useState();
  const [templateList, setTemplateList] = useState();
  const [templateId, setTemplateId] = useState();
  const [processTemplateData, setProcessTemplateData] = useState();
  console.log(mappedData, "campaignContractList");
  const handelScroll = (e) => {
    if (
      onscrollRef.current.scrollTop + onscrollRef.current.clientHeight >=
        onscrollRef.current.scrollHeight &&
      loadMoreStatus
    ) {
      props
        .callRequest(
          "GET",
          `${API_GET_MAILINGLIST_CONTRACTS}${csvData.mailinglist_id}/?page_number=${contractPageNo}`,
          true
        )
        .then((res) => {
          if (res.data.length > 0) {
            setContractPageNo(contractPageNo + 1);
            setCampaignContractList([...campaignContractList, ...res.data]);
          } else {
            setLoadMoreStatus(false);
          }
        })
        .catch((error) => {
          setLoadMoreStatus(false);
        });
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleNext = () => {
    //props.history.push('/admin/create-campaign');
    const formData = new FormData();
    formData.append("mailinglist_id", csvData.mailinglist_id);
    formData.append("total_records", formCampaignTotalRecord);

    props
      .callRequest(
        "POST",
        `${API_POST_MAILINGLIST_CONTACT_REMOVE}`,
        true,
        formData
      )
      .then((res) => {
        toggleNotification4();

        if (!stopRedirection) {
          props.resetSavedCampaign();
          props.history.push("/campaigns");
          // props.history.push("/admin/campaigns");
        }
      })
      .catch((error) => {
        Swal.close();
      });
  };

  const toggleNotification = () => setNotificationModal(!notificationModal);
  const toggleNotification1 = () => setNotificationModal1(!notificationModal1);
  const toggleNotification2 = () => setNotificationModal2(!notificationModal2);
  const toggleNotification3 = () => setNotificationModal3(!notificationModal3);
  const toggleNotification4 = () => setNotificationModal4(!notificationModal4);

  const handleNext1 = () => {
    toggleNotification();
    toggleNotification1();
  };

  const handleNext2 = () => {
    toggleNotification1();
    toggleNotification2();
  };

  const handleNext3 = (typeVale) => {
    Swal.fire({
      title: "Processing your request, Please wait....",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    //get duplicate Record
    toggleNotification2();
    setDuplicateCheckBy(typeVale);

    props
      .callRequest(
        "GET",
        `${API_GET_MAILINGLIST_DUPLICATE_RECORD}?mailinglist_id=${csvData.mailinglist_id}&duplicate_check_by=${typeVale}`,
        true
      )
      .then((res) => {
        if (res.status === 200) {
          Swal.close();
        }
        setCampaignDuplicateDetail(res.data);
        toggleNotification3();
      })
      .catch((error) => {
        Swal.close();
      });
  };

  const handleNext4 = (statusBy) => {
    toggleNotification3();
    Swal.fire({
      title: "Processing your request, Please wait....",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    const formData = new FormData();
    formData.append("mailinglist_id", csvData.mailinglist_id);
    formData.append("duplicate_check_by", duplicateCheckBy);
    formData.append("is_removed", statusBy);

    props
      .callRequest(
        "POST",
        `${API_MAILINGLIST_DUPLICATE_REMOVE}`,
        true,
        formData
      )
      .then((res) => {
        //toggleNotification4();
        setCampaignTotalRecord(res.data.total_records);
        setFormCampaignTotalRecord(res.data.total_records);
        if (res.status === 200) {
          Swal.close();
        }

        if (!stopRedirection) {
          props.resetSavedCampaign();
          props.history.push("/mailing-list");
          // props.history.push("/admin/mailing-list");
        }
      })
      .catch((error) => {
        Swal.close();
      });
  };

  const getMapData = (processCsvData) => {
    setMappedData(processCsvData);
  };

  const processCSVMapping = () => {
    let tempcsvValidationSection = { ...csvValidationSection };
    const formData = new FormData();
    formData.append("mailinglist_id", csvData.mailinglist_id);
    let validationStatus = true;
    let notMappedField = "";
    mappedData.map((item) => {
      item.nav_data.map((inItem) => {
        if (
          inItem.item_value &&
          csvData["headers"].indexOf(inItem.item_value) < 0
        ) {
          notMappedField += "<li>" + inItem.item_value + "</li>";
        }

        if (
          inItem.api_key in tempcsvValidationSection &&
          inItem.item_value == null
        ) {
          tempcsvValidationSection[inItem.api_key] = true;
        } else if (
          inItem.api_key in tempcsvValidationSection &&
          inItem.item_value != null
        ) {
          tempcsvValidationSection[inItem.api_key] = false;
        }

        formData.append(
          inItem.api_key,
          inItem.item_value == null ? "" : inItem.item_value
        );
      });
    });

    // if (
    //   mappedData[1]["nav_data"][16]["item_value"] != null ||
    //   mappedData[1]["nav_data"][17]["item_value"] != null
    // ) {
    //   tempcsvValidationSection["address2"] = false;
    //   tempcsvValidationSection["address"] = false;
    // }

    setCsvValidationSection({ ...tempcsvValidationSection });
    validationStatus =
      Object.values(tempcsvValidationSection).filter(
        (item) => item == true
      )[0] == true
        ? false
        : true;

    if (notMappedField != "") {
      Swal.fire({
        title: "Template Maping Error !!",
        icon: "error",
        html:
          "<div style='text-align:left'><p>Please remove all the below invalid header and mapped with the correct one from right side of the Drag and Drop section.</p>" +
          "<ul style='margin-top:10px; margin-left:20px;'>" +
          notMappedField +
          "</ul></div>",
        showCloseButton: true,
        showCancelButton: false,
        focusConfirm: false,
      });
      return false;
    }

    if (validationStatus == false) {
      Swal.close();
      Swal.fire("Validation", "Please fill all the required value", "error");
      return false;
    }

    Swal.fire({
      title: "CSV Mapping is processing, Please wait....",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    props
      .callRequest("POST", `${API_MAP_MAILINGLIST}`, true, formData)
      .then((res) => {
        getCampaignContracts();
      })
      .catch((error) => {
        Swal.close();
      });
  };

  const getCampaignContracts = () => {
    //contractPageNo
    props
      .callRequest(
        "GET",
        `${API_GET_MAILINGLIST_CONTRACTS}${csvData.mailinglist_id}/?page_number=${contractPageNo}`,
        true
      )
      .then((res) => {
        Swal.close();
        setContractPageNo(contractPageNo + 1);
        setCampaignContractList(res.data);
        toggleNotification();
      })
      .catch((error) => {});
  };

  const getBlankField = () => {
    Swal.fire({
      title: "Processing your request, Please wait....",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    var letInterval = setInterval(function () {
      props
        .callRequest(
          "GET",
          `${API_GET_MAILINGLIST_BLANK_FIELD}${csvData.mailinglist_id}/`,
          true
        )
        .then((res) => {
          clearInterval(letInterval);
          toggleNotification();
          console.log(res.data.length, "res.data.legth");
          if (res.status === 200) {
            Swal.close();
          }
          if (res.data.length > 0) {
            setCampaignBlankFieldList(res.data);
            toggleNotification1();
          } else {
            toggleNotification2();
          }
        })
        .catch((error) => {});
    }, 1000);
  };

  const resetSavedCampaign = () => {
    setMappedData();
    setCampaignContractList();
    setCampaignBlankFieldList();
    setDuplicateCheckBy();
    setCampaignDuplicateDetail();
    setCampaignTotalRecord(0);
    setFormCampaignTotalRecord(0);
    setCsvValidationSection({
      property_address: false,
      property_city: false,
      property_county: false,
      property_state: false,
      property_zip: false,
      city: false,
      state: false,
      Zip: false,
    });
    setLoadMoreStatus(true);
    props.resetSavedCampaign();
  };

  const getPropertyLeadPreviewAction = (map_id) => {
    props
      .callRequest(
        "GET",
        `${API_GET_MAILING_DETAILS_BY_MAP_ID}${map_id}/`,
        true
      )
      .then((res) => {
        setPropertyLeadPreview(res.data);
      })
      .catch((error) => {
        console.log("Map detail error", error);
      });
  };

  const cancelAction = () => {
    props.history.push("/mailing-list");
    // props.history.push("/admin/mailing-list");
  };

  const nextBtnAction = (e) => {
    if (activeTab == "Property Information") {
      setActiveTab("Primary Contact");
    } else if (activeTab == "Primary Contact") {
      processCSVMapping(e);
    }
    //   setActiveTab("Custom Fields");
    // } else if (activeTab == "Custom Fields") {
    //   processCSVMapping(e);
    // }
  };

  const getImportTemplate = () => {
    //API_GET_CAMPAIGN_TEMPLATEAPI_GET_CAMPAIGN_TEMPLATE
    props
      .callRequest("GET", `${API_GET_MAILINGLIST_TEMPLATE}`, true)
      .then((res) => {
        setTemplateList(res.data);
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const processTemplate = () => {
    if (!templateId) return false;
    //get Map Meta
    Swal.fire({
      title: "Processing template, Please wait....",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    props
      .callRequest("GET", `${API_GET_MAILINGLIST_MAP_META}${templateId}`, true)
      .then((res) => {
        setProcessTemplateData(res.data);
        Swal.close();
      })
      .catch((error) => {
        console.log("Error", error);
        Swal.close();
      });
  };

  useEffect(() => {
    getImportTemplate();
  }, []);

  return (
    <>
      <div className="import-template-main-block">
        <Card>
          <CardBody>
            <div className="select-import-template">
              <div className="import-template-form">
                <Form>
                  <FormGroup>
                    <Label for="exampleSelect">Import Template:</Label>
                    <div className="add-new-template-box">
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        onChange={(e) => setTemplateId(e.target.value)}
                      >
                        <option value="">Select a Import Template</option>
                        {templateList &&
                          templateList.map((item) => {
                            return (
                              item.mailinglist_name && (
                                <option value={item.mailinglist_id}>
                                  {item.mailinglist_name}
                                </option>
                              )
                            );
                          })}
                      </Input>
                      <Button type="button" onClick={() => processTemplate()}>
                        Add New Template
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </div>
              <div className="next-button">
                <Button
                  variant="danger"
                  color="danger"
                  onClick={(e) => cancelAction(e)}
                >
                  Cancel
                </Button>
                <Button onClick={(e) => nextBtnAction(e)}>Next</Button>
              </div>
            </div>
          </CardBody>
        </Card>

        <DragDrop
          csvData={csvData}
          getMapData={getMapData}
          csvValidationSection={csvValidationSection}
          activeTab={activeTab}
          processTemplateData={processTemplateData}
        />

        <Modal
          isOpen={notificationModal}
          className="notification-modal cntacts-list-modal"
        >
          <ModalBody>
            <Row>
              <Col xs="7">
                <div className="cntacts-list-tabel">
                  <Card>
                    <CardBody>
                      <div
                        ref={onscrollRef}
                        style={{
                          maxHeight: "345px",
                          overflowY: "auto",
                        }}
                        onScroll={(e) => handelScroll(e)}
                      >
                        <Table>
                          <thead>
                            <tr>
                              <th>Address</th>
                              <th>City</th>
                              <th>State</th>
                              <th>Primary Contact</th>
                            </tr>
                          </thead>
                          <tbody>
                            {campaignContractList &&
                              campaignContractList.map((item) => {
                                return (
                                  <tr
                                    onClick={() =>
                                      getPropertyLeadPreviewAction(item.map_id)
                                    }
                                  >
                                    <td>{item.mailing_street}</td>
                                    <td>{item.mailing_city}</td>
                                    <td>{item.mailing_state}</td>
                                    <td>{item.phone1}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col xs="5">
                <div className="cntacts-list-details">
                  <Card>
                    <CardHeader>Property Lead Preview</CardHeader>
                    <CardBody>
                      <ul className="cntacts-list">
                        {mappedData &&
                          mappedData.map((item) => {
                            return item.nav_data.map((inItem) => {
                              return (
                                <li>
                                  <b>{inItem.api_key}:</b>{" "}
                                  {propertyLeadPreview &&
                                    inItem.api_key in propertyLeadPreview &&
                                    propertyLeadPreview[inItem.api_key]}
                                </li>
                              );
                            });
                          })}
                      </ul>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <div className="modal-button-block">
              <Button color="secondary" onClick={() => cancelAction()}>
                Cancel
              </Button>
              <Button color="primary" onClick={() => getBlankField()}>
                Confirm <i className="ni ni-bold-right"></i>
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={notificationModal1} className="notification-modal">
          <ModalHeader toggle={toggleNotification1}>Missing Data</ModalHeader>
          <ModalBody>
            <div className="notification-popup-block">
              <h4 className="sub-titel">
                Your CSV file is missing data for the following fieldss
              </h4>
              <ul className="missing-list">
                {campaignBlankFieldList &&
                  campaignBlankFieldList.map((item) => {
                    return <li>{item}</li>;
                  })}
              </ul>
              <p className="info-text">
                Please be advised if there is missing data for the mailing
                address or property addresses this will be reflected on your
                mail pieces. If you are oky with this click proceed. Otherwise,
                you will need to choose a new list with all data completed for
                those fields.
              </p>
            </div>
            <div className="modal-button-block">
              <Button color="secondary" onClick={() => cancelAction()}>
                Cancel
              </Button>
              <Button color="primary" onClick={handleNext2}>
                Agree and Proceed <i className="ni ni-bold-right"></i>
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={notificationModal2} className="notification-modal">
          <ModalHeader toggle={toggleNotification2}>
            Remove Duplicates
          </ModalHeader>
          <ModalBody>
            <div className="notification-popup-block">
              <h4 className="sub-titel">
                Please choose duplicate check option.
              </h4>
            </div>
            <div className="modal-button-block">
              <Button color="primary" onClick={() => handleNext3(0)}>
                Check Mailing Addresses <i className="ni ni-bold-right"></i>
              </Button>
              <Button color="primary" onClick={() => handleNext3(1)}>
                Check Property Addresses <i className="ni ni-bold-right"></i>
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={notificationModal3} className="notification-modal">
          <ModalHeader toggle={toggleNotification3}>
            Duplicate Results
          </ModalHeader>
          <ModalBody>
            <div className="notification-popup-block">
              <h4 className="sub-titel">
                Your list has duplicate mailing addresses.
              </h4>
              <ul className="list">
                <li>
                  <b>Original Records:</b>{" "}
                  {campaignDuplicateDetail &&
                    campaignDuplicateDetail.total_records}
                </li>
                <li>
                  <b>Duplicate Records:</b>{" "}
                  {campaignDuplicateDetail &&
                    campaignDuplicateDetail.duplicate_records}
                </li>
                <li>
                  <b>Unique Records:</b>{" "}
                  {campaignDuplicateDetail &&
                    campaignDuplicateDetail.unique_records}
                </li>
                <li>
                  <b>Invalid Records:</b>{" "}
                  {campaignDuplicateDetail &&
                    campaignDuplicateDetail.invalid_records}
                </li>
              </ul>
              <p className="sub-titel">Would you like to remove them?</p>
            </div>
            <div className="modal-button-block">
              <Button color="primary" onClick={() => handleNext4(1)}>
                Yes{" "}
              </Button>
              <Button color="primary" onClick={() => handleNext4(0)}>
                No
              </Button>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={notificationModal4} className="notification-modal">
          <ModalHeader toggle={toggleNotification4}>Amount To Send</ModalHeader>
          <ModalBody>
            <div className="notification-popup-block">
              <h4 className="sub-titel">
                Your list has <b>{campaignTotalRecord}</b> Remaining Records
              </h4>
              <div className="input-box">
                <FormGroup>
                  <Label for="enter-desired-quantity">
                    How many records would you like to send with this campaign?
                  </Label>
                  <Input
                    type="text"
                    name="enter-desired-quantity"
                    id="enter-desired-quantity"
                    placeholder="Enter desired quantity"
                    value={formCampaignTotalRecord}
                    autoComplete="off"
                    onChange={(e) => setFormCampaignTotalRecord(e.target.value)}
                  />
                </FormGroup>
              </div>
              <p className="info-text">
                If quantity entered is less than list provided than remaining
                records we will be pulled in succession starting with the first
                one, all additional records will not be mailed.
              </p>
            </div>
            <div className="modal-button-block">
              <Button color="primary" onClick={() => cancelAction()}>
                {" "}
                Cancel
              </Button>
              <Button color="primary" onClick={handleNext}>
                Next <i className="ni ni-bold-right"></i>
              </Button>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default MailingListCrmContacts;
