import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import ResizableNonEditableAccupixStreetview from "./ResizableNonEditableAccupixStreetview";

let default_style = {
  width: "100%",
  height: "100%",
  display: "flex",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
  padding: "0",
  margin: "0",
  border: "none",
};

const NonEditableAccupixStreetviewComponent = (props) => {
  const [top, setTop] = useState(props.top);
  const [left, setLeft] = useState(props.left);
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const [rotateAngle] = useState(props.rotateAngle);
  const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
  const [roundedCorner, setRoundedCorner] = useState(props.rounded_corner);
  const [margin_top, setMarginTop] = useState(props.margin_top);
  const [margin_left, setMarginLeft] = useState(props.margin_left);
  const [padding_top, setPaddingTop] = useState(props.padding_top);
  const [padding_right, setPaddingRight] = useState(props.padding_right);
  const [padding_bottom, setPaddingBottom] = useState(props.padding_bottom);
  const [padding_left, setPaddingLeft] = useState(props.padding_left);
  const [marginFlag, setMarginFlag] = useState(props.marginFlag);
  const [aspect_ratio_width, setAspectRatioWidth] = useState(
    props.aspect_ratio_width
  );
  const [aspect_ratio_height, setAspectRatioHeight] = useState(
    props.aspect_ratio_height
  );
  const [aspectRatioFlag, setAspectRatioFlag] = useState(props.aspectRatioFlag);
  const [z_index, setZIndex] = useState(props.z_index);
  const [accupix_streetview_lock, setAccupixStreetviewLock] = useState(props.accupix_streetview_lock);

  useEffect(() => {
    setBackgroundColor(props.backgroundColor);
    setRoundedCorner(props.rounded_corner);
    setMarginTop(props.margin_top);
    setMarginLeft(props.margin_left);
    setPaddingTop(props.padding_top);
    setPaddingRight(props.padding_right);
    setPaddingBottom(props.padding_bottom);
    setPaddingLeft(props.padding_left);
    setTop(props.top);
    setLeft(props.left);
    setWidth(props.width);
    setHeight(props.height);
    setMarginFlag(props.marginFlag);
    setAspectRatioWidth(props.aspect_ratio_width);
    setAspectRatioHeight(props.aspect_ratio_height);
    setAspectRatioFlag(props.aspectRatioFlag);
    setZIndex(props.z_index);
    setAccupixStreetviewLock(props.accupix_streetview_lock);
  }, [
    props.backgroundColor,
    props.rounded_corner,
    props.margin_top,
    props.margin_left,
    props.padding_top,
    props.padding_right,
    props.padding_bottom,
    props.padding_left,
    props.top,
    props.left,
    props.width,
    props.height,
    props.marginFlag,
    props.aspect_ratio_width,
    props.aspect_ratio_height,
    props.aspectRatioFlag,
    props.z_index,
    props.accupix_streetview_lock,
  ]);

  function setNonEditableAccupixStreetviewDesign(
    htmldata,
    id,
    left,
    top,
    width,
    height,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    aspect_ratio_width,
    aspect_ratio_height,
    z_index
  ) {
    props.parentSetNonEditableAccupixStreetviewDesign(
      htmldata,
      id,
      left,
      top,
      width,
      height,
      props.title,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      resize,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_ratio_width,
      aspect_ratio_height,
      z_index
    );
  }

  return (
    <ResizableNonEditableAccupixStreetview
      top={top}
      left={left}
      width={width}
      height={height}
      rotateAngle={rotateAngle}
      backgroundColor={backgroundColor}
      roundedCorner={roundedCorner}
      margin_top={margin_top}
      margin_left={margin_left}
      padding_top={padding_top}
      padding_right={padding_right}
      padding_bottom={padding_bottom}
      padding_left={padding_left}
      marginFlag={marginFlag}
      aspect_ratio_width={aspect_ratio_width}
      aspect_ratio_height={aspect_ratio_height}
      aspectRatioFlag={aspectRatioFlag}
      z_index={z_index}
      accupix_streetview_lock={accupix_streetview_lock}
      parentSetNonEditableAccupixStreetviewDesign={
        setNonEditableAccupixStreetviewDesign
      }
      id={props.id}
      title={props.title}
    >
      <div style={default_style}>
        <img
          alt="streetview.png"
          className="accupix_streetview_class"
          src={
            process.env.REACT_APP_ACCUPIX_URL_PHOTOPATH +
            "media/static/streetview.png"
          }
          style={default_style}
          title="accupix"
        />
      </div>
    </ResizableNonEditableAccupixStreetview>
  );
};

export default NonEditableAccupixStreetviewComponent;
