import React from "react";
import "./assets/css/TemplateDesign.css";

const DataMatrixComponentLeft = (props) => {
  return (
    <div className="custom_text_full">
      <p dangerouslySetInnerHTML={{ __html: props.title }}></p>

      {/* <button
        className="btn btn-delete"
        onClick={() => props.parentDeleteDataMatrix(props.id)}
      >
        Delete
      </button> */}
    </div>
  );
};

export default DataMatrixComponentLeft;
