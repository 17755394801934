import React, { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import { BreadCrumb } from "../../config/Components";
import "./Orders.css";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import "../../assets/css/data-table.css";
import {
  API_GET_BATCH_PREVIEW,
  API_DELETE_PREVIEW_BATCH,
  API_CREATE_BATCH_PRODUCTION,
  API_GET_BATCH_CSV_UPLOAD,
} from "../../config/Api";
import { BatchPreviewDetails } from "../../config/Components";
import moment from "moment"

const BatchPreview = (props) => {
  const [batchPreview, setBatchPreview] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [rows, setRows] = useState(10);
  const [uplodedFile, setUplodedFile] = useState("");
  const userType = useSelector((store) => store.getUserType);
  const searchParam = [
    "batch_number",
    "category",
    "envelope",
    "ink",
    "paper",
    "postage",
    "quantity",
    "size",
  ];
  const [checkBatch, setCheckBatch] = useState([]);
  const [toggledClearRows, setToggleClearRows] = useState(false);

  useEffect(() => {
    fetchBatchPreview();
  }, []);

  const fetchBatchPreview = () => {
    props.callRequest("GET", API_GET_BATCH_PREVIEW, true, null).then((res) => {
      setBatchPreview(res.data);
    });
  };

  const upload_csv = (event, batch_id) => {
    event.preventDefault();
    setUplodedFile(event.target.files[0].name);

    if (
      event.target.files[0].name === "" ||
      event.target.files[0].name === undefined
    ) {
      Swal.fire({
        title: "enter valid csv file",
      });
      return false;
    } else {
      var file = event.target.files[0];
      const formData = new FormData();
      formData.append("uplodedFile", file, event.target.files[0].name);
      formData.append("batch_id", batch_id);
      props
        .callRequest("POST", API_GET_BATCH_CSV_UPLOAD, true, formData)
        .then((res) => {
          Swal.fire({
            title: "csv uploaded successfully!!",
            html: "Processing may take 5-10 minutes.",
          });
        })
        .catch((error) => {
          Swal.fire({
            title: "Failed, Something went wrong!!",
          });
        });
    }
  };

  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return moment(d).format('MM/DD/YYYY')
    // return d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear();
  };

  const onDelete = (batch_id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete preview batch?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("POST", API_DELETE_PREVIEW_BATCH, true, {
            batch_id: batch_id,
          })
          .then((res) => {
            if (res.status) {
              fetchBatchPreview();
              Swal.fire("Preview batch deleted!", "", "success");
            }
          });
      }
    });
  };

  const columns = [
    {
      name: "Process Status",
      selector: "process_status",
      sortable: true,
      cell: (row) => {
        return (
          <>
            <i
              className="fas fa-circle"
              style={{ color: row.process_status == "1" ? "green" : "orange" }}
            ></i>
          </>
        );
      },
    },
    {
      name: "Batch ID",
      selector: "batch_number",
      sortable: true,
    },
    {
      name: "Category",
      selector: "category",
      sortable: true,
    },
    {
      name: "Size",
      selector: "size",
      sortable: true,
    },
    {
      name: "Paper Description",
      selector: "paper",
      sortable: true,
    },
    {
      name: "Ink",
      selector: "ink",
      sortable: true,
    },
    {
      name: "Postage",
      selector: "postage",
      sortable: true,
    },
    {
      name: "Envelope Description",
      selector: "envelope",
      sortable: true,
    },
    {
      name: "Quantity",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "Date In",
      selector: "date_in",
      sortable: true,
      cell: (row) => {
        return dateFormater(row.date_in);
      },
    },
    {
      name: "Date Due",
      selector: "due_date",
      sortable: true,
      cell: (row) => {
        return row.due_date == "" || row.due_date == null
          ? "--/--/--"
          : dateFormater(row.due_date);
      },
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <div>
          {row.status == 0
            ? "Ready for production"
            : row.status == 1
            ? "Completed"
            : row.status == 2
            ? "Cancelled"
            : row.status == 3
            ? "Preview"
            : "Deleted"}
        </div>
      ),
    },
    {
      name: userType && userType == 2 ? null : "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) =>
        userType && userType == 2 ? null : (
          <div className="action-div">
            <span
              className="upload-batch-preview-csv"
              // style={{
              //   width: "16px",
              //   height: "16px",
              //   display: "inline-block",
              //   position: "relative",
              //   color: "#545cd8",
              // }}
            >
              <i className="fas fa-file-upload"></i>

              <input
                type="file"
                id="uplodedFile"
                name="uplodedFile"
                accept=".csv"
                className="btn custom-file-input"
                style={{
                  opacity: "0",
                  top: "0",
                  left: "0",
                  padding: "0",
                  margin: "0",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
                onChange={(e) => upload_csv(e, row.batch_id)}
              ></input>
            </span>
            <span className="csv-download-btn">
              <a title="Download csv" href={row.preview_csv_file} download>
                <i className="fas fa-file-download"></i>
              </a>
            </span>
            <span
              className="delete-batch-btn"
              title="Delete preview batch"
              onClick={() => onDelete(row.batch_id)}
            >
              <i className="fas fa-trash"></i>
            </span>
          </div>
        ),
    },
  ];

  const rowDisabledCriteria = (row) => row.process_status === "1";

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Product"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          autoComplete="off"
        />
      </FormGroup>
    );
  }, [filterText]);

  const export_column = [
    "batch_number",
    "category",
    "size",
    "paper",
    "ink",
    "postage",
    "envelope",
    "quantity",
  ];

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      {/* {userType && userType == 2 ? null : (
                <Button onClick={() => props.history.push("/order-batching")}>
                    <i className="ni ni-fat-add"></i> Create Batch
                </Button>
            )} */}
      {/* <UncontrolledDropdown>
         <DropdownToggle caret>
                    <i className="bi-filter"></i> Filter
                </DropdownToggle>
      </UncontrolledDropdown> */}
      {userType && userType == 2 ? null : (
        <UncontrolledDropdown disabled={batchPreview.length > 0 ? false : true}>
          <DropdownToggle caret>
            <i className="ni ni-ungroup"></i> Export
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={(e) => onExportView(e.target.value)}>
              Export View
            </DropdownItem>
            <DropdownItem onClick={(e) => onExport(e.target.value)}>
              Export All
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      )}
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            batchPreview,
            export_column,
            props.generateUniqueId()
          )
        }
        onExportView={() =>
          DataTableSettings.downloadCSV(
            batchPreview.slice(0, rows),
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [batchPreview]
  );

  const handleChange = ({ selectedRows }) => {
    let tempData = [];
    for (let i = 0; i < selectedRows.length; i++) {
      var temp_bath_id = selectedRows[i].batch_id;
      tempData.push(temp_bath_id);
    }
    setCheckBatch(tempData);
  };

  const contextActions = useMemo(() => {
    const handleCreate = () => {
      props
        .callRequest("POST", API_CREATE_BATCH_PRODUCTION, true, {
          batch_id: checkBatch,
        })
        .then((res) => {
          if (res.status) {
            setToggleClearRows(!toggledClearRows);
            Swal.fire(
              "Production batch processing has been started.",
              "",
              "success"
            );
            fetchBatchPreview();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    return (
      <Button
        key="create"
        onClick={handleCreate}
        style={{ backgroundColor: "blue" }}
      >
        <i className="ni ni-fat-add"></i> Create Batch
      </Button>
    );
  }, [batchPreview, checkBatch]);

  return (
    <div>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order Batching"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            Preview Batching
          </h1>
        </div>
        <div className="listingtable-main-block batch-preview-table">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                <DataTable
                  title={" "}
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    batchPreview,
                    searchParam,
                    filterText
                  )}
                  pagination
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  actions={actionsExport}
                  selectableRows
                  contextActions={contextActions}
                  onSelectedRowsChange={handleChange}
                  clearSelectedRows={toggledClearRows}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  expandableRows
                  expandableRowsComponent={
                    <BatchPreviewDetails
                      {...props}
                      fetchBatchPreview={fetchBatchPreview}
                    />
                  }
                  selectableRowDisabled={rowDisabledCriteria}
                />
              </div>
              <div className="status-infoblock">
                <div className="statusinfo-box">
                  <span className="status-round all-jobs"></span>
                  Working Progress
                </div>
                <div className="statusinfo-box">
                  <span className="status-round on-hold"></span>
                  Not Started
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </div>
  );
};

export default BatchPreview;
