import { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";
import { API_INTERVALS } from "../../config/Api";

const IntervalList = (props) => {
  const [loading, setLoading] = useState(true);
  const [intervals, setIntervals] = useState([]);
  const [filterText, setFilterText] = useState("");
  const searchParam = ["interval_name"];

  useEffect(() => {
    fetchIntervals();
  }, []);

  const fetchIntervals = async () => {
    props.callRequest("GET", API_INTERVALS, true).then((res) => {
      setIntervals(res.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      name: "Interval Name",
      selector: "interval_name",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "interval_id",
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => editInterval(row.interval_id)}>
              Edit
            </DropdownItem>
            <DropdownItem onClick={() => onDelete(row.interval_id)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const editInterval = (interval_id) => {
    props.history.push("/edit-interval/" + interval_id);
    // props.history.push("/admin/edit-interval/" + interval_id);
  };

  const onDelete = (interval_id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("DELETE", API_INTERVALS + interval_id, true)
          .then((res) => {
            // if (res.statusText === "No Content") {
				if (res.status) {
              fetchIntervals();
              Swal.fire("Deleted!", "", "success");
            }
          });
      }
    });
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = ({ onExport }) => (
    <div className="right-button-block">
      <Button onClick={() => props.history.push("/interval-add")}>
        {/* <Button onClick={() => props.history.push("/admin/interval-add")}> */}
        <i className="ni ni-fat-add"></i> Add Interval
      </Button>
      {/* <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>Filter Name 1</DropdownItem>
          <DropdownItem>Filter Name 2</DropdownItem>
          <DropdownItem>Filter Name 3</DropdownItem>
          <DropdownItem>Filter Name 4</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      <Button><i className="bi-pencil-square"></i> Edit Columns</Button> */}
      {/* <Button
        disabled={intervals.length > 0 ? false : true}
        onClick={(e) => onExport(e.target.value)}
      >
        <i className="ni ni-ungroup"></i> Export
      </Button> */}
    </div>
  );

  const export_column = ["interval_name"];

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            intervals,
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [intervals]
  );

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Intervals", "Interval List"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-time-alarm"></i>
            </span>
            Interval List
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <div className="list-filtered-table-block">
                    <DataTable
                      columns={columns}
                      data={DataTableSettings.filterItems(
                        intervals,
                        searchParam,
                        filterText
                      )}
                      pagination
                      paginationPerPage={DataTableSettings.paginationPerPage}
                      paginationRowsPerPageOptions={
                        DataTableSettings.paginationRowsPerPageOptions
                      }
                      actions={actionsExport}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                    />
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default IntervalList;
