import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
} from "reactstrap";
import DataTable from "react-data-table-component";
import moment from "moment";
import DatePicker from "react-datepicker";
import { BreadCrumb } from "../../config/Components";
import { API_POST_ORDERS, API_POSTED_ORDERS } from "../../config/Api";

import DataTableSettings from "../../utils/DataTableSettings";

import "../../assets/css/data-table.css";
import Swal from "sweetalert2";

const PostOrders = (props) => {
  const [post_orders, setPostOrders] = useState([]);
  const [modal, setModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [dateFilter, setDateFilter] = useState({
    start_date: "",
    end_date: "",
  });
  const [clearSearch, setClearSearch] = useState(null);

  const searchParam = ["orderid", "mailer", "item", "postage", "envelope"];
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchPostOrders();
  }, []);

  const fetchPostOrders = () => {
    let startDate = dateFilter.start_date
      ? moment(dateFilter.start_date).format("MM/DD/YYYY")
      : "";
    let endDate = dateFilter.end_date
      ? moment(dateFilter.end_date).format("MM/DD/YYYY")
      : "";
    let paramQ;
    if (!startDate && !endDate) {
      paramQ = API_POST_ORDERS;
    } else if (!startDate) {
      paramQ = API_POST_ORDERS + "&end_date=" + endDate;
    } else if (!endDate) {
      paramQ =
        API_POST_ORDERS + "?start_date=" + startDate + "&end_date=" + endDate;
    } else {
      paramQ =
        API_POST_ORDERS + "?start_date=" + startDate + "&end_date=" + endDate;
    }

    props
      .callRequest("GET", paramQ, true, null)
      .then((res) => {
        setTotalRows(res.data.total_records);
        setPostOrders(res.data.data);
      })
      .catch((error) => {
        if (error.response.data.message === "No record found") {
          setTotalRows([]);
          setPostOrders([]);
        }
      });
  };

  const columns = [
    {
      name: "Order Id",
      selector: "orderid",
      sortable: true,
      maxWidth: 400,
      minWidth: 140,
      width: 200,
    },
    {
      name: "Drop",
      selector: "mailer",
      sortable: true,
    },
    {
      name: "Item",
      selector: "item",
      sortable: true,
    },
    {
      name: "Postage",
      selector: "postage",
      sortable: true,
    },
    {
      name: "Envelop",
      selector: "envelope_name",
      sortable: true,
    },
    {
      name: "Quantity",
      selector: "quantity",
      sortable: true,
    },
    {
      name: "Date In",
      selector: "date_in",
      sortable: true,
      cell: (row) => {
        return (
          row &&
          row.date_in &&
          moment(new Date(row.date_in * 1000)).format("MM/DD/YYYY")
        );
      },
    },
    {
      name: "Shipped Date",
      selector: "shipped_date",
      sortable: true,
      cell: (row) => {
        return (
          row &&
          row.shipped_date &&
          moment(new Date(row.shipped_date * 1000)).format("MM/DD/YYYY")
        );
      },
    },
    {
      name: "Per Price",
      selector: (row) => "$" + row.per,
      sortable: true,
    },
    {
      name: "Total",
      selector: (row) =>
        "$" +
        Number(row.total).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      sortable: true,
    },
    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) => (
        <Button
          onClick={() => {
            PostOrderButton(row.mailing_date_id);
          }}
        >
          Post
        </Button>
      ),
    },
  ];

  const PostOrderButton = (mailing_date_id) => {
    Swal.fire({
      title: `Do you want to post this order?`,
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("PUT", API_POSTED_ORDERS + mailing_date_id + "/", true)
          .then((res) => {
            fetchPostOrders();
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        Swal.fire("This Order Cannot be Posted!!", "", "info");
      }
    });
  };
  const subHeaderComponentMemo = useMemo(() => {
    return (
      <>
        <div className="row">
          {/* <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        > */}
          <div className="col-sm-4">
            <FormGroup>
              <Label for="mailing_date">Search By Order Id</Label>
              <Input
                id="search"
                type="text"
                placeholder="Search"
                aria-label="Search Product"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
            </FormGroup>
          </div>

          <div className="col-sm-4">
            <FormGroup>
              <Label for="mailing_date">Start Date</Label>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={dateFilter.start_date}
                name="start_date"
                id="start_date"
                onChange={(date) => {
                  setDateFilter({ ...dateFilter, start_date: date });
                }}
                autoComplete="off"
                disabled={false}
              />
            </FormGroup>
          </div>

          <div className="col-sm-4">
            <FormGroup>
              <Label for="mailing_date">End Date</Label>
              <DatePicker
                dateFormat="MM/dd/yyyy"
                selected={dateFilter.end_date}
                name="start_date"
                id="start_date"
                onChange={(date) => {
                  setDateFilter({ ...dateFilter, end_date: date });
                }}
                autoComplete="off"
                disabled={false}
              />
            </FormGroup>
          </div>

          <div
            className="col-sm-12"
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button onClick={fetchPostOrders()} style={{ float: "right" }}>
              Search
            </Button>

            <Button
              onClick={() => {
                clearPostOrders();
              }}
              // style={{ float: "right" }}
            >
              Clear
            </Button>
          </div>
        </div>
      </>
    );
  }, [filterText, dateFilter]);

  const clearPostOrders = () => {
    setDateFilter({ start_date: "", end_date: "" });

    setClearSearch(Math.random());
    props
      .callRequest("GET", API_POST_ORDERS, true, null)
      .then((res) => {
        setTotalRows(res.data.total_records);
        setPostOrders(res.data.data);
      })
      .catch((err) => {
        // window.location.reload();
        console.log(err);
      });
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Post Orders"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            Post Orders
          </h1>
        </div>
        <div className="listingtable-main-block orderhistory-tabile-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                <DataTable
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    post_orders,
                    searchParam,
                    filterText
                  )}
                  pagination
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default PostOrders;
