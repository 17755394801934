import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

import {
  UploadImage,
  BreadCrumb,
  AffilateModal,
} from "../../config/Components";
import { withRouter } from "react-router-dom";
import {
  API_PRODUCT_BUNDLE,
  API_CATEGORY,
  API_ATTRIBUTE_TYPE,
  API_ATTRIBUTES,
  API_PRODUCT_THUMB_IMAGE,
  API_GET_PRODUCT_BY_CAT,
  API_PRODUCT_BUNDLE_UPDATE,
  API_GET_BUNDLE,
  API_PRODUCTS,
  API_GET_AFFILIATE,
} from "../../config/Api";
import Swal from "sweetalert2";
import "./AddProduct.css";

//import AddBundleProduct from "./AddBundleProduct";

const AddBundle = (props) => {
  const [categories, setCategory] = useState([]);
  const [products, setProducts] = useState([]);
  const productInfo = {
    bundlelist_id: "",
    bundle: "",
    category: "",
    product: "",
    product_attribute: [],
    mailing_order: "",
    default_mail_date: "",
    save: "",
  };

  const [product_info, setAddProducts] = useState([productInfo]);

  const bundleInfo = {
    bundle_name: "",
    bundle_description: "",
    category: [],
    imagepathname: "",
    min_qnty: "",
    min_qnty_txt: "",
    is_active: false,
    bundle_discount: "",
    ordering: "",
    bundle_id: "",
    affiliate: [],
    BundleProducts: [],
  };

  const [bundle_info, setBundleInfo] = useState(bundleInfo);
  const [productList, setProductList] = useState([]);
  const [errors, setErrors] = useState({});
  const [productInfoErrors, setProductInfoErrors] = useState([{}]);
  const [attrtypes, setAttrTypes] = useState([]);
  const [attrs, setAttrs] = useState([]);
  const [title, setTitle] = useState("Add Bundle");
  const [attribute, setAttribute] = useState();
  const [affiliates, setAffiliates] = useState([]);
  const [modalVal, setModalVal] = useState();
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  useEffect(() => {
    fetchCategory();
    fetchAttributeTypes();
    fetchAttributes();
    fetchAllProducts();
    fetchAffiliates();
    if (props.match.params.hasOwnProperty("id")) {
      fetchBundle(props.match.params.id);
      setTitle("Update Bundle");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchBundle = (id) => {
    props
      .callRequest("GET", API_GET_BUNDLE + "?bundleId=" + id, true)
      .then((res) => {
        let bndl_info = res.data.Bundle;
        let prod_info = res.data.BundleProducts;
        for (let i = 0; i < prod_info.length; i++) {
          prod_info[i].save = "saved";
        }
        setAddProducts(prod_info);
        setBundleInfo({ ...bndl_info, BundleProducts: prod_info });
        prod_info.map((prd) => {
          return fetchProducts(prd.category);
        });
      });
  };
  const fetchAffiliates = () => {
    props.callRequest("GET", API_GET_AFFILIATE, true).then((res) => {
      setAffiliates(res.data);
    });
  };
  const setAffiliateValues = (values) => {
    setBundleInfo({ ...bundle_info, affiliate: values });
  };
  const affiliateToggle = () => {
    setModalVal(false);
    toggle();
  };
  const fetchCategory = () => {
    props.callRequest("GET", API_CATEGORY, true).then((res) => {
      setCategory(res.data);
    });
  };

  const fetchAttributeTypes = () => {
    props.callRequest("GET", API_ATTRIBUTE_TYPE, true).then((res) => {
      setAttrTypes(res.data);
      let filteredAttr = attrtypes
        .filter((rs) =>
          rs.attribute_section.split(",").includes("Reiprintmail_Default")
        )
        .map((type) => {
          return type.type_id;
        });
      let newAttr = {};

      let prdAttr = filteredAttr.map((prdAttr) => {
        return (newAttr[prdAttr] = "");
      });
      setAttribute(newAttr);
    });
  };

  //added fetch attributes
  const fetchAttributes = () => {
    props.callRequest("GET", API_ATTRIBUTES, true).then((res) => {
      setAttrs(res.data);
    });
  };
  //added fetchProducts by category
  const fetchProducts = (id) => {
    props.callRequest("GET", API_GET_PRODUCT_BY_CAT + id, true).then((res) => {
      setProducts(res.data[0].product_list);
    });
  };
  const fetchAllProducts = () => {
    props.callRequest("GET", API_PRODUCTS, true).then((res) => {
      setProductList(res.data);
    });
  };
  // added for image upload
  const setImage = (path) => {
    setBundleInfo({ ...bundle_info, imagepathname: path });
  };

  //added form validation
  const validateForm = () => {
    let {
      category,
      bundle_name,
      bundle_description,
      ordering,
      min_qnty,
      bundle_discount,
    } = bundle_info;

    let formIsValid = true;
    let errors = {};

    if (category.length === 0) {
      formIsValid = false;
      errors["category"] = "This field is required";
    }
    if (bundle_name === "") {
      formIsValid = false;
      errors["bundle_name"] = "This field is required";
    }
    if (min_qnty === "") {
      formIsValid = false;
      errors["min_qnty"] = "This field is required";
    }
    if (bundle_discount === "") {
      formIsValid = false;
      errors["bundle_discount"] = "This field is required";
    }
    if (bundle_description === "") {
      formIsValid = false;
      errors["bundle_description"] = "This field is required";
    }
    if (ordering === "") {
      formIsValid = false;
      errors["ordering"] = "This field is required";
    }

    setErrors(errors);
    return formIsValid;
  };

  const validateProductInfoForm = (i) => {
    //let { category, product, product_attribute } = productInfo;
    let isFormValid = true;
    let productInfoErrors = [{}];

    if (product_info[i].category == "") {
      isFormValid = false;
      productInfoErrors["productCategory"] = "This field is required";
    }

    if (product_info[i].product == "") {
      isFormValid = false;
      productInfoErrors["product"] = "This field is required";
    }
    if (product_info[i].product_attribute.length == 0) {
      isFormValid = false;
      productInfoErrors["product_attribute"] = "This field is required";
    }
    if (product_info[i].product_attribute.length !== 0) {
      for (let j = 0; j < product_info[i].product_attribute.length; j++) {
        if (product_info[i].product_attribute[j] == "") {
          isFormValid = false;
          productInfoErrors["product_attribute"] = "This field is required";
        }
      }
    }
    if (product_info[i].mailing_order == "") {
      isFormValid = false;
      productInfoErrors["mailing_order"] = "Enter a valid number";
    }
    if (product_info[i].save == "") {
      isFormValid = false;
      productInfoErrors["save"] = "Added product must be saved";
    }
    // if (
    //   product_info.product_attribute &&
    //   product_info[i].product_attribute.length != attrtypes.length
    // ) {
    //   isFormValid = false;
    //   productInfoErrors["product_attributes"] =
    //     "All Attribute fields are required";
    // }

    setProductInfoErrors(productInfoErrors);
    return isFormValid;
  };
  // console.log(productInfoErrors, "productInfoErrors");

  //changed handle change function
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      let options = e.target.options;
      let val = [];
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          val.push(options[i].value);
        }
      }
      setBundleInfo({ ...bundle_info, [name]: val });
    } else {
      setBundleInfo({ ...bundle_info, [name]: value });
    }
  };

  const productAdd = (i, event) => {
    const { name, value } = event.target;
    let currProductInfoArr = [...product_info];
    currProductInfoArr[i][name] = value;
    setAddProducts(currProductInfoArr);
    if (name === "category") {
      fetchProducts(product_info[i].category);
    }
  };
  const handleProductAttributes = (e, i, type_id) => {
    try {
      const { name, value } = e.target;
      let currPrdAttr = { ...attribute };
      currPrdAttr[type_id] = value;
      setAttribute(currPrdAttr);
    } catch (err) {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    for (let i = 0; i < product_info.length; i++) {
      if (!validateProductInfoForm(i)) {
        return false;
      }
    }

    let newBundle = { ...bundle_info };
    newBundle.BundleProducts = product_info;
    // console.log(newBundle, "bundle_info");
    // console.log(product_info, "product_info");

    //setBundleInfo({ ...newBundle });
    let post_data = {};
    post_data = { ...newBundle };
    post_data["is_active"] = Number(post_data.is_active);
    let post_url = API_PRODUCT_BUNDLE;
    let method = "POST";
    let bnd_id = bundle_info.bundle_id;
    if (bnd_id) {
      post_url = API_PRODUCT_BUNDLE_UPDATE + bnd_id + "/";
      method = "PUT";
    }
    props
      .callRequest(method, post_url, true, post_data)
      .then((res) => {
        if (res.status) {
          //console.log(post_data);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Saved successfully",
          });
          setTimeout(() => {
            props.history.push("/product-bundles");
          }, 3000);
        }
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Something Went Wrong!",
        });
      });
  };
  const AddCount = () => {
    let newProductInfoArr = [...product_info, productInfo];
    setAddProducts(newProductInfoArr);
  };
  const getCommon = (arr1, arr2) => {
    var common = [];
    for (var i = 0; i < arr1.length; ++i) {
      for (var j = 0; j < arr2.length; ++j) {
        if (arr1[i] == arr2[j]) {
          common.push(arr1[i]);
        }
      }
    }

    return common[0]; // Return the common elements
  };
  const findAttributeIdList = (id) => {
    const arrList = attrs.filter((attr) => {
      return attr.attributetype_id == id;
    });
    const arrIdList = arrList.map((arr) => arr.attribute_id);
    return arrIdList;
  };

  const saveProduct = (i) => {
    let oldAttr = product_info[i].product_attribute;
    // console.log(oldAttr, "oldAttr-out");
    if (oldAttr.length !== 0) {
      for (let i in attribute) {
        let commonAttr = getCommon(findAttributeIdList(i), oldAttr);
        let index = oldAttr.indexOf(commonAttr);
        oldAttr[index] = Number(attribute[i]);
      }
      // console.log(oldAttr, "oldAttr-ins");
      let currProductInfoArr = [...product_info];
      currProductInfoArr[i].product_attribute = [...oldAttr];
      currProductInfoArr[i].save = "saved";
      setAddProducts(currProductInfoArr);
    } else {
      let abc = [];
      for (let items in attribute) {
        abc.push(attribute[items]);
      }
      let currProductInfoArr = [...product_info];
      currProductInfoArr[i].product_attribute = [...abc];
      currProductInfoArr[i].save = "saved";
      setAddProducts(currProductInfoArr);
      if (!validateProductInfoForm(i)) {
        currProductInfoArr[i].save = "";
        return false;
      }
    }

    // console.log(product_info[i].product_attribute, "prdAttr");
  };
  const editProduct = (i) => {
    fetchProducts(product_info[i].category);
    let currProductInfoArr = [...product_info];
    currProductInfoArr[i].save = "";
    // currProductInfoArr[i]["product_attribute"] = [];
    //  currProductInfoArr[i] = productInfo;
    // let filteredAttr = attrtypes
    //   .filter((rs) =>
    //     rs.attribute_section.split(",").includes("Reiprintmail_Default")
    //   )
    //   .map((type) => {
    //     return type.type_id;
    //   });
    // let newAttr = {};
    // let prdAttr = filteredAttr.map((prdAttr) => {
    //   return (newAttr[prdAttr] = "");
    // });
    // setAttribute(newAttr);
    setAddProducts(currProductInfoArr);
  };
  const removeProduct = (id) => {
    if (product_info.length == 1) {
      Swal.fire({
        icon: "error",
        title: "Atleast one product is required to create product bundle!",
      });
    } else {
      let newProductInfoArr = [...product_info];
      newProductInfoArr.splice(id, 1);
      setAddProducts(newProductInfoArr);
    }
  };

  const findDefaultValue = (defaultArr, attributetype_id) => {
    // if (
    //   defaultArr.length === 0 &&
    //   attribute &&
    //   !Object.values(attribute).some((x) => x !== "")
    // ) {
    //   return "";
    // }
    try {
      let r = defaultArr
        .map(
          (d) =>
            attrs.filter(
              (re) =>
                re.attributetype_id === attributetype_id &&
                re.attribute_id === d
            )[0]
        )
        .filter((temp) => temp !== undefined)[0];
      return r && r.attribute_id;
    } catch (err) {
      return false;
    }
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Products Bundles", title]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-bag-17"></i>
            </span>
            {title}
          </h1>
        </div>

        <div className="add-Product-block">
          <Row>
            <Col sm="8" lg="9">
              <Card>
                <CardBody>
                  <Form onSubmit={handleSubmit}>
                    <Row form>
                      {/*added dynamic category */}
                      <Col md={6}>
                        <FormGroup>
                          <Label for="category">
                            Category: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="select"
                            name="category"
                            id="category"
                            onChange={handleChange}
                            value={bundle_info.category}
                            multiple
                          >
                            <option value="">Select Category</option>
                            {categories.map((cat, index) => {
                              return (
                                <option value={cat.category_id} key={index}>
                                  {cat.category_name}
                                </option>
                              );
                            })}
                          </Input>
                          <label className="red_err">{errors.category}</label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="bundleName">
                            Bundle Name:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name="bundle_name"
                            id="bundleName"
                            autoComplete="off"
                            placeholder=""
                            value={bundle_info.bundle_name}
                            onChange={handleChange}
                          />
                          <label className="red_err">
                            {errors.bundle_name}
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="description">
                        Bundle Description:
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="bundle_description"
                        id="description"
                        placeholder="Enter Description"
                        value={bundle_info.bundle_description}
                        onChange={handleChange}
                      />
                      <label className="red_err">
                        {errors.bundle_description}
                      </label>
                    </FormGroup>
                    <div className="addnew-form-button">
                      <Button onClick={AddCount}>
                        <i className="ni ni-fat-add"></i> &nbsp; Add New
                        Products
                      </Button>
                    </div>

                    {product_info.map((prd, index) => {
                      return (
                        <div key={index}>
                          <div className="add-extraform-block">
                            <Card>
                              <CardHeader>
                                Product list{" "}
                                <span className="text-danger">*</span>
                              </CardHeader>
                              <CardBody>
                                <Row form>
                                  <Col md={6} lg={3}>
                                    <FormGroup>
                                      <Label for="selectCategory">
                                        Select Category:
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        type="select"
                                        name="category"
                                        id="selectCategory"
                                        onChange={productAdd.bind(this, index)}
                                        value={prd.category}
                                        disabled={
                                          prd.save == "saved" ? true : false
                                        }
                                      >
                                        <option value="">
                                          Select Category
                                        </option>
                                        {categories.map((cat, index) => {
                                          return (
                                            <option
                                              value={cat.category_id}
                                              key={index}
                                            >
                                              {cat.category_name}
                                            </option>
                                          );
                                        })}
                                      </Input>
                                      <label className="red_err">
                                        {productInfoErrors.productCategory}
                                      </label>
                                    </FormGroup>
                                  </Col>

                                  <Col md={6} lg={3}>
                                    <FormGroup>
                                      <Label for="productName">
                                        Product Name:
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        type="select"
                                        name="product"
                                        id="productName"
                                        value={prd.product}
                                        onChange={productAdd.bind(this, index)}
                                        disabled={
                                          prd.save == "saved" ? true : false
                                        }
                                      >
                                        <option>Select Product</option>
                                        {prd.save === ""
                                          ? products.map((prod, i) => {
                                              return (
                                                <option
                                                  value={prod.product_id}
                                                  key={i}
                                                >
                                                  {prod.product_name}
                                                </option>
                                              );
                                            })
                                          : productList.map((prod, i) => {
                                              return (
                                                <option
                                                  value={prod.product_id}
                                                  key={i}
                                                >
                                                  {prod.product_name}
                                                </option>
                                              );
                                            })}
                                      </Input>
                                      <label className="red_err">
                                        {productInfoErrors.product}
                                      </label>
                                    </FormGroup>
                                  </Col>

                                  {attrtypes
                                    .filter((rs) =>
                                      rs.attribute_section
                                        .split(",")
                                        .includes("Reiprintmail_Default")
                                    )
                                    .map((type, i) => {
                                      return (
                                        <Col md={6} lg={3} key={i}>
                                          <FormGroup>
                                            <Label for={type.type_name}>
                                              {type.type_name}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </Label>

                                            <Input
                                              type="select"
                                              name={type.type_name}
                                              id={type.type_name}
                                              value={
                                                prd.save == "saved"
                                                  ? findDefaultValue(
                                                      prd.product_attribute,
                                                      type.type_id
                                                    )
                                                  : null
                                              }
                                              onChange={(e) =>
                                                handleProductAttributes(
                                                  e,
                                                  index,
                                                  type.type_id
                                                )
                                              }
                                              disabled={
                                                prd.save == "saved"
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <option value="">
                                                Please Select
                                              </option>
                                              {attrs
                                                .filter(
                                                  (rs) =>
                                                    rs.attributetype_id ===
                                                    type.type_id
                                                )
                                                .map((attr, index) => {
                                                  return (
                                                    <option
                                                      value={attr.attribute_id}
                                                      key={index}
                                                    >
                                                      {attr.attribute_name}
                                                    </option>
                                                  );
                                                })}
                                            </Input>
                                            <label className="red_err">
                                              {
                                                productInfoErrors.product_attribute
                                              }
                                            </label>
                                            {/* <label className="red_err">
                                              {
                                                productInfoErrors.product_attributes
                                              }
                                            </label> */}
                                          </FormGroup>
                                        </Col>
                                      );
                                    })}
                                  <Col md={6} lg={3}>
                                    <FormGroup>
                                      <Label for="sortOrder">
                                        Mailing Order:{" "}
                                        <span className="text-danger">*</span>
                                      </Label>

                                      <Input
                                        onChange={productAdd.bind(this, index)}
                                        value={prd.mailing_order}
                                        type="number"
                                        name="mailing_order"
                                        id="sortOrder"
                                        autoComplete="off"
                                        placeholder=""
                                        disabled={
                                          prd.save == "saved" ? true : false
                                        }
                                      />
                                      <label className="red_err">
                                        {productInfoErrors.mailing_order}
                                      </label>
                                    </FormGroup>
                                  </Col>
                                  <Col md={6} lg={3}>
                                    <FormGroup>
                                      <Label for="mailDate">
                                        Default Mail Date:
                                      </Label>
                                      <Input
                                        type="date"
                                        name="default_mail_date"
                                        id="mailDate"
                                        onChange={productAdd.bind(this, index)}
                                        value={prd.default_mail_date}
                                        disabled={
                                          prd.save == "saved" ? true : false
                                        }
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                </Row>{" "}
                                {/* {prd && prd.product_attribute.length === 0 ? (
                                  <Button
                                    onClick={saveProduct.bind(this, index)}
                                    color="success"
                                  >
                                    Save
                                  </Button>
                                ) : prd &&
                                  prd.product_attribute.length !== 0 ? (
                                  <Button
                                    onClick={editProduct.bind(this, index)}
                                    color="warning"
                                  >
                                    Edit
                                  </Button>
                                ) : (
                                  null
                                )} */}
                                {prd && prd.save == "" ? (
                                  <Button
                                    onClick={saveProduct.bind(this, index)}
                                    color="success"
                                  >
                                    Save
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={editProduct.bind(this, index)}
                                    color="warning"
                                  >
                                    Edit
                                  </Button>
                                )}
                                <Button
                                  onClick={removeProduct.bind(this, index)}
                                  color="danger"
                                >
                                  Remove
                                </Button>{" "}
                                <label className="red_err">
                                  {productInfoErrors.save}
                                </label>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                      );
                    })}

                    <Row form>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="bundleDiscount">
                            Bundle Discount:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            name="bundle_discount"
                            id="bundleDiscount"
                            placeholder="0"
                            autoComplete="off"
                            value={bundle_info.bundle_discount}
                            onChange={handleChange}
                          />
                          <label className="red_err">
                            {errors.bundle_discount}
                          </label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="sortOrder">
                            Sort Order:<span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            name="ordering"
                            id="productName"
                            autoComplete="off"
                            placeholder="Enter Sort Order"
                            value={bundle_info.ordering}
                            onChange={handleChange}
                          />
                          <label className="red_err">{errors.ordering}</label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="minimumQuantity">
                            Minimum Quantity:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            name="min_qnty"
                            id="minimumQuantity"
                            placeholder=""
                            autoComplete="off"
                            value={bundle_info.min_qnty}
                            onChange={handleChange}
                          />
                          <label className="red_err">{errors.min_qnty}</label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <div className="affiliate-block">
                            <Label className="affiliate-titel">Affiliate</Label>
                            <Button onClick={affiliateToggle}>
                              Select Affiliate
                            </Button>
                            {modalVal === false ? (
                              <AffilateModal
                                modal={modal}
                                toggle={toggle}
                                affiliates={affiliates}
                                setAffiliateValues={setAffiliateValues}
                                affiliate={bundle_info.affiliate}
                                is_bundle="Yes"
                                setBundleInfo={setBundleInfo}
                                bundle_info={bundle_info}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label for="notification">
                        Notification on Minimum Quantity:
                      </Label>
                      <Input
                        type="textarea"
                        name="min_qnty_txt"
                        id="notification"
                        placeholder="Enter Notification on Minimum Quantity:"
                        value={bundle_info.min_qnty_txt}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    {/* added code for status */}
                    <FormGroup tag="fieldset">
                      <div className="radio-block">
                        <legend>
                          Status:<span className="text-danger">*</span>
                        </legend>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="is_active"
                              value="1"
                              checked={
                                bundle_info.is_active === "1" ? true : false
                              }
                              onChange={handleChange}
                            />{" "}
                            Active
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="is_active"
                              value="0"
                              checked={
                                bundle_info.is_active !== "1" ? true : false
                              }
                              onChange={handleChange}
                            />{" "}
                            Inactive
                          </Label>
                        </FormGroup>
                      </div>
                    </FormGroup>
                    {/* added button for save changes */}
                    <div className="submit-button-block">
                      <Button type="submit">
                        Save Changes <i className="ni ni-bold-right"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            {/* <Col sm="4" lg="3">
              <div className="addproduct-right-block">
                <div className="product-image-preview-box">
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      //src={} //{require("../../assets/img/36_front.png").default}
                      alt="template"
                    />
                  </Card>
                </div>

                <div className="product-image-preview-box">
                  <Card>
                    <CardImg
                      top
                      width="100%"
                      // src={require("../../assets/img/36_back.png").default}
                      alt="template"
                    />
                  </Card>
                </div>
              </div>
            </Col> */}
            <Col sm="4" lg="3">
              <div id="scaleBoxLayout" className="">
                <div className="design-thum-block">
                  <UploadImage
                    btn_name="Upload Photo"
                    url={API_PRODUCT_THUMB_IMAGE}
                    original_img={bundle_info.imagepathname}
                    setImage={setImage}
                    {...props}
                    // name="imagepathname"
                    // value={bundle_info.imagepathname}
                    // onChange={handleChange}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default withRouter(AddBundle);
