import React, { useState, useEffect } from "react";
import "../../assets/css/data-table.css";
import Swal from "sweetalert2";
import { Button, Input, FormGroup, Form, Label } from "reactstrap";

import {
  API_PARENT_CATEGORY,
  API_CATEGORY,
  API_GET_CATEGORY,
} from "../../config/Api";

const CategoryForm = (props) => {
  const [category_name, setCategoryName] = useState("");
  const [is_active, setIsActive] = useState(1);
  const [parent_category_id, setParentCategoryId] = useState(0);
  const [parent_categories, setParentCategories] = useState([]);
  const [is_parent, setIsParent] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchParentCategory();
    if (props.categoryId !== 0) {
      fetchCategoryById();
    }
  }, [props]);

  const fetchParentCategory = async () => {
    props.callRequest("GET", API_PARENT_CATEGORY, true).then((res) => {
      setParentCategories(res.data);
    });
  };

  const fetchCategoryById = async () => {
    props
      .callRequest("GET", API_GET_CATEGORY + props.categoryId, true)
      .then((res) => {
        setCategoryName(res.data.category_name);
        setIsActive(res.data.is_active);
        setParentCategoryId(res.data.parent_category_id);
        if (res.data.parent_category_id === 0) {
          setIsParent(true);
        }
      });
  };

  const toggleChange = () => {
    setIsParent(!is_parent);
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (category_name === "") {
      formIsValid = false;
      errors["err_category_name"] = "Please provide categoryname";
    }
    if (is_parent === false) {
      if (parent_category_id > 0) {
      } else {
        formIsValid = false;
        errors["err_category"] = "Please choose parent category";
      }
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setErrors({ err_category_name: "" });
      setErrors({ err_category: "" });
      const formData = new FormData();

      formData.append("category_name", category_name);
      formData.append("is_active", is_active);
      if (is_parent === true) {
        formData.append("parent_category_id", "0");
      } else {
        formData.append("parent_category_id", parent_category_id);
      }
      if (props.categoryId !== 0) {
        props
          .callRequest(
            "PUT",
            API_CATEGORY + props.categoryId + "/",
            true,
            formData
          )
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Updated successfully",
              });
              setTimeout(
                function () {
                  setCategoryName("");
                  setIsActive(1);
                  setParentCategories([]);
                  setIsParent(false);
                  props.modalToggle();
                  props.fetchCategory();
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      } else {
        props
          .callRequest("POST", API_CATEGORY, true, formData)
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Saved successfully",
              });
              setTimeout(
                function () {
                  setCategoryName("");
                  setIsActive(1);
                  setParentCategories([]);
                  setIsParent(false);
                  props.modalToggle();
                  props.fetchCategory();
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      }
    }
  };

  return (
    <>
      <div className="popup-form-block">
        <Form>
          <FormGroup>
            <Label for="categoryName">Category Name *</Label>
            <Input
              type="text"
              autoComplete="off"
              name="category_name"
              id="category_name"
              className="form-control"
              value={category_name}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <label className="red_err">{errors.err_category_name}</label>
          </FormGroup>
          {is_parent ? null : (
            <div className="form-group">
              <label>Select Parent Category: </label>
              <select
                id="parent_category_id"
                name="parent_category_id"
                value={parent_category_id}
                onChange={(e) => setParentCategoryId(e.target.value)}
                className="form-control"
                aria-label="Default select example"
              >
                <option>Select Parent Category</option>
                {parent_categories.map((parent_category) => (
                  <option
                    key={parent_category.category_id}
                    value={parent_category.category_id}
                  >
                    {parent_category.category_name}
                  </option>
                ))}
              </select>
              <label className="red_err">{errors.err_category}</label>
            </div>
          )}
          <FormGroup tag="fieldset">
            <div className="radio-block">
              <legend>Status:</legend>
              <FormGroup check>
                <Label>
                  <Input
                    type="radio"
                    name="is_active"
                    id="is_active"
                    value="1"
                    checked={is_active === "1"}
                    onChange={(e) => setIsActive(e.target.value)}
                  />
                  Active
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label>
                  <Input
                    type="radio"
                    name="is_active"
                    id="is_inactive"
                    value="0"
                    checked={is_active === "0"}
                    onChange={(e) => setIsActive(e.target.value)}
                  />
                  Inactive
                </Label>
              </FormGroup>
            </div>
          </FormGroup>

          <FormGroup>
            <Input
              type="checkbox"
              name="is_parent"
              id="is_parent"
              className="form-control"
              checked={is_parent === true ? "checked" : ""}
              onChange={toggleChange}
            />
            Is Parent
          </FormGroup>
          <div className="popup-button-block">
            <Button onClick={handleSubmit}>
              Save Changes <i className="ni ni-bold-right"></i>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CategoryForm;
