import React, { useEffect } from "react";
import { Input, Button, Form, FormGroup } from "reactstrap";
import {
  API_GET_ADMIN_AFFILIATE_DETAILS,
  API_UPDATE_USER_PASSWORD,
} from "../../config/Api";
import Swal from "sweetalert2";

const ChangePassword = (props) => {
  const [password, setPassword] = React.useState({
    old_password: "",
    newPassword: "",
    newPassword1: "",
  });
  const [user, setUser] = React.useState({});
  const [errors, setErrors] = React.useState({});

  useEffect(() => {
    fetchUser();
  }, []);
  const fetchUser = () => {
    props
      .callRequest("GET", API_GET_ADMIN_AFFILIATE_DETAILS, true)
      .then((res) => {
        setUser(res.data);
      });
  };
  const validateForm = () => {
    let { old_password, newPassword, newPassword1 } = password;
    let errors = {};
    let formIsValid = true;

    if (!old_password) {
      formIsValid = false;
      errors["old_password"] = "Please provide current password";
    }

    if (!newPassword) {
      formIsValid = false;
      errors["newPassword"] = "Please provide new password";
    }
    if (!newPassword1) {
      formIsValid = false;
      errors["newPassword1"] = "Please provide new password again";
    }
    if (newPassword && newPassword1) {
      if (newPassword !== newPassword1) {
        formIsValid = false;
        errors["matchPwd"] = "New passwords don't match";
      }
    }

    if (old_password !== user.original_password) {
      formIsValid = false;
      errors["originalPwd"] = "Incorrect Old Password";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }
    const formData = new FormData();
    formData.append("password", password.newPassword);
    props
      .callRequest("PUT", API_UPDATE_USER_PASSWORD, true, formData)
      .then((res) => {
        props.toggle();
        Swal.fire({
          icon: "success",
          title: "Password changed successfully!!",
        });
      })
      .catch((err) => {
        Swal.fire({
          showConfirmButton: false,
          timer: 1000,
          icon: "error",
          title: "Something went wrong!!",
        });
        //   setErrors({});
        //   setPassword({});
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword({ ...password, [name]: value });
  };
  return (
    <div className="card">
      <div className="card-body">
        <div className="login-box">
          <Form>
            <h2 className="text-center">
              Change Password
              <span>
                <i
                  style={{ cursor: "pointer" }}
                  onClick={props.toggle}
                  className="fas fa-times-circle popup-close"
                ></i>
              </span>
            </h2>
            <FormGroup>
              <Input
                type="password"
                placeholder="enter current password"
                value={password.old_password}
                name="old_password"
                onChange={handleChange}
              />
              {errors.old_password ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.old_password}
                </label>
              ) : (
                ""
              )}
              {errors.originalPwd ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.originalPwd}
                </label>
              ) : (
                ""
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                placeholder="enter new password"
                value={password.newPassword}
                name="newPassword"
                onChange={handleChange}
              />
              {errors.newPassword ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.newPassword}
                </label>
              ) : (
                ""
              )}
            </FormGroup>
            <FormGroup>
              <Input
                type="password"
                placeholder="confirm new password"
                value={password.newPassword1}
                name="newPassword1"
                onChange={handleChange}
              />
              {errors.newPassword1 ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.newPassword1}
                </label>
              ) : (
                ""
              )}
              {errors.matchPwd ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.matchPwd}
                </label>
              ) : (
                ""
              )}
            </FormGroup>

            <div className=" row d-flex justify-content-center align-content-center ">
              <Button
                style={{ cursor: "pointer" }}
                onClick={handleChangePassword}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>

    // <>
    //   <div>
    //     <div className="popupSection">
    //       <div className="popupBox">
    //         <span className="popup-close-button">
    //           <i onClick={handlePwdClose} className="fas fa-times-circle"></i>
    //         </span>
    //         <h2>Change Password</h2>
    //         <form>
    //           <input
    //             type="password"
    //             placeholder="Current password"
    //             name="old_password"
    //             value={password.old_password}
    //             onChange={handleOnChange}
    //           />
    //           {errors.old_password ? (
    //             <label className="text-danger">
    //               <i className="fas fa-exclamation-circle"></i>
    //               {errors.old_password}
    //             </label>
    //           ) : (
    //             ""
    //           )}
    //           {errors.originalPwd ? (
    //             <label className="text-danger">
    //               <i className="fas fa-exclamation-circle"></i>
    //               {errors.originalPwd}
    //             </label>
    //           ) : (
    //             ""
    //           )}
    //           <input
    //             type="password"
    //             placeholder="New password"
    //             name="newPassword"
    //             value={password.newPassword}
    //             onChange={handleOnChange}
    //           />
    //           {errors.newPassword ? (
    //             <label className="text-danger">
    //               <i className="fas fa-exclamation-circle"></i>
    //               {errors.newPassword}
    //             </label>
    //           ) : (
    //             ""
    //           )}
    //           <input
    //             type="password"
    //             placeholder="Confirm New password"
    //             name="newPassword1"
    //             value={password.newPassword1}
    //             onChange={handleOnChange}
    //           />
    //           {errors.newPassword1 ? (
    //             <label className="text-danger">
    //               <i className="fas fa-exclamation-circle"></i>
    //               {errors.newPassword1}
    //             </label>
    //           ) : (
    //             ""
    //           )}
    //           {errors.matchPwd ? (
    //             <label className="text-danger">
    //               <i className="fas fa-exclamation-circle"></i>
    //               {errors.matchPwd}
    //             </label>
    //           ) : (
    //             ""
    //           )}

    //           <button
    //             style={{ cursor: "pointer" }}
    //             onClick={changePassword}
    //             type="submit"
    //           >
    //             Change Password
    //           </button>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </>
  );
};

export default ChangePassword;
