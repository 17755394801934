import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import {
  API_GET_FILTERED_PROUCTS,
  API_GET_FILTERED_NEW_PRODUCTS,
  API_GET_SIZE_WISE_ALL_PRODUCTS,
  API_GET_CATEGORY,
  API_PARENT_CATEGORY,
  API_TEMPLATE_SIZE,
  API_CHILD_CATEGORY,
} from "../../config/Api";

const AddProductOrder = (props) => {
  const [products, setProducts] = useState([]);
  const [singleProduct, setSingleProduct] = useState({});
  const [seeAll, setSeeAll] = useState([]);
  const [image, setImage] = useState();
  const [newProducts, setNewProducts] = useState([]);
  const [parentCategorys, setParentCategorys] = useState([]);
  const [templateSizes, setTemplateSizes] = useState([]);
  const [childCategorys, setChildCategorys] = useState([]);
  const [searchInputs, setSerachInputs] = useState({
    product_name: "",
  });
  const [seeAllSection, setSeeAllSection] = useState({
    size_name: "",
    parent_category_name: "",
  });
  const [checkTypeSize, setCheckTypeSize] = useState([]);
  const [checkTypeSubCat, setCheckTypeSubCat] = useState([]);
  const [checkTypeParentCat, setCheckTypeParentCat] = useState([]);
  // console.log(props.SelectedUserId, "selectedUserId");
  useEffect(() => {
    fetchNewProducts();
    fetchProducts();
    fetchParentCategory();
    fetchTemplateSize();
    fetchChildCategory();
  }, []);

  const fetchProducts = () => {
    props
      .callRequest(
        "GET",
        API_GET_FILTERED_PROUCTS + "?user_id=" + props.SelectedUserId,
        true
      )
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchParentCategory = () => {
    props
      .callRequest("GET", `${API_PARENT_CATEGORY}`, true)
      .then((res) => {
        setParentCategorys(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchTemplateSize = () => {
    props
      .callRequest("GET", `${API_TEMPLATE_SIZE}`, true)
      .then((res) => {
        setTemplateSizes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchChildCategory = () => {
    props
      .callRequest("GET", `${API_CHILD_CATEGORY}`, true)
      .then((res) => {
        setChildCategorys(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchNewProducts = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_FILTERED_NEW_PRODUCTS}?limit=${7}&user_id=${
          props.SelectedUserId
        }`,
        true
      )
      .then((res) => {
        setNewProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onChangeAction = (event) => {
    let searchVal = {
      ...searchInputs,
      [event.target.name]: event.target.value,
    };
    setSerachInputs({ ...searchVal });
  };
  const sendImage = (image) => {
    props.sendImage(image);
  };

  const sendSingleProduct = (id, size_id, cat_id) => {
    Object.keys(products).map((prod) => {
      Object.keys(products[prod]).map((size) => {
        products[prod][size].map((item) => {
          if (
            item.product_id == id &&
            item.size_id == size_id &&
            item.category_id == cat_id
          ) {
            
            setSingleProduct(item);
            return props.sendSingleProduct(item);
          }
        });
      });
    });
  };

  const sendProductId = (id) => {
    props.setSingleProductId(id);
  };
  const handleNextProduct = (id, image, size_id, cat_id, cat_name) => {
    setImage(image);
    sendImage(image);
    sendSingleProduct(id, size_id, cat_id);
    sendProductId(id);
    props.addProductOrder();
    props.sendCategory(cat_id,cat_name);
    // setTimeout(() => {
    //   props.addProductOrder();
    // }, 1000);
    props.chooseDesignDetails();
  };

  const fetchSizeWiseAllProducts = (
    category_id,
    parent_category_id,
    size_id
  ) => {
    let x = document.getElementById("see_all_product_sections");
    let y = document.getElementById("all_product_sections");
    y.style.display = "none";
    x.style.display = "block";
    props
      .callRequest(
        "GET",
        `${API_GET_SIZE_WISE_ALL_PRODUCTS}?user_id=${props.SelectedUserId}&category_id=${parent_category_id}&size_id=${size_id}`,
        true
      )
      .then((res) => {
        props
          .callRequest(
            "GET",
            `${API_GET_CATEGORY}` + "/" + parent_category_id,
            true
          )
          .then((response) => {
            setSeeAll(res.data);
            const size_name = res.data
              .map((item) => item.size_name)
              .filter((value, index, self) => self.indexOf(value) === index)[0];
            setSeeAllSection({
              ...seeAllSection,
              size_name: size_name,
              parent_category_name: response.data.category_name,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const closeProductSection = () => {
    let x = document.getElementById("see_all_product_sections");
    let y = document.getElementById("all_product_sections");
    y.style.display = "block";
    x.style.display = "none";
  };

  const handleChangeSize = (e, id) => {
    if (e.target.checked) {
      setCheckTypeSize([
        ...checkTypeSize,
        {
          id: id,
        },
      ]);
    } else {
      // remove from list
      setCheckTypeSize(checkTypeSize.filter((c) => c.id !== id));
    }
  };

  const handleChangeSubCat = (e, id) => {
    if (e.target.checked) {
      setCheckTypeSubCat([
        ...checkTypeSubCat,
        {
          id: id,
        },
      ]);
    } else {
      // remove from list
      setCheckTypeSubCat(checkTypeSubCat.filter((c) => c.id !== id));
    }
  };

  const handleChangeParentCat = (e, id) => {
    if (e.target.checked) {
      setCheckTypeParentCat([
        ...checkTypeParentCat,
        {
          id: id,
        },
      ]);
    } else {
      // remove from list
      setCheckTypeParentCat(checkTypeParentCat.filter((c) => c.id !== id));
    }
  };

  useEffect(() => {
    let full_query_param = "";
    let category_ids_str = "";
    let size_ids_str = "";
    let search_q_str = "";
    if (checkTypeSubCat.length > 0) {
      category_ids_str = checkTypeSubCat
        .map((item) => {
          return item.id;
        })
        .join(",");
    }
    if (checkTypeSize.length > 0) {
      size_ids_str = checkTypeSize
        .map((item) => {
          return item.id;
        })
        .join(",");
    }
    if (searchInputs.product_name != "") {
      search_q_str = searchInputs.product_name;
    }

    if (
      checkTypeSubCat.length > 0 &&
      checkTypeSize.length > 0 &&
      searchInputs.product_name != ""
    ) {
      full_query_param =
        "&category_ids=" +
        category_ids_str +
        "&size_ids=" +
        size_ids_str +
        "&search_q=" +
        search_q_str;
    } else if (
      checkTypeSubCat.length > 0 &&
      checkTypeSize.length == 0 &&
      searchInputs.product_name != ""
    ) {
      full_query_param =
        "&category_ids=" + category_ids_str + "&search_q=" + search_q_str;
    } else if (
      checkTypeSubCat.length == 0 &&
      checkTypeSize.length > 0 &&
      searchInputs.product_name != ""
    ) {
      full_query_param =
        "&size_ids=" + size_ids_str + "&search_q=" + search_q_str;
    } else if (
      checkTypeSubCat.length > 0 &&
      checkTypeSize.length > 0 &&
      searchInputs.product_name == ""
    ) {
      full_query_param =
        "&category_ids=" + category_ids_str + "&size_ids=" + size_ids_str;
    } else if (
      checkTypeSubCat.length > 0 &&
      checkTypeSize.length == 0 &&
      searchInputs.product_name == ""
    ) {
      full_query_param = "&category_ids=" + category_ids_str;
    } else if (
      checkTypeSubCat.length == 0 &&
      checkTypeSize.length > 0 &&
      searchInputs.product_name == ""
    ) {
      full_query_param = "&size_ids=" + size_ids_str;
    } else if (
      checkTypeSubCat.length == 0 &&
      checkTypeSize.length == 0 &&
      searchInputs.product_name != ""
    ) {
      full_query_param = "&search_q=" + search_q_str;
    }

    props
      .callRequest(
        "GET",
        API_GET_FILTERED_PROUCTS +
          "?user_id=" +
          props.SelectedUserId +
          full_query_param,
        true
      )
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [checkTypeSize, checkTypeSubCat, searchInputs]);

  useEffect(() => {
    let category_id = "";
    if (checkTypeParentCat.length > 0) {
      for (let j = 0; j < parentCategorys.length; j++) {
        category_id = parentCategorys[j].category_id;
        if (document.getElementById("parent_cat_section_" + category_id)) {
          if (
            document.getElementById("parent_cat_section_" + category_id)
              .parentNode
          ) {
            let p = document.getElementById(
              "parent_cat_section_" + category_id
            ).parentNode;
            p.style.display = "none";
          }
        }
      }
      for (let i = 0; i < checkTypeParentCat.length; i++) {
        category_id = checkTypeParentCat[i].id;
        if (document.getElementById("parent_cat_section_" + category_id)) {
          if (
            document.getElementById("parent_cat_section_" + category_id)
              .parentNode
          ) {
            let p = document.getElementById(
              "parent_cat_section_" + category_id
            ).parentNode;
            p.style.display = "block";
          }
        }
      }
    } else {
      for (let j = 0; j < parentCategorys.length; j++) {
        category_id = parentCategorys[j].category_id;
        if (document.getElementById("parent_cat_section_" + category_id)) {
          if (
            document.getElementById("parent_cat_section_" + category_id)
              .parentNode
          ) {
            let p = document.getElementById(
              "parent_cat_section_" + category_id
            ).parentNode;
            p.style.display = "block";
          }
        }
      }
    }
  }, [checkTypeParentCat, parentCategorys]);

  const clearAllSelection = () => {
    let items = document.getElementsByName("check");
    for (let i = 0; i < items.length; i++) {
      if (items[i].type == "checkbox") {
        items[i].checked = false;
      }
    }
    props
      .callRequest(
        "GET",
        API_GET_FILTERED_PROUCTS + "?user_id=" + props.SelectedUserId,
        true
      )
      .then((res) => {
        setProducts(res.data);
        setCheckTypeSize([]);
        setCheckTypeSubCat([]);
        setCheckTypeParentCat([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div>
        <div className="choose-design-main-block">
          <div className="titel-block">
            <h2 className="titel">Choose a Design</h2>
          </div>
          <div className="choose-design-block">
            <div className="left-bar-main">
              <h2 className="leftbar-titel">Sort By</h2>
              <div className="search-box">
                <FormGroup>
                  <Label for="sortbySearch">
                    <i className="fas fa-search"></i>
                  </Label>
                  <Input
                    type="search"
                    name="product_name"
                    id="sortBySearch"
                    placeholder="Search"
                    value={searchInputs.product_name}
                    onChange={(e) => onChangeAction(e)}
                    autoComplete="off"
                  />
                </FormGroup>
              </div>
              <div className="clear-all-selections">
                <Button color="primary" onClick={clearAllSelection}>
                  Clear all Selections
                </Button>
              </div>
              <div className="sortby-all-listbox">
                <div className="selections-listbox type">
                  <h3 className="listbox-titel">Type</h3>
                  <ul className="lselections-list">
                    <li>
                      <div className="list-checkbox">
                        <FormGroup>
                          <Input
                            id="myTemplates"
                            name="check"
                            type="checkbox"
                          />
                          <Label check for="myTemplates">
                            My Templates
                          </Label>
                        </FormGroup>
                      </div>
                    </li>
                    <li>
                      <div className="list-checkbox">
                        <FormGroup>
                          <Input
                            id="newProducts"
                            name="check"
                            type="checkbox"
                          />
                          <Label check for="newProducts">
                            New Products
                          </Label>
                        </FormGroup>
                      </div>
                    </li>
                    <li>
                      <div className="list-checkbox">
                        <FormGroup>
                          <Input
                            id="mostPopular"
                            name="check"
                            type="checkbox"
                          />
                          <Label check for="mostPopular">
                            Most Popular
                          </Label>
                        </FormGroup>
                      </div>
                    </li>
                    {parentCategorys &&
                      parentCategorys.map((item, j) => {
                        return (
                          <>
                            {/* <li key={item.category_id}> */}
                            <li key={j}>
                              <div className="list-checkbox">
                                <FormGroup>
                                  <Input
                                    name="check"
                                    type="checkbox"
                                    id={
                                      "parent_category_id_" + item.category_id
                                    }
                                    onChange={(e) =>
                                      handleChangeParentCat(e, item.category_id)
                                    }
                                  />
                                  <Label
                                    check
                                    for={
                                      "parent_category_id_" + item.category_id
                                    }
                                  >
                                    {item.category_name}
                                  </Label>
                                </FormGroup>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>

                <div className="selections-listbox size">
                  <h3 className="listbox-titel">Size</h3>
                  <ul className="lselections-list">
                    {templateSizes &&
                      templateSizes.map((item, j) => {
                        return (
                          <>
                            {/* <li key={item.size_id}> */}
                            <li key={j}>
                              <div className="list-checkbox">
                                <FormGroup>
                                  <Input
                                    name="check"
                                    type="checkbox"
                                    id={"size_id_" + item.size_id}
                                    onChange={(e) =>
                                      handleChangeSize(e, item.size_id)
                                    }
                                  />
                                  <Label check for={"size_id_" + item.size_id}>
                                    {item.size_name}
                                  </Label>
                                </FormGroup>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>

                <div className="selections-listbox category">
                  <h3 className="listbox-titel">Category</h3>
                  <ul className="lselections-list">
                    {childCategorys &&
                      childCategorys.map((item, j) => {
                        return (
                          <>
                            <li key={item.category_id}>
                              <div className="list-checkbox">
                                <FormGroup>
                                  <Input
                                    name="check"
                                    type="checkbox"
                                    id={"child_category_id_" + item.category_id}
                                    onChange={(e) =>
                                      handleChangeSubCat(e, item.category_id)
                                    }
                                  />
                                  <Label
                                    check
                                    for={
                                      "child_category_id_" + item.category_id
                                    }
                                  >
                                    {item.category_name}
                                  </Label>
                                </FormGroup>
                              </div>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div id="see_all_product_sections" style={{ display: "none" }}>
              <div className="products-contener-main">
                <div className="choose-all-products-box-main">
                  <div className="choose-products-list-block">
                    <h2 className="titel yellow-color-text">
                      {seeAllSection.parent_category_name}
                    </h2>
                    <div className="header-right-block">
                      <span
                        className="btn see-all-button"
                        onClick={closeProductSection}
                      >
                        Close
                      </span>
                    </div>
                    <h4 className="sub-titel">{seeAllSection.size_name}</h4>
                    <div className="products-list-block">
                      <ul className="products-list">
                        {seeAll &&
                          seeAll.map((item, j) => {
                            return (
                              <>
                                <li
                                  key={
                                    item.category_id +
                                    "_" +
                                    item.product_id +
                                    "_" +
                                    j
                                  }
                                >
                                  <a
                                    onClick={(e) =>
                                      handleNextProduct(
                                        item.product_id,
                                        item.imagepathname,
                                        item.size_id,
                                        item.category_id,
                                        item.category_name
                                      )
                                    }
                                  >
                                    <div className="products-box">
                                      <div className="product-image">
                                        <img
                                          alt="reiprintmail"
                                          src={
                                            item.imagepathname
                                              ? process.env
                                                  .REACT_APP_PUBLIC_URL_PHOTOPATH +
                                                item.imagepathname
                                              : require("../../assets/img/product-noimage.png")
                                                  .default
                                          }
                                        />
                                      </div>
                                      <div className="product-details">
                                        <h4 className="titel">
                                          {item.product_name}
                                        </h4>
                                        <p className="size">
                                          {item.category_name}
                                        </p>
                                        <p className="details">
                                          {item.size_name}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </li>
                              </>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="all_product_sections">
              <div className="products-contener-main">
                <div className="choose-all-products-box-main">
                  <div className="choose-products-list-block new-products">
                    <div className="header-block">
                      <h2 className="titel">New Products</h2>
                    </div>
                    <div className="products-list-block">
                      <ul className="products-list">
                        {newProducts &&
                          newProducts.map((newProd, index) => (
                            <li key={index}>
                              <a
                                onClick={(e) =>
                                  handleNextProduct(
                                    newProd.product_id,
                                    newProd.imagepathname,
                                    newProd.size_id,
                                    newProd.category_id,
                                    newProd.category_name
                                  )
                                }
                              >
                                <div className="products-box">
                                  <div className="product-image">
                                    <img
                                      alt="reiprintmail"
                                      src={
                                        newProd.imagepathname
                                          ? process.env
                                              .REACT_APP_PUBLIC_URL_PHOTOPATH +
                                            newProd.imagepathname
                                          : require("../../assets/img/product-noimage.png") //product1
                                              .default
                                      }
                                    />
                                  </div>
                                  <div className="product-details">
                                    <h4 className="titel">
                                      {newProd.product_name}
                                    </h4>
                                    <p className="size">
                                      {newProd.category_name}
                                    </p>
                                    <p className="details">
                                      {newProd.size_name}
                                    </p>
                                  </div>
                                </div>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>

                  {Object.keys(products).map((cat, index) => {
                    return (
                      <>
                        {Object.keys(products[cat]).length === 0 ? null : (
                          <div
                            key={index}
                            className="choose-products-list-block"
                          >
                            <div className="header-block">
                              <div className="header-left-block">
                                <h2 className="titel yellow-color-text">
                                  {cat}
                                </h2>
                              </div>
                            </div>
                            {Object.keys(products[cat]).map((size, i) => {
                              return (
                                <>
                                  <div
                                    className="header-block"
                                    id={
                                      "parent_cat_section_" +
                                      products[cat][size][0].parent_category_id
                                    }
                                  >
                                    <div className="header-left-block">
                                      <h4 key={i} className="sub-titel">
                                        {size}
                                      </h4>
                                    </div>
                                    <div className="header-right-block">
                                      <span
                                        className="btn see-all-button"
                                        onClick={() =>
                                          fetchSizeWiseAllProducts(
                                            products[cat][size][0].category_id,
                                            products[cat][size][0]
                                              .parent_category_id,
                                            products[cat][size][0].size_id
                                          )
                                        }
                                      >
                                        See all
                                      </span>
                                    </div>
                                  </div>
                                  <div className="products-list-block">
                                    <ul className="products-list">
                                      {products[cat][size].map((item, j) => {
                                        return (
                                          <>
                                            <li
                                              key={
                                                item.category_id +
                                                "_" +
                                                item.product_id +
                                                "_" +
                                                j
                                              }
                                            >
                                              <a
                                                onClick={(e) =>
                                                  handleNextProduct(
                                                    item.product_id,
                                                    item.imagepathname,
                                                    item.size_id,
                                                    item.category_id,
                                                    item.category_name
                                                  )
                                                }
                                              >
                                                <div className="products-box">
                                                  <div className="product-image">
                                                    <img
                                                      alt="reiprintmail"
                                                      src={
                                                        item.imagepathname
                                                          ? process.env
                                                              .REACT_APP_PUBLIC_URL_PHOTOPATH +
                                                            item.imagepathname
                                                          : require("../../assets/img/product-noimage.png")
                                                              .default
                                                      }
                                                    />
                                                  </div>
                                                  <div className="product-details">
                                                    <h4 className="titel">
                                                      {item.product_name}
                                                    </h4>
                                                    <p className="size">
                                                      {item.category_name}
                                                    </p>
                                                    <p className="details">
                                                      {item.size_name}
                                                    </p>
                                                  </div>
                                                </div>
                                              </a>
                                            </li>
                                          </>
                                        );
                                      })}
                                    </ul>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProductOrder;
