import React, { useState, useEffect } from "react";
import "../../assets/css/importTemplate.css";
import Swal from "sweetalert2";
import moment from "moment";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  CardTitle,
  CardImg,
  FormGroup,
  Label,
  Input,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  ModalFooter,
} from "reactstrap";
import {
  API_GET_AFFILIATE,
  API_GET_USER_PERMISSION,
  API_USERS,
  API_ADD_TO_CART,
  API_ATTRIBUTES,
  API_ADD_TO_ORDER,
  API_GET_ORDER_DETAIL,
  API_GET_ALL_SAVED_CARD,
  API_CREATE_CONFIRM_ORDER,
  API_UPDATE_CONFIRM_ORDER,
  API_UPDATE_ORDER,
  API_UPDATE_CAMPAIGN,
  API_GET_PROOF,
  API_UPDATE_CART,
  API_PAYMENT_SETTINGS,
  API_UPLOAD_CAMPAIGN_CSV,
  API_APPLY_DISCOUNT,
  API_CANCEL_ORDER,
  API_CANCEL_MAILER,
  API_ORDER_EDIT_EXPORT_CSV,
  API_ORDER_EDIT_IMPORT_CSV,
  API_UPDATE_IS_PROOF_APPROVED,
  API_DOWNLOAD_ZIP_ORDER,
  API_ORDER_MAILER,
  API_COPY_ORDER_MAILER_DATA_TO_MAILING_DATE_RECORD,
  API_CHANGE_MAILER_PAYMENT_STATUS,
  API_CHANGE_NORMAL_ORDER_PAYMENT_STATUS,
  API_GET_ORDER_CONFIRM_PROOF,
  API_PAYMENT_ACCOUNT_STORAGE_FORM,
  API_PROFILE,
  API_GET_SALES_REP,
} from "../../config/Api";
import { Link } from "react-router-dom";
import CommissionOverrideForm from "./CommissionOverrideForm";
import UserFrom from "../Users/UserForm";
import SelectUserForm from "../Subscriptions/SelectUserForm";
import ProductDetail from "./ProductDetail";
import MailingList from "../CRM/MailingList";
import { useHistory } from "react-router-dom";
import CCForm from "../Billing/CCForm";
import CrmContacts from "../CRM/CrmContacts";

const CreateOrderApproval = (props) => {
  const { className } = props;
  const [payAsYouGo, setPayAsYouGo] = useState(false);
  const history = useHistory();
  const [openPayment, setOpenPayment] = useState(false);
  const [mailingListDetail, setMailingListDetail] = useState(
    props.mailingListDetail
  );
  const [campaignData, setCampaignData] = useState(props.campaignData);
  const [campaignId, setCampaignId] = useState({
    campaign_id:
      "campaignData" in props &&
        props.campaignData &&
        "campaign_id" in props.campaignData &&
        props.campaignData.campaign_id
        ? props.campaignData.campaign_id
        : null,
  });
  const [userData, setUserData] = useState(props.selectedUserDetail);
  const [affiliateData, setAffiliateData] = useState(props.affiliateDetail);
  const [permissionData, setPermissionData] = useState(props.permissionDetail);
  const [productData, setProductData] = useState(props.productList);
  const [editProductData, setEditProductData] = useState(
    props.edittedProductList
  );
  const [singleproductData, setSingleProductData] = useState(
    props.singleProduct
  );
  const [cartProduct, setCartProduct] = useState(props.productList);
  const [userList, setUserList] = useState();
  const [ProductDetailId, setProductDetailId] = useState(0);
  const [sizeId, setSizeId] = useState(0);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [mailListData, setMailListData] = useState(props.addMailList);
  const [draftButton, setDraftButton] = useState({ disabled: false });
  const [cartButton, setCartButton] = useState({
    button_name: "Add all items to user Cart",
    color: "danger",
    disabled: false,
  });
  const [cartId, setCartId] = useState(
    "cartId" in props.match.params ? props.match.params.cartId : null
  );
  const [editStatus, setEditStatus] = useState(
    "edit" in props.match.params ? props.match.params.edit : null
  );
  const [attrs, setAttrs] = useState([]);
  const [overrideAmount, setOverrideAmount] = useState("");
  const [subComm, setSubComm] = useState(false);
  const closeComm = () => setSubComm(!subComm);
  const [updateProduct, setUpdateProduct] = useState(false);
  const [orderId, setOrderId] = useState(
    "orderId" in props && props.orderId ? props.orderId : 0
  );
  const [orderDetails, setOrderDetails] = useState(
    "draftOrderDetail" in props &&
      props.draftOrderDetail &&
      "OrderDetails" in props.draftOrderDetail &&
      props.draftOrderDetail["OrderDetails"]
      ? props.draftOrderDetail
      : { partial_lockout_status: 0 }
  );
  const [productDetailModal, setProductDetailModal] = useState(false);
  const [getAllPaymentList, setAllPaymentList] = useState();
  const [selectedPayment, setSelectedPayment] = useState();
  const [orderDetail, setOrderDetail] = useState();
  const [showAddToCard, setShowAddToCard] = useState(false);
  const [showEditForm, setEditForm] = useState(false);
  const [productClientApproval, setProductClientApproval] = useState([]);
  const [selectedProductForEdit, setSelectedProdcutForEdit] = useState();
  const [clientApproveProduct, setClientApprovedProduct] = useState({});

  const [attributes, setAttributes] = useState(props.attributes);

  const [postage, setPostage] = useState(props.postage);

  const [envelope, setEnvelope] = useState(props.envelope);
  const [paper, setPaper] = useState(props.paper);
  const [ink, setInk] = useState(props.ink);
  const [accTXT, setAccTXT] = useState(props.accTXT);
  const [accuPIX, setAccuPIX] = useState(props.accuPIX);
  const [editItemIndex, setEditItemIndex] = useState(null);
  const [voidProcessingFee, setVoidProcessingFee] = useState(0);
  const [orderStatus, setOrderStatus] = useState(props.orderStatus);
  const [downloadedZip, setDownloadedZip] = useState();
  const [eventType, setEventType] = useState();
  const [isPayAsYouGoOrder, setIsPayAsYouGoOrder] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [clickType, setClickType] = useState();
  const [paymentMode, setPaymentMode] = useState([]);
  const [IframeUrl, setIframeUrl] = useState();
  const [loading, setLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState();
  const [formData, setFormData] = useState({
    user_id: "userId" in props ? props.userId : props.getUserInfo().user_id,
    payment_method: null,
    company_name: null,
    billing_city: null,
    billing_state: null,
  });
  const [salesRep, setSalesRep] = useState([]);
  const [UploadedFiles, setUploadedFiles] = useState(props.UploadedFiles);
  const toggleCardPayment = () => {
    setPaymentModal(!paymentModal);
  };

  const [couponCode, setCouponCode] = useState();
  const [couponApplied, setCouponApplied] = React.useState(false);

  useEffect(() => {
    props.postage && setPostage(props.postage);
  }, [props.postage]);

  useEffect(() => {
    setEnvelope(props.envelope);
  }, [props.envelope]);
  useEffect(() => {
    setPaper(props.paper);
  }, [props.paper]);

  useEffect(() => {
    setInk(props.ink);
  }, [props.ink]);

  useEffect(() => {
    setAccTXT(props.accTXT);
  }, [props.accTXT]);

  useEffect(() => {
    setAccuPIX(props.accuPIX);
  }, [props.accuPIX]);

  useEffect(() => {
    // console.log("Order Detail", orderDetails);
  }, [orderDetails]);

  useEffect(() => {
    props.UploadedFiles && setUploadedFiles(props.UploadedFiles);
  }, [props.UploadedFiles]);

  useEffect(() => {
    props.eventType && setEventType(props.eventType);
  }, [props.eventType]);

  useEffect(() => {
    setOrderStatus(props.orderStatus);
  }, [props.orderStatus]);

  useEffect(() => {
    setCartProduct(props.productList);
  }, [props.productList]);

  useEffect(() => {
    fetchSalesRep();
  }, []);

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  const fetchSalesRepName = (id) => {
    const salesRepName = salesRep.filter((item) => {
      return item.user_id == id;
    });
    if (salesRepName.length > 0) {
      return salesRepName[0].name;
    }
  };

  useEffect(() => {
    setMailListData(props.addMailList);
  }, [props.addMailList]);
  const updateProdcutApproval = (
    e,
    product_id,
    index = null,
    mailing_date_id
  ) => {
    let temp = productClientApproval;
    let tempPre = { ...clientApproveProduct };
    if (e.target.checked) {
      tempPre[product_id] = true;
      temp.push(true);
    } else {
      tempPre[product_id] = false;
      temp.pop();
    }

    if (index != null) {
      let tempCartProduct = [...cartProduct];
      tempCartProduct[index]["is_proof_approved"] = e.target.checked ? 1 : 0;
      // setCartProduct(tempCartProduct);
    }
    setClientApprovedProduct(tempPre);
    setProductClientApproval(temp);
    if (orderStatus) {
      updateMailerApproved(mailing_date_id, e.target.checked ? 1 : 0);
    }
  };

  const updateMailerApproved = (mailing_date_id, proofStatus) => {
    if (orderId) {
      props
        .callRequest("POST", API_UPDATE_IS_PROOF_APPROVED, true, {
          order_id: Number(orderId),
          mailing_date_id: mailing_date_id,
          is_proof_approved: proofStatus,
        })
        .then((res) => {
          // console.log("proof updated");

          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: res.data.Message,
          });
        });
    }
  };

  const proofAll = () => {
    let temp = [];
    let tempPre = {};
    for (let i = 0; i < cartProduct.length; i++) {
      temp.push(true);
      tempPre[cartProduct[i]["product_id"]] = true;
    }
    setClientApprovedProduct(tempPre);
    setProductClientApproval(temp);
  };

  const [disablePaymentMethod, setDisablePaymentMethod] = useState(false);
  const validateConfirmOrder = () => {
    let disablePaymentStatus = false;

    if (
      walletSelected &&
      walletSelected == 1 &&
      applyDiscountDetail &&
      "charge_able_amount" in applyDiscountDetail &&
      applyDiscountDetail.charge_able_amount == 0
    ) {
      disablePaymentStatus = true;
    }

    setDisablePaymentMethod(disablePaymentStatus);
    let info_id =
      selectedPayment && "info_id" in selectedPayment && selectedPayment.info_id
        ? selectedPayment.info_id
        : "";

    if (disablePaymentStatus == false && info_id == "") {
      Swal.fire(
        "Validation!",
        "Please select a valid payment method.",
        "error"
      );
      return false;
    }

    // if (
    //   props.campaignDetail &&
    //   "uplodedFile" in props.campaignDetail &&
    //   props.campaignDetail.uplodedFile &&
    //   validateProof() != true
    // ) {
    //   Swal.fire(
    //     "Validation!",
    //     "All products must be 'Client approved', Please validate it",
    //     "error"
    //   );
    //   return false;
    // }
    return true;
  };

  const validateProof = () => {
    // setClientApprovedProduct;
    // cartProduct;
    let approveArr = [];
    for (let i = 0; i < cartProduct.length; i++) {
      if (clientApproveProduct[cartProduct[i]["product_id"]]) {
        approveArr.push(true);
      }
    }

    return cartProduct.length == approveArr.length ? true : false;
  };

  // const togglePayment = () => {
  //   setOpenPayment(!openPayment);
  //   getUserCardListForPayment();
  // };

  const toggleEditForm = () => {
    setEditForm(!showEditForm);
  };

  const chooseDesignDetails = () => {
    setProductDetailModal(!productDetailModal);
    setProductDetailId();
  };

  useEffect(() => {
    fetchAffiliates();
    getUserList();
    fetchUserRolePermission();
    fetchAttributes();
    getUserCardListForPayment();
    getPaymentSetting();
  }, []);
  useEffect(() => {
    if (orderId !== 0) {
      getOrderDetails(orderId);
    }
  }, [orderId]);

  useEffect(() => {
    if (productData && productData.length !== 0) {
      addDrop();
    }
    if (
      productData &&
      productData.length !== 0 &&
      singleproductData &&
      singleproductData.length !== 0
    ) {
      cartProductDetails();
    }
  }, [productData, singleproductData]);

  const fetchAttributes = () => {
    props.callRequest("GET", API_ATTRIBUTES, true).then((res) => {
      setAttrs(res.data);
    });
  };
  const fetchAffiliates = () => {
    props
      .callRequest("GET", API_GET_AFFILIATE, true)
      .then((res) => {
        setAffiliateData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatedProduct = (productInfo) => {
    let cartDetail = cartProduct;

    let indexF = editItemIndex;
    if (indexF == null) {
      for (let i = 0; i < cartDetail.length; i++) {
        if (
          "product_id" in cartDetail[i] &&
          "product_id" in productInfo &&
          cartDetail[i]["product_id"] == productInfo.product_id
        ) {
          indexF = i;
          break;
        }

        if (
          "item_id" in cartDetail[i] &&
          "item_id" in productInfo &&
          cartDetail[i]["item_id"] == productInfo.item_id
        ) {
          indexF = i;
          break;
        }
      }
    }

    if (indexF != null) {
      productInfo.quantity =
        "estimated_quantity" in productInfo
          ? productInfo.estimated_quantity
          : productInfo.quantity;
      cartDetail[indexF] = { ...productInfo };
      // setCartProduct([...cartDetail]);
      props.setProductList([...cartDetail]);
      setEditItemIndex(null);
    }
    if (orderId) {
      getOrderDetails(orderId);
    }

    //return productInfo;
  };

  const ShowAttributeName = (id) => {
    try {
      if (attrs !== undefined) {
        let attr = attrs.filter((rs) => rs && rs.attribute_id == id);
        let attrName = attr && attr.length > 0 ? attr[0].attribute_name : "--";
        return attrName;
      } else {
        return "--";
      }
    } catch (error) {
      return "--";
      console.log(error);
    }
  };
  const getUserList = () => {
    props.callRequest("GET", API_USERS, true, null).then((res) => {
      let tempData = [];
      if (res.data.length > 0) {
        res.data.map((item) => {
          tempData.push({ ...item, name: item.username, value: item.user_id });
        });
      }
      setUserList(tempData);
      if (userData && userData.user_id) {
        let userDetail = tempData.filter(
          (ele) => ele.user_id == userData.user_id
        )[0];
        setUserData({ ...userDetail });
      }
    });
  };

  const getFormattedDate = (timestamp) => {
    let date = new Date(timestamp);
    return (
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date.getDate()).slice(-2) +
      "-" +
      date.getFullYear()
    );
  };
  const fetchUserRolePermission = () => {
    props
      .callRequest(
        "GET",
        API_GET_USER_PERMISSION + props.getUserInfo().user_id,
        true
      )
      .then((res) => {
        setPermissionData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const oppupCreateUser = () => setCreateUserModal(!createUserModal);
  const toggle = () => setModal(!modal);

  const toggleCommissions = () => {
    toggle();
  };

  const affUsr =
    affiliateData &&
    affiliateData.filter((aff) => {
      return aff.affiliate_id.id == (userData && userData.affiliate_id);
    });

  const changeSelectedUser = () => {
    oppupCreateUser();
    //setUserData(null);
    //setCampaignData({ ...campaignData, user: null, selectedUser: null });
  };

  const receiveUserId = (selectUsr) => {
    setUserData({ ...selectUsr });
    //setCampaignData({ ...campaignData, user: selectUsr.user_id });
  };

  const editUserPop = () => {
    oppupCreateUser();
  };

  const refreshList = () => {
    getUserList();
    oppupCreateUser();
    fetchProfileInfo();
  };
  const addDrop = () => {
    productData.map((prd, index) => {
      return (prd["drop"] = `DROP ${index + 1}`);
    });
  };

  const cartProductDetails = () => {
    let cartProd = [];
    let currProductData = productData;
    let currSingleProductData = singleproductData;

    for (let i = 0; i < productData.length; i++) {
      let cartPrd = productData[i];
      if (currSingleProductData[i]) {
        cartPrd = Object.assign(currSingleProductData[i], currProductData[i]);
      }
      cartProd[i] = { ...cartPrd };
    }
    setCartProduct(cartProd);
  };
  const changeOverrideAmt = (e) => {
    const { name, value } = e.target;
    setOverrideAmount(value);
  };
  const confirmOverride = (e) => {
    //  if (!validateOverrideForm()) {
    //    return false;
    //  }

    //  setSubscription({ ...Subscription, override_amount: overrideAmount });
    closeComm();
  };
  const handleAddToCart = () => {
    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let cart_detail = cartProduct.map((cartPrd, ind) => {
      let temVar = {
        // ...cartPrd,
        mailer: "drop" in cartPrd ? cartPrd.drop : "DROP " + (ind + 1),
        textResponse1: "drop" in cartPrd ? cartPrd.drop : "DROP " + (ind + 1),
        responsetxt1: "drop" in cartPrd ? cartPrd.drop : "DROP " + (ind + 1),

        quantity:
          "quantity" in cartPrd
            ? Number(cartPrd.quantity)
            : Number(cartPrd.estimated_quantity),
        item_id: Number(
          "item_id" in cartPrd && cartPrd.item_id
            ? cartPrd.item_id
            : cartPrd.product_id
        ),
        template_id:
          "template" in cartPrd
            ? Number(cartPrd.template)
            : Number(cartPrd.template_id),
        coupon_discount_price: 0,
        date:
          "mailing_date" in cartPrd
            ? moment(new Date(cartPrd.mailing_date)).format("MM-DD-YYYY") //.unix().toString()
            : "date" in cartPrd
              ? moment(cartPrd.date, "MM-DD-YYYY").format("MM-DD-YYYY") //.toString()
              : moment(new Date()).format("MM-DD-YYYY"), //.unix().toString(),
        is_proof_approved:
          "is_proof_approved" in cartPrd
            ? Number(cartPrd.is_proof_approved)
            : 0,
        is_send_without_return_address:
          "is_send_without_return_address" in cartPrd
            ? Number(cartPrd.is_send_without_return_address)
            : 0,
        sub_category_id: Number(cartPrd.category_id),
      };
      let paper_id =
        "paper_id" in cartPrd
          ? Number(cartPrd.paper_id)
          : "PaperColor" in cartPrd
            ? Number(cartPrd["PaperColor"])
            : "";

      let ink_id =
        "ink_id" in cartPrd
          ? cartPrd.ink_id
          : "InkColor" in cartPrd
            ? Number(cartPrd["InkColor"])
            : "";

      let accutext_id =
        "accutext_id" in cartPrd ? Number(cartPrd.accutext_id) : "";
      let accupix_id =
        "accupix_id" in cartPrd ? Number(cartPrd.accupix_id) : "";
      let envelope_id =
        "envelope_id" in cartPrd
          ? Number(cartPrd.envelope_id)
          : "Envelope" in cartPrd
            ? Number(cartPrd.Envelope)
            : "";
      let postage_id =
        "postage_id" in cartPrd
          ? Number(cartPrd.postage_id)
          : "Postage" in cartPrd
            ? Number(cartPrd.Postage)
            : "";

      if (paper_id) {
        temVar.paper_id = paper_id;
      }

      if (ink_id) {
        temVar.ink_id = ink_id;
      }

      if (accutext_id) {
        temVar.accutext_id = accutext_id;
      }

      if (accupix_id) {
        temVar.accupix_id = accupix_id;
      }

      if (envelope_id) {
        temVar.envelope_id = envelope_id;
      }

      if (postage_id) {
        temVar.postage_id = postage_id;
      }
      if (cartPrd.textResponse1) {
        temVar.textResponse1 = cartPrd.textResponse1;
      }
      if (cartPrd.responsetxt1) {
        temVar.responsetxt1 = cartPrd.responsetxt1;
      }
      if (cartPrd.textEmail1) {
        temVar.textEmail1 = cartPrd.textEmail1;
      }
      if (cartPrd.textEmail2) {
        temVar.textEmail2 = cartPrd.textEmail2;
      }

      if (cartPrd.textEmail3) {
        temVar.textEmail3 = cartPrd.textEmail3;
      }
      return temVar;
    });

    const post_data = {
      user: campaignData.user.toString(),
      campaign: Number(campaignId.campaign_id),
      CartProducts: cart_detail,
      is_pay_as_you_go: payAsYouGo ? 1 : 0,
    };
    /* calendly changes start */
    if (
      "OrderDetails" in orderDetails &&
      orderDetails["OrderDetails"]["is_calendly"]
    ) {
      post_data["is_calendly"] = Number(
        orderDetails["OrderDetails"]["is_calendly"]
      );
    }
    /* calendly changes end */
    let mailinglist_attr_id =
      mailListData &&
        "mailinglist_attr_id" in mailListData &&
        mailListData.mailinglist_attr_id
        ? Number(mailListData.mailinglist_attr_id)
        : "";
    let desired_quantity =
      mailListData &&
        "desired_quantity" in mailListData &&
        mailListData.desired_quantity
        ? Number(mailListData.desired_quantity)
        : "";
    if (mailinglist_attr_id != "") {
      post_data["mailinglist_attr_id"] = Number(mailinglist_attr_id);
    }

    if (desired_quantity != "") {
      post_data["mailingqty"] = Number(desired_quantity);
    }

    let apiEnd = API_ADD_TO_CART;
    if (cartId) {
      post_data.cartid = cartId;
      apiEnd = API_UPDATE_CART;
    }

    post_data.UploadedFiles = UploadedFiles;

    props
      .callRequest("POST", apiEnd, true, post_data)
      .then((res) => {
        Swal.close();
        setCartId(res.data["CartID"]);
        setCartButton({
          button_name: "All items saved to user cart",
          color: "warning",
          disabled: false,
        });
      })
      .catch((err) => {
        Swal.close();
        console.log(err);
      });
  };
  const handleAddToDraft = (updateNow = false) => {
    if (orderStatus && orderStatus != 2) {
      return false;
    }

    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let order_detail = cartProduct.map((cartPrd, ind) => {
      let temVar = {
        // ...cartPrd,
        mailer: "drop" in cartPrd ? cartPrd.drop : "DROP " + (ind + 1),
        quantity:
          "quantity" in cartPrd
            ? Number(cartPrd.quantity)
            : Number(cartPrd.estimated_quantity),
        item_id:
          "item_id" in cartPrd ? Number(cartPrd.item_id) : cartPrd.product_id,
        template_id:
          "template" in cartPrd
            ? Number(cartPrd.template)
            : Number(cartPrd.template_id),
        coupon_discount_price: 0,
        date:
          "mailing_date" in cartPrd
            ? moment(new Date(cartPrd.mailing_date)).format("MM-DD-YYYY") //.unix().toString()
            : "date" in cartPrd
              ? moment(cartPrd.date, "MM-DD-YYYY").format("MM-DD-YYYY") //.toString()
              : moment(new Date()).format("MM-DD-YYYY"), //.unix().toString(),
        is_proof_approved:
          "is_proof_approved" in cartPrd
            ? Number(cartPrd.is_proof_approved)
            : 0,
        is_send_without_return_address:
          "is_send_without_return_address" in cartPrd
            ? Number(cartPrd.is_send_without_return_address)
            : 0,
        sub_category_id: Number(cartPrd.category_id),
      };

      let paper_id =
        "paper_id" in cartPrd
          ? Number(cartPrd.paper_id)
          : "PaperColor" in cartPrd
            ? Number(cartPrd["PaperColor"])
            : "";

      let ink_id =
        "ink_id" in cartPrd
          ? cartPrd.ink_id
          : "InkColor" in cartPrd
            ? Number(cartPrd["InkColor"])
            : "";

      let accutext_id =
        "accutext_id" in cartPrd ? Number(cartPrd.accutext_id) : "";
      let accupix_id =
        "accupix_id" in cartPrd ? Number(cartPrd.accupix_id) : "";
      let envelope_id =
        "envelope_id" in cartPrd
          ? Number(cartPrd.envelope_id)
          : "Envelope" in cartPrd
            ? Number(cartPrd.Envelope)
            : "";
      let postage_id =
        "postage_id" in cartPrd
          ? Number(cartPrd.postage_id)
          : "Postage" in cartPrd
            ? Number(cartPrd.Postage)
            : "";

      if (paper_id) {
        temVar.paper_id = paper_id;
      }

      if (ink_id) {
        temVar.ink_id = ink_id;
      }

      if (accutext_id) {
        temVar.accutext_id = accutext_id;
      }

      if (accupix_id) {
        temVar.accupix_id = accupix_id;
      }

      if (envelope_id) {
        temVar.envelope_id = envelope_id;
      }

      if (postage_id) {
        temVar.postage_id = postage_id;
      }
      if (cartPrd.textResponse1) {
        temVar.textResponse1 = cartPrd.textResponse1;
      }
      if (cartPrd.responsetxt1) {
        temVar.responsetxt1 = cartPrd.responsetxt1;
      }
      if (cartPrd.textEmail1) {
        temVar.textEmail1 = cartPrd.textEmail1;
      }
      if (cartPrd.textEmail2) {
        temVar.textEmail2 = cartPrd.textEmail2;
      }

      if (cartPrd.textEmail3) {
        temVar.textEmail3 = cartPrd.textEmail3;
      }
      return temVar;
    });
    const post_data = {
      user: userData.user_id.toString(), //campaignData.user,
      campaign: campaignId.campaign_id ? campaignId.campaign_id : "",
      OrderProducts: order_detail,
      is_pay_as_you_go: payAsYouGo ? 1 : 0,
      //mailinglist_attr_id: 31,
      //mailingqty: 2,
      UploadedFiles: [
        {
          filename: "",
          filepath: "",
        },
      ],
    };

    /* calendly changes start */
    if (
      "OrderDetails" in orderDetails &&
      orderDetails["OrderDetails"]["is_calendly"]
    ) {
      post_data["is_calendly"] = Number(
        orderDetails["OrderDetails"]["is_calendly"]
      );
    }
    /* calendly changes end */

    let updateStatus = true;
    // orderDetails["Order Details"]["orderid"];
    let APIAct = API_ADD_TO_ORDER;
    if (
      "OrderDetails" in orderDetails &&
      orderDetails["OrderDetails"]["order_id"]
    ) {
      post_data["order_id"] = Number(orderDetails["OrderDetails"]["order_id"]);
      APIAct = API_UPDATE_ORDER;
      updateStatus = false;
    }

    let mailinglist_attr_id =
      mailListData &&
        "mailinglist_attr_id" in mailListData &&
        mailListData.mailinglist_attr_id
        ? mailListData.mailinglist_attr_id
        : "";
    let desired_quantity =
      mailListData &&
        "desired_quantity" in mailListData &&
        mailListData.desired_quantity
        ? mailListData.desired_quantity
        : "";
    if (mailinglist_attr_id != "") {
      post_data["mailinglist_attr_id"] = Number(mailinglist_attr_id);
    }

    if (desired_quantity != "") {
      post_data["mailingqty"] = Number(desired_quantity);
    }

    post_data.UploadedFiles = UploadedFiles;

    try {
      props
        .callRequest("POST", APIAct, true, post_data)
        .then((res) => {
          Swal.close();
          let order_id = "OrderID" in res.data ? res.data["OrderID"] : orderId;
          setOrderId(order_id);
          getOrderDetails(order_id);

          // console.log(updateStatus, res.status);
          // if (res.statusText === "Created" && updateStatus == true) {
          //   setOrderId(res.data["OrderID"]);
          //   setDraftButton({
          //     disabled: true,
          //   });
          //   getOrderDetails(res.data["OrderID"]);
          // } else {
          //   orderId && getOrderDetails(orderId);
          // }
        })
        .catch((err) => {
          Swal.close();
          let msg =
            err.response &&
              "Error" in err.response.data &&
              err.response.data["Error"]
              ? err.response.data["Error"]
              : "Something went wrong, Please try again";
          Swal.fire("Error!", msg, "error");
        });
    } catch (e) {
      console.log("Error", e);
    }
  };
  const getOrderDetails = (id) => {
    if (id) {
      props
        .callRequest("GET", API_GET_ORDER_DETAIL + id + "/", true)
        .then((res) => {
          setOrderDetails(res.data);
          res.data &&
            "OrderDetails" in res.data &&
            res.data.OrderDetails &&
            "pay_as_you_go" in res.data.OrderDetails &&
            setPayAsYouGo(
              res.data.OrderDetails.pay_as_you_go == "1" ? true : false
            );

          res.data &&
            "OrderDetails" in res.data &&
            res.data.OrderDetails &&
            "pay_as_you_go" in res.data.OrderDetails &&
            setIsPayAsYouGoOrder(
              res.data.OrderDetails.pay_as_you_go == "1" ? true : false
            );
          // setCartProduct(res.data.Product)
          // props.setProductList(res.data.Product)
        });
    }
  };

  const fetchProductDetails = (id, size_id, prd, index) => {
    if ("is_lockout" in prd && prd.is_lockout != 0) {
      Swal.fire(
        "Sorry !!",
        "We can't process your request, Drop already processed",
        "error"
      );
      return 0;
    }

    setEditItemIndex(index);
    chooseDesignDetails();
    setProductDetailId(id);
    setSizeId(size_id);
    setUpdateProduct(true);
    setSelectedProdcutForEdit({ ...prd });
  };
  const getPrice = (priceList, qty, tier) => {
    let pricearray = 0;
    try {
      if (qty >= 1 && qty < 200) {
        pricearray = 0;
      } else if (qty >= 200 && qty < 350) {
        pricearray = 1;
      } else if (qty >= 350 && qty < 500) {
        pricearray = 2;
      } else if (qty >= 500 && qty < 1000) {
        pricearray = 3;
      } else if (qty >= 1000 && qty < 2500) {
        pricearray = 4;
      } else if (qty >= 2500 && qty < 5000) {
        pricearray = 5;
      } else if (qty >= 5000 && qty < 10000) {
        pricearray = 6;
      } else if (qty >= 10000 && qty < 25000) {
        pricearray = 7;
      } else if (qty >= 25000 && qty < 50000) {
        pricearray = 8;
      } else if (qty >= 50000) {
        pricearray = 9;
      } else {
        pricearray = 0;
      }
      let priceData = priceList && priceList.split("|");
      let arraylen = priceData && priceData.length;
      if (pricearray < arraylen) {
        let price = priceData[pricearray];
        return price;
      } else {
        return 0;
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const updateProductFlag = () => {
  //   setUpdateProduct(true);
  // };
  const removeProduct = (index) => {
    Swal.fire({
      title: "Drop name will be reset, do you want to continue?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Ok",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let tempProduct = [...cartProduct];
        tempProduct.splice(index, 1);
        for (var i = 0; i < tempProduct.length; i++) {
          tempProduct[i]["drop"] = "DROP " + (i + 1);
          tempProduct[i]["mailer"] = "DROP " + (i + 1);
        }
        // setCartProduct(tempProduct);
        props.setProductList([...tempProduct]);
      }
    });
  };

  const getUserCardListForPayment = (selectedCard = null) => {
    //API_GET_ALL_SAVED_CARD
    //props.selectedUserDetail.user_id
    props
      .callRequest(
        "GET",
        API_GET_ALL_SAVED_CARD + "?user_id=" + props.selectedUserDetail.user_id,
        true
      )
      .then((res) => {
        setAllPaymentList(res.data);
        selectedCard != null && setPaymentMethod(selectedCard);
      });
  };
  const HandleCardType = (cardType, payment_method) => {
    if (payment_method == "ach") {
      return "ACH";
    } else {
      if (cardType == 3) {
        return "AMEX";
      } else if (cardType == 4) {
        return "VISA";
      } else if (cardType == 5) {
        return "Mastercard";
      } else if (cardType == 6) {
        return "Discover";
      } else {
        return "CC-" + cardType;
      }
    }
  };
  const HandleCardImage = (cardType, payment_method) => {
    if (payment_method == "ach") {
      return (
        <img
          alt="reiprintmail"
          src={require("../../assets/img/card3.png").default}
        />
      );
    } else {
      if (cardType == 3) {
        return (
          <img
            alt="reiprintmail"
            className={
              selectedPayment && "info_id" in selectedPayment && selectedPayment
                ? "selected"
                : ""
            }
            src={require("../../assets/img/carddefault.png").default}
          />
        );
      } else if (cardType == 4) {
        return (
          <img
            alt="reiprintmail"
            src={require("../../assets/img/carddefault.png").default}
          />
        );
      } else if (cardType == 5) {
        return (
          <img
            alt="reiprintmail"
            src={require("../../assets/img/card2.png").default}
          />
        );
      } else if (cardType == 6) {
        return (
          <img
            alt="reiprintmail"
            src={require("../../assets/img/card1.png").default}
          />
        );
      } else {
        return (
          <img
            alt="reiprintmail"
            src={require("../../assets/img/carddefault.png").default}
          />
        );
      }
    }
  };

  const getPaymentUI = (detail) => {
    if (detail.payment_method !== "ach") {
      let arr = [
        <tr
          // className={
          //   selectedPayment &&
          //   "info_id" in selectedPayment &&
          //   selectedPayment.info_id == detail.info_id
          //     ? "payment-selected"
          //     : ""
          // }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <td>
            <input
              type="radio"
              name="selectedPayment"
              defaultChecked={
                selectedPayment &&
                  "info_id" in selectedPayment &&
                  selectedPayment.info_id == detail.info_id
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <div style={{ display: "flex" }}>
              <img
                alt="reiprintmail"
                className={
                  selectedPayment &&
                    "info_id" in selectedPayment &&
                    selectedPayment
                    ? "selected"
                    : ""
                }
                src={require("../../assets/img/carddefault.png").default}
              />
              <div style={{ paddingLeft: "15px" }}>
                <h5>AMEX</h5>
                <p>
                  Card ending in ... <br />
                  {detail.maskedNumber}
                </p>
                <p>Expires {detail.expirationDate}</p>
              </div>
            </div>
          </td>
          <td>
            <p> {detail.nameoncard}</p>
          </td>
          <td>
            <p>
              {detail.expirationDate.substr(0, 2)}/20
              {detail.expirationDate.substr(2)}
            </p>
          </td>
        </tr>,
        <tr
          // className={
          //   selectedPayment &&
          //   "info_id" in selectedPayment &&
          //   selectedPayment.info_id == detail.info_id
          //     ? "payment-selected"
          //     : ""
          // }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <td>
            <input
              type="radio"
              name="selectedPayment"
              defaultChecked={
                selectedPayment &&
                  "info_id" in selectedPayment &&
                  selectedPayment.info_id == detail.info_id
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <div style={{ display: "flex" }}>
              <img
                alt="reiprintmail"
                src={require("../../assets/img/carddefault.png").default}
              />
              <div style={{ paddingLeft: "15px" }}>
                <h5>VISA</h5>
                <p>
                  Card ending in ... <br />
                  {detail.maskedNumber}
                </p>
                <p>Expires {detail.expirationDate}</p>
              </div>
            </div>
          </td>
          <td>
            <p> {detail.nameoncard}</p>
          </td>
          <td>
            <p>
              {detail.expirationDate.substr(0, 2)}/20
              {detail.expirationDate.substr(2)}
            </p>
          </td>
        </tr>,
        <tr
          // className={
          //   selectedPayment &&
          //   "info_id" in selectedPayment &&
          //   selectedPayment.info_id == detail.info_id
          //     ? "payment-selected"
          //     : ""
          // }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <td>
            <input
              type="radio"
              name="selectedPayment"
              defaultChecked={
                selectedPayment &&
                  "info_id" in selectedPayment &&
                  selectedPayment.info_id == detail.info_id
                  ? true
                  : false
              }
            />

            {/* &nbsp;&nbsp; */}
          </td>
          <td>
            <div style={{ display: "flex" }}>
              <img
                alt="reiprintmail"
                src={require("../../assets/img/card2.png").default}
              />
              <div style={{ paddingLeft: "15px" }}>
                <h5>MASTERCARD</h5>
                <p>
                  Card ending in ... <br />
                  {detail.maskedNumber}
                </p>
                <p>Expires {detail.expirationDate}</p>
              </div>
            </div>
          </td>
          <td>
            <p> {detail.nameoncard}</p>
          </td>
          <td>
            <p>
              {detail.expirationDate.substr(0, 2)}/20
              {detail.expirationDate.substr(2)}
            </p>
          </td>
        </tr>,
        <tr
          // className={
          //   selectedPayment &&
          //   "info_id" in selectedPayment &&
          //   selectedPayment.info_id == detail.info_id
          //     ? "payment-selected"
          //     : ""
          // }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <td>
            <input
              type="radio"
              name="selectedPayment"
              defaultChecked={
                selectedPayment &&
                  "info_id" in selectedPayment &&
                  selectedPayment.info_id == detail.info_id
                  ? true
                  : false
              }
            />
          </td>
          <td>
            <div style={{ display: "flex" }}>
              <img
                alt="reiprintmail"
                src={require("../../assets/img/card1.png").default}
              />
              <div style={{ paddingLeft: "15px" }}>
                <h5>DISCOVER</h5>
                <p>
                  Card ending in ... <br />
                  {detail.maskedNumber}
                </p>
                <p>Expires {detail.expirationDate}</p>
              </div>
            </div>
          </td>
          <td>
            <p> {detail.nameoncard}</p>
          </td>
          <td>
            <p>
              {detail.expirationDate.substr(0, 2)}/20
              {detail.expirationDate.substr(2)}
            </p>
          </td>
        </tr>,
      ];

      return parseInt(detail.cardType) - 3 in arr ? (
        arr[parseInt(detail.cardType) - 3]
      ) : (
        <li
          // className={
          //   selectedPayment &&
          //   "info_id" in selectedPayment &&
          //   selectedPayment.info_id == detail.info_id
          //     ? "payment-selected"
          //     : ""
          // }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <img
            alt="reiprintmail"
            src={require("../../assets/img/carddefault.png").default}
          />
          <div style={{ paddingLeft: "15px" }}>
            <h5>CC - {detail.cardType}</h5>
            <p>
              Card ending in ... <br />
              {detail.maskedNumber}
            </p>
            <p>Expires {detail.expirationDate}</p>
          </div>
        </li>
      );
    }
  };

  const getPaymentAchUI = (detail) => {
    if (detail.payment_method == "ach") {
      return (
        <li
          className={
            selectedPayment &&
              "info_id" in selectedPayment &&
              selectedPayment.info_id == detail.info_id
              ? "payment-selected"
              : ""
          }
          key={Math.random()}
          onClick={() => setPaymentMethod(detail)}
        >
          <input
            type="radio"
            name="selectedPayment"
            defaultChecked={
              selectedPayment &&
                "info_id" in selectedPayment &&
                selectedPayment.info_id == detail.info_id
                ? true
                : false
            }
          />
          &nbsp; &nbsp;
          <img
            style={{ width: "100px", height: "65px" }}
            alt="reiprintmail"
            src={require("../../assets/img/card3.png").default}
          />
          <div>
            <div>
              <strong>Bank: ACH</strong>
            </div>

            <div>
              <p>Account #:</p> <p>{detail.maskedNumber}</p>
            </div>
          </div>
        </li>
      );
    }
  };

  useEffect(() => {
    fetchProfileInfo();
  }, []);

  const fetchProfileInfo = () => {
    props
      .callRequest("GET", API_PROFILE + userData.user_id, true)
      .then((res) => {
        setProfileInfo(res.data);
        setFormData({
          ...formData,

          company_name: res.data.user_profile.imprint_company_name,
          billing_city: res.data.user_profile.imprint_return_city,
          billing_state: res.data.user_profile.imprint_return_state,
        });
      });
  };

  const onClickAddCard = (clickType) => {
    setLoading(true);
    setClickType(clickType);
    let latestFormdata = {
      ...formData,
      user_id: userData && userData.user_id,
      payment_method: clickType,
    };
    if (
      // latestFormdata.company_name !== "" &&
      latestFormdata.billing_state !== "" &&
      latestFormdata.billing_city !== ""
    ) {
      props
        .callRequest(
          "POST",
          API_PAYMENT_ACCOUNT_STORAGE_FORM,
          true,
          latestFormdata
        )
        .then((res) => {
          // window.addEventListener("message", receiveMessage, false);
          setIframeUrl(res.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });

      toggleCardPayment();
    } else {
      Swal.fire({
        showConfirmButton: true,
        icon: "error",
        title: "Please set up billing address details in profile first.",
      });
      return false;
    }
  };

  const setPaymentMethod = (detail) => {
    setSelectedPayment({ ...detail });
  };

  const processOrder = () => {
    if (!validateConfirmOrder()) return false;

    let dopError = [];

    let orderId =
      "OrderDetails" in orderDetails &&
        orderDetails["OrderDetails"]["order_id"] &&
        orderDetails["OrderDetails"]["order_id"]
        ? orderDetails["OrderDetails"]["order_id"]
        : "";

    let cart_detail = cartProduct.map((cartPrd, ind) => {
      let temVar = {
        mailer: "drop" in cartPrd ? cartPrd.drop : "DROP " + (ind + 1),
        quantity:
          "quantity" in cartPrd
            ? Number(cartPrd.quantity)
            : Number(cartPrd.estimated_quantity),
        item_id:
          "item_id" in cartPrd
            ? Number(cartPrd.item_id)
            : Number(cartPrd.product_id),
        template_id:
          "template" in cartPrd
            ? Number(cartPrd.template)
            : Number(cartPrd.template_id),
        coupon_discount_price: 0,
        date:
          "mailing_date" in cartPrd
            ? moment(new Date(cartPrd.mailing_date)).format("MM-DD-YYYY") //.unix().toString()
            : "date" in cartPrd
              ? moment(new Date(cartPrd.date)).format("MM-DD-YYYY") //.toString()
              : moment(new Date()).format("MM-DD-YYYY"), //.unix().toString(),
        is_proof_approved:
          "is_proof_approved" in cartPrd
            ? Number(cartPrd.is_proof_approved)
            : 0,
        is_send_without_return_address:
          "is_send_without_return_address" in cartPrd
            ? Number(cartPrd.is_send_without_return_address)
            : 0,
        sub_category_id: Number(cartPrd.category_id),
      };

      let start = moment(new Date(temVar.date), "DD/MM/YYYY");

      let end =
        "mailing_date_limit" in cartPrd &&
          cartPrd.mailing_date_limit &&
          orderStatus == 2
          ? moment(new Date(), "DD/MM/YYYY").add(
            cartPrd.mailing_date_limit,
            "days"
          )
          : moment(new Date(), "DD/MM/YYYY");
      let diff = end.diff(start, "days");

      if (diff > 0) {
        dopError.push(temVar.mailer);
      }

      let paper_id =
        "paper_id" in cartPrd
          ? Number(cartPrd.paper_id)
          : "PaperColor" in cartPrd
            ? Number(cartPrd["PaperColor"])
            : "";

      let ink_id =
        "ink_id" in cartPrd
          ? cartPrd.ink_id
          : "InkColor" in cartPrd
            ? Number(cartPrd["InkColor"])
            : "";

      let accutext_id =
        "accutext_id" in cartPrd ? Number(cartPrd.accutext_id) : "";
      let accupix_id =
        "accupix_id" in cartPrd ? Number(cartPrd.accupix_id) : "";
      let envelope_id =
        "envelope_id" in cartPrd
          ? Number(cartPrd.envelope_id)
          : "Envelope" in cartPrd
            ? Number(cartPrd.Envelope)
            : "";
      let postage_id =
        "postage_id" in cartPrd
          ? Number(cartPrd.postage_id)
          : "Postage" in cartPrd
            ? Number(cartPrd.Postage)
            : "";

      if (paper_id) {
        temVar.paper_id = paper_id;
      }

      if (ink_id) {
        temVar.ink_id = ink_id;
      }

      if (accutext_id) {
        temVar.accutext_id = accutext_id;
      }

      if (accupix_id) {
        temVar.accupix_id = accupix_id;
      }

      if (envelope_id) {
        temVar.envelope_id = envelope_id;
      }

      if (postage_id) {
        temVar.postage_id = postage_id;
      }
      if (cartPrd.textResponse1) {
        temVar.textResponse1 = cartPrd.textResponse1;
      }
      if (cartPrd.responsetxt1) {
        temVar.responsetxt1 = cartPrd.responsetxt1;
      }
      if (cartPrd.textEmail1) {
        temVar.textEmail1 = cartPrd.textEmail1;
      }
      if (cartPrd.textEmail2) {
        temVar.textEmail2 = cartPrd.textEmail2;
      }

      if (cartPrd.textEmail3) {
        temVar.textEmail3 = cartPrd.textEmail3;
      }
      return temVar;
    });

    if (dopError.length > 0) {
      Swal.close();
      Swal.fire(
        "Error",
        "Mailing date have been expired. Please select new date of " +
        dopError.toString(),
        "error"
      );
      return false;
    }

    Swal.fire({
      title: "We are processing your order...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let paramObj = {
      user: props.selectedUserDetail.user_id.toString(),
      campaign: Number(props.campaignData.campaign_id),
      // payment_id: Number(selectedPayment.info_id),
      OrderProducts: cart_detail,
      is_pay_as_you_go: payAsYouGo ? 1 : 0,
      UploadedFiles: [
        {
          filename: "",
          filepath: "",
        },
      ],
      void_processing_fee: voidProcessingFee,
    };

    if (walletSelected == 1) {
      paramObj["is_wallet"] = walletSelected;
    }

    if (
      selectedPayment &&
      "info_id" in selectedPayment &&
      selectedPayment.info_id
    ) {
      paramObj["payment_id"] = Number(selectedPayment.info_id);
    }

    if (cartId) {
      paramObj["cart_id"] = cartId.toString();
    }

    let mailinglist_attr_id =
      mailListData &&
        "mailinglist_attr_id" in mailListData &&
        mailListData.mailinglist_attr_id
        ? mailListData.mailinglist_attr_id
        : "";
    let desired_quantity =
      mailListData &&
        "desired_quantity" in mailListData &&
        mailListData.desired_quantity
        ? mailListData.desired_quantity
        : "";
    if (mailinglist_attr_id != "") {
      paramObj["mailinglist_attr_id"] = Number(mailinglist_attr_id);
    }

    if (desired_quantity != "") {
      paramObj["mailingqty"] = Number(desired_quantity);
    }

    /* calendly changes start */
    if (
      "OrderDetails" in orderDetails &&
      orderDetails["OrderDetails"]["is_calendly"]
    ) {
      paramObj["is_calendly"] = Number(
        orderDetails["OrderDetails"]["is_calendly"]
      );
    }
    /* calendly changes end */
    if (couponCode) {
      paramObj.coupon_code = couponCode;
    }

    if (orderId) {
      //Update order Confirm When we Order Id
      paramObj["order_id"] = Number(orderId);

      if (
        orderDetails &&
        "UploadFiles" in orderDetails &&
        orderDetails.UploadFiles.length > 0 &&
        "filepath" in orderDetails.UploadFiles[0] &&
        orderDetails.UploadFiles[0].filepath
      ) {
        paramObj.UploadedFiles = orderDetails.UploadFiles;
      }

      paramObj.UploadedFiles = UploadedFiles;

      props
        .callRequest("POST", API_UPDATE_CONFIRM_ORDER, true, paramObj)
        .then((res) => {
          Swal.close();
          setOrderDetail(res.data);
          Swal.fire(
            "Success!",
            "Your Order has been placed succesfully. Due to background processing, it may take some time to reflect in order listing",
            "success"
          );
          history.push(props.redirectPath);
        })
        .catch((e) => {
          Swal.close();
        });
    }

    paramObj.UploadedFiles = UploadedFiles;

    if (orderId == "") {
      //Create Order API without Save as Draft
      props
        .callRequest("POST", API_CREATE_CONFIRM_ORDER, true, paramObj)
        .then((res) => {
          setOrderDetail(res.data);
          Swal.fire(
            "Success!",
            "Your Order has been placed succesfully. Due to background processing, it may take some time to reflect in order listing",
            "success"
          );
          history.push(props.redirectPath);
        });
    }
  };

  const onChangeAction = (event) => {
    let campDetail = {
      ...campaignData,
      [event.target.name]: event.target.value,
    };
    setCampaignData({ ...campDetail });
  };

  const updateCampaign = () => {
    const formData = new FormData();
    formData.append("campaign_name", campaignData.campaign_name);
    formData.append("campaign_type", campaignData.campaign_type);
    formData.append("campaign_target", campaignData.campaign_target);
    props
      .callRequest(
        "PUT",
        `${API_UPDATE_CAMPAIGN}` + campaignData.campaign_id + "/",
        true,
        formData
      )
      .then((res) => {
        setCampaignData(res.data);
        toggleEditForm();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const downloadProof = (prd) => {
    if (orderStatus == 5 || orderStatus == 3) {
      downloadConfirmOrderProof(prd);
      return true;
    }

    let template =
      "template" in prd && prd.template
        ? Number(prd.template)
        : prd.template_id;

    props
      .callRequest(
        "GET",
        `${API_GET_ORDER_CONFIRM_PROOF}?template_id=${template}&campaign_id=${campaignData.campaign_id
        }&postage_id=${"Postage" in prd && prd["Postage"] ? prd["Postage"] : prd.postage_id
        }&user_id=${props.selectedUserDetail.user_id}&campaign_name=${campaignData &&
        "campaign_name" in campaignData &&
        campaignData.campaign_name
        }&drop=${prd && "drop" in prd && prd.drop
          ? prd.drop
          : prd && "mailer" in prd && prd.mailer
            ? prd.mailer
            : ""
        }`,
        true
      )
      .then((res) => {
        if (res.data.pdf) {
          const link = document.createElement("a");
          link.href = res.data.pdf;
          link.target = "_blank";
          let fName = res.data.pdf.split("/").pop();
          link.setAttribute("download", fName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const downloadConfirmOrderProof = (prd) => {
    let template =
      "template" in prd && prd.template
        ? Number(prd.template)
        : prd.template_id;

    props
      .callRequest(
        "GET",
        `${API_GET_PROOF}?template_id=${template}&mailing_date_id=${prd.mailing_date_id
        }&postage_id=${"Postage" in prd && prd["Postage"] ? prd["Postage"] : prd.postage_id
        }&campaign_name=${campaignData &&
        "campaign_name" in campaignData &&
        campaignData.campaign_name
        }&drop=${prd && "drop" in prd && prd.drop
          ? prd.drop
          : prd && "mailer" in prd && prd.mailer
            ? prd.mailer
            : ""
        }`,
        true
      )
      .then((res) => {
        if (res.data.pdf) {
          const link = document.createElement("a");
          link.href = res.data.pdf;
          link.target = "_blank";
          let fName = res.data.pdf.split("/").pop();
          link.setAttribute("download", fName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copyProduct = (item) => {
    Swal.fire({
      title: "Drop name will be reset, do you want to continue?",
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: "Ok",
      // denyButtonText: `Don't save`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let tempProduct = [...cartProduct];
        tempProduct.push({ ...item });
        for (let i = 0; i < tempProduct.length; i++) {
          tempProduct[i]["drop"] = "DROP " + (i + 1);
          tempProduct[i]["mailer"] = "DROP " + (i + 1);
        }

        // setCartProduct([...tempProduct]);
        props.setProductList([...tempProduct]);
      }
    });
  };

  useEffect(async () => {
    if (orderId !== 0 && editStatus == null) {
      await handleAddToDraft(true);
      getOrderDetails(orderId);
    }
    applyDiscount();
    editStatus == "edit" && setEditStatus(null);
  }, [cartProduct]);

  //API_PAYMENT_SETTINGS
  const [paymentSettings, setPaymentSettings] = useState();
  const getPaymentSetting = () => {
    props
      .callRequest("GET", API_PAYMENT_SETTINGS, true)
      .then((res) => {
        setPaymentSettings(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const upload_csv = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("campaign_id", campaignData.campaign_id);
    formData.append("uplodedFile", file);

    props
      .callRequest("POST", API_UPLOAD_CAMPAIGN_CSV, true, formData)
      .then((res) => {
        let tempcampaignData = {
          ...campaignData,
          csv_file: res.data.csv_file,
          headers: res.data.headers,
        };
        props.updateCampaign(tempcampaignData);
        Swal.close();
      })
      .catch((e) => {
        Swal.fire(
          "Error",
          "Something went wrong, Please try again later",
          "error"
        );
      });
  };
  const [walletSelected, setWalletSelected] = useState(0);
  const [applyDiscountDetail, setApplyDiscountDetail] = useState();
  const setIsWalletSelected = () => {
    if (
      props.selectedUserDetail &&
      "wallet_balance" in props.selectedUserDetail &&
      props.selectedUserDetail.wallet_balance
    ) {
      let wallet_bal = !walletSelected ? 1 : 0;
      setWalletSelected(wallet_bal);
      // applyDiscount();
    } else {
      setWalletSelected(0);
      setApplyDiscountDetail();
      Swal.fire("Error", "Insufficient wallet balance", "error");
    }
  };

  useEffect(() => {
    applyDiscount();
  }, [walletSelected]);

  const applyDiscount = (validateCoupon = 0, isRemove = 0) => {
    if (isRemove == 1) {
      setCouponCode("");
    }

    if (!couponCode && validateCoupon == 1) {
      Swal.fire({
        icon: "error",
        title: "Validation Error!!",
        text: "Please enter a valid coupon code.",
      });
      return false;
    }

    if (validateCoupon == 1) {
      Swal.fire({
        title: "Coupon is validating, please wait...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
    }

    let cart_detail = cartProduct.map((cartPrd, ind) => {
      let temVar = {
        mailer: "drop" in cartPrd ? cartPrd.drop : "DROP " + (ind + 1),
        quantity:
          "quantity" in cartPrd
            ? Number(cartPrd.quantity)
            : Number(cartPrd.estimated_quantity),
        item_id:
          "item_id" in cartPrd
            ? Number(cartPrd.item_id)
            : Number(cartPrd.product_id),
        template_id:
          "template" in cartPrd
            ? Number(cartPrd.template)
            : Number(cartPrd.template_id),
        coupon_discount_price: 0,
        date:
          "mailing_date" in cartPrd
            ? moment(new Date(cartPrd.mailing_date)).format("MM-DD-YYYY") //.unix().toString()
            : "date" in cartPrd
              ? moment(new Date(cartPrd.date)).format("MM-DD-YYYY") //.toString()
              : moment(new Date()).format("MM-DD-YYYY"), //.unix().toString(),
        sub_category_id: Number(cartPrd.category_id),
      };
      let paper_id =
        "paper_id" in cartPrd
          ? Number(cartPrd.paper_id)
          : "PaperColor" in cartPrd
            ? Number(cartPrd["PaperColor"])
            : "";

      let ink_id =
        "ink_id" in cartPrd
          ? cartPrd.ink_id
          : "InkColor" in cartPrd
            ? Number(cartPrd["InkColor"])
            : "";

      let accutext_id =
        "accutext_id" in cartPrd ? Number(cartPrd.accutext_id) : "";
      let accupix_id =
        "accupix_id" in cartPrd ? Number(cartPrd.accupix_id) : "";
      let envelope_id =
        "envelope_id" in cartPrd
          ? Number(cartPrd.envelope_id)
          : "Envelope" in cartPrd
            ? Number(cartPrd.Envelope)
            : "";
      let postage_id =
        "postage_id" in cartPrd
          ? Number(cartPrd.postage_id)
          : "Postage" in cartPrd
            ? Number(cartPrd.Postage)
            : "";

      if (paper_id) {
        temVar.paper_id = paper_id;
      }

      if (ink_id) {
        temVar.ink_id = ink_id;
      }

      if (accutext_id) {
        temVar.accutext_id = accutext_id;
      }

      if (accupix_id) {
        temVar.accupix_id = accupix_id;
      }

      if (envelope_id) {
        temVar.envelope_id = envelope_id;
      }

      if (postage_id) {
        temVar.postage_id = postage_id;
      }

      return temVar;
    });

    let paramObj = {
      user_id: Number(props.selectedUserDetail.user_id),
      campaign: props.campaignData && Number(props.campaignData.campaign_id),
      // payment_id: Number(selectedPayment.info_id),
      OrderProducts: cart_detail,
      is_pay_as_you_go: payAsYouGo ? 1 : 0,
      coupon_code:
        (payAsYouGo && !couponCode) || isRemove == 1 ? "" : couponCode,
      UploadedFiles: [
        {
          filename: "",
          filepath: "",
        },
      ],
    };

    if (validateCoupon == 0 && !couponCode) {
      paramObj.coupon_code = "";
    }

    if (
      selectedPayment &&
      "info_id" in selectedPayment &&
      selectedPayment.info_id
    ) {
      paramObj["payment_id"] = selectedPayment.info_id;
    }

    let mailinglist_attr_id =
      mailListData &&
        "mailinglist_attr_id" in mailListData &&
        mailListData.mailinglist_attr_id
        ? mailListData.mailinglist_attr_id
        : "";
    let desired_quantity =
      mailListData &&
        "desired_quantity" in mailListData &&
        mailListData.desired_quantity
        ? mailListData.desired_quantity
        : "";
    if (mailinglist_attr_id != "") {
      paramObj["mailinglist_attr_id"] = Number(mailinglist_attr_id);
    }

    if (desired_quantity != "") {
      paramObj["mailingqty"] = Number(desired_quantity);
    }

    paramObj["is_wallet"] = payAsYouGo ? 0 : walletSelected;

    props
      .callRequest("POST", API_APPLY_DISCOUNT, true, paramObj)
      .then((res) => {
        Swal.close();
        setApplyDiscountDetail(res.data);

        if (
          "coupon_code" in res.data.coupon_data &&
          couponCode == res.data.coupon_data.coupon_code
        ) {
          setCouponApplied(true);
        } else {
          setCouponApplied(false);
        }
      })
      .catch((e) => {
        let er =
          "Error" in e.response.data && e.response.data.Error
            ? e.response.data.Error
            : "Something went wrong, Please try again later";

        Swal.fire("Error", er, "error");
      });
  };

  const removeWallet = () => {
    setApplyDiscountDetail();
    setWalletSelected(0);
  };

  useEffect(() => {
    applyDiscount();
  }, [mailListData, payAsYouGo]);

  const cancelOrder = () => {
    try {
      Swal.fire({
        title: "Request is processing...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const formData = new FormData();
      formData.append("order_id", orderId);

      props
        .callRequest("POST", API_CANCEL_ORDER, true, formData)
        .then((res) => {
          Swal.close();
          Swal.fire("Success", res.data.success, "success");
          history.push("/orders");
        })
        .catch((e) => {
          let msg = e.response.data && e.response.data.join();
          Swal.fire("Error", msg, "error");
        });
    } catch (e) {
      Swal.fire("Error", "Something went wrong, Please try again.", "error");
    }
  };
  useEffect(() => {
    handleDownloadList();
  }, []);

  const handleDownloadList = () => {
    try {
      props
        .callRequest("GET", API_DOWNLOAD_ZIP_ORDER + orderId + "/", true)
        .then((res) => {
          setDownloadedZip(res.data.zip_filepath);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const cancelDrop = (mailing_date_id, productDetail) => {
    try {
      if ("is_lockout" in productDetail && productDetail.is_lockout != 0) {
        Swal.fire(
          "Sorry !!",
          "We can't process your request, Drop already processed",
          "error"
        );
        return 0;
      }

      Swal.fire({
        title: "Request is processing...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const formData = new FormData();
      formData.append("mailing_date_id", mailing_date_id);

      props
        .callRequest("POST", API_CANCEL_MAILER, true, formData)
        .then((res) => {
          Swal.close();
          Swal.fire("Success", res.data.success, "success");
          props.getOrderDetail();
        })
        .catch((e) => {
          let msg = e.response.data && e.response.data.join();
          Swal.fire("Error", msg, "error");
        });
    } catch (e) {
      Swal.fire("Error", "Something went wrong, Please try again.", "error");
    }
  };

  const exportCsv = () => {
    if (orderId == 0) return false;

    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    props
      .callRequest("GET", API_ORDER_EDIT_EXPORT_CSV + orderId, true, {})
      .then((res) => {
        Swal.close();
        const element = document.createElement("a");
        element.setAttribute("href", res.data);
        element.setAttribute("download", "demo.csv");
        element.style.display = "none";
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const importCsv = (event) => {
    Swal.fire({
      title: "Uploading CSV",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let csvsrc;
    event.preventDefault();
    var file = event.target.files[0];
    const formData = new FormData();
    formData.append("uplodedFile", file, file.name);
    formData.append("order_id", orderId);

    props
      .callRequest("POST", API_ORDER_EDIT_IMPORT_CSV, true, formData)
      .then((res) => {
        Swal.close();
        Swal.fire(
          "Success",
          "File has been imported successfully, Please check the list status against your order in order listing page.",
          "success"
        );
      })
      .catch((er) => {
        let msg = er.response.data
          ? er.response.data.join()
          : "Something went wrong, Please try again.";
        Swal.fire("Error", msg, "error");
      });
  };

  const [applyListToAllDrop, setApplyListToAllDrop] = useState(false);

  useEffect(() => {
    campaignData && campaignData.csv_file && setApplyListToAllDrop(true);
  }, [campaignData && campaignData.csv_file]);

  const onChangeApplyListToAllDrops = (e) => {
    if (e.target.checked == true) {
      setApplyListToAllDrop(true);
    } else {
      setApplyListToAllDrop(false);
    }
  };

  const [showCsvMap, setShowCsvMap] = useState(false);
  const [savedCampaignData, setSavedCampaignData] = useState();
  const [map_mailing_date_id, setMapMailingDateId] = useState();

  const uploadMailerWise = (event, mailing_date_id) => {
    //API_ORDER_MAILER
    event.preventDefault();
    let fileTypeArr = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    let type = event.target.files[0]["type"];

    if (fileTypeArr.indexOf(type) < 0) {
      Swal.fire(
        "Validation!",
        "Please select a valid csv or excel file",
        "error"
      );
      return false;
    }

    const formData = new FormData();
    formData.append("UploadedFiles", event.target.files[0]);
    formData.append("mailing_date_id", mailing_date_id);
    setMapMailingDateId(mailing_date_id);

    Swal.fire({
      title: "CSV is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    props
      .callRequest("POST", API_ORDER_MAILER, true, formData)
      .then((res) => {
        Swal.close();
        setSavedCampaignData(res.data);
        setShowCsvMap(true);
      })
      .catch((er) => {
        Swal.close();
        console.log("error", er);
      });
  };

  const resetSavedCampaign = () => {
    setShowCsvMap(false);
    setSavedCampaignData(null);
    processCopyCamDataToMailingDateRecord();
  };

  const processCopyCamDataToMailingDateRecord = () => {
    if (map_mailing_date_id && orderId) {
      props
        .callRequest(
          "POST",
          API_COPY_ORDER_MAILER_DATA_TO_MAILING_DATE_RECORD,
          true,
          {
            mailing_date_id: Number(map_mailing_date_id),
            order_id: Number(orderId),
          }
        )
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const changeMailerPaymentStatus = async (e, mailing_date_id) => {
    Swal.fire({
      title: "Do you want to change your mailer payment status?",
      showCancelButton: true,
      confirmButtonText: "Ok",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        props
          .callRequest("POST", API_CHANGE_MAILER_PAYMENT_STATUS, true, {
            mailing_date_id: Number(mailing_date_id),
            order_id: Number(orderId),
            paymentStatus: Number(e.target.value),
          })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.Message,
            });
            getOrderDetails(orderId);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.Error,
            });
          });
      }
    });
  };

  const changeOrderPaymentStatus = async (e) => {
    Swal.fire({
      title: "Do you want to change order payment status?",
      showCancelButton: true,
      confirmButtonText: "Ok",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        props
          .callRequest("POST", API_CHANGE_NORMAL_ORDER_PAYMENT_STATUS, true, {
            order_id: Number(orderId),
            paymentStatus: Number(e.target.value),
          })
          .then((res) => {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: res.data.Message,
            });
            getOrderDetails(orderId);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: err.response.data.Error,
            });
          });
      }
    });
  };

  function formatUSNumber(entry = "") {
    let cleaned = ('' + entry).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  return (
    <>
      {showCsvMap && (
        <CrmContacts
          {...props}
          csvData={savedCampaignData}
          resetSavedCampaign={resetSavedCampaign}
          stopRedirection={true}
          map_mailing_date_id={map_mailing_date_id}
        />
      )}

      {showCsvMap == false && (
        <div className="createOrderMain">
          <div className="create-order-main-block">
            <Row>
              <Col sm="8">
                <div className="Campaign-table-details">
                  <Card>
                    <CardHeader>Campaign</CardHeader>
                    <CardBody>
                      <div className="tabile-main-block">
                        {campaignData && (
                          <Table>
                            <thead>
                              <tr>
                                <th>Campaign Name</th>
                                <th>Campaign Type</th>
                                <th>Campaign Target</th>
                                {orderStatus != 5 && <th>Actions</th>}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  {campaignData &&
                                    "campaign_name" in campaignData &&
                                    campaignData.campaign_name}
                                </td>
                                <td>
                                  {campaignData.campaign_type === "0"
                                    ? "Direct Mail"
                                    : campaignData.campaign_type === "1"
                                      ? "Probate"
                                      : "Micro-Campaigns"}
                                </td>
                                <td>
                                  {campaignData.campaign_target === "0"
                                    ? "Seller"
                                    : "Buyer"}
                                </td>
                                {orderStatus != 5 && (
                                  <td>
                                    <Button
                                      color="primary edit-btn"
                                      onClick={toggleEditForm}
                                    >
                                      <i className="bi-pencil-square"></i>
                                    </Button>
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </Table>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>

                <div className="order-approval-details-block">
                  <Card>
                    <CardHeader>Details</CardHeader>

                    <CardBody>
                      <div className="order-approval-list-block">
                        <div className="add-all-items-usercart">
                          {/* <FormGroup>
                          <Input
                            id="exampleCheck"
                            name="check"
                            type="checkbox"
                          />
                          <Label check for="exampleCheck">
                            Add all items to user Cart
                          </Label>
                        </FormGroup> */}
                          <FormGroup>
                            {orderStatus != 5 && !isPayAsYouGoOrder && (
                              <>
                                <Button
                                  type="submit"
                                  onClick={handleAddToCart}
                                  color={cartButton.color}
                                  disabled={
                                    orderId ? true : cartButton.disabled
                                  }
                                  size="sm"
                                >
                                  <i className="fas fa-shopping-cart">&nbsp;</i>
                                  {cartId
                                    ? "Update all item to user Cart"
                                    : cartButton.button_name}
                                </Button>

                                <Button
                                  type="submit"
                                  onClick={() =>
                                    props.setMailingListData(
                                      mailListData
                                        ? mailListData
                                        : {
                                          mailinglist_attr_id: "",
                                          desired_quantity: 0,
                                          mailing_list_type: "",
                                          price_amount: "",
                                        }
                                    )
                                  }
                                  color={cartButton.color}
                                  disabled={orderStatus > 2 ? true : false}
                                  size="sm"
                                >
                                  <i className="ni ni-fat-add">&nbsp;</i>
                                  Buy Mailing List
                                </Button>

                                <Button
                                  color="secondary"
                                  onClick={props.addProductOrder}
                                  size="sm"
                                >
                                  Add Product to Campaign{" "}
                                  <i className="ni ni-fat-add"></i>
                                </Button>

                                <Button
                                  className="m-auto"
                                  type="submit"
                                  onClick={handleAddToDraft}
                                  size="sm"
                                //color={cartButton.color}
                                >
                                  <i className="fas fa-shopping-cart">&nbsp;</i>
                                  {orderDetails &&
                                    "OrderDetails" in orderDetails &&
                                    orderDetails["OrderDetails"]["orderid"]
                                    ? "Update as draft"
                                    : "Save as draft"}
                                </Button>
                              </>
                            )}

                            {(orderStatus == 5 || isPayAsYouGoOrder) && (
                              <div
                              // style={{
                              //   display: "flex",
                              //   justifyContent: "space-around",
                              // }}
                              >
                                <Button
                                  onClick={cancelOrder}
                                  variant="danger"
                                  size="sm"
                                >
                                  <i className="fas fa-trash">&nbsp;</i>
                                  Cancel Order
                                </Button>
                              </div>
                            )}
                          </FormGroup>
                          <FormGroup>
                            {downloadedZip !== undefined && (
                              <Button
                                style={{
                                  marginTop: "5px",
                                }}
                                size="sm"
                              >
                                <a
                                  style={{
                                    padding: "5px",
                                    color: "white",
                                  }}
                                  title="Download zip"
                                  href={downloadedZip}
                                  // target="_blank"
                                  download
                                >
                                  <i className="fas fa-file-download">&nbsp;</i>{" "}
                                  Download List
                                </a>
                              </Button>
                            )}
                          </FormGroup>

                          <div className="row">
                            <div className="col-sm-8"></div>
                            <div className="col-sm-4">
                              {orderStatus != 2 &&
                                orderDetails &&
                                "OrderDetails" in orderDetails &&
                                orderDetails.OrderDetails &&
                                !isPayAsYouGoOrder && (
                                  <FormGroup style={{ marginTop: "5px" }}>
                                    <Label for="payment-status">
                                      Payment Status
                                    </Label>
                                    <Input
                                      type="select"
                                      name="order_payment_status"
                                      id="payment-status"
                                      bsSize="sm"
                                      value={parseInt(
                                        cartProduct &&
                                          cartProduct.length > 0 &&
                                          "paymentStatus" in cartProduct[0]
                                          ? Number(
                                            cartProduct[0]["paymentStatus"]
                                          )
                                          : 0
                                      )}
                                      onChange={(e) => {
                                        changeOrderPaymentStatus(e);
                                      }}
                                    >
                                      <option value={0}>Pending</option>
                                      <option value={1}>Success</option>
                                      <option value={2}>Failure</option>
                                    </Input>
                                  </FormGroup>
                                )}
                            </div>
                          </div>
                        </div>
                        <Row>
                          {cartProduct.map((prd, index) => {
                            return (
                              <Col sm="4" key={index}>
                                <div className="usercart-items-box">
                                  <Card>
                                    <CardHeader>
                                      <div className="usercart-items-header-block">
                                        {eventType != "edit-cart" &&
                                          orderStatus != 2 &&
                                          "mailing_date_id" in prd &&
                                          prd.mailing_date_id && (
                                            <div className="header-upload">
                                              <i
                                                className="bi-card-text"
                                                title="Map Csv"
                                              ></i>
                                              <input
                                                type="file"
                                                id="header_uplodedFile"
                                                className="header_uplodedFile"
                                                name="uplodedFile"
                                                accept=".csv,.xls,.xlsx"
                                                onChange={(e) =>
                                                  uploadMailerWise(
                                                    e,
                                                    prd.mailing_date_id
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                        {(!orderStatus || orderStatus == 2) && (
                                          <Button
                                            color="primary items-btn edit-btn"
                                            onClick={() =>
                                              copyProduct({ ...prd })
                                            }
                                            title="Copy"
                                          >
                                            <i className="ni ni-ungroup"></i>
                                          </Button>
                                        )}

                                        {prd.status != 3 && (
                                          <Button
                                            onClick={() =>
                                              fetchProductDetails(
                                                "item_id" in prd
                                                  ? prd.item_id
                                                  : prd.product_id,
                                                prd.size_id,
                                                prd,

                                                index

                                                // prd.category_id
                                              )
                                            }
                                            color="primary items-btn edit-btn"
                                            title="Edit"
                                          >
                                            <i className="bi-pencil-square"></i>
                                          </Button>
                                        )}

                                        {(!orderStatus || orderStatus == 2) &&
                                          !isPayAsYouGoOrder && (
                                            <Button
                                              onClick={() =>
                                                removeProduct(index)
                                              }
                                              color="primary items-btn edit-btn"
                                              title="Remove"
                                            >
                                              <i className="bi bi-trash"></i>
                                            </Button>
                                          )}
                                        {(!orderStatus || orderStatus == 2) && (
                                          <Button
                                            onClick={() => {
                                              setSubComm(true);
                                            }}
                                            color="primary items-btn edit-btn"
                                          >
                                            <i className="bi bi-cash-coin"></i>
                                          </Button>
                                        )}

                                        {orderStatus &&
                                          ((orderStatus != 2 &&
                                            prd.status != 3) ||
                                            isPayAsYouGoOrder) && (
                                            <Button
                                              onClick={() =>
                                                cancelDrop(
                                                  prd.mailing_date_id,
                                                  prd
                                                )
                                              }
                                              color="primary items-btn edit-btn"
                                              title="Cancel Drop"
                                            >
                                              <i className="bi bi-trash"></i>
                                            </Button>
                                          )}

                                        {orderStatus &&
                                          orderStatus != 2 &&
                                          prd.status == 3 && (
                                            <Button
                                              color="primary items-btn edit-btn"
                                              title="Cancel Drop"
                                            >
                                              Cancelled
                                            </Button>
                                          )}
                                      </div>
                                    </CardHeader>

                                    <CardBody>
                                      <div className="usercart-items-body">
                                        <div className="mailing-date">
                                          Desired Mailing Date:{" "}
                                          {"mailing_date" in prd &&
                                            prd.mailing_date
                                            ? getFormattedDate(prd.mailing_date)
                                            : "date" in prd
                                              ? prd.date
                                              : "--"}
                                        </div>
                                        <div className="drop-block">
                                          <div className="template-image">
                                            <CardImg
                                              top
                                              width="100%"
                                              src={
                                                prd.FullSizeImagePath
                                                  ? process.env
                                                    .REACT_APP_PUBLIC_URL_PHOTOPATH +
                                                  prd.FullSizeImagePath
                                                  : "item_img_url" in prd &&
                                                    prd.item_img_url
                                                    ? process.env
                                                      .REACT_APP_PUBLIC_URL_PHOTOPATH +
                                                    prd.item_img_url
                                                    : require("../../assets/img/product3.png")
                                                      .default
                                              }
                                              alt="Profile"
                                            />
                                          </div>
                                          <div className="drop-content-block">
                                            <h2 className="titel1">
                                              {/* {"drop" in prd && prd.drop
                                              ? prd.drop
                                              : "mailer" in prd && prd.mailer
                                              ? prd.mailer
                                              : ""} */}
                                              {"mailer" in prd
                                                ? prd.mailer
                                                : "DROP " + (index + 1)}
                                            </h2>
                                            <h4 className="product-name">
                                              {"item" in prd
                                                ? prd.item
                                                : prd.product_name}
                                            </h4>

                                            <div className="product-short-details">
                                              {"quantity" in prd
                                                ? prd.quantity
                                                : prd.estimated_quantity}{" "}
                                              <br />
                                              {"size_code" in prd
                                                ? prd.size_code
                                                : prd.size_name}{" "}
                                              <br />
                                              {prd.category_name}
                                            </div>
                                            <div className="product-price">
                                              $
                                              {"per" in prd
                                                ? Number(
                                                  parseFloat(prd.per)
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })
                                                : "unit_price" in prd &&
                                                Number(
                                                  parseFloat(prd.unit_price)
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}{" "}
                                              <br />$
                                              {"total_item_price" in prd
                                                ? Number(
                                                  parseFloat(
                                                    prd.total_item_price
                                                  )
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })
                                                : "total" in prd &&
                                                Number(
                                                  parseFloat(prd.total)
                                                ).toLocaleString(undefined, {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}{" "}
                                              total
                                            </div>
                                          </div>
                                        </div>
                                        <div className="product-details-block">
                                          <ul className="details-list">
                                            <li>
                                              <strong>Paper:</strong>{" "}
                                              {"PaperColor" in prd
                                                ? ShowAttributeName(
                                                  prd["PaperColor"]
                                                )
                                                : "paper" in prd
                                                  ? prd.paper
                                                  : "--"}
                                            </li>
                                            <li>
                                              <strong>Ink:</strong>{" "}
                                              {"InkColor" in prd &&
                                                prd["InkColor"]
                                                ? ShowAttributeName(
                                                  prd["InkColor"]
                                                )
                                                : "ink" in prd
                                                  ? prd.ink
                                                  : "--"}
                                            </li>
                                            <li>
                                              <strong>Postage:</strong>{" "}
                                              {"Postage" in prd &&
                                                prd["Postage"]
                                                ? ShowAttributeName(
                                                  prd["Postage"]
                                                )
                                                : "postage" in prd
                                                  ? prd.postage
                                                  : "--"}
                                            </li>
                                            <li>
                                              <strong>Envelope Option:</strong>
                                              {"Envelope" in prd &&
                                                prd["Envelope"]
                                                ? ShowAttributeName(
                                                  prd["Envelope"]
                                                )
                                                : "envelope_name" in prd
                                                  ? prd.envelope_name
                                                  : "--"}
                                            </li>

                                            {/* <li>
                                            <strong>AccuTxt:</strong>{" "}
                                           
                                          </li> */}
                                          </ul>
                                        </div>

                                        <div className="items-button-block">
                                          {
                                            /* "list_status" in prd &&
                                          prd.list_status > 1 && */
                                            orderStatus == 5 ? (
                                              <Button
                                                color="warning download-proof-btn"
                                                onClick={() =>
                                                  downloadProof(prd)
                                                }
                                              >
                                                <i className="bi bi-file-earmark-pdf-fill"></i>{" "}
                                                Download Proof
                                              </Button>
                                            ) : (
                                              <Button
                                                color="warning download-proof-btn"
                                                onClick={() =>
                                                  downloadProof(prd)
                                                }
                                              >
                                                <i className="bi bi-file-earmark-pdf-fill"></i>{" "}
                                                Download Proof
                                              </Button>
                                            )
                                          }
                                          { }
                                          <FormGroup>
                                            <Input
                                              id={"client-approved2_" + index}
                                              name="check"
                                              type="checkbox"
                                              checked={
                                                "is_proof_approved" in prd &&
                                                  prd.is_proof_approved == 1
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                updateProdcutApproval(
                                                  e,
                                                  prd.product_id,
                                                  index,
                                                  prd.mailing_date_id
                                                )
                                              }
                                              disabled={
                                                "is_lockout" in prd &&
                                                  prd.is_lockout == 1
                                                  ? true
                                                  : false
                                              }
                                            />
                                            <Label
                                              check
                                              for={"client-approved2_" + index}
                                            >
                                              client approved
                                            </Label>
                                          </FormGroup>
                                        </div>
                                        {orderStatus != 2 &&
                                          isPayAsYouGoOrder &&
                                          orderId && (
                                            <FormGroup>
                                              Payment Status
                                              <Input
                                                type="select"
                                                name="mailer_payment_status"
                                                id="mailinglist_id"
                                                bsSize="sm"
                                                value={parseInt(
                                                  prd.paymentStatus
                                                )}
                                                onChange={(e) => {
                                                  changeMailerPaymentStatus(
                                                    e,
                                                    prd.mailing_date_id
                                                  );
                                                }}
                                              >
                                                <option value={0}>
                                                  Pending
                                                </option>
                                                <option value={1}>
                                                  Success
                                                </option>
                                                <option value={2}>
                                                  Failure
                                                </option>
                                              </Input>
                                            </FormGroup>
                                          )}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Col>
                            );
                          })}

                          <Modal
                            isOpen={productDetailModal}
                            toggle={chooseDesignDetails}
                            className="show-product-details-modal"
                          >
                            <ModalHeader toggle={chooseDesignDetails}>
                              Manage Cart Item
                            </ModalHeader>
                            <ModalBody>
                              <ProductDetail
                                {...props}
                                chooseDesignDetails={() => {
                                  chooseDesignDetails();
                                }}
                                cartId={cartId}
                                ProductDetailId={ProductDetailId}
                                sizeId={sizeId}
                                updateProduct={updateProduct}
                                updatedProduct={(i) => {
                                  updatedProduct(i);
                                }}
                                modalToggle={() => {
                                  chooseDesignDetails();
                                }}
                                selectedProductForEdit={selectedProductForEdit}
                                prdId={ProductDetailId}
                                selectedUserDetail={userData}
                                postage={postage}
                                envelope={envelope}
                                paper={paper}
                                ink={ink}
                                accTXT={accTXT}
                                accuPIX={accuPIX}
                                attributes={attributes}
                                edittedProductFlag={(e) => {
                                  console.log(e);
                                }}
                                selectedProduct={(item) => {
                                  updatedProduct(item);
                                }}
                                orderStatus={orderStatus}
                                dateAdded={
                                  campaignData && campaignData.date_added
                                }
                                orderId={orderId}
                                orderDetails={orderDetails}
                                campaignName={
                                  campaignData &&
                                    "campaign_name" in campaignData &&
                                    campaignData.campaign_name
                                    ? campaignData.campaign_name
                                    : null
                                }
                              />
                            </ModalBody>
                          </Modal>
                        </Row>

                        {mailListData &&
                          Object.keys(mailListData).length !== 0 &&
                          mailListData.mailinglist_attr_id != "" ? (
                          <Row>
                            <Col sm="4">
                              <div className="usercart-items-box miling-list-box">
                                <Card>
                                  <CardHeader>
                                    <div className="usercart-items-header-block">
                                      {/* <Button color="primary items-btn edit-btn">
                                      <i className="ni ni-ungroup"></i>
                                    </Button> */}
                                      {orderStatus != 5 && (
                                        <>
                                          <Button
                                            color="primary items-btn edit-btn"
                                            onClick={() =>
                                              props.setMailingListData(
                                                mailListData
                                              )
                                            }
                                          >
                                            <i className="bi-pencil-square"></i>
                                          </Button>
                                          <Button
                                            color="primary items-btn edit-btn"
                                            onClick={() =>
                                              props.setMailingListData(null)
                                            }
                                          >
                                            <i className="bi bi-trash"></i>
                                          </Button>
                                        </>
                                      )}
                                      {/* <Button color="primary items-btn edit-btn">
                                      <i className="bi bi-cash-coin"></i>
                                    </Button> */}
                                    </div>
                                  </CardHeader>
                                  <CardBody>
                                    <div className="usercart-items-body">
                                      <div className="drop-block">
                                        <div className="template-image">
                                          <CardImg
                                            top
                                            width="100%"
                                            src={
                                              require("../../assets/img/email-icon.png")
                                                .default
                                            }
                                            alt="MailingList"
                                          />
                                        </div>
                                        <div className="drop-content-block">
                                          <h2 className="titel1">
                                            Mailing List
                                          </h2>
                                          <h4 className="product-name">
                                            {mailListData.mailing_list_type}
                                          </h4>
                                          <div className="product-short-details">
                                            Qty :{" "}
                                            {mailListData.desired_quantity}
                                          </div>
                                          <div className="product-price">
                                            $
                                            {Number(
                                              parseFloat(
                                                mailListData.price_amount
                                                  .toString()
                                                  .includes("|")
                                                  ? getPrice(
                                                    mailListData.price_amount,
                                                    mailListData.desired_quantity,
                                                    1
                                                  )
                                                  : "per_mailer" in mailListData
                                                    ? mailListData.per_mailer
                                                    : mailListData.price_amount
                                              )
                                            ).toFixed(2)}{" "}
                                            <br />$
                                            {Number(
                                              parseFloat(
                                                mailListData.price_amount
                                                  .toString()
                                                  .includes("|")
                                                  ? Number(
                                                    parseFloat(
                                                      getPrice(
                                                        mailListData.price_amount,
                                                        mailListData.desired_quantity,
                                                        1
                                                      )
                                                    ) *
                                                    Number(
                                                      mailListData.desired_quantity
                                                    )
                                                  ).toFixed(2)
                                                  : Number(
                                                    parseFloat(
                                                      "per_mailer" in
                                                        mailListData
                                                        ? mailListData.per_mailer
                                                        : mailListData.price_amount
                                                    ) *
                                                    Number(
                                                      "desired_quantity" in
                                                        mailListData
                                                        ? mailListData.desired_quantity
                                                        : mailListData.mailingqty
                                                    )
                                                  ).toFixed(2)
                                              )
                                            ).toFixed(2)}{" "}
                                            total
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </div>
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                        {/* {props.campaignDetail &&
                        "uplodedFile" in props.campaignDetail &&
                        props.campaignDetail.uplodedFile && (
                          <Col sm="12">
                            <Button
                              className="m-auto"
                              type="button"
                              //color={cartButton.color}
                              onClick={() => proofAll()}
                            >
                              <i className="fas fa-user-check">&nbsp;</i>
                              Proof
                            </Button>
                          </Col>
                        )} */}
                      </div>
                    </CardBody>
                  </Card>
                </div>

                <div className="payment-method-mainblock">
                  <Card>
                    <CardBody>
                      <div className="row header-title-block">
                        <div className="col-sm-6">
                          <h4 className="card-title">Final Cost</h4>
                          <p className="card-subtitle">
                            Price detail with applicable discount
                          </p>
                        </div>
                        <div className="col-sm-3"></div>
                        <div
                          className="col-sm-3"
                          style={{ textAlign: "right", paddingTop: "23px" }}
                        ></div>
                      </div>

                      <div className="row" style={{ marginBottom: "10px" }}>
                        <div className="col-sm-3">
                          <FormGroup>
                            <Label
                              check
                              for={"apply_list_to_all_drops"}
                              style={{ margin: "29px" }}
                            >
                              <Input
                                id={"apply_list_to_all_drops"}
                                name="check"
                                type="checkbox"
                                checked={payAsYouGo}
                                onChange={(e) => {
                                  setPayAsYouGo(e.target.checked);
                                  e.target.checked && setWalletSelected(0);
                                }}
                                disabled={
                                  (orderStatus == false || orderStatus < 3) &&
                                    cartProduct.length > 1
                                    ? false
                                    : true
                                }
                              />{" "}
                              Pay per Drop
                            </Label>
                          </FormGroup>
                        </div>
                        <div className="col-sm-4">
                          <div
                            className="add-new-payment-block"
                            style={{ textAlign: "center" }}
                          >
                            <h3
                              style={{
                                marginBottom: "0px",
                                color: "rgb(237, 189, 28)",
                              }}
                            >
                              Wallet Balance
                            </h3>

                            <div className="upload-list-content">
                              <div style={{ margin: "5px" }}>
                                <input
                                  type="checkbox"
                                  onClick={setIsWalletSelected}
                                  disabled={
                                    (orderStatus == false || orderStatus < 3) &&
                                      payAsYouGo == false
                                      ? false
                                      : true
                                  }
                                  checked={walletSelected == 1 ? true : false}
                                />
                                Amount in Wallet
                              </div>
                            </div>

                            <div className="add-new-payment-button1">
                              $
                              {props.selectedUserDetail &&
                                "wallet_balance" in props.selectedUserDetail &&
                                props.selectedUserDetail.wallet_balance > 0
                                ? Number(
                                  Number(
                                    props.selectedUserDetail.wallet_balance
                                  ) -
                                  Number(
                                    applyDiscountDetail &&
                                      "wallet_debit_amount" in
                                      applyDiscountDetail &&
                                      applyDiscountDetail.wallet_debit_amount
                                      ? applyDiscountDetail.wallet_debit_amount
                                      : 0
                                  )
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : "0.00"}{" "}
                              /-
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-5">
                          <div
                            className="add-new-payment-block"
                            style={{ textAlign: "center" }}
                          >
                            <h3
                              style={{
                                marginBottom: "0px",
                                color: "rgb(237, 189, 28)",
                              }}
                            >
                              Discount
                            </h3>

                            <div className="upload-list-content">
                              <div style={{ margin: "5px" }} className="row">
                                <div
                                  className="col-sm-8"
                                  style={{ marginRight: "0px" }}
                                >
                                  <Input
                                    type="text"
                                    name="coupon_code"
                                    id="coupon_code"
                                    value={couponCode}
                                    placeholder="Enter Coupon Code"
                                    onChange={(e) => {
                                      setCouponCode(e.target.value);
                                    }}
                                    size="sm"
                                    disabled={
                                      (orderStatus == false ||
                                        orderStatus < 3) &&
                                        couponApplied == false
                                        ? false
                                        : true
                                    }
                                  />
                                </div>
                                <div className="col-sm-4">
                                  <Button
                                    className="add-new-card-class"
                                    onClick={() =>
                                      applyDiscount(
                                        couponApplied ? 0 : 1,
                                        couponApplied ? 1 : 0
                                      )
                                    }
                                    size="sm"
                                    variant={
                                      couponApplied ? "danger" : "secondary"
                                    }
                                    disabled={
                                      orderStatus == false || orderStatus < 3
                                        ? false
                                        : true
                                    }
                                  >
                                    {couponApplied ? "Remove" : "Apply"}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{ marginLeft: "3px", background: "#f5f5f5" }}
                      >
                        <div className="col-sm-8">Sub Total</div>{" "}
                        <div
                          className="col-sm-4"
                          style={{ textAlign: "right" }}
                        >
                          $
                          {(orderStatus == 5 || orderStatus == 3) &&
                            orderDetails &&
                            "OrderDetails" in orderDetails &&
                            orderDetails.OrderDetails.sub_total
                            ? Number(
                              orderDetails.OrderDetails.sub_total
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : applyDiscountDetail &&
                              "sub_total" in applyDiscountDetail &&
                              applyDiscountDetail.sub_total
                              ? Number(
                                applyDiscountDetail.sub_total
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              : "0.00"}
                          /-
                        </div>
                        <div className="col-sm-8">Discount</div>{" "}
                        <div
                          className="col-sm-4"
                          style={{ textAlign: "right" }}
                        >
                          - $
                          {(orderStatus == 5 || orderStatus == 3) &&
                            orderDetails &&
                            "OrderDetails" in orderDetails &&
                            orderDetails.OrderDetails.client_discount_amount
                            ? Number(
                              orderDetails.OrderDetails.client_discount_amount
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : applyDiscountDetail &&
                              "discount_amount" in applyDiscountDetail &&
                              applyDiscountDetail.discount_amount
                              ? Number(
                                applyDiscountDetail.discount_amount
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              : "0.00"}
                          /-
                        </div>
                        <div className="col-sm-8">Add-ons</div>{" "}
                        <div
                          className="col-sm-4"
                          style={{ textAlign: "right" }}
                        >
                          $0.00/-
                        </div>
                        <div className="col-sm-8">
                          Wallet {orderStatus == 5 ? "Debited" : "Discount"}
                        </div>{" "}
                        <div
                          className="col-sm-4"
                          style={{ textAlign: "right" }}
                        >
                          - $
                          {(orderStatus == 5 || orderStatus == 3) &&
                            orderDetails &&
                            "OrderDetails" in orderDetails &&
                            orderDetails.OrderDetails.wallet_debit_amount
                            ? Number(
                              orderDetails.OrderDetails.wallet_debit_amount
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : applyDiscountDetail &&
                              "wallet_debit_amount" in applyDiscountDetail &&
                              applyDiscountDetail.wallet_debit_amount
                              ? Number(
                                applyDiscountDetail.wallet_debit_amount
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              : "0.00"}{" "}
                          /-
                        </div>
                        <div className="col-sm-8">Processing fee amount</div>{" "}
                        <div
                          className="col-sm-4"
                          style={{ textAlign: "right" }}
                        >
                          $
                          {orderDetails &&
                            "OrderDetails" in orderDetails &&
                            orderDetails.OrderDetails.processing_fee_amount
                            ? Number(
                              orderDetails.OrderDetails.processing_fee_amount
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : "0.00"}{" "}
                          /-
                        </div>
                      </div>

                      <div
                        className="row"
                        style={{
                          marginLeft: "3px",
                          background: "#fbf6e7",
                          marginTop: "5px",
                        }}
                      >
                        <div className="col-sm-8">Total</div>{" "}
                        <div
                          className="col-sm-4"
                          style={{ textAlign: "right" }}
                        >
                          $
                          {orderStatus > 2 &&
                            orderDetails &&
                            "OrderDetails" in orderDetails &&
                            orderDetails.OrderDetails.charge_able_amount
                            ? Number(
                              orderDetails.OrderDetails.charge_able_amount
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                            : applyDiscountDetail &&
                              "charge_able_amount" in applyDiscountDetail
                              ? Number(
                                applyDiscountDetail.charge_able_amount
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                              : applyDiscountDetail &&
                                "grand_total" in applyDiscountDetail &&
                                applyDiscountDetail.grand_total
                                ? Number(
                                  applyDiscountDetail.grand_total
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                                : "0.00"}
                          /-
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>

                {(orderStatus == false || orderStatus < 3) && (
                  <div className="payment-method-mainblock">
                    <Card>
                      <CardBody>
                        <div className="row header-title-block">
                          <div className="col-sm-6">
                            <h4 className="card-title">Payment</h4>
                            <p className="card-subtitle">
                              Choose payment method below
                            </p>
                          </div>
                          <div className="col-sm-3"></div>
                          <div
                            className="col-sm-3"
                            style={{ textAlign: "right", paddingTop: "23px" }}
                          >
                            {/* {isPayAsYouGoOrder && orderId && <FormGroup>
                            <Label check for={"apply_list_to_all_drops"}>
                              <Input
                                id={"apply_list_to_all_drops"}
                                name="check"
                                type="checkbox"
                                checked={payAsYouGo}
                                onChange={(e)=>setPayAsYouGo(e.target.checked)}
                                
                              />{" "}
                              Pay-As-You-Go
                            </Label>
                        </FormGroup>} */}
                          </div>
                        </div>
                        <div className="paymentMethod">
                          <div className="pmbox cc-payment-method">
                            <h4>Your Credit Cards</h4>
                            <Table>
                              <thead>
                                <tr>
                                  <th />
                                  <th />
                                  <th>Name On Card</th>
                                  <th>Expires On</th>
                                </tr>
                              </thead>
                              <tbody>
                                {getAllPaymentList &&
                                  getAllPaymentList.map((item) => {
                                    return getPaymentUI(item);
                                  })}
                              </tbody>
                            </Table>
                            <hr />
                            <div className="void-processing-fee-class">
                              <Button
                                className="add-new-card-class"
                                onClick={() => setPaymentMethod(null)}
                              >
                                &nbsp; Reset Payment Selection
                              </Button>

                              <Button
                                className="add-new-card-class"
                                onClick={() => onClickAddCard("cc")}
                              >
                                <i className="fas fa-plus"></i> &nbsp; Add new
                                card
                              </Button>
                              <div className="confirm-order-button">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setVoidProcessingFee(!voidProcessingFee)
                                  }
                                />
                                Void Processing Fee
                              </div>
                            </div>
                          </div>

                          {/* {selectedPayment && (
                            <div className="pmbox payment-conform-box">
                              <div className="header-block">
                                <h4>Saved Payment Details</h4>
                                <div className="removed-button">
                                 
                                </div>
                              </div>
                              <div className="payment-card-details">
                                {HandleCardImage(
                                  selectedPayment.cardType,
                                  selectedPayment.payment_method
                                )}
                                
                                <div>
                                  <h5>
                                    {HandleCardType(
                                      selectedPayment.cardType,
                                      selectedPayment.payment_method
                                    )}
                                  </h5>
                                
                                  <p>
                                    Card ending in{" "}
                                    {selectedPayment &&
                                      selectedPayment.maskedNumber}
                                  </p>
                                  <p className="yellowColor">
                                    Expires{" "}
                                    {selectedPayment &&
                                      selectedPayment.expirationDate}
                                  </p>
                                </div>
                              </div>
                              <div className="payment-billing-address">
                                <h5>Billing Address</h5>
                                <div className="billing-form-block">
                                  <Input
                                    id="address"
                                    name="Address"
                                    placeholder="123 Street Name"
                                    type="text"
                                    value={
                                      selectedPayment &&
                                      selectedPayment.billing_address
                                    }
                                    readOnly
                                  />
                                  <Row>
                                    <Col sm="4">
                                      <Input
                                        id="cityName"
                                        name="cityName"
                                        placeholder="City Name"
                                        type="text"
                                        value={
                                          selectedPayment &&
                                          selectedPayment.billing_city
                                        }
                                        readOnly
                                      />
                                    </Col>
                                    <Col sm="4">
                                      <Input
                                        id="State"
                                        name="State"
                                        placeholder="City Name"
                                        type="text"
                                        value={
                                          selectedPayment &&
                                          selectedPayment.billing_state
                                        }
                                        readOnly
                                      />
                                    </Col>
                                    <Col sm="4">
                                      <Input
                                        id="pincode"
                                        name="pincode"
                                        placeholder="12345-123"
                                        type="text"
                                        value={
                                          selectedPayment &&
                                          selectedPayment.zip_code
                                        }
                                        readOnly
                                      />
                                    </Col>

                                    {selectedPayment &&
                                      selectedPayment.payment_method == "cc" &&
                                      paymentSettings &&
                                      "credit_card_processing_fee" in
                                        paymentSettings &&
                                      paymentSettings.credit_card_processing_fee && (
                                        <Col
                                          sm="12"
                                          style={{
                                            marginTop: "5px",
                                            color: "red",
                                          }}
                                        >
                                          Payment processing fee{" "}
                                          {
                                            paymentSettings.credit_card_processing_fee
                                          }
                                          %
                                        </Col>
                                      )}
                                  </Row>
                                </div>
                              </div>
                              <div className="confirm-order-button">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    setVoidProcessingFee(!voidProcessingFee)
                                  }
                                />
                                Void Processing Fee
                              </div>
                            </div>
                          )} */}

                          {showAddToCard && (
                            <div className="pmbox payment-conform-box">
                              <div className="header-block">
                                <h4>New Card</h4>
                                {/* <div className="removed-button">
                              <Button onClick={() => setShowAddToCard(false)}>
                                <i className="bi bi-trash"></i>
                                <span>Removed</span>
                              </Button>
                            </div> */}
                              </div>
                              <div className="payment-card-details">
                                <img
                                  alt="reiprintmail"
                                  src={
                                    require("../../assets/img/card1.png")
                                      .default
                                  }
                                />
                                <div>
                                  <h5>VISA</h5>
                                  <p>
                                    Card ending in ... <br />
                                    2299
                                  </p>
                                  <p className="yellowColor">Expires 01/23</p>
                                </div>
                              </div>

                              <div className="add-card-button-block">
                                <FormGroup check>
                                  <Input
                                    id="defaultPaymentMethod"
                                    name="check"
                                    type="checkbox"
                                  />
                                  <Label check for="defaultPaymentMethod">
                                    Set as default payment method
                                  </Label>
                                </FormGroup>
                                <Button type="button">Add New Card</Button>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="wallet-payment-block">
                          <div className="paymentMethod ach-payment-box">
                            <div className="pmbox">
                              <h4>Your Bank Accounts</h4>
                              <hr />
                              <ul className="ach-payment-ui-class">
                                {getAllPaymentList &&
                                  getAllPaymentList.map((item) => {
                                    return getPaymentAchUI(item);
                                  })}
                              </ul>
                              <div className="void-processing-fee-class">
                                <Button
                                  className="add-new-card-class"
                                  onClick={() => setPaymentMethod(null)}
                                >
                                  &nbsp; Reset Payment Selection
                                </Button>
                                <Button
                                  className="add-new-card-class"
                                  onClick={() => onClickAddCard("ach")}
                                >
                                  <i className="fas fa-plus"></i> &nbsp; Add new
                                  bank
                                </Button>
                              </div>
                              <div className="paymentfooter"></div>
                            </div>
                          </div>

                          <div className="ach-payment-box">
                            {/* <div className="paymentMethod ">
                              <div
                                className="pmbox payment-conform-box"
                                style={{ "max-width": "383px;" }}
                              >
                                <div className="payment-methods-box">
                                  <div>
                                    <div>
                                      <div
                                        className="add-new-payment-block"
                                        style={{ textAlign: "center" }}
                                      >
                                        <h3 className="card-title">
                                          Wallet Balance
                                        </h3>
                                        <hr />

                                        <div className="upload-list-content">
                                          <div className="confirm-order-button">
                                            <input
                                              type="checkbox"
                                              onClick={setIsWalletSelected}
                                            />
                                            Apply my Wallet Balance to Order
                                          </div>
                                        </div>

                                        <div className="add-new-payment-button">
                                          <span>
                                            <strong>Amount in Wallet:</strong> ${" "}
                                          </span>
                                          {props.selectedUserDetail &&
                                          "wallet_balance" in
                                            props.selectedUserDetail
                                            ? props.selectedUserDetail
                                                .wallet_balance
                                            : "0.00"}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> */}

                            {/* {walletSelected == 1 &&
                                applyDiscountDetail &&
                                "wallet_debit_amount" in applyDiscountDetail &&
                                Number(
                                  applyDiscountDetail.wallet_debit_amount
                                ) > 0 && (
                                  <div
                                    className="pmbox"
                                    style={{
                                      float: "right",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div className="payment-card-details">
                                      <div className="user-balance-box">
                                        Wallet Debit Balance
                                      </div>
                                      <div className="blance-text">
                                        <h5>
                                          Balance: $
                                          {
                                            applyDiscountDetail.wallet_debit_amount
                                          }
                                        </h5>
                                      </div>
                                      <div>
                                        <a
                                          onClick={() => removeWallet()}
                                          style={{ color: "red" }}
                                        >
                                          Remove
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )} */}
                          </div>
                        </div>
                      </CardBody>
                      <div
                        className="confirm-order-button"
                        style={{ marginTop: "5px" }}
                      >
                        <Button
                          color="secondary"
                          onClick={() => processOrder()}
                          disabled={(!cartProduct || (cartProduct && cartProduct.length < 1) ? true : false)}
                        >
                          Confirm Order <i className="ni ni-credit-card"></i>
                        </Button>
                      </div>
                    </Card>
                  </div>
                )}
              </Col>
              <Col sm="4">
                <div className="right-panel">
                  <div className="select-user-box">
                    {!userData && (
                      <Card>
                        <CardHeader>Search User </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <SelectUserForm
                              {...props}
                              receiveUserId={receiveUserId}
                              permissions={permissionData}
                            />
                          </FormGroup>
                        </CardBody>
                      </Card>
                    )}
                    <Card>
                      <CardHeader>
                        <div className="card-header-block">
                          <div className="header-left">
                            <CardTitle tag="h5">User</CardTitle>
                          </div>
                          <div className="header-right">
                            {orderStatus != 5 && (
                              <UncontrolledDropdown>
                                <DropdownToggle>
                                  <i className="fas fa-ellipsis-v"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  {/* <DropdownItem
                                    onClick={() => changeSelectedUser()}
                                  >
                                    Change User
                                  </DropdownItem> */}
                                  <DropdownItem onClick={() => editUserPop()}>
                                    Edit User Profile
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            )}

                            {orderStatus === "5" && (
                              <UncontrolledDropdown>
                                <DropdownToggle>
                                  <i className="fas fa-ellipsis-v"></i>
                                </DropdownToggle>

                                {permissionData &&
                                  permissionData.length !== 0 ? (
                                  Object.keys(orderDetails).length !== 0 &&
                                    "OrderDetails" in orderDetails &&
                                    orderDetails !== undefined ? (
                                    <DropdownMenu>
                                      <DropdownItem onClick={toggleCommissions}>
                                        Commissions Override
                                      </DropdownItem>
                                    </DropdownMenu>
                                  ) : null
                                ) : null}
                              </UncontrolledDropdown>
                            )}
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="details-text-block">
                          <p>
                            <strong>Name:</strong>{" "}
                            {userData && userData.username}
                          </p>
                          <p>
                            <strong>Email:</strong> {userData && userData.email}
                          </p>
                          <p>
                            <strong>Phone:</strong>{" "}
                            {userData && formatUSNumber(userData.phone)}
                          </p>
                          <p>
                            <strong>Direct Marketing Coach 1:</strong>{" "}
                            {orderStatus === "5"
                              ? fetchSalesRepName(
                                orderDetails.OrderDetails.sales_rep_1
                              )
                              : userData &&
                              fetchSalesRepName(userData.sales_rep_1)}
                          </p>
                          <p>
                            <strong>Direct Marketing Coach 2:</strong>{" "}
                            {orderStatus === "5"
                              ? fetchSalesRepName(
                                orderDetails.OrderDetails.sales_rep_2
                              )
                              : userData &&
                              fetchSalesRepName(userData.sales_rep_2)}
                          </p>
                          <p>
                            <strong>CSR:</strong> {userData && userData.csr}
                          </p>
                          <p>
                            <strong>Affiliate:</strong>&nbsp;
                            {userData && userData.affiliate_id && affUsr
                              ? affUsr[0].affiliate_id.name
                              : userData && userData.affiliate_id}
                          </p>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                  {eventType != "edit-cart" &&
                    orderStatus &&
                    orderStatus != 2 &&
                    orderDetails.partial_lockout_status != 1 && (
                      <div className="select-user-box order-details-box">
                        <Card>
                          <CardHeader>
                            <div className="card-header-block">
                              <div className="header-left">
                                <CardTitle tag="h5">List Manager</CardTitle>
                              </div>
                              {/* <div className="header-right">
                            <Button color="primary edit-btn">
                              <i className="bi-pencil-square"></i>
                            </Button>
                          </div> */}
                            </div>
                          </CardHeader>
                          <CardBody>
                            <div className="searchfile-list-block">
                              <div className="row">
                                <div className="col-sm-8">
                                  <Input
                                    type="text"
                                    size={"sm"}
                                    disabled={true}
                                    value={
                                      campaignData &&
                                        "csv_file" in campaignData &&
                                        campaignData.csv_file
                                        ? campaignData.csv_file
                                        : ""
                                    }
                                  />
                                </div>

                                <div className="col-sm-4">
                                  <div
                                    className="upload-button"
                                    style={{ marginLeft: "10px" }}
                                  >
                                    <Button
                                      color="secondary"
                                      size="sm"
                                      disabled={
                                        applyListToAllDrop ? false : true
                                      }
                                    >
                                      Upload
                                    </Button>

                                    {applyListToAllDrop && (
                                      <input
                                        type="file"
                                        id="uplodedFile"
                                        name="uplodedFile"
                                        accept=".csv,.xls,.xlsx"
                                        className="btn custom-file-input"
                                        onChange={(e) => upload_csv(e)}
                                        style={{ marginTop: "-44px" }}
                                      ></input>
                                    )}
                                  </div>
                                </div>
                                {/* <div className="col-sm-8">

                              </div>
                              <div className="col-sm-4">
                              <Button color="secondary" size="sm" disabled={campaignData && 'csv_file' in campaignData && campaignData.csv_file ? false: true}>Export{" "}</Button>
                              </div> */}
                              </div>
                            </div>

                            {orderDetails &&
                              "order_export_status" in orderDetails &&
                              orderDetails.order_export_status == 1 &&
                              orderId != 0 && (
                                <div className="searchfile-list-block">
                                  <div className="row">
                                    <div className="col-sm-12 text-center btn-css">
                                      <Button
                                        color="secondary"
                                        size="sm"
                                        onClick={() => exportCsv()}
                                        className="cust-btn"
                                      >
                                        Export
                                      </Button>

                                      <div className="upload-button">
                                        <Button
                                          color="secondary"
                                          size="sm"
                                          className="cust-btn"
                                        >
                                          Import
                                        </Button>

                                        <input
                                          type="file"
                                          id="uplodedFile"
                                          name="uplodedFile"
                                          accept=".csv,.xls,.xlsx"
                                          className="btn custom-file-input"
                                          onChange={(e) => importCsv(e)}
                                          style={{ marginTop: "-44px" }}
                                        ></input>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                            <FormGroup>
                              <Label check for={"apply_list_to_all_drops"}>
                                <Input
                                  id={"apply_list_to_all_drops"}
                                  name="check"
                                  type="checkbox"
                                  checked={applyListToAllDrop ? true : false}
                                  onChange={(e) =>
                                    onChangeApplyListToAllDrops(e)
                                  }
                                />{" "}
                                Apply list to all Drops
                              </Label>
                            </FormGroup>
                          </CardBody>
                        </Card>
                      </div>
                    )}
                  <div className="select-user-box order-details-box">
                    {Object.keys(orderDetails).length !== 0 &&
                      "OrderDetails" in orderDetails &&
                      orderDetails !== undefined ? (
                      <Card>
                        <CardHeader>
                          <div className="card-header-block">
                            <div className="header-left">
                              <CardTitle tag="h5">Order Details</CardTitle>
                            </div>
                            <div className="header-right">
                              <Button color="primary edit-btn">
                                <i className="bi-pencil-square"></i>
                              </Button>
                            </div>
                          </div>
                        </CardHeader>
                        <CardBody>
                          <CardTitle tag="h6">Order Status:</CardTitle>
                          <div className="searchfile-list-block">
                            <ul className="list">
                              <li>
                                <strong>Order ID:</strong>{" "}
                                {orderDetails["OrderDetails"]["orderid"]}
                              </li>
                              <li>
                                <strong>Date Created:</strong>
                                {getFormattedDate(
                                  new Date(
                                    moment.unix(
                                      orderDetails["OrderDetails"].date_added
                                    )
                                  )
                                )}
                              </li>
                              <li>
                                <strong>Discount:</strong>${" "}
                                {Number(
                                  orderDetails["OrderDetails"]
                                    .client_discount_amount
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </li>
                              <li className="gray-bg">
                                <strong>Total:</strong>$
                                {Number(
                                  parseFloat(
                                    orderDetails["OrderDetails"].grand_total
                                  )
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                                {/* {orderDetails["OrderDetails"].grand_total} */}
                              </li>
                              {/*
                            <li>
                              <strong>Date Due:</strong> {""}
                            </li>
                            <li>
                              <strong>Date Shipped:</strong> {""}
                            </li>
                            <li>
                              <strong>Date Posted:</strong> {""}
                            </li>

                             <li>
                              <strong>PO:</strong> HSK-11123314
                            </li>
                            
                            
                            <li>
                              <strong>Credit:</strong>{" "}
                            </li>
                            <li>
                              <strong>Shipping:</strong>{" "}
                            </li>
                            <li>
                              <strong>Postage:</strong>{" "}
                            </li>
                            <li>
                              <strong>Tax: Tax Exempt:</strong>{" "}
                            </li>
                            <li>
                              <strong>Credit Card Fees:</strong>{" "}
                            </li> 
                           
                            <li className="gray-bg">
                              <strong>Amount Paid:</strong>{" "}
                            </li>
                            <li className="gray-bg">
                              <strong>Baalance:</strong>{" "}
                            </li>*/}
                            </ul>
                          </div>
                        </CardBody>
                      </Card>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          {/* Create user */}
          <Modal
            isOpen={createUserModal}
            toggle={oppupCreateUser}
            className="create-campaign-modal"
            style={{ maxWidth: "950px" }}
          >
            <ModalHeader toggle={oppupCreateUser}>
              <div className="search-box">
                <FormGroup>
                  <Label for="listSearch">Edit User</Label>
                </FormGroup>
              </div>
            </ModalHeader>
            <ModalBody>
              <UserFrom
                {...props}
                isInPopUp={true}
                refreshList={refreshList}
                userId={userData && userData.user_id}
              />
            </ModalBody>
          </Modal>
          <Modal
            isOpen={subComm}
            toggle={closeComm}
            className="price-override-modal"
          >
            <ModalHeader className="header-leftright-block warning">
              Price Override
              <span className="float-right">
                <i
                  onClick={closeComm}
                  className="far fa-times-circle popup-close"
                ></i>
              </span>
            </ModalHeader>
            <ModalBody>
              <div className="subscription-price-box">
                <FormGroup>
                  <Label for="subscriptionPrice">Subscription Price</Label>
                </FormGroup>
              </div>
              <Input
                type="text"
                placeholder="Enter new subscription price"
                name="overrideAmount"
                value={overrideAmount}
                onChange={changeOverrideAmt}
              ></Input>
              {/* <label className="red_err">{err.amt}</label> */}
            </ModalBody>
            <ModalFooter>
              <Button onClick={confirmOverride} type="submit" color="warning">
                Confirm Override
              </Button>
            </ModalFooter>
          </Modal>
          {/* End of create user */}

          {/* <Modal isOpen={openPayment} toggle={togglePayment} size="lg">
            <ModalHeader toggle={togglePayment}>{"Add Card"}</ModalHeader>
            <ModalBody>
              <CCForm
                {...props}
                toggle={togglePayment}
                paymentMethod={"cc"}
                userId={userData.user_id}
              />
            </ModalBody>
          </Modal> */}

          <Modal isOpen={showEditForm} toggle={toggleEditForm} size="lg">
            <ModalHeader toggle={toggleEditForm}>
              {"Manage Campaign"}
            </ModalHeader>
            <ModalBody>
              <div>
                <FormGroup>
                  <Label for="campaign_name">
                    Campaign Name: <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="campaign_name"
                    id="campaign_name"
                    value={campaignData && campaignData.campaign_name}
                    placeholder="Enter Campaign Name"
                    onChange={(e) => onChangeAction(e)}
                  />
                </FormGroup>

                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="campaign_target">
                        Campaign Target:
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        name="campaign_target"
                        value={campaignData && campaignData.campaign_target}
                        id="campaign_target"
                        onChange={(e) => onChangeAction(e)}
                      >
                        <option value="">Select Campaign Target</option>
                        <option value="0">Seller</option>
                        <option value="1">Buyer</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="campaign_type">
                        Campaign Type:
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="select"
                        name="campaign_type"
                        value={campaignData && campaignData.campaign_type}
                        id="campaign_type"
                        onChange={(e) => onChangeAction(e)}
                      >
                        {/* <option value="">Select Campaign Type</option> */}
                        <option value="0">Direct Mail</option>
                        {/* <option value="1">Probate</option>
                        <option value="2">Micro-Campaigns</option> */}
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <div>
                <Button color="primary edit-btn" onClick={updateCampaign}>
                  Save
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      )}
      <Modal
        isOpen={paymentModal}
        toggle={toggleCardPayment}
        className={className}
        size="lg"
      >
        <ModalHeader toggle={toggleCardPayment}>
          {clickType == "ach" ? (
            <div className="card-payment-header">
              <span>ACH</span>
              <span style={{ fontSize: "13px", color: "red" }}>
                &nbsp; *please note that ACH takes 7 days to process and may
                delay your mailing
              </span>
            </div>
          ) : (
            <div className="card-payment-header">
              <span>Add Card </span> &nbsp;
              <span style={{ fontSize: "13px", color: "red" }}>
                *user agrees to a 3% processing fee
              </span>
            </div>
          )}
        </ModalHeader>

        <ModalBody>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <CCForm
              {...props}
              getAllSavedCard={getUserCardListForPayment}
              formData={formData}
              IframeUrl={IframeUrl}
              clickType={clickType}
              paymentToggle={toggleCardPayment}
              userId={userData && userData.user_id}
            />
          )}
        </ModalBody>
      </Modal>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="create-user-modal commissions-override-modal"
      >
        <ModalBody>
          <CommissionOverrideForm
            {...props}
            isInPopUp={true}
            refreshList={refreshList}
            selectedUser={userData && userData.user_id}
            modalToggle={toggle}
            orderDetails={orderDetails}
            getOrderDetails={getOrderDetails}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateOrderApproval;
