import { NavigateBefore, NavigateBeforeOutlined } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

const ShortPaymentHistory = (props) => {
  const history = useHistory();
  const cardDetail = [
    "--",
    "--",
    "--",
    "Amex",
    "Visa",
    "Master Card",
    "Discover",
  ];
  const paymentStatusDetail = ["Failure", "Success", "Pending ACH"];
  const [paymentHistory, setPaymentHistory] = useState(props.paymentHistory);
  useEffect(() => {
    setPaymentHistory(props.paymentHistory);
  }, [props.paymentHistory]);
  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return d.getMonth() + 1 + "/" + d.getDate() + "/" + d.getFullYear();
  };
  const getCardDetail = (cType, payment_mode) => {
    cType = cType && cType !== "" ? parseInt(cType) : "";
    if (cType === "") return payment_mode;
    return cardDetail[cType];
  };

  const getPaymentStatusValue = (pCode) => {
    pCode = pCode && pCode !== "" ? parseInt(pCode) : "";
    if (pCode === "") return "--";

    return paymentStatusDetail[pCode];
  };

  const handleClick = () => history.push("/payment-history");

  return (
    <>
      <div className="payment-methods-box">
        <Card>
          <CardHeader>
            <CardTitle tag="h2">Payment History</CardTitle>
          </CardHeader>
          <CardBody>
            <div className="Payment-history-table-block">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Payment Method</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Card Number</th>
                    <th>Card Holder Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {paymentHistory == null && (
                    <tr>
                      <td colSpan={7} align="center">
                        {" "}
                        <div style={{ textAlign: "center" }}>
                          --- No Record ---
                        </div>
                      </td>
                    </tr>
                  )}

                  {paymentHistory &&
                    paymentHistory.map((item) => {
                      return (
                        <tr>
                          <td>{dateFormater(item.tran_date)}</td>
                          <td>
                            {getCardDetail(item.c_type, item.payment_mode)}
                          </td>
                          <td>
                            $
                            {Number(item.amount).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </td>
                          <td>{getPaymentStatusValue(item.payment_status)}</td>
                          <td>{item.card_no}</td>
                          <td>{item.nameoncard}</td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle>
                                <i className="fas fa-ellipsis-v"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* <DropdownItem header>Header</DropdownItem>
                                                                    <DropdownItem disabled>Action</DropdownItem>
                                                                    <DropdownItem>Another Action</DropdownItem>
                                                                    <DropdownItem divider /> */}
                                <DropdownItem>View Detail</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              <div className="viewmore-button-block">
                <Button onClick={handleClick}>View More</Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ShortPaymentHistory;
