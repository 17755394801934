import React, { useState, useEffect } from "react";
import { API_PROFILE_PICTURE } from "../../config/Api";
import {
  Card,
  CardBody,
  CardTitle,
  CardImg,
  CustomInput,
  FormGroup,
  Label,
} from "reactstrap";

const ProfileUserDetails = (props) => {
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    fetchUserImage();
  }, []);

  const fetchUserImage = () => {
    props
      .callRequest(
        "GET",
        API_PROFILE_PICTURE + props.getUserInfo().user_id,
        true
      )
      .then((res) => {
        setProfileImage(res.data.user_photo);
      });
  };

  const handleChange = (event) => {
    var file = event.target.files[0];
    const formData = new FormData();
    formData.append("user_photo", file, file.name);
    props
      .callRequest(
        "POST",
        API_PROFILE_PICTURE + props.getUserInfo().user_id + "/",
        true,
        formData
      )
      .then((res) => {
        setProfileImage(res.data.user_photo);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="user-profile-box">
        <Card>
          <div className="user-image">
            <CardImg
              top
              width="100%"
              src={
                profileImage
                  ? profileImage
                  : require("../../assets/img/face.jpg").default
              }
              alt="Profile"
            />
          </div>
          <CardBody>
            <div className="user-name">
              <CardTitle tag="h5">
                {props.userDetails.contact_first_name &&
                  props.userDetails.contact_first_name +
                    " " +
                    props.userDetails.contact_last_name}
              </CardTitle>
            </div>
            <div className="upload-profile-photo-block">
              <FormGroup>
                <Label for="uploadProfilePhoto">Upload Profile Photo</Label>
                <CustomInput
                  type="file"
                  id="user_photo"
                  accept="image/png, image/jpeg, .pdf"
                  onChange={handleChange}
                />
              </FormGroup>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ProfileUserDetails;
