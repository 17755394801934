import React, { useState, useEffect } from "react";
import "./UserForm.css";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { BreadCrumb } from "../../config/Components";
import PrintProfileInformation from "../Profile/PrintProfileInformation";
import WalletBalanceBox from "../Billing/WalletBalanceBox";
import SavedPaymentMethod from "../Billing/SavedPaymentMethod";
import AddToWalletManually from "./AddToWalletManually";
import {
  API_GET_USER,
  API_USERS,
  API_USER_GROUPS,
  API_GET_ROLES,
  API_GET_AFFILIATE,
  API_PAYMENT_SETTINGS,
  API_PROFILE,
  API_GET_WALLET_BALANCE,
  API_GET_ALL_SAVED_CARD,
  API_MANUALLY_UPDATE_WALLET,
  API_INACTIVE_A_USER,
  API_GET_SALES_REP,
} from "../../config/Api";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

const UserFrom = (props) => {
  const initUserInfo = {
    user_id:
      "userId" in props.match.params && props.match.params.userId !== 0
        ? props.match.params.userId
        : "userId" in props && props.userId
        ? props.userId
        : "",
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    original_password: "",
    reset_password: "",
    user_discount: parseFloat(0.0),
    wallet_balance: parseFloat(0.0),
    sales_rep_1: "",
    sales_rep_2: "",
    sales_rep_1_commission_type: 1,
    sales_rep_1_commission_percentage: parseFloat(0.0),
    sales_rep_1_commission_flat_rate: parseFloat(0.0),
    sales_rep_2_commission_type: 1,
    sales_rep_2_commission_percentage: parseFloat(0.0),
    sales_rep_2_commission_flat_rate: parseFloat(0.0),
    csr: "",
    is_state_tax: "0",
    state_tax_rate: "",
    is_active: "1",
    group: 1,
    affiliate_id: "",
    tier: 0,
    // client_suggested_marketing_coach: "",
  };
  const userType = useSelector((store) => store.getUserType);
  const [isInPopUp, setIsInPopUp] = useState(props.isInPopUp);
  const [user, setUser] = useState(initUserInfo);
  const [stateTax, setStateTax] = useState(0);
  const [userGroups, setUserGroups] = useState([]);
  const [multipleRoles, setMultipleRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [errors, setErrors] = useState({});
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [profile, setProfile] = useState({});
  const [walletBalance, setWalletBalance] = useState(0);
  const [savedCardList, setSavedCardList] = useState(null);
  const [inactiveUser, setInactiveUser] = useState(false);
  const [salesRep, setSalesRep] = useState([]);
  const [logo, setUpdatedLogo] = useState("");

  useEffect(() => {
    if (typeof user.user_id !== "undefined" && user.user_id > 0) {
      fetchUserById();
      fetchProfileInfo();
      getWalletBalance();
      getAllSavedCard();
    }
    fetchUserGroups();
    // fetchRoles();
    fetchAffiliates();
    fetchStateTax();
    fetchSalesRep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const getWalletBalance = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_WALLET_BALANCE}?user_id=${user.user_id}`,
        true
      )
      .then((res) => {
        setWalletBalance(res.data[0]["wallet_balance"]);
      })
      .catch((error) => {});
  };
  const getAllSavedCard = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_ALL_SAVED_CARD}?user_id=${user.user_id}`,
        true
      )
      .then((res) => {
        setSavedCardList(res.data);
      })
      .catch((error) => {});
  };
  const fetchAffiliates = () => {
    props.callRequest("GET", API_GET_AFFILIATE, true).then((res) => {
      setAffiliates(res.data);
    });
  };
  const fetchStateTax = () => {
    props.callRequest("GET", API_PAYMENT_SETTINGS, true).then((res) => {
      setStateTax(res.data.local_tax);
    });
  };
  const fetchUserById = async () => {
    props.callRequest("GET", API_GET_USER + user.user_id, true).then((res) => {
      let activeInactive = res.data.is_active === "0" ? 0 : res.data.is_active;
      // if (res.data.phone != "" && res.data.phone != "0") {
      //   let phone_split =
      //     res.data.phone.substring(0, 3) +
      //     "-" +
      //     res.data.phone.substring(3, 6) +
      //     "-" +
      //     res.data.phone.substring(6);

      //   setUser({ ...res.data, phone: phone_split, is_active: activeInactive });
      // } else {
      setUser({ ...res.data, is_active: activeInactive });
      // }
      if (res.data.is_active === "0") {
        setInactiveUser(true);
      }
      // setMultipleRoles(res.data.roles);
    });
  };
  const fetchProfileInfo = (id) => {
    props.callRequest("GET", API_PROFILE + user.user_id, true).then((res) => {
      setProfile(res.data.user_profile);
    });
  };
  const fetchUserGroups = async () => {
    props.callRequest("GET", API_USER_GROUPS, true).then((res) => {
      setUserGroups(res.data);
    });
  };

  // const fetchRoles = async () => {
  //   props.callRequest("GET", API_GET_ROLES, true).then((res) => {
  //     setRoles(res.data);
  //   });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });

    if (e.target.name === "phone") {
      if (e.target.value.match(/[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi)) {
        e.target.value = e.target.value.replace(
          /[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi,
          ""
        );
      }
      setUser({ ...user, [name]: value });
    }

    // if (name === "is_active") {
    //   if (e.target.checked) {
    //     setInactiveUser(false);
    //   }
    //   setUser({ ...user, [name]: value });
    // }
  };

  const validateForm = () => {
    let { username, firstname, lastname, password, email } = user;

    let formIsValid = true;
    let errors = {};
    if (username === "") {
      formIsValid = false;
      errors["err_username"] = "Please provide username";
    }
    if (firstname === "") {
      formIsValid = false;
      errors["err_firstname"] = "Please provide firstname";
    }
    if (lastname === "") {
      formIsValid = false;
      errors["err_lastname"] = "Please provide lastname";
    }
    if (password === "") {
      formIsValid = false;
      errors["err_password"] = "Please provide password";
    }

    // if (phone) {
    //   if (phone.length > 10) {
    //     formIsValid = false;
    //     errors["err_phone"] = "Please provide valid phone number";
    //   }
    // }
    if (!props.validateEmail(email)) {
      formIsValid = false;
      errors["err_email"] = "Please enter valid email address";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      let value = "";
      // const multipleRolesArr = multipleRoles.filter((item) => item !== value);
      if (user.user_id > 0) {
        const new_password =
          user.reset_password === undefined || user.reset_password === ""
            ? user.original_password
            : user.reset_password;

        let final_put_data = {
          username: user.username,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          phone: user.phone.replace(
            /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
            ""
          ),
          password: new_password,
          user_discount: user.user_discount,
          wallet_balance: user.wallet_balance,
          sales_rep_1: user.sales_rep_1 ? user.sales_rep_1 : null,
          sales_rep_2: user.sales_rep_2 ? user.sales_rep_2 : null,
          sales_rep_1_commission_type: user.sales_rep_1_commission_type,
          sales_rep_1_commission_percentage:
            user.sales_rep_1_commission_percentage,
          sales_rep_1_commission_flat_rate:
            user.sales_rep_1_commission_flat_rate,
          sales_rep_2_commission_type: user.sales_rep_2_commission_type,
          sales_rep_2_commission_percentage:
            user.sales_rep_2_commission_percentage,
          sales_rep_2_commission_flat_rate:
            user.sales_rep_2_commission_flat_rate,
          csr: user.csr,
          is_state_tax: user.is_state_tax,
          state_tax_rate: user.is_state_tax === "0" ? stateTax : 0,

          is_active: user.is_active,
          group: user.group,
          affiliate_id: user.affiliate_id,
          // roles: multipleRolesArr,
          tier: user.tier,
          // client_suggested_marketing_coach:
          //   user.client_suggested_marketing_coach,
        };
        props
          .callRequest(
            "PUT",
            API_USERS + user.user_id + "/",
            true,
            final_put_data
          )
          .then((res) => {
            fetchUserById();
            if (res.status) {
              const formData = new FormData();
              for (var key in updatedProfile) {
                if (key !== "user_logo") {
                  if (key === "imprint_phone") {
                    formData.append(
                      "imprint_phone",
                      updatedProfile.imprint_phone.replace(
                        /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                        ""
                      )
                    );
                  } else if (key === "imprint_cell") {
                    formData.append(
                      "imprint_cell",
                      updatedProfile.imprint_cell.replace(
                        /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                        ""
                      )
                    );
                  } else if (key === "contact_phone") {
                    formData.append(
                      "contact_phone",
                      user.phone.replace(
                        /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                        ""
                      )
                    );
                  } else {
                    formData.append(key, updatedProfile[key]);
                  }
                }
              }
              props.callRequest(
                "POST",
                API_PROFILE + user.user_id + "/",
                true,
                formData
              );
            }
            return res;
          })
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Updated successfully",
              });

              if (isInPopUp) {
                props.refreshList();
              } else {
                setTimeout(
                  function () {
                    //props.history.push("/user-list");
                  }.bind(),
                  3000
                );
              }
            }
          })
          .catch((error) => {
            // console.log(error.response.data, "err");
            if (error.response.data.error === "email id already exists") {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: "Email id already exists",
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: `${error.response.data.error}`,
              });
            }
          });
      } else {
        let final_post_data = {
          username: user.username,
          firstname: user.firstname,
          lastname: user.lastname,
          email: user.email,
          phone: user.phone.replace(
            /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
            ""
          ),
          password: user.password,
          user_discount: user.user_discount,
          wallet_balance: user.wallet_balance,
          sales_rep_1: user.sales_rep_1 ? user.sales_rep_1 : null,
          sales_rep_2: user.sales_rep_2 ? user.sales_rep_2 : null,
          sales_rep_1_commission_type: user.sales_rep_1_commission_type,
          sales_rep_1_commission_percentage:
            user.sales_rep_1_commission_percentage,
          sales_rep_1_commission_flat_rate:
            user.sales_rep_1_commission_flat_rate,
          sales_rep_2_commission_type: user.sales_rep_2_commission_type,
          sales_rep_2_commission_percentage:
            user.sales_rep_2_commission_percentage,
          sales_rep_2_commission_flat_rate:
            user.sales_rep_2_commission_flat_rate,
          csr: user.csr,
          is_state_tax: user.is_state_tax,
          state_tax_rate: user.is_state_tax === "0" ? stateTax : 0,
          is_active: user.is_active,
          group: user.group,
          affiliate_id: user.affiliate_id,
          // roles: multipleRolesArr,
          tier: user.tier,
          user_logo: logo,
          // client_suggested_marketing_coach:
          //   user.client_suggested_marketing_coach,
        };

        props
          .callRequest("POST", API_USERS, true, final_post_data)
          .then((res) => {
            if (res.status) {
              if (key !== "user_logo") {
                const formData = new FormData();
                for (var key in updatedProfile) {
                  formData.append(key, updatedProfile[key]);
                }
                props.callRequest(
                  "POST",
                  API_PROFILE + res.data.user_id + "/",
                  true,
                  formData
                );
              }
              return res;
            }
          })
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Saved successfully",
              });

              if (isInPopUp) {
                props.refreshList();
              } else {
                setTimeout(
                  function () {
                    props.history.goBack();
                  }.bind(),
                  3000
                );
              }
            }
          })
          .catch((error) => {
            if (error.response.data.error === "email id already exists") {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: "Email id already exists",
              });
            } else {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: `${error.response.data.error}`,
              });
            }
          });
      }
    }
  };

  const handleClose = () => {
    props.history.goBack();
  };
  const sendProfileInfo = (imprintInfo) => {
    setUpdatedProfile(imprintInfo);
  };

  const sendLogoInfo = (user_logo) => {
    setUpdatedLogo(user_logo);
  };

  const handleInactiveUser = (e) => {
    let active_inactive;
    let active_inactive_name;
    if (e.target.name === "is_active") {
      active_inactive = "1";
      active_inactive_name = "active";
    } else if (e.target.name === "inactiveUser") {
      active_inactive = "0";
      active_inactive_name = "inactive";
    }

    // setUser({ ...user, is_active: "0" });
    props
      .callRequest("POST", API_INACTIVE_A_USER, true, {
        user_id: user.user_id,
        is_active: active_inactive,
      })
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: `This user has been marked ${active_inactive_name} !!`,
        });
        if (active_inactive === "1") {
          setInactiveUser(false);
          setUser({ ...user, is_active: "1" });
        } else {
          setInactiveUser(true);
          setUser({ ...user, is_active: "0" });
        }
      })
      .catch((error) => {
        if (user.user_id === "" || user.user_id === undefined) {
          Swal.fire({
            icon: "failure",
            title: "Invalid user",
          });
        } else {
          Swal.fire({
            icon: "failure",
            title: "Something went wrong !!",
          });
        }
      });
  };

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  function formatUSNumber(entry = "") {
    let cleaned = ("" + entry).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  return (
    <>
      <main role="main" className="pt-3 px-4">
        {!isInPopUp && (
          <div className="main-heading-block">
            {user.user_id > 0 ? (
              <BreadCrumb links={["User List", "Edit User"]} />
            ) : (
              <BreadCrumb links={["User List", "Create User"]} />
            )}
            {user.user_id > 0 ? (
              <h1 className="header-title">
                <span className="header-icon">
                  <i className="ni ni-circle-08"></i>
                </span>
                Edit User
              </h1>
            ) : (
              <h1 className="header-title">
                <span className="header-icon">
                  <i className="ni ni-circle-08"></i>
                </span>
                Create User
              </h1>
            )}
          </div>
        )}
        <div className="payment-methods-main-block">
          <div className="create-user-mainblock">
            <Row>
              <Col
                sm={
                  typeof user.user_id !== "undefined" && user.user_id > 0
                    ? 7
                    : isInPopUp
                    ? 12
                    : 9
                }
                lg={
                  typeof user.user_id !== "undefined" && user.user_id > 0
                    ? 8
                    : isInPopUp
                    ? 12
                    : 10
                }
              >
                {" "}
                <Card>
                  <CardBody>
                    <div className="create-user-formblock">
                      <Form>
                        <FormGroup>
                          <Label for="username">User Name: *</Label>
                          <Input
                            type="text"
                            autoComplete="off"
                            name="username"
                            id="username"
                            value={user.username}
                            onChange={handleChange}
                            placeholder="Enter Username"
                          />
                          <label className="red_err">
                            {errors.err_username}
                          </label>
                        </FormGroup>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="firstname">First Name: *</Label>
                              <Input
                                type="text"
                                autoComplete="off"
                                name="firstname"
                                id="firstname"
                                value={user.firstname}
                                onChange={handleChange}
                                placeholder="Enter First Name"
                              />
                              <label className="red_err">
                                {errors.err_firstname}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="lastname">Last Name: *</Label>
                              <Input
                                type="text"
                                autoComplete="off"
                                name="lastname"
                                id="lastname"
                                value={user.lastname}
                                onChange={handleChange}
                                placeholder="Enter Last Name"
                              />
                              <label className="red_err">
                                {errors.err_lastname}
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label for="email">Email Address: *</Label>
                          <Input
                            type="email"
                            autoComplete="off"
                            name="email"
                            id="email"
                            value={user.email}
                            onChange={handleChange}
                            onKeyUp={handleChange}
                            placeholder="Enter Email Address"
                          />
                          <label className="red_err">{errors.err_email}</label>
                        </FormGroup>

                        {/* <Col md={6}>
                            <FormGroup>
                              <Label for="email">
                                {" "}
                                Client Suggested Marketing Coach:{" "}
                              </Label>
                              <Input
                                type="text"
                                autoComplete="off"
                                name="client_suggested_marketing_coach"
                                id="client_suggested_marketing_coach"
                                value={user.client_suggested_marketing_coach}
                                onChange={handleChange}
                                onKeyUp={handleChange}
                                placeholder="Name of Direct Marketing Coach"
                              />
                            </FormGroup>
                          </Col> */}

                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              {user.user_id > 0 ? (
                                <Label for="password">Password</Label>
                              ) : (
                                <Label for="password">Password: *</Label>
                              )}
                              {user.user_id > 0 ? (
                                <Input
                                  type="text"
                                  autoComplete="off"
                                  name="original_password"
                                  id="original_password"
                                  value={user.original_password}
                                  readOnly
                                />
                              ) : (
                                <Input
                                  type="text"
                                  autoComplete="off"
                                  name="password"
                                  id="password"
                                  value={user.password}
                                  onChange={handleChange}
                                  placeholder="Enter Password"
                                />
                              )}
                              {user.user_id > 0 && userType == 0 ? (
                                <Label for="resetPassword">
                                  Reset Password:
                                </Label>
                              ) : (
                                ""
                              )}
                              {user.user_id > 0 ? (
                                <Input
                                  type="text"
                                  autoComplete="off"
                                  name="reset_password"
                                  id="reset_password"
                                  value={user.reset_password || ""}
                                  onChange={handleChange}
                                  placeholder="Enter Password"
                                />
                              ) : (
                                ""
                              )}
                              <label className="red_err">
                                {errors.err_password}
                              </label>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="phone">Telephone Number:</Label>
                              <Input
                                type="text"
                                autoComplete="off"
                                name="phone"
                                id="phone"
                                value={user.phone && formatUSNumber(user.phone)}
                                onChange={handleChange}
                                placeholder="Enter Telephone Number"
                                maxlength={
                                  user.phone.replace(
                                    /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                                    ""
                                  ).length === 10
                                    ? 10
                                    : 14
                                }
                              />
                              <label className="red_err">
                                {errors.err_phone}
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="user_discount">User Discount:</Label>
                              <Input
                                type="number"
                                autoComplete="off"
                                name="user_discount"
                                id="user_discount"
                                value={user.user_discount}
                                onChange={handleChange}
                                placeholder="Enter User Discount"
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="affiliate_id">Affiliate:</Label>
                              <select
                                id="affiliate_id"
                                name="affiliate_id"
                                value={user.affiliate_id}
                                onChange={handleChange}
                                className="form-control"
                                aria-label="Default select example"
                              >
                                <option value="">Select Affiliate</option>
                                {affiliates.map((aff, index) => (
                                  <option
                                    key={index}
                                    value={aff.affiliate_id.id}
                                  >
                                    {aff.affiliate_id.name}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="sales_rep_1">
                                Direct Marketing Coach 1
                              </Label>
                              <select
                                id="sales_rep_1"
                                name="sales_rep_1"
                                value={user.sales_rep_1}
                                onChange={handleChange}
                                className="form-control"
                                aria-label="Default select example"
                              >
                                <option value="">
                                  Select Direct Marketing Coach 1
                                </option>

                                {salesRep.map((rep, index) => {
                                  return (
                                    <option
                                      key={rep.user_id}
                                      value={rep.user_id}
                                    >
                                      {rep.name}
                                    </option>
                                  );
                                })}

                                {/* <option value="Ryan Dixon">Ryan Dixon</option>
                                <option value="Corey Meeker">
                                  Corey Meeker
                                </option>
                                <option value="Emmie Gerritsen">
                                  Emmie Gerritsen
                                </option>
                                <option value="Nick Orf">Nick Orf</option>
                                <option value="Scott Snofke">
                                  Scott Snofke
                                </option> */}
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="sales_rep_2">
                                Direct Marketing Coach 2:
                              </Label>
                              <select
                                id="sales_rep_2"
                                name="sales_rep_2"
                                value={user.sales_rep_2}
                                onChange={handleChange}
                                className="form-control"
                                aria-label="Default select example"
                              >
                                <option value="">
                                  Select Direct Marketing Coach 2
                                </option>

                                {salesRep.map((rep, index) => {
                                  return (
                                    <option value={rep.user_id}>
                                      {rep.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </FormGroup>
                          </Col>
                          {/*  
                            <FormGroup>
                              <Label for="sales_rep_1">Sales Rep 1/ID:</Label>
                              <Input
                                type="text"
                                autoComplete="off"
                                name="sales_rep_1"
                                id="sales_rep_1"
                                value={user.sales_rep_1}
                                onChange={handleChange}
                                placeholder="Enter Sales Rep"
                              />
                            </FormGroup>
                               */}
                          {/* <Col md={6}>
                            <FormGroup>
                              <Label for="sales_rep_2">Sales Rep 2/ID:</Label>
                              <Input
                                type="text"
                                autoComplete="off"
                                name="sales_rep_2"
                                id="sales_rep_2"
                                value={user.sales_rep_2} 
                                onChange={handleChange}
                                placeholder="Enter Sales Rep ID"
                              />
                            </FormGroup>
                              </Col> */}
                        </Row>
                        <Row form>
                          <Col md={6}>
                            <FormGroup tag="fieldset">
                              <legend>Set Sales Rep 1 Commission</legend>
                              <div className="set-sales-formbox">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="sales_rep_1_commission_type"
                                      id="sales_rep_1_commission_type"
                                      value="1"
                                      checked={
                                        user.sales_rep_1_commission_type === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />
                                    Percentage
                                  </Label>
                                  <Input
                                    type="text"
                                    name="sales_rep_1_commission_percentage"
                                    id="sales_rep_1_commission_percentage"
                                    autoComplete="off"
                                    placeholder="Enter %"
                                    value={
                                      user.sales_rep_1_commission_percentage
                                    }
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="sales_rep_1_commission_type"
                                      id="sales_rep_1_commission_type1"
                                      value="0"
                                      checked={
                                        user.sales_rep_1_commission_type !== "1"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />
                                    Flat Rate
                                  </Label>
                                  <Input
                                    type="text"
                                    name="sales_rep_1_commission_flat_rate"
                                    id="sales_rep_1_commission_flat_rate"
                                    autoComplete="off"
                                    placeholder="1-100"
                                    value={
                                      user.sales_rep_1_commission_flat_rate
                                    }
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </div>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup tag="fieldset">
                              <legend>Set Sales Rep 2 Commission</legend>
                              <div className="set-sales-formbox">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="sales_rep_2_commission_type"
                                      id="sales_rep_2_commission_type"
                                      value="1"
                                      checked={
                                        user.sales_rep_2_commission_type === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />
                                    Percentage
                                  </Label>
                                  <Input
                                    type="text"
                                    name="sales_rep_2_commission_percentage"
                                    id="sales_rep_2_commission_percentage"
                                    autoComplete="off"
                                    placeholder="Enter %"
                                    value={
                                      user.sales_rep_2_commission_percentage
                                    }
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="sales_rep_2_commission_type"
                                      id="sales_rep_2_commission_type2"
                                      value="0"
                                      checked={
                                        user.sales_rep_2_commission_type !== "1"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />
                                    Flat Rate
                                  </Label>
                                  <Input
                                    type="text"
                                    name="sales_rep_2_commission_flat_rate"
                                    id="sales_rep_2_commission_flat_rate"
                                    autoComplete="off"
                                    placeholder="1-100"
                                    value={
                                      user.sales_rep_2_commission_flat_rate
                                    }
                                    onChange={handleChange}
                                  />
                                </FormGroup>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="csr">
                                CSR (Customer Service Rep)
                              </Label>
                              <Input
                                type="text"
                                name="csr"
                                id="csr"
                                placeholder="Enter CSR"
                                autoComplete="off"
                                value={user.csr}
                                onChange={handleChange}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup tag="fieldset">
                              <legend>Tax</legend>
                              <div className="set-sales-formbox">
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="is_state_tax"
                                      id="is_state_tax1"
                                      value="1"
                                      checked={
                                        user.is_state_tax === "1" ? true : false
                                      }
                                      onChange={handleChange}
                                    />
                                    Exempt
                                  </Label>
                                </FormGroup>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="is_state_tax"
                                      id="is_state_tax"
                                      value="0"
                                      checked={
                                        user.is_state_tax !== "1" ? true : false
                                      }
                                      onChange={handleChange}
                                    />
                                    State Tax: &nbsp;
                                    <span>{stateTax}</span>
                                  </Label>
                                  {/* <Input
                                  type="text"
                                  name="state_tax_rate"
                                  id="state_tax_rate"
                                  placeholder="Enter %"
                                  value={user.state_tax_rate}
                                  onChange={handleChange}
                                /> */}
                                </FormGroup>
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row form>
                          {/* <Col md={6}>
                            <FormGroup>
                              <Label for="roles">User Role:</Label>
                              <select
                                id="roles"
                                name="roles"
                                multiple={true}
                                value={multipleRoles}
                                onChange={handleMultipleRoleChange}
                                className="form-control"
                                aria-label="Default select example"
                              >
                                <option value="">Select Role</option>
                                {roles.map((role) => (
                                  <option
                                    key={role.role_id}
                                    value={role.role_id}
                                  >
                                    {role.role_name}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col> */}
                          <Col md={6}>
                            <FormGroup>
                              <Label for="group">User Group:</Label>
                              <select
                                id="group"
                                name="group"
                                value={user.group}
                                onChange={handleChange}
                                className="form-control"
                                aria-label="Default select example"
                              >
                                <option value={1}>Select User Group</option>
                                {userGroups.map((userGroup) => (
                                  <option
                                    key={userGroup.group_id}
                                    value={userGroup.group_id}
                                  >
                                    {userGroup.group_name}
                                  </option>
                                ))}
                              </select>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup>
                              <Label for="tier">Tier:</Label>
                              <select
                                id="tier"
                                name="tier"
                                value={user.tier}
                                onChange={handleChange}
                                className="form-control"
                                aria-label="Default select example"
                              >
                                {/* <option value="">Select Tier</option> */}
                                <option default value={0}>
                                  Tier1
                                </option>
                                <option value={1}>Tier2</option>
                                <option value={2}>Tier3</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup tag="fieldset">
                          <div className="radio-block">
                            <legend>Status &nbsp;</legend>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="radio"
                                  name="is_active"
                                  id="is_active"
                                  value="1"
                                  checked={
                                    user.is_active === "1" ? true : false
                                  }
                                  onChange={handleInactiveUser}
                                />
                                Active
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label>
                                <Input
                                  type="radio"
                                  name="inactiveUser"
                                  id="inactiveUser"
                                  checked={inactiveUser}
                                  // checked={inactiveUser === "1" ? true : false}
                                  onChange={handleInactiveUser}
                                />
                                Inactive
                              </Label>
                            </FormGroup>
                          </div>
                        </FormGroup>
                        <PrintProfileInformation
                          {...props}
                          userDetails={profile}
                          subProfile={1}
                          sendProfileInfo={sendProfileInfo}
                          sendLogoInfo={sendLogoInfo}
                        />
                        <div className="submit-button-block">
                          {!isInPopUp && (
                            <Button
                              className="cancel-button"
                              onClick={handleClose}
                            >
                              Cancel
                            </Button>
                          )}
                          <Button
                            className="create-User-button"
                            onClick={handleSubmit}
                          >
                            Save Changes
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {/* <Col sm="4" lg="3">
                            <div className="create-user-right-block">
                                <div className="product-image-preview-box">
                                    <Card>
                                        <CardImg top width="100%"
                                            src={require("../../assets/img/36_front.png").default}
                                            alt="template"
                                        />
                                    </Card>
                                </div>
                                <div className="product-image-preview-box">
                                    <Card>
                                        <CardImg top width="100%"
                                            src={require("../../assets/img/36_back.png").default}
                                            alt="template"
                                        />
                                    </Card>
                                </div>
                            </div>
                        </Col> */}
              {typeof user.user_id !== "undefined" && user.user_id > 0 ? (
                <Col sm="5" lg="4">
                  <div className="payment-methods-right-box wallet-balance-box">
                    <WalletBalanceBox
                      {...props}
                      userId={user.user_id}
                      walletBalance={walletBalance}
                      savedCardList={savedCardList}
                      getWalletBalance={getWalletBalance}
                    />
                  </div>
                  <div className="payment-methods-right-box wallet-balance-box">
                    <AddToWalletManually
                      {...props}
                      userId={user.user_id}
                      walletBalance={walletBalance}
                      savedCardList={savedCardList}
                      getWalletBalance={getWalletBalance}
                    />
                  </div>
                  <div className="payment-methods-block saved-payment-method">
                    <SavedPaymentMethod
                      {...props}
                      userBal={1}
                      userId={user.user_id}
                      savedCardList={savedCardList}
                      getAllSavedCard={getAllSavedCard}
                    />
                  </div>
                  {/* <div className="payment-methods-block add-new-payment-method">
                    <AddNewPaymentMethod
                      {...props}
                      getAllSavedCard={getAllSavedCard}
                    />
                  </div> */}
                </Col>
              ) : null}
            </Row>
          </div>
        </div>
      </main>
    </>
  );
};

export default withRouter(UserFrom);
