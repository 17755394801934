import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { Button, Input } from "reactstrap";
import { API_UPDATE_ORDER_ACCUTXT } from "../../config/Api";

const AccutxtInformation = (props) => {
  const [viewAccutxt, setViewAccutxt] = React.useState(props.viewAccutxt);
  const [errors, setErrors] = React.useState({});
  const [accutxtInfo, setAccutxtInfo] = React.useState({
    textResponse1:
      props.viewAccutxt && props.item.textResponse1
        ? props.item.textResponse1
        : props.productInfo && props.productInfo.textResponse1
        ? props.productInfo.textResponse1
        : "Select Your Option",
    responsetxt1:
      props.viewAccutxt && props.item.responsetxt1
        ? props.item.responsetxt1
        : props.productInfo && props.productInfo.responsetxt1
        ? props.productInfo.responsetxt1
        : "",
    textEmail1:
      props.viewAccutxt && props.item.textEmail1
        ? props.item.textEmail1
        : props.productInfo && props.productInfo.textEmail1
        ? props.productInfo.textEmail1
        : "",
    textEmail2:
      props.viewAccutxt && props.item.textEmail2
        ? props.item.textEmail2
        : props.productInfo && props.productInfo.textEmail2
        ? props.productInfo.textEmail2
        : "",
    textEmail3:
      props.viewAccutxt && props.item.textEmail3
        ? props.item.textEmail3
        : props.productInfo && props.productInfo.textEmail3
        ? props.productInfo.textEmail3
        : "",
  });

  // useEffect(() => {
  //   handleresponsetxt1();
  // }, [accutxtInfo.textResponse1]);
  const handleresponsetxt1 = (event) => {
    let currresponsetxt1;
    if (event === "Select Your Option") {
      currresponsetxt1 = "";
    }
    if (event === "I Can Make You An Instant Offer") {
      currresponsetxt1 = `Thank you for your interest in finding out how much I will offer to buy your house. I can make you an instant offer, but I need to know a couple of quick things first.  I will be calling you shortly, but if you want to talk to me now, call or text me at xxx-xxx-xxxx`;
    } else if (event === "I Will Pay You $xxxxx For Your House") {
      currresponsetxt1 = `Thank you for your interest in finding out how much I will offer to buy your house.  I will pay you $xxxxx for your house.  I may be Able to pay more, but I need to see the house first.  If interested in getting a better price, please call or text me at xxx-xxx-xxxx`;
    } else if (event === "I Am At Another Property Right Now") {
      currresponsetxt1 = `Billy Prospect, Thank you for your interest in finding out how much I will offer to buy your home. I am at another property right now. I need ????, my assistant, to give you a call to find out a couple things about your home. I will make you an instant offer. She'll be calling shortly.`;
    } else if (event === "Soft Offer Check Claim") {
      currresponsetxt1 = `Thank you for claiming your offer check! The next step is a phone call. Please let me know a good time to call and we'll continue to move the process forward. I look forward to speaking with you. Thanks again.`;
    }
    let newAccutxtInfo = {
      ...accutxtInfo,
      responsetxt1: currresponsetxt1,
    };
    return setAccutxtInfo({
      ...accutxtInfo,
      textResponse1: event,
      responsetxt1: currresponsetxt1,
    });
  };

  const validateForm = () => {
    let { textResponse1, responsetxt1, textEmail1, textEmail2, textEmail3 } =
      accutxtInfo;
    let errors = {};
    let formIsValid = true;

    if (textResponse1 == "Select Your Option" || textResponse1 == "") {
      formIsValid = false;
      errors["textResponse1"] = "Please choose a proper response";
    }

    if (responsetxt1 === "") {
      formIsValid = false;
      errors["responseText1"] = "Please provide a proper response";
    }

    if (textEmail1 === "" && textEmail2 === "" && textEmail3 === "") {
      formIsValid = false;
      errors["textEmail"] = "Please enter an email address";
    }

    if (textEmail1) {
      if (!props.validateEmail(textEmail1)) {
        formIsValid = false;
        errors["textEmail1"] = "Please enter valid email address";
      }
    }
    if (textEmail2) {
      if (!props.validateEmail(textEmail2)) {
        formIsValid = false;
        errors["textEmail2"] = "Please enter valid email address";
      }
    }
    if (textEmail3) {
      if (!props.validateEmail(textEmail3)) {
        formIsValid = false;
        errors["textEmail3"] = "Please enter valid email address";
      }
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (event) => {
    let currAccutxtInfo = {
      ...accutxtInfo,
      [event.target.name]: event.target.value,
    };
    setAccutxtInfo({ ...currAccutxtInfo });
  };
  const handleResponseChange = (event) => {
    handleresponsetxt1(event.target.value);
  };
  const handleSaveAccutxtInfo = (e) => {
    e.preventDefault();

    if (validateForm() == false) {
      return false;
    }
    let currAccInf = {};
    if (
      accutxtInfo.textResponse1 &&
      accutxtInfo.textResponse1 !== "Select Your Option"
    ) {
      currAccInf.textResponse1 = accutxtInfo.textResponse1;
    }
    if (accutxtInfo.responsetxt1) {
      currAccInf.responsetxt1 = accutxtInfo.responsetxt1;
    }
    if (accutxtInfo.textEmail1) {
      currAccInf.textEmail1 = accutxtInfo.textEmail1;
    }
    if (accutxtInfo.textEmail2) {
      currAccInf.textEmail2 = accutxtInfo.textEmail2;
    }
    if (accutxtInfo.textEmail3) {
      currAccInf.textEmail3 = accutxtInfo.textEmail3;
    }
    if (props.viewAccutxt) {
      let post_data = {
        ...currAccInf,
        mailing_date_id: props.item.mailing_date_id,
        order_id: props.item.order,
      };

      props
        .callRequest("POST", API_UPDATE_ORDER_ACCUTXT, true, post_data)
        .then((res) => {
          if (res.data.Status) {
            Swal.fire(
              "Success",
              "Accutxt Information updated successfully!!",
              "success"
            );
            props.closeTextiumHandler();
            props.fetchProducts();
          }
        })
        .catch((e) => {
          Swal.fire("Error", "Something went wrong!!", "error");
        });
    }
    if (!props.viewAccutxt) {
      // if (
      //   accutxtInfo.textResponse1 === "Select Your Option" &&
      //   accutxtInfo.responsetxt1 === "" &&
      //   accutxtInfo.textEmail1 === "" &&
      //   accutxtInfo.textEmail2 === "" &&
      //   accutxtInfo.textEmail3 === ""
      // ) {
      //   props.handleAccutxtClose();
      // } else {
      props.sendAccutxtInfo(currAccInf);
      props.handleAccutxtData();
      props.handleAccutxtClose();
      // }
    }
  };
  const handleEditAccutxtClose = (event) => {
    event.preventDefault();
    props.handleAccutxtClose();
  };

  const handleViewAccutxtClose = (event) => {
    event.preventDefault();
    props.closeTextiumHandler();
  };
  return (
    <>
      <div>
        <div>
          <h4 className="accutxt-text-response">Text Response Message</h4>
          <form className="accutxt-modal-form">
            <select
              className="accutext-response-select"
              name="textResponse1"
              value={accutxtInfo.textResponse1}
              onChange={handleResponseChange}
            >
              <>
                <option value="Select Your Option">Select Your Option</option>

                <option value="I Can Make You An Instant Offer">
                  I Can Make You An Instant Offer
                </option>
                <option value="I Will Pay You $xxxxx For Your House">
                  I Will Pay You $xxxxx For Your House
                </option>
                <option value="I Am At Another Property Right Now">
                  I Am At Another Property Right Now
                </option>
                <option value="Soft Offer Check Claim">
                  Soft Offer Check Claim
                </option>
              </>
            </select>
            {errors.textResponse1 ? (
              <label className="text-danger">
                <i className="fas fa-exclamation-circle"></i>
                {errors.textResponse1}
              </label>
            ) : (
              ""
            )}

            <div className="control">
              <textarea
                className="accutext-response-textfield"
                id="responsetxt1"
                name="responsetxt1"
                value={accutxtInfo.responsetxt1}
                rows="4"
                cols="50"
                onChange={handleChange}
              ></textarea>
            </div>
            {errors.responseText1 ? (
              <label className="text-danger">
                <i className="fas fa-exclamation-circle"></i>
                {errors.responseText1}
              </label>
            ) : (
              ""
            )}
            <h4 className="accutxt-text-response">Notification Emails</h4>
            {errors.textEmail ? (
              <label className="text-danger">
                <i className="fas fa-exclamation-circle"></i>
                {errors.textEmail}
              </label>
            ) : (
              ""
            )}
            <div className="accutxt-input-modal">
              <Input
                className="accutext-response-select"
                type="text"
                id="textEmail1"
                name="textEmail1"
                value={accutxtInfo.textEmail1}
                autoComplete="off"
                onChange={handleChange}
              />
              {errors.textEmail1 ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.textEmail1}
                </label>
              ) : (
                ""
              )}
              <Input
                className="accutext-response-select"
                type="text"
                id="textEmail2"
                name="textEmail2"
                value={accutxtInfo.textEmail2}
                autoComplete="off"
                onChange={handleChange}
              />
              {errors.textEmail2 ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.textEmail2}
                </label>
              ) : (
                ""
              )}
              <Input
                className="accutext-response-select"
                type="text"
                id="textEmail3"
                name="textEmail3"
                value={accutxtInfo.textEmail3}
                autoComplete="off"
                onChange={handleChange}
              />
              {errors.textEmail3 ? (
                <label className="text-danger">
                  <i className="fas fa-exclamation-circle"></i>
                  {errors.textEmail3}
                </label>
              ) : (
                ""
              )}
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <Button
                className="accutxt-info-button"
                // style={{ backgroundColor: "#e30613", color: "#fff" }}
                onClick={handleSaveAccutxtInfo}
                type="submit"
              >
                Save
              </Button>

              <Button
                className="accutxt-info-button"
                onClick={
                  props.viewAccutxt == 1
                    ? handleViewAccutxtClose
                    : handleEditAccutxtClose
                }
                type="submit"
              >
                Cancel
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AccutxtInformation;
