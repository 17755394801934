import { Route, Switch, Redirect, withRouter } from "react-router-dom";

import { Login, AdminLayout } from "./config/Components";

import Common from "./utils/Common";

function App(props) {
  let global = { ...props };
  return (
    <Switch>
      <Route
        path="/"
        exact
        render={(props) => <Login {...global} {...props} />}
      />
      <Route
        path="/"
        render={(props) => <AdminLayout {...global} {...props} />}
      />
      <Redirect from="/" to="/" />
    </Switch>
  );
}

export default withRouter(Common(App));
