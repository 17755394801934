import { useState, useEffect, useMemo } from "react";
import Swal from "sweetalert2";
import { Card, CardBody, Table, CardTitle } from "reactstrap";
import moment from "moment";

import {
  API_VDP_LISTING,
  API_TEMPLATES,
  API_DELETE_VDP,
} from "../../config/Api";

const VDPMergeList = (props) => {
  const [loading, setLoading] = useState(true);
  const [mergeList, setMergeList] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [delete_record, setDeleteRecord] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchMergeList();
  }, [page]);

  const fetchMergeList = () => {
    props.callRequest("GET", API_VDP_LISTING + page, true).then((res) => {
      let updatedRecords = res.data.map((res) => {
        res.html_path = res.pdf_path.replace("pdf.zip", "html.zip");
        return res;
      });

      //let mergedArr = [...prevState, ...updatedRecords];
      setMergeList((prevmergeList) => [...prevmergeList, ...updatedRecords]);

      if (updatedRecords.length == 10) {
        setPage(page + 1);
        //fetchMergeList();
      }
    });
  };

  const MergeProcess = (id) => {
    if (id == 1) {
      return "Production Merge";
    }
    if (id == 2) {
      return "Proof Merge";
    }
    if (id == 3) {
      return "Partial Proof";
    }
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        props.callRequest("DELETE", API_DELETE_VDP + id, true).then((res) => {
          if (res.status) {
            Swal.fire("Deleted!", "", "success");
            let currUpdatedMergeList = [...mergeList];
            let updatedMergeList = currUpdatedMergeList.filter(
              (item) => item.vdp_id !== id
            );
            setMergeList(updatedMergeList);
            //window.location.reload();
          }
        });
      }
    });
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <Card>
          <CardTitle style={{ padding: "5px 5px", margin: "5px 20px" }}>
            <strong>Previous Merges</strong>
          </CardTitle>
          <CardBody className="vdp-merge-listing-table">
            <div className="Payment-history-table-block">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Template</th>
                    <th>Process</th>
                    <th>Profile</th>
                    <th>CSV FIle</th>
                    <th>Status</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {mergeList == null && (
                    <tr>
                      <td colSpan={7} align="center">
                        {" "}
                        <div style={{ textAlign: "center" }}>
                          --- No Record ---
                        </div>
                      </td>
                    </tr>
                  )}
                  {mergeList &&
                    mergeList.map((item) => {
                      return (
                        <tr key={item.vdp_id}>
                          <td width="100" title={item.template_name}>
                            <div
                              style={{
                                width: "150px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.template_name}
                            </div>
                          </td>
                          <td>{MergeProcess(item.marge_process_type)}</td>
                          <td>{item.username}</td>
                          <td>{item.csv_file_name}</td>
                          <td>
                            {item.status == 1
                              ? "completed"
                              : item.status == 0
                              ? "in-progress"
                              : "--"}
                          </td>
                          <td>{moment(item.time).format("MM/DD/YYYY")}</td>
                          <td style={{ textAlign: "center" }}>
                            <span>
                              <a
                                style={{ padding: "5px", color: "red" }}
                                title="Download pdf zip"
                                href={item.pdf_path}
                                //target="_blank"
                                download
                              >
                                <i className="fas fa-file-pdf"></i>
                              </a>
                            </span>
                            <span className="csv-download-btn">
                              <a
                                style={{ padding: "5px" }}
                                title="Download csv"
                                href={item.csv_path}
                                download
                              >
                                <i className="fas fa-file-download"></i>
                              </a>
                            </span>
                            <span className="csv-download-btn">
                              <a
                                style={{ padding: "5px" }}
                                title="Download html zip"
                                href={item.html_path}
                                // target="_blank"
                                download
                              >
                                <i className="fas fa-file-code"></i>
                              </a>
                            </span>
                            {/* #5e72e4*/}
                            <span>
                              <a
                                style={{
                                  padding: "5px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  onClick={() => handleDelete(item.vdp_id)}
                                  className="fas fa-trash"
                                ></i>
                              </a>
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {/* <div>
                <Button onClick={scrollToEnd}>View More</Button>
              </div> */}
            </div>
          </CardBody>
        </Card>
      </main>
    </>
  );
};

export default VDPMergeList;
