import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";

import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";
import { API_USER_GROUPS } from "../../config/Api";

const UserGroups = (props) => {
  const [userGroups, setUserGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const searchParam = ["group_name"];

  useEffect(() => {
    fetchUserGroups();
  }, []);

  const fetchUserGroups = async () => {
    props.callRequest("GET", API_USER_GROUPS, true).then((res) => {
      setUserGroups(res.data);
      setLoading(false);
    });
  };

  const columns = [
    {
      name: "Group Name",
      selector: "group_name",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => editGroup(row.group_id)}>
              Edit
            </DropdownItem>
            {/* <DropdownItem>Delete</DropdownItem> */}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const editGroup = (group_id) => {
    props.history.push("/edit-group/" + group_id);
  };

  const export_column = ["group_name"];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          autoComplete="off"
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = ({ onExport }) => (
    <div className="right-button-block">
      <Button onClick={() => props.history.push("/create-group")}>
        {/* <Button onClick={() => props.history.push("/admin/create-group")}> */}
        <i className="ni ni-fat-add"></i> Add User Groups
      </Button>
      {/* <Button>
        <i className="bi-pencil-square"></i> Edit Columns
      </Button> */}
      {/* <Button
        disabled={userGroups.length > 0 ? false : true}
        onClick={(e) => onExport(e.target.value)}
      >
        <i className="ni ni-ungroup"></i> Export
      </Button> */}
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            userGroups,
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [userGroups]
  );

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Users", "User Groups"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-circle-08"></i>
            </span>
            User Groups
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                {loading === true ? <h3>Loading...</h3> : null}
                {loading === false ? (
                  <div className="list-filtered-table-block">
                    <DataTable
                      columns={columns}
                      data={DataTableSettings.filterItems(
                        userGroups,
                        searchParam,
                        filterText
                      )}
                      pagination
                      paginationPerPage={DataTableSettings.paginationPerPage}
                      paginationRowsPerPageOptions={
                        DataTableSettings.paginationRowsPerPageOptions
                      }
                      actions={actionsExport}
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                      persistTableHead
                    />
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default UserGroups;
