import React, { useState, useEffect } from "react";
import { Button, Row, Col, FormGroup, Label, Input, Modal } from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";
import EditTemplate from "./../TemplateDesign/edit/EditTemplate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AccutxtInformation from "../Orders/AccutxtInformation";

import {
  API_CART_DETAILS,
  API_GET_USER_TEMPLATE_PRODUCT,
  API_UPDATE_ORDER_TEMPLATE,
  UPDATE_DESIRED_MAILING_DATE,
  API_GET_HOLIDAY_LIST,
} from "../../config/Api";
import { Dropdown } from "semantic-ui-react";
import "./semantic.css";

const getFormattedDate = (timestamp) => {
  let date = new Date(timestamp);
  return (
    date.getFullYear() +
    "-" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date.getDate()).slice(-2)
  );
};

const getDefaultDate = (date, days) => {
  const copy = new Date(Number(date));
  copy.setDate(date.getDate() + days);
  return getFormattedDate(copy);
};

const ProductDetail = (props) => {
  const [editAccutxt, setEditAccutxt] = useState(false);
  const [accuTXTData, setAccuTXTData] = useState(false);
  const [postage, setPostage] = useState(props.postage);
  const [singleProductInfo, setSingleProductInfo] = useState();
  const [orderStatus, setOrderStatus] = useState(
    "orderStatus" in props && props.orderStatus ? props.orderStatus : null
  );
  const [orderId, setOrderId] = useState(
    "orderId" in props && props.orderId ? props.orderId : null
  );
  const [orderDetails, setOrderDetails] = useState(
    "orderDetails" in props && props.orderDetails ? props.orderDetails : {}
  );
  //orderDetails
  const [holidayList, setHolidayList] = useState();
  const [uiReady, setUiReady] = useState(false);
  const product_details = {
    ...props.selectedProductForEdit,
    product_name: "Product Name",
    prod_id:
      "prdId" in props && props.prdId
        ? props.prdId
        : "selectedProductForEdit" in props &&
          props.selectedProductForEdit.item_id
        ? props.selectedProductForEdit.item_id
        : props.selectedProductForEdit &&
          props.selectedProductForEdit.product_id,
    sizeid:
      "selectedProductForEdit" in props && props.selectedProductForEdit.size_id
        ? props.selectedProductForEdit.size_id
        : "",
    estimated_quantity:
      "selectedProductForEdit" in props &&
      props.selectedProductForEdit.estimated_quantity
        ? props.selectedProductForEdit.estimated_quantity
        : props.selectedProductForEdit && props.selectedProductForEdit.quantity,
    mailing_date:
      "selectedProductForEdit" in props &&
      "mailing_date" in props.selectedProductForEdit &&
      props.selectedProductForEdit.mailing_date
        ? props.selectedProductForEdit.mailing_date
        : "selectedProductForEdit" in props &&
          "date" in props.selectedProductForEdit &&
          props.selectedProductForEdit.date
        ? moment(props.selectedProductForEdit.date,"MM-DD-YYYY").toDate()
        : "",
    template:
      "selectedProductForEdit" in props
        ? "template" in props.selectedProductForEdit
          ? props.selectedProductForEdit.template
          : "template_id" in props.selectedProductForEdit
          ? props.selectedProductForEdit.template_id
          : ""
        : "",
    // "selectedProductForEdit" in props && props.selectedProductForEdit.template
    //   ? props.selectedProductForEdit.template
    //   : "selectedProductForEdit" in props &&
    //     "template_id" in props.selectedProductForEdit
    //   ? props.selectedProductForEdit.template_id
    //   : "",

    // Postage:
    //   "selectedProductForEdit" in props &&
    //   props.selectedProductForEdit.postage_id
    //     ? props.selectedProductForEdit.postage_id
    //     : props.selectedProductForEdit && props.selectedProductForEdit.Postage,

    Postage:
      "selectedProductForEdit" in props &&
      props.selectedProductForEdit.postage_id
        ? props.selectedProductForEdit.postage_id
        : props.selectedProductForEdit && props.selectedProductForEdit.Postage,

    Envelope:
      "selectedProductForEdit" in props &&
      props.selectedProductForEdit.envelope_id
        ? props.selectedProductForEdit.envelope_id
        : props.selectedProductForEdit && props.selectedProductForEdit.Envelope,
    InkColor:
      "selectedProductForEdit" in props && props.selectedProductForEdit.ink_id
        ? props.selectedProductForEdit.ink_id
        : props.selectedProductForEdit && props.selectedProductForEdit.InkColor,
    PaperColor:
      "selectedProductForEdit" in props && props.selectedProductForEdit.paper_id
        ? props.selectedProductForEdit.paper_id
        : props.selectedProductForEdit &&
          props.selectedProductForEdit.PaperColor,
    image: props.invImage !== undefined ? props.invImage : "",
  };
  const [productInfo, setProductInfo] = useState(product_details);

  const [singleProductData, setSingleProductData] = useState({});
  const [templates, setTemplates] = useState([]);

  const [attributes, setAttributes] = useState(props.attributes);

  const [envelope, setEnvelope] = useState(props.envelope);
  const [paper, setPaper] = useState(props.paper);
  const [ink, setInk] = useState(props.ink);
  const [accTXT, setAccTXT] = useState(props.accTXT);
  const [accuPIX, setAccuPIX] = useState(props.accuPIX);

  const [editProduct, setEditProduct] = useState(props.updateProduct);
  const [showEditTemplate, setShowEditTemplate] = useState(false);
  const [categoryShowHide, setCategoryShowHide] = useState({
    envelope: true,
    postage: true,
    ink: true,
    paper: true,
    accTXT: true,
    accuPIX: true,
  });

  const [dateAdded, setDateAdded] = useState(props.dateAdded);
  const [accuInfo, setAccuInfo] = useState();
  const [campaignName, setCampaignName] = useState(props.campaignName);

  useEffect(() => {
    setDateAdded(props.dateAdded);
  }, [props.dateAdded]);

  const updatedProduct = (productInfo) => {
    props.updatedProduct(productInfo);
  };

  useEffect(() => {
    setOrderStatus(props.orderStatus);
  }, [props.orderStatus]);
  useEffect(() => {
    //fetchTemplates();
    props.prdId != undefined && fetchProducts(props.prdId);
  }, [props.prdId]);

  // useEffect(() => {
  //   console.log("edit");
  //   "item_id" in props.selectedProductForEdit &&
  //     fetchProducts(props.selectedProductForEdit.item_id);
  // }, [props.selectedProductForEdit]);

  // useEffect(() => {

  // }, [props]);
  useEffect(() => {
    if (
      singleProductInfo !== undefined &&
      ink !== undefined &&
      postage !== undefined &&
      envelope !== undefined &&
      paper !== undefined
    ) {
      // fetchDropdowns();
    }
  }, [singleProductInfo]);
  useEffect(() => {
    if (
      props.cartId !== undefined &&
      props.ProductDetailId &&
      props.ProductDetailId !== 0 &&
      props.sizeId !== 0
    ) {
      fetchSingleProduct();
      props.ProductDetailId != undefined &&
        fetchProducts(props.ProductDetailId);
    }
    // if (props.updateProduct === true) {
    //   props.updatedProductInfo(productInfo);
    // }
  }, [props]);
  useEffect(() => {
    if (props.updateProduct === true) {
      setEditProduct((preveditProduct) => true);
      edittedProductFlag(editProduct);
    }
  }, []);

  useEffect(() => {
    fetchHolidayList();
  }, []);

  const fetchSingleProduct = () => {
    if (!props.cartId) {
      return false;
    }
    props
      .callRequest("GET", API_CART_DETAILS + props.cartId, true)
      .then((res) => {
        let productData = res.data.Product.filter((prd) => {
          return (
            Number(prd.item_id) === Number(props.ProductDetailId) &&
            Number(prd.size_id) === Number(props.sizeId)
          );
        })[0];

        setSingleProductData(productData);

        let quantity = productData.quantity;
        let date = productData.date;
        let ink_type = productData.ink_id;
        let template_id = productData.template_id;
        let paper_type = productData.paper_id;
        let envelope = productData.envelope_id;
        let postage = productData.postage_id;
        let image = productData.item_img_url;
        // setProductInfo({
        //   ...productInfo,
        //   ...product_details,
        //   // estimated_quantity: quantity,
        //   // mailing_date: getFormattedDate(new Date(moment.unix(date))),
        //   // template: template_id,
        //   // "Ink Color": ink_type,
        //   // "Paper Color": paper_type,
        //   // Postage: postage,
        //   // Envelope: envelope,
        //   // image: image,
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChangeAction = (event) => {
    const { name, value } = event.target;
    let prodInfo = { ...productInfo, [name]: value };
    setProductInfo({ ...prodInfo });
  };
  const fetchProducts = (id) => {
    if (id && id != 0) {
      props
        .callRequest(
          "GET",
          API_GET_USER_TEMPLATE_PRODUCT +
            id +
            "/?user_id=" +
            props.selectedUserDetail.user_id,
          true
        )
        .then((res) => {
          setSingleProductInfo(res.data);
          let mailing_date_limit =
            "mailing_date_limit" in res.data && res.data.mailing_date_limit
              ? Number(res.data.mailing_date_limit)
              : 5;

          let tmpProduct = {
            ...res.data,

            //...productInfo,
            ...product_details,
            min_qnty:
              "min_qnty" in res.data && res.data.min_qnty
                ? res.data.min_qnty
                : 1,
            mailing_date:
              product_details.mailing_date == ""
                ? "" //calculateMin(res.data.mailing_date_limit) //getDefaultDate(new Date(), mailing_date_limit)
                : product_details.mailing_date,
            product_name:
              "item" in res.data && res.data.item
                ? res.data.item
                : "product_name" in res.data && res.data.product_name
                ? res.data.product_name
                : product_details.product_name,
            attribute_default: res.data.attribute_default,
            // estimated_quantity:
            //   res.data && "min_qnty" in res.data ? res.data.min_qnty : 0,
            // ...res.data,
            //template: res.data.template[0].template_id,
          };

          if (!productInfo.template || productInfo.template == "") {
            tmpProduct.template =
              "template" in res.data &&
              res.data.template &&
              res.data.template.length > 0
                ? res.data.template[0]["template_id"]
                : "";
          }

          let attribute_default = res.data.attribute_default;
          let categoryShowHideDetail = { ...categoryShowHide };

          let filter_accTXT = attribute_default.filter(
            (i) => accTXT.filter((item) => item.attribute_id == i).length > 0
          );
          categoryShowHideDetail.accTXT =
            filter_accTXT && filter_accTXT.length > 0 ? true : false;

          if (categoryShowHideDetail.accTXT && !productInfo.accutext_id) {
            tmpProduct.accutext_id = filter_accTXT[0];
          }

          let filter_accuPIX = attribute_default.filter(
            (i) => accuPIX.filter((item) => item.attribute_id == i).length > 0
          );
          categoryShowHideDetail.accuPIX =
            filter_accuPIX && filter_accuPIX.length > 0 ? true : false;
          if (
            categoryShowHideDetail.accuPIX &&
            filter_accuPIX &&
            filter_accuPIX.length > 0 &&
            !productInfo.accupix_id
          ) {
            tmpProduct.accupix_id = filter_accuPIX[0];
          }

          let filter_envelope = attribute_default.filter(
            (i) => envelope.filter((item) => item.attribute_id == i).length > 0
          );
            
          categoryShowHideDetail.envelope =
            filter_envelope && filter_envelope.length > 0 ? true : false;

          if (
            categoryShowHideDetail.envelope &&
            filter_envelope &&
            filter_envelope.length > 0 &&
            !productInfo.Envelope
          ) {
            tmpProduct.Envelope = filter_envelope[0];
          }
          let filter_postage = attribute_default.filter(
            (i) => postage.filter((item) => item.attribute_id == i).length > 0
          );

          categoryShowHideDetail.postage =
            filter_postage && filter_postage.length > 0 ? true : false;
          if (
            categoryShowHideDetail.postage &&
            filter_postage &&
            filter_postage.length > 0 &&
            !productInfo.Postage
          ) {
            //tmpProduct.Postage = filter_postage[0];
          }

          let filter_paper = attribute_default.filter(
            (i) => paper.filter((item) => item.attribute_id == i).length > 0
          );

          categoryShowHideDetail.paper =
            filter_paper && filter_paper.length > 0 ? true : false;

          if (
            filter_paper &&
            filter_paper.length &&
            categoryShowHideDetail.paper &&
            !productInfo.PaperColor
          ) {
            tmpProduct.PaperColor = filter_paper[0];
          }

          let filter_ink = attribute_default.filter(
            (i) => ink.filter((item) => item.attribute_id == i).length > 0
          );

          categoryShowHideDetail.ink =
            filter_ink && filter_ink.length > 0 ? true : false;

          if (
            categoryShowHideDetail.ink &&
            filter_ink &&
            filter_ink.length > 0 &&
            !productInfo.InkColor
          ) {
            tmpProduct.InkColor = filter_ink[0];
          }

          setCategoryShowHide({ ...categoryShowHideDetail });
          setProductInfo(tmpProduct);
          setUiReady(true);
        });
    }
  };
  const validateForm = () => {
    let tempproductError = { ...productError };
    let returnVal = true;
    let formEle = {
      //estimated_quantity: "Estimated quantity is required",
      template: "Template is required",
      Postage: "Postage is required",
      Envelope: " Envelope is required",
      InkColor: "Ink Color is required",
      PaperColor: "Paper Color is required",
      accutext_id: "AccuText required",
      accupix_id: "AccuPix required",
    };

    let min_qnty =
      "min_qnty" in productInfo && productInfo.min_qnty
        ? Number(productInfo.min_qnty)
        : 100;

    if ("mailing_date" in productInfo && productInfo.mailing_date == "") {
      tempproductError.mailing_date = true;
      returnVal = false;
    } else {
      tempproductError.mailing_date = false;
    }

    if (
      productInfo["estimated_quantity"] < min_qnty ||
      !productInfo["estimated_quantity"]
    ) {
      //Swal.fire("Validation!", formEle.estimated_quantity, "error");
      tempproductError.estimated_quantity = true;
      returnVal = false;
    } else {
      tempproductError.estimated_quantity = false;
    }

    for (var it in formEle) {
      if (it in productInfo && productInfo[it] == "" && productInfo[it] != 0) {
        tempproductError[it] = true;
        // Swal.fire("Validation!", formEle[it], "error");
        returnVal = false;
      } else {
        tempproductError[it] = false;
      }
    }
    if (categoryShowHide.accTXT) {
      if (!productInfo.responsetxt1 && !productInfo.textResponse1) {
        tempproductError.responsetxt1 = true;
        tempproductError.textResponse1 = true;
        returnVal = false;
        Swal.fire({
          title: "Please provide Accutxt Information!!",
          showConfirmButton: true,
        });
      } else {
        tempproductError.responsetxt1 = false;
        tempproductError.textResponse1 = false;
      }
    }

    setProductError(tempproductError);
    return returnVal;
  };
  // const fetchAttributeValue = ()=>{

  // }
  const fetchDropdowns = () => {
    let inkDropDown = [];
    singleProductInfo.attribute_default.map((attr_id) => {
      ink.map((ink) => {
        if (attr_id === ink.attribute_id) {
          inkDropDown.push(ink);
          setInk(inkDropDown);
        }
      });
    });
    // if (inkDropDown.length == 1) {
    //   setProductInfo({
    //     ...productInfo,
    //     "Ink Color": inkDropDown[0].attribute_id,
    //   });
    // }
    let postageDropDown = [];
    singleProductInfo.attribute_default.map((attr_id) => {
      postage.map((postage) => {
        if (attr_id === postage.attribute_id) {
          postageDropDown.push(postage);
          setPostage(postageDropDown);
        }
      });
    });
    // if (postageDropDown.length == 1) {
    //   setProductInfo({
    //     ...productInfo,
    //     Postage: postageDropDown[0].attribute_id,
    //   });
    // }
    let envelopeDropDown = [];
    singleProductInfo.attribute_default.map((attr_id) => {
      envelope.map((envelope) => {
        if (attr_id === envelope.attribute_id) {
          envelopeDropDown.push(envelope);
          setEnvelope(envelopeDropDown);
        }
      });
    });
    // if (envelopeDropDown.length == 1) {
    //   setProductInfo({
    //     ...productInfo,
    //     Envelope: envelopeDropDown[0].attribute_id,
    //   });
    // }
    let paperDropDown = [];
    singleProductInfo.attribute_default.map((attr_id) => {
      paper.map((ppr) => {
        if (attr_id === ppr.attribute_id) {
          paperDropDown.push(ppr);
          setPaper(paperDropDown);
        }
      });
    });
    //   if (paperDropDown.length == 1) {
    //     setProductInfo({
    //       ...productInfo,
    //       "Paper Color": paperDropDown[0].attribute_id,
    //     });
    //   }
  };

  const selectedProduct = (productInfo) => {
    let temp = { ...productInfo, product_id: props.prdId };
    //do the calculation
    if (temp) {
      //normal price
      let totalItemPrice =
        getPrice(temp.price, temp.estimated_quantity, 1) *
        temp.estimated_quantity;
      let unit_price = parseFloat(
        getPrice(temp.price, temp.estimated_quantity, 1)
      );

      if (
        "accutext_id" in temp &&
        temp["accutext_id"] &&
        accTXT &&
        accTXT.length > 0
      ) {
        let tempPrice = accTXT.filter(
          (it) => it.attribute_id == temp["accutext_id"]
        )[0];

        tempPrice = tempPrice ? tempPrice.price : undefined;

        totalItemPrice += tempPrice
          ? getPrice(tempPrice, temp.estimated_quantity, 1) *
            temp.estimated_quantity
          : 0;
        unit_price += parseFloat(
          tempPrice ? getPrice(tempPrice, temp.estimated_quantity, 1) : 0
        );
      }

      if (
        "accupix_id" in temp &&
        temp["accupix_id"] &&
        accuPIX &&
        accuPIX.length > 0
      ) {
        let tempPrice = accuPIX.filter(
          (it) => it.attribute_id == temp["accupix_id"]
        )[0];
        tempPrice = tempPrice ? tempPrice.price : undefined;

        totalItemPrice += tempPrice
          ? getPrice(tempPrice, temp.estimated_quantity, 1) *
            temp.estimated_quantity
          : 0;
        unit_price += parseFloat(
          tempPrice ? getPrice(tempPrice, temp.estimated_quantity, 1) : 0
        );
      }

      if (
        "Envelope" in temp &&
        temp["Envelope"] &&
        envelope &&
        envelope.length > 0
      ) {
        let tempPrice = envelope.filter(
          (it) => it.attribute_id == temp["Envelope"]
        )[0];
        tempPrice = tempPrice ? tempPrice.price : undefined;

        totalItemPrice += tempPrice
          ? getPrice(tempPrice, temp.estimated_quantity, 1) *
            temp.estimated_quantity
          : 0;
        unit_price += parseFloat(
          tempPrice ? getPrice(tempPrice, temp.estimated_quantity, 1) : 0
        );
      }

      if (
        "Postage" in temp &&
        temp["Postage"] &&
        postage &&
        postage.length > 0
      ) {
        let tempPrice = postage.filter(
          (it) => it.attribute_id == temp["Postage"]
        )[0];
        tempPrice = tempPrice ? tempPrice.price : undefined;

        totalItemPrice += tempPrice
          ? getPrice(tempPrice, temp.estimated_quantity, 1) *
            temp.estimated_quantity
          : 0;
        unit_price += parseFloat(
          tempPrice ? getPrice(tempPrice, temp.estimated_quantity, 1) : 0
        );
      }

      if ("InkColor" in temp && temp["InkColor"] && ink && ink.length > 0) {
        let tempPrice = ink.filter(
          (it) => it.attribute_id == temp["InkColor"]
        )[0];
        tempPrice = tempPrice ? tempPrice.price : undefined;

        totalItemPrice += tempPrice
          ? getPrice(tempPrice, temp.estimated_quantity, 1) *
            temp.estimated_quantity
          : 0;
        unit_price += parseFloat(
          tempPrice ? getPrice(tempPrice, temp.estimated_quantity, 1) : 0
        );
      }

      if (
        "PaperColor" in temp &&
        temp["PaperColor"] &&
        paper &&
        paper.length > 0
      ) {
        let tempPrice = paper.filter(
          (it) => it.attribute_id == temp["PaperColor"]
        )[0];
        tempPrice = tempPrice ? tempPrice.price : undefined;
        totalItemPrice += tempPrice
          ? getPrice(tempPrice, temp.estimated_quantity, 1) *
            temp.estimated_quantity
          : 0;
        unit_price += parseFloat(
          tempPrice ? getPrice(tempPrice, temp.estimated_quantity, 1) : 0
        );
      }

      temp["total_item_price"] = totalItemPrice;
      temp["unit_price"] = unit_price;
      temp["per"] = unit_price;
    }
    console.log("temp",temp)
    props.selectedProduct({...temp});
  };
  const edittedProductFlag = (editProduct) => {
    props.edittedProductFlag(editProduct);
  };
  const handleNext = () => {
    let tempItem = { ...productInfo };
    if (validateForm() != true) return false;
    if (props.updateProduct === true) {
      updatedProduct(tempItem);
    }
    
    selectedProduct(tempItem);

    if (
      productInfo &&
      "date_change" in productInfo &&
      productInfo.date_change &&
      "mailing_date_id" &&
      productInfo.mailing_date_id &&
      orderId
    ) {
      props
        .callRequest("POST", UPDATE_DESIRED_MAILING_DATE, true, {
          mailing_date_id: Number(productInfo.mailing_date_id),
          order_id: Number(orderId),
          date: moment(productInfo.mailing_date).format('MM-DD-YYYY'),
        })
        .then((res) => {
          console.log(res);
        });
    }

    props.chooseDesignDetails();

    //props.modalToggle();
  };

  const getTemplateResponse = (res) => {
    if (orderStatus && orderId && orderStatus != 2) {
      updateTemplateForOrder(res);
    } else if (res) {
      let prodInfo = { ...productInfo, template: res };
      setProductInfo({ ...prodInfo });
      fetchTemplate(product_details.prod_id);
    }
    setShowEditTemplate(false);
  };

  const fetchTemplate = (id) => {
    if (id && id != 0) {
      props
        .callRequest(
          "GET",
          API_GET_USER_TEMPLATE_PRODUCT +
            id +
            "/?user_id=" +
            props.selectedUserDetail.user_id,
          true
        )
        .then((res) => {
          setSingleProductInfo({
            ...singleProductInfo,
            template: res.data.template,
          });
        });
    }
  };

  const [productError, setProductError] = useState({
    estimated_quantity: false,
    mailing_date: false,
    template: false,
    Postage: false,
    Envelope: false,
    InkColor: false,
    PaperColor: false,
    accutext_id: false,
    accupix_id: false,
    textResponse1: false,
    responsetxt1: false,
  });

  const getPrice = (priceList, qty, tier) => {
    let pricearray = 0;
    try {
      if (qty >= 1 && qty < 200) {
        pricearray = 0;
      } else if (qty >= 200 && qty < 350) {
        pricearray = 1;
      } else if (qty >= 350 && qty < 500) {
        pricearray = 2;
      } else if (qty >= 500 && qty < 1000) {
        pricearray = 3;
      } else if (qty >= 1000 && qty < 2500) {
        pricearray = 4;
      } else if (qty >= 2500 && qty < 5000) {
        pricearray = 5;
      } else if (qty >= 5000 && qty < 10000) {
        pricearray = 6;
      } else if (qty >= 10000 && qty < 25000) {
        pricearray = 7;
      } else if (qty >= 25000 && qty < 50000) {
        pricearray = 8;
      } else if (qty >= 50000) {
        pricearray = 9;
      } else {
        pricearray = 0;
      }
      let priceData = priceList && priceList.split("|");
      let arraylen = priceData && priceData.length;
      if (pricearray < arraylen) {
        let price = priceData[pricearray];
        return price;
      } else {
        return 0;
      }
    } catch (err) {
      console.log(err);
    }
  };

  const selectMailingDate = (date) => {
    let prodInfo = { ...productInfo, mailing_date: date };
    setProductInfo({ ...prodInfo, date_change: true });
  };

  // const isWeekday = (date) => {
  //   const d = new Date(date);
  //   const day = d.getDay();
  //   return day !== 0 && day !== 6;
  // };

  const fetchHolidayList = () => {
    props.callRequest("GET", API_GET_HOLIDAY_LIST, true).then((res) => {
      setHolidayList(res.data);
    });
  };

  let generated_holiday_list;

  if (holidayList !== undefined) {
    generated_holiday_list = holidayList.map((item) => {
      return item.split("-");
    });
  }

  var holiday_list = generated_holiday_list;

  // var holiday_list = [
  //   [7, 4, 2022],
  //   [9, 5, 2022],
  //   [10, 10, 2022],
  //   [11, 11, 2022],
  //   [11, 24, 2022],
  //   [12, 26, 2022],
  //   [1, 2, 2023],
  //   [1, 16, 2023],
  //   [2, 20, 2023],
  //   [5, 29, 2023],
  //   [6, 19, 2023],
  //   [7, 4, 2023],
  //   [9, 4, 2023],
  //   [10, 9, 2023],
  //   [11, 10, 2023],
  //   [11, 23, 2023],
  //   [12, 25, 2023],
  // ];

  // A function to show holidays and disable weekend days in a datepicker
  function showHolidaysDisableWeekends(date) {
    var thisMonth = date.getMonth();
    var thisDate = date.getDate();
    var thisDay = date.getDay();
    var thisYear = date.getFullYear();
    var returnedArr = [true, ""]; // Normal day

    if (thisDay == 0 || thisDay == 6) {
      returnedArr = [false, "", "Cannot choose a weekend day"]; // Weekend day
    } else {
      for (let i = 0; i < holiday_list.length; i++) {
        if (
          thisMonth == holiday_list[i][0] - 1 &&
          thisDate == holiday_list[i][1] &&
          thisYear == holiday_list[i][2]
        ) {
          returnedArr = [false, "holiday"]; // Weekend holiday
        }
      }
    }
    return returnedArr[0];
  }

  useEffect(() => {
    let tempitemDetail = { ...productInfo };
    let accutext_id = null;
    let accupix_id = null;

    accTXT &&
      accTXT.map((item) => {
        if (
          tempitemDetail &&
          "attribute_default" in tempitemDetail &&
          tempitemDetail.attribute_default.indexOf(item.attribute_id) > -1
        ) {
          accutext_id = item.attribute_id;
        }
      });

    accuPIX &&
      accuPIX.map((item) => {
        if (
          tempitemDetail &&
          "attribute_default" in tempitemDetail &&
          tempitemDetail.attribute_default.indexOf(item.attribute_id) > -1
        ) {
          accupix_id = item.attribute_id;
        }
      });

    if (accutext_id != null) {
      tempitemDetail.accutext_id = accutext_id;
    }

    if (accupix_id != null) {
      tempitemDetail.accupix_id = accupix_id;
    }

    setProductInfo({ ...tempitemDetail });
  }, [productInfo && productInfo.attribute_default]);

  useEffect(() => {
    let tempPostage = props.postage;
    //console.log(tempPostage, "tempPostagetempPostagetempPostagetempPostage");
    let newPostage = [];
    let tempEstimatedQty =
      productInfo &&
      "estimated_quantity" in productInfo &&
      productInfo.estimated_quantity
        ? productInfo.estimated_quantity
        : 0;
    for (let i = 0; i < tempPostage.length; i++) {
      if (
        productInfo &&
        "attribute_default" in productInfo &&
        productInfo.attribute_default.indexOf(tempPostage[i].attribute_id) > -1
      ) {
        tempEstimatedQty >= tempPostage[i].min_qnty &&
          newPostage.push({
            ...tempPostage[i],
            key: tempPostage[i].attribute_id,
            text: tempPostage[i].attribute_name,
            value: tempPostage[i].attribute_id,
            description:
              "$" +
              Number(
                parseFloat(getPrice(tempPostage[i].price, tempEstimatedQty))
              ).toFixed(2),
            image: {
              avatar: true,
              src:
                process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
                tempPostage[i].thumb_image_path,
            },
          });
      }
    }
    if (newPostage && newPostage.length > 0 && !productInfo.Postage) {
      setProductInfo({ ...productInfo, Postage: newPostage[0]["value"] });
    }

    setPostage(newPostage);

    let postageLength = newPostage.filter(
      (post) => post.attribute_name === "Marketing Mail Presort Indicia"
    );
    if (
      newPostage &&
      newPostage.length > 0 &&
      props.SinglePrd &&
      props.SinglePrd.parent_category_id == 2
    ) {
      if (postageLength && postageLength.length > 0) {
        setProductInfo({
          ...productInfo,
          Postage:
            "Postage" in productInfo
              ? productInfo.Postage
              : parseInt(postageLength[0].attribute_id),
        });
      } else if (newPostage && newPostage.length > 0) {
        setProductInfo({ ...productInfo, Postage: newPostage[0]["value"] });
      }
    }
  }, [
    productInfo && productInfo.estimated_quantity,
    productInfo && productInfo.attribute_default,
  ]);

  const [minDate, setMinDate] = useState();
  useEffect(() => {
    setMinDate(calculateMin(productInfo.mailing_date_limit));
  }, [productInfo && productInfo.mailing_date_limit]);

  const calculateMin = (mailing_date_limit) => {
    let d = new Date();
    if (dateAdded && orderStatus != 2) {
      d = new Date(dateAdded * 1000);
    }

    var startDate = d.toISOString();
    startDate = new Date(startDate);
    var endDate = "",
      noOfDaysToAdd = mailing_date_limit > 0 ? mailing_date_limit : 5,
      count = 0;
    while (count < noOfDaysToAdd) {
      endDate = new Date(startDate.setDate(startDate.getDate() + 1));
      if (endDate.getDay() != 0 && endDate.getDay() != 6) {
        count++;
      }
    }

    return endDate;
  };

  const updateTemplateForOrder = (res) => {
    try {
      let ProductTemplate =
        orderDetails &&
        Object.keys(orderDetails).length > 0 &&
        "ProductTemplate" in orderDetails
          ? orderDetails.ProductTemplate
          : [];
      let template_id =
        res && "template_id" in res && res.template_id ? res.template_id : null;

      let temp = ProductTemplate.find(
        (k) => k.original_template == template_id
      );
      let order_template_id =
        temp && "order_template_id" in temp ? temp.order_template_id : null;

      if (order_template_id && orderId) {
        res.order_template_id = order_template_id;
        res.order_id = Number(orderId);
        props
          .callRequest("POST", API_UPDATE_ORDER_TEMPLATE, true, res)
          .then((response) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Order template has been updated successfully",
            });
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleAccutxtClose = () => {
    setEditAccutxt(false);
  };

  const handleAccutxtData = () => {
    setAccuTXTData(true);
  };

  const receiveAccutxtInfo = (accutxtInfo) => {
    //  textResponse1: accutxtInfo.textResponse1,
    //   responsetxt1: accutxtInfo.responsetxt1,
    //   textEmail1: accutxtInfo.textEmail1,
    //   textEmail2: accutxtInfo.textEmail2,
    //   textEmail3: accutxtInfo.textEmail3,
    setProductInfo({
      ...productInfo,
      ...accutxtInfo,
    });
    // setAccuInfo(accuInfo);
  };
  const returnEnvelopeImage = (envelope_id) => {
    const envImage =
      envelope &&
      envelope.filter((item) => {
        return item.attribute_id == envelope_id;
      });
    if (envImage && envImage.length>0) {
      let temp_image = envImage[0].thumb_image_path;
      if (temp_image) {
        return temp_image;
      } else {
        return require("../../assets/img/product-noimage.png").default;
      }
    } else {
      return null;
    }
  };
  return (
    <>
      {showEditTemplate == false && (
        <div className="add-product-details-popup">
          <div className="header-block">
            <h2 className="titel">{productInfo.product_name}</h2>
          </div>
          <Row style={{ height: "500px", overflowY: "auto" }}>
            <Col sm="7">
              <div className="product-left-details-block">
                <FormGroup>
                  <Label for="estimated_quantity">
                    Quantity
                    {/* <i className="far fa-question-circle small_text"></i> */}
                    <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="number"
                    name="estimated_quantity"
                    value={productInfo && productInfo.estimated_quantity}
                    id="estimated_quantity"
                    placeholder="Enter Estimated Quantity"
                    onChange={(e) => onChangeAction(e)}
                    disabled={!uiReady || orderStatus == 5}
                    onWheel={(event)=> { event.target.blur(); }}
                  />
                  {productError.estimated_quantity && (
                    <span className="text-danger">
                      Please enter valid quantity, minimum quantity{" "}
                      {productInfo.min_qnty}
                    </span>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="mailing_date">
                    Mailing Date <span className="text-danger">*</span>
                  </Label>
                  <DatePicker
                    dateFormat="MM-dd-yyyy"
                    selected={
                      productInfo && "mailing_date" in productInfo && 
                      productInfo.mailing_date!="" ? productInfo.mailing_date :""
                    }
                    name="mailing_date"
                    id="mailing_date"
                    onChange={(date) => selectMailingDate(date)}
                    // filterDate={isWeekday}
                    filterDate={showHolidaysDisableWeekends}
                    minDate={minDate && moment(minDate).toDate()}
                    autoComplete="off"
                    disabled={
                      !uiReady ||
                      (productInfo &&
                      "is_lockout" in productInfo &&
                      productInfo.is_lockout == 1
                        ? true
                        : false)
                    }
                  />

                  {/* <Input
                    type="date"
                    name="mailing_date"
                    id="mailing_date"
                    onChange={(e) => onChangeAction(e)}
                    value={productInfo && productInfo.mailing_date}
                  ></Input> */}
                  {productError.mailing_date && (
                    <span className="text-danger">
                      Please enter valid mailing date
                    </span>
                  )}
                </FormGroup>

                <FormGroup>
                  <Label for="template">
                    Template <span className="text-danger">*</span>
                  </Label>

                  <Input
                    type="select"
                    name="template"
                    value={productInfo && productInfo.template}
                    id="template"
                    onChange={(e) => onChangeAction(e)}
                    disabled={!uiReady || orderStatus == 5}
                  >
                    {singleProductInfo &&
                      singleProductInfo.template.map((temp, index) => {
                        return (
                          <option value={temp.template_id} key={index}>
                            {temp.template_name}
                          </option>
                        );
                      })}
                  </Input>
                  {productError.template && (
                    <span className="text-danger">
                      Please select a valid template
                    </span>
                  )}
                </FormGroup>

                {/* {categoryShowHide.accTXT == true && (
                  <FormGroup>
                    <Label for="postage_options">
                      AccTXT Options <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="accutext_id"
                      value={productInfo && productInfo.accutext_id}
                      id="accutext-options"
                      onChange={(e) => onChangeAction(e)}
                      disabled={!uiReady}
                    >
                      {accTXT &&
                        accTXT.map((attr, index) => {
                          return (
                            productInfo &&
                            "attribute_default" in productInfo &&
                            productInfo.attribute_default.indexOf(
                              attr.attribute_id
                            ) > -1 && (
                              <option value={attr.attribute_id} key={index}>
                                Yes
                              </option>
                            )
                          );
                        })}
                      <option value={0}>No</option>
                    </Input>
                    {productError.accutext_id && (
                      <span className="text-danger">
                        Please select a valid AccTXT
                      </span>
                    )}
                  </FormGroup>
                )} */}

                {/* {categoryShowHide.accuPIX == true && (
                  <FormGroup>
                    <Label for="postage_options">
                      AccuPIX Options <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="accupix_id"
                      value={productInfo && productInfo.accupix_id}
                      id="accupix-options"
                      onChange={(e) => onChangeAction(e)}
                      disabled={!uiReady}
                    >
                      {accTXT &&
                        accTXT.map((attr, index) => {
                          return (
                            productInfo &&
                            "attribute_default" in productInfo &&
                            productInfo.attribute_default.indexOf(
                              attr.attribute_id
                            ) > -1 && (
                              <option value={attr.attribute_id} key={index}>
                                Yes
                              </option>
                            )
                          );
                        })}

                      <option value={0}>No</option>
                    </Input>
                    {productError.accupix_id && (
                      <span className="text-danger">
                        Please select a valid AccuPIX
                      </span>
                    )}
                  </FormGroup>
                )} */}

                {categoryShowHide.postage == true && orderStatus != 5 && (
                  <FormGroup>
                    <Label for="postage_options">
                      Postage Options <span className="text-danger">*</span>
                    </Label>
                    {/* <Input
                      type="select"
                      name="Postage"
                      value={productInfo && productInfo.Postage}
                      id="postage-options"
                      onChange={(e) => onChangeAction(e)}
                      disabled={!uiReady}
                    >
                      {postage &&
                        postage.map((attr, index) => {
                          return (
                            productInfo &&
                            "attribute_default" in productInfo &&
                            productInfo.attribute_default.indexOf(
                              attr.attribute_id
                            ) > -1 && (
                              <option value={attr.attribute_id} key={index}>
                                {attr.attribute_name}
                              </option>
                            )
                          );
                        })}
                    </Input> */}

                    <Dropdown
                      fluid
                      selection
                      options={postage}
                      value={productInfo && productInfo.Postage}
                      onChange={(on, to) => {
                        setProductInfo({
                          ...productInfo,
                          Postage: to.value,
                        });
                      }}
                      disabled={
                        productInfo && productInfo.estimated_quantity
                          ? false
                          : true
                      }
                    />

                    {productError.Postage && (
                      <span className="text-danger">
                        Please select a valid Postage
                      </span>
                    )}
                  </FormGroup>
                )}

                {categoryShowHide.envelope == true && (
                  <FormGroup>
                    <Label for="envelope">
                      Envelope <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="Envelope"
                      value={productInfo && productInfo.Envelope}
                      id="envelope"
                      onChange={(e) => onChangeAction(e)}
                      disabled={!uiReady || orderStatus == 5}
                    >
                      {envelope &&
                        envelope.map((attr, index) => {
                          return (
                            productInfo &&
                            "attribute_default" in productInfo &&
                            productInfo.attribute_default.indexOf(
                              attr.attribute_id
                            ) > -1 && (
                              <option value={attr.attribute_id} key={index}>
                                {attr.attribute_name}
                              </option>
                            )
                          );
                        })}
                    </Input>
                    {productError.Envelope && (
                      <span className="text-danger">
                        Please select a valid Envelope
                      </span>
                    )}
                  </FormGroup>
                )}

                {/* {categoryShowHide.ink == true && (
                  <FormGroup>
                    <Label for="ink_type">
                      Ink Type <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="InkColor"
                      value={productInfo && productInfo["InkColor"]}
                      id="ink_type"
                      onChange={(e) => onChangeAction(e)}
                      disabled={!uiReady}
                    >
                      {ink &&
                        ink.map((attr, index) => {
                          return (
                            productInfo &&
                            "attribute_default" in productInfo &&
                            productInfo.attribute_default.indexOf(
                              attr.attribute_id
                            ) > -1 && (
                              <option value={attr.attribute_id} key={index}>
                                {attr.attribute_name}
                              </option>
                            )
                          );
                        })}
                    </Input>
                    {productError.InkColor && (
                      <span className="text-danger">
                        Please select a valid Ink
                      </span>
                    )}
                  </FormGroup>
                )} */}

                {/* {categoryShowHide.paper == true && (
                  <FormGroup>
                    <Label for="paper_type">
                      Paper Type <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="select"
                      name="PaperColor"
                      value={productInfo && productInfo["PaperColor"]}
                      id="paper_type"
                      onChange={(e) => onChangeAction(e)}
                      disabled={!uiReady}
                    >
                      {paper &&
                        paper.map((attr, index) => {
                          return (
                            productInfo &&
                            "attribute_default" in productInfo &&
                            productInfo.attribute_default.indexOf(
                              attr.attribute_id
                            ) > -1 && (
                              <option value={attr.attribute_id} key={index}>
                                {attr.attribute_name}
                              </option>
                            )
                          );
                        })}
                    </Input>
                    {productError.PaperColor && (
                      <span className="text-danger">
                        Please select a valid Paper
                      </span>
                    )}
                  </FormGroup>
                )} */}

                <div className="add-to-order-button_block">
                  <Button
                    color="danger"
                    onClick={handleNext}
                    disabled={!uiReady}
                  >
                    {orderDetails ? "Save Product" : "Add Product to Order"}{" "}
                    <i className="bi bi-cart-plus"></i>
                  </Button>
                  <Button onClick={() => props.chooseDesignDetails()}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Col>
            <Col sm="5">
              <Col sm="12">
                <div className="product-image">
                  <img
                    alt="reiprintmail"
                    src={
                      "FullSizeImagePath" in productInfo &&
                      productInfo.FullSizeImagePath
                        ? process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
                          productInfo.FullSizeImagePath
                        : require("../../assets/img/product-noimage.png")
                            .default
                    }
                  />
                </div>
              </Col>

              <Col sm="12" className="text-center" style={{ marginTop: "5px" }}>
                {productInfo && productInfo.template && (
                  <Button
                    type="button"
                    onClick={() => setShowEditTemplate(true)}
                  >
                    Edit Template
                  </Button>
                )}
              </Col>
              {}
              {categoryShowHide.accTXT ? (
                <div className="product-image text-center">
                  <button
                    onClick={() => setEditAccutxt(true)}
                    className="accutxt-button"
                    hidden={orderStatus == 5}
                  >
                    Choose AccuTXT
                  </button>
                </div>
              ) : (
                ""
              )}

              {categoryShowHide.envelope && (
                <div className="product-image text-center">
                  <img
                    style={{ width: "250px", margin: "7px" }}
                    alt="envelope"
                    src={
                      productInfo && productInfo.Envelope
                        ? process.env.REACT_APP_PUBLIC_URL_PHOTOPATH +
                          returnEnvelopeImage(
                            productInfo && productInfo.Envelope
                          )
                        : require("../../assets/img/product-noimage.png")
                            .default
                    }
                  />
                </div>
              )}
            </Col>
          </Row>
        </div>
      )}
      {showEditTemplate == true && (
        <>
          <EditTemplate
            template_id={productInfo.template}
            {...props}
            propsEdit={true}
            getTemplateResponse={getTemplateResponse}
            selectedUserDetail={props.selectedUserDetail}
            product_id={product_details.prod_id}
            orderStatus={orderStatus}
            paper_color={
              productInfo && "paper_color" in productInfo
                ? productInfo.paper_color
                : null
            }
            campaignName={campaignName}
          />
          <div className="row">
            <div className="col-sm-12 text-center" style={{ marginTop: "5px" }}>
              <Button
                variant="light"
                size="sm"
                onClick={() => setShowEditTemplate(false)}
              >
                Cancel Edit Template
              </Button>
            </div>
          </div>
        </>
      )}

      <Modal isOpen={editAccutxt} toggle={handleAccutxtClose}>
        <div className="accutxt-info-modal">
          <h2
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "23px",
            }}
          >
            <span style={{ color: "#e30613" }}>AccuTXT</span> Information
          </h2>
          <span style={{ cursor: "pointer" }}>
            <i onClick={handleAccutxtClose} className="fas fa-times-circle"></i>
          </span>
        </div>

        <AccutxtInformation
          {...props}
          viewAccutxt={0}
          handleAccutxtClose={handleAccutxtClose}
          handleAccutxtData={handleAccutxtData}
          sendAccutxtInfo={receiveAccutxtInfo}
          selectedProductForEdit={props.selectedProductForEdit}
          productInfo={productInfo}
        />
      </Modal>
    </>
  );
};

export default ProductDetail;
