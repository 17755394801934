import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";

import {
  API_GET_SECTION,
  API_GET_SECTION_BY_CMS,
  API_GET_CMS,
} from "../../config/Api";
import CmsForm from "./CmsForm";
import Swal from "sweetalert2";

const SectionLists = (props) => {
  const [section, setSection] = useState([]);
  const [cms, setCms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  //const [rows, setRows] = useState(10);
  //   const toggle = () => {
  //     setModal(!modal);
  //   };

  const searchParam = ["section_id", "section_name", "content", "pattern"];
  //console.log(props.match.params.id, "props.params.match.id");
  useEffect(() => {
    fetchCms();
    if (props.match.params.id != ":id") {
      fetchSectionByCms();
    } else {
      fetchSections();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const fetchSections = () => {
    props.callRequest("GET", API_GET_SECTION, true).then((res) => {
      setSection(res.data);
      setLoading(false);
    });
  };
  const fetchCms = () => {
    props.callRequest("GET", API_GET_CMS, true).then((res) => {
      setCms(res.data);
    });
  };
  const fetchSectionByCms = () => {
    props
      .callRequest(
        "GET",
        API_GET_SECTION_BY_CMS + props.match.params.id + "/",
        true
      )
      .then((res) => {
        setSection(res.data.cms_sections);
        setLoading(false);
      });
  };
  const handleEdit = (id) => {
    props.history.push("/edit-section/" + id + "/");
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure?",
      showDenyButton: true,
      confirmButtonText: "yes",
      denyButtonText: "no",
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("DELETE", API_GET_SECTION + id + "/", true)
          .then((res) => {
            fetchSections();
          })
          .catch((error) => {
            Swal.fire("something went wrong !!", "", "info");
            console.log(error);
          });
      }
    });
  };

  const columns = [
    {
      name: "Section Id",
      selector: "section_id",
      sortable: true,
    },
    {
      name: "Pattern Name",
      selector: "cms",
      sortable: true,
      // cell: (row) =>
      //   <div>{cms.filter(cms=> ( cms.cms_id == row.cms))[0].pattern</div>
      // ,

      cell: (row) =>
        cms.length != 0 ? (
          <div>
            {cms.map((cms) => {
              if (cms.cms_id == row.cms) {
                return cms.pattern;
              }
            })}
          </div>
        ) : (
          <div></div>
        ),
    },
    {
      name: "Section Name",
      selector: "section_name",
      sortable: true,
    },
    // {
    //   name: "Content",
    //   selector: "content",
    //   sortable: true,
    // },
    // {
    //   name: "Ordering",
    //   selector: "ordering",
    //   sortable: true,
    // },

    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            <DropdownItem onClick={() => handleEdit(row.section_id)}>
              Edit
            </DropdownItem>

            <DropdownItem onClick={() => handleDelete(row.section_id)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          autoComplete="off"
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = () => (
    <div className="right-button-block">
      <Button onClick={() => props.history.push("/add-section")}>
        <i className="ni ni-fat-add"></i> Add Section
      </Button>

      {/* <UncontrolledDropdown>
      <DropdownToggle caret>
        <i className="bi-filter"></i> Filter
      </DropdownToggle>
      <DropdownMenu>
       
      </DropdownMenu>
    </UncontrolledDropdown>
     <UncontrolledDropdown disabled={users.length > 0 ? false : true}>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExportView(e.target.value)}>
            Export View
          </DropdownItem>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export All
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
  */}
    </div>
  );

  const actionsExport = (
    <Export
    //     onExport={() =>
    //       DataTableSettings.downloadCSV(
    //         users,
    //         export_column,
    //         props.generateUniqueId()
    //       )
    //     }
    />
  );

  return (
    <main role="main" className="pt-3 px-4">
      <div className="main-heading-block">
        <BreadCrumb links={["Content Management", "Section"]} />
        <h1 className="header-title">
          <span className="header-icon">
            <i className="ni ni-circle-08"></i>
          </span>
          Section Listing
        </h1>
      </div>
      <div className="listingtable-main-block">
        <Card>
          <CardBody>
            <div className="list-filtered-table-block">
              {loading === true ? <h3>Loading...</h3> : null}
              {loading === false ? (
                <div className="list-filtered-table-block cms-data-table">
                  <DataTable
                    columns={columns}
                    data={DataTableSettings.filterItems(
                      section,
                      searchParam,
                      filterText
                    )}
                    pagination
                    paginationPerPage={DataTableSettings.paginationPerPage}
                    paginationRowsPerPageOptions={
                      DataTableSettings.paginationRowsPerPageOptions
                    }
                    actions={actionsExport}
                    //onChangeRowsPerPage={changeRowPerPage}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                </div>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </div>
    </main>
  );
};

export default withRouter(SectionLists);
