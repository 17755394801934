import React, { useState, useEffect } from "react";
import "./AddProduct.css";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

import { BreadCrumb } from "../../config/Components";
import { API_PAYMENT_SETTINGS } from "../../config/Api";

const PaymentProcessing = (props) => {
  const paymentProcessingInfo = {
    is_credit: "0",
    is_paypal: "0",
    is_local_tax: "0",
    payment_gateway_mode: "0",
    credit_card_processing_fee: parseFloat(0.0),
    pay_in_full_discount: parseFloat(0.0),
    paypal_processing_fee: parseFloat(0.0),
    order_cancellation_fee: parseFloat(0.0),
    local_tax: parseFloat(0.0),
    micro_campaign_subscription_fee: parseFloat(0.0),
    micro_campaign_subscription_tier: 0,
  };
  const [paymentProcessing, setPaymentProcessing] = useState(
    paymentProcessingInfo
  );
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchPaymentProcessing();
  }, []);

  const fetchPaymentProcessing = async () => {
    props.callRequest("GET", API_PAYMENT_SETTINGS, true).then((res) => {
      setPaymentProcessing(res.data);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentProcessing({ ...paymentProcessing, [name]: value });
    validateForm();
  };

  const validateForm = () => {
    let {
      credit_card_processing_fee,
      pay_in_full_discount,
      paypal_processing_fee,
      order_cancellation_fee,
      local_tax,
      micro_campaign_subscription_fee,
      micro_campaign_subscription_tier,
    } = paymentProcessing;

    let formIsValid = true;
    let errors = {};
    if (credit_card_processing_fee === "") {
      formIsValid = false;
      errors["err_credit_card_processing_fee"] = "This field is required.";
    }
    if (pay_in_full_discount === "") {
      formIsValid = false;
      errors["err_pay_in_full_discount"] = "This field is required.";
    }
    if (paypal_processing_fee === "") {
      formIsValid = false;
      errors["err_paypal_processing_fee"] = "This field is required.";
    }
    if (order_cancellation_fee === "") {
      formIsValid = false;
      errors["err_order_cancellation_fee"] = "This field is required.";
    }
    if (local_tax === "") {
      formIsValid = false;
      errors["err_local_tax"] = "This field is required.";
    }
    if (micro_campaign_subscription_fee === "") {
      formIsValid = false;
      errors["err_sub_fee"] = "This field is required.";
    }
    if (micro_campaign_subscription_tier === "") {
      formIsValid = false;
      errors["tier"] = "This field is required.";
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      const formData = new FormData();
      for (var key in paymentProcessing) {
        formData.append(key, paymentProcessing[key]);
      }
      props
        .callRequest("PUT", API_PAYMENT_SETTINGS, true, formData)
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Updated successfully",
            });
          }
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Something Went Wrong!",
          });
        });
    }
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Store Front", "Payment Processing"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-bag-17"></i>
            </span>
            Payment Processing
          </h1>
        </div>

        <div className="add-Product-block payment-processing-block">
          <Row>
            <Col sm="8" lg="9">
              <Card>
                <CardBody>
                  <Form>
                    <div className="payment-processing-form-box">
                      <div className="oneline-redio-block">
                        <FormGroup>
                          <Label
                            for="creditCardProcessingFee"
                            className="oneline-left-titel"
                          >
                            Credit Card Processing Fee
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="oneline-redio-list">
                            <CustomInput
                              type="radio"
                              id="is_credit"
                              name="is_credit"
                              value="1"
                              checked={paymentProcessing.is_credit === "1"}
                              onChange={handleChange}
                              label="Yes"
                            />
                            <CustomInput
                              type="radio"
                              id="is_not_credit"
                              name="is_credit"
                              value="0"
                              checked={paymentProcessing.is_credit === "0"}
                              onChange={handleChange}
                              label="No"
                            />
                          </div>
                        </FormGroup>
                      </div>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="creditCardProcessingFeeAmount">
                              Credit Card Processing Fee Amount:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              name="credit_card_processing_fee"
                              id="credit_card_processing_fee"
                              value={
                                paymentProcessing.credit_card_processing_fee
                              }
                              onChange={handleChange}
                            />
                            <label className="red_err">
                              {errors.err_credit_card_processing_fee}
                            </label>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="payInFullDiscount">
                              Pay in full discount:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              name="pay_in_full_discount"
                              id="pay_in_full_discount"
                              value={paymentProcessing.pay_in_full_discount}
                              onChange={handleChange}
                            />
                            <label className="red_err">
                              {errors.err_pay_in_full_discount}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="payment-processing-form-box">
                      <div className="oneline-redio-block">
                        <FormGroup>
                          <Label
                            for="paypalProcessingFee"
                            className="oneline-left-titel"
                          >
                            Paypal Processing Fee
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="oneline-redio-list">
                            <CustomInput
                              type="radio"
                              id="is_paypal"
                              name="is_paypal"
                              value="1"
                              checked={paymentProcessing.is_paypal === "1"}
                              onChange={handleChange}
                              label="Yes"
                            />
                            <CustomInput
                              type="radio"
                              id="is_not_paypal"
                              name="is_paypal"
                              value="0"
                              checked={paymentProcessing.is_paypal === "0"}
                              onChange={handleChange}
                              label="No"
                            />
                          </div>
                        </FormGroup>
                      </div>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="paypal_processing_fee">
                              Paypal Processing Fee Amount:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              name="paypal_processing_fee"
                              id="paypal_processing_fee"
                              value={paymentProcessing.paypal_processing_fee}
                              onChange={handleChange}
                            />
                            <label className="red_err">
                              {errors.err_paypal_processing_fee}
                            </label>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="order_cancellation_fee">
                              Order Cancellation Fee:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              name="order_cancellation_fee"
                              id="order_cancellation_fee"
                              value={paymentProcessing.order_cancellation_fee}
                              onChange={handleChange}
                            />
                            <label className="red_err">
                              {errors.err_order_cancellation_fee}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className="payment-processing-form-box">
                      <div className="oneline-redio-block">
                        <FormGroup>
                          <Label
                            for="payment_gateway_mode"
                            className="oneline-left-titel"
                          >
                            Payment Mode
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="oneline-redio-list">
                            <CustomInput
                              type="radio"
                              id="sandbox"
                              name="payment_gateway_mode"
                              label="Sandbox"
                              value="0"
                              checked={
                                paymentProcessing.payment_gateway_mode === "0"
                              }
                              onChange={handleChange}
                            />
                            <CustomInput
                              type="radio"
                              id="live"
                              name="payment_gateway_mode"
                              label="Live"
                              value="1"
                              checked={
                                paymentProcessing.payment_gateway_mode === "1"
                              }
                              onChange={handleChange}
                            />
                          </div>
                        </FormGroup>
                      </div>
                    </div>

                    <div className="payment-processing-form-box">
                      <div className="oneline-redio-block">
                        <FormGroup>
                          <Label
                            for="localStateTaxRates"
                            className="oneline-left-titel"
                          >
                            Local State Tax Rates
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="oneline-redio-list">
                            <CustomInput
                              type="radio"
                              id="is_local_tax"
                              name="is_local_tax"
                              value="1"
                              checked={paymentProcessing.is_local_tax === "1"}
                              onChange={handleChange}
                              label="Yes"
                            />
                            <CustomInput
                              type="radio"
                              id="is_no_local_tax"
                              name="is_local_tax"
                              value="0"
                              checked={paymentProcessing.is_local_tax === "0"}
                              onChange={handleChange}
                              label="No"
                            />
                          </div>
                        </FormGroup>
                      </div>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="localTaxRateAmount">
                              Local Tax Rate Amount:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              name="local_tax"
                              id="local_tax"
                              value={paymentProcessing.local_tax}
                              onChange={handleChange}
                            />
                            <label className="red_err">
                              {errors.err_local_tax}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="micro_campaign_subscription_fee">
                              Micro Campaign Subscription Fee:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              name="micro_campaign_subscription_fee"
                              id="micro_campaign_subscription_fee"
                              value={
                                paymentProcessing.micro_campaign_subscription_fee
                              }
                              onChange={handleChange}
                            />
                            <label className="red_err">
                              {errors.err_sub_fee}
                            </label>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="micro_campaign_subscription_tier">
                              Micro Campaign Subscription Tier:
                            </Label>
                            <select
                              id="micro_campaign_subscription_tier"
                              name="micro_campaign_subscription_tier"
                              value={
                                paymentProcessing.micro_campaign_subscription_tier
                              }
                              onChange={handleChange}
                              className="form-control"
                              aria-label="Default select example"
                            >
                              <label className="red_err">
                                {errors.err_sub_fee}
                              </label>
                              <option>Select Tier</option>
                              <option default value="0">
                                Tier1
                              </option>
                              <option value="1">Tier2</option>
                              <option value="2">Tier3</option>
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className="submit-button-block">
                      <Button onClick={handleSubmit}>
                        Save Changes <i className="ni ni-bold-right"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default PaymentProcessing;
