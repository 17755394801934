import React, { Component } from "react";
import axios from "axios";
import { refresh } from "../classes/auth";
import jwt_decode from "jwt-decode";

const Common = (WrappedComponent) => {
  class common extends Component {
    state = {};

    callRequest(method, url, auth, data = "") {
      try {
        if (auth) {
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${localStorage.getItem("token")}`;
        }

        return new Promise((resolve, reject) => {
          axios({
            method: method,
            url: url,
            data: data,
          })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              const status = error.response ? error.response.status : null;
              if (status === 403 && auth) {
                let response = refresh(error);
                response.then((res) => {
                  resolve(response);
                });
              } else {
                reject(error);
              }
            });
        });
      } catch (e) {
        console.log("Call Request Error:", e);
      }
    }

    getFileType = (file) => {
      let type;
      let source;
      let regexp =
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

      if (regexp.test(file)) {
        if (file.match(/\.(jpeg|jpg|gif|png)$/)) {
          type = "image";
          source = file;
        } else {
          type = "video";
          source = file;
        }
      } else {
        type = file.type.split("/")[0];
        source = URL.createObjectURL(file);
      }

      return type + "~" + source;
    };

    getFileExtension = (fileName) => {
      return fileName.substring(fileName.lastIndexOf(".") + 1);
    };

    truncateStringbyWord = (str, no_words) => {
      return str.split(" ").splice(0, no_words).join(" ");
    };

    isNumber = (evt) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
        return false;
      }
      return true;
    };

    validURL = (str) => {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator

      return !!pattern.test(str);
    };

    generateUniqueId = () => {
      return Date.now();
    };

    getUserInfo() {
      return jwt_decode(localStorage.getItem("token"));
    }

    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    getDateObj(timestamp) {
      let date = new Date(timestamp * 1000),
        datevalues = {
          year: date.getFullYear(),
          month: ("0" + (date.getMonth() + 1)).slice(-2),
          date: ("0" + date.getDate()).slice(-2),
          hrs: ("0" + date.getHours()).slice(-2),
          min: ("0" + date.getMinutes()).slice(-2),
          sec: ("0" + date.getSeconds()).slice(-2),
        };

      return datevalues;
    }

    groupAssocArr(arr, key, key1) {
      return arr.reduce(function (r, a) {
        r[a[key][key1]] = r[a[key][key1]] || [];
        r[a[key][key1]].push(a);
        return r;
      }, Object.create(null));
    }

    hexToRgba = (color) => {
      if (!color) return;
      if (color[0] === "#") {
        if (color.length < 7) {
          color =
            "#" +
            color[1] +
            color[1] +
            color[2] +
            color[2] +
            color[3] +
            color[3] +
            (color.length > 4 ? color[4] + color[4] : "");
        }
        return [
          parseInt(color.substr(1, 2), 16),
          parseInt(color.substr(3, 2), 16),
          parseInt(color.substr(5, 2), 16),
          color.length > 7 ? parseInt(color.substr(7, 2), 16) / 255 : 1,
        ];
      }
      if (color.indexOf("rgba") === -1) color += ",1";
      return color.match(/[\.\d]+/g).map(function (a) {
        return +a;
      });
    }

    rgbaToHex = (color) => {
      if (/^rgb/.test(color)) {
        const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
        let hex = `#${((1 << 24) + (parseInt(rgba[0], 10) << 16) + (parseInt(rgba[1], 10) << 8) + parseInt(rgba[2], 10))
          .toString(16)
          .slice(1)}`;
        return hex;
      }
      return color;
    };

    render() {
      return (
        <WrappedComponent
          getFileType={this.getFileType}
          truncateStringbyWord={this.truncateStringbyWord}
          getFileExtension={this.getFileExtension}
          isNumber={this.isNumber}
          validURL={this.validURL}
          callRequest={this.callRequest}
          generateUniqueId={this.generateUniqueId}
          getUserInfo={this.getUserInfo}
          validateEmail={this.validateEmail}
          getDateObj={this.getDateObj}
          groupAssocArr={this.groupAssocArr}
          hexToRgba={this.hexToRgba}
          rgbaToHex={this.rgbaToHex}
          {...this.props}
        />
      );
    }
  }

  return common;
};

export default Common;
