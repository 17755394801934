import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";
import { BreadCrumb } from "../../config/Components";
import {
  API_USER_GROUPS,
  API_GET_AFFILIATE,
  API_GET_SALES_REP,
} from "../../config/Api";
import { withRouter } from "react-router-dom";

const AffiliateForm = (props) => {
  const afflUserInfo = {
    id:
      props.match.params.hasOwnProperty("afflId") &&
      props.match.params.afflId !== 0
        ? props.match.params.afflId
        : "",
    name: "",
    firstname: "",
    lastname: "",
    username: "",
    password: "",
    reset_password: "",
    phone: "",
    url_key: "",
    email: "",
    affiliate_sales_rep: "",
    commission_rate: "",
    discount: "",
    group_id: 1,
    unbounce_url: "",
    show_default: "0",
    status: "1",
  };

  const [userGroups, setUserGroups] = useState([]);
  const [affiliates, setAffiliates] = useState(afflUserInfo);
  const [salesRep, setSalesRep] = useState([]);
  const [show_default, setShowDefault] = useState(0);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.match.params.hasOwnProperty("afflId")) {
      fetchAffiliatesById();
    }
    fetchUserGroups();
    fetchSalesRep();
  }, [props]);

  const fetchAffiliatesById = async () => {
    props
      .callRequest("GET", API_GET_AFFILIATE + affiliates.id, true)
      .then((res) => {
        setAffiliates(res.data);
      });
  };

  const fetchUserGroups = async () => {
    props.callRequest("GET", API_USER_GROUPS, true).then((res) => {
      setUserGroups(res.data);
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAffiliates({ ...affiliates, [name]: value });
    validateForm();
    if (e.target.name === "phone") {
      if (e.target.value.match(/[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi)) {
        e.target.value = e.target.value.replace(
          /[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi,
          ""
        );
      }
      setAffiliates({ ...affiliates, [name]: value });
    }
  };

  const formatUSNumber = (entry = "") => {
    let cleaned = ("" + entry).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const validateForm = () => {
    let { name, username, password, phone, email, url_key } = affiliates;

    let formIsValid = true;
    let errors = {};

    if (name === "") {
      formIsValid = false;
      errors["err_name"] = "Please provide name";
    }
    if (username === "") {
      formIsValid = false;
      errors["err_username"] = "Please provide username";
    }
    if (password === "") {
      formIsValid = false;
      errors["err_password"] = "Please provide password";
    }
    if (phone === "") {
      formIsValid = false;
      errors["err_phone"] = "Please provide valid phone number";
    }
    if (!props.validateEmail(email)) {
      formIsValid = false;
      errors["err_email"] = "Please enter valid email address";
    }
    if (url_key === "") {
      formIsValid = false;
      errors["err_url_key"] = "Please provide url key";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return false;
    }
    setLoading(true);

    let affiliates_name = affiliates.name.split(" "),
      affiliates_firstname = "",
      affiliates_lastname = "";
    affiliates_firstname = affiliates_name[0];
    if (affiliates_name.length === 2) {
      affiliates_lastname = affiliates_name[1];
    }
    if (affiliates_name.length === 3) {
      affiliates_lastname = affiliates_name[1] + " " + affiliates_name[2];
    }
    if (affiliates.id > 0) {
      let new_password =
        affiliates.reset_password === undefined ||
        affiliates.reset_password === ""
          ? affiliates.password
          : affiliates.reset_password;

      let put_data = {
        name: affiliates.name,
        firstname: affiliates_firstname,
        lastname: affiliates_lastname,
        username: affiliates.username,
        password: new_password,
        phone: affiliates.phone.replace(
          /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
          ""
        ),
        url_key: affiliates.url_key,
        email: affiliates.email,
        affiliate_sales_rep: affiliates.affiliate_sales_rep
          ? affiliates.affiliate_sales_rep
          : null,
        commission_rate: affiliates.commission_rate
          ? affiliates.commission_rate
          : "0",
        discount: affiliates.discount ? affiliates.discount : "0",
        group: affiliates.group_id,
        unbounce_url: affiliates.unbounce_url ? affiliates.unbounce_url : null,
        show_default: show_default,
        status: affiliates.status,
      };

      props
        .callRequest(
          "PUT",
          API_GET_AFFILIATE + affiliates.id + "/",
          true,
          put_data
        )
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Updated successfully",
            });
            setLoading(false);
            props.history.push("/affiliate-list");
          }
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: `${error.response.data.error}`,
          });
          setLoading(false);
        });
    } else {
      let post_data = {
        name: affiliates.name,
        firstname: affiliates_firstname,
        lastname: affiliates_lastname,
        username: affiliates.username,
        password: affiliates.password,
        phone: affiliates.phone.replace(
          /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
          ""
        ),
        url_key: affiliates.url_key,
        email: affiliates.email,
        affiliate_sales_rep: affiliates.affiliate_sales_rep
          ? affiliates.affiliate_sales_rep
          : null,
        commission_rate: affiliates.commission_rate
          ? affiliates.commission_rate
          : "0",
        discount: affiliates.discount ? affiliates.discount : "0",
        group: affiliates.group_id,
        unbounce_url: affiliates.unbounce_url ? affiliates.unbounce_url : null,
        show_default: show_default,
        status: affiliates.status,
      };

      props
        .callRequest("POST", API_GET_AFFILIATE, true, post_data)
        .then((res) => {
          if (res.status) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "success",
              title: "Saved successfully",
            });
            setLoading(false);
            props.history.push("/affiliate-list");
          }
        })
        .catch((error) => {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: `${error.response.data.error}`,
          });
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    props.history.push("/affiliate-list");
  };

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  const changeShowHandler = (e) => {
    let checkedVal = !show_default === true ? 1 : 0;
    setShowDefault(checkedVal);
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          {affiliates.id > 0 ? (
            <BreadCrumb links={["Affiliate List", "Edit Affiliate"]} />
          ) : (
            <BreadCrumb links={["Affiliate List", "Create Affiliate"]} />
          )}

          {affiliates.id > 0 ? (
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-circle-08"></i>
              </span>
              Edit Affiliate
            </h1>
          ) : (
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-circle-08"></i>
              </span>
              Add Affiliate
            </h1>
          )}
        </div>
        <div className="create-user-mainblock">
          <Row>
            <Col sm="8" lg="9">
              <Card>
                <CardBody>
                  <div className="create-user-formblock">
                    <Form>
                      <FormGroup>
                        <Label for="name">
                          Name: <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          autoComplete="off"
                          name="name"
                          id="name"
                          value={affiliates.name}
                          onChange={handleChange}
                          onKeyUp={handleChange}
                          placeholder="Enter Name"
                        />
                        <label className="red_err">{errors.err_name}</label>
                      </FormGroup>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="username">
                              Username: <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              autoComplete="off"
                              name="username"
                              id="username"
                              value={affiliates.username}
                              onChange={handleChange}
                              onKeyUp={handleChange}
                              placeholder="Enter Username"
                            />
                            <label className="red_err">
                              {errors.err_username}
                            </label>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            {affiliates.id > 0 ? (
                              <Label for="password">Password</Label>
                            ) : (
                              <Label for="password">
                                Password: <span className="text-danger">*</span>
                              </Label>
                            )}
                            {affiliates.id > 0 ? (
                              <Input
                                type="text"
                                autoComplete="off"
                                name="original_password"
                                id="original_password"
                                value={affiliates.password}
                                readOnly
                              />
                            ) : (
                              <Input
                                type="text"
                                autoComplete="off"
                                name="password"
                                id="password"
                                value={affiliates.password}
                                onChange={handleChange}
                                onKeyUp={handleChange}
                                placeholder="Enter Password"
                              />
                            )}
                            {affiliates.id > 0 ? (
                              <Label for="resetPassword">Reset Password:</Label>
                            ) : (
                              ""
                            )}
                            {affiliates.id > 0 ? (
                              <Input
                                type="text"
                                autoComplete="off"
                                name="reset_password"
                                id="reset_password"
                                value={affiliates.reset_password || ""}
                                onChange={handleChange}
                                placeholder="Enter Password"
                              />
                            ) : (
                              ""
                            )}
                            <label className="red_err">
                              {errors.err_password}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="email">
                              Email: <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="email"
                              autoComplete="off"
                              name="email"
                              id="email"
                              value={affiliates.email}
                              onChange={handleChange}
                              onKeyUp={handleChange}
                              placeholder="Enter Email"
                            />
                            <label className="red_err">
                              {errors.err_email}
                            </label>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="phone">
                              Phone: <span className="text-danger">*</span>
                            </Label>
                            <br />
                            <Input
                              type="text"
                              autoComplete="off"
                              name="phone"
                              id="phone"
                              value={
                                affiliates.phone &&
                                formatUSNumber(affiliates.phone)
                              }
                              onChange={handleChange}
                              onKeyUp={handleChange}
                              placeholder="Enter Phone"
                              maxlength={
                                affiliates.phone &&
                                affiliates.phone.replace(
                                  /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                                  ""
                                ).length === 10
                                  ? 10
                                  : 14
                              }
                            />
                            <label className="red_err">
                              {errors.err_phone}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={12}>
                          <FormGroup>
                            <Label for="unbounce_url">
                              Enter Unbounce URL: &nbsp;
                            </Label>
                            <br />
                            <Input
                              type="text"
                              autoComplete="off"
                              name="unbounce_url"
                              id="unbounce_url"
                              value={affiliates.unbounce_url}
                              onChange={handleChange}
                              placeholder="Enter Unbounce URL"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="group_id">User Group:</Label>
                            <select
                              id="group_id"
                              name="group_id"
                              value={affiliates.group_id}
                              onChange={handleChange}
                              className="form-control"
                              aria-label="Default select example"
                            >
                              <option value="">Select User Group</option>
                              {userGroups.map((userGroup) => (
                                <option
                                  key={userGroup.group_id}
                                  value={userGroup.group_id}
                                >
                                  {userGroup.group_name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="url_key">
                              Url Key: <span className="text-danger">*</span>
                            </Label>
                            <br />
                            <Input
                              type="text"
                              autoComplete="off"
                              name="url_key"
                              id="url_key"
                              value={affiliates.url_key}
                              onChange={handleChange}
                              onKeyUp={handleChange}
                              placeholder="Enter Url Key"
                            />
                            <label className="red_err">
                              {errors.err_url_key}
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="discount">
                              Affiliate Discount: &nbsp;
                            </Label>
                            <br />
                            <Input
                              type="text"
                              autoComplete="off"
                              name="discount"
                              id="discount"
                              value={affiliates.discount}
                              onChange={handleChange}
                              placeholder="0"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="affiliate_sales_rep">
                              Direct Marketing Coach:
                            </Label>
                            <select
                              id="affiliate_sales_rep"
                              name="affiliate_sales_rep"
                              value={affiliates.affiliate_sales_rep}
                              onChange={handleChange}
                              className="form-control"
                              aria-label="Default select example"
                            >
                              <option value="">Select Marketing Coach</option>

                              {salesRep.map((rep, index) => {
                                return (
                                  <option key={rep.user_id} value={rep.user_id}>
                                    {rep.name}
                                  </option>
                                );
                              })}
                            </select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label for="commission_rate">
                              Commission Rate: &nbsp;
                            </Label>
                            <br />
                            <Input
                              type="text"
                              autoComplete="off"
                              name="commission_rate"
                              id="commission_rate"
                              value={affiliates.commission_rate}
                              onChange={handleChange}
                              placeholder="0"
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6} style={{ display: "flex" }}>
                          <div
                            style={{
                              display: "flex",
                              textAlign: "center",
                              justifyItems: "center",
                              alignItems: "center",
                            }}
                          >
                            <Input
                              type="checkbox"
                              name="show_default"
                              id="show_default"
                              checked={show_default == 1 ? true : false}
                              onChange={changeShowHandler}
                            />
                            <Label for="show_default" check>
                              Show Default Products
                            </Label>
                          </div>
                        </Col>
                      </Row>
                      <div className="status-form-box">
                        <div className="oneline-redio-block">
                          <FormGroup>
                            <Label for="status" className="oneline-left-titel">
                              Status
                            </Label>
                            <div className="oneline-redio-list">
                              <CustomInput
                                type="radio"
                                id="status"
                                name="status"
                                value="1"
                                checked={
                                  affiliates.status === "1" ? true : false
                                }
                                onChange={handleChange}
                                label="Active"
                              />
                              <CustomInput
                                type="radio"
                                id="not_status"
                                name="status"
                                value="0"
                                checked={
                                  affiliates.status !== "1" ? true : false
                                }
                                onChange={handleChange}
                                label="Inactive"
                              />
                            </div>
                          </FormGroup>
                        </div>
                      </div>
                      <div className="submit-button-block">
                        <Button
                          className="cancel-button"
                          onClick={handleClose}
                          disabled={loading}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="create-User-button"
                          onClick={handleSubmit}
                          disabled={loading}
                        >
                          Save Changes
                        </Button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default withRouter(AffiliateForm);
