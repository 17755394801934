import React, { useState } from "react";

const EditorPadding = (props) => {
    const [padding_top, setPaddingTop] = useState(0);
    const [padding_right, setPaddingRight] = useState(0);
    const [padding_bottom, setPaddingBottom] = useState(0);
    const [padding_left, setPaddingLeft] = useState(0);

    function handleChangeTop(e) {
        setPaddingTop(e.target.value);
        props.parentCallbackPadding(e.target.value, padding_right, padding_bottom, padding_left);
    };

    function handleChangeRight(e) {
        setPaddingRight(e.target.value);
        props.parentCallbackPadding(padding_top, e.target.value, padding_bottom, padding_left);
    };

    function handleChangeBottom(e) {
        setPaddingBottom(e.target.value);
        props.parentCallbackPadding(padding_top, padding_right, e.target.value, padding_left);
    };

    function handleChangeLeft(e) {
        setPaddingLeft(e.target.value);
        props.parentCallbackPadding(padding_top, padding_right, padding_bottom, e.target.value);
    };

    return (
        <div className="inputbox-value padding-box left-ui-section">
            <div className="editor-margin-padding-header left-ui-header">
                <span>Padding</span>
            </div>
            <div className="inputbox padding-inputbox-top left-ui-body-section">
                <input
                    type="number"
                    name="padding_top"
                    id="padding_top"
                    className="form-control"
                    autoComplete="off"
                    value={props.padding_top ? props.padding_top : padding_top}
                    onChange={handleChangeTop}
                />
                <span>Top</span>
            </div>
            <div className="inputbox padding-inputbox-right">
                <input
                    type="number"
                    name="padding_right"
                    id="padding_right"
                    className="form-control"
                    autoComplete="off"
                    value={props.padding_right ? props.padding_right : padding_right}
                    onChange={handleChangeRight}
                />
                <span>Right</span>
            </div>
            <div className="inputbox padding-inputbox-bottom">
                <input
                    type="number"
                    name="padding_bottom"
                    id="padding_bottom"
                    className="form-control"
                    autoComplete="off"
                    value={props.padding_bottom ? props.padding_bottom : padding_bottom}
                    onChange={handleChangeBottom}
                />
                <span>Bottom</span>
            </div>
            <div className="inputbox padding-inputbox-left">
                <input
                    type="number"
                    name="padding_left"
                    id="padding_left"
                    className="form-control"
                    autoComplete="off"
                    value={props.padding_left ? props.padding_left : padding_left}
                    onChange={handleChangeLeft}
                />
                <span>Left</span>
            </div>
        </div>
    );
};

export default EditorPadding