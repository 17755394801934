import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Badge,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { BreadCrumb } from "../../config/Components";
import DataTableSettings from "../../utils/DataTableSettings";
import "../../assets/css/data-table.css";
import {
  API_GET_MAILINGLIST,
  API_MAILINGLIST_STATUS_UPDATE,
} from "../../config/Api";
import Swal from "sweetalert2";

const MailingList = (props) => {
  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterSelection, setfilterSelection] = useState({
    campaign_type: "",
    campaign_target: "",
  });
  const [rows, setRows] = useState(10);

  const handleNext = () => {
    props.history.push("/create-mailinglist");
    // props.history.push("/admin/create-mailinglist");
  };

  const searchParam = ["username", "mailinglist_name", "mailinglist_file_path"];

  useEffect(() => {
    getCampaignList();
  }, []);

  const columns = [
    {
      name: "User Name",
      selector: "username",
      sortable: true,
      filterable: true,
    },
    {
      name: "Mailinglist Name",
      selector: "mailinglist_name",
      sortable: true,
      filterable: true,
    },

    {
      name: "CSV file",
      selector: "mailinglist_file_path",
      sortable: false,
      cell: (item) => {
        return item && item.mailinglist_file_path;
      },
    },
    {
      name: "Status",
      selector: "status",
      sortable: false,
      cell: (item) => {
        return item.is_active == 0 ? (
          <Badge color="danger">Suspend</Badge>
        ) : (
          <Badge color="success">Continue</Badge>
        );
      },
    },

    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (item) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            {/* <DropdownItem>Duplicate Mailinglist</DropdownItem> */}
            {/* <DropdownItem>Suspend Campaign</DropdownItem>
            <DropdownItem>Continue Campaign</DropdownItem> */}
            {item.is_active == 0 ? (
              <DropdownItem
                onClick={() => changeStatus(item.mailinglist_id, 1)}
              >
                Continue MailingList
              </DropdownItem>
            ) : (
              <DropdownItem
                onClick={() => changeStatus(item.mailinglist_id, 0)}
              >
                Suspend MailingList
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const redirectUploadCsv = (id) => {
    props.history.push("/create-campaign?campaign_id=" + id);
    // props.history.push("/admin/create-campaign?campaign_id=" + id);
  };

  const changeStatus = (mailinglist_id, status) => {
    if (mailinglist_id) {
      const formData = new FormData();
      formData.append("mailinglist_id", mailinglist_id);
      formData.append("is_active", status);

      Swal.fire({
        title: "Are you sure?",
        text:
          "You want to " + (status == 0 ? "Suspend" : "Continue") + "  it ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, confirm!",
      }).then((result) => {
        if (result.isConfirmed) {
          props
            .callRequest(
              "PUT",
              API_MAILINGLIST_STATUS_UPDATE + mailinglist_id + "/",
              true,
              formData
            )
            .then((res) => {
              Swal.fire({
                position: "top-end",
                toast: true,
                timerProgressBar: true,
                title: "Status has been update successfully",
                showConfirmButton: false,
                timer: 3000,
              });
              getCampaignList();
            });
        }
      });
    }
  };

  const export_column = [
    "username",
    "mailinglist_name",
    "mailinglist_file_path",
  ];

  const campaignFilterSlection = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setfilterSelection({ ...filterSelection, [name]: value });
  };

  useEffect(() => {
    getCampaignList();
  }, [filterSelection]);

  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Product"
          value={filterText}
          autoComplete="off"
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      <Button color="primary" onClick={handleNext}>
        <i className="ni ni-fat-add"></i> Add Mailinglist
      </Button>
      {/* <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>Filter Name 1</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}
      <UncontrolledDropdown disabled={products.length > 0 ? false : true}>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExportView(e.target.value)}>
            Export View
          </DropdownItem>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export All
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {/* <Button
        disabled={products.length > 0 ? false : true}
        onClick={(e) => onExport(e.target.value)}
      >
        <i className="ni ni-ungroup"></i> Export
      </Button> */}
    </div>
  );

  const actionsExport = (
    <Export
      onExport={() =>
        DataTableSettings.downloadCSV(
          products,
          export_column,
          props.generateUniqueId()
        )
      }
      onExportView={() =>
        DataTableSettings.downloadCSV(
          products.slice(0, rows),
          export_column,
          props.generateUniqueId()
        )
      }
    />
  );

  const getCampaignList = () => {
    let APIEndPoint = API_GET_MAILINGLIST + "?page_number=1&page_size=10";
    props.callRequest("GET", APIEndPoint, true, null).then((res) => {
      let items = [];
      res.data.map((item) => {
        ((item.status == 2 && item.mailinglist_file_path != "") ||
          item.mailinglist_file_path == "") &&
          items.push({
            ...item,
            username: item && item.user && item.user.username,
          });
      });
      // console.log(items);
      setProducts(items);
    });
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["MailingList"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-money-coins"></i>
            </span>
            Mailinglist
          </h1>
        </div>

        <div className="listingtable-main-block">
          {console.log(products)}
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                <DataTable
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    products,
                    searchParam,
                    filterText
                  )}
                  pagination
                  onChangeRowsPerPage={changeRowPerPage}
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  actions={actionsExport}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default MailingList;
