import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const OrderSearch = ({ ...props }) => {
  const param = {
    username: "",
    orderid: "",
    campaign_name: "",
    campaign_type: "",
    order_status: "",
    start_date: "",
    end_date: "",
  };
  const [searchParam, setSearchParam] = useState(param);

  useEffect(() => {
    props.orderSearchParam && setSearchParam(props.orderSearchParam);
  }, [props.orderSearchParam]);

  return (
    <>
      <div
        id="row-0"
        role="row"
        className="sc-jrQzAO OTlLy rdt_TableRowc search_tablerow"
      >
        <div
          id="cell-zTt6Avwrg-undefined"
          role="gridcell"
          data-tag="allowRowEvents"
          className="col-sm-3"
          style={{ marginBottom: "5px" }}
        >
          {/* <label>User Name:</label> */}
          <Input
            id="username"
            name="username"
            placeholder="Enter User Name"
            type="text"
            autoComplete="off"
            className="form-control"
            value={searchParam.username}
            onChange={(e) => {
              setSearchParam({ ...searchParam, username: e.target.value });
            }}
          />
        </div>
        <div
          id="cell-zTt6Avwrg-undefined"
          role="gridcell"
          data-tag="allowRowEvents"
          className="col-sm-3"
          style={{ marginBottom: "5px" }}
        >
          {/* <label>Order ID:</label> */}
          <Input
            id="orderid"
            name="orderid"
            placeholder="Enter Order ID"
            type="text"
            autoComplete="off"
            className="form-control"
            value={searchParam.orderid}
            onChange={(e) => {
              setSearchParam({ ...searchParam, orderid: e.target.value });
            }}
          />
        </div>
        <div
          id="cell-zTt6Avwrg-undefined"
          role="gridcell"
          data-tag="allowRowEvents"
          className="col-sm-3"
          style={{ marginBottom: "5px" }}
        >
          {/* <label>Campaign Name:</label> */}
          <Input
            id="campaign_name"
            name="campaign_name"
            placeholder="Enter Campaign Name"
            type="text"
            autoComplete="off"
            className="form-control"
            value={searchParam.campaign_name}
            onChange={(e) => {
              setSearchParam({ ...searchParam, campaign_name: e.target.value });
            }}
          />
        </div>
        <div className="col-sm-3" style={{ marginBottom: "5px" }}>
          {/* <label>Campaign Type:</label> */}
          <Input
            type="select"
            name="campaign_type"
            id="campaign_type"
            onChange={(e) => {
              setSearchParam({ ...searchParam, campaign_type: e.target.value });
            }}
            value={searchParam && searchParam.campaign_type}
          >
            <option value="">Select Campaign Type</option>
            <option value="0">Direct Mail</option>
            <option value="1">Probate</option>
            <option value="2">Micro-Campaigns</option>
          </Input>
        </div>
        {props.draftSearch === 0 ? null : (
          <div
            id="cell-N-rKjtRaPW-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="col-sm-3"
            style={{ marginBottom: "5px" }}
          >
            <Input
              type="select"
              name="order_status"
              id="order_status"
              onChange={(e) => {
                setSearchParam({
                  ...searchParam,
                  order_status: e.target.value,
                });
              }}
              value={searchParam && searchParam.order_status}
            >
              <option value="">Select Status</option>
              <option value="0">Inactive</option>
              <option value="1">Active</option>
              <option value="3">Order Pending Payment</option>
              <option value="4">Order In Production</option>
              <option value="5">Order Created</option>
              <option value="6">Order Complete</option>
              <option value="7">Cancelled order</option>
              <option value="8">Batch Preview</option>
              <option value="9">Awaiting Mailing List</option>
              <option value="10">Rei cancel order</option>
            </Input>
          </div>
        )}
        <div
          id="cell-NlvjReDqTn-undefined"
          role="gridcell"
          data-tag="allowRowEvents"
          className="col-sm-3"
          style={{ marginBottom: "5px" }}
        >
          {/* <label>Start Date:</label> */}
          <DatePicker
            dateFormat="MM/dd/yyyy"
            placeholderText="Start Date"
            autoComplete="off"
            isClearable
            selected={searchParam.start_date}
            name="start_date"
            id="start_date"
            onChange={(date) =>
              setSearchParam({ ...searchParam, start_date: date })
            }
          />
        </div>
        <div
          id="cell-QhIJDofkqq-undefined"
          role="gridcell"
          data-tag="allowRowEvents"
          className="col-sm-3"
          style={{ marginBottom: "5px" }}
        >
          {/* <label>End Date:</label> */}
          <DatePicker
            dateFormat="MM/dd/yyyy"
            placeholderText="End Date"
            autoComplete="off"
            isClearable
            selected={searchParam.end_date}
            name="end_date"
            id="end_date"
            onChange={(date) =>
              setSearchParam({ ...searchParam, end_date: date })
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            //marginTop: "0px",
            marginLeft: "12px",
            marginBottom: "20px",
          }}
        >
          <div
            id="cell-rbCMEtiO9Wc-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
          >
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.setOrderSearchParam(searchParam);
              }}
            >
              Search
            </button>
          </div>

          <div
            id="cell-rbCMEtiO9Wc-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
          >
            <button
              className="btn btn-sm btn-primary"
              onClick={() => {
                props.setOrderSearchParam(param);
                setSearchParam(param);
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSearch;
