import React, { useState, useEffect } from "react";
import { API_HTML_TO_IMAGE } from "../../config/Api";
import "./assets/css/TemplateDesign.css";
import { Modal, Button, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { BiReset } from "react-icons/bi";

import LeftTextFields from "./LeftTextFields";
import TextComponent from "./TextComponent";
import TextComponentLeft from "./TextComponentLeft";

import LeftImageFields from "./LeftImageFields";
import ImageComponent from "./ImageComponent";
import ImageComponentLeft from "./ImageComponentLeft";

import TemplateColorPicker from "./TemplateColorPicker";
import ColorPicker from "./ColorPicker";

import RoundedCorner from "./RoundedCorner";

import LeftNonEditableTextFields from "./LeftNonEditableTextFields";
import NonEditableTextComponentLeft from "./NonEditableTextComponentLeft";
import NonEditableTextComponent from "./NonEditableTextComponent";

import LeftNonEditablePostageFields from "./LeftNonEditablePostageFields";
import NonEditablePostageComponentLeft from "./NonEditablePostageComponentLeft";
import NonEditablePostageComponent from "./NonEditablePostageComponent";

import LeftNonEditableAccupixFields from "./LeftNonEditableAccupixFields";
import NonEditableAccupixComponentLeft from "./NonEditableAccupixComponentLeft";
import NonEditableAccupixComponent from "./NonEditableAccupixComponent";
import NonEditableAccupixStreetviewComponent from "./NonEditableAccupixStreetviewComponent";

import LeftLogoUploaderFields from "./LeftLogoUploaderFields";
import LogoUploaderComponentLeft from "./LogoUploaderComponentLeft";
import LogoUploaderComponent from "./LogoUploaderComponent";

import LeftDataMatrixFields from "./LeftDataMatrixFields";
import DataSequenceComponentLeft from "./DataSequenceComponentLeft";
import DataSequenceComponent from "./DataSequenceComponent";

import DataMatrixComponentLeft from "./DataMatrixComponentLeft";
import DataMatrixComponent from "./DataMatrixComponent";

import LeftImbarcodeFields from "./LeftImbarcodeFields";
import ImbarcodeComponentLeft from "./ImbarcodeComponentLeft";
import ImbarcodeComponent from "./ImbarcodeComponent";

import EditorPadding from "./EditorPadding";

import accupix_img from "../../assets/img/accupix.png";
import accupix_streetview_img from "../../assets/img/streetview.png";

import RulerHorizontal from "./RulerHorizontal";
import RulerVertical from "./RulerVertical";
import RulerComponent from "./RulerComponent";

import MenuItem from "./MenuItem";

import BackGroundImage from "./BackGroundImage";

import TextFontSize from "./TextFontSize";

import LeftLineShapeFields from "./LeftLineShapeFields";
import LineShapeLeft from "./LineShapeLeft";
import LineShapeComponent from "./LineShapeComponent";

let style_size_designer = {};
let style_size_designer_screen_box = {};
let bleed_style = {};
let editor_style = {};
let editor_screen_inside = {};
let customs_text_arr = [];
let non_editable_text_arr = [];

const DesignModal = (props) => {
  customs_text_arr = props.customs_text_data.map((obj) => {
    obj.font_min_size =
      "font_min_size" in obj && obj.font_min_size ? obj.font_min_size : 7;
    obj.font_max_size =
      "font_max_size" in obj && obj.font_max_size ? obj.font_max_size : 0;
    obj.scalling_class =
      "scalling_class" in obj && obj.scalling_class ? obj.scalling_class : "";
    obj.transparency =
      "transparency" in obj && obj.transparency ? obj.transparency : false;
    obj.text_autofit =
      "text_autofit" in obj && obj.text_autofit ? obj.text_autofit : false;
    return obj;
  });

  non_editable_text_arr = props.non_editable_text.map((obj) => {
    obj.font_min_size =
      "font_min_size" in obj && obj.font_min_size ? obj.font_min_size : 7;
    obj.font_max_size =
      "font_max_size" in obj && obj.font_max_size ? obj.font_max_size : 0;
    obj.scalling_class =
      "scalling_class" in obj && obj.scalling_class ? obj.scalling_class : "";
    obj.transparency =
      "transparency" in obj && obj.transparency ? obj.transparency : false;
    obj.text_autofit =
      "text_autofit" in obj && obj.text_autofit ? obj.text_autofit : false;
    return obj;
  });

  const [show, setShow] = useState(false);
  const [isModalLoaded, setModalState] = useState(false);
  const [accupixImageFlag, setAccupixImageFlag] = useState("satellite");
  const [zoomXY, setZoomXY] = useState(parseFloat("1"));
  const [propsEdit, setPropsEdit] = useState(
    "propsEdit" in props ? props.propsEdit : false
  );
  const [customs, updateSate] = useState(customs_text_arr);
  const [nonEditableTexts, setNonEditableText] = useState(
    non_editable_text_arr
  );
  const [customImages, updateImageState] = useState(props.image_customs_data);
  const [nonEditablePostages, setNonEditablePostage] = useState(
    props.non_editable_postage
  );
  const [nonEditableAccupixs, setNonEditableAccupix] = useState(
    props.non_editable_accupix
  );
  const [nonEditableAccupixStreetview, setNonEditableAccupixStreetview] =
    useState(props.non_editable_accupix_streetview);
  const [template_background_color, setTemplateBackgroundColor] = useState(
    props.template_background_color
  );
  const [logo_uploader, setLogoUploader] = useState(
    props.logo_uploader_component
  );
  const [data_sequence_component, setDataSequence] = useState(
    props.data_sequence_component
  );
  const [data_matrix_component, setDataMatrix] = useState(
    props.data_matrix_component
  );
  const [imbarcode_component, setImbarcode] = useState(
    props.imbarcode_component
  );
  const [textType, setTextType] = useState("");
  const [aspectRatioFlag, setAspectRatioFlag] = useState(false);
  const [ruler, setRuler] = useState(props.ruler);
  const [min_max, setMinMax] = useState(props.min_max);
  const [loadEditor, setLoadEditor] = useState(false);
  const [is_front_img_transparent, setFrontImgTransparent] = useState(
    props.is_front_img_transparent
  );
  const [is_back_img_transparent, setBackImgTransparent] = useState(
    props.back_img_transparent !== undefined ? props.back_img_transparent : "1"
  );
  const [line_shape, setLineShape] = useState(props.line_shape);

  const [menu_item, setMenuItem] = useState({
    text_menu: false,
    image_menu: false,
    background_menu: false,
    component_menu: false,
    ruler_menu: false,
    shape_menu: false,
    back_menu: false,
  });

  const handleZoomIn = () => {
    setZoomXY(zoomXY + 0.1);
  };

  const handleZoomOut = () => {
    setZoomXY(zoomXY - 0.1);
  };

  const handleReset = () => {
    setZoomXY(1);
  };

  const handleClose = () => {
    if (isModalLoaded === true) {
      setShow(false);
      setModalState(false);
      setLoadEditor(false);
      if (typeof props.template_id !== "undefined") {
        props.ParentOnClose();
      }
    }
  };

  const handleShow = () => {
    setShow(true);
    setTimeout(
      function () {
        setModalState(true);
      }.bind(),
      5000
    );

    setTimeout(() => {
      setLoadEditor(true);
    }, 3000);
  };

  let new_id;
  let tem_customs;
  let bleed_int =
    props.myProps.is_bleed === "1"
      ? 0
      : parseFloat(props.myProps.bleed.split("in")[0]) * 2;
  let width_int = parseFloat(props.myProps.width.split("in")[0]) + bleed_int;
  let height_int = parseFloat(props.myProps.height.split("in")[0]) + bleed_int;

  let width_matrix = props.myProps.width.split("in")[0];
  let height_matrix = props.myProps.height.split("in")[0];

  style_size_designer = {
    width: width_int + "in",
    height: height_int + "in",
    position: "relative",
    backgroundColor: `${template_background_color}`,
    top: props.myProps.is_bleed === "1" ? 0 : "-" + props.myProps.bleed,
    left: props.myProps.is_bleed === "1" ? 0 : "-" + props.myProps.bleed,
    maxWidth: width_int + "in",
  };

  style_size_designer_screen_box = {
    width: props.myProps.width,
    height: props.myProps.height,
    position: "relative",
    backgroundColor: "#FFFFFF",
  };

  bleed_style = {
    padding: props.myProps.is_bleed === "1" ? 0 : props.myProps.bleed,
    margin: "0",
    border: "2px dotted #ffec03",
    outline: "none",
    textDecoration: "none",
    backgroundColor: "rgba(175, 175, 175, 0)",
    overflow: "hidden",
  };

  editor_style = {
    display: "flex",
    padding: "0",
    margin: "0",
    border: "none",
    outline: "none",
    textDecoration: "none",
    //display: "flex",
  };

  editor_screen_inside = {
    top: "0",
    borderStyle: "solid",
    //borderColor: "rgba(255, 0, 0, 0.35)",
    borderWidth: props.myProps.is_bleed === "1" ? 0 : props.myProps.margin,
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: "1",
    boxSizing: "border-box",
    //overflow: "hidden",
    borderColor: "transparent",
  };

  const handleImageChange = () => {
    if (
      (props.panelType === "single" &&
        props.imageType === "front" &&
        props.myProps.font_background === null) ||
      (props.panelType === "both" &&
        ((props.imageType === "front" &&
          props.myProps.font_background === null &&
          props.myProps.back_background === null) ||
          (props.imageType === "back" &&
            props.myProps.font_background === null &&
            props.myProps.back_background === null) ||
          (props.imageType === "front" &&
            props.myProps.font_background === null) ||
          (props.imageType === "back" &&
            props.myProps.back_background === null)))
    ) {
      if (props.imageType === "front") {
        return (
          <div
            style={style_size_designer}
            id="front_template_background_default"
          >
            <img alt="" id="front_img" src="" style={{ display: "none" }}></img>
          </div>
        );
      }
      if (props.imageType === "back") {
        return (
          <div
            style={style_size_designer}
            id="back_template_background_default"
          >
            <img alt="" id="back_img" src="" style={{ display: "none" }}></img>
          </div>
        );
      }
    } else {
      if (props.imageType === "front") {
        if (props.myProps.font_background) {
          customImages[0].img_src = props.myProps.font_background;
        }
        return props.myProps.font_background ? (
          <img
            alt=""
            id="front_img"
            src={props.myProps.font_background}
            style={style_size_designer}
          ></img>
        ) : (
          <div
            style={style_size_designer}
            id="front_template_background_default"
          >
            <img alt="" id="front_img" src="" style={{ display: "none" }}></img>
          </div>
        );
      } else if (props.imageType === "back") {
        if (props.myProps.back_background) {
          customImages[0].img_src = props.myProps.back_background;
        }
        return props.myProps.back_background ? (
          <img
            alt=""
            id="back_img"
            src={props.myProps.back_background}
            style={style_size_designer}
          ></img>
        ) : (
          <div
            style={style_size_designer}
            id="back_template_background_default"
          >
            <img alt="" id="back_img" src="" style={{ display: "none" }}></img>
          </div>
        );
      } else {
        if (props.myProps.font_background) {
          customImages[0].img_src = props.myProps.font_background;
        }
        return props.myProps.font_background ? (
          <img
            alt=""
            id="front_img"
            src={props.myProps.font_background}
            style={style_size_designer}
          ></img>
        ) : (
          <div
            style={style_size_designer}
            id="front_template_background_default"
          >
            <img alt="" id="front_img" src="" style={{ display: "none" }}></img>
          </div>
        );
      }
    }
  };

  useEffect(() => {
    if (
      props.imageType === "front" &&
      props.image_customs_data[0].img_src !== "" &&
      props.image_customs_data[0].img_src !== null
    ) {
      setTemplateBackgroundColor("#FFFFFF");
      setFrontImgTransparent("0");
    }
    if (
      props.imageType === "back" &&
      props.image_customs_data[0].img_src !== "" &&
      props.image_customs_data[0].img_src !== null
    ) {
      setTemplateBackgroundColor("#FFFFFF");
      setBackImgTransparent("0");
    }
  }, [
    props.imageType,
    props.image_customs_data,
    props.is_front_img_transparent,
    props.is_back_img_transparent,
  ]);

  useEffect(() => {
    if (
      props.imageType === "front" &&
      (props.template_background_color === "#FFFFFF" ||
        props.template_background_color === "#ffffff")
    ) {
      setFrontImgTransparent("1");
      props.handleImageTransparency(props.imageType, "1");
    }
    if (
      props.imageType === "back" &&
      (props.template_background_color === "#FFFFFF" ||
        props.template_background_color === "#ffffff")
    ) {
      setBackImgTransparent("1");
      props.handleImageTransparency(props.imageType, "1");
    }
  }, [props.template_background_color]);

  const handleBackGroundColor = (color, type) => {
    let template_background_color;
    template_background_color = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
      })`;
    if (type === "front") {
      if (
        template_background_color === "#FFFFFF" ||
        color.a === 0 ||
        (color.r === 255 && color.g === 255 && color.b === 255)
      ) {
        setFrontImgTransparent("1");
      } else {
        setFrontImgTransparent("0");
      }
    } else {
      if (
        template_background_color === "#FFFFFF" ||
        color.a === 0 ||
        (color.r === 255 && color.g === 255 && color.b === 255)
      ) {
        setBackImgTransparent("1");
      } else {
        setBackImgTransparent("0");
      }
    }

    setTemplateBackgroundColor(template_background_color);
    props.parentSetBackgroundColor(props.imageType, template_background_color);
  };

  const handleBackGroundColorSection = (default_color) => {
    setTemplateBackgroundColor(default_color);
    props.parentSetBackgroundColor(props.imageType, default_color);
  };

  const handleTransparency = (type, is_transparent) => {
    if (type === "front") {
      setFrontImgTransparent(is_transparent);
      props.parentSetBackgroundColor(type, is_transparent);
    } else {
      setBackImgTransparent(is_transparent);
      props.parentSetBackgroundColor(type, is_transparent);
    }
  };

  function addCustom(data) {
    if (customs.length === 0) {
      new_id = 0;
    } else {
      const custom = customs.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = custom.id + 1;
    }

    let newelement = {
      id: new_id,
      title: "Custom text",
      top: 100,
      left: 100,
      width: 100,
      height: 100,
      rotateAngle: 0,
      selected_text_element: 0,
      backgroundColor: "",
      rounded_corner: 0,
      double_click: 0,
      margin_top: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
      custom_text_lock: false,
      font_min_size: 7,
      font_max_size: 0,
      scalling_class: "",
      transparency: false,
      text_autofit: false,
    };
    // image z index adjustment start
    for (let i = 0; i < customImages.length; i++) {
      if (customImages[i].order === 21) {
        let img_src = customImages[i].img_src;
        let id = customImages[i].id;
        let img_box_title = customImages[i].img_box_title;
        let order = customImages.length - 1;

        props.parentUpdateImageCustomsDataElementImg(
          img_src,
          id,
          props.imageType,
          img_box_title,
          order
        );
      }
    }
    adjustImageZindex();
    // image z index adjustment end

    updateSate(customs.concat(newelement));
    props.parentaddCustom(props.imageType);
  }

  function setCustomDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state,
    font_min_size,
    font_max_size,
    scalling_class,
    transparency,
    text_autofit,
  ) {
    let selected_text_element = 0;
    if (resize === true) {
      selected_text_element = 1;
      setTextType("custom_text");
    }
    let customs_text_data = customs.filter((c) => c.id !== id);
    let temp_element = {};
    temp_element.id = id;
    temp_element.title = title;
    temp_element.top = top;
    temp_element.left = left;
    temp_element.width = width;
    temp_element.height = height;
    temp_element.rotateAngle = rotateAngle;
    temp_element.double_click = 0;
    temp_element.selected_text_element = selected_text_element;
    temp_element.backgroundColor = backgroundColor;
    temp_element.rounded_corner = roundedCorner;
    temp_element.margin_top = margin_top;
    temp_element.margin_left = margin_left;
    temp_element.padding_top = padding_top;
    temp_element.padding_right = padding_right;
    temp_element.padding_bottom = padding_bottom;
    temp_element.padding_left = padding_left;
    temp_element.custom_text_lock = lock_state;
    temp_element.font_min_size = font_min_size;
    temp_element.font_max_size = font_max_size;
    temp_element.scalling_class = scalling_class;
    temp_element.transparency = transparency;
    temp_element.text_autofit = text_autofit;
    customs_text_data = customs_text_data.concat(temp_element);

    updateSate(customs_text_data);

    for (let i = 0; i < customs.length; i++) {
      if (customs[i].id === id) {
        customs[i].left = left;
        customs[i].top = top;
        customs[i].width = width;
        customs[i].height = height;
        customs[i].title = title;
        customs[i].rotateAngle = rotateAngle;
        customs[i].backgroundColor = backgroundColor;
        customs[i].rounded_corner = roundedCorner;
        customs[i].selected_text_element = 1;
        customs[i].margin_top = margin_top;
        customs[i].margin_left = margin_left;
        customs[i].padding_top = padding_top;
        customs[i].padding_right = padding_right;
        customs[i].padding_bottom = padding_bottom;
        customs[i].padding_left = padding_left;
        customs[i].custom_text_lock = lock_state;
        customs[i].font_min_size = font_min_size;
        customs[i].font_max_size = font_max_size;
        customs[i].scalling_class = scalling_class;
        customs[i].transparency = transparency;
        customs[i].text_autofit = text_autofit;
      } else {
        customs[i].selected_text_element = 0;
      }
    }
    updateSate(customs);
    props.parentsetCustomDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      lock_state,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );
  }

  function addCustomImage(data) {
    adjustImageZindex();

    let newelement = {
      id: customImages.length,
      img_box_title: "Custom Image " + customImages.length,
      img_src: data,
      top: 120,
      left: 260,
      width: 100,
      height: 100,
      order: customImages.length,
      //order: 21,
      original_image_width: 100,
      original_image_height: 100,
      custom_image_lock: false,
    };

    updateImageState(customImages.concat(newelement));
  }

  function updateCustomImage(
    img_src,
    id,
    order,
    image_box_id_with_order_21,
    image_box_max_order,
    image_upload = false,
    current_image_box_order,
    original_image_width1,
    original_image_height1,
    top,
    left
  ) {
    let temp_state = [...customImages];
    if (image_upload === true && order !== current_image_box_order) {
      if (id > 0) {
        adjustImageZindexWhileUpload(current_image_box_order);
      }
    }

    let temp_element = { ...temp_state[id] };
    temp_element.img_src = img_src;
    if (id > 0) {
      temp_element.order = order;
    }
    temp_element.original_image_width = original_image_width1;
    temp_element.original_image_height = original_image_height1;

    if (image_upload === true) {
      temp_element.width = original_image_width1;
      temp_element.height = original_image_height1;
      temp_element.top = top == null || top == undefined ? 120 : top;
      temp_element.left = left == null || left == undefined ? 260 : left;
    }
    temp_state[id] = temp_element;
    if (id === 0) {
      if (props.imageType === "front") {
        props.myProps.font_background = img_src;
      } else if (props.imageType === "back") {
        props.myProps.back_background = img_src;
      } else {
        props.myProps.font_background = img_src;
      }
    }
    updateImageState(temp_state);
    if (id === 0) {
      props.parentUpdateImageCustomsDataElementImg(
        "",
        id,
        props.imageType,
        temp_element.img_box_title
      );
    } else {
      props.parentUpdateImageCustomsDataElementImg(
        img_src,
        id,
        props.imageType,
        temp_element.img_box_title,
        temp_element.order,
        temp_state,
        image_upload,
        temp_element.top,
        temp_element.left
      );
    }
  }

  function adjustImageZindexWhileUpload(current_image_box_order) {
    let image_customs_data = customImages;
    let exist_image_with_z_index = 0;
    let current_order = "";
    for (let i = 0; i < image_customs_data.length; i++) {
      if (image_customs_data[i].id > 0 && image_customs_data[i].order !== 1) {
        if (image_customs_data[i].order === 21) {
          current_order = image_customs_data[i].order;
          exist_image_with_z_index = 1;
          customImages[i].order = image_customs_data.length - 1;
        }
      }
    }

    // detect missing order start
    //if (current_order === 21)
    {
      var a = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ];

      let check_data = [];
      for (let i = 0; i < image_customs_data.length - 1; i++) {
        check_data.push(a[i]);
      }
      let image_data = [];
      for (let i = 0; i < image_customs_data.length; i++) {
        if (
          image_customs_data[i].id > 0 &&
          image_customs_data[i].order < image_customs_data.length - 1
        ) {
          image_data.push(image_customs_data[i].order);
        }
      }
      let notPresentInData = check_data.filter(
        (val) => !image_data.includes(val)
      );
      current_order = notPresentInData[0];
    }

    if (exist_image_with_z_index === 1) {
      for (let i = 0; i < image_customs_data.length; i++) {
        if (image_customs_data[i].img_src !== "") {
          if (current_image_box_order === 1) {
            if (
              image_customs_data[i].id > 0 &&
              image_customs_data[i].order !== 1 &&
              image_customs_data[i].order <= image_customs_data.length - 1
            ) {
              customImages[i].order = customImages[i].order - 1;
            }
          } else {
            if (
              image_customs_data[i].id > 0 &&
              image_customs_data[i].order !== 1 &&
              image_customs_data[i].order >= current_order - 1
            ) {
              customImages[i].order = customImages[i].order - 1;
            }
          }
        }
      }
      updateImageState(customImages);
    } else {
      for (let i = 0; i < image_customs_data.length; i++) {
        if (current_image_box_order === 1) {
          if (
            image_customs_data[i].id > 0 &&
            image_customs_data[i].order !== 1 &&
            image_customs_data[i].order <= image_customs_data.length - 1
          ) {
            customImages[i].order = customImages[i].order - 1;
          }
        } else if (current_image_box_order === image_customs_data.length - 1) {
          if (
            image_customs_data[i].id > 0 &&
            image_customs_data[i].order !== 1 &&
            image_customs_data[i].order > current_order - 1
          ) {
            customImages[i].order = customImages[i].order - 1;
          }
        } else {
          if (
            image_customs_data[i].id > 0 &&
            image_customs_data[i].order !== 1 &&
            image_customs_data[i].order >= current_order - 1
          ) {
            customImages[i].order = customImages[i].order - 1;
          }
        }
      }
      updateImageState(customImages);
    }
  }

  function adjustImageZindex() {
    let image_customs_data = customImages;
    let exist_image_with_z_index = 0;
    let current_order = "";
    for (let i = 0; i < image_customs_data.length; i++) {
      if (image_customs_data[i].id > 0 && image_customs_data[i].order !== 1) {
        if (image_customs_data[i].order === 21) {
          current_order = image_customs_data[i].order;
          exist_image_with_z_index = 1;
          customImages[i].order = image_customs_data.length;
        }
      }
    }

    // detect missing order start
    if (current_order === 21) {
      var a = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ];

      let check_data = [];
      for (let i = 0; i < image_customs_data.length - 1; i++) {
        check_data.push(a[i]);
      }
      let image_data = [];
      for (let i = 0; i < image_customs_data.length; i++) {
        if (image_customs_data[i].id > 0) {
          image_data.push(image_customs_data[i].order);
        }
      }
      let notPresentInData = check_data.filter(
        (val) => !image_data.includes(val)
      );
      current_order = notPresentInData[0];
    }

    if (exist_image_with_z_index === 1) {
      for (let i = 0; i < image_customs_data.length; i++) {
        if (
          image_customs_data[i].id > 0 &&
          image_customs_data[i].order !== 1 &&
          image_customs_data[i].order > current_order
        ) {
          customImages[i].order = customImages[i].order - 1;
        }
      }
      updateImageState(customImages);
    }
  }

  function handleDone() {
    setModalState(false);
    let imagesrc;
    let html_data = document.getElementById("editor_box").innerHTML;

    const formData = new FormData();
    formData.append("html", html_data);
    formData.append("width", props.myProps.width.split("in")[0]);
    formData.append("height", props.myProps.height.split("in")[0]);
    if (props.panelType === "single") {
      formData.append("is_front_img_transparent", is_front_img_transparent);
      formData.append("is_back_img_transparent", is_front_img_transparent);
      props.handleImageTransparency(props.imageType, is_front_img_transparent);
    } else {
      if (props.imageType === "front") {
        formData.append("is_front_img_transparent", is_front_img_transparent);
        formData.append("is_back_img_transparent", is_front_img_transparent);
        props.handleImageTransparency(
          props.imageType,
          is_front_img_transparent
        );
      } else {
        formData.append("is_front_img_transparent", is_back_img_transparent);
        formData.append("is_back_img_transparent", is_back_img_transparent);
        props.handleImageTransparency(props.imageType, is_back_img_transparent);
      }
    }

    props
      .callRequest("POST", API_HTML_TO_IMAGE, true, formData)
      .then((res) => {
        imagesrc = process.env.REACT_APP_API + "api" + res.data;
        setTimeout(
          function () {
            props.parentCallback(html_data, imagesrc, props.myProps.paper_color);
            setModalState(true);
            handleClose();
          }.bind(),
          500
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function setImageDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    img_src,
    order
  ) {
    let temp_state = [...customImages];
    let temp_element = { ...temp_state[id] };
    temp_element.left = left;
    temp_element.top = top;
    temp_element.width = width;
    temp_element.height = height;
    temp_element.order = order;
    temp_state[id] = temp_element;
    updateImageState(temp_state);
    props.parentUpdateImageCustomsDataElement(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      img_src,
      props.imageType,
      order
    );
  }

  function deleteCustomElement(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        tem_customs = customs.filter((c) => c.id !== elementId);
        setMinMax({});
        let new_min_max = {};
        for (let i = 0; i < tem_customs.length; i++) {
          let temp_title = tem_customs[i].title.split('"');
          for (let k = 0; k < temp_title.length; k++) {
            let temp_title2 = temp_title[k].split(" ");
            for (let j = 0; j < temp_title2.length; j++) {
              if (temp_title2[j].includes("kgstar")) {
                let temp_title3 = temp_title2[j].split("kgstar");
                let title_data = `kgstar${temp_title3[1]}`;
                new_min_max = {
                  ...new_min_max,
                  [title_data]: {
                    min: tem_customs[i].font_min_size,
                    max: tem_customs[i].font_max_size,
                  },
                };
              }
            }
          }
        }

        let new_min_max2 = {};
        let non_editable_text = [...nonEditableTexts];
        for (let i = 0; i < non_editable_text.length; i++) {
          let temp_title = non_editable_text[i].title.split('"');
          for (let k = 0; k < temp_title.length; k++) {
            let temp_title2 = temp_title[k].split(" ");
            for (let j = 0; j < temp_title2.length; j++) {
              if (temp_title2[j].includes("kgstar")) {
                let temp_title3 = temp_title2[j].split("kgstar");
                let title_data = `kgstar${temp_title3[1]}`;
                new_min_max2 = {
                  ...new_min_max2,
                  [title_data]: {
                    min: non_editable_text[i].font_min_size,
                    max: non_editable_text[i].font_max_size,
                  },
                };
              }
            }
          }
        }

        setMinMax({ ...min_max, ...new_min_max, ...new_min_max2 });
        let new_min_max3 = {};
        new_min_max3 = { ...new_min_max3, ...new_min_max, ...new_min_max2 };
        props.handleTextScalingClass(props.imageType, new_min_max3);

        updateSate(tem_customs);
        props.parentdeleteCustom(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function deleteImageElement(elementId) {
    if (elementId === "1" || elementId === "2" || elementId === "3") {
      updateCustomImage("", elementId, customImages[elementId].order);
    } else {
      updateCustomImage("", elementId, customImages[elementId].order);
    }
  }

  function deleteCustomImage(id) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        let temp_state = [...customImages];
        let temp_element = { ...temp_state[id] };
        temp_element.img_src = "";
        temp_state[id] = temp_element;
        updateImageState(temp_state);
        if (id === 0) {
        } else {
          props.parentUpdateImageCustomsDataElementImg(
            "",
            id,
            props.imageType,
            temp_element.img_box_title
          );
        }

        deleteImageElement(id);
        Swal.fire("Deleted!", "", "success");
        if (id === 0) {
          if (props.imageType === "front") {
            setFrontImgTransparent("1");
            props.parentSetBackgroundColor(
              props.imageType,
              template_background_color,
              "1"
            );
          } else {
            setBackImgTransparent("1");
            props.parentSetBackgroundColor(
              props.imageType,
              template_background_color,
              "1"
            );
          }
        }
      }
    });
  }

  function addNonEditableText(data) {
    if (nonEditableTexts.length === 0) {
      new_id = 0;
    } else {
      const nonEditable = nonEditableTexts.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = nonEditable.id + 1;
    }
    let newelement = {
      id: new_id,
      title: "Non editable custom text",
      top: 100,
      left: 240,
      width: 100,
      height: 100,
      rotateAngle: 0,
      selected_text: 0,
      bgColor: "",
      rounded_corner: 0,
      double_click_text: 0,
      margin_top: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
      non_editable_text_state: true,
      non_editable_text_lock: false,
      font_min_size: 7,
      font_max_size: 0,
      scalling_class: "",
      transparency: false,
      text_autofit: false,
    };

    setNonEditableText(nonEditableTexts.concat(newelement));
    props.parentAddNonEditableText(props.imageType);
  }

  function setNonEditableTextDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state,
    font_min_size,
    font_max_size,
    scalling_class,
    transparency,
    text_autofit,
  ) {
    let selected_text_element = 0;
    if (resize === true) {
      selected_text_element = 1;
      setTextType("non_editable_text");
    }

    let temp_data = nonEditableTexts.filter((c) => c.id !== id);
    let temp_element = {};
    temp_element.id = id;
    temp_element.title = title;
    temp_element.top = top;
    temp_element.left = left;
    temp_element.width = width;
    temp_element.height = height;
    temp_element.double_click = 0;
    temp_element.rotateAngle = rotateAngle;
    temp_element.selected_text = 0;
    temp_element.bgColor = backgroundColor;
    temp_element.rounded_corner = roundedCorner;
    temp_element.margin_top = margin_top;
    temp_element.margin_left = margin_left;
    temp_element.padding_top = padding_top;
    temp_element.padding_right = padding_right;
    temp_element.padding_bottom = padding_bottom;
    temp_element.padding_left = padding_left;
    temp_element.non_editable_text_lock = lock_state;
    temp_element.font_min_size = font_min_size;
    temp_element.font_max_size = font_max_size;
    temp_element.scalling_class = scalling_class;
    temp_element.transparency = transparency;
    temp_element.text_autofit = text_autofit;
    temp_data = temp_data.concat(temp_element);
    setNonEditableText(temp_data);

    for (let i = 0; i < nonEditableTexts.length; i++) {
      if (nonEditableTexts[i].id === id) {
        nonEditableTexts[i].left = left;
        nonEditableTexts[i].top = top;
        nonEditableTexts[i].width = width;
        nonEditableTexts[i].height = height;
        nonEditableTexts[i].title = title;
        nonEditableTexts[i].rotateAngle = rotateAngle;
        nonEditableTexts[i].bgColor = backgroundColor;
        nonEditableTexts[i].rounded_corner = roundedCorner;
        nonEditableTexts[i].selected_text = 1;
        nonEditableTexts[i].margin_top = margin_top;
        nonEditableTexts[i].margin_left = margin_left;
        nonEditableTexts[i].padding_top = padding_top;
        nonEditableTexts[i].padding_right = padding_right;
        nonEditableTexts[i].padding_bottom = padding_bottom;
        nonEditableTexts[i].padding_left = padding_left;
        nonEditableTexts[i].non_editable_text_lock = lock_state;
        nonEditableTexts[i].font_min_size = font_min_size;
        nonEditableTexts[i].font_max_size = font_max_size;
        nonEditableTexts[i].scalling_class = scalling_class;
        nonEditableTexts[i].transparency = transparency;
        nonEditableTexts[i].text_autofit = text_autofit;
      } else {
        nonEditableTexts[i].selected_text = 0;
      }
    }
    setNonEditableText(nonEditableTexts);
    props.parentSetNonEditableTextDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      lock_state,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );
  }

  function deleteNonEditableText(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        tem_customs = nonEditableTexts.filter((c) => c.id !== elementId);

        setMinMax({});
        let new_min_max = {};
        for (let i = 0; i < tem_customs.length; i++) {
          let temp_title = tem_customs[i].title.split('"');
          for (let k = 0; k < temp_title.length; k++) {
            let temp_title2 = temp_title[k].split(" ");
            for (let j = 0; j < temp_title2.length; j++) {
              if (temp_title2[j].includes("kgstar")) {
                let temp_title3 = temp_title2[j].split("kgstar");
                let title_data = `kgstar${temp_title3[1]}`;
                new_min_max = {
                  ...new_min_max,
                  [title_data]: {
                    min: tem_customs[i].font_min_size,
                    max: tem_customs[i].font_max_size,
                  },
                };
              }
            }
          }
        }

        let new_min_max2 = {};
        let customs_text_data = [...customs];
        for (let i = 0; i < customs_text_data.length; i++) {
          let temp_title = customs_text_data[i].title.split('"');
          for (let k = 0; k < temp_title.length; k++) {
            let temp_title2 = temp_title[k].split(" ");
            for (let j = 0; j < temp_title2.length; j++) {
              if (temp_title2[j].includes("kgstar")) {
                let temp_title3 = temp_title2[j].split("kgstar");
                let title_data = `kgstar${temp_title3[1]}`;
                new_min_max2 = {
                  ...new_min_max2,
                  [title_data]: {
                    min: customs_text_data[i].font_min_size,
                    max: customs_text_data[i].font_max_size,
                  },
                };
              }
            }
          }
        }

        setMinMax({ ...min_max, ...new_min_max, ...new_min_max2 });
        let new_min_max3 = {};
        new_min_max3 = { ...new_min_max3, ...new_min_max, ...new_min_max2 };
        props.handleTextScalingClass(props.imageType, new_min_max3);

        setNonEditableText(tem_customs);
        props.parentDeleteNonEditableText(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function nonEditableTextEditorData(
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state,
    font_min_size,
    font_max_size,
    scalling_class,
    transparency,
    text_autofit,
  ) {
    let temp_data = nonEditableTexts.filter((c) => c.id !== id);
    let temp_element = {};
    temp_element.id = id;
    temp_element.title = title;
    temp_element.top = top;
    temp_element.left = left;
    temp_element.width = width;
    temp_element.height = height;
    temp_element.double_click_text = 0;
    temp_element.rotateAngle = rotateAngle;
    temp_element.selected_text = 0;
    temp_element.bgColor = backgroundColor;
    temp_element.rounded_corner = roundedCorner;
    temp_element.margin_top = margin_top;
    temp_element.margin_left = margin_left;
    temp_element.padding_top = padding_top;
    temp_element.padding_right = padding_right;
    temp_element.padding_bottom = padding_bottom;
    temp_element.padding_left = padding_left;
    temp_element.non_editable_text_lock = lock_state;
    temp_element.font_min_size = font_min_size < 7 ? 7 : font_min_size;
    temp_element.font_max_size = font_max_size === "" ? 0 : font_max_size;
    temp_element.scalling_class = scalling_class;
    temp_element.transparency = transparency;
    temp_element.text_autofit = text_autofit;
    temp_data = temp_data.concat(temp_element);

    setNonEditableText(temp_data);
    props.parentSetNonEditableTextDesign(
      "",
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      lock_state,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );

    setMinMax({});
    let new_min_max = {};
    for (let i = 0; i < temp_data.length; i++) {
      if (temp_data[i].title.includes("kgstar")) {
        let temp_title = temp_data[i].title.split('"');
        for (let k = 0; k < temp_title.length; k++) {
          let temp_title2 = temp_title[k].split(" ");
          for (let j = 0; j < temp_title2.length; j++) {
            if (temp_title2[j].includes("kgstar")) {
              let temp_title3 = temp_title2[j].split("kgstar");
              let title_data = `kgstar${temp_title3[1]}`;
              new_min_max = {
                ...new_min_max,
                [title_data]: {
                  min: temp_data[i].font_min_size,
                  max: temp_data[i].font_max_size,
                },
              };
            }
          }
        }
      }
    }

    let new_min_max2 = {};
    let customs_text_data = [...customs];
    for (let i = 0; i < customs_text_data.length; i++) {
      if (customs_text_data[i].title.includes("kgstar")) {
        let temp_title = customs_text_data[i].title.split('"');
        for (let k = 0; k < temp_title.length; k++) {
          let temp_title2 = temp_title[k].split(" ");
          for (let j = 0; j < temp_title2.length; j++) {
            if (temp_title2[j].includes("kgstar")) {
              let temp_title3 = temp_title2[j].split("kgstar");
              let title_data = `kgstar${temp_title3[1]}`;
              new_min_max2 = {
                ...new_min_max2,
                [title_data]: {
                  min: customs_text_data[i].font_min_size,
                  max: customs_text_data[i].font_max_size,
                },
              };
            }
          }
        }
      }
    }

    setMinMax({ ...min_max, ...new_min_max, ...new_min_max2 });
    let new_min_max3 = {};
    new_min_max3 = { ...new_min_max3, ...new_min_max, ...new_min_max2 };
    props.handleTextScalingClass(props.imageType, new_min_max3);
  }

  function addNonEditablePostage(data) {
    if (nonEditablePostages.length === 0) {
      new_id = 0;
    } else {
      const nonEditable = nonEditablePostages.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = nonEditable.id + 1;
    }
    let newelement = {
      id: new_id,
      title: "{{postage}}",
      top: 180,
      left: 120,
      width: 100,
      height: 100,
      rotateAngle: 0,
      selected_postage: 0,
      bgColor: "",
      rounded_corner: 0,
      double_click_text: 0,
      margin_top: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
      non_editable_postage_state: true,
      non_editable_postage_lock: false,
    };

    setNonEditablePostage(nonEditablePostages.concat(newelement));
    props.parentAddNonEditablePostage(props.imageType);
  }

  function setNonEditablePostageDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state
  ) {
    let selected_postage = 0;
    if (resize === true) {
      selected_postage = 1;
      setTextType("non_editable_postage");
    }

    let non_editable_postage = nonEditablePostages.filter((c) => c.id !== id);
    let temp_element = {};
    temp_element.id = id;
    temp_element.title = title;
    temp_element.top = top;
    temp_element.left = left;
    temp_element.width = width;
    temp_element.height = height;
    temp_element.rotateAngle = rotateAngle;
    temp_element.selected_postage = selected_postage;
    temp_element.bgColor = backgroundColor;
    temp_element.rounded_corner = roundedCorner;
    temp_element.margin_top = margin_top;
    temp_element.margin_left = margin_left;
    temp_element.padding_top = padding_top;
    temp_element.padding_right = padding_right;
    temp_element.padding_bottom = padding_bottom;
    temp_element.padding_left = padding_left;
    temp_element.non_editable_postage_lock = lock_state;
    non_editable_postage = non_editable_postage.concat(temp_element);

    setNonEditablePostage(non_editable_postage);

    for (let i = 0; i < nonEditablePostages.length; i++) {
      if (nonEditablePostages[i].id === id) {
        nonEditablePostages[i].left = left;
        nonEditablePostages[i].top = top;
        nonEditablePostages[i].width = width;
        nonEditablePostages[i].height = height;
        nonEditablePostages[i].title = title;
        nonEditablePostages[i].rotateAngle = rotateAngle;
        nonEditablePostages[i].bgColor = backgroundColor;
        nonEditablePostages[i].rounded_corner = roundedCorner;
        nonEditablePostages[i].selected_postage = 1;
        nonEditablePostages[i].margin_top = margin_top;
        nonEditablePostages[i].margin_left = margin_left;
        nonEditablePostages[i].padding_top = padding_top;
        nonEditablePostages[i].padding_right = padding_right;
        nonEditablePostages[i].padding_bottom = padding_bottom;
        nonEditablePostages[i].padding_left = padding_left;
        nonEditablePostages[i].non_editable_postage_lock = lock_state;
      } else {
        nonEditablePostages[i].selected_postage = 0;
      }
    }
    setNonEditablePostage(nonEditablePostages);
    props.parentSetNonEditablePostageDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      lock_state
    );
  }

  function deleteNonEditablePostage(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        tem_customs = nonEditablePostages.filter((c) => c.id !== elementId);
        setNonEditablePostage(tem_customs);
        props.parentDeleteNonEditablePostage(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function addNonEditableAccupix(data) {
    if (nonEditableAccupixs.length == 0) {
      if (nonEditableAccupixs.length === 0) {
        new_id = 0;
      } else {
        const nonEditable = nonEditableAccupixs.reduce((prev, current) =>
          +prev.id > +current.id ? prev : current
        );
        new_id = nonEditable.id + 1;
      }
      let newelement = {
        id: new_id,
        title: accupix_img,
        top: 220,
        left: 120,
        width: 300,
        height: 200,
        rotateAngle: 0,
        selected_accupix: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_accupix_state: true,
        aspect_ratio_width: 3,
        aspect_ratio_height: 2,
        z_index: 1000,
        accupix_satellite_lock: false,
      };
      setNonEditableAccupix(nonEditableAccupixs.concat(newelement));
      props.parentAddNonEditableAccupix(props.imageType);
    } else {
      if (nonEditableAccupixStreetview.length === 0) {
        new_id = 0;
      } else {
        const nonEditable = nonEditableAccupixStreetview.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = nonEditable.id + 1;
      }
      let newelement = {
        id: new_id,
        title: accupix_streetview_img,
        top: 150,
        left: 120,
        width: 300,
        height: 200,
        rotateAngle: 0,
        selected_accupix: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_accupix_state: true,
        aspect_ratio_width: 3,
        aspect_ratio_height: 2,
        z_index: 1000,
        accupix_streetview_lock: false,
      };

      setNonEditableAccupixStreetview(
        nonEditableAccupixStreetview.concat(newelement)
      );
      props.parentAddNonEditableStreetviewAccupix(props.imageType);
    }
  }

  function setNonEditableAccupixDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    aspect_ratio_width,
    aspect_ratio_height,
    z_index
  ) {
    let selected_accupix = 0;
    if (resize === true) {
      //selected_accupix = 1;
      setTextType("non_editable_accupix");
    }

    let newelement = {
      id: id,
      title: title,
      top: top,
      left: left,
      width: width,
      height: height,
      rotateAngle: rotateAngle,
      bgColor: backgroundColor,
      rounded_corner: roundedCorner,
      selected_accupix: selected_accupix,
      margin_top: margin_top,
      margin_left: margin_left,
      padding_top: padding_top,
      padding_right: padding_right,
      padding_bottom: padding_bottom,
      padding_left: padding_left,
      non_editable_accupix_state: true,
      aspect_ratio_width: aspect_ratio_width,
      aspect_ratio_height: aspect_ratio_height,
      z_index: z_index,
    };

    tem_customs = nonEditableAccupixs.filter((c) => c.id !== id);
    setNonEditableAccupix(tem_customs.concat(newelement));
    props.parentSetNonEditableAccupixDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_ratio_width,
      aspect_ratio_height,
      z_index
    );
  }

  function deleteNonEditableAccupix(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        setAccupixImageFlag("satellite");
        tem_customs = nonEditableAccupixs.filter((c) => c.id !== elementId);
        setNonEditableAccupix(tem_customs);
        props.parentDeleteNonEditableAccupix(elementId, props.imageType);
        if (props.imageType === "front") {
          props.parentGetAccupixTypeFront(0);
          props.parentGetAccupixAspectRatioFront("3:2");
        } else if (props.imageType === "back") {
          props.parentGetAccupixTypeBack(0);
          props.parentGetAccupixAspectRatioBack("3:2");
        }
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function addNonEditableAccupixStreeview(data) {
    if (nonEditableAccupixStreetview.length == 0) {
      if (nonEditableAccupixStreetview.length === 0) {
        new_id = 0;
      } else {
        const nonEditable = nonEditableAccupixStreetview.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = nonEditable.id + 1;
      }
      let newelement = {
        id: new_id,
        title: accupix_streetview_img,
        top: 150,
        left: 120,
        width: 300,
        height: 200,
        rotateAngle: 0,
        selected_accupix: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_accupix_state: true,
        aspect_ratio_width: 3,
        aspect_ratio_height: 2,
        z_index: 1000,
        accupix_streetview_lock: false,
      };

      setNonEditableAccupixStreetview(
        nonEditableAccupixStreetview.concat(newelement)
      );
      props.parentAddNonEditableStreetviewAccupix(props.imageType);
    } else {
      if (nonEditableAccupixs.length === 0) {
        new_id = 0;
      } else {
        const nonEditable = nonEditableAccupixs.reduce((prev, current) =>
          +prev.id > +current.id ? prev : current
        );
        new_id = nonEditable.id + 1;
      }
      let newelement = {
        id: new_id,
        title: accupix_img,
        top: 220,
        left: 120,
        width: 300,
        height: 200,
        rotateAngle: 0,
        selected_accupix: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_accupix_state: true,
        aspect_ratio_width: 3,
        aspect_ratio_height: 2,
        z_index: 1000,
        accupix_satellite_lock: false,
      };

      setNonEditableAccupix(nonEditableAccupixs.concat(newelement));
      props.parentAddNonEditableAccupix(props.imageType);
    }
  }

  function setNonEditableAccupixStreetviewDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    aspect_ratio_width,
    aspect_ratio_height,
    z_index
  ) {
    let selected_accupix = 0;
    if (resize === true) {
      //selected_accupix = 1;
      setTextType("non_editable_accupix_streetview");
    }

    let newelement = {
      id: id,
      title: title,
      top: top,
      left: left,
      width: width,
      height: height,
      rotateAngle: rotateAngle,
      bgColor: backgroundColor,
      rounded_corner: roundedCorner,
      selected_accupix: selected_accupix,
      margin_top: margin_top,
      margin_left: margin_left,
      padding_top: padding_top,
      padding_right: padding_right,
      padding_bottom: padding_bottom,
      padding_left: padding_left,
      non_editable_accupix_state: true,
      aspect_ratio_width: aspect_ratio_width,
      aspect_ratio_height: aspect_ratio_height,
      z_index: z_index,
    };

    tem_customs = nonEditableAccupixStreetview.filter((c) => c.id !== id);
    setNonEditableAccupixStreetview(tem_customs.concat(newelement));
    props.parentSetNonEditableStreetviewAccupixDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_ratio_width,
      aspect_ratio_height,
      z_index
    );
  }

  function deleteNonEditableStreetviewAccupix(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        setAccupixImageFlag("streetview");
        tem_customs = nonEditableAccupixStreetview.filter(
          (c) => c.id !== elementId
        );

        setNonEditableAccupixStreetview(tem_customs);
        props.parentDeleteNonEditableStreetviewAccupix(
          elementId,
          props.imageType
        );
        if (props.imageType === "front") {
          props.parentGetAccupixStreetviewTypeFront(0);
          props.parentGetAccupixStreetviewAspectRatioFront("3:2");
        } else if (props.imageType === "back") {
          props.parentGetAccupixStreetviewTypeBack(0);
          props.parentGetAccupixStreetviewAspectRatioBack("3:2");
        }
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function addNonEditableAccupixOnchange(data, id) {
    if (data == "satellite") {
      if (nonEditableAccupixs.length === 0) {
        new_id = 0;
      } else {
        const nonEditable = nonEditableAccupixs.reduce((prev, current) =>
          +prev.id > +current.id ? prev : current
        );
        new_id = nonEditable.id + 1;
      }
      let newelement = {
        id: new_id,
        title: accupix_img,
        top: 220,
        left: 120,
        width: 300,
        height: 200,
        rotateAngle: 0,
        selected_accupix: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_accupix_state: true,
        aspect_ratio_width: 3,
        aspect_ratio_height: 2,
        z_index: 1000,
        accupix_satellite_lock: false,
      };

      setNonEditableAccupix(nonEditableAccupixs.concat(newelement));
      let tem_customs = nonEditableAccupixStreetview.filter((c) => c.id !== id);
      setNonEditableAccupixStreetview(tem_customs);
      props.parentDeleteNonEditableStreetviewAccupix(id, props.imageType);
    } else if (data == "streetview") {
      if (nonEditableAccupixStreetview.length === 0) {
        new_id = 0;
      } else {
        const nonEditable = nonEditableAccupixStreetview.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = nonEditable.id + 1;
      }
      let newelement = {
        id: new_id,
        title: accupix_streetview_img,
        top: 150,
        left: 120,
        width: 300,
        height: 200,
        rotateAngle: 0,
        selected_accupix: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_accupix_state: true,
        aspect_ratio_width: 3,
        aspect_ratio_height: 2,
        z_index: 1000,
        accupix_streetview_lock: false,
      };

      setNonEditableAccupixStreetview(
        nonEditableAccupixStreetview.concat(newelement)
      );
      let tem_customs = nonEditableAccupixs.filter((c) => c.id !== id);
      setNonEditableAccupix(tem_customs);
      props.parentDeleteNonEditableAccupix(id, props.imageType);
    }
  }

  function updateNonEditableAccupixZIndex(z_index, id) {
    let temp_state = [...nonEditableAccupixs];
    let temp_element = { ...temp_state[id] };
    temp_element.id = id;
    temp_element.z_index = z_index;
    temp_state[id] = temp_element;
    setNonEditableAccupix(temp_state);
    props.parentUpdateNonEditableAccupixZIndex(
      temp_element.z_index,
      temp_element.id,
      props.imageType
    );
  }

  function updateNonEditableAccupixStreetviewZIndex(z_index, id) {
    let temp_state = [...nonEditableAccupixStreetview];
    let temp_element = { ...temp_state[id] };
    temp_element.id = id;
    temp_element.z_index = z_index;
    temp_state[id] = temp_element;
    setNonEditableAccupixStreetview(temp_state);
    props.parentUpdateNonEditableAccupixStreetviewZIndex(
      temp_element.z_index,
      temp_element.id,
      props.imageType
    );
  }

  const onDelElement = (e, id, type, double_click = 0) => {
    e.preventDefault();
    if (e.key === "Delete") {
      if (type === "text") {
        if (double_click === 1) {
          return false;
        }
        deleteCustomElement(id);
      } else if (type === "custom_image") {
        deleteCustomImage(id);
      } else if (type === "non_editable_text") {
        if (double_click === 1) {
          return false;
        }
        deleteNonEditableText(id);
      } else if (type === "non_editable_postage") {
        if (double_click === 1) {
          return false;
        }
        deleteNonEditablePostage(id);
      } else if (type === "non_editable_accupix") {
        if (double_click === 1) {
          return false;
        }
        deleteNonEditableAccupix(id);
      } else if (type === "non_editable_accupix_streetview") {
        if (double_click === 1) {
          return false;
        }
        deleteNonEditableStreetviewAccupix(id);
      } else if (type === "logo_uploader_component") {
        deleteLogoUploader(id);
      } else if (
        type === "data_sequence_component" ||
        type === "data_matrix_component"
      ) {
        deleteDataSequenceMatrix(id);
      } else if (type === "imbarcode_component") {
        if (double_click === 1) {
          return false;
        }
        deleteImbarcode(id);
      } else if (type === "line_shape") {
        deleteLineShape(id);
      }
    }
  };

  function textEditorData(
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state,
    font_min_size,
    font_max_size,
    scalling_class,
    transparency,
    text_autofit,
  ) {
    let customs_text_data = customs.filter((c) => c.id !== id);
    let temp_element = {};
    temp_element.id = id;
    temp_element.title = title;
    temp_element.top = top;
    temp_element.left = left;
    temp_element.width = width;
    temp_element.height = height;
    temp_element.double_click = 0;
    temp_element.rotateAngle = rotateAngle;
    temp_element.selected_text_element = 0;
    temp_element.backgroundColor = backgroundColor;
    temp_element.rounded_corner = roundedCorner;
    temp_element.margin_top = margin_top;
    temp_element.margin_left = margin_left;
    temp_element.padding_top = padding_top;
    temp_element.padding_right = padding_right;
    temp_element.padding_bottom = padding_bottom;
    temp_element.padding_left = padding_left;
    temp_element.custom_text_lock = lock_state;
    temp_element.font_min_size = font_min_size < 7 ? 7 : font_min_size;
    temp_element.font_max_size = font_max_size === "" ? 0 : font_max_size;
    temp_element.scalling_class = scalling_class;
    temp_element.transparency = transparency;
    temp_element.text_autofit = text_autofit;
    customs_text_data = customs_text_data.concat(temp_element);

    updateSate(customs_text_data);
    props.parentsetCustomDesign(
      "",
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      lock_state,
      font_min_size,
      font_max_size,
      scalling_class,
      transparency,
      text_autofit,
    );

    setMinMax({});
    let new_min_max = {};
    for (let i = 0; i < customs_text_data.length; i++) {
      if (customs_text_data[i].title.includes("kgstar")) {
        let temp_title = customs_text_data[i].title.split('"');
        for (let k = 0; k < temp_title.length; k++) {
          let temp_title2 = temp_title[k].split(" ");
          for (let j = 0; j < temp_title2.length; j++) {
            if (temp_title2[j].includes("kgstar")) {
              let temp_title3 = temp_title2[j].split("kgstar");
              let title_data = `kgstar${temp_title3[1]}`;
              new_min_max = {
                ...new_min_max,
                [title_data]: {
                  min: customs_text_data[i].font_min_size,
                  max: customs_text_data[i].font_max_size,
                },
              };
            }
          }
        }
      }
    }

    let new_min_max2 = {};
    let non_editable_text = [...nonEditableTexts];
    for (let i = 0; i < non_editable_text.length; i++) {
      if (non_editable_text[i].title.includes("kgstar")) {
        let temp_title = non_editable_text[i].title.split('"');
        for (let k = 0; k < temp_title.length; k++) {
          let temp_title2 = temp_title[k].split(" ");
          for (let j = 0; j < temp_title2.length; j++) {
            if (temp_title2[j].includes("kgstar")) {
              let temp_title3 = temp_title2[j].split("kgstar");
              let title_data = `kgstar${temp_title3[1]}`;
              new_min_max2 = {
                ...new_min_max2,
                [title_data]: {
                  min: non_editable_text[i].font_min_size,
                  max: non_editable_text[i].font_max_size,
                },
              };
            }
          }
        }
      }
    }

    setMinMax({ ...min_max, ...new_min_max, ...new_min_max2 });
    let new_min_max3 = {};
    new_min_max3 = { ...new_min_max3, ...new_min_max, ...new_min_max2 };
    props.handleTextScalingClass(props.imageType, new_min_max3);
  }

  function updateImageCustomsZIndex(
    img_box_title,
    img_src,
    order,
    current_id,
    imageType,
    currentZIndex
  ) {
    let temp_state = [...customImages];
    let temp_element = { ...temp_state[current_id] };
    temp_element.img_box_title = img_box_title;
    temp_element.img_src = img_src;
    temp_element.order = order;
    temp_state[current_id] = temp_element;

    for (let i = 0; i < currentZIndex.length - 1; i++) {
      customImages[i + 1].order = currentZIndex[i + 1].order;
    }
    updateImageState(customImages);
    props.parentUpdateImageCustomsDataElementImg(
      img_src,
      current_id,
      imageType,
      img_box_title,
      order,
      currentZIndex
    );
  }

  function customImageCompare(a, b) {
    const orderA = a.order;
    const orderB = b.order;

    let comparison = 0;
    if (orderA < orderB) {
      comparison = 1;
    } else if (orderA > orderB) {
      comparison = -1;
    }
    return comparison;
  }

  const renderStyles = () => {
    return (
      <style>
        {`.bleed:before {
          border-width: ${props.myProps.is_bleed === "1" ? 0 : props.myProps.bleed
          };
        }`}
      </style>
    );
  };

  function selectCustomText(id, type) {
    if (type === "non_editable_text") {
      let non_editable_text = [...nonEditableTexts];
      for (let i = 0; i < nonEditableTexts.length; i++) {
        if (non_editable_text[i].id === id) {
          non_editable_text[i].selected_text = 1;
        } else {
          non_editable_text[i].selected_text = 0;
        }
      }
      setTextType(type);
      setNonEditableText(non_editable_text);
    } else if (type === "non_editable_postage") {
      let non_editable_postage = [...nonEditablePostages];
      for (let i = 0; i < nonEditablePostages.length; i++) {
        if (non_editable_postage[i].id === id) {
          non_editable_postage[i].selected_postage = 1;
        } else {
          non_editable_postage[i].selected_postage = 0;
        }
      }
      setTextType(type);
      setNonEditablePostage(non_editable_postage);
    } else if (type === "imbarcode_component") {
      let imbarcode = [...imbarcode_component];
      for (let i = 0; i < imbarcode.length; i++) {
        if (imbarcode[i].id === id) {
          imbarcode[i].selected_imbarcode = 1;
        } else {
          imbarcode[i].selected_imbarcode = 0;
        }
      }
      setTextType(type);
      setImbarcode(imbarcode);
    } else if (type === "line_shape") {
      let line_shape_data = [...line_shape];
      for (let i = 0; i < line_shape_data.length; i++) {
        if (line_shape_data[i].id === id) {
          line_shape_data[i].selected_line = 1;
        } else {
          line_shape_data[i].selected_line = 0;
        }
      }
      setTextType(type);
      setLineShape(line_shape_data);
    } else {
      let customs_text_data = [...customs];
      for (let i = 0; i < customs.length; i++) {
        if (customs_text_data[i].id === id) {
          customs_text_data[i].selected_text_element = 1;
        } else {
          customs_text_data[i].selected_text_element = 0;
        }
      }
      setTextType(type);
      updateSate(customs_text_data);
    }
  }
  function handleChangeRoundedCorner(rounded_corner) {
    let id;
    let left;
    let top;
    let width;
    let height;
    let title;
    let rotateAngle;
    let backgroundColor;
    let bgColor;
    let margin_top;
    let margin_left;
    let padding_top;
    let padding_right;
    let padding_bottom;
    let padding_left;
    let lock_state;
    let font_min_size;
    let font_max_size;
    let scalling_class;
    let transparency;
    let text_autofit;

    if (textType === "custom_text") {
      let customs_text_data = [...customs];
      for (let i = 0; i < customs.length; i++) {
        if (customs_text_data[i].selected_text_element === 1) {
          id = customs_text_data[i].id;
          left = customs_text_data[i].left;
          top = customs_text_data[i].top;
          width = customs_text_data[i].width;
          height = customs_text_data[i].height;
          title = customs_text_data[i].title;
          rotateAngle = customs_text_data[i].rotateAngle;
          backgroundColor = customs_text_data[i].backgroundColor;
          customs_text_data[i].rounded_corner = rounded_corner;
          margin_top = customs_text_data[i].margin_top;
          margin_left = customs_text_data[i].margin_left;
          padding_top = customs_text_data[i].padding_top;
          padding_right = customs_text_data[i].padding_right;
          padding_bottom = customs_text_data[i].padding_bottom;
          padding_left = customs_text_data[i].padding_left;
          lock_state = customs_text_data[i].customs_text_data;
          font_min_size = customs_text_data[i].font_min_size;
          font_max_size = customs_text_data[i].font_max_size;
          scalling_class = customs_text_data[i].scalling_class;
          transparency = customs_text_data[i].transparency;
          text_autofit = customs_text_data[i].text_autofit;
        }
      }
      updateSate(customs_text_data);
      props.parentsetCustomDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        backgroundColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state,
        font_min_size,
        font_max_size,
        scalling_class,
        transparency,
        text_autofit,
      );
    } else if (textType === "non_editable_postage") {
      let non_editable_postage = [...nonEditablePostages];
      for (let i = 0; i < nonEditablePostages.length; i++) {
        if (non_editable_postage[i].selected_postage === 1) {
          id = non_editable_postage[i].id;
          left = non_editable_postage[i].left;
          top = non_editable_postage[i].top;
          width = non_editable_postage[i].width;
          height = non_editable_postage[i].height;
          title = non_editable_postage[i].title;
          rotateAngle = non_editable_postage[i].rotateAngle;
          bgColor = non_editable_postage[i].bgColor;
          non_editable_postage[i].rounded_corner = rounded_corner;
          margin_top = non_editable_postage[i].margin_top;
          margin_left = non_editable_postage[i].margin_left;
          padding_top = non_editable_postage[i].padding_top;
          padding_right = non_editable_postage[i].padding_right;
          padding_bottom = non_editable_postage[i].padding_bottom;
          padding_left = non_editable_postage[i].padding_left;
          lock_state = non_editable_postage[i].non_editable_postage_lock;
        }
      }
      setNonEditablePostage(non_editable_postage);
      props.parentSetNonEditablePostageDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state
      );
    } else if (textType === "imbarcode_component") {
      let imbarcode = [...imbarcode_component];
      for (let i = 0; i < imbarcode.length; i++) {
        if (imbarcode[i].selected_imbarcode === 1) {
          id = imbarcode[i].id;
          left = imbarcode[i].left;
          top = imbarcode[i].top;
          width = imbarcode[i].width;
          height = imbarcode[i].height;
          title = imbarcode[i].title;
          rotateAngle = imbarcode[i].rotateAngle;
          bgColor = imbarcode[i].bgColor;
          imbarcode[i].rounded_corner = rounded_corner;
          margin_top = imbarcode[i].margin_top;
          margin_left = imbarcode[i].margin_left;
          padding_top = imbarcode[i].padding_top;
          padding_right = imbarcode[i].padding_right;
          padding_bottom = imbarcode[i].padding_bottom;
          padding_left = imbarcode[i].padding_left;
          lock_state = imbarcode[i].imbarcode_lock;
        }
      }
      setImbarcode(imbarcode);
      props.parentSetImbarcodeDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state
      );
    } else {
      let non_editable_text = [...nonEditableTexts];
      for (let i = 0; i < nonEditableTexts.length; i++) {
        if (non_editable_text[i].selected_text === 1) {
          id = non_editable_text[i].id;
          left = non_editable_text[i].left;
          top = non_editable_text[i].top;
          width = non_editable_text[i].width;
          height = non_editable_text[i].height;
          title = non_editable_text[i].title;
          rotateAngle = non_editable_text[i].rotateAngle;
          bgColor = non_editable_text[i].bgColor;
          non_editable_text[i].rounded_corner = rounded_corner;
          margin_top = non_editable_text[i].margin_top;
          margin_left = non_editable_text[i].margin_left;
          padding_top = non_editable_text[i].padding_top;
          padding_right = non_editable_text[i].padding_right;
          padding_bottom = non_editable_text[i].padding_bottom;
          padding_left = non_editable_text[i].padding_left;
          lock_state = non_editable_text[i].non_editable_text_lock;
          font_min_size = non_editable_text[i].font_min_size;
          font_max_size = non_editable_text[i].font_max_size;
          scalling_class = non_editable_text[i].scalling_class;
          transparency = non_editable_text[i].transparency;
          text_autofit = non_editable_text[i].text_autofit;
        }
      }
      setNonEditableText(non_editable_text);
      props.parentSetNonEditableTextDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state,
        font_min_size,
        font_max_size,
        scalling_class,
        transparency,
        text_autofit
      );
    }
  }

  function handleTextMinMaxSize(min_size, max_size) {
    let id;
    let left;
    let top;
    let width;
    let height;
    let title;
    let rotateAngle;
    let backgroundColor;
    let bgColor;
    let rounded_corner;
    let margin_top;
    let margin_left;
    let padding_top;
    let padding_right;
    let padding_bottom;
    let padding_left;
    let lock_state;
    let font_min_size;
    let font_max_size;
    let scalling_class;
    let transparency;
    let text_autofit;

    if (textType === "custom_text") {
      let customs_text_data = [...customs];
      for (let i = 0; i < customs.length; i++) {
        if (customs_text_data[i].selected_text_element === 1) {
          id = customs_text_data[i].id;
          left = customs_text_data[i].left;
          top = customs_text_data[i].top;
          width = customs_text_data[i].width;
          height = customs_text_data[i].height;
          title = customs_text_data[i].title;
          rotateAngle = customs_text_data[i].rotateAngle;
          backgroundColor = customs_text_data[i].backgroundColor;
          rounded_corner = customs_text_data[i].rounded_corner;
          margin_top = customs_text_data[i].margin_top;
          margin_left = customs_text_data[i].margin_left;
          padding_top = customs_text_data[i].padding_top;
          padding_right = customs_text_data[i].padding_right;
          padding_bottom = customs_text_data[i].padding_bottom;
          padding_left = customs_text_data[i].padding_left;
          lock_state = customs_text_data[i].customs_text_data;
          font_min_size = min_size < 7 ? 7 : min_size;
          font_max_size = max_size === "" ? 0 : max_size;
          customs_text_data[i].font_min_size = min_size < 7 ? 7 : min_size;
          customs_text_data[i].font_max_size = max_size === "" ? 0 : max_size;
          scalling_class = customs_text_data[i].scalling_class;
          transparency = customs_text_data[i].transparency;
          text_autofit = customs_text_data[i].text_autofit;
        }
      }

      updateSate(customs_text_data);
      props.parentsetCustomDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        backgroundColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state,
        font_min_size,
        font_max_size,
        scalling_class,
        transparency,
        text_autofit,
      );

      setMinMax({});
      let new_min_max = {};
      for (let i = 0; i < customs_text_data.length; i++) {
        let temp_title = customs_text_data[i].title.split('"');
        for (let k = 0; k < temp_title.length; k++) {
          let temp_title2 = temp_title[k].split(" ");
          for (let j = 0; j < temp_title2.length; j++) {
            if (temp_title2[j].includes("kgstar")) {
              let temp_title3 = temp_title2[j].split("kgstar");
              let title_data = `kgstar${temp_title3[1]}`;
              new_min_max = {
                ...new_min_max,
                [title_data]: {
                  min: customs_text_data[i].font_min_size,
                  max: customs_text_data[i].font_max_size,
                },
              };
            }
          }
        }
      }

      let new_min_max2 = {};
      let non_editable_text = [...nonEditableTexts];
      for (let i = 0; i < non_editable_text.length; i++) {
        let temp_title = non_editable_text[i].title.split('"');
        for (let k = 0; k < temp_title.length; k++) {
          let temp_title2 = temp_title[k].split(" ");
          for (let j = 0; j < temp_title2.length; j++) {
            if (temp_title2[j].includes("kgstar")) {
              let temp_title3 = temp_title2[j].split("kgstar");
              let title_data = `kgstar${temp_title3[1]}`;
              new_min_max2 = {
                ...new_min_max2,
                [title_data]: {
                  min: non_editable_text[i].font_min_size,
                  max: non_editable_text[i].font_max_size,
                },
              };
            }
          }
        }
      }

      setTimeout(() => {
        setMinMax({ ...min_max, ...new_min_max, ...new_min_max2 });
        let new_min_max3 = {};
        new_min_max3 = { ...new_min_max3, ...new_min_max, ...new_min_max2 };
        props.handleTextScalingClass(props.imageType, new_min_max3);
      }, 1000);
    } else {
      let non_editable_text = [...nonEditableTexts];
      for (let i = 0; i < nonEditableTexts.length; i++) {
        if (non_editable_text[i].selected_text === 1) {
          id = non_editable_text[i].id;
          left = non_editable_text[i].left;
          top = non_editable_text[i].top;
          width = non_editable_text[i].width;
          height = non_editable_text[i].height;
          title = non_editable_text[i].title;
          rotateAngle = non_editable_text[i].rotateAngle;
          bgColor = non_editable_text[i].bgColor;
          non_editable_text[i].rounded_corner = rounded_corner;
          margin_top = non_editable_text[i].margin_top;
          margin_left = non_editable_text[i].margin_left;
          padding_top = non_editable_text[i].padding_top;
          padding_right = non_editable_text[i].padding_right;
          padding_bottom = non_editable_text[i].padding_bottom;
          padding_left = non_editable_text[i].padding_left;
          lock_state = non_editable_text[i].non_editable_text_lock;
          font_min_size = min_size < 7 ? 7 : min_size;
          font_max_size = max_size === "" ? 0 : max_size;
          non_editable_text[i].font_min_size = min_size < 7 ? 7 : min_size;
          non_editable_text[i].font_max_size = max_size === "" ? 0 : max_size;
          scalling_class = non_editable_text[i].scalling_class;
          transparency = non_editable_text[i].transparency;
          text_autofit = non_editable_text[i].text_autofit;
        }
      }

      setNonEditableText(non_editable_text);
      props.parentSetNonEditableTextDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state,
        font_min_size,
        font_max_size,
        scalling_class,
        transparency,
        text_autofit,
      );

      setMinMax({});
      let new_min_max = {};
      let customs_text_data = [...customs];
      for (let i = 0; i < customs_text_data.length; i++) {
        let temp_title = customs_text_data[i].title.split('"');
        for (let k = 0; k < temp_title.length; k++) {
          let temp_title2 = temp_title[k].split(" ");
          for (let j = 0; j < temp_title2.length; j++) {
            if (temp_title2[j].includes("kgstar")) {
              let temp_title3 = temp_title2[j].split("kgstar");
              let title_data = `kgstar${temp_title3[1]}`;
              new_min_max = {
                ...new_min_max,
                [title_data]: {
                  min: customs_text_data[i].font_min_size,
                  max: customs_text_data[i].font_max_size,
                },
              };
            }
          }
        }
      }

      let new_min_max2 = {};
      for (let i = 0; i < non_editable_text.length; i++) {
        let temp_title = non_editable_text[i].title.split('"');
        for (let k = 0; k < temp_title.length; k++) {
          let temp_title2 = temp_title[k].split(" ");
          for (let j = 0; j < temp_title2.length; j++) {
            if (temp_title2[j].includes("kgstar")) {
              let temp_title3 = temp_title2[j].split("kgstar");
              let title_data = `kgstar${temp_title3[1]}`;
              new_min_max2 = {
                ...new_min_max2,
                [title_data]: {
                  min: non_editable_text[i].font_min_size,
                  max: non_editable_text[i].font_max_size,
                },
              };
            }
          }
        }
      }

      setTimeout(() => {
        setMinMax({ ...min_max, ...new_min_max, ...new_min_max2 });
        let new_min_max3 = {};
        new_min_max3 = { ...new_min_max3, ...new_min_max, ...new_min_max2 };
        props.handleTextScalingClass(props.imageType, new_min_max3);
      }, 1000);
    }
  }

  function handleChangeColor(color) {
    let id;
    let left;
    let top;
    let width;
    let height;
    let title;
    let rotateAngle;
    let backgroundColor;
    let bgColor;
    let rounded_corner;
    let margin_top;
    let margin_left;
    let padding_top;
    let padding_right;
    let padding_bottom;
    let padding_left;
    let lock_state;
    let font_min_size;
    let font_max_size;
    let scalling_class;
    let transparency;
    let text_autofit;

    if (textType === "custom_text") {
      let customs_text_data = [...customs];
      for (let i = 0; i < customs.length; i++) {
        if (customs_text_data[i].selected_text_element === 1) {
          id = customs_text_data[i].id;
          left = customs_text_data[i].left;
          top = customs_text_data[i].top;
          width = customs_text_data[i].width;
          height = customs_text_data[i].height;
          title = customs_text_data[i].title;
          rotateAngle = customs_text_data[i].rotateAngle;
          backgroundColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;

          customs_text_data[i].backgroundColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;
          rounded_corner = customs_text_data[i].rounded_corner;
          margin_top = customs_text_data[i].margin_top;
          margin_left = customs_text_data[i].margin_left;
          padding_top = customs_text_data[i].padding_top;
          padding_right = customs_text_data[i].padding_right;
          padding_bottom = customs_text_data[i].padding_bottom;
          padding_left = customs_text_data[i].padding_left;
          lock_state = customs_text_data[i].customs_text_data;
          font_min_size = customs_text_data[i].font_min_size;
          font_max_size = customs_text_data[i].font_max_size;
          scalling_class = customs_text_data[i].scalling_class;
          transparency = customs_text_data[i].transparency;
          text_autofit = customs_text_data[i].text_autofit;
        }
      }
      updateSate(customs_text_data);
      props.parentsetCustomDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        backgroundColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state,
        font_min_size,
        font_max_size,
        scalling_class,
        transparency,
        text_autofit,
      );
    } else if (textType === "non_editable_postage") {
      let non_editable_post = [...nonEditablePostages];
      for (let i = 0; i < nonEditablePostages.length; i++) {
        if (non_editable_post[i].selected_postage === 1) {
          id = non_editable_post[i].id;
          left = non_editable_post[i].left;
          top = non_editable_post[i].top;
          width = non_editable_post[i].width;
          height = non_editable_post[i].height;
          title = non_editable_post[i].title;
          rotateAngle = non_editable_post[i].rotateAngle;
          bgColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;

          non_editable_post[i].bgColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;
          rounded_corner = non_editable_post[i].rounded_corner;
          margin_top = non_editable_post[i].margin_top;
          margin_left = non_editable_post[i].margin_left;
          padding_top = non_editable_post[i].padding_top;
          padding_right = non_editable_post[i].padding_right;
          padding_bottom = non_editable_post[i].padding_bottom;
          padding_left = non_editable_post[i].padding_left;
          lock_state = non_editable_post[i].non_editable_postage_lock;
        }
      }
      setNonEditablePostage(non_editable_post);
      props.parentSetNonEditablePostageDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state
      );
    } else if (textType === "imbarcode_component") {
      let imbarcode = [...imbarcode_component];
      for (let i = 0; i < imbarcode.length; i++) {
        if (imbarcode[i].selected_imbarcode === 1) {
          id = imbarcode[i].id;
          left = imbarcode[i].left;
          top = imbarcode[i].top;
          width = imbarcode[i].width;
          height = imbarcode[i].height;
          title = imbarcode[i].title;
          rotateAngle = imbarcode[i].rotateAngle;
          bgColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;

          imbarcode[i].bgColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;
          rounded_corner = imbarcode[i].rounded_corner;
          margin_top = imbarcode[i].margin_top;
          margin_left = imbarcode[i].margin_left;
          padding_top = imbarcode[i].padding_top;
          padding_right = imbarcode[i].padding_right;
          padding_bottom = imbarcode[i].padding_bottom;
          padding_left = imbarcode[i].padding_left;
          lock_state = imbarcode[i].imbarcode_lock;
        }
      }
      setImbarcode(imbarcode);
      props.parentSetImbarcodeDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state
      );
    } else if (textType === "line_shape") {
      let line_shape_data = [...line_shape];
      for (let i = 0; i < line_shape_data.length; i++) {
        if (line_shape_data[i].selected_line === 1) {
          id = line_shape_data[i].id;
          left = line_shape_data[i].left;
          top = line_shape_data[i].top;
          width = line_shape_data[i].width;
          height = line_shape_data[i].height;
          title = line_shape_data[i].title;
          rotateAngle = line_shape_data[i].rotateAngle;
          backgroundColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;

          line_shape_data[i].backgroundColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;
          rounded_corner = line_shape_data[i].rounded_corner;
          margin_top = line_shape_data[i].margin_top;
          margin_left = line_shape_data[i].margin_left;
          padding_top = line_shape_data[i].padding_top;
          padding_right = line_shape_data[i].padding_right;
          padding_bottom = line_shape_data[i].padding_bottom;
          padding_left = line_shape_data[i].padding_left;
          lock_state = line_shape_data[i].line_lock;
        }
      }
      setLineShape(line_shape_data);
      props.setLineShapeDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        backgroundColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state
      );
    } else {
      let non_editable_text = [...nonEditableTexts];
      for (let i = 0; i < nonEditableTexts.length; i++) {
        if (non_editable_text[i].selected_text === 1) {
          id = non_editable_text[i].id;
          left = non_editable_text[i].left;
          top = non_editable_text[i].top;
          width = non_editable_text[i].width;
          height = non_editable_text[i].height;
          title = non_editable_text[i].title;
          rotateAngle = non_editable_text[i].rotateAngle;
          bgColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;

          non_editable_text[i].bgColor = `rgba(${color.r + "," + color.g + "," + color.b + "," + color.a
            })`;
          rounded_corner = non_editable_text[i].rounded_corner;
          margin_top = non_editable_text[i].margin_top;
          margin_left = non_editable_text[i].margin_left;
          padding_top = non_editable_text[i].padding_top;
          padding_right = non_editable_text[i].padding_right;
          padding_bottom = non_editable_text[i].padding_bottom;
          padding_left = non_editable_text[i].padding_left;
          lock_state = non_editable_text[i].non_editable_text_lock;
          font_min_size = non_editable_text[i].font_min_size;
          font_max_size = non_editable_text[i].font_max_size;
          scalling_class = non_editable_text[i].scalling_class;
          transparency = non_editable_text[i].transparency;
          text_autofit = non_editable_text[i].text_autofit;
        }
      }
      setNonEditableText(non_editable_text);
      props.parentSetNonEditableTextDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state,
        font_min_size,
        font_max_size,
        scalling_class,
        transparency,
        text_autofit,
      );
    }
  }

  function onSelectElement(e, id, type) {
    setTextType(type);
    selectCustomText(id, type);
    if (type === "custom_text" || type === "non_editable_text") {
      setMenuItem({
        ...menu_item,
        text_menu: true,
        image_menu: false,
        background_menu: false,
        component_menu: false,
        ruler_menu: false,
        shape_menu: false,
        back_menu: true,
      });
    } else if (
      type === "non_editable_postage" ||
      type === "imbarcode_component" ||
      type === "data_sequence_component" ||
      type === "data_matrix_component"
    ) {
      setMenuItem({
        ...menu_item,
        text_menu: false,
        image_menu: false,
        background_menu: false,
        component_menu: true,
        ruler_menu: false,
        shape_menu: false,
        back_menu: true,
      });
    } else if (
      type === "custom_image" ||
      type === "logo_uploader_component" ||
      type === "non_editable_accupix" ||
      type === "non_editable_accupix_streetview"
    ) {
      setMenuItem({
        ...menu_item,
        text_menu: false,
        image_menu: true,
        background_menu: false,
        component_menu: false,
        ruler_menu: false,
        shape_menu: false,
        back_menu: true,
      });
    } else if (type === "line_shape") {
      setMenuItem({
        ...menu_item,
        text_menu: false,
        image_menu: false,
        background_menu: false,
        component_menu: false,
        ruler_menu: false,
        shape_menu: true,
        back_menu: true,
      });
    }
  }

  function onOpenEditor(evt, id, type) {
    if (type === "non_editable_text") {
      let non_editable_text = [...nonEditableTexts];
      for (let i = 0; i < nonEditableTexts.length; i++) {
        if (non_editable_text[i].id === id) {
          non_editable_text[i].double_click_text = 1;
        } else {
          non_editable_text[i].double_click_text = 0;
        }
      }
      setNonEditableText(non_editable_text);
    } else {
      let customs_text_data = [...customs];
      for (let i = 0; i < customs.length; i++) {
        if (customs_text_data[i].id === id) {
          customs_text_data[i].double_click = 1;
        } else {
          customs_text_data[i].double_click = 0;
        }
      }
      updateSate(customs_text_data);
    }
  }

  function handleChangePadding(
    padding_top,
    padding_right,
    padding_bottom,
    padding_left
  ) {
    let id;
    let left;
    let top;
    let width;
    let height;
    let title;
    let rotateAngle;
    let backgroundColor;
    let rounded_corner;
    let bgColor;
    let margin_top;
    let margin_left;
    let lock_state;
    let font_min_size;
    let font_max_size;
    let scalling_class;
    let transparency;
    let text_autofit;

    if (textType === "custom_text") {
      let customs_text_data = [...customs];
      for (let i = 0; i < customs.length; i++) {
        if (customs_text_data[i].selected_text_element === 1) {
          id = customs_text_data[i].id;
          left = customs_text_data[i].left;
          top = customs_text_data[i].top;
          width = customs_text_data[i].width;
          height = customs_text_data[i].height;
          title = customs_text_data[i].title;
          rotateAngle = customs_text_data[i].rotateAngle;
          backgroundColor = customs_text_data[i].backgroundColor;
          rounded_corner = customs_text_data[i].rounded_corner;
          margin_top = customs_text_data[i].margin_top;
          margin_left = customs_text_data[i].margin_left;
          lock_state = customs_text_data[i].custom_text_lock;
          font_min_size = customs_text_data[i].font_min_size;
          font_max_size = customs_text_data[i].font_max_size;
          scalling_class = customs_text_data[i].scalling_class;
          transparency = customs_text_data[i].transparency;
          text_autofit = customs_text_data[i].text_autofit;
          customs_text_data[i].padding_top = padding_top;
          customs_text_data[i].padding_right = padding_right;
          customs_text_data[i].padding_bottom = padding_bottom;
          customs_text_data[i].padding_left = padding_left;
        }
      }
      updateSate(customs_text_data);
      props.parentsetCustomDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        backgroundColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state,
        font_min_size,
        font_max_size,
        scalling_class,
        transparency,
        text_autofit,
      );
    } else if (textType === "non_editable_postage") {
      let non_editable_postage = [...nonEditablePostages];
      for (let i = 0; i < nonEditablePostages.length; i++) {
        if (non_editable_postage[i].selected_postage === 1) {
          id = non_editable_postage[i].id;
          left = non_editable_postage[i].left;
          top = non_editable_postage[i].top;
          width = non_editable_postage[i].width;
          height = non_editable_postage[i].height;
          title = non_editable_postage[i].title;
          rotateAngle = non_editable_postage[i].rotateAngle;
          bgColor = non_editable_postage[i].bgColor;
          rounded_corner = non_editable_postage[i].rounded_corner;
          margin_top = non_editable_postage[i].margin_top;
          margin_left = non_editable_postage[i].margin_left;
          lock_state = non_editable_postage[i].non_editable_postage_lock;
          non_editable_postage[i].padding_top = padding_top;
          non_editable_postage[i].padding_right = padding_right;
          non_editable_postage[i].padding_bottom = padding_bottom;
          non_editable_postage[i].padding_left = padding_left;
        }
      }
      setNonEditablePostage(non_editable_postage);
      props.parentSetNonEditablePostageDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state
      );
    } else if (textType === "imbarcode_component") {
      let imbarcode = [...imbarcode_component];
      for (let i = 0; i < imbarcode.length; i++) {
        if (imbarcode[i].selected_imbarcode === 1) {
          id = imbarcode[i].id;
          left = imbarcode[i].left;
          top = imbarcode[i].top;
          width = imbarcode[i].width;
          height = imbarcode[i].height;
          title = imbarcode[i].title;
          rotateAngle = imbarcode[i].rotateAngle;
          bgColor = imbarcode[i].bgColor;
          rounded_corner = imbarcode[i].rounded_corner;
          margin_top = imbarcode[i].margin_top;
          margin_left = imbarcode[i].margin_left;
          lock_state = imbarcode[i].imbarcode_lock;
          imbarcode[i].padding_top = padding_top;
          imbarcode[i].padding_right = padding_right;
          imbarcode[i].padding_bottom = padding_bottom;
          imbarcode[i].padding_left = padding_left;
        }
      }
      setImbarcode(imbarcode);
      props.parentSetImbarcodeDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state
      );
    } else {
      let non_editable_text = [...nonEditableTexts];
      for (let i = 0; i < nonEditableTexts.length; i++) {
        if (non_editable_text[i].selected_text === 1) {
          id = non_editable_text[i].id;
          left = non_editable_text[i].left;
          top = non_editable_text[i].top;
          width = non_editable_text[i].width;
          height = non_editable_text[i].height;
          title = non_editable_text[i].title;
          rotateAngle = non_editable_text[i].rotateAngle;
          bgColor = non_editable_text[i].bgColor;
          rounded_corner = non_editable_text[i].rounded_corner;
          margin_top = non_editable_text[i].margin_top;
          margin_left = non_editable_text[i].margin_left;
          lock_state = non_editable_text[i].non_editable_text_lock;
          font_min_size = non_editable_text[i].font_min_size;
          font_max_size = non_editable_text[i].font_max_size;
          scalling_class = non_editable_text[i].scalling_class;
          transparency = non_editable_text[i].transparency;
          text_autofit = non_editable_text[i].text_autofit;
          non_editable_text[i].padding_top = padding_top;
          non_editable_text[i].padding_right = padding_right;
          non_editable_text[i].padding_bottom = padding_bottom;
          non_editable_text[i].padding_left = padding_left;
        }
      }
      setNonEditableText(non_editable_text);
      props.parentSetNonEditableTextDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        rotateAngle,
        bgColor,
        rounded_corner,
        margin_top,
        margin_left,
        padding_top,
        padding_right,
        padding_bottom,
        padding_left,
        lock_state,
        font_min_size,
        font_max_size,
        scalling_class,
        transparency,
        text_autofit,
      );
    }
  }

  const getAccupixTypeFront = (type_front) => {
    props.parentGetAccupixTypeFront(type_front);
  };

  const getAccupixTypeBack = (type_back) => {
    props.parentGetAccupixTypeBack(type_back);
  };

  const getAccupixStreetviewTypeFront = (type_front) => {
    props.parentGetAccupixStreetviewTypeFront(type_front);
  };

  const getAccupixStreetviewTypeBack = (type_back) => {
    props.parentGetAccupixStreetviewTypeBack(type_back);
  };

  function handleAspectRatioState(aspect_ratio_flag = false) {
    setAspectRatioFlag(aspect_ratio_flag);
  }

  const getAccupixAspectRatioFront = (
    aspect_ratio_front,
    aspect_ratio_flag = false
  ) => {
    props.parentGetAccupixAspectRatioFront(aspect_ratio_front);
    let aspect_width = aspect_ratio_front.split(":")[0];
    let aspect_height = aspect_ratio_front.split(":")[1];
    let id;
    let left;
    let top;
    let width;
    let height;
    let title;
    let rotateAngle;
    let bgColor;
    let rounded_corner;
    let margin_top;
    let margin_left;
    let padding_top;
    let padding_right;
    let padding_bottom;
    let padding_left;

    let non_editable_accupix = [...nonEditableAccupixs];
    for (let i = 0; i < nonEditableAccupixs.length; i++) {
      id = non_editable_accupix[i].id;

      left = non_editable_accupix[i].left;
      top = non_editable_accupix[i].top;
      if (aspect_ratio_flag === true) {
        non_editable_accupix[i].width = 100;
        non_editable_accupix[i].height = 100;
        if (aspect_ratio_front === "1:1") {
          width = non_editable_accupix[i].width * aspect_width;
          height = non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_front === "3:2") {
          width = non_editable_accupix[i].width * aspect_width;
          height = non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_front === "3:1") {
          width = non_editable_accupix[i].width * aspect_width;
          height = non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_front === "4:1") {
          width = non_editable_accupix[i].width * aspect_width;
          height = non_editable_accupix[i].height * aspect_height;
        }
      }
      title = non_editable_accupix[i].title;

      rotateAngle = non_editable_accupix[i].rotateAngle;
      bgColor = non_editable_accupix[i].bgColor;
      rounded_corner = non_editable_accupix[i].rounded_corner;
      margin_top = non_editable_accupix[i].margin_top;
      margin_left = non_editable_accupix[i].margin_left;
      padding_top = non_editable_accupix[i].padding_top;
      padding_right = non_editable_accupix[i].padding_right;
      padding_bottom = non_editable_accupix[i].padding_bottom;
      padding_left = non_editable_accupix[i].padding_left;
      if (aspect_ratio_flag === true) {
        non_editable_accupix[i].width = 100;
        non_editable_accupix[i].height = 100;
        if (aspect_ratio_front === "1:1") {
          non_editable_accupix[i].width =
            non_editable_accupix[i].width * aspect_width;
          non_editable_accupix[i].height =
            non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_front === "3:2") {
          non_editable_accupix[i].width =
            non_editable_accupix[i].width * aspect_width;
          non_editable_accupix[i].height =
            non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_front === "3:1") {
          non_editable_accupix[i].width =
            non_editable_accupix[i].width * aspect_width;
          non_editable_accupix[i].height =
            non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_front === "4:1") {
          non_editable_accupix[i].width =
            non_editable_accupix[i].width * aspect_width;
          non_editable_accupix[i].height =
            non_editable_accupix[i].height * aspect_height;
        }
      }
      non_editable_accupix[i].aspect_ratio_width = aspect_width;
      non_editable_accupix[i].aspect_ratio_height = aspect_height;
    }

    setNonEditableAccupix(non_editable_accupix);
    props.parentSetNonEditableAccupixDesign(
      "",
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      bgColor,
      rounded_corner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_width,
      aspect_height
    );
  };

  const getAccupixStreetviewAspectRatioFront = (
    aspect_ratio_front,
    aspect_ratio_flag = false
  ) => {
    props.parentGetAccupixStreetviewAspectRatioFront(aspect_ratio_front);
    let aspect_width = aspect_ratio_front.split(":")[0];
    let aspect_height = aspect_ratio_front.split(":")[1];
    let id;
    let left;
    let top;
    let width;
    let height;
    let title;
    let rotateAngle;
    let bgColor;
    let rounded_corner;
    let margin_top;
    let margin_left;
    let padding_top;
    let padding_right;
    let padding_bottom;
    let padding_left;

    let non_editable_accupix_streetview = [...nonEditableAccupixStreetview];
    for (let i = 0; i < nonEditableAccupixStreetview.length; i++) {
      id = non_editable_accupix_streetview[i].id;

      left = non_editable_accupix_streetview[i].left;
      top = non_editable_accupix_streetview[i].top;
      if (aspect_ratio_flag === true) {
        non_editable_accupix_streetview[i].width = 100;
        non_editable_accupix_streetview[i].height = 100;
        if (aspect_ratio_front === "1:1") {
          width = non_editable_accupix_streetview[i].width * aspect_width;
          height = non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_front === "3:2") {
          width = non_editable_accupix_streetview[i].width * aspect_width;
          height = non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_front === "3:1") {
          width = non_editable_accupix_streetview[i].width * aspect_width;
          height = non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_front === "4:1") {
          width = non_editable_accupix_streetview[i].width * aspect_width;
          height = non_editable_accupix_streetview[i].height * aspect_height;
        }
      }
      title = non_editable_accupix_streetview[i].title;

      rotateAngle = non_editable_accupix_streetview[i].rotateAngle;
      bgColor = non_editable_accupix_streetview[i].bgColor;
      rounded_corner = non_editable_accupix_streetview[i].rounded_corner;
      margin_top = non_editable_accupix_streetview[i].margin_top;
      margin_left = non_editable_accupix_streetview[i].margin_left;
      padding_top = non_editable_accupix_streetview[i].padding_top;
      padding_right = non_editable_accupix_streetview[i].padding_right;
      padding_bottom = non_editable_accupix_streetview[i].padding_bottom;
      padding_left = non_editable_accupix_streetview[i].padding_left;
      if (aspect_ratio_flag === true) {
        non_editable_accupix_streetview[i].width = 100;
        non_editable_accupix_streetview[i].height = 100;
        if (aspect_ratio_front === "1:1") {
          non_editable_accupix_streetview[i].width =
            non_editable_accupix_streetview[i].width * aspect_width;
          non_editable_accupix_streetview[i].height =
            non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_front === "3:2") {
          non_editable_accupix_streetview[i].width =
            non_editable_accupix_streetview[i].width * aspect_width;
          non_editable_accupix_streetview[i].height =
            non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_front === "3:1") {
          non_editable_accupix_streetview[i].width =
            non_editable_accupix_streetview[i].width * aspect_width;
          non_editable_accupix_streetview[i].height =
            non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_front === "4:1") {
          non_editable_accupix_streetview[i].width =
            non_editable_accupix_streetview[i].width * aspect_width;
          non_editable_accupix_streetview[i].height =
            non_editable_accupix_streetview[i].height * aspect_height;
        }
      }
      non_editable_accupix_streetview[i].aspect_ratio_width = aspect_width;
      non_editable_accupix_streetview[i].aspect_ratio_height = aspect_height;
    }

    setNonEditableAccupixStreetview(non_editable_accupix_streetview);
    props.parentSetNonEditableStreetviewAccupixDesign(
      "",
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      bgColor,
      rounded_corner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_width,
      aspect_height
    );
  };

  const getAccupixAspectRatioBack = (
    aspect_ratio_back,
    aspect_ratio_flag = false
  ) => {
    props.parentGetAccupixAspectRatioBack(aspect_ratio_back);
    let aspect_width = aspect_ratio_back.split(":")[0];
    let aspect_height = aspect_ratio_back.split(":")[1];
    let id;
    let left;
    let top;
    let width;
    let height;
    let title;
    let rotateAngle;
    let bgColor;
    let rounded_corner;
    let margin_top;
    let margin_left;
    let padding_top;
    let padding_right;
    let padding_bottom;
    let padding_left;

    let non_editable_accupix = [...nonEditableAccupixs];
    for (let i = 0; i < nonEditableAccupixs.length; i++) {
      id = non_editable_accupix[i].id;
      left = non_editable_accupix[i].left;
      top = non_editable_accupix[i].top;
      if (aspect_ratio_flag === true) {
        non_editable_accupix[i].width = 100;
        non_editable_accupix[i].height = 100;
        if (aspect_ratio_back === "1:1") {
          width = non_editable_accupix[i].width * aspect_width;
          height = non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_back === "3:2") {
          width = non_editable_accupix[i].width * aspect_width;
          height = non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_back === "3:1") {
          width = non_editable_accupix[i].width * aspect_width;
          height = non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_back === "4:1") {
          width = non_editable_accupix[i].width * aspect_width;
          height = non_editable_accupix[i].height * aspect_height;
        }
      }
      title = non_editable_accupix[i].title;
      rotateAngle = non_editable_accupix[i].rotateAngle;
      bgColor = non_editable_accupix[i].bgColor;
      rounded_corner = non_editable_accupix[i].rounded_corner;
      margin_top = non_editable_accupix[i].margin_top;
      margin_left = non_editable_accupix[i].margin_left;
      padding_top = non_editable_accupix[i].padding_top;
      padding_right = non_editable_accupix[i].padding_right;
      padding_bottom = non_editable_accupix[i].padding_bottom;
      padding_left = non_editable_accupix[i].padding_left;
      if (aspect_ratio_flag === true) {
        non_editable_accupix[i].width = 100;
        non_editable_accupix[i].height = 100;
        if (aspect_ratio_back === "1:1") {
          non_editable_accupix[i].width =
            non_editable_accupix[i].width * aspect_width;
          non_editable_accupix[i].height =
            non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_back === "3:2") {
          non_editable_accupix[i].width =
            non_editable_accupix[i].width * aspect_width;
          non_editable_accupix[i].height =
            non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_back === "3:1") {
          non_editable_accupix[i].width =
            non_editable_accupix[i].width * aspect_width;
          non_editable_accupix[i].height =
            non_editable_accupix[i].height * aspect_height;
        } else if (aspect_ratio_back === "4:1") {
          non_editable_accupix[i].width =
            non_editable_accupix[i].width * aspect_width;
          non_editable_accupix[i].height =
            non_editable_accupix[i].height * aspect_height;
        }
      }
      non_editable_accupix[i].aspect_ratio_width = aspect_width;
      non_editable_accupix[i].aspect_ratio_height = aspect_height;
    }
    setNonEditableAccupix(non_editable_accupix);
    props.parentSetNonEditableAccupixDesign(
      "",
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      bgColor,
      rounded_corner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_width,
      aspect_height
    );
  };

  const getAccupixStreetviewAspectRatioBack = (
    aspect_ratio_back,
    aspect_ratio_flag = false
  ) => {
    props.parentGetAccupixStreetviewAspectRatioBack(aspect_ratio_back);

    let aspect_width = aspect_ratio_back.split(":")[0];
    let aspect_height = aspect_ratio_back.split(":")[1];
    let id;
    let left;
    let top;
    let width;
    let height;
    let title;
    let rotateAngle;
    let bgColor;
    let rounded_corner;
    let margin_top;
    let margin_left;
    let padding_top;
    let padding_right;
    let padding_bottom;
    let padding_left;

    let non_editable_accupix_streetview = [...nonEditableAccupixStreetview];
    for (let i = 0; i < non_editable_accupix_streetview.length; i++) {
      id = non_editable_accupix_streetview[i].id;
      left = non_editable_accupix_streetview[i].left;
      top = non_editable_accupix_streetview[i].top;
      if (aspect_ratio_flag === true) {
        non_editable_accupix_streetview[i].width = 100;
        non_editable_accupix_streetview[i].height = 100;
        if (aspect_ratio_back === "1:1") {
          width = non_editable_accupix_streetview[i].width * aspect_width;
          height = non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_back === "3:2") {
          width = non_editable_accupix_streetview[i].width * aspect_width;
          height = non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_back === "3:1") {
          width = non_editable_accupix_streetview[i].width * aspect_width;
          height = non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_back === "4:1") {
          width = non_editable_accupix_streetview[i].width * aspect_width;
          height = non_editable_accupix_streetview[i].height * aspect_height;
        }
      }
      title = non_editable_accupix_streetview[i].title;
      rotateAngle = non_editable_accupix_streetview[i].rotateAngle;
      bgColor = non_editable_accupix_streetview[i].bgColor;
      rounded_corner = non_editable_accupix_streetview[i].rounded_corner;
      margin_top = non_editable_accupix_streetview[i].margin_top;
      margin_left = non_editable_accupix_streetview[i].margin_left;
      padding_top = non_editable_accupix_streetview[i].padding_top;
      padding_right = non_editable_accupix_streetview[i].padding_right;
      padding_bottom = non_editable_accupix_streetview[i].padding_bottom;
      padding_left = non_editable_accupix_streetview[i].padding_left;
      if (aspect_ratio_flag === true) {
        non_editable_accupix_streetview[i].width = 100;
        non_editable_accupix_streetview[i].height = 100;
        if (aspect_ratio_back === "1:1") {
          non_editable_accupix_streetview[i].width =
            non_editable_accupix_streetview[i].width * aspect_width;
          non_editable_accupix_streetview[i].height =
            non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_back === "3:2") {
          non_editable_accupix_streetview[i].width =
            non_editable_accupix_streetview[i].width * aspect_width;
          non_editable_accupix_streetview[i].height =
            non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_back === "3:1") {
          non_editable_accupix_streetview[i].width =
            non_editable_accupix_streetview[i].width * aspect_width;
          non_editable_accupix_streetview[i].height =
            non_editable_accupix_streetview[i].height * aspect_height;
        } else if (aspect_ratio_back === "4:1") {
          non_editable_accupix_streetview[i].width =
            non_editable_accupix_streetview[i].width * aspect_width;
          non_editable_accupix_streetview[i].height =
            non_editable_accupix_streetview[i].height * aspect_height;
        }
      }
      non_editable_accupix_streetview[i].aspect_ratio_width = aspect_width;
      non_editable_accupix_streetview[i].aspect_ratio_height = aspect_height;
    }
    setNonEditableAccupixStreetview(non_editable_accupix_streetview);
    props.parentSetNonEditableStreetviewAccupixDesign(
      "",
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      bgColor,
      rounded_corner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_width,
      aspect_height
    );
  };

  function addLogoUploader(data) {
    if (logo_uploader.length === 0) {
      new_id = 0;
    } else {
      const nonEditable = logo_uploader.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = nonEditable.id + 1;
    }
    let newelement = {
      id: new_id,
      title: "{{logo}}",
      top: 220,
      left: 240,
      width: 132,
      height: 48,
      rotateAngle: 0,
      selected_logo: 0,
      bgColor: "",
      rounded_corner: 0,
      double_click_text: 0,
      margin_top: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
      non_editable_logo_state: true,
      logo_uploader_lock: false,
    };

    setLogoUploader(logo_uploader.concat(newelement));
    props.parentAddLogoUploader(props.imageType);
  }

  function setLogoUploaderDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left
  ) {
    let selected_logo = 0;
    if (resize === true) {
      selected_logo = 1;
      setTextType("logo_uploader_component");
    }

    let newelement = {
      id: id,
      title: title,
      top: top,
      left: left,
      width: width,
      height: height,
      rotateAngle: rotateAngle,
      bgColor: backgroundColor,
      rounded_corner: roundedCorner,
      selected_logo: selected_logo,
      margin_top: margin_top,
      margin_left: margin_left,
      padding_top: padding_top,
      padding_right: padding_right,
      padding_bottom: padding_bottom,
      padding_left: padding_left,
      non_editable_logo_state: true,
    };

    tem_customs = logo_uploader.filter((c) => c.id !== id);
    setLogoUploader(tem_customs.concat(newelement));

    for (let i = 0; i < logo_uploader.length; i++) {
      if (logo_uploader[i].id === id) {
        logo_uploader[i].left = left;
        logo_uploader[i].top = top;
        logo_uploader[i].width = width;
        logo_uploader[i].height = height;
        logo_uploader[i].title = title;
        logo_uploader[i].rotateAngle = rotateAngle;
        logo_uploader[i].bgColor = backgroundColor;
        logo_uploader[i].rounded_corner = roundedCorner;
        logo_uploader[i].selected_logo = 1;
        logo_uploader[i].margin_top = margin_top;
        logo_uploader[i].margin_left = margin_left;
        logo_uploader[i].padding_top = padding_top;
        logo_uploader[i].padding_right = padding_right;
        logo_uploader[i].padding_bottom = padding_bottom;
        logo_uploader[i].padding_left = padding_left;
      } else {
        logo_uploader[i].selected_logo = 0;
      }
    }
    setLogoUploader(logo_uploader);
    props.parentSetLogoUploaderDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left
    );
  }

  function deleteLogoUploader(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        tem_customs = logo_uploader.filter((c) => c.id !== elementId);
        setLogoUploader(tem_customs);
        props.parentDeleteLogoUploader(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function addDataSequence(data) {
    if (data_sequence_component.length === 0) {
      new_id = 0;
    } else {
      const nonEditable = data_sequence_component.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = nonEditable.id + 1;
    }
    let newelement = {
      id: new_id,
      title: "{{sequence}}",
      top: 25,
      left: props.myProps.is_bleed === "1" ? 671 : 650,
      width: 14,
      height: 5,
      data_sequence_swap: false,
    };

    setDataSequence(data_sequence_component.concat(newelement));
    props.parentAddDataSequence(props.imageType);
  }

  function setDataSequenceDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    data_sequence_swap
  ) {
    let newelement = {
      id: id,
      title: title,
      top: top,
      left: left,
      width: width,
      height: height,
      data_sequence_swap: data_sequence_swap,
    };

    tem_customs = data_sequence_component.filter((c) => c.id !== id);
    setDataSequence(tem_customs.concat(newelement));
    props.parentSetDataSequenceDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      data_sequence_swap
    );
  }

  function deleteDataSequence(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        tem_customs = data_sequence_component.filter((c) => c.id !== elementId);
        setDataSequence(tem_customs);
        props.parentDeleteDataSequence(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function addDataMatrix(data) {
    if (data_matrix_component.length === 0) {
      new_id = 0;
    } else {
      const nonEditable = data_matrix_component.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = nonEditable.id + 1;
    }
    let newelement = {
      id: new_id,
      title: "{{data_matrix}}",
      top: 35,
      left: props.myProps.is_bleed === "1" ? 672 : 651,
      width: 12,
      height: 12,
      data_matrix_swap: false,
    };

    setDataMatrix(data_matrix_component.concat(newelement));
    props.parentAddDataMatrix(props.imageType);
  }

  function setDataMatrixDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    data_matrix_swap
  ) {
    let newelement = {
      id: id,
      title: title,
      top: top,
      left: left,
      width: width,
      height: height,
      data_matrix_swap: data_matrix_swap,
    };

    tem_customs = data_matrix_component.filter((c) => c.id !== id);
    setDataMatrix(tem_customs.concat(newelement));
    props.parentSetDataMatrixDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      data_matrix_swap
    );
  }

  function deleteDataMatrix(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        tem_customs = data_matrix_component.filter((c) => c.id !== elementId);
        setDataMatrix(tem_customs);
        props.parentDeleteDataMatrix(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function deleteDataSequenceMatrix(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        let tem_customs = data_sequence_component.filter(
          (c) => c.id !== elementId
        );
        let tem_customs2 = data_matrix_component.filter(
          (c) => c.id !== elementId
        );

        setDataSequence(tem_customs);
        setDataMatrix(tem_customs2);
        props.parentDeleteDataSequence(elementId, props.imageType);
        props.parentDeleteDataMatrix(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  const handleUpDownMatrix = (
    data_sequence_swap = false,
    data_matrix_swap = false
  ) => {
    let id;
    let title;
    let top;
    let left;
    let width;
    let height;

    let id2;
    let title2;
    let top2;
    let left2;
    let width2;
    let height2;

    let data_sequence = [...data_sequence_component];
    for (let i = 0; i < data_sequence_component.length; i++) {
      id = data_sequence[i].id;
      title = data_sequence[i].title;
      width = data_sequence[i].width;
      height = data_sequence[i].height;
      data_sequence[i].data_sequence_swap = data_sequence_swap;

      if (data_sequence_swap) {
        top = props.myProps.is_bleed === "1" ? 1025 : 1001;
        left = 127;
        data_sequence[i].top = props.myProps.is_bleed === "1" ? 1025 : 1001;
        data_sequence[i].left = 127;
      } else {
        top = 25;
        left = props.myProps.is_bleed === "1" ? 671 : 650;
        data_sequence[i].top = 25;
        data_sequence[i].left = props.myProps.is_bleed === "1" ? 671 : 650;
      }
    }
    setDataSequence(data_sequence);
    setTimeout(() => {
      props.parentSetDataSequenceDesign(
        "",
        id,
        left,
        top,
        width,
        height,
        title,
        props.imageType,
        data_sequence_swap
      );
    }, 500);

    let data_matrix = [...data_matrix_component];
    for (let i = 0; i < data_matrix_component.length; i++) {
      id2 = data_matrix[i].id;
      title2 = data_matrix[i].title;
      width2 = data_matrix[i].width;
      height2 = data_matrix[i].height;
      data_matrix[i].data_matrix_swap = data_matrix_swap;

      if (data_matrix_swap) {
        top2 = props.myProps.is_bleed === "1" ? 1006 : 982;
        left2 = 129;
        data_matrix[i].top = props.myProps.is_bleed === "1" ? 1006 : 982;
        data_matrix[i].left = 129;
      } else {
        top2 = 35;
        left2 = props.myProps.is_bleed === "1" ? 672 : 651;
        data_matrix[i].top = 35;
        data_matrix[i].left = props.myProps.is_bleed === "1" ? 672 : 651;
      }
    }
    setDataMatrix(data_matrix);
    setTimeout(() => {
      props.parentSetDataMatrixDesign(
        "",
        id2,
        left2,
        top2,
        width2,
        height2,
        title2,
        props.imageType,
        data_matrix_swap
      );
    }, 1000);
  };

  function addImbarcode(data) {
    if (imbarcode_component.length === 0) {
      new_id = 0;
    } else {
      const nonEditable = imbarcode_component.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = nonEditable.id + 1;
    }
    let newelement = {
      id: new_id,
      title: "{{imbarcode}}",
      top: 180,
      left: 220,
      width: 300,
      height: 24,
      rotateAngle: 0,
      selected_imbarcode: 0,
      bgColor: "",
      rounded_corner: 0,
      double_click_text: 0,
      margin_top: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
      imbarcode_state: true,
      imbarcode_lock: false,
    };

    setImbarcode(imbarcode_component.concat(newelement));
    props.parentAddImbarcode(props.imageType);
  }

  function setImbarcodeDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state
  ) {
    let selected_imbarcode = 0;
    if (resize === true) {
      selected_imbarcode = 1;
      setTextType("imbarcode_component");
    }

    let imbarcode = imbarcode_component.filter((c) => c.id !== id);
    let temp_element = {};
    temp_element.id = id;
    temp_element.title = title;
    temp_element.top = top;
    temp_element.left = left;
    temp_element.width = width;
    temp_element.height = height;
    temp_element.rotateAngle = rotateAngle;
    temp_element.selected_imbarcode = selected_imbarcode;
    temp_element.bgColor = backgroundColor;
    temp_element.rounded_corner = roundedCorner;
    temp_element.margin_top = margin_top;
    temp_element.margin_left = margin_left;
    temp_element.padding_top = padding_top;
    temp_element.padding_right = padding_right;
    temp_element.padding_bottom = padding_bottom;
    temp_element.padding_left = padding_left;
    temp_element.imbarcode_lock = lock_state;
    imbarcode = imbarcode.concat(temp_element);

    setImbarcode(imbarcode);

    for (let i = 0; i < imbarcode_component.length; i++) {
      if (imbarcode_component[i].id === id) {
        imbarcode_component[i].left = left;
        imbarcode_component[i].top = top;
        imbarcode_component[i].width = width;
        imbarcode_component[i].height = height;
        imbarcode_component[i].title = title;
        imbarcode_component[i].rotateAngle = rotateAngle;
        imbarcode_component[i].bgColor = backgroundColor;
        imbarcode_component[i].rounded_corner = roundedCorner;
        imbarcode_component[i].selected_imbarcode = 1;
        imbarcode_component[i].margin_top = margin_top;
        imbarcode_component[i].margin_left = margin_left;
        imbarcode_component[i].padding_top = padding_top;
        imbarcode_component[i].padding_right = padding_right;
        imbarcode_component[i].padding_bottom = padding_bottom;
        imbarcode_component[i].padding_left = padding_left;
        imbarcode_component[i].imbarcode_lock = lock_state;
      } else {
        imbarcode_component[i].selected_imbarcode = 0;
      }
    }
    setImbarcode(imbarcode_component);
    props.parentSetImbarcodeDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      lock_state
    );
  }

  function deleteImbarcode(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        tem_customs = imbarcode_component.filter((c) => c.id !== elementId);
        setImbarcode(tem_customs);
        props.parentDeleteImbarcode(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  function addLineShape(data) {
    if (line_shape.length === 0) {
      new_id = 0;
    } else {
      const line = line_shape.reduce((prev, current) =>
        +prev.id > +current.id ? prev : current
      );
      new_id = line.id + 1;
    }
    let newelement = {
      id: new_id,
      title: "",
      top: 220,
      left: 220,
      width: 250,
      height: 1,
      rotateAngle: 0,
      selected_line: 0,
      backgroundColor: "#000000",
      rounded_corner: 0,
      double_click: 0,
      margin_top: 0,
      margin_left: 0,
      padding_top: 0,
      padding_right: 0,
      padding_bottom: 0,
      padding_left: 0,
      line_state: true,
      line_lock: false,
    };

    setLineShape(line_shape.concat(newelement));
    props.addLineShape(props.imageType);
  }

  function setLineShapeDesign(
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    rotateAngle,
    backgroundColor,
    roundedCorner,
    resize = false,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state
  ) {
    let selected_line = 0;
    if (resize === true) {
      selected_line = 1;
      setTextType("line_shape");
    }

    let line_shape_data = line_shape.filter((c) => c.id !== id);
    let temp_element = {};
    temp_element.id = id;
    temp_element.title = title;
    temp_element.top = top;
    temp_element.left = left;
    temp_element.width = width;
    temp_element.height = height;
    temp_element.rotateAngle = rotateAngle;
    temp_element.selected_line = selected_line;
    temp_element.backgroundColor = backgroundColor;
    temp_element.rounded_corner = roundedCorner;
    temp_element.margin_top = margin_top;
    temp_element.margin_left = margin_left;
    temp_element.padding_top = padding_top;
    temp_element.padding_right = padding_right;
    temp_element.padding_bottom = padding_bottom;
    temp_element.padding_left = padding_left;
    temp_element.line_lock = lock_state;
    line_shape_data = line_shape_data.concat(temp_element);

    setLineShape(line_shape_data);

    for (let i = 0; i < line_shape.length; i++) {
      if (line_shape[i].id === id) {
        line_shape[i].left = left;
        line_shape[i].top = top;
        line_shape[i].width = width;
        line_shape[i].height = height;
        line_shape[i].title = title;
        line_shape[i].rotateAngle = rotateAngle;
        line_shape[i].backgroundColor = backgroundColor;
        line_shape[i].rounded_corner = roundedCorner;
        line_shape[i].selected_line = 1;
        line_shape[i].margin_top = margin_top;
        line_shape[i].margin_left = margin_left;
        line_shape[i].padding_top = padding_top;
        line_shape[i].padding_right = padding_right;
        line_shape[i].padding_bottom = padding_bottom;
        line_shape[i].padding_left = padding_left;
        line_shape[i].line_lock = lock_state;
      } else {
        line_shape[i].selected_line = 0;
      }
    }
    setLineShape(line_shape);
    props.setLineShapeDesign(
      data,
      id,
      left,
      top,
      width,
      height,
      title,
      props.imageType,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      lock_state
    );
  }

  function deleteLineShape(elementId) {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete?",
      showDenyButton: true,
      //showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `Cancel`,
    }).then((result) => {
      if (result.isConfirmed) {
        tem_customs = line_shape.filter((c) => c.id !== elementId);
        setLineShape(tem_customs);
        props.deleteLineShape(elementId, props.imageType);
        Swal.fire("Deleted!", "", "success");
      }
    });
  }

  const toggleRuler = (isRuler) => {
    let ruler_data = { ...ruler };
    ruler_data.is_ruler = isRuler;
    setRuler(ruler_data);
    props.toggleRuler(props.imageType, isRuler);
  };

  const setHorizontalGuide = (guides) => {
    let ruler_data = { ...ruler };
    ruler_data.horizontal_line = guides;
    setRuler(ruler_data);
    props.setHorizontalGuide(props.imageType, guides);
  };

  const setVerticalGuide = (guides) => {
    let ruler_data = { ...ruler };
    ruler_data.vertical_line = guides;
    setRuler(ruler_data);
    props.setVerticalGuide(props.imageType, guides);
  };

  const handleLockUnlock = (id, lock_state = false, type) => {
    switch (type) {
      case "custom_image_lock":
        let temp_state2 = [...customImages];
        let temp_element2 = { ...temp_state2[id] };
        temp_element2.id = id;
        temp_element2.custom_image_lock = lock_state;
        temp_state2[id] = temp_element2;
        updateImageState(temp_state2);
        props.handleImageLockUnlock(props.imageType, id, lock_state);
        break;
      case "custom_text_lock":
        let el_id;
        let custom_text_lock;
        let customs_text_data = [...customs];
        for (let i = 0; i < customs.length; i++) {
          if (customs_text_data[i].id === id) {
            el_id = customs_text_data[i].id;
            custom_text_lock = lock_state;
            customs_text_data[i].custom_text_lock = lock_state;
          }
        }
        updateSate(customs_text_data);
        props.handleLockUnlock(props.imageType, el_id, custom_text_lock);
        break;
      case "non_editable_text_lock":
        let elm_id;
        let non_editable_text_lock;
        let non_editable_text = [...nonEditableTexts];
        for (let i = 0; i < nonEditableTexts.length; i++) {
          if (non_editable_text[i].id === id) {
            elm_id = non_editable_text[i].id;
            non_editable_text_lock = lock_state;
            non_editable_text[i].non_editable_text_lock = lock_state;
          }
        }
        setNonEditableText(non_editable_text);
        props.handleNonEditableTextLockUnlock(
          props.imageType,
          elm_id,
          non_editable_text_lock
        );
        break;
      case "non_editable_postage_lock":
        let p_id;
        let non_editable_postage_lock;
        let non_editable_postage = [...nonEditablePostages];
        for (let i = 0; i < nonEditablePostages.length; i++) {
          if (non_editable_postage[i].id === id) {
            p_id = non_editable_postage[i].id;
            non_editable_postage_lock = lock_state;
            non_editable_postage[i].non_editable_postage_lock = lock_state;
          }
        }
        setNonEditablePostage(non_editable_postage);
        props.handleNonEditablePostageLockUnlock(
          props.imageType,
          p_id,
          non_editable_postage_lock
        );
        break;
      case "imbarcode_lock":
        let im_id;
        let imbarcode_lock;
        let imbarcode = [...imbarcode_component];
        for (let i = 0; i < imbarcode_component.length; i++) {
          if (imbarcode[i].id === id) {
            im_id = imbarcode[i].id;
            imbarcode_lock = lock_state;
            imbarcode[i].imbarcode_lock = lock_state;
          }
        }
        setImbarcode(imbarcode);
        props.handleImbarcodeLockUnlock(props.imageType, im_id, imbarcode_lock);
        break;
      case "line_lock":
        let line_id;
        let line_lock;
        let line = [...line_shape];
        for (let i = 0; i < line_shape.length; i++) {
          if (line[i].id === id) {
            line_id = line[i].id;
            line_lock = lock_state;
            line[i].line_lock = lock_state;
          }
        }
        setLineShape(line);
        props.handleLineShapeLockUnlock(props.imageType, line_id, line_lock);
        break;
      case "logo_uploader_lock":
        let temp_state6 = [...logo_uploader];
        let temp_element6 = { ...temp_state6[id] };
        temp_element6.id = id;
        temp_element6.logo_uploader_lock = lock_state;
        temp_state6[id] = temp_element6;
        setLogoUploader(temp_state6);
        props.handleLogoLockUnlock(props.imageType, id, lock_state);
        break;
      case "accupix_satellite_lock":
        let temp_state7 = [...nonEditableAccupixs];
        let temp_element7 = { ...temp_state7[id] };
        temp_element7.id = id;
        temp_element7.accupix_satellite_lock = lock_state;
        temp_state7[id] = temp_element7;
        setNonEditableAccupix(temp_state7);
        props.handleAccupixSatelliteLockUnlock(props.imageType, id, lock_state);
        break;
      case "accupix_streetview_lock":
        let temp_state8 = [...nonEditableAccupixStreetview];
        let temp_element8 = { ...temp_state8[id] };
        temp_element8.id = id;
        temp_element8.accupix_streetview_lock = lock_state;
        temp_state8[id] = temp_element8;
        setNonEditableAccupixStreetview(temp_state8);
        props.handleAccupixStreetviewLockUnlock(
          props.imageType,
          id,
          lock_state
        );
        break;
      default:
        break;
    }
  };

  const handleMenu = (type) => {
    switch (type) {
      case "text_menu":
        setMenuItem({
          ...menu_item,
          text_menu: true,
          image_menu: false,
          background_menu: false,
          component_menu: false,
          ruler_menu: false,
          shape_menu: false,
          back_menu: true,
        });
        break;
      case "image_menu":
        setMenuItem({
          ...menu_item,
          text_menu: false,
          image_menu: true,
          background_menu: false,
          component_menu: false,
          ruler_menu: false,
          shape_menu: false,
          back_menu: true,
        });
        break;
      case "background_menu":
        setMenuItem({
          ...menu_item,
          text_menu: false,
          image_menu: false,
          background_menu: true,
          component_menu: false,
          ruler_menu: false,
          shape_menu: false,
          back_menu: true,
        });
        break;
      case "component_menu":
        setMenuItem({
          ...menu_item,
          text_menu: false,
          image_menu: false,
          background_menu: false,
          component_menu: true,
          ruler_menu: false,
          shape_menu: false,
          back_menu: true,
        });
        break;
      case "ruler_menu":
        setMenuItem({
          ...menu_item,
          text_menu: false,
          image_menu: false,
          background_menu: false,
          component_menu: false,
          ruler_menu: true,
          shape_menu: false,
          back_menu: true,
        });
        break;
      case "shape_menu":
        setMenuItem({
          ...menu_item,
          text_menu: false,
          image_menu: false,
          background_menu: false,
          component_menu: false,
          ruler_menu: false,
          shape_menu: true,
          back_menu: true,
        });
        break;
      default:
        setMenuItem({
          ...menu_item,
          text_menu: false,
          image_menu: false,
          background_menu: false,
          component_menu: false,
          ruler_menu: false,
          shape_menu: false,
          back_menu: false,
        });
        break;
    }
  };

  const textCompare = (a, b) => {
    const orderA = a.id;
    const orderB = b.id;

    let comparison = 0;
    if (orderA < orderB) {
      comparison = 1;
    } else if (orderA > orderB) {
      comparison = -1;
    }
    return comparison;
  };

  const handleTextTransparency = (id, transparency = false, type) => {
    switch (type) {
      case "custom_text":
        let el_id;
        let text_transparency;
        let customs_text_data = [...customs];
        for (let i = 0; i < customs.length; i++) {
          if (customs_text_data[i].id === id) {
            el_id = customs_text_data[i].id;
            text_transparency = transparency;
            customs_text_data[i].transparency = transparency;
          }
        }
        updateSate(customs_text_data);
        props.handleCustomTextTransparency(props.imageType, el_id, text_transparency);
        break;
      case "non_editable_text":
        let elm_id;
        let non_editable_text_transparency;
        let non_editable_text = [...nonEditableTexts];
        for (let i = 0; i < nonEditableTexts.length; i++) {
          if (non_editable_text[i].id === id) {
            elm_id = non_editable_text[i].id;
            non_editable_text_transparency = transparency;
            non_editable_text[i].transparency = transparency;
          }
        }
        setNonEditableText(non_editable_text);
        props.handleNonEditableTextTransparency(
          props.imageType,
          elm_id,
          non_editable_text_transparency
        );
        break;
      default:
        break;
    }
  };

  const handleTextFit = (id, text_autofit = false, type) => {
    switch (type) {
      case "custom_text":
        let el_id;
        let text_auto_fit;
        let customs_text_data = [...customs];
        for (let i = 0; i < customs.length; i++) {
          if (customs_text_data[i].id === id) {
            el_id = customs_text_data[i].id;
            text_auto_fit = text_autofit;
            customs_text_data[i].text_autofit = text_autofit;
          }
        }
        updateSate(customs_text_data);
        props.handleCustomTextFit(props.imageType, el_id, text_auto_fit);
        break;
      case "non_editable_text":
        let elm_id;
        let non_editable_text_autofit;
        let non_editable_text = [...nonEditableTexts];
        for (let i = 0; i < nonEditableTexts.length; i++) {
          if (non_editable_text[i].id === id) {
            elm_id = non_editable_text[i].id;
            non_editable_text_autofit = text_autofit;
            non_editable_text[i].text_autofit = text_autofit;
          }
        }
        setNonEditableText(non_editable_text);
        props.handleNonEditableTextFit(
          props.imageType,
          elm_id,
          non_editable_text_autofit
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      {renderStyles()}
      <Button className="btn upload-bg-image" onClick={handleShow}>
        Advance Edit
      </Button>

      <Modal
        isOpen={show}
        size="lg"
        toggle={handleClose}
        backdrop="static"
        keyboard={false}
        className="my-modal modal-template-designer"
      >
        <ModalHeader toggle={handleClose}>Template Designer</ModalHeader>
        <ModalBody>
          <div className="postcarrd-editor_block">
            <div className="postcarrd-editor_box">
              <div className="postcarrd-editor_left-block">
                <div className="editor-left-panel">
                  <div className="tm-inside-panel-block">
                    {isModalLoaded === false ? (
                      <div className="loader_main">
                        <div className="loade_box">
                          <div className="loader"></div>
                          <p>Please Wait...</p>
                        </div>
                      </div>
                    ) : null}

                    <MenuItem
                      handleMenu={handleMenu}
                      back_menu={menu_item.back_menu}
                    />
                  </div>

                  {menu_item.image_menu ? (
                    <div className="tm-inside-panel-block">
                      <LeftImageFields
                        parentCallbackImage={addCustomImage}
                        imageProps={customImages}
                      />
                      <div className="tm-inside-panel-box">
                        {[...customImages]
                          .sort(customImageCompare)
                          .map((customimage) => {
                            return (
                              <ImageComponentLeft
                                imageBox={customimage}
                                getChildImageData={updateCustomImage}
                                getChildImageZIndex={updateImageCustomsZIndex}
                                allCustomImages={customImages}
                                key={customimage.id}
                                id={customimage.id}
                                custom_image_lock={
                                  customimage.custom_image_lock
                                }
                                handleImageLockUnlock={handleLockUnlock}
                                imageType={props.imageType}
                                ParentDeleteImageElement={deleteCustomImage}
                                parentBackGroundColorSection={
                                  handleBackGroundColorSection
                                }
                              />
                            );
                          })}
                      </div>
                    </div>
                  ) : null}
                  {menu_item.image_menu ? (
                    <div className="tm-inside-panel-block">
                      <LeftLogoUploaderFields
                        logo_uploader={logo_uploader}
                        parentAddLogoUploader={addLogoUploader}
                      />
                      <div className="tm-inside-panel-box">
                        {logo_uploader.map((logo_item, index) => {
                          return (
                            <LogoUploaderComponentLeft
                              title={logo_item.title}
                              id={logo_item.id}
                              key={logo_item.id}
                              logo_uploader_lock={logo_item.logo_uploader_lock}
                              handleLogoLockUnlock={handleLockUnlock}
                              parentDeleteLogoUploader={deleteLogoUploader}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : null}

                  {menu_item.text_menu ? (
                    <div className="tm-inside-panel-block">
                      <LeftTextFields
                        parentCallback={addCustom}
                        myProps={customs}
                      />
                      <div className="tm-inside-panel-box">
                        {[...customs].sort(textCompare).map((custom, index) => {
                          return (
                            <TextComponentLeft
                              title={custom.title}
                              id={custom.id}
                              key={custom.id}
                              custom_text_lock={custom.custom_text_lock}
                              transparency={custom.transparency}
                              text_autofit={custom.text_autofit}
                              ParentDeleteCustomElement={deleteCustomElement}
                              ParentSelectCustomText={selectCustomText}
                              handleLockUnlock={handleLockUnlock}
                              handleTextTransparency={handleTextTransparency}
                              handleTextFit={handleTextFit}
                            />
                          );
                        })}
                      </div>
                    </div>
                  ) : null}

                  {menu_item.text_menu ? (
                    <div className="tm-inside-panel-block">
                      <LeftNonEditableTextFields
                        parentNonEditableTextCallback={addNonEditableText}
                        myProps={nonEditableTexts}
                      />
                      <div className="tm-inside-panel-box">
                        {[...nonEditableTexts]
                          .sort(textCompare)
                          .map((nonEditableText, index) => {
                            return (
                              <NonEditableTextComponentLeft
                                title={nonEditableText.title}
                                id={nonEditableText.id}
                                key={nonEditableText.id}
                                non_editable_text_lock={nonEditableText.non_editable_text_lock}
                                transparency={nonEditableText.transparency}
                                text_autofit={nonEditableText.text_autofit}
                                handleNonEditableTextLockUnlock={handleLockUnlock}
                                ParentNonEditableDeleteCustomText={deleteNonEditableText}
                                ParentNonEditableCustomText={selectCustomText}
                                handleTextTransparency={handleTextTransparency}
                                handleTextFit={handleTextFit}
                              />
                            );
                          })}
                      </div>
                    </div>
                  ) : null}

                  {menu_item.component_menu ? (
                    <div className="tm-inside-panel-block">
                      <LeftNonEditablePostageFields
                        parentNonEditablePostageCallback={addNonEditablePostage}
                        myProps={nonEditablePostages}
                      />
                      <div className="tm-inside-panel-box">
                        {[...nonEditablePostages]
                          .sort(textCompare)
                          .map((nonEditablePostage, index) => {
                            return (
                              <NonEditablePostageComponentLeft
                                title={nonEditablePostage.title}
                                id={nonEditablePostage.id}
                                key={nonEditablePostage.id}
                                non_editable_postage_lock={
                                  nonEditablePostage.non_editable_postage_lock
                                }
                                handleNonEditablePostageLockUnlock={
                                  handleLockUnlock
                                }
                                ParentNonEditableDeleteCustomPostage={
                                  deleteNonEditablePostage
                                }
                                ParentNonEditableCustomPostage={
                                  selectCustomText
                                }
                              />
                            );
                          })}
                      </div>
                    </div>
                  ) : null}

                  {menu_item.component_menu ? (
                    <div className="tm-inside-panel-block">
                      <LeftImbarcodeFields
                        parentAddImbarcode={addImbarcode}
                        myProps={imbarcode_component}
                      />
                      <div className="tm-inside-panel-box">
                        {[...imbarcode_component]
                          .sort(textCompare)
                          .map((imbarcode, index) => {
                            return (
                              <ImbarcodeComponentLeft
                                title={imbarcode.title}
                                id={imbarcode.id}
                                key={imbarcode.id}
                                imbarcode_lock={imbarcode.imbarcode_lock}
                                handleImbarcodeLockUnlock={handleLockUnlock}
                                ParentDeleteImbarcode={deleteImbarcode}
                                parentImbarcode={selectCustomText}
                              />
                            );
                          })}
                      </div>
                    </div>
                  ) : null}

                  {menu_item.shape_menu ? (
                    <div className="tm-inside-panel-block">
                      <LeftLineShapeFields addLineShape={addLineShape} />
                      <div className="tm-inside-panel-box">
                        {[...line_shape]
                          .sort(textCompare)
                          .map((line, index) => {
                            return (
                              <LineShapeLeft
                                backgroundColor={line.backgroundColor}
                                id={line.id}
                                key={line.id}
                                line_lock={line.line_lock}
                                handleLineShapeLockUnlock={handleLockUnlock}
                                deleteLineShape={deleteLineShape}
                                parentLineShape={selectCustomText}
                              />
                            );
                          })}
                      </div>
                    </div>
                  ) : null}

                  {width_matrix == 8.5 && height_matrix == 11 ? (
                    <>
                      {menu_item.component_menu ? (
                        <div className="tm-inside-panel-block">
                          <LeftDataMatrixFields
                            parentAddDataSequence={addDataSequence}
                            parentAddDataMatrix={addDataMatrix}
                            data_sequence_component={data_sequence_component}
                            data_matrix_component={data_matrix_component}
                            parentDeleteDataSequenceMatrix={
                              deleteDataSequenceMatrix
                            }
                            handleUpDownMatrix={handleUpDownMatrix}
                          />
                          <div className="tm-inside-panel-box">
                            {data_sequence_component.map(
                              (data_sequence, index) => {
                                return (
                                  <DataSequenceComponentLeft
                                    title={data_sequence.title}
                                    id={data_sequence.id}
                                    key={data_sequence.id}
                                    parentDeleteDataSequence={
                                      deleteDataSequence
                                    }
                                  />
                                );
                              }
                            )}
                            {data_matrix_component.map((data_matrix, index) => {
                              return (
                                <DataMatrixComponentLeft
                                  title={data_matrix.title}
                                  id={data_matrix.id}
                                  key={data_matrix.id}
                                  parentDeleteDataMatrix={deleteDataMatrix}
                                />
                              );
                            })}
                          </div>
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  {menu_item.image_menu ? (
                    <div className="tm-inside-panel-block">
                      <LeftNonEditableAccupixFields
                        parentNonEditableAccupixCallback={addNonEditableAccupix}
                        nonEditableAccupix={nonEditableAccupixs}
                        nonEditableAccupixStreetview={
                          nonEditableAccupixStreetview
                        }
                        accupixImageFlag={accupixImageFlag}
                        ParentAddNonEditableAccupixStreeview={
                          addNonEditableAccupixStreeview
                        }
                      />

                      <div className="tm-inside-panel-box">
                        {nonEditableAccupixs.map(
                          (nonEditableAccupix, index) => {
                            return (
                              <NonEditableAccupixComponentLeft
                                nonEditableAccupixStreetview={
                                  nonEditableAccupixStreetview
                                }
                                nonEditableAccupixs={nonEditableAccupixs}
                                title={nonEditableAccupix.title}
                                id={nonEditableAccupix.id}
                                key={nonEditableAccupix.id}
                                accupix_type_front={
                                  props.myProps.accupix_type_front
                                }
                                accupix_type_back={
                                  props.myProps.accupix_type_back
                                }
                                accupix_type_back_two={
                                  props.myProps.accupix_type_back_two
                                }
                                accupix_aspect_ratio_front={
                                  props.myProps.accupix_aspect_ratio_front
                                }
                                accupix_aspect_ratio_back={
                                  props.myProps.accupix_aspect_ratio_back
                                }
                                accupix_aspect_ratio_back_two={
                                  props.myProps.accupix_aspect_ratio_back_two
                                }
                                accupix_type_front_two={
                                  props.myProps.accupix_type_front_two
                                }
                                accupix_aspect_ratio_front_two={
                                  props.myProps.accupix_aspect_ratio_front_two
                                }
                                accupix_satellite_lock={
                                  nonEditableAccupix.accupix_satellite_lock
                                }
                                imageType={props.imageType}
                                ParentNonEditableDeleteCustomAccupix={
                                  deleteNonEditableAccupix
                                }
                                ParentNonEditableCustomAccupix={
                                  selectCustomText
                                }
                                parentGetAccupixTypeFront={getAccupixTypeFront}
                                parentGetAccupixTypeBack={getAccupixTypeBack}
                                parentGetAccupixAspectRatioFront={
                                  getAccupixAspectRatioFront
                                }
                                parentGetAccupixAspectRatioBack={
                                  getAccupixAspectRatioBack
                                }
                                parentHandleAspectRatioState={
                                  handleAspectRatioState
                                }
                                parentAddNonEditableAccupixOnchange={
                                  addNonEditableAccupixOnchange
                                }
                                parentGetAccupixStreetviewTypeFront={
                                  getAccupixStreetviewTypeFront
                                }
                                parentGetAccupixStreetviewAspectRatioFront={
                                  getAccupixStreetviewAspectRatioFront
                                }
                                parentUpdateNonEditableAccupixZIndex={
                                  updateNonEditableAccupixZIndex
                                }
                                parentUpdateNonEditableAccupixStreetviewZIndex={
                                  updateNonEditableAccupixStreetviewZIndex
                                }
                                parentGetAccupixStreetviewAspectRatioBack={
                                  getAccupixStreetviewAspectRatioBack
                                }
                                parentGetAccupixStreetviewTypeBack={
                                  getAccupixStreetviewTypeBack
                                }
                                handleAccupixSatelliteLockUnlock={
                                  handleLockUnlock
                                }
                                handleAccupixStreetviewLockUnlock={
                                  handleLockUnlock
                                }
                              />
                            );
                          }
                        )}
                      </div>
                      {accupixImageFlag == "streetview" ||
                        nonEditableAccupixStreetview.length > 0 ? (
                        <div className="tm-inside-panel-box">
                          {nonEditableAccupixStreetview.map(
                            (nonEditableAccupix, index) => {
                              return (
                                <NonEditableAccupixComponentLeft
                                  nonEditableAccupixs={nonEditableAccupixs}
                                  nonEditableAccupixStreetview={
                                    nonEditableAccupixStreetview
                                  }
                                  title={nonEditableAccupix.title}
                                  id={nonEditableAccupix.id}
                                  key={nonEditableAccupix.id}
                                  accupix_type_back={
                                    props.myProps.accupix_type_back
                                  }
                                  accupix_type_back_two={
                                    props.myProps.accupix_type_back_two
                                  }
                                  accupix_type_front_two={
                                    props.myProps.accupix_type_front_two
                                  }
                                  accupix_aspect_ratio_front_two={
                                    props.myProps.accupix_aspect_ratio_front_two
                                  }
                                  accupix_aspect_ratio_back={
                                    props.myProps.accupix_aspect_ratio_back
                                  }
                                  accupix_aspect_ratio_back_two={
                                    props.myProps.accupix_aspect_ratio_back_two
                                  }
                                  accupix_streetview_lock={
                                    nonEditableAccupix.accupix_streetview_lock
                                  }
                                  imageType={props.imageType}
                                  parentNonEditableDeleteStreetviewAccupix={
                                    deleteNonEditableStreetviewAccupix
                                  }
                                  parentGetAccupixStreetviewTypeFront={
                                    getAccupixStreetviewTypeFront
                                  }
                                  parentGetAccupixTypeFront={
                                    getAccupixTypeFront
                                  }
                                  parentGetAccupixAspectRatioFront={
                                    getAccupixAspectRatioFront
                                  }
                                  parentGetAccupixStreetviewAspectRatioFront={
                                    getAccupixStreetviewAspectRatioFront
                                  }
                                  parentHandleAspectRatioState={
                                    handleAspectRatioState
                                  }
                                  parentAddNonEditableAccupixOnchange={
                                    addNonEditableAccupixOnchange
                                  }
                                  ParentNonEditableDeleteCustomAccupix={
                                    deleteNonEditableAccupix
                                  }
                                  parentUpdateNonEditableAccupixStreetviewZIndex={
                                    updateNonEditableAccupixStreetviewZIndex
                                  }
                                  parentGetAccupixStreetviewAspectRatioBack={
                                    getAccupixStreetviewAspectRatioBack
                                  }
                                  parentGetAccupixStreetviewTypeBack={
                                    getAccupixStreetviewTypeBack
                                  }
                                  handleAccupixStreetviewLockUnlock={
                                    handleLockUnlock
                                  }
                                />
                              );
                            }
                          )}
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {menu_item.ruler_menu ? (
                    <div className="tm-inside-panel-block">
                      <RulerComponent
                        toggleRuler={toggleRuler}
                        ruler={ruler.is_ruler}
                      />
                    </div>
                  ) : null}

                  {menu_item.background_menu ? (
                    <>
                      <div className="color-picker-block">
                        <div className="color-picker-panel">
                          {props.imageType === "front" &&
                            (customImages[0].img_src === "" ||
                              customImages[0].img_src === null) ? (
                            <TemplateColorPicker
                              imageType={props.imageType}
                              {...props}
                              selectedColor={template_background_color}
                              parentCallBackgroundColorPicker={
                                handleBackGroundColor
                              }
                            />
                          ) : props.imageType === "back" &&
                            (customImages[0].img_src === "" ||
                              customImages[0].img_src === null) ? (
                            <TemplateColorPicker
                              imageType={props.imageType}
                              {...props}
                              selectedColor={template_background_color}
                              parentCallBackgroundColorPicker={
                                handleBackGroundColor
                              }
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="tm-inside-panel-block">
                        <div className="tm-inside-panel-box">
                          {props.imageType === "front"
                            ? [...customImages]
                              .sort(customImageCompare)
                              .map((customimage) => {
                                return (
                                  <BackGroundImage
                                    imageBox={customimage}
                                    getChildImageData={updateCustomImage}
                                    allCustomImages={customImages}
                                    key={customimage.id}
                                    id={customimage.id}
                                    imageType={props.imageType}
                                    ParentDeleteImageElement={
                                      deleteCustomImage
                                    }
                                    parentBackGroundColorSection={
                                      handleBackGroundColorSection
                                    }
                                    handleTransparency={handleTransparency}
                                  />
                                );
                              })
                            : props.imageType === "back"
                              ? [...customImages]
                                .sort(customImageCompare)
                                .map((customimage) => {
                                  return (
                                    <BackGroundImage
                                      imageBox={customimage}
                                      getChildImageData={updateCustomImage}
                                      allCustomImages={customImages}
                                      key={customimage.id}
                                      id={customimage.id}
                                      imageType={props.imageType}
                                      ParentDeleteImageElement={
                                        deleteCustomImage
                                      }
                                      parentBackGroundColorSection={
                                        handleBackGroundColorSection
                                      }
                                      handleTransparency={handleTransparency}
                                    />
                                  );
                                })
                              : null}
                        </div>
                      </div>
                    </>
                  ) : null}

                  {menu_item.text_menu ? (
                    <div className="text-size-block">
                      <div className="text-size-panel">
                        {textType === "custom_text"
                          ? customs.map((custom, index) => {
                            if (
                              custom.selected_text_element === 1 &&
                              !custom.custom_text_lock &&
                              custom.scalling_class !== "" &&
                              menu_item.text_menu
                            ) {
                              return (
                                <TextFontSize
                                  key={"custom_text_size_" + custom.id}
                                  font_min_size={custom.font_min_size}
                                  font_max_size={custom.font_max_size}
                                  handleTextMinMaxSize={handleTextMinMaxSize}
                                />
                              );
                            }
                            return null;
                          })
                          : nonEditableTexts.map((nonEditableText, index) => {
                            if (
                              nonEditableText.selected_text === 1 &&
                              !nonEditableText.non_editable_text_lock &&
                              nonEditableText.scalling_class !== "" &&
                              menu_item.text_menu
                            ) {
                              return (
                                <TextFontSize
                                  myProps={nonEditableTexts}
                                  key={
                                    "non_editable_text_size_" +
                                    nonEditableText.id
                                  }
                                  font_min_size={
                                    nonEditableText.font_min_size
                                  }
                                  font_max_size={
                                    nonEditableText.font_max_size
                                  }
                                  handleTextMinMaxSize={handleTextMinMaxSize}
                                />
                              );
                            }
                            return null;
                          })}
                      </div>
                    </div>
                  ) : null}

                  {menu_item.text_menu ||
                    menu_item.component_menu ||
                    menu_item.shape_menu ? (
                    <div className="color-picker-block">
                      <div className="color-picker-panel">
                        {textType === "custom_text"
                          ? customs.map((custom, index) => {
                            if (
                              custom.selected_text_element === 1 &&
                              !custom.custom_text_lock &&
                              menu_item.text_menu
                            ) {
                              return (
                                <ColorPicker
                                  {...props}
                                  myProps={customs}
                                  key={custom.id}
                                  parentCallbackColorPicker={
                                    handleChangeColor
                                  }
                                  selectedColor={custom.backgroundColor}
                                />
                              );
                            }
                            return null;
                          })
                          : textType === "non_editable_postage"
                            ? nonEditablePostages.map(
                              (nonEditablePostage, index) => {
                                if (
                                  nonEditablePostage.selected_postage === 1 &&
                                  !nonEditablePostage.non_editable_postage_lock &&
                                  menu_item.component_menu
                                ) {
                                  return (
                                    <ColorPicker
                                      {...props}
                                      myProps={nonEditablePostages}
                                      key={
                                        "non_editable_postage_color_" +
                                        nonEditablePostage.id
                                      }
                                      parentCallbackColorPicker={
                                        handleChangeColor
                                      }
                                      selectedColor={nonEditablePostage.bgColor}
                                    />
                                  );
                                }
                                return null;
                              }
                            )
                            : textType === "imbarcode_component"
                              ? imbarcode_component.map((imbarcode, index) => {
                                if (
                                  imbarcode.selected_imbarcode === 1 &&
                                  !imbarcode.imbarcode_lock &&
                                  menu_item.component_menu
                                ) {
                                  return (
                                    <ColorPicker
                                      {...props}
                                      myProps={imbarcode_component}
                                      key={"imbarcode_color_" + imbarcode.id}
                                      parentCallbackColorPicker={
                                        handleChangeColor
                                      }
                                      selectedColor={imbarcode.bgColor}
                                    />
                                  );
                                }
                                return null;
                              })
                              : textType === "line_shape"
                                ? line_shape.map((line, index) => {
                                  if (
                                    line.selected_line === 1 &&
                                    !line.line_lock &&
                                    menu_item.shape_menu
                                  ) {
                                    return (
                                      <ColorPicker
                                        {...props}
                                        shape_menu={menu_item.shape_menu}
                                        myProps={line_shape}
                                        key={"line_color_" + line.id}
                                        parentCallbackColorPicker={
                                          handleChangeColor
                                        }
                                        selectedColor={line.backgroundColor}
                                      />
                                    );
                                  }
                                  return null;
                                })
                                : nonEditableTexts.map((nonEditableText, index) => {
                                  if (
                                    nonEditableText.selected_text === 1 &&
                                    !nonEditableText.non_editable_text_lock &&
                                    menu_item.text_menu
                                  ) {
                                    return (
                                      <ColorPicker
                                        {...props}
                                        myProps={nonEditableTexts}
                                        key={
                                          "non_editable_text_color_" +
                                          nonEditableText.id
                                        }
                                        parentCallbackColorPicker={
                                          handleChangeColor
                                        }
                                        selectedColor={nonEditableText.bgColor}
                                      />
                                    );
                                  }
                                  return null;
                                })}
                      </div>
                    </div>
                  ) : null}

                  {menu_item.text_menu || menu_item.component_menu ? (
                    <div className="rounded-corner-block">
                      <div className="rounded-corner-panel">
                        {textType === "custom_text"
                          ? customs.map((custom, index) => {
                            if (
                              custom.selected_text_element === 1 &&
                              !custom.custom_text_lock &&
                              menu_item.text_menu
                            ) {
                              return (
                                <RoundedCorner
                                  myProps={customs}
                                  key={custom.id}
                                  parentCallbackRoundedCorner={
                                    handleChangeRoundedCorner
                                  }
                                  roundedCorner={custom.rounded_corner}
                                />
                              );
                            }
                            return null;
                          })
                          : textType === "non_editable_postage"
                            ? nonEditablePostages.map(
                              (nonEditablePostage, index) => {
                                if (
                                  nonEditablePostage.selected_postage === 1 &&
                                  !nonEditablePostage.non_editable_postage_lock &&
                                  menu_item.component_menu
                                ) {
                                  return (
                                    <RoundedCorner
                                      myProps={nonEditablePostages}
                                      key={
                                        "non_editable_postage_rounded_corner_" +
                                        nonEditablePostage.id
                                      }
                                      parentCallbackRoundedCorner={
                                        handleChangeRoundedCorner
                                      }
                                      roundedCorner={
                                        nonEditablePostage.rounded_corner
                                      }
                                    />
                                  );
                                }
                                return null;
                              }
                            )
                            : textType === "imbarcode_component"
                              ? imbarcode_component.map((imbarcode, index) => {
                                if (
                                  imbarcode.selected_imbarcode === 1 &&
                                  !imbarcode.imbarcode_lock &&
                                  menu_item.component_menu
                                ) {
                                  return (
                                    <RoundedCorner
                                      myProps={imbarcode_component}
                                      key={
                                        "imbarcode_rounded_corner_" + imbarcode.id
                                      }
                                      parentCallbackRoundedCorner={
                                        handleChangeRoundedCorner
                                      }
                                      roundedCorner={imbarcode.rounded_corner}
                                    />
                                  );
                                }
                                return null;
                              })
                              : nonEditableTexts.map((nonEditableText, index) => {
                                if (
                                  nonEditableText.selected_text === 1 &&
                                  !nonEditableText.non_editable_text_lock &&
                                  menu_item.text_menu
                                ) {
                                  return (
                                    <RoundedCorner
                                      myProps={nonEditableTexts}
                                      key={
                                        "non_editable_text_rounded_corner_" +
                                        nonEditableText.id
                                      }
                                      parentCallbackRoundedCorner={
                                        handleChangeRoundedCorner
                                      }
                                      roundedCorner={
                                        nonEditableText.rounded_corner
                                      }
                                    />
                                  );
                                }
                                return null;
                              })}
                      </div>
                    </div>
                  ) : null}

                  {menu_item.text_menu || menu_item.component_menu ? (
                    <div className="margin-padding-panel-block">
                      <div className="margin-padding-panel">
                        {textType === "custom_text"
                          ? customs.map((custom, index) => {
                            if (
                              custom.selected_text_element === 1 &&
                              !custom.custom_text_lock &&
                              menu_item.text_menu
                            ) {
                              return (
                                <EditorPadding
                                  myProps={customs}
                                  key={custom.id}
                                  padding_top={custom.padding_top}
                                  padding_right={custom.padding_right}
                                  padding_bottom={custom.padding_bottom}
                                  padding_left={custom.padding_left}
                                  parentCallbackPadding={handleChangePadding}
                                />
                              );
                            }
                            return null;
                          })
                          : textType === "non_editable_postage"
                            ? nonEditablePostages.map(
                              (nonEditablePostage, index) => {
                                if (
                                  nonEditablePostage.selected_postage === 1 &&
                                  !nonEditablePostage.non_editable_postage_lock &&
                                  menu_item.component_menu
                                ) {
                                  return (
                                    <EditorPadding
                                      myProps={nonEditablePostages}
                                      key={
                                        "non_editable_post_padding_" +
                                        nonEditablePostage.id
                                      }
                                      padding_top={
                                        nonEditablePostage.padding_top
                                      }
                                      padding_right={
                                        nonEditablePostage.padding_right
                                      }
                                      padding_bottom={
                                        nonEditablePostage.padding_bottom
                                      }
                                      padding_left={
                                        nonEditablePostage.padding_left
                                      }
                                      parentCallbackPadding={
                                        handleChangePadding
                                      }
                                    />
                                  );
                                }
                                return null;
                              }
                            )
                            : textType === "imbarcode_component"
                              ? imbarcode_component.map((imbarcode, index) => {
                                if (
                                  imbarcode.selected_imbarcode === 1 &&
                                  !imbarcode.imbarcode_lock &&
                                  menu_item.component_menu
                                ) {
                                  return (
                                    <EditorPadding
                                      myProps={imbarcode_component}
                                      key={"imbarcode_padding_" + imbarcode.id}
                                      padding_top={imbarcode.padding_top}
                                      padding_right={imbarcode.padding_right}
                                      padding_bottom={imbarcode.padding_bottom}
                                      padding_left={imbarcode.padding_left}
                                      parentCallbackPadding={handleChangePadding}
                                    />
                                  );
                                }
                                return null;
                              })
                              : nonEditableTexts.map((nonEditableText, index) => {
                                if (
                                  nonEditableText.selected_text === 1 &&
                                  !nonEditableText.non_editable_text_lock &&
                                  menu_item.text_menu
                                ) {
                                  return (
                                    <EditorPadding
                                      myProps={nonEditableTexts}
                                      key={
                                        "non_editable_text_padding_" +
                                        nonEditableText.id
                                      }
                                      padding_top={nonEditableText.padding_top}
                                      padding_right={
                                        nonEditableText.padding_right
                                      }
                                      padding_bottom={
                                        nonEditableText.padding_bottom
                                      }
                                      padding_left={nonEditableText.padding_left}
                                      parentCallbackPadding={handleChangePadding}
                                    />
                                  );
                                }
                                return null;
                              })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="postcarrd-editor_right-block">
                <div className="zooms-creen-block">
                  <Button
                    disabled={zoomXY > 1.5 ? true : false}
                    onClick={handleZoomIn}
                  >
                    <AiOutlineZoomIn />
                  </Button>
                  <Button
                    disabled={zoomXY < 0.2 ? true : false}
                    onClick={handleZoomOut}
                  >
                    <AiOutlineZoomOut />
                  </Button>
                  <Button onClick={handleReset}>
                    <BiReset />
                  </Button>
                </div>
                <div className="postcarrd-editor_screen-block">
                  <div
                    className="scroll-box"
                    style={{
                      transform: `scale(${zoomXY})`,
                      width: width_int + "in",
                      height: height_int + "in",
                    }}
                  >
                    <div className="ruler-main-section">
                      {ruler.is_ruler && (
                        <div className="ruler-block">
                          <RulerHorizontal
                            horizontal_line={ruler.horizontal_line}
                            is_bleed={props.myProps.is_bleed}
                            setHorizontalGuide={setHorizontalGuide}
                          />
                          <RulerVertical
                            vertical_line={ruler.vertical_line}
                            is_bleed={props.myProps.is_bleed}
                            setVerticalGuide={setVerticalGuide}
                          />
                        </div>
                      )}

                      <div
                        className={
                          props.myProps.is_bleed === "1"
                            ? "bleed no_bleed"
                            : "bleed"
                        }
                        style={bleed_style}
                      >
                        <span className="bleed-box bleed-top"></span>
                        <span className="bleed-box bleed-right"></span>
                        <span className="bleed-box bleed-button"></span>
                        <span className="bleed-box bleed-left"></span>

                        <span className="margin-box margin-top"></span>
                        <span className="margin-box margin-right"></span>
                        <span className="margin-box margin-button"></span>
                        <span className="margin-box margin-left"></span>

                        <div id="editor_box">
                          <div id="editor" style={editor_style}>
                            <div
                              className="editor-screen_box"
                              id="editor_background_color"
                              style={style_size_designer_screen_box}
                            >
                              {handleImageChange()}
                              <div
                                className="editor-screen-inside"
                                style={editor_screen_inside}
                              >
                                {!loadEditor
                                  ? null
                                  : customs.map((custom) => {
                                    return (
                                      <div
                                        tabIndex="0"
                                        onKeyUp={(event) =>
                                          onDelElement(
                                            event,
                                            custom.id,
                                            "text",
                                            custom.double_click
                                          )
                                        }
                                        onClick={(event) =>
                                          onSelectElement(
                                            event,
                                            custom.id,
                                            "custom_text"
                                          )
                                        }
                                        onDoubleClick={(event) =>
                                          !custom.custom_text_lock
                                            ? onOpenEditor(event, custom.id)
                                            : null
                                        }
                                        id={"editor_block_" + custom.id}
                                        className={
                                          custom.double_click &&
                                            custom.backgroundColor !== ""
                                            ? "txt_comp_box active-editor background-exist"
                                            : custom.double_click
                                              ? "txt_comp_box active-editor"
                                              : custom.backgroundColor !== ""
                                                ? "txt_comp_box background-exist"
                                                : custom.selected_text_element ===
                                                  1 && !custom.custom_text_lock
                                                  ? "txt_comp_box active"
                                                  : "txt_comp_box"
                                        }
                                        key={custom.id}
                                      >
                                        <TextComponent
                                          top={custom.top}
                                          left={custom.left}
                                          width={custom.width}
                                          height={custom.height}
                                          title={custom.title}
                                          rotateAngle={custom.rotateAngle}
                                          backgroundColor={
                                            custom.backgroundColor
                                          }
                                          rounded_corner={
                                            custom.rounded_corner
                                          }
                                          margin_top={custom.margin_top}
                                          margin_left={custom.margin_left}
                                          padding_top={custom.padding_top}
                                          padding_right={custom.padding_right}
                                          padding_bottom={
                                            custom.padding_bottom
                                          }
                                          padding_left={custom.padding_left}
                                          custom_text_lock={
                                            custom.custom_text_lock
                                          }
                                          double_click={custom.double_click}
                                          font_min_size={custom.font_min_size}
                                          font_max_size={custom.font_max_size}
                                          scalling_class={
                                            custom.scalling_class
                                          }
                                          transparency={custom.transparency}
                                          text_autofit={custom.text_autofit}
                                          id={custom.id}
                                          key={custom.id}
                                          parentsetCustomDesign={
                                            setCustomDesign
                                          }
                                          parentEditorData={textEditorData}
                                        />
                                      </div>
                                    );
                                  })}
                                {!loadEditor
                                  ? null
                                  : nonEditableTexts.map((nonEditableText) => {
                                    return (
                                      <div
                                        tabIndex="0"
                                        onKeyUp={(event) =>
                                          onDelElement(
                                            event,
                                            nonEditableText.id,
                                            "non_editable_text",
                                            nonEditableText.double_click_text
                                          )
                                        }
                                        onClick={(event) =>
                                          onSelectElement(
                                            event,
                                            nonEditableText.id,
                                            "non_editable_text"
                                          )
                                        }
                                        onDoubleClick={(event) =>
                                          !nonEditableText.non_editable_text_lock
                                            ? onOpenEditor(
                                              event,
                                              nonEditableText.id,
                                              "non_editable_text"
                                            )
                                            : null
                                        }
                                        id={
                                          "non_editable_editor_block_" +
                                          nonEditableText.id
                                        }
                                        className={
                                          nonEditableText.double_click_text &&
                                            nonEditableText.bgColor !== ""
                                            ? "non_editable_txt_comp_box active-non-editable-editor non-editable-background-exist"
                                            : nonEditableText.double_click_text
                                              ? "non_editable_txt_comp_box active-non-editable-editor"
                                              : nonEditableText.bgColor !== ""
                                                ? "non_editable_txt_comp_box non-editable-background-exist"
                                                : nonEditableText.selected_text ===
                                                  1 &&
                                                  !nonEditableText.non_editable_text_lock
                                                  ? "non_editable_txt_comp_box active"
                                                  : "non_editable_txt_comp_box"
                                        }
                                        key={nonEditableText.id}
                                      >
                                        <NonEditableTextComponent
                                          top={nonEditableText.top}
                                          left={nonEditableText.left}
                                          width={nonEditableText.width}
                                          height={nonEditableText.height}
                                          title={nonEditableText.title}
                                          rotateAngle={
                                            nonEditableText.rotateAngle
                                          }
                                          backgroundColor={
                                            nonEditableText.bgColor
                                          }
                                          rounded_corner={
                                            nonEditableText.rounded_corner
                                          }
                                          margin_top={
                                            nonEditableText.margin_top
                                          }
                                          margin_left={
                                            nonEditableText.margin_left
                                          }
                                          padding_top={
                                            nonEditableText.padding_top
                                          }
                                          padding_right={
                                            nonEditableText.padding_right
                                          }
                                          padding_bottom={
                                            nonEditableText.padding_bottom
                                          }
                                          padding_left={
                                            nonEditableText.padding_left
                                          }
                                          non_editable_text_lock={
                                            nonEditableText.non_editable_text_lock
                                          }
                                          double_click={
                                            nonEditableText.double_click_text
                                          }
                                          font_min_size={
                                            nonEditableText.font_min_size
                                          }
                                          font_max_size={
                                            nonEditableText.font_max_size
                                          }
                                          scalling_class={
                                            nonEditableText.scalling_class
                                          }
                                          transparency={nonEditableText.transparency}
                                          text_autofit={nonEditableText.text_autofit}
                                          id={nonEditableText.id}
                                          key={nonEditableText.id}
                                          parentSetNonEditableTextDesign={
                                            setNonEditableTextDesign
                                          }
                                          parentNonEditableTextEditorData={
                                            nonEditableTextEditorData
                                          }
                                        />
                                      </div>
                                    );
                                  })}
                                {nonEditablePostages.map(
                                  (nonEditablePostage) => {
                                    return (
                                      <div
                                        tabIndex="0"
                                        onKeyUp={(event) =>
                                          onDelElement(
                                            event,
                                            nonEditablePostage.id,
                                            "non_editable_postage",
                                            nonEditablePostage.double_click_text
                                          )
                                        }
                                        onClick={(event) =>
                                          onSelectElement(
                                            event,
                                            nonEditablePostage.id,
                                            "non_editable_postage"
                                          )
                                        }
                                        id={
                                          "non_editable_editor_block_" +
                                          nonEditablePostage.id
                                        }
                                        className={
                                          nonEditablePostage.double_click_text &&
                                            nonEditablePostage.bgColor !== ""
                                            ? "non_editable_txt_comp_box active-non-editable-editor non-editable-background-exist"
                                            : nonEditablePostage.double_click_text
                                              ? "non_editable_txt_comp_box active-non-editable-editor"
                                              : nonEditablePostage.bgColor !== ""
                                                ? "non_editable_txt_comp_box non-editable-background-exist"
                                                : "non_editable_txt_comp_box"
                                        }
                                        key={nonEditablePostage.id}
                                      >
                                        <NonEditablePostageComponent
                                          top={nonEditablePostage.top}
                                          left={nonEditablePostage.left}
                                          width={nonEditablePostage.width}
                                          height={nonEditablePostage.height}
                                          title={nonEditablePostage.title}
                                          rotateAngle={
                                            nonEditablePostage.rotateAngle
                                          }
                                          backgroundColor={
                                            nonEditablePostage.bgColor
                                          }
                                          rounded_corner={
                                            nonEditablePostage.rounded_corner
                                          }
                                          margin_top={
                                            nonEditablePostage.margin_top
                                          }
                                          margin_left={
                                            nonEditablePostage.margin_left
                                          }
                                          padding_top={
                                            nonEditablePostage.padding_top
                                          }
                                          padding_right={
                                            nonEditablePostage.padding_right
                                          }
                                          padding_bottom={
                                            nonEditablePostage.padding_bottom
                                          }
                                          padding_left={
                                            nonEditablePostage.padding_left
                                          }
                                          non_editable_postage_lock={
                                            nonEditablePostage.non_editable_postage_lock
                                          }
                                          id={nonEditablePostage.id}
                                          key={nonEditablePostage.id}
                                          parentSetNonEditablePostageDesign={
                                            setNonEditablePostageDesign
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                )}
                                {nonEditableAccupixs.map(
                                  (nonEditableAccupix) => {
                                    return (
                                      <div
                                        tabIndex="0"
                                        onKeyUp={(event) =>
                                          onDelElement(
                                            event,
                                            nonEditableAccupix.id,
                                            "non_editable_accupix",
                                            nonEditableAccupix.double_click_text
                                          )
                                        }
                                        onClick={(event) =>
                                          onSelectElement(
                                            event,
                                            nonEditableAccupix.id,
                                            "non_editable_accupix"
                                          )
                                        }
                                        id={
                                          "non_editable_editor_block_" +
                                          nonEditableAccupix.id
                                        }
                                        className={
                                          "non_editable_txt_comp_box non_editable_accupix_satellite_comp_box_" +
                                          nonEditableAccupix.z_index
                                        }
                                        key={nonEditableAccupix.id}
                                      >
                                        <NonEditableAccupixComponent
                                          top={nonEditableAccupix.top}
                                          left={nonEditableAccupix.left}
                                          width={nonEditableAccupix.width}
                                          height={nonEditableAccupix.height}
                                          title={nonEditableAccupix.title}
                                          rotateAngle={
                                            nonEditableAccupix.rotateAngle
                                          }
                                          backgroundColor={
                                            nonEditableAccupix.bgColor
                                          }
                                          rounded_corner={
                                            nonEditableAccupix.rounded_corner
                                          }
                                          margin_top={
                                            nonEditableAccupix.margin_top
                                          }
                                          margin_left={
                                            nonEditableAccupix.margin_left
                                          }
                                          padding_top={
                                            nonEditableAccupix.padding_top
                                          }
                                          padding_right={
                                            nonEditableAccupix.padding_right
                                          }
                                          padding_bottom={
                                            nonEditableAccupix.padding_bottom
                                          }
                                          padding_left={
                                            nonEditableAccupix.padding_left
                                          }
                                          aspect_ratio_width={
                                            nonEditableAccupix.aspect_ratio_width
                                          }
                                          aspect_ratio_height={
                                            nonEditableAccupix.aspect_ratio_height
                                          }
                                          z_index={nonEditableAccupix.z_index}
                                          accupix_satellite_lock={
                                            nonEditableAccupix.accupix_satellite_lock
                                          }
                                          aspectRatioFlag={aspectRatioFlag}
                                          id={nonEditableAccupix.id}
                                          key={nonEditableAccupix.id}
                                          parentSetNonEditableAccupixDesign={
                                            setNonEditableAccupixDesign
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                )}
                                {nonEditableAccupixStreetview &&
                                  nonEditableAccupixStreetview.map(
                                    (nonEditableAccupix) => {
                                      return (
                                        <div
                                          tabIndex="0"
                                          onKeyUp={(event) =>
                                            onDelElement(
                                              event,
                                              nonEditableAccupix.id,
                                              "non_editable_accupix_streetview",
                                              nonEditableAccupix.double_click_text
                                            )
                                          }
                                          onClick={(event) =>
                                            onSelectElement(
                                              event,
                                              nonEditableAccupix.id,
                                              "non_editable_accupix_streetview"
                                            )
                                          }
                                          id={
                                            "non_editable_editor_block_" +
                                            nonEditableAccupix.id
                                          }
                                          className={
                                            "non_editable_txt_comp_box non_editable_accupix_streetview_comp_box_" +
                                            nonEditableAccupix.z_index
                                          }
                                          key={nonEditableAccupix.id}
                                        >
                                          <NonEditableAccupixStreetviewComponent
                                            top={nonEditableAccupix.top}
                                            left={nonEditableAccupix.left}
                                            width={nonEditableAccupix.width}
                                            height={nonEditableAccupix.height}
                                            title={nonEditableAccupix.title}
                                            rotateAngle={
                                              nonEditableAccupix.rotateAngle
                                            }
                                            backgroundColor={
                                              nonEditableAccupix.bgColor
                                            }
                                            rounded_corner={
                                              nonEditableAccupix.rounded_corner
                                            }
                                            margin_top={
                                              nonEditableAccupix.margin_top
                                            }
                                            margin_left={
                                              nonEditableAccupix.margin_left
                                            }
                                            padding_top={
                                              nonEditableAccupix.padding_top
                                            }
                                            padding_right={
                                              nonEditableAccupix.padding_right
                                            }
                                            padding_bottom={
                                              nonEditableAccupix.padding_bottom
                                            }
                                            padding_left={
                                              nonEditableAccupix.padding_left
                                            }
                                            aspect_ratio_width={
                                              nonEditableAccupix.aspect_ratio_width
                                            }
                                            aspect_ratio_height={
                                              nonEditableAccupix.aspect_ratio_height
                                            }
                                            z_index={nonEditableAccupix.z_index}
                                            accupix_streetview_lock={
                                              nonEditableAccupix.accupix_streetview_lock
                                            }
                                            aspectRatioFlag={aspectRatioFlag}
                                            id={nonEditableAccupix.id}
                                            key={nonEditableAccupix.id}
                                            parentSetNonEditableAccupixStreetviewDesign={
                                              setNonEditableAccupixStreetviewDesign
                                            }
                                          />
                                        </div>
                                      );
                                    }
                                  )}

                                {customImages.map((customimage, index) => {
                                  if (
                                    typeof customimage.img_src !==
                                    "undefined" &&
                                    customimage.img_src !== "" &&
                                    customimage.id !== 0
                                  ) {
                                    return (
                                      <div
                                        custom-attribute-id={customimage.id}
                                        custom-attribute-name={
                                          customimage.img_box_title
                                        }
                                        className={
                                          "image_com_box image_com_box_zindex_" +
                                          customimage.order
                                        }
                                        key={customimage.id}
                                        tabIndex="0"
                                        onKeyDown={(event) =>
                                          onDelElement(
                                            event,
                                            customimage.id,
                                            "custom_image"
                                          )
                                        }
                                        onClick={(event) =>
                                          onSelectElement(
                                            event,
                                            customimage.id,
                                            "custom_image"
                                          )
                                        }
                                      >
                                        <ImageComponent
                                          key={customimage.id}
                                          modalImageSrc={customimage.img_src}
                                          top={customimage.top}
                                          left={customimage.left}
                                          width={customimage.width}
                                          height={customimage.height}
                                          original_image_width={
                                            customimage.original_image_width
                                          }
                                          original_image_height={
                                            customimage.original_image_height
                                          }
                                          order={customimage.order}
                                          custom_image_lock={
                                            customimage.custom_image_lock
                                          }
                                          id={customimage.id}
                                          parentSetImageDesign={setImageDesign}
                                        />
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                                {logo_uploader.map((logo_data) => {
                                  return (
                                    <div
                                      tabIndex="0"
                                      onKeyDown={(event) =>
                                        onDelElement(
                                          event,
                                          logo_data.id,
                                          "logo_uploader_component"
                                        )
                                      }
                                      onClick={(event) =>
                                        onSelectElement(
                                          event,
                                          logo_data.id,
                                          "logo_uploader_component"
                                        )
                                      }
                                      className="non_editable_box"
                                      key={logo_data.id}
                                    >
                                      <LogoUploaderComponent
                                        top={logo_data.top}
                                        left={logo_data.left}
                                        width={logo_data.width}
                                        height={logo_data.height}
                                        title={logo_data.title}
                                        rotateAngle={logo_data.rotateAngle}
                                        backgroundColor={logo_data.bgColor}
                                        rounded_corner={
                                          logo_data.rounded_corner
                                        }
                                        margin_top={logo_data.margin_top}
                                        margin_left={logo_data.margin_left}
                                        padding_top={logo_data.padding_top}
                                        padding_right={logo_data.padding_right}
                                        padding_bottom={
                                          logo_data.padding_bottom
                                        }
                                        padding_left={logo_data.padding_left}
                                        logo_uploader_lock={
                                          logo_data.logo_uploader_lock
                                        }
                                        id={logo_data.id}
                                        key={logo_data.id}
                                        parentSetLogoUploaderDesign={
                                          setLogoUploaderDesign
                                        }
                                      />
                                    </div>
                                  );
                                })}
                                {width_matrix == 8.5 && height_matrix == 11 ? (
                                  <>
                                    {data_sequence_component.map(
                                      (data_sequence) => {
                                        return (
                                          <div
                                            tabIndex="0"
                                            onKeyDown={(event) =>
                                              onDelElement(
                                                event,
                                                data_sequence.id,
                                                "data_sequence_component"
                                              )
                                            }
                                            onClick={(event) =>
                                              onSelectElement(
                                                event,
                                                data_sequence.id,
                                                "data_sequence_component"
                                              )
                                            }
                                            className="non_editable_box"
                                            key={data_sequence.id}
                                          >
                                            <DataSequenceComponent
                                              top={data_sequence.top}
                                              left={data_sequence.left}
                                              width={data_sequence.width}
                                              height={data_sequence.height}
                                              title={data_sequence.title}
                                              id={data_sequence.id}
                                              key={data_sequence.id}
                                              data_sequence_swap={
                                                data_sequence.data_sequence_swap
                                              }
                                              parentSetDataSequenceDesign={
                                                setDataSequenceDesign
                                              }
                                            />
                                          </div>
                                        );
                                      }
                                    )}

                                    {data_matrix_component.map((data_matrix) => {
                                      return (
                                        <div
                                          tabIndex="0"
                                          onKeyDown={(event) =>
                                            onDelElement(
                                              event,
                                              data_matrix.id,
                                              "data_matrix_component"
                                            )
                                          }
                                          onClick={(event) =>
                                            onSelectElement(
                                              event,
                                              data_matrix.id,
                                              "data_matrix_component"
                                            )
                                          }
                                          className="non_editable_box"
                                          key={data_matrix.id}
                                        >
                                          <DataMatrixComponent
                                            top={data_matrix.top}
                                            left={data_matrix.left}
                                            width={data_matrix.width}
                                            height={data_matrix.height}
                                            title={data_matrix.title}
                                            id={data_matrix.id}
                                            key={data_matrix.id}
                                            data_matrix_swap={
                                              data_matrix.data_matrix_swap
                                            }
                                            parentSetDataMatrixDesign={
                                              setDataMatrixDesign
                                            }
                                          />
                                        </div>
                                      );
                                    })}
                                  </>
                                ) : null}
                                {imbarcode_component.map((imbarcode) => {
                                  return (
                                    <div
                                      tabIndex="0"
                                      onKeyUp={(event) =>
                                        onDelElement(
                                          event,
                                          imbarcode.id,
                                          "imbarcode_component",
                                          imbarcode.double_click_text
                                        )
                                      }
                                      onClick={(event) =>
                                        onSelectElement(
                                          event,
                                          imbarcode.id,
                                          "imbarcode_component"
                                        )
                                      }
                                      id={
                                        "non_editable_editor_block_" +
                                        imbarcode.id
                                      }
                                      className={
                                        imbarcode.double_click_text &&
                                          imbarcode.bgColor !== ""
                                          ? "non_editable_txt_comp_box active-non-editable-editor non-editable-background-exist"
                                          : imbarcode.double_click_text
                                            ? "non_editable_txt_comp_box active-non-editable-editor"
                                            : imbarcode.bgColor !== ""
                                              ? "non_editable_txt_comp_box non-editable-background-exist"
                                              : "non_editable_txt_comp_box"
                                      }
                                      key={imbarcode.id}
                                    >
                                      <ImbarcodeComponent
                                        top={imbarcode.top}
                                        left={imbarcode.left}
                                        width={imbarcode.width}
                                        height={imbarcode.height}
                                        title={imbarcode.title}
                                        rotateAngle={imbarcode.rotateAngle}
                                        backgroundColor={imbarcode.bgColor}
                                        rounded_corner={
                                          imbarcode.rounded_corner
                                        }
                                        margin_top={imbarcode.margin_top}
                                        margin_left={imbarcode.margin_left}
                                        padding_top={imbarcode.padding_top}
                                        padding_right={imbarcode.padding_right}
                                        padding_bottom={
                                          imbarcode.padding_bottom
                                        }
                                        padding_left={imbarcode.padding_left}
                                        imbarcode_lock={
                                          imbarcode.imbarcode_lock
                                        }
                                        id={imbarcode.id}
                                        key={imbarcode.id}
                                        parentSetImbarcodeDesign={
                                          setImbarcodeDesign
                                        }
                                      />
                                    </div>
                                  );
                                })}
                                {line_shape.map((line) => {
                                  return (
                                    <div
                                      tabIndex="0"
                                      onKeyUp={(event) =>
                                        onDelElement(
                                          event,
                                          line.id,
                                          "line_shape",
                                          line.double_click
                                        )
                                      }
                                      onClick={(event) =>
                                        onSelectElement(
                                          event,
                                          line.id,
                                          "line_shape"
                                        )
                                      }
                                      id={
                                        "line_shape_block_" + line.id
                                      }
                                      className={
                                        line.double_click &&
                                          line.backgroundColor !== ""
                                          ? "line_shape_comp_box active-line-shape line-shape-background-exist"
                                          : line.double_click
                                            ? "line_shape_comp_box active-line-shape"
                                            : line.backgroundColor !== ""
                                              ? "line_shape_comp_box line-shape-background-exist"
                                              : "line_shape_comp_box"
                                      }
                                      key={line.id}
                                    >
                                      <LineShapeComponent
                                        top={line.top}
                                        left={line.left}
                                        width={line.width}
                                        height={line.height}
                                        title={line.title}
                                        rotateAngle={line.rotateAngle}
                                        backgroundColor={line.backgroundColor}
                                        rounded_corner={line.rounded_corner}
                                        margin_top={line.margin_top}
                                        margin_left={line.margin_left}
                                        padding_top={line.padding_top}
                                        padding_right={line.padding_right}
                                        padding_bottom={line.padding_bottom}
                                        padding_left={line.padding_left}
                                        line_lock={line.line_lock}
                                        id={line.id}
                                        key={line.id}
                                        setLineShapeDesign={setLineShapeDesign}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="editor_button-block">
                  {typeof props.template_id !== "undefined" ? (
                    <Link
                      to={
                        propsEdit ? "#" : "/edit-template/" + props.template_id
                      }
                      className="btn cancel-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </Link>
                  ) : (
                    <Link
                      to="/template-design"
                      className="btn cancel-btn"
                      onClick={handleClose}
                    >
                      Cancel
                    </Link>
                  )}
                  {typeof props.template_id !== "undefined" ? (
                    <Link
                      to={
                        propsEdit ? "#" : "/edit-template/" + props.template_id
                      }
                      className="btn done-btn"
                      onClick={handleDone}
                    >
                      Done
                    </Link>
                  ) : (
                    <Link
                      to="/template-design"
                      className="btn done-btn"
                      onClick={handleDone}
                    >
                      Done
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default DesignModal;
