import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import ResizableDataSequence from "./ResizableDataSequence";

const DataSequenceComponent = (props) => {
  const [width] = useState(props.width);
  const [height] = useState(props.height);

  function setDataSequenceDesign(htmldata, id, left, top, width, height) {
    props.parentSetDataSequenceDesign(
      htmldata,
      id,
      left,
      top,
      width,
      height,
      props.title
    );
  }

  return (
    <>
      <ResizableDataSequence
        top={props.top}
        left={props.left}
        width={width}
        height={height}
        parentSetDataSequenceDesign={setDataSequenceDesign}
        id={props.id}
        title={props.title}
        data_sequence_swap={props.data_sequence_swap}
      ></ResizableDataSequence>
    </>
  );
};

export default DataSequenceComponent;
