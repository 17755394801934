import React, { useState, useEffect, useLayoutEffect } from "react";
import "./Billing.css";
import SavedPaymentMethod from "./SavedPaymentMethod";
import AddNewPaymentMethod from "./AddNewPaymentMethod";
import ShortPaymentHistory from "./ShortPaymentHistory";
import WalletBalanceBox from "./WalletBalanceBox";
import AutoPaymentBox from "./AutoPaymentBox";
import { Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";
import {
  API_GET_WALLET_BALANCE,
  API_GET_ALL_SAVED_CARD,
  API_GET_AUTO_PAYMENT,
  API_GET_PAYMENT_HISTORY,
} from "../../config/Api";

const PaymentMethods = (props) => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [savedCardList, setSavedCardList] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const [autoPaymentValue, setAutoPaymentValue] = useState({
    auto_payment_id: null,
    funding_threshold: null,
    amount_to_fund: null,
    is_auto_funding: "0",
    payment_info_id: null,
    user: null,
  });

  useEffect(() => {
    console.log(props.getUserInfo());
    getWalletBalance();
    getAllSavedCard();
    getAutoPaymentDetail();
    getShortPaymentDetail();
  }, [walletBalance]);

  const getWalletBalance = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_WALLET_BALANCE}?user_id=${props.getUserInfo().user_id}`,
        true
      )
      .then((res) => {
        setWalletBalance(res.data[0]["wallet_balance"]);
      })
      .catch((error) => {});
  };

  const getAllSavedCard = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_ALL_SAVED_CARD}?user_id=${props.getUserInfo().user_id}`,
        true
      )
      .then((res) => {
        setSavedCardList(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getAutoPaymentDetail = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_AUTO_PAYMENT}/${props.getUserInfo().user_id}/`,
        true
      )
      .then((res) => {
        setAutoPaymentValue(res.data);
      })
      .catch((error) => {});
  };

  const getShortPaymentDetail = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_PAYMENT_HISTORY}?user_id=${
          props.getUserInfo().user_id
        }&limit=6`,
        true
      )
      .then((res) => {
        setPaymentHistory(res.data);
      })
      .catch((error) => {});
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <div className="breadcrumb-block">
            <Breadcrumb>
              <BreadcrumbItem>Billing</BreadcrumbItem>
              <BreadcrumbItem active>Payment Methods</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-credit-card"></i>
            </span>
            Billing
          </h1>
        </div>

        <div className="payment-methods-main-block">
          <Row>
            <Col sm="7" lg="8">
              <div className="payment-methods-block saved-payment-method">
                {loading == true ? (
                  <h4>Loading... </h4>
                ) : (
                  <SavedPaymentMethod
                    {...props}
                    savedCardList={savedCardList}
                    getAllSavedCard={getAllSavedCard}
                  />
                )}
                {/* <SavedPaymentMethod
                    {...props}
                    savedCardList={savedCardList}
                    getAllSavedCard={getAllSavedCard}
                  /> */}
              </div>
              {/* <div className="payment-methods-block add-new-payment-method">
                <AddNewPaymentMethod
                  {...props}
                  getAllSavedCard={getAllSavedCard}
                />
              </div> */}
              <div className="payment-methods-block short-payment-history">
                <ShortPaymentHistory
                  {...props}
                  paymentHistory={paymentHistory}
                />
              </div>
            </Col>
            <Col sm="5" lg="4">
              <div className="payment-methods-right-box wallet-balance-box">
                <WalletBalanceBox
                  {...props}
                  walletBalance={walletBalance}
                  savedCardList={savedCardList}
                  getWalletBalance={getWalletBalance}
                />
              </div>
              <div className="payment-methods-right-box auto-payment-box">
                <AutoPaymentBox
                  {...props}
                  autoPaymentValue={autoPaymentValue}
                  getAutoPaymentDetail={getAutoPaymentDetail}
                  savedCardList={savedCardList}
                />
              </div>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default PaymentMethods;
