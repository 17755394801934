import React from "react";
import "./Subscriptions.css";
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const MailerOptions = (props) => {
  const handleNext = () => {
    props.history.push("/select-template");
    // props.history.push('/admin/select-template')
  };
  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <div className="breadcrumb-block">
            <Breadcrumb>
              <BreadcrumbItem>Subscription</BreadcrumbItem>
              <BreadcrumbItem active>Add Subscription</BreadcrumbItem>
            </Breadcrumb>
          </div>

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-calendar-grid-58"></i>
            </span>
            Mailer Options
          </h1>
        </div>
        <div className="add-subscription-block">
          <div className="row">
            <div className="col-sm-6">
              <Card>
                <CardBody>
                  <Form>
                    <FormGroup>
                      <Label for="templateType">Template Type:</Label>
                      <Input type="select" name="select" id="templateType">
                        <option>LETTER</option>
                        <option>POST CARD</option>
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label for="templateSize">Template Size:</Label>
                      <Input type="select" name="select" id="templateSize">
                        <option>Small: 4.25 x 5.5</option>
                        <option>medium: 5.5 x 8.5</option>
                        <option>Large: 5.5 x 11</option>
                      </Input>
                    </FormGroup>

                    <FormGroup tag="fieldset" className="form-radio-block">
                      <Label>Template Option:</Label>
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="radio1" /> Pre-Defined
                          Template
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input type="radio" name="radio1" /> Blank Template
                        </Label>
                      </FormGroup>
                    </FormGroup>

                    <FormGroup>
                      <Label for="intervel">Intervel:</Label>
                      <Input type="select" name="select" id="intervel">
                        <option>Intervel Name 1</option>
                        <option>Intervel Name 2</option>
                        <option>Intervel Name 3</option>
                        <option>Intervel Name 4</option>
                        <option>Intervel Name 5</option>
                        <option>Intervel Name 6</option>
                        <option>Intervel Name 7</option>
                        <option>Intervel Name 8</option>
                      </Input>
                    </FormGroup>

                    <FormGroup>
                      <Label for="postage">Postage:</Label>
                      <Input type="select" name="select" id="postage">
                        <option>Postage Name 1</option>
                        <option>Postage Name 2</option>
                        <option>Postage Name 3</option>
                        <option>Postage Name 4</option>
                        <option>Postage Name 5</option>
                        <option>Postage Name 6</option>
                        <option>Postage Name 7</option>
                        <option>Postage Name 8</option>
                      </Input>
                    </FormGroup>
                    <div className="submit-button-block">
                      <Button onClick={handleNext}>
                        Next <i className="ni ni-bold-right"></i>
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </div>
            <div className="col-sm-4">
              <div className="print-profile-right-block">Right Sidebar</div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MailerOptions;
