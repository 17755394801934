import React, { useState, useEffect, useMemo } from 'react';
import { BreadCrumb } from "../../config/Components";
import "./Orders.css";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Input,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import "../../assets/css/data-table.css";
import {
    API_CANCEL_BATCH_LISTING,
} from "../../config/Api";
import moment from "moment"

const CancelBatches = (props) => {
    const [cancelBatch, setCancelBatch] = useState([]);
    const [filterText, setFilterText] = useState("");
    const userType = useSelector((store) => store.getUserType);
    const searchParam = [
        "batch_number",
        "category",
        "envelope",
        "ink",
        "paper",
        "postage",
        "quantity",
        "size"
    ];

    useEffect(() => {
        fetchCancelBatch();
    }, []);

    const fetchCancelBatch = () => {
        props.callRequest("GET", API_CANCEL_BATCH_LISTING, true, null).then((res) => {
            setCancelBatch(res.data);
        });
    };

    const dateFormater = (inputValue) => {
        let d = new Date(inputValue * 1000);
        return moment(d).format('MM/DD/YYYY')
        // return (d.getMonth() + 1) + "-" + d.getDate() + "-" + d.getFullYear();
    };

    const columns = [
        {
            name: "Batch ID",
            selector: "batch_number",
            sortable: true,
        },
        {
            name: "Category",
            selector: "category",
            sortable: true,
        },
        {
            name: "Size",
            selector: "size",
            sortable: true,
        },
        {
            name: "Paper Description",
            selector: "paper",
            sortable: true,
        },
        {
            name: "Ink",
            selector: "ink",
            sortable: true,
        },
        {
            name: "Postage",
            selector: "postage",
            sortable: true,
        },
        {
            name: "Envelope Description",
            selector: "envelope",
            sortable: true,
        },
        {
            name: "Quantity",
            selector: "quantity",
            sortable: true,
        },
        {
            name: "Date In",
            selector: "date_in",
            sortable: true,
            cell: (row) => {
                return dateFormater(row.date_in);
            },
        },
        {
            name: "Date Due",
            selector: "due_date",
            sortable: true,
            cell: (row) => {
                return row.due_date == "" || row.due_date == null ? "--/--/--" : dateFormater(row.due_date);
            },
        },
        {
            name: "Status",
            selector: "status",
            sortable: true,
            cell: (row) => (
                <div>
                    {
                        row.status == 0 ? "Ready for production" : row.status == 1 ? "Completed"
                            : row.status == 2 ? "Cancelled" : row.status == 3 ? 'Preview' : 'Deleted'
                    }
                </div>
            ),
        },
        {
            name: userType && userType == 2 ? null : "Actions",
            selector: "actions",
            sortable: false,
            cell: (row) =>
                userType && userType == 2 ? null : (
                    <div className="action-div batch-action">
                        <span className="csv-download-btn">
                            <a title="Download csv" href={row.csv_file} download>
                                <i className="fas fa-file-download"></i>
                            </a>
                        </span>
                        <span className="csv-download-btn">
                            <a title="Download Zip" href={row.vdp_zip_file} download>
                                <i className="fas fa-file-archive"></i>
                            </a>
                        </span>
                    </div>
                ),
        },
    ];

    const subHeaderComponentMemo = useMemo(() => {
        return (
            <FormGroup>
                <Input
                    id="search"
                    type="text"
                    placeholder="Search"
                    aria-label="Search Product"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                    autoComplete="off"
                />
            </FormGroup>
        );
    }, [filterText]);

    return (
        <div>
            <main role="main" className="pt-3 px-4">
                <div className="main-heading-block">
                    <BreadCrumb links={["Order Batching"]} />

                    <h1 className="header-title">
                        <span className="header-icon">
                            <i className="ni ni-cart"></i>
                        </span>
                        Cancel Batches
                    </h1>
                </div>
                <div className="listingtable-main-block batch-preview-table">
                    <Card>
                        <CardBody>
                            <div className="list-filtered-table-block">
                                <DataTable
                                    columns={columns}
                                    data={DataTableSettings.filterItems(
                                        cancelBatch,
                                        searchParam,
                                        filterText
                                    )}
                                    pagination
                                    paginationPerPage={DataTableSettings.paginationPerPage}
                                    paginationRowsPerPageOptions={
                                        DataTableSettings.paginationRowsPerPageOptions
                                    }
                                    //actions={actionsExport}
                                    subHeader
                                    subHeaderComponent={subHeaderComponentMemo}
                                    persistTableHead
                                />
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </main>
        </div>
    );
}

export default CancelBatches;
