import React, { Component } from "react";
import "./assets/css/TemplateDesign.css";
import {
  MdOutlineFontDownloadOff,
} from 'react-icons/md';

export default class LeftNonEditableTextFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      non_editable_texts: props.myProps,
    };
  }

  onTrigger = (event) => {
    this.props.parentNonEditableTextCallback();
    event.preventDefault();
  };

  render() {
    return (
      <div className="template-fill-menu-item">
        <button
          className="menu-item-btn"
          value="Add Custom Text"
          onClick={this.onTrigger}
        >
          <div className='menu-item'>
            <span className='icon'><MdOutlineFontDownloadOff /></span>
            <span className='name'>Add Non Editable Text</span>
          </div>

        </button>
      </div>
    );
  }
}
