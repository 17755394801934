import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import {
    BiShapeSquare,
} from 'react-icons/bi';

const LineShapeLeft = (props) => {
    const [lock, setLock] = useState(props.line_lock);

    useEffect(() => {
        setLock(props.line_lock);
    }, [props.line_lock]);

    const toggleLock = (evt) => {
        setLock(!lock);
        props.handleLineShapeLockUnlock(props.id, !lock, "line_lock");
    };

    return (
        <div className="custom_text_full">
            <p
                onClick={() => props.parentLineShape(props.id, "line_shape")}
            >
                <span className='icon' style={{ color: props.backgroundColor }}><BiShapeSquare /></span>
            </p>
            <button
                style={{ padding: "1px 8px", margin: "2px", fontSize: "16px", background: "transparent" }}
                value={lock}
                onClick={(e) => toggleLock(e)}
            >
                {lock ? (
                    <i style={{ color: "#66707d" }} className="fas fa-lock"></i>
                ) : (
                    <i style={{ color: "#cccccc" }} className="fas fa-unlock"></i>
                )}
            </button>
            <button
                className="btn btn-delete"
                onClick={() => props.deleteLineShape(props.id)}
            >
                Delete
            </button>
        </div>
    );
};

export default LineShapeLeft;