import React, { useState } from "react";
import "./UserForm.css";
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  CardFooter,
} from "reactstrap";
import Swal from "sweetalert2";
import { API_MANUALLY_UPDATE_WALLET } from "../../config/Api";

const AddToWalletManually = (props) => {
  const [addToWalletManually, setAddToWalletManually] = useState({
    user_id: props.userId,
    add_to_wallet: 0,
    amount: "",
  });

  const handleAddToWalletManually = () => {
    if (!addToWalletManually.amount || addToWalletManually.amount < 0) {
      Swal.fire({
        showConfirmButton: true,
        icon: "error",
        title: "Please enter valid amount",
      });
      return false;
    }
    props
      .callRequest("POST", `${API_MANUALLY_UPDATE_WALLET}`, true, {
        user_id: addToWalletManually.user_id,
        amount: addToWalletManually.amount,
        add_to_wallet: addToWalletManually.add_to_wallet,
      })
      .then((res) => {
        let debit_or_credit = addToWalletManually.add_to_wallet
          ? "credited"
          : "debited";
        let prep_status = addToWalletManually.add_to_wallet ? "to" : "from";
        Swal.fire({
          showConfirmButton: false,
          timer: 1500,
          icon: "success",
          title: `$${addToWalletManually.amount} has been ${debit_or_credit} ${prep_status} Wallet`,
        });
        setAddToWalletManually({
          ...addToWalletManually,
          add_to_wallet: 0,
          amount: "",
        });
        props.getWalletBalance();
      })
      .catch((error) => {
        if (
          error.response.data.Error ===
          "debited amount should be less than current wallet balance"
        ) {
          Swal.fire({
            showConfirmButton: true,
            icon: "error",
            title: `debited amount should be less than current wallet balance`,
          });
        } else {
          Swal.fire({
            showConfirmButton: true,
            icon: "error",
            title: `something went wrong`,
          });
        }
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAddToWalletManually({ ...addToWalletManually, [name]: Number(value) });
  };
  return (
    <>
      <div className="payment-methods-box">
        <Card>
          <CardHeader>
            <CardTitle style={{ fontSize: "19px" }} tag="h2">
              Update Wallet Balance Manually
            </CardTitle>
          </CardHeader>
          <CardBody>
            <div className="form-box">
              <Form>
                <FormGroup tag="fieldset">
                  <Label>
                    Enter Amount: $ {"  "}
                    <input
                      className="wallet_amount_style"
                      type="number"
                      name="amount"
                      placeholder="0"
                      value={addToWalletManually.amount}
                      onChange={handleChange}
                    ></input>
                  </Label>
                </FormGroup>
                <FormGroup style={{ display: "flex" }} tag="fieldset">
                  <Label>Action:</Label>
                  {/* <FormGroup check> */}
                  <Label check>
                    <Input
                      type="radio"
                      name="add_to_wallet"
                      value={0}
                      checked={
                        addToWalletManually.add_to_wallet == 0 ? true : false
                      }
                      onChange={handleChange}
                    />
                    Debit
                  </Label>

                  <Label check>
                    <Input
                      type="radio"
                      name="add_to_wallet"
                      value={1}
                      checked={
                        addToWalletManually.add_to_wallet == 1 ? true : false
                      }
                      onChange={handleChange}
                    />
                    Credit
                  </Label>
                </FormGroup>
              </Form>
            </div>{" "}
          </CardBody>
          <CardFooter>
            <div className="add-wallet-button">
              <Button color="success" onClick={handleAddToWalletManually}>
                Update
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default AddToWalletManually;
