import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../config/Components";
import classnames from "classnames";
import "../../assets/css/importTemplate.css";
import "./selectSearch.css";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
} from "reactstrap";
import Swal from "sweetalert2";

import {
  API_USERS,
  API_POST_MAILINGLIST,
  API_GET_USER_PERMISSION,
  API_GET_SALES_REP,
  API_UPLOAD_CAMPAIGN_CSV,
} from "../../config/Api";
import MailingListCrmContacts from "./../CRM/MailingListCrmContacts";
import campaignData from "./campaignData";
import UserFrom from "../Users/UserForm";
import SelectSearch, { fuzzySearch } from "react-select-search";
import SelectUserForm from "../Subscriptions/SelectUserForm";

const CreateMailingList = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [createcampaignModal, setcreatecampaignModal] = useState(false);
  const [createcampaignModal1, setcreatecampaignModal1] = useState(false);
  const [createcampaignModal2, setcreatecampaignModal2] = useState(false);
  const [value, setValue] = useState("");

  const [userList, setUserList] = useState();
  const [mailingListDetail, setMailingListingDetail] = useState({
    uplodedFile: null,
    _name: null,
    user: null,
  });
  const [selectedUserDetail, setSelectedUserDetail] = useState();
  const [savedMailingListData, setSavedMailingListData] = useState();
  const [createUserModal, setCreateUserModal] = useState(false);
  const [permissions, setPermissions] = useState();
  const [salesRep, setSalesRep] = useState([]);

  useEffect(() => {
    getUserList();
    fetchUserRolePermission();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleNext = () => {
    props.history.push("/crm-contacts");
    // props.history.push("/admin/crm-contacts");
  };

  const popupCreateCampaign = () =>
    setcreatecampaignModal(!createcampaignModal);
  const popupCreateCampaign1 = () =>
    setcreatecampaignModal1(!createcampaignModal1);
  const popupCreateCampaign2 = () =>
    setcreatecampaignModal2(!createcampaignModal2);

  const oppupCreateUser = () => setCreateUserModal(!createUserModal);

  const handleNext1 = () => {
    popupCreateCampaign();
    popupCreateCampaign1();
  };

  const handleNext2 = () => {
    popupCreateCampaign1();
    popupCreateCampaign2();
  };

  const upload_csv = (event) => {
    event.preventDefault();
    let campDetail = { ...mailingListDetail };
    campDetail.uplodedFile = event.target.files[0];
    setMailingListingDetail({ ...campDetail });
  };

  const onChangeAction = (event) => {
    let campDetail = {
      ...mailingListDetail,
      [event.target.name]: event.target.value,
    };
    setMailingListingDetail({ ...campDetail });
  };

  const createMailingList = () => {
    if (validateForm() != true) return false;

    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();
    mailingListDetail.uplodedFile &&
      mailingListDetail.uplodedFile.name &&
      formData.append(
        "uplodedFile",
        mailingListDetail.uplodedFile,
        mailingListDetail.uplodedFile.name
      );
    formData.append("mailinglist_name", mailingListDetail.mailinglist_name);
    formData.append("user", mailingListDetail.user);

    props
      .callRequest("POST", API_POST_MAILINGLIST, true, formData)
      .then((res) => {
        Swal.close();
        setSavedMailingListData(res.data);

        // if (res.data && "csv_file" in res.data && res.data.csv_file) {
        //   setSavedMailingListData(res.data); //with CSV
        // } else {
        //   Swal.fire({
        //     position: "top-end",
        //     toast: true,
        //     timerProgressBar: true,
        //     title: "Campaign has been created successfully",
        //     showConfirmButton: false,
        //     timer: 3000,
        //   });
        //   props.history.push("/admin/campaigns");
        // }
      });
  };

  const validateForm = () => {
    let returnVal = true;
    let formEle = {
      mailinglist_name: "Mailinglist name is required",
      uplodedFile: "Please Select a valid CSV file",
      user: "Please select a valid user",
    };

    for (var it in formEle) {
      if (mailingListDetail[it] == null) {
        Swal.fire("Validation!", formEle[it], "error");
        returnVal = false;
        break;
      }
    }

    return returnVal;
  };

  const getUserList = () => {
    props.callRequest("GET", API_USERS, true, null).then((res) => {
      let tempData = [];
      if (res.data.length > 0) {
        res.data.map((item) => {
          tempData.push({ ...item, name: item.username, value: item.user_id });
        });
      }
      setUserList(tempData);
      if (selectedUserDetail && selectedUserDetail.user_id) {
        let userDetail = tempData.filter(
          (ele) => ele.user_id == selectedUserDetail.user_id
        )[0];
        setSelectedUserDetail({ ...userDetail });
      }
    });
  };

  const confirmUser = (e) => {
    if (value && userList) {
      let userDetail = userList.filter((ele) => ele.value == value)[0];
      setSelectedUserDetail({ ...userDetail });
      setMailingListingDetail({ ...mailingListDetail, user: value });
    }
  };

  const changeSelectedUser = () => {
    setSelectedUserDetail(null);
    setMailingListingDetail({
      ...mailingListDetail,
      user: null,
      selectedUser: null,
    });
    setValue();
  };

  const resetSavedMailingList = () => {
    setSavedMailingListData(null);
    setSelectedUserDetail(null);
    setMailingListingDetail({
      uplodedFile: null,
      mailinglist_name: null,
      user: null,
    });
    setcreatecampaignModal(false);
    setcreatecampaignModal1(false);
    setcreatecampaignModal2(false);
    setValue();
  };

  const refreshList = () => {
    getUserList();
    oppupCreateUser();
  };

  const fetchUserRolePermission = () => {
    props
      .callRequest(
        "GET",
        API_GET_USER_PERMISSION + props.getUserInfo().user_id,
        true
      )
      .then((res) => {
        setPermissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editUserPop = () => {
    oppupCreateUser();
  };

  const receiveUserId = (selectUsr) => {
    setSelectedUserDetail({ ...selectUsr });
    setMailingListingDetail({ ...mailingListDetail, user: selectUsr.user_id });
  };

  useEffect(() => {
    fetchSalesRep();
  }, []);

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  const fetchSalesRepName = (id) => {
    const salesRepName = salesRep.filter((item) => {
      return item.user_id == id;
    });
    if (salesRepName.length > 0) {
      return salesRepName[0].name;
    }
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["MailingList"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-money-coins"></i>
            </span>
            Create MailingList
          </h1>
        </div>
        <div className="create-order-main-block">
          {savedMailingListData && (
            <MailingListCrmContacts
              {...props}
              csvData={savedMailingListData}
              resetSavedCampaign={resetSavedMailingList}
            />
          )}

          {!savedMailingListData && (
            <Row>
              <Col sm="8">
                <Form>
                  <div className="create-order-block">
                    <Card>
                      <CardBody>
                        <div className="form-box">
                          <div className="header-title-block">
                            <CardTitle tag="h5">
                              Create New MailingList
                            </CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">
                              Name your MailingList type
                            </CardSubtitle>
                          </div>

                          <FormGroup>
                            <Label for="mailinglist_name">
                              Mailinglist Name:{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              name="mailinglist_name"
                              id="mailinglist_name"
                              autoComplete="off"
                              value={mailingListDetail.mailinglist_name}
                              placeholder="Enter Mailinglist Name"
                              onChange={(e) => onChangeAction(e)}
                            />
                          </FormGroup>
                        </div>

                        <div className="form-box">
                          <div className="form-item upload-map-block">
                            <h4>
                              Upload and Map{" "}
                              <span
                                class="d-inline-block"
                                tabindex="0"
                                data-toggle="tooltip"
                                title="Description of the hovericon to explain something specific that is not currently common knowledge."
                              >
                                tooltip
                              </span>
                              <span className="text-danger">*</span>
                            </h4>
                            <p>
                              Click the button to upload a mailing list(CSV
                              format only)
                            </p>
                            <div className="upload-button-block">
                              <div className="form-group upload-block">
                                <div className="upload-button">
                                  <button className="btn btn-danger">
                                    Upload{" "}
                                    <i className="ni ni-cloud-upload-96"></i>
                                  </button>

                                  <input
                                    type="file"
                                    id="uplodedFile"
                                    name="uplodedFile"
                                    accept=".csv"
                                    className="btn custom-file-input"
                                    onChange={(e) => upload_csv(e)}
                                  ></input>
                                  <span>
                                    {mailingListDetail.uplodedFile &&
                                      mailingListDetail.uplodedFile.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="button-block">
                          <Button
                            type="button"
                            onClick={(e) => createMailingList(e)}
                            color="primary"
                            className="float-right"
                          >
                            Create New MailingList
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Form>
              </Col>
              <Col sm="4">
                <div className="right-panel">
                  <div className="select-user-box">
                    {!selectedUserDetail && (
                      <Card>
                        <CardHeader>Search User </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <SelectUserForm
                              {...props}
                              receiveUserId={receiveUserId}
                              permissions={permissions}
                            />
                            {/* <Label for="selectedUser">Search<span className="text-danger">*</span></Label>
                                                    <SelectSearch search options={userList} value={value} onChange={(de)=>{console.log(de)}}  name="user" placeholder="Select User" />

                                                    <div className="modal-button-block confirm-button">
                                                        <Button type="button" onClick={(e)=>confirmUser(e)} disabled={value ? false:true} color="danger">Confirm</Button>
                                                    </div>

                                                    {permissions && permissions.filter(item=>item.role_name=="User_Create").length>0 &&<><div className="or-block">
                                                        <span>OR</span>
                                                    </div>
                                                    <div className="modal-button-block">
                                                        <Button type="button" color="warning" onClick={oppupCreateUser}>
                                                            Create New User <i className="ni ni-bold-right"></i>
                                                        </Button>
                                                    </div></>} */}
                          </FormGroup>
                        </CardBody>
                      </Card>
                    )}

                    {selectedUserDetail && (
                      <Card>
                        <CardHeader>
                          User
                          <UncontrolledDropdown>
                            <DropdownToggle>
                              <i className="fas fa-ellipsis-v"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => changeSelectedUser()}
                              >
                                Change User
                              </DropdownItem>
                              <DropdownItem onClick={() => editUserPop()}>
                                Edit User Profile
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <div className="details-text-block">
                              <p>
                                <strong>Name:</strong>{" "}
                                {selectedUserDetail &&
                                  selectedUserDetail.username}
                              </p>
                              <p>
                                <strong>Email:</strong>{" "}
                                {selectedUserDetail && selectedUserDetail.email}
                              </p>
                              <p>
                                <strong>Phone:</strong>{" "}
                                {selectedUserDetail && selectedUserDetail.phone}
                              </p>
                              <p>
                                <strong>Direct Marketing Coach 1:</strong>{" "}
                                {selectedUserDetail &&
                                  fetchSalesRepName(
                                    selectedUserDetail.sales_rep_1
                                  )}
                              </p>
                              <p>
                                <strong>Direct Marketing Coach 2:</strong>{" "}
                                {selectedUserDetail &&
                                  fetchSalesRepName(
                                    selectedUserDetail.sales_rep_2
                                  )}
                              </p>
                              <p>
                                <strong>CSR:</strong>{" "}
                                {selectedUserDetail && selectedUserDetail.csr}
                              </p>
                              {/* <p><strong>Affiliate:</strong>&nbsp;{selectedUserDetail && selectedUserDetail.username}</p> */}
                            </div>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {/* Create user */}
          <Modal
            isOpen={createUserModal}
            toggle={oppupCreateUser}
            className="create-campaign-modal"
          >
            <ModalHeader toggle={oppupCreateUser}>
              <div className="search-box">
                <FormGroup>
                  <Label for="listSearch">New User</Label>
                </FormGroup>
              </div>
            </ModalHeader>
            <ModalBody>
              <UserFrom
                {...props}
                isInPopUp={true}
                refreshList={refreshList}
                userId={selectedUserDetail && selectedUserDetail.user_id}
              />
            </ModalBody>
          </Modal>
          {/* End of create user */}
        </div>
      </main>
    </>
  );
};

export default CreateMailingList;
