import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import { API_LOGIN } from "../../config/Api";

import "./Login.css";
import logo from "../../assets/img/logo.png";
import ForgotPassword from "./ForgotPassword";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      remember: false,
      redirect: localStorage.getItem("token") ? true : false,
      auth_err: "",
      btn_disabled: false,
      errors: {},
      modal: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    if (localStorage.getItem("remember") === "true") {
      this.state.remember = true;
      this.state.username = localStorage.getItem("username");
    }
  }
  toggle = () => {
    this.setState({ modal: true });
  };
  closeToggle = () => {
    this.setState({ modal: false });
  };
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      this.validateForm();
    });
  };

  validateForm() {
    let { username, password } = this.state;
    let errors = {};
    let formIsValid = true;

    if (!username) {
      formIsValid = false;
      errors["username"] = "Please provide username";
    }

    if (!password) {
      formIsValid = false;
      errors["password"] = "Please provide password";
    }

    this.setState({
      errors: errors,
    });

    return formIsValid;
  }

  rememberChange = (event) => {
    const input = event.target;
    const value = input.type === "checkbox" ? input.checked : input.value;
    this.setState({ remember: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (!this.validateForm()) {
      return false;
    }
    this.setState({ btn_disabled: true });
    this.props
      .callRequest("POST", API_LOGIN, false, {
        username: this.state.username,
        password: this.state.password,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("username", this.state.username);
        localStorage.setItem("remember", this.state.remember);

        this.setState({ redirect: true, btn_disabled: false });
      })
      .catch((error) => {
        this.setState({
          auth_err: "Invalid username/password",
          btn_disabled: false,
        });
      });
  };

  handleForgotPassword = (event) => {
    this.toggle();
  };

  render() {
    const { username, remember, redirect, auth_err, errors, btn_disabled } =
      this.state;

    if (redirect) {
      this.props.history.push("/dashboard");
    }

    return (
      <div className="login login-main-block">
        <div className="card">
          <div className="card-body">
            <div className="login-box">
              <img
                className="logo mx-auto d-block"
                src={logo}
                alt="Reiprintmail"
              />

              <Form onSubmit={this.handleSubmit}>
                {auth_err && <Alert color="danger">{auth_err}</Alert>}
                <label className="title">Please Sign In</label>
                <FormGroup size="lg">
                  <Input
                    type="text"
                    placeholder="Username"
                    value={username}
                    name="username"
                    autoComplete="off"
                    onChange={this.handleChange}
                  />
                  <label className="red_err">{errors.username}</label>
                </FormGroup>

                <FormGroup size="lg">
                  <Input
                    type="password"
                    placeholder="Password"
                    name="password"
                    onChange={this.handleChange}
                  />
                  <label className="red_err">{errors.password}</label>
                </FormGroup>
                <div className="forgot-password-div">
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={remember}
                        name="remember"
                        id="remember"
                        onChange={this.rememberChange}
                        label="Remember Me"
                      />{" "}
                      Remember me
                    </Label>
                    <FormGroup check></FormGroup>
                  </FormGroup>

                  <FormGroup check>
                    <u
                      onClick={this.handleForgotPassword}
                      className="text-blue-pwd"
                    >
                      Forgot Password
                    </u>
                  </FormGroup>
                  {this.state.modal === true ? (
                    <Modal backdrop="static" isOpen={this.state.modal} toggle={this.closeToggle}>
                      <ForgotPassword
                        {...this.props}
                        toggle={this.closeToggle}
                      />
                    </Modal>
                  ) : null}
                </div>
                <Button
                  block
                  size="lg"
                  color="primary"
                  type="submit"
                  className="mt-4"
                  disabled={btn_disabled}
                >
                  Login
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
