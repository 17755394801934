import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Badge,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { BreadCrumb } from "../../config/Components";
import DataTableSettings from "../../utils/DataTableSettings";
import "../../assets/css/data-table.css";
import {
  API_GET_CAMPAIGN,
  API_DUPLICATE_CAMPAIGN,
  API_CAMPAIGN_STATUS_UPDATE,
} from "../../config/Api";
import Swal from "sweetalert2";

const Campaigns = (props) => {
  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterSelection, setfilterSelection] = useState({
    campaign_type: "",
    campaign_target: "",
  });
  const [rows, setRows] = useState(10);

  const handleNext = () => {
    //props.history.push("/admin/create-campaign");
    props.history.push("/create-campaign");
  };

  const searchParam = [
    "username",
    "campaign_name",
    "campTarget",
    "campType",
    "campaign_file_path",
    //"campaign_type",
    // "campaign_target",
  ];

  useEffect(() => {
    getCampaignList();
  }, []);

  const columns = [
    {
      name: "User Name",
      selector: "username",
      sortable: true,
      filterable: true,
    },
    {
      name: "Campaign Name",
      selector: "campaign_name",
      sortable: true,
      filterable: true,
    },
    {
      name: "Campaign Target",
      selector: "campTarget",
      sortable: true,
      // cell: (item) => {
      //   return (
      //     item &&
      //     item.campaign_target &&
      //     (item.campaign_target == 0
      //       ? "Seller"
      //       : item.campaign_target == 1
      //       ? "Buyer"
      //       : "")
      //   );
      // },
    },
    {
      name: "Campaign Type",
      selector: "campType",
      sortable: true,
      // cell: (item) => {
      //   return (
      //     item &&
      //     item.campaign_type &&
      //     (item.campaign_type == 0
      //       ? "Direct Mail"
      //       : item.campaign_type == 1
      //       ? "Probate"
      //       : item.campaign_type == 2
      //       ? "Micro-Campaign"
      //       : "")
      //   );
      // },
    },
    {
      name: "CSV file",
      selector: "campaign_file_path",
      sortable: false,
      cell: (item) => {
        return item && item.campaign_file_path ? (
          item.campaign_file_path
        ) : (
          <button
            onClick={() => redirectUploadCsv(item.campaign_id)}
            className="btn btn-sm btn-danger "
          >
            Upload CSV&nbsp;<i className="ni ni-cloud-upload-96"></i>
          </button>
        );
      },
    },
    {
      name: "Status",
      selector: "is_active",
      sortable: false,
      cell: (item) => {
        return item.is_active == 0 ? (
          <Badge color="danger">Suspended</Badge>
        ) : (
          <Badge color="success">Continue</Badge>
        );
      },
    },

    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (item) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            <DropdownItem onClick={() => duplicateCampaign(item.campaign_id)}>
              Duplicate Campaign
            </DropdownItem>
            {/* <DropdownItem>Suspend Campaign</DropdownItem>
            <DropdownItem>Continue Campaign</DropdownItem> */}
            {item.is_active == 0 ? (
              <DropdownItem onClick={() => changeCampaign(item.campaign_id, 1)}>
                Continue Campaign
              </DropdownItem>
            ) : (
              <DropdownItem onClick={() => changeCampaign(item.campaign_id, 0)}>
                Suspend Campaign
              </DropdownItem>
            )}
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const duplicateCampaign = (campaignId) => {
    if (campaignId) {
      const formData = new FormData();
      formData.append("campaign_id", campaignId);

      Swal.fire({
        title: "Are you sure?",
        text: "Do you want to duplicate that campaigin ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, confirm!",
      }).then((result) => {
        if (result.isConfirmed) {
          props
            .callRequest("POST", API_DUPLICATE_CAMPAIGN, true, formData)
            .then((res) => {
              Swal.fire({
                position: "top-end",
                toast: true,
                timerProgressBar: true,
                title: "Request has been completed successfully",
                showConfirmButton: false,
                timer: 3000,
              });
              getCampaignList();
            });
        }
      });
    }
  };

  const redirectUploadCsv = (id) => {
    //props.history.push("/admin/create-campaign?campaign_id=" + id);
    props.history.push("/create-campaign?campaign_id=" + id);
  };

  const changeCampaign = (campaignId, status) => {
    if (campaignId) {
      const formData = new FormData();
      formData.append("campaign_id", campaignId);
      formData.append("is_active", status);

      Swal.fire({
        title: "Are you sure?",
        text:
          "You want to " + (status == 0 ? "Suspend" : "Continue") + "  it ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, confirm!",
      }).then((result) => {
        if (result.isConfirmed) {
          props
            .callRequest(
              "PUT",
              API_CAMPAIGN_STATUS_UPDATE + campaignId + "/",
              true,
              formData
            )
            .then((res) => {
              Swal.fire({
                position: "top-end",
                toast: true,
                timerProgressBar: true,
                title: "Status has been update successfully",
                showConfirmButton: false,
                timer: 3000,
              });
              getCampaignList();
            });
        }
      });
    }
  };

  const export_column = [
    "username",
    "campaign_name",
    "campTarget",
    "campType",
    "campaign_file_path",
  ];

  const campaignFilterSlection = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setfilterSelection({ ...filterSelection, [name]: value });
  };

  useEffect(() => {
    getCampaignList();
  }, [filterSelection]);

  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <Row>
        <Col sm={4}>
          <FormGroup>
            <Input
              id="search"
              type="text"
              autoComplete="off"
              placeholder="Search"
              aria-label="Search Product"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
          </FormGroup>
        </Col>

        <Col sm={4}>
          <FormGroup>
            <Input
              type="select"
              name="campaign_type"
              id="campaign_type"
              autoComplete="off"
              onChange={(e) => campaignFilterSlection(e)}
            >
              <option value="">Select Campaign Type</option>
              <option value="0">Direct Mail</option>
              <option value="1">Probate</option>
              <option value="2">Micro-Campaigns</option>
            </Input>
          </FormGroup>
        </Col>

        <Col sm={4}>
          <FormGroup>
            <Input
              type="select"
              name="campaign_target"
              id="campaign_target"
              autoComplete="off"
              onChange={(e) => campaignFilterSlection(e)}
            >
              <option value="">Select Campaign Target</option>
              <option value="0">Seller</option>
              <option value="1">Buyer</option>
            </Input>
          </FormGroup>
        </Col>
      </Row>
    );
  }, [
    filterText,
    filterSelection.campaign_type,
    filterSelection.campaign_target,
  ]);

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      <Button color="primary" onClick={handleNext}>
        <i className="ni ni-fat-add"></i> Add Campaign
      </Button>
      {/* <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="bi-filter"></i> Filter
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem>Filter Name 1</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}
      <UncontrolledDropdown disabled={products.length > 0 ? false : true}>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExportView(e.target.value)}>
            Export View
          </DropdownItem>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export All
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {/* <Button
        disabled={products.length > 0 ? false : true}
        onClick={(e) => onExport(e.target.value)}
      >
        <i className="ni ni-ungroup"></i> Export
      </Button> */}
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            products,
            export_column,
            props.generateUniqueId()
          )
        }
        onExportView={() =>
          DataTableSettings.downloadCSV(
            products.slice(0, rows),
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [props, products, rows]
  );

  const getCampaignList = () => {
    let APIEndPoint = API_GET_CAMPAIGN; //+"?campaign_type="+filterSelection.campaign_type+"&campaign_target="+filterSelection.campaign_target;
    if (
      filterSelection.campaign_type != "" &&
      filterSelection.campaign_target != ""
    ) {
      APIEndPoint +=
        "?campaign_type=" +
        filterSelection.campaign_type +
        "&campaign_target=" +
        filterSelection.campaign_target;
    } else if (filterSelection.campaign_type != "") {
      APIEndPoint += "?campaign_type=" + filterSelection.campaign_type;
    } else if (filterSelection.campaign_target != "") {
      APIEndPoint += "?campaign_target=" + filterSelection.campaign_target;
    }

    props.callRequest("GET", APIEndPoint, true, null).then((res) => {
      let items = [];

      //  res.data.map((item) => {
      //    item.status = item.is_active == "1" ? "Active" : " Inacti";
      //    item.subType = item.subscription_type === "0" ? "Probate" : "Micro Campaign";
      //    item.userName = users.filter((usr) => {
      //      if (usr.user_id === item.user) {
      //        return usr.username;
      //      } else {
      //        return "";
      //      }
      //    })[0].username;
      //    item.formattedDate = dateFormater(item.added_date);
      //    item.userEmail = users.filter((usr) => {
      //      if (usr.user_id === item.user) {
      //        return usr.email;
      //      } else {
      //        return "";
      //      }
      //    })[0].email;
      //    allRecords.push(item);
      //  });

      res.data.map((item) => {
        ((item.status == 2 && item.campaign_file_path != "") ||
          item.campaign_file_path == "") &&
          items.push({
            ...item,
            username: item && item.user && item.user.username,
            campTarget:
              item &&
              item.campaign_target &&
              (item.campaign_target == 0
                ? "Seller"
                : item.campaign_target == 1
                ? "Buyer"
                : ""),
            campType:
              item &&
              item.campaign_type &&
              (item.campaign_type == 0
                ? "Direct Mail"
                : item.campaign_type == 1
                ? "Probate"
                : item.campaign_type == 2
                ? "Micro-Campaign"
                : ""),
          });
      });
      setProducts(items);
    });
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Campaigns"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-money-coins"></i>
            </span>
            Campaigns
          </h1>
        </div>

        <div className="listingtable-main-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                <DataTable
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    products,
                    searchParam,
                    filterText
                  )}
                  pagination
                  onChangeRowsPerPage={changeRowPerPage}
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  actions={actionsExport}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default Campaigns;
