import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { API_ADD_CREDIT_CARD } from "../../config/Api";
import Swal from "sweetalert2";

const CCForm = (props) => {
  var callCount = 0;
  const [appAPICall, setAppAPICall] = useState(false);
  const [IframeUrl, setIframeUrl] = useState(props.IframeUrl);
  const [showLoading, setShowLoading] = useState(false);
  

  useEffect(() => {
    
      window.addEventListener("message", receiveMessage, true);
      return ()=>{
        window.removeEventListener("message", receiveMessage, true);
      }

  }, []);

  const receiveMessage = (event) => {
    try {
      event.stopPropagation()
      addCCCard(JSON.parse(event.data));
     
    } catch (error) {
      console.log(error);
    }
  };

  const addCCCard = (trnData) => {
    console.log("-------");
    if (appAPICall == true) return 0;
    setShowLoading(true);
    let formRequest = {
      user: props.userId ? props.userId : props.getUserInfo().user_id,
      nameoncard: trnData.account_holder_name,
      company_name:
        props.formData !== undefined ? props.formData.company_name : "",
      billing_address: trnData.billing_address,
      billing_city:
        trnData.billing_city == ""
          ? props.formData.billing_city
          : trnData.billing_city,
      billing_state:
        trnData.billing_state == ""
          ? props.formData.billing_state
          : trnData.billing_state,
      zip_code: trnData.billing_zip,
      phone_num: trnData.billing_phone == "" ? "0" : trnData.billing_phone,
      token: trnData.id,
      payment_method: trnData.payment_method,
      ach_sec_code: trnData.ach_sec_code == null ? "" : trnData.ach_sec_code,
      cardType: trnData.account_type,
      tran_no: trnData.account_vault_api_id,
      expirationDate: trnData.exp_date !== null ? trnData.exp_date : "",
      date_added: trnData.modified_ts,
      maskedNumber: trnData.last_four,
    };
    props
      .callRequest("POST", API_ADD_CREDIT_CARD, true, formRequest)
      .then((res) => {
        setAppAPICall(true);
        window.removeEventListener("message", receiveMessage, true);
        props.paymentToggle();
        props.getAllSavedCard(res.data);
        setShowLoading(false);
        Swal.fire({
          icon: "success",
          text: "Card has been added successfully.",
        });

        props.paymentToggle();
      })
      .catch((error) => {
        callCount=0
        window.removeEventListener("message", receiveMessage, true);
        setAppAPICall(false);
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Error !",
          text: "Something went wrong, Please try again later",
        });
        setShowLoading(false);
        props.paymentToggle();
      });
  };

  return (
    <>
      <div className="add-card-popup-form" style={{ textAlign: "center" }}>
        {
          <iframe
            id="cc_iframe"
            name="cc_iframe"
            src={IframeUrl}
            style={{
              height: props.clickType == "ach" ? "575px" : "468px",
              width: props.clickType == "ach" ? "75%" : "auto",
            }}
          />
        }

        {showLoading && (
          <>
            <div style={{ width: "100%", textAlign: "center" }}>
              Wait we are processing ....
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default CCForm;
