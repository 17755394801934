import React, { Fragment, useEffect, useState } from "react";
import ResizableRect from "react-resizable-rotatable-draggable";

const ResizableImbarcode = (props) => {
  const [width, setWidth] = useState(props.width);
  const [height, setHeight] = useState(props.height);
  const [top, setTop] = useState(props.top);
  const [left, setLeft] = useState(props.left);
  const [rotateAngle, setRotateAngle] = useState(props.rotateAngle);
  const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
  const [roundedCorner, setRoundedCorner] = useState(props.roundedCorner);
  const [margin_top, setMarginTop] = useState(props.margin_top);
  const [margin_left, setMarginLeft] = useState(props.margin_left);
  const [padding_top, setPaddingTop] = useState(props.padding_top);
  const [padding_right, setPaddingRight] = useState(props.padding_right);
  const [padding_bottom, setPaddingBottom] = useState(props.padding_bottom);
  const [padding_left, setPaddingLeft] = useState(props.padding_left);
  const [imbarcode_lock, setImbarcodeLock] = useState(props.imbarcode_lock);
  const [id] = useState(props.id);
  let topy;
  let leftx;

  useEffect(() => {
    setBackgroundColor(props.backgroundColor);
    setRoundedCorner(props.roundedCorner);
    setMarginTop(props.margin_top);
    setMarginLeft(props.margin_left);
    setPaddingTop(props.padding_top);
    setPaddingRight(props.padding_right);
    setPaddingBottom(props.padding_bottom);
    setPaddingLeft(props.padding_left);
    setImbarcodeLock(props.imbarcode_lock);
  }, [
    props.backgroundColor,
    props.roundedCorner,
    props.margin_top,
    props.margin_left,
    props.padding_top,
    props.padding_right,
    props.padding_bottom,
    props.padding_left,
    props.imbarcode_lock
  ]);

  const contentStyle = {
    top,
    left,
    width,
    height,
    position: "absolute",
    transform: `rotate(${rotateAngle}deg)`,
    display: "flex",
    //justifyContent: "center",
    alignItems: "flex-start",
    padding: "0",
    margin: "0",
    zIndex: 1000,
    backgroundColor: backgroundColor,
    borderRadius: roundedCorner + "%",
    fontFamily: "USPSIMBStandard",
  };

  let styleck = {
    position: "absolute",
    top: "0",
    left: "0",
    visibility: "visible",
    zIndex: "1002",
    width: width,
    height: height,
    overflow: "hidden",
    //textAlign: "center",
    lineHeight: "normal",
    display: "block",
    //justifyContent: "center",
    //alignItems: "flex-start",
    padding: "0",
    margin: "0",
    //flexWrap: "wrap",
    minHeight: "20px",
    borderRadius: roundedCorner + "%",
    paddingTop: padding_top + "px",
    paddingRight: padding_right + "px",
    paddingBottom: padding_bottom + "px",
    paddingLeft: padding_left + "px",
    //wordBreak: "break-all",
    boxSizing: "border-box",
  };

  const handleResize = (style, isShiftKey, type) => {
    const { top, left, width, height } = style;
    setWidth(Math.round(width));
    setHeight(Math.round(height));
    setTop(Math.round(top));
    setLeft(Math.round(left));

    props.parentSetImbarcodeDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      imbarcode_lock
    );

    styleck = {
      position: "absolute",
      top: "0",
      left: "0",
      visibility: "visible",
      zIndex: "1002",
      width: width,
      height: height,
      overflow: "hidden",
      //textAlign: "center",
      lineHeight: "normal",
      display: "block",
      //justifyContent: "center",
      //alignItems: "flex-start",
      padding: "0",
      margin: "0",
      //flexWrap: "wrap",
      minHeight: "20px",
      borderRadius: roundedCorner + "%",
      paddingTop: padding_top + "px",
      paddingRight: padding_right + "px",
      paddingBottom: padding_bottom + "px",
      paddingLeft: padding_left + "px",
      //wordBreak: "break-all",
      boxSizing: "border-box",
    };
  };

  const handleRotate = (rotateAngle) => {
    setRotateAngle(rotateAngle);

    props.parentSetImbarcodeDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      imbarcode_lock
    );
  };

  const handleDrag = (deltaX, deltaY) => {
    leftx = left + deltaX;
    topy = top + deltaY;

    setTop(topy);
    setLeft(leftx);

    props.parentSetImbarcodeDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height,
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      imbarcode_lock
    );
  };
  return (
    <Fragment>
      <div style={contentStyle}>
        {props.title}

        <div className="align-element-position" style={{ display: "none" }}>
          <span>
            <i className="fa fa-arrow-down" aria-hidden="true"></i>{Math.round(props.top) + 26}
          </span>
          <span>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>{Math.round(props.left) + 26}
          </span>
        </div>
      </div>

      <ResizableRect
        top={top}
        rotatable
        left={left}
        //aspectRatio
        minWidth={10}
        width={width}
        minHeight={10}
        height={height}
        onDrag={!imbarcode_lock ? handleDrag : null}
        onRotate={!imbarcode_lock ? handleRotate : null}
        //onResize={handleResize}
        //zoomable="n, w, s, e, nw, ne, se, sw"
        rotateAngle={rotateAngle}
      />
    </Fragment>
  );
};

export default ResizableImbarcode;
