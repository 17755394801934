import React from 'react';

class RoundedCorner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rounded_corner: 0,
        };
    }

    handleChange = (e) => {
        this.setState({ rounded_corner: e.target.value });
        this.props.parentCallbackRoundedCorner(e.target.value);
    };

    render() {
        return (
            <div className="rounded-corner left-ui-section">
                <div className="rounded-corner-header left-ui-header">
                    <span>Rounded Corner</span>
                </div>
                <div className="rounded-corner-inputbox left-ui-body-section">
                    <input
                        type="text"
                        name="rounded_corner"
                        id="rounded_corner"
                        className="form-control"
                        value={this.props.roundedCorner ? this.props.roundedCorner : this.state.rounded_corner}
                        onChange={this.handleChange}
                    />
                </div>
            </div>
        )
    }
}

export default RoundedCorner