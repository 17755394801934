import React, { useState, useEffect } from "react";
import "../../assets/css/data-table.css";
import Swal from "sweetalert2";
import { Button, Input, FormGroup, Form, Label } from "reactstrap";

import { API_GET_CMS } from "../../config/Api";
const cmsDetails = {
  pattern: "",
  page_title: "",
  seo_title: "",
  meta_tag: "",
  meta_desc: "",
};

const CmsForm = (props) => {
  const [cms, setCms] = useState(cmsDetails);

  useEffect(() => {
    if (props.cms_id !== 0) {
      fetchCmsById();
    }
  }, [props]);

  const fetchCmsById = () => {
    props
      .callRequest("GET", API_GET_CMS + props.cms_id + "/", true)
      .then((res) => {
        setCms(res.data);
      });
  };

  const handleChange = (e) => {
    setCms((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    props
      .callRequest("PUT", API_GET_CMS + props.cms_id + "/", true, cms)
      .then((res) => {
        if (res.status) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Updated successfully",
          });
          setTimeout(
            function () {
              props.modalToggle();
              props.fetchCms();
            }.bind(),
            3000
          );
        }
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Something Went Wrong!",
        });
      });
  };

  return (
    <>
      <div className="popup-form-block">
        <Form>
          <FormGroup>
            <Label for="pattern">Pattern Name: </Label>
            <Input
              readOnly
              type="text"
              autoComplete="off"
              name="pattern"
              id="pattern"
              className="form-control"
              value={cms.pattern}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="page_title">Page Title: </Label>
            <Input
              type="text"
              autoComplete="off"
              name="page_title"
              id="page_title"
              className="form-control"
              value={cms.page_title}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="seo_title">SEO Title: </Label>
            <Input
              type="text"
              autoComplete="off"
              name="seo_title"
              id="seo_title"
              className="form-control"
              value={cms.seo_title}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="meta_tag">Meta Tag: </Label>
            <Input
              type="text"
              autoComplete="off"
              name="meta_tag"
              id="meta_tag"
              className="form-control"
              value={cms.meta_tag}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="meta_desc">Meta Description: </Label>
            <Input
              type="text"
              autoComplete="off"
              name="meta_desc"
              id="meta_desc"
              className="form-control"
              value={cms.meta_desc}
              onChange={handleChange}
            />
          </FormGroup>

          <div className="popup-button-block">
            <Button onClick={handleSubmit}>
              Save Changes <i className="ni ni-bold-right"></i>
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CmsForm;
