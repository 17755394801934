import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  API_PROFILE,
  API_USER_LOGO_UPLOAD,
  API_GET_USER_LOGO_UPLOAD,
} from "../../config/Api";
import Swal from "sweetalert2";
import { states_hash } from "../../data/countries";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import {
  Row,
  Col,
  Card,
  Button,
  CardHeader,
  CardTitle,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../store/actions/UserActions";

const PrintProfileInformation = (props) => {
  const dispatch = useDispatch();
  const initImprintInfo = {
    contact_first_name: "",
    contact_last_name: "",
    contact_address: "",
    contact_city: "",
    contact_state: "",
    contact_zip: "",
    contact_phone: "",
    contact_cell: "",
    contact_email: "",
    email_opt_in: 1,
    imprint_company_name: "",
    imprint_first_name: "",
    imprint_last_name: "",
    imprint_return_address: "",
    imprint_return_city: "",
    imprint_return_state: "",
    imprint_return_zip: "",
    imprint_phone: "",
    imprint_cell: "",
    imprint_email: "",
    imprint_website: "",
    imprint_response_text: "",
    mailing_address: "",
    mailing_city: "",
    mailing_state: "",
    mailing_zip: "",
    user_logo: "",
  };

  // const initSeedInfo = {
  //   mailing_address: "",
  //   mailing_city: "",
  //   mailing_state: "",
  //   mailing_zip: "",
  // };
  const [imprintInfo, setImprintInfo] = useState(initImprintInfo);
  const [logoUpload, setLogoUpload] = useState("");
  const [logoUploadFilename, setLogoUploadFileName] = useState("");
  const [errors, setErrors] = useState({});
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 132,
    aspect: 11 / 4,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [modal, setModal] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [zoomXY, setZoomXY] = useState(1);
  const [straighten, setStraighten] = useState(0);
  const contactInfo = useSelector((state) => state.setProfile);

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (contactInfo) {
      let contacts = { ...contactInfo };
      imprintInfo.contact_first_name = contacts.contact_first_name;
      imprintInfo.contact_last_name = contacts.contact_last_name;
      imprintInfo.contact_address = contacts.contact_address;
      imprintInfo.contact_city = contacts.contact_city;
      imprintInfo.contact_state = contacts.contact_state;
      imprintInfo.contact_zip = contacts.contact_zip;
      imprintInfo.contact_phone = contacts.contact_phone;
      imprintInfo.contact_cell = contacts.contact_cell;
      imprintInfo.contact_email = contacts.contact_email;
      imprintInfo.email_opt_in = 1;
      setImprintInfo(imprintInfo);
    }
  }, [contactInfo]);

  useEffect(() => {
    setImprintInfo(props.userDetails);
    fetchLogoInfo();
  }, [props.userDetails]);
  const sendProfileInfo = (imprintInfo) => {
    if (imprintInfo !== undefined) {
      props.sendProfileInfo(imprintInfo);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const temp = { ...imprintInfo, [name]: value };
    setImprintInfo(temp);

    if (e.target.name === "imprint_phone") {
      if (e.target.value.match(/[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi)) {
        e.target.value = e.target.value.replace(
          /[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi,
          ""
        );
      }
      const temp = { ...imprintInfo, [name]: value };
      setImprintInfo(temp);
    }

    if (e.target.name === "imprint_cell") {
      if (e.target.value.match(/[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi)) {
        e.target.value = e.target.value.replace(
          /[`~!@#$%^*_|+\=?;:'",.<>&\{\}\[\]\\\/a-z]/gi,
          ""
        );
      }
      const temp = { ...imprintInfo, [name]: value };
      setImprintInfo(temp);
    }

    validateForm();
    if (props.subProfile === 1) {
      if (imprintInfo !== undefined) {
        sendProfileInfo(temp);
      }
    }
  };
  const validateForm = () => {
    let { imprint_email, imprint_return_zip, mailing_zip } = imprintInfo;
    let errors = {};
    let formIsValid = true;

    if (imprint_email) {
      if (!props.validateEmail(imprint_email)) {
        formIsValid = false;
        errors["imprint_email"] = "Please enter valid email address";
      }
    }

    if (imprint_return_zip) {
      if (!isUSAZipCode(imprint_return_zip)) {
        formIsValid = false;
        errors["imprint_return_zip"] = "Please enter valid zip code";
      }
    }

    if (mailing_zip) {
      if (!isUSAZipCode(mailing_zip)) {
        formIsValid = false;
        errors["mailing_zip"] = "Please enter valid zip code";
      }
    }

    setErrors(errors);

    return formIsValid;
  };
  const changeHandler = (e) => {
    let contacts = { ...imprintInfo };
    if (e.target.checked) {
      imprintInfo.imprint_first_name =
        contacts.contact_first_name !== undefined
          ? contacts.contact_first_name
          : "";
      imprintInfo.imprint_last_name =
        contacts.contact_last_name !== undefined
          ? contacts.contact_last_name
          : "";
      imprintInfo.imprint_return_address =
        contacts.contact_address !== undefined ? contacts.contact_address : "";
      imprintInfo.imprint_return_city =
        contacts.contact_city !== undefined ? contacts.contact_city : "";
      imprintInfo.imprint_return_state =
        contacts.contact_state !== undefined ? contacts.contact_state : "";
      imprintInfo.imprint_return_zip =
        contacts.contact_zip !== undefined ? contacts.contact_zip : "";
      imprintInfo.imprint_phone =
        contacts.contact_phone !== undefined ? contacts.contact_phone : "";
      imprintInfo.imprint_cell =
        contacts.contact_cell !== undefined ? contacts.contact_cell : "";
      imprintInfo.imprint_email =
        contacts.contact_email !== undefined ? contacts.contact_email : "";
    } else {
      imprintInfo.imprint_first_name = "";
      imprintInfo.imprint_last_name = "";
      imprintInfo.imprint_return_address = "";
      imprintInfo.imprint_return_city = "";
      imprintInfo.imprint_return_state = "";
      imprintInfo.imprint_return_zip = "";
      imprintInfo.imprint_phone = "";
      imprintInfo.imprint_cell = "";
      imprintInfo.imprint_email = "";
    }
    setImprintInfo({ ...imprintInfo, imprintInfo });
    if (props.subProfile === 1) {
      sendProfileInfo(imprintInfo);
    }
  };
  const changeSeedHandler = (e) => {
    let imprint_info = { ...imprintInfo };
    if (e.target.checked) {
      imprintInfo.mailing_address =
        imprint_info.imprint_return_address !== undefined
          ? imprint_info.imprint_return_address
          : "";
      imprintInfo.mailing_city =
        imprint_info.imprint_return_city !== undefined
          ? imprint_info.imprint_return_city
          : "";
      imprintInfo.mailing_state =
        imprint_info.imprint_return_state !== undefined
          ? imprint_info.imprint_return_state
          : "";
      imprintInfo.mailing_zip =
        imprint_info.imprint_return_zip !== undefined
          ? imprint_info.imprint_return_zip
          : "";
    } else {
      imprintInfo.mailing_address = "";
      imprintInfo.mailing_city = "";
      imprintInfo.mailing_state = "";
      imprintInfo.mailing_zip = "";
    }
    setImprintInfo({ ...imprintInfo, imprintInfo });
    if (props.subProfile === 1) {
      sendProfileInfo(imprintInfo);
    }
  };

  const fetchLogoInfo = () => {
    props
      .callRequest(
        "GET",
        API_GET_USER_LOGO_UPLOAD + props.userDetails.user_id + "/",
        true
      )
      .then((res) => {
        setLogoUpload(res.data.user_logo);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLogoChange = (e) => {
    var file = e.target.files[0];
    setImageFile(file);
    setLogoUploadFileName(file.name);
    setUpImg(URL.createObjectURL(e.target.files[0]));
    toggle();
  };

  const handleLogoRemoval = (e) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to remove the logo?",
      showConfirmButton: "yes",
      showDenyButton: "no",
    })
      .then((res) => {
        if (res.isConfirmed) {
          setLogoUploadFileName("");
          props
            .callRequest(
              "DELETE",
              API_GET_USER_LOGO_UPLOAD + props.userDetails.user_id + "/",
              true
            )
            .then(
              Swal.fire({
                icon: "success",
                title: "Deleted !!",
                showConfirmButton: false,
                timer: 1000,
              })
            );
        }
        fetchLogoInfo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (!validateForm(imprintInfo.imprint_email)) {
      return false;
    }

    const formData = new FormData();
    for (var key in imprintInfo) {
      if (key !== "user_logo") {
        if (key === "imprint_phone") {
          formData.append(
            "imprint_phone",
            imprintInfo.imprint_phone.replace(
              /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
              ""
            )
          );
        } else if (key === "imprint_cell") {
          formData.append(
            "imprint_cell",
            imprintInfo.imprint_cell.replace(
              /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
              ""
            )
          );
        } else {
          if (key === "contact_phone" || key === "contact_cell") {
            imprintInfo[key] = imprintInfo[key].replace(
              /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
              ""
            );
          }
          formData.append(key, imprintInfo[key]);
        }
      }
      //formData.append(key, imprintInfo[key]);
    }
    props
      .callRequest(
        "POST",
        API_PROFILE + props.getUserInfo().user_id + "/",
        true,
        formData
      )
      .then((res) => {
        if (res.status) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Updated successfully",
          });
          dispatch(setProfile(imprintInfo));
        }
      })
      .catch((error) => {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "error",
          title: "Something Went Wrong!",
        });
      });
  };

  const onImageLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const TO_RADIANS = Math.PI / 180;

  const getCroppedImg = async () => {
    try {
      if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
        return;
      }

      const image = imgRef.current;
      const canvas = previewCanvasRef.current;
      const crop = completedCrop;

      const ctx = canvas.getContext("2d");

      if (!ctx) {
        throw new Error("No 2d context");
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const pixelRatio = window.devicePixelRatio || 1;

      canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
      canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

      ctx.scale(pixelRatio, pixelRatio);
      ctx.imageSmoothingQuality = "high";

      const cropX = crop.x * scaleX;
      const cropY = crop.y * scaleY;

      const rotateRads = straighten * TO_RADIANS;
      const centerX = image.naturalWidth / 2;
      const centerY = image.naturalHeight / 2;

      ctx.save();

      // 5) Move the crop origin to the canvas origin (0,0)
      ctx.translate(-cropX, -cropY);
      // 4) Move the origin to the center of the original position
      ctx.translate(centerX, centerY);
      // 3) Rotate around the origin
      ctx.rotate(rotateRads);
      // 2) Scale the image
      ctx.scale(zoomXY, zoomXY);
      // 1) Move the center of the image to the origin (0,0)
      ctx.translate(-centerX, -centerY);
      ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
      );

      ctx.restore();

      const base64Image = canvas.toDataURL("image/webp", 0.7);

      if (base64Image) {
        const formData = new FormData();
        formData.append("user_logo", base64Image);
        formData.append("crop", "1");
        if (props.userDetails && props.userDetails.user_id !== undefined) {
          formData.append("user_id", props.userDetails.user_id);
        }

        props
          .callRequest("POST", API_USER_LOGO_UPLOAD + "/", true, formData)
          .then((res) => {
            setLogoUpload(res.data.user_logo);
            setImprintInfo({ ...imprintInfo, user_logo: res.data.user_logo });
            toggle();
            setZoomXY(1);
            setStraighten(0);
            setCrop({
              unit: "%",
              width: 132,
              aspect: 11 / 4,
            });
            props.sendLogoInfo(res.data.user_logo);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const cancelImageCrop = () => {
    const formData = new FormData();
    formData.append("user_logo", imageFile, imageFile.name);
    formData.append("crop", "0");
    if (props.userDetails && props.userDetails.user_id !== undefined) {
      formData.append("user_id", props.userDetails.user_id);
    }

    props
      .callRequest("POST", API_USER_LOGO_UPLOAD + "/", true, formData)
      .then((res) => {
        setLogoUpload(res.data.user_logo);
        setImprintInfo({ ...imprintInfo, user_logo: res.data.user_logo });
        toggle();
        setZoomXY(1);
        setStraighten(0);
        setCrop({
          unit: "%",
          width: 132,
          aspect: 11 / 4,
        });
        props.sendLogoInfo(res.data.user_logo);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function formatUSNumber(entry = "") {
    let cleaned = ("" + entry).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  function isUSAZipCode(str) {
    return /^\d{5}(\d{4})?$/.test(str);
  }

  function formatZip(str) {
    let formattedStr;
    const regex = /(\d{5})(\d{4})/;
    if (str.length === 5) {
      formattedStr = str;
    } else if (str.length === 9) {
      formattedStr = str.replace(regex, "$1-$2");
    } else {
      formattedStr = str;
    }
    return formattedStr;
  }

  return (
    <>
      <div className="information-form-box">
        <Card>
          <CardHeader>
            <CardTitle tag="h2">Print Profile</CardTitle>
            {props.subProfile == 1 ? null : (
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="check"
                  id="useContactInformation"
                  onChange={changeHandler}
                />

                <Label for="useContactInformation" check>
                  Use my Contact Information for my Print Profile
                </Label>
              </FormGroup>
            )}
          </CardHeader>
          <CardBody>
            <div className="form-box table-category-form-block">
              <Form>
                <FormGroup>
                  <Label for="imprint_company_name">Company Name:</Label>
                  <Input
                    type="text"
                    name="imprint_company_name"
                    id="imprint_company_name"
                    placeholder="Company Name"
                    value={imprintInfo.imprint_company_name}
                    onChange={handleChange}
                  />
                </FormGroup>

                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="imprint_first_name">First Name:</Label>
                      <Input
                        type="text"
                        name="imprint_first_name"
                        id="imprint_first_name"
                        placeholder="First Name"
                        value={imprintInfo.imprint_first_name}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="imprint_last_name">Last Name:</Label>
                      <Input
                        type="text"
                        name="imprint_last_name"
                        id="imprint_last_name"
                        placeholder="Last Name"
                        value={imprintInfo.imprint_last_name}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="imprint_return_address">Return Address:</Label>
                  <Input
                    type="text"
                    name="imprint_return_address"
                    id="imprint_return_address"
                    placeholder="1234 Main St"
                    value={imprintInfo.imprint_return_address}
                    onChange={handleChange}
                  />
                </FormGroup>
                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="imprint_return_city">Return City:</Label>
                      <Input
                        type="text"
                        name="imprint_return_city"
                        id="imprint_return_city"
                        value={imprintInfo.imprint_return_city}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="imprint_return_state">Return State:</Label>
                      <select
                        id="imprint_return_state"
                        name="imprint_return_state"
                        value={imprintInfo.imprint_return_state}
                        onChange={handleChange}
                        className="form-control"
                        aria-label="Default select example"
                      >
                        <option value="">Select State</option>
                        {states_hash.map((state_info, index) => (
                          <option key={index} value={state_info.abbreviation}>
                            {state_info.abbreviation}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <Label for="imprint_return_zip">Return Zip:</Label>
                      <Input
                        type="text"
                        name="imprint_return_zip"
                        id="imprint_return_zip"
                        value={
                          imprintInfo.imprint_return_zip &&
                          formatZip(imprintInfo.imprint_return_zip)
                        }
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <label className="red_err">
                      {errors.imprint_return_zip}
                    </label>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="imprint_phone">Phone:</Label>
                      <Input
                        type="text"
                        name="imprint_phone"
                        id="imprint_phone"
                        value={
                          imprintInfo.imprint_phone &&
                          formatUSNumber(imprintInfo.imprint_phone)
                        }
                        onChange={handleChange}
                        maxlength={
                          imprintInfo.imprint_phone &&
                          imprintInfo.imprint_phone.replace(
                            /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                            ""
                          ).length === 10
                            ? 10
                            : 14
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="imprint_cell">Cell:</Label>
                      <Input
                        type="text"
                        name="imprint_cell"
                        id="imprint_cell"
                        value={
                          imprintInfo.imprint_cell &&
                          formatUSNumber(imprintInfo.imprint_cell)
                        }
                        onChange={handleChange}
                        maxlength={
                          imprintInfo.imprint_cell &&
                          imprintInfo.imprint_cell.replace(
                            /[`~!@#$%^*()_|+\-=?;:'",.<>\{\}\[\]\\\/ ]/gi,
                            ""
                          ).length === 10
                            ? 10
                            : 14
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="imprint_email">Email Address:</Label>
                  <Input
                    type="email"
                    name="imprint_email"
                    id="imprint_email"
                    placeholder="example@email.com"
                    value={imprintInfo.imprint_email}
                    onChange={handleChange}
                    onKeyUp={handleChange}
                  />
                  <label className="red_err">{errors.imprint_email}</label>
                </FormGroup>

                <FormGroup>
                  <Label for="imprint_website">Website:</Label>
                  <Input
                    type="url"
                    name="imprint_website"
                    id="imprint_website"
                    placeholder=""
                    value={imprintInfo.imprint_website}
                    onChange={handleChange}
                  />
                </FormGroup>
                {/* <FormGroup>
                  <Label for="imprint_response_text">Responsetxt1:</Label>
                  <Input
                    type="text"
                    name="imprint_response_text"
                    id="imprint_response_text"
                    placeholder=""
                    value={imprintInfo.imprint_response_text}
                    onChange={handleChange}
                  />
                </FormGroup> */}
              </Form>
            </div>
          </CardBody>
          <CardHeader>
            <CardTitle tag="h2">Seed Information</CardTitle>
            <FormGroup check>
              <Input
                type="checkbox"
                name="check1"
                id="useReturnInformation"
                onChange={changeSeedHandler}
              />
              <Label for="useReturnInformation" check>
                Use my Return Address for my Seed Information
              </Label>
            </FormGroup>
          </CardHeader>
          <CardBody>
            <div className="form-box table-category-form-block">
              <FormGroup>
                <Label for="mailing_address">Mailing Address:</Label>
                <Input
                  type="text"
                  name="mailing_address"
                  id="mailing_address"
                  placeholder="1234 Main St"
                  value={imprintInfo.mailing_address}
                  onChange={handleChange}
                />
              </FormGroup>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for="mailing_city">Mailing City:</Label>
                    <Input
                      type="text"
                      name="mailing_city"
                      id="mailing_city"
                      value={imprintInfo.mailing_city}
                      onChange={handleChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="mailing_state">Mailing State:</Label>
                    <select
                      id="mailing_state"
                      name="mailing_state"
                      value={imprintInfo.mailing_state}
                      onChange={handleChange}
                      className="form-control"
                      aria-label="Default select example"
                    >
                      <option value="">Select State</option>
                      {states_hash.map((state_info, index) => (
                        <option key={index} value={state_info.abbreviation}>
                          {state_info.abbreviation}
                        </option>
                      ))}
                    </select>
                  </FormGroup>
                </Col>
                <Col md={2}>
                  <FormGroup>
                    <Label for="mailing_zip">Mailing Zip:</Label>
                    <Input
                      type="text"
                      name="mailing_zip"
                      id="mailing_zip"
                      value={
                        imprintInfo.mailing_zip &&
                        formatZip(imprintInfo.mailing_zip)
                      }
                      onChange={handleChange}
                    />
                  </FormGroup>
                  <label className="red_err">{errors.mailing_zip}</label>
                </Col>
              </Row>
              {props.subProfile !== undefined ? (
                <>
                  <Row>
                    <Col md={3}>
                      <FormGroup>
                        <label htmlFor="logoUpload">
                          {" "}
                          <i className="fas fa-upload"></i>
                          &nbsp; Upload Logo
                          <Input
                            id="logoUpload"
                            type="file"
                            accept=".jpg, .png"
                            onChange={(e) => handleLogoChange(e)}
                            onClick={(e) => (e.target.value = "")}
                            style={{
                              display: "none",
                            }}
                          />
                        </label>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        {logoUpload && (
                          <img
                            alt="logo.png"
                            src={logoUpload}
                            style={{
                              paddingRight: "5px",
                              width: "40%",
                              height: "40%",
                              position: "acsolute",
                            }}
                          ></img>
                        )}
                        <span>{logoUploadFilename}</span>
                        {props.userDetails &&
                          props.userDetails.user_id !== undefined &&
                          logoUpload && (
                            <span>
                              <i
                                onClick={handleLogoRemoval}
                                className="fas fa-trash"
                                style={{
                                  padding: "7px",
                                  color: "red",
                                  cursor: "pointer",
                                }}
                              ></i>
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : (
                <div className="update-button-block">
                  <Button onClick={handleUpdate}>Update</Button>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        keyboard={false}
        className="crop-image-modal-profile"
      >
        <ModalHeader toggle={toggle}>Fit Logo to Size</ModalHeader>
        <ModalBody>
          <div>
            {upImg && (
              <>
                <div className="image-crop-body">
                  <ReactCrop
                    src={upImg}
                    onImageLoaded={onImageLoad}
                    crop={crop}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c) => setCompletedCrop(c)}
                    ruleOfThirds
                    imageStyle={{
                      transform: `scale(${zoomXY}) rotate(${straighten}deg)`,
                    }}
                  />
                  <div className="logo-control-main-block">
                    <div className="logo-control-box">
                      <div className="logo-adjust-header">
                        <label htmlFor="zoom-input">Zoom: </label>
                        <span>{zoomXY}</span>
                      </div>
                      <input
                        id="zoom-input"
                        type="range"
                        step="0.1"
                        min="0.5"
                        max="2.5"
                        value={zoomXY}
                        onChange={(e) => setZoomXY(Number(e.target.value))}
                      />
                    </div>
                    <div className="logo-control-box">
                      <div className="logo-adjust-header">
                        <label htmlFor="straighten-input">Straighten: </label>
                        <span>{straighten}</span>
                      </div>
                      <input
                        id="straighten-input"
                        type="range"
                        min="-180"
                        max="180"
                        value={straighten}
                        onChange={(e) => setStraighten(Number(e.target.value))}
                      />
                    </div>
                  </div>
                  <p>
                    Width: {Math.round(completedCrop?.width ?? 0) + "px"}
                    {", "}
                    Height: {Math.round(completedCrop?.height ?? 0) + "px"}
                  </p>
                  <div style={{ display: "none" }}>
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0),
                      }}
                    />
                  </div>
                  <div className="crop-image-btn-block-profile">
                    <Button onClick={getCroppedImg}>Crop</Button>
                    <Button
                      onClick={cancelImageCrop}
                      className="btn btn-danger"
                      color="#ff0000"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PrintProfileInformation;
