import React, { Fragment, useEffect, useState } from "react";
import ResizableRect from "react-resizable-rotatable-draggable";

const ResizableLineShape = (props) => {
    const [width, setWidth] = useState(props.width);
    const [height, setHeight] = useState(props.height);
    const [top, setTop] = useState(props.top);
    const [left, setLeft] = useState(props.left);
    const [rotateAngle, setRotateAngle] = useState(props.rotateAngle);
    const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
    const [roundedCorner, setRoundedCorner] = useState(props.roundedCorner);
    const [margin_top, setMarginTop] = useState(props.margin_top);
    const [margin_left, setMarginLeft] = useState(props.margin_left);
    const [padding_top, setPaddingTop] = useState(props.padding_top);
    const [padding_right, setPaddingRight] = useState(props.padding_right);
    const [padding_bottom, setPaddingBottom] = useState(props.padding_bottom);
    const [padding_left, setPaddingLeft] = useState(props.padding_left);
    const [line_lock, setLineLock] = useState(props.line_lock);
    const [id] = useState(props.id);
    let topy;
    let leftx;

    useEffect(() => {
        setBackgroundColor(props.backgroundColor);
        setRoundedCorner(props.roundedCorner);
        setMarginTop(props.margin_top);
        setMarginLeft(props.margin_left);
        setPaddingTop(props.padding_top);
        setPaddingRight(props.padding_right);
        setPaddingBottom(props.padding_bottom);
        setPaddingLeft(props.padding_left);
        setLineLock(props.line_lock);
    }, [
        props.backgroundColor,
        props.roundedCorner,
        props.margin_top,
        props.margin_left,
        props.padding_top,
        props.padding_right,
        props.padding_bottom,
        props.padding_left,
        props.line_lock,
    ]);

    const contentStyle = {
        top,
        left,
        width,
        height,
        position: "absolute",
        transform: `rotate(${rotateAngle}deg)`,
        display: "flex",
        alignItems: "flex-start",
        padding: "0",
        margin: "0",
        zIndex: 100,
        backgroundColor: backgroundColor,
        borderRadius: roundedCorner + "%",
    };

    const handleResize = (style, isShiftKey, type) => {
        const { top, left, width, height } = style;
        setWidth(Math.round(width));
        setHeight(Math.round(height));
        setTop(Math.round(top));
        setLeft(Math.round(left));
        props.setLineShapeDesign(
            document.getElementById("editor").innerHTML,
            id,
            left,
            top,
            width,
            height,
            rotateAngle,
            backgroundColor,
            roundedCorner,
            true,
            margin_top,
            margin_left,
            padding_top,
            padding_right,
            padding_bottom,
            padding_left,
            line_lock
        );
    };

    const handleRotate = (rotateAngle) => {
        setRotateAngle(rotateAngle);
        props.setLineShapeDesign(
            document.getElementById("editor").innerHTML,
            id,
            left,
            top,
            width,
            height,
            rotateAngle,
            backgroundColor,
            roundedCorner,
            true,
            margin_top,
            margin_left,
            padding_top,
            padding_right,
            padding_bottom,
            padding_left,
            line_lock
        );
    };

    const handleDrag = (deltaX, deltaY) => {
        leftx = left + deltaX;
        topy = top + deltaY;

        setTop(topy);
        setLeft(leftx);
        props.setLineShapeDesign(
            document.getElementById("editor").innerHTML,
            id,
            left,
            top,
            width,
            height,
            rotateAngle,
            backgroundColor,
            roundedCorner,
            true,
            margin_top,
            margin_left,
            padding_top,
            padding_right,
            padding_bottom,
            padding_left,
            line_lock
        );
    };

    return (
        <Fragment>
            <div style={contentStyle}>
                <div className="align-element-position" style={{ display: "none" }}>
                    <span>
                        <i className="fa fa-arrow-down" aria-hidden="true"></i>{Math.round(props.top) + 26}
                    </span>
                    <span>
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>{Math.round(props.left) + 26}
                    </span>
                </div>
            </div>

            <ResizableRect
                top={top}
                rotatable
                left={left}
                //aspectRatio
                minWidth={1}
                width={width}
                minHeight={1}
                height={height}
                onDrag={!line_lock ? handleDrag : null}
                onRotate={!line_lock ? handleRotate : null}
                onResize={!line_lock ? handleResize : null}
                zoomable={!line_lock ? "n, w, s, e" : ""}
                rotateAngle={rotateAngle}
            />
        </Fragment>
    );
};

export default ResizableLineShape;
