const initialState = null;

const getUserApp = (state = initialState, action) => {
  switch (action.type) {
    case "GETUSERAPP":
      return action.payload;
    default:
      return state;
  }
};

export default getUserApp;
