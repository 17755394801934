import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from "reactstrap";
import DataTable from "react-data-table-component";

import { BreadCrumb } from "../../config/Components";
import { API_GET_CONTACTS } from "../../config/Api";

import DataTableSettings from "../../utils/DataTableSettings";

import "../../assets/css/data-table.css";

const Contacts = (props) => {
  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  //const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const tempRow = {
    map_id: 3644,
    campaign: "",
    apn: "",
    property_address: "",
    property_city: "",
    property_county: "",
    property_state: "",
    property_zip: "",
    property_size_sq_ft: "",
    short_leagal_description: "",
    offer_amount: "",
    offer_value_name: "",
    mark_value: "",
    black_texes: "",
    zoning: "",
    offer_accept_by: "",
    offer_expire_on: "",
    stage: "",
    address_status: "",
    Type: "",
    full_name: "",
    first_name: "",
    last_name: "",
    company: "",
    email1: "",
    email2: "",
    email3: "",
    email4: "",
    email5: "",
    phone1: "",
    phone2: "",
    phone3: "",
    phone4: "",
    phone5: "",
    fax: "",
    address: "",
    address2: "",
    city: "",
    state: "",
    Zip: "",
    owner_first_name: "",
    owner_last_name: "",
    second_owner_first_name: "",
    second_owner_last_name: "",
    third_owner_first_name: "",
    third_owner_last_name: "",
    forth_owner_first_name: "",
    forth_owner_last_name: "",
    fifth_owner_first_name: "",
    fifth_owner_last_name: "",
  };

  const [searchUri, setSearchUrl] = useState("");

  const [searchInputs, setSerachInputs] = useState({
    Type: null,
    full_name: null,
    email1: null,
    phone1: null,
    mailing_address: null,
    mailing_address2: null,
    mailing_city: null,
    mailing_state: null,
    mailing_Zip: null,
    property_address: null,
    property_city: null,
    property_county: null,
    property_state: null,
    property_zip: null,
    tags: null,
    stage: null,
    address_status: null,
  });

  const searchParam = [
    "type",
    "full_name",
    "email",
    "phone",
    "mailing_address",
    "mailing_address2",
    "mailing_city",
    "mailing_state",
    "mailing_zip",
    "property_address",
    "property_city",
    "property_county",
    "property_state",
    "property_zip",
    "tags",
    "stage",
    "address_status",
  ];
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchProducts(10, 1);
  }, []);

  const fetchProducts = (page) => {
    props
      .callRequest(
        "GET",
        `${API_GET_CONTACTS}?page_size=${perPage}&page_number=${page}`,
        true,
        null
      )
      .then((res) => {
        //res.data && res.data.data &&  res.data.data.length>0 && setProducts([tempRow,...res.data.data]);
        setProducts(res.data.data);
        setTotalRows(res.data.totla_records);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.data.message === "No items") {
          setProducts([]);
          setTotalRows([]);
          setLoading(false);
        }
      });
  };

  const columns = [
    // {
    //   name: "Type",
    //   selector: "type",
    //   sortable: true,
    // },
    {
      name: "Full Name",
      selector: "full_name",
      sortable: true,
    },
    // {
    //   name: "Email",
    //   selector: "email1",
    //   sortable: true,
    // },
    // {
    //   name: "Phone",
    //   selector: "phone1",
    //   sortable: true,
    // },
    {
      name: "Address",
      selector: "mailing_street",
      sortable: true,
    },
    {
      name: "Address 2",
      selector: "mailing_street2",
      sortable: true,
    },
    {
      name: "City",
      selector: "mailing_city",
      sortable: true,
    },
    {
      name: "State",
      selector: "mailing_state",
      sortable: true,
    },
    {
      name: "Zip",
      selector: "mailing_Zip",
      sortable: true,
    },
    // {
    //   name: "Property Address",
    //   selector: "property_address",
    //   sortable: true,
    // },
    {
      name: "Property City",
      selector: "property_city",
      sortable: true,
    },
    // {
    //   name: "Property County",
    //   selector: "property_county",
    //   sortable: true,
    // },
    {
      name: "Property State",
      selector: "property_state",
      sortable: true,
    },
    {
      name: "Property Zip",
      selector: "property_zip",
      sortable: true,
    },
    // {
    //   name: "Tags",
    //   selector: "tags",
    //   sortable: true,
    // },
    // {
    //   name: "Stage",
    //   selector: "stage",
    //   sortable: true,
    // },
    // {
    //   name: "Address Status",
    //   selector: "address_status",
    //   sortable: true,
    // },
    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (item, itemIndex) => (
        <>
          <UncontrolledDropdown>
            <DropdownToggle>
              <i className="fas fa-ellipsis-v"></i>
            </DropdownToggle>
            <DropdownMenu positionFixed={true}>
              <DropdownItem>Edit</DropdownItem>
              <DropdownItem>Delete</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      ),
    },
  ];

  const export_column = [
    "Type",
    "full_name",
    "email1",
    "phone1",
    "mailing_address",
    "mailing_address2",
    "mailing_city",
    "mailing_state",
    "mailing_Zip",
    "property_address",
    "property_city",
    "property_county",
    "property_state",
    "property_zip",
    "tags",
    "stage",
    "address_status",
  ];

  const searchOnChange = (e) => {
    let tempVal = { ...searchInputs, [e.target.name]: e.target.value };
    setSerachInputs({ ...tempVal });
  };

  const searchAction = () => {
    //search action

    let temUrl = "";
    let first = true;
    Object.keys(searchInputs).map((k) => {
      if (searchInputs[k]) {
        temUrl += "&" + k + "=" + searchInputs[k];
      }
    });

    setSearchUrl(temUrl);

    props
      .callRequest(
        "GET",
        `${API_GET_CONTACTS}?page_size=${perPage}&page_number=1${temUrl}`,
        true,
        null
      )
      .then((res) => {
        //res.data && res.data.data &&  res.data.data.length>0 && setProducts([tempRow,...res.data.data]);
        setProducts(res.data.data);
        setTotalRows(res.data.totla_records);
        setLoading(false);
      });
  };

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <>
        <div
          id="row-0"
          role="row"
          className="sc-jrQzAO OTlLy rdt_TableRowc search_tablerow"
        >
          <div
            id="cell-zTt6Avwrg-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="Type">Type:</label>
            <Input
              id="Type"
              name="Type"
              placeholder="Search By "
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.Type}
            />
          </div>
          <div
            id="cell-6BcI2ll_vS-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="full_name">Full Name:</label>
            <Input
              id="full_name"
              name="full_name"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.full_name}
            />
          </div>
          <div
            id="cell-9s9_jL4wzy-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="email1">Email:</label>
            <Input
              id="email1"
              name="email1"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.email1}
            />
          </div>
          <div
            id="cell-N-rKjtRaPW-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="phone1">phone:</label>
            <Input
              id="phone1"
              name="phone1"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.phone1}
            />
          </div>
          <div
            id="cell-NlvjReDqTn-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="address">Address:</label>
            <Input
              id="address"
              name="mailing_address"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.mailing_address}
            />
          </div>
          <div
            id="cell-QhIJDofkqq-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="address2">Address2:</label>
            <Input
              id="address2"
              name="mailing_address2"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.mailing_address2}
            />
          </div>
          <div
            id="cell-bVL82Sya1g-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="city">City:</label>
            <Input
              id="city"
              name="mailing_city"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.mailing_city}
            />
          </div>
          <div
            id="cell-mBaHquSWnf-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="state">State:</label>
            <Input
              id="state"
              name="mailing_state"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.mailing_state}
            />
          </div>
          <div
            id="cell-k18Rz3dsJf-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="Zip">Zip:</label>
            <Input
              id="Zip"
              name="mailing_Zip"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.mailing_Zip}
            />
          </div>
          <div
            id="cell-wsNeA5b-fG-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="property_address">Property Address:</label>
            <Input
              id="property_address"
              name="property_address"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.property_address}
            />
          </div>
          <div
            id="cell-2AqHZxP992-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="property_city">Property City:</label>
            <Input
              id="property_city"
              name="property_city"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.property_city}
            />
          </div>
          <div
            id="cell-iH06xmgNrW-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="property_county">Property County:</label>
            <Input
              id="property_county"
              name="property_county"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.property_county}
            />
          </div>
          <div
            id="cell-hizfI6l1Mi-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="property_state">Property State:</label>
            <Input
              id="property_state"
              name="property_state"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.property_state}
            />
          </div>
          <div
            id="cell-Se2M3yKtOH-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="property_zip">property Zip:</label>
            <Input
              id="property_zip"
              name="property_zip"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.property_zip}
            />
          </div>
          <div
            id="cell-LI1-5zNMSF-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="tags">Tags:</label>
            <Input
              id="tags"
              name="tags"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.tags}
            />
          </div>
          <div
            id="cell-Fz6BjMKztH-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="stage">Stage:</label>
            <Input
              id="stage"
              name="stage"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.stage}
            />
          </div>
          <div
            id="cell-USpqYeulXO_-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <label for="address_status">Status:</label>
            <Input
              id="address_status"
              name="address_status"
              placeholder="Search By"
              aria-label="Search Product"
              type="text"
              autoComplete="off"
              className="form-control"
              onChange={(e) => searchOnChange(e)}
              value={searchInputs.address_status}
            />
          </div>
          <div
            id="cell-rbCMEtiO9Wc-undefined"
            role="gridcell"
            data-tag="allowRowEvents"
            className="sc-hKwDye sc-eCImPb sc-jRQBWg kophWq iitKUf cumKbW rdt_TableCell"
          >
            <button
              className="btn btn-sm btn-primary contact-search-btn"
              onClick={() => searchAction()}
            >
              Search
            </button>
          </div>
        </div>
      </>
    );
  }, [searchInputs]);

  const Export = ({ onExport }) => (
    <div className="right-button-block">
      {/* <UncontrolledDropdown disabled={products.length > 0 ? false : true}>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export View
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown> */}
    </div>
  );

  const actionsExport = (
    <Export
      onExport={() =>
        DataTableSettings.downloadCSV(
          products,
          export_column,
          props.generateUniqueId()
        )
      }
    />
  );
  // console.log(products, "prds");
  const handlePerRowsChange = (newPerPage, page) => {
    setLoading(true);

    props
      .callRequest(
        "GET",
        `${API_GET_CONTACTS}?page_size=${newPerPage}&page_number=${page}${searchUri}`,
        true,
        null
      )
      .then((res) => {
        //res.data && res.data.data &&  res.data.data.length>0 && setProducts([tempRow,...res.data.data]);
        setProducts(res.data.data);
        setLoading(false);
        setPerPage(newPerPage);
      });
  };

  const handlePageChange = (page) => {
    fetchProducts(page);
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order History"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-money-coins"></i>
            </span>
            Contacts
          </h1>
        </div>

        <div className="listingtable-main-block orderhistory-tabile-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block">
                <DataTable
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    products,
                    searchParam,
                    filterText
                  )}
                  pagination
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  actions={actionsExport}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  progressPending={loading}
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={handlePerRowsChange}
                  onChangePage={handlePageChange}
                />
              </div>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default Contacts;
