import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { BreadCrumb } from "../../../config/Components";
import { Singlepannel, Bothpannel } from "../../../config/Components";
import { API_TEMPLATES, API_TEMPLATE_SIZE } from "../../../config/Api";

class EditTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product_id: "product_id" in this.props ? this.props.product_id : null,
      propsEdit: "propsEdit" in this.props ? this.props.propsEdit : false,
      template_id:
        "template_id" in props && props.template_id
          ? props.template_id
          : this.props.match.params.templateid,
      template_name: "",
      width: "",
      height: "",
      margin: "",
      bleed: "",
      template_type: "",
      font_background: null,
      isAdvanceEdit: true,
      editor_data: "",
      front: {},
      size_id: "",
      sizes: [],
      user_id: "",
      accupix_type_front: "",
      accupix_type_back: "",
      accupix_aspect_ratio_front: "",
      accupix_aspect_ratio_back: "",
      accupix_type_front_two: "",
      accupix_aspect_ratio_front_two: "",
      accupix_type_back_two: "",
      accupix_aspect_ratio_back_two: "",
      is_bleed: "",
      is_front_img_transparent: "",
      is_back_img_transparent: "",
      selectedUserDetail: this.props.selectedUserDetail,
      orderStatus: "orderStatus" in this.props ? this.props.orderStatus : null,
      paper_color: "paper_color" in this.props ? this.props.paper_color : null,
      campaignName: "campaignName" in this.props ? this.props.campaignName : null,
    };
    this.onChangeHandeller = this.onChangeHandeller.bind(this);
  }
  componentDidMount() {
    this.props
      .callRequest("GET", API_TEMPLATES + this.state.template_id, true)
      .then((res) => {
        let template_json = JSON.parse(res.data.template_json);
        this.setState({ template_name: res.data.template_name });
        this.setState({ height: res.data.height.split("in")[0] });
        this.setState({ width: res.data.width.split("in")[0] });
        this.setState({ bleed: res.data.bleed.split("in")[0] });
        this.setState({ margin: res.data.margin.split("in")[0] });
        this.setState({ template_type: res.data.type });
        this.setState({ size_id: res.data.size });
        this.setState({ user_id: res.data.user });
        this.setState({ is_bleed: res.data.is_bleed });
        this.setState({
          is_front_img_transparent: res.data.is_front_img_transparent,
        });
        this.setState({
          is_back_img_transparent: res.data.is_back_img_transparent,
        });

        if (res.data.type === "Single") {
          this.setState({ front: template_json.front });
          this.setState({ editor_data: res.data.html_front });
          this.setState({ font_background: template_json.font_background });
          this.setState({ png_front_url: res.data.png_front });
          this.setState({ accupix_type_front: res.data.accupix_type_front });
          this.setState({
            accupix_aspect_ratio_front: res.data.accupix_aspect_ratio_front,
          });
          this.setState({
            accupix_type_front_two: res.data.accupix_type_front_two,
          });
          this.setState({
            accupix_aspect_ratio_front_two:
              res.data.accupix_aspect_ratio_front_two,
          });
        } else {
          this.setState({ front: template_json.front });
          this.setState({ font_editor_data: res.data.html_front });
          this.setState({ font_background: template_json.font_background });
          this.setState({ png_front_url: res.data.png_front });
          this.setState({ accupix_type_front: res.data.accupix_type_front });
          this.setState({
            accupix_aspect_ratio_front: res.data.accupix_aspect_ratio_front,
          });
          this.setState({
            accupix_type_front_two: res.data.accupix_type_front_two,
          });
          this.setState({
            accupix_aspect_ratio_front_two:
              res.data.accupix_aspect_ratio_front_two,
          });
          this.setState({ back: template_json.back });
          this.setState({ back_editor_data: res.data.html_back });
          this.setState({ back_background: template_json.back_background });
          this.setState({ png_back_url: res.data.png_back });
          this.setState({ accupix_type_back: res.data.accupix_type_back });
          this.setState({
            accupix_aspect_ratio_back: res.data.accupix_aspect_ratio_back,
          });
          this.setState({
            accupix_type_back_two: res.data.accupix_type_back_two,
          });
          this.setState({
            accupix_aspect_ratio_back_two:
              res.data.accupix_aspect_ratio_back_two,
          });
        }
        this.getDesigner();
      })
      .catch((error) => { });
    this.getTemplateSize();
  }

  onChangeHandeller = (event) => {
    if (event.target.name === "size_id") {
      let size_id_value = event.target.value;
      let found = this.state.sizes.find(
        (size) => size.size_id == size_id_value
      );
      if (found && found.size_id !== 0) {
        this.setState({ width: found.width, height: found.height });
      }
    }
    this.setState({ [event.target.name]: event.target.value });
    this.OnUpdateState.onChangeHandeller(event);
  };

  getTemplateSize = (event) => {
    this.props.callRequest("GET", API_TEMPLATE_SIZE, true).then((res) => {
      this.setState({ sizes: res.data });
    });
  };

  getDesigner = () => {
    if (
      this.state.template_name !== "" &&
      this.state.size_id !== 0 &&
      this.state.template_type !== ""
    ) {
      if (this.state.template_type === "Single") {
        this.setState({ showSinglepannel: true });
        this.setState({ showBothpannel: false });
      } else {
        this.setState({ showSinglepannel: false });
        this.setState({ showBothpannel: true });
      }

      if (this.state.template_name === "") {
        this.setState({ err_template_name: "Please provide template name." });
      } else {
        this.setState({ err_template_name: "" });
      }

      if (this.state.size_id === "") {
        this.setState({ err_size: "Please select size." });
      } else {
        this.setState({ err_size: "" });
      }

      if (this.state.template_type === "") {
        this.setState({ err_template_type: "Please provide template type." });
      } else {
        this.setState({ err_template_type: "" });
      }
    } else {
      if (this.state.template_name === "") {
        this.setState({ err_template_name: "Please provide template name." });
      } else {
        this.setState({ err_template_name: "" });
      }

      if (this.state.size_id === "") {
        this.setState({ err_size: "Please select size." });
      } else {
        this.setState({ err_size: "" });
      }

      if (this.state.template_type === "") {
        this.setState({ err_template_type: "Please provide template type." });
      } else {
        this.setState({ err_template_type: "" });
      }
    }
  };

  getTemplateResponse = (res) => {
    this.props.getTemplateResponse(res);
  };

  handleBleedNoBleed = (e) => {
    if (e.target.checked === true) {
      this.setState({ is_bleed: "1" });
    } else {
      this.setState({ is_bleed: "0" });
    }
  };

  render() {
    return (
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Template List", "Edit Template"]} />
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-album-2"></i>
            </span>
            Edit Template
          </h1>
        </div>
        <div className="App">
          <div className="container">
            <form>
              <div className="row">
                {this.state.propsEdit == false && (
                  <div className="col-sm-6">
                    <Card>
                      <CardBody>
                        <div className="form-group">
                          <label>Template Name: </label>
                          <input
                            type="text"
                            name="template_name"
                            id="template_name"
                            className="form-control"
                            value={this.state.template_name}
                            onChange={this.onChangeHandeller}
                          />
                          <label className="red_err">
                            {this.state.err_template_name}
                          </label>
                        </div>
                        <div className="form-group">
                          <label>Template Size: </label>
                          <select
                            id="size_id"
                            name="size_id"
                            value={this.state.size_id}
                            onChange={this.onChangeHandeller}
                            className="form-control"
                            aria-label="Default select example"
                          // readOnly
                          // disabled
                          >
                            <option>Select Size</option>
                            {this.state.sizes.map((size) => (
                              <option key={size.size_id} value={size.size_id}>
                                {size.size_name}
                              </option>
                            ))}
                          </select>
                          <label className="red_err">
                            {this.state.err_size}
                          </label>
                        </div>

                        <div className="form-group">
                          <label>Template Type: </label>
                          <select
                            name="template_type"
                            id="template_type"
                            className="form-control"
                            onChange={this.onChangeHandeller}
                            value={this.state.template_type}
                            readOnly
                            disabled
                          >
                            <option value="">Select Type</option>
                            <option value="Single">Single</option>
                            {/* <option value="Both">Both</option> */}
                            <option value="Both">Double Sided</option>
                          </select>
                          <label className="red_err">
                            {this.state.err_template_type}
                          </label>
                        </div>
                        <div className="form-group d-flex justify-content-center bleed-button-block">
                          <div className="bleed_no_bleed">
                            <input
                              type="checkbox"
                              id="is_bleed"
                              name="is_bleed"
                              value={this.state.is_bleed}
                              checked={
                                this.state.is_bleed === "1" ? true : false
                              }
                              onChange={this.handleBleedNoBleed}
                            // readOnly
                            // disabled
                            />
                            <label htmlFor="is_bleed">No Bleed</label>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                )}
                <div
                  className={this.state.propsEdit ? "col-sm-12" : "col-sm-6"}
                >
                  <div id="scaleBoxLayout" className="">
                    {this.state.showSinglepannel ? (
                      <Singlepannel
                        {...this.props}
                        myProps={this.state}
                        onRef={(ref) => (this.OnUpdateState = ref)}
                        propsEdit={this.props.propsEdit}
                        getTemplateResponse={this.getTemplateResponse}
                        user_id={
                          this.state.selectedUserDetail &&
                            "user_id" in this.props.selectedUserDetail &&
                            this.state.selectedUserDetail.user_id
                            ? this.state.selectedUserDetail.user_id
                            : ""
                        }
                        product_id={this.state.product_id}
                        orderStatus={this.state.orderStatus}
                        paper_color={this.state.paper_color}
                        campaignName={this.state.campaignName}
                      />
                    ) : null}
                    {this.state.showBothpannel ? (
                      <Bothpannel
                        {...this.props}
                        myProps={this.state}
                        onRef={(ref) => (this.OnUpdateState = ref)}
                        propsEdit={this.props.propsEdit}
                        getTemplateResponse={this.getTemplateResponse}
                        user_id={
                          this.state.selectedUserDetail &&
                            "user_id" in this.props.selectedUserDetail &&
                            this.state.selectedUserDetail.user_id
                            ? this.state.selectedUserDetail.user_id
                            : ""
                        }
                        product_id={this.state.product_id}
                        orderStatus={this.state.orderStatus}
                        paper_color={this.state.paper_color}
                        campaignName={this.state.campaignName}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(EditTemplate);
