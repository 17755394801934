import React, { useState, useEffect } from "react";
import "../Users/UserForm.css";
import Swal from "sweetalert2";
import { Button, Row, Col, Form, FormGroup, Label, Input } from "reactstrap";
import {
  API_GET_USER,
  API_USERS,
  API_USER_GROUPS,
  API_GET_ROLES,
  API_GET_AFFILIATE,
  API_PAYMENT_SETTINGS,
  API_PROFILE,
} from "../../config/Api";
import { withRouter } from "react-router-dom";
import PrintProfileInformation from "../Profile/PrintProfileInformation";

const CreateUserForm = (props) => {
  const initUserInfo = {
    user_id:
      props.selectedUser !== "undefined" && props.selectedUser
        ? props.selectedUser
        : "",
    username: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    original_password: "",
    reset_password: "",
    user_discount: parseFloat(0.0),
    wallet_balance: parseFloat(0.0),
    sales_rep_1: "",
    sales_rep_2: "",
    sales_rep_1_commission_type: 1,
    sales_rep_1_commission_percentage: parseFloat(0.0),
    sales_rep_1_commission_flat_rate: parseFloat(0.0),
    sales_rep_2_commission_type: 1,
    sales_rep_2_commission_percentage: parseFloat(0.0),
    sales_rep_2_commission_flat_rate: parseFloat(0.0),
    csr: "",
    is_state_tax: "0",
    state_tax_rate: "",
    is_active: 1,
    group: 1,
    affiliate_id: "",
    tier: 0,
  };

  const [user, setUser] = useState(initUserInfo);
  const [profile, setProfile] = useState({});
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [multipleRoles, setMultipleRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [errors, setErrors] = useState({});
  const [stateTax, setStateTax] = useState(0);

  useEffect(() => {
    if (typeof user.user_id !== "undefined" && user.user_id > 0) {
      fetchUserById();
      fetchProfileInfo();
    }
    fetchUserGroups();
    fetchRoles();
    fetchAffiliates();
    fetchStateTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const fetchAffiliates = () => {
    props
      .callRequest("GET", API_GET_AFFILIATE, true)
      .then((res) => {
        setAffiliates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchStateTax = () => {
    props.callRequest("GET", API_PAYMENT_SETTINGS, true).then((res) => {
      setStateTax(res.data.local_tax);
    });
  };
  const fetchProfileInfo = (id) => {
    props.callRequest("GET", API_PROFILE + user.user_id, true).then((res) => {
      setProfile(res.data.user_profile);
    });
  };
  const fetchUserById = async () => {
    props
      .callRequest("GET", API_GET_USER + user.user_id, true)
      .then((res) => {
        setUser(res.data);
        setMultipleRoles(res.data.roles);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchUserGroups = async () => {
    props
      .callRequest("GET", API_USER_GROUPS, true)
      .then((res) => {
        setUserGroups(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchRoles = async () => {
    props
      .callRequest("GET", API_GET_ROLES, true)
      .then((res) => {
        setRoles(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    validateForm();
  };

  const handleMultipleRoleChange = (e) => {
    let value = Array.from(e.target.selectedOptions, (option) => option.value);
    setMultipleRoles(value);
  };

  const validateForm = () => {
    let { username, firstname, lastname, password, email } = user;

    let formIsValid = true;
    let errors = {};
    if (username === "") {
      formIsValid = false;
      errors["err_username"] = "Please provide username";
    }
    if (firstname === "") {
      formIsValid = false;
      errors["err_firstname"] = "Please provide firstname";
    }
    if (lastname === "") {
      formIsValid = false;
      errors["err_lastname"] = "Please provide lastname";
    }
    if (password === "") {
      formIsValid = false;
      errors["err_password"] = "Please provide password";
    }
    if (!props.validateEmail(email)) {
      formIsValid = false;
      errors["err_email"] = "Please enter valid email address";
    }
    setErrors(errors);
    return formIsValid;
  };

  const sendEdittedUser = (user) => {
    props.sendEdittedUser(user);
  };
  const sendProfileInfo = (imprintInfo) => {
    setUpdatedProfile(imprintInfo);
  };
  // console.log(updatedProfile, "up");
  const sendUpdatedProfile = (updatedProfile) => {
    props.sendUpdatedProfile(updatedProfile);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      let value = "";
      const multipleRolesArr = multipleRoles.filter((item) => item !== value);
      if (user.user_id > 0) {
        const new_password =
          user.reset_password === undefined || user.reset_password === ""
            ? user.original_password
            : user.reset_password;
        props
          .callRequest("PUT", API_USERS + user.user_id + "/", true, {
            username: user.username,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
            password: new_password,
            user_discount: user.user_discount,
            wallet_balance: user.wallet_balance,
            sales_rep_1: user.sales_rep_1,
            sales_rep_2: user.sales_rep_2,
            sales_rep_1_commission_type: user.sales_rep_1_commission_type,
            sales_rep_1_commission_percentage:
              user.sales_rep_1_commission_percentage,
            sales_rep_1_commission_flat_rate:
              user.sales_rep_1_commission_flat_rate,
            sales_rep_2_commission_type: user.sales_rep_2_commission_type,
            sales_rep_2_commission_percentage:
              user.sales_rep_2_commission_percentage,
            sales_rep_2_commission_flat_rate:
              user.sales_rep_2_commission_flat_rate,
            csr: user.csr,
            is_state_tax: user.is_state_tax,
            state_tax_rate: user.is_state_tax === "0" ? stateTax : 0,
            //state_tax_rate: user.state_tax_rate,
            is_active: user.is_active,
            group: user.group,
            affiliate_id: user.affiliate_id,
            roles: multipleRolesArr,
            tier: user.tier,
          })
          .then((res) => {
            if (res.status) {
              const formData = new FormData();
              for (var key in updatedProfile) {
                formData.append(key, updatedProfile[key]);
              }
              props.callRequest(
                "POST",
                API_PROFILE + user.user_id + "/",
                true,
                formData
              );
            }
            return res;
          })
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "success",
                title: "Updated successfully",
              });
              setTimeout(
                function () {
                  props.modalToggle();
                  sendEdittedUser(user);
                  sendUpdatedProfile(updatedProfile);
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      } else {
        props
          .callRequest("POST", API_USERS, true, {
            username: user.username,
            firstname: user.firstname,
            lastname: user.lastname,
            email: user.email,
            phone: user.phone,
            password: user.password,
            user_discount: user.user_discount,
            wallet_balance: user.wallet_balance,
            sales_rep_1: user.sales_rep_1,
            sales_rep_2: user.sales_rep_2,
            sales_rep_1_commission_type: user.sales_rep_1_commission_type,
            sales_rep_1_commission_percentage:
              user.sales_rep_1_commission_percentage,
            sales_rep_1_commission_flat_rate:
              user.sales_rep_1_commission_flat_rate,
            sales_rep_2_commission_type: user.sales_rep_2_commission_type,
            sales_rep_2_commission_percentage:
              user.sales_rep_2_commission_percentage,
            sales_rep_2_commission_flat_rate:
              user.sales_rep_2_commission_flat_rate,
            csr: user.csr,
            is_state_tax: user.is_state_tax,
            state_tax_rate: user.is_state_tax === "0" ? stateTax : 0,
            //state_tax_rate: user.state_tax_rate,
            is_active: user.is_active,
            group: user.group,
            affiliate_id: user.affiliate_id,
            roles: multipleRolesArr,
            tier: user.tier,
          })
          .then((res) => {
            if (res.status) {
              const formData = new FormData();
              for (var key in updatedProfile) {
                formData.append(key, updatedProfile[key]);
              }
              props.callRequest(
                "POST",
                API_PROFILE + res.data.user_id + "/",
                true,
                formData
              );
            }
            return res;
          })
          .then((res) => {
            if (res.status) {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });
              Toast.fire({
                icon: "success",
                title: "Updated successfully",
              });
              setTimeout(
                function () {
                  props.modalToggle();
                  sendEdittedUser(user);
                }.bind(),
                3000
              );
            }
          })
          .catch((error) => {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          });
      }
    }
  };

  const handleClose = () => {
    props.modalToggle();
    //props.history.push("/admin/new-subscription");
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          {user.user_id > 0 ? (
            <h1 className="header-title">Edit User</h1>
          ) : (
            <h1 className="header-title">Create User</h1>
          )}
        </div>

        <div className="create-user-formblock">
          <Form>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="username">User Name: *</Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="username"
                    id="username"
                    value={user.username}
                    onChange={handleChange}
                    placeholder="Enter Username"
                  />
                  <label className="red_err">{errors.err_username}</label>
                </FormGroup>
              </Col>
              {/* <Row form> */}
              <Col md={6}>
                <FormGroup>
                  <Label for="firstname">First Name: *</Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="firstname"
                    id="firstname"
                    value={user.firstname}
                    onChange={handleChange}
                    placeholder="Enter First Name"
                  />
                  <label className="red_err">{errors.err_firstname}</label>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="lastname">Last Name: *</Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="lastname"
                    id="lastname"
                    value={user.lastname}
                    onChange={handleChange}
                    placeholder="Enter Last Name"
                  />
                  <label className="red_err">{errors.err_lastname}</label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="email">Email Address: *</Label>
                  <Input
                    type="email"
                    autoComplete="off"
                    name="email"
                    id="email"
                    value={user.email}
                    onChange={handleChange}
                    onKeyUp={handleChange}
                    placeholder="Enter Email Address"
                  />
                  <label className="red_err">{errors.err_email}</label>
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  {user.user_id > 0 ? (
                    <Label for="password">Password</Label>
                  ) : (
                    <Label for="password">Password: *</Label>
                  )}
                  {user.user_id > 0 ? (
                    <Input
                      type="text"
                      autoComplete="off"
                      name="original_password"
                      id="original_password"
                      value={user.original_password}
                      readOnly
                    />
                  ) : (
                    <Input
                      type="password"
                      autoComplete="off"
                      name="password"
                      id="password"
                      value={user.password}
                      onChange={handleChange}
                      placeholder="Enter Password"
                    />
                  )}
                  {user.user_id > 0 ? (
                    <Label for="resetPassword">Reset Password:</Label>
                  ) : (
                    ""
                  )}
                  {user.user_id > 0 ? (
                    <Input
                      type="password"
                      autoComplete="off"
                      name="reset_password"
                      id="reset_password"
                      value={user.reset_password || ""}
                      onChange={handleChange}
                      placeholder="Enter Password"
                    />
                  ) : (
                    ""
                  )}
                  <label className="red_err">{errors.err_password}</label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="phone">Telephone Number:</Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="phone"
                    id="phone"
                    value={user.phone}
                    onChange={handleChange}
                    placeholder="Enter Telephone Number"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="user_discount">User Discount:</Label>
                  <Input
                    type="number"
                    autoComplete="off"
                    name="user_discount"
                    id="user_discount"
                    value={user.user_discount}
                    onChange={handleChange}
                    placeholder="Enter User Discount"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="affiliate_id">Affiliate:</Label>
                  <select
                    id="affiliate_id"
                    name="affiliate_id"
                    value={user.affiliate_id}
                    onChange={handleChange}
                    className="form-control"
                    aria-label="Default select example"
                  >
                    <option value="">Select Affiliate</option>
                    {affiliates.map((aff, index) => (
                      <option key={index} value={aff.affiliate_id.id}>
                        {aff.affiliate_id.name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="sales_rep_1">Sales Rep 1/ID:</Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="sales_rep_1"
                    id="sales_rep_1"
                    value={user.sales_rep_1}
                    onChange={handleChange}
                    placeholder="Enter Sales Rep"
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="sales_rep_2">Sales Rep 2/ID:</Label>
                  <Input
                    type="text"
                    autoComplete="off"
                    name="sales_rep_2"
                    id="sales_rep_2"
                    value={user.sales_rep_2}
                    onChange={handleChange}
                    placeholder="Enter Sales Rep ID"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <label>Set Sales Rep 1 Commission</label>
                  <div className="set-sales-formbox">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_1_commission_type"
                          id="sales_rep_1_commission_type"
                          value="1"
                          checked={
                            user.sales_rep_1_commission_type === "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Percentage
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_1_commission_percentage"
                        id="sales_rep_1_commission_percentage"
                        autoComplete="off"
                        placeholder="Enter %"
                        value={user.sales_rep_1_commission_percentage}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_1_commission_type"
                          id="sales_rep_1_commission_type1"
                          value="0"
                          checked={
                            user.sales_rep_1_commission_type !== "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Flat Rate
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_1_commission_flat_rate"
                        id="sales_rep_1_commission_flat_rate"
                        placeholder="1-100"
                        autoComplete="off"
                        value={user.sales_rep_1_commission_flat_rate}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <label>Set Sales Rep 2 Commission</label>
                  <div className="set-sales-formbox">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_2_commission_type"
                          id="sales_rep_2_commission_type"
                          value="1"
                          checked={
                            user.sales_rep_2_commission_type === "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Percentage
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_2_commission_percentage"
                        id="sales_rep_2_commission_percentage"
                        placeholder="Enter %"
                        autoComplete="off"
                        value={user.sales_rep_2_commission_percentage}
                        onChange={handleChange}
                      />
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="sales_rep_2_commission_type"
                          id="sales_rep_2_commission_type2"
                          value="0"
                          checked={
                            user.sales_rep_2_commission_type !== "1"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Flat Rate
                      </Label>
                      <Input
                        type="text"
                        name="sales_rep_2_commission_flat_rate"
                        id="sales_rep_2_commission_flat_rate"
                        placeholder="1-100"
                        autoComplete="off"
                        value={user.sales_rep_2_commission_flat_rate}
                        onChange={handleChange}
                      />
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="csr">CSR (Customer Service Rep)</Label>
                  <Input
                    type="text"
                    name="csr"
                    id="csr"
                    autoComplete="off"
                    placeholder="Enter CSR"
                    value={user.csr}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup tag="fieldset">
                  <legend>Tax</legend>
                  <div className="set-sales-formbox">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="is_state_tax"
                          id="is_state_tax1"
                          value="1"
                          checked={user.is_state_tax === "1" ? true : false}
                          onChange={handleChange}
                        />
                        Exempt
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="is_state_tax"
                          id="is_state_tax"
                          value="0"
                          checked={user.is_state_tax !== "1" ? true : false}
                          onChange={handleChange}
                        />
                        State Tax:&nbsp;
                        <span>{stateTax}</span>
                      </Label>
                      {/* <Input
                        type="text"
                        name="state_tax_rate"
                        id="state_tax_rate"
                        placeholder="Enter %"
                        value={user.state_tax_rate}
                        onChange={handleChange}
                      /> */}
                    </FormGroup>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label for="roles">User Role:</Label>
                  <select
                    id="roles"
                    name="roles"
                    multiple={true}
                    value={multipleRoles}
                    onChange={handleMultipleRoleChange}
                    className="form-control"
                    aria-label="Default select example"
                  >
                    <option value="">Select Role</option>
                    {roles.map((role) => (
                      <option key={role.role_id} value={role.role_id}>
                        {role.role_name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="group">User Group:</Label>
                  <select
                    id="group"
                    name="group"
                    value={user.group}
                    onChange={handleChange}
                    className="form-control"
                    aria-label="Default select example"
                  >
                    <option>Select User Group</option>
                    {userGroups.map((userGroup) => (
                      <option
                        key={userGroup.group_id}
                        value={userGroup.group_id}
                      >
                        {userGroup.group_name}
                      </option>
                    ))}
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Col md={6}>
              <FormGroup>
                <Label for="tier">Tier:</Label>
                <select
                  id="tier"
                  name="tier"
                  value={user.tier}
                  onChange={handleChange}
                  className="form-control"
                  aria-label="Default select example"
                >
                  <option default value={0}>
                    Tier1
                  </option>
                  <option value={1}>Tier2</option>
                  <option value={2}>Tier3</option>
                </select>
              </FormGroup>
            </Col>
            {user.user_id === "" ? (
              <div className="profile-information-block">
                <FormGroup tag="fieldset">
                  <div className="radio-block">
                    <Label>Status &nbsp;</Label>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="is_active"
                          id="is_active"
                          value={user.is_active}
                          checked={true}
                          onChange={handleChange}
                        />
                        Active
                      </Label>
                    </FormGroup>
                  </div>
                </FormGroup>
                <PrintProfileInformation
                  {...props}
                  userDetails={profile}
                  subProfile={1}
                  sendProfileInfo={sendProfileInfo}
                //user_id={profile.user_id}
                />
                <div className="submit-button-block">
                  <Button
                    color="danger"
                    className="cancel-button"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="warning"
                    className="create-User-button"
                    onClick={handleSubmit}
                  >
                    Create User
                  </Button>
                </div>
              </div>
            ) : (
              <div className="profile-information-block">
                {/* <Row> */}
                <FormGroup tag="fieldset">
                  <Row className="radio-block">
                    <Label>
                      <b>Status &nbsp;</b>
                    </Label>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="is_active"
                          id="is_active"
                          value="1"
                          checked={user.is_active === "1" ? true : false}
                          onChange={handleChange}
                        />
                        Active &nbsp;
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label>
                        <Input
                          type="radio"
                          name="is_active"
                          id="is_inactive"
                          value="0"
                          checked={
                            user.is_active !== "1" && user.is_active !== "2"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Inactive &nbsp;
                      </Label>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="radio"
                          name="is_active"
                          id="no_sell"
                          value="2"
                          checked={
                            user.is_active !== "1" && user.is_active !== "0"
                              ? true
                              : false
                          }
                          onChange={handleChange}
                        />
                        Do Not Sell
                      </Label>
                    </FormGroup>
                  </Row>
                </FormGroup>
                <PrintProfileInformation
                  {...props}
                  userDetails={profile}
                  subProfile={1}
                  sendProfileInfo={sendProfileInfo}
                //user_id={profile.user_id}
                />
                {/* </Row> */}
                <div className="submit-button-block">
                  <Button
                    color="warning"
                    className="create-User-button"
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            )}
          </Form>
        </div>
      </main>
    </>
  );
};

export default withRouter(CreateUserForm);
