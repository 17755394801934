import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
  Modal,
} from "reactstrap";
import Swal from "sweetalert2";
import { withRouter } from "react-router-dom";

import {
  AffilateModal,
  BreadCrumb,
  UploadImage,
} from "../../config/Components";

import {
  API_TEMPLATE_LIST,
  API_MASTER_TEMPLATES,
  API_TEMPLATES,
  API_PRODUCTS,
  API_GET_PRODUCT,
  API_PARENT_CATEGORY,
  API_GET_CHILD_CATEGORY,
  API_PRODUCT_FULL_IMAGE,
  API_PRODUCT_THUMB_IMAGE,
  API_ATTRIBUTE_TYPE,
  API_ATTRIBUTES,
  API_GET_AFFILIATE,
} from "../../config/Api";

import "./AddProduct.css";
import ProductPrice from "./ProductPrice";
import { AsyncPaginate } from "react-select-async-paginate";

const AddProduct = (props) => {
  const [modal, setModal] = useState(false);
  const [parent_categories, setParentCategory] = useState([]);
  const [child_categories, setChildCategory] = useState([]);
  // const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const productInfo = {
    product_name: "",
    description: "",
    template_code: "",
    price: "",
    tier2_price: "",
    tier3_price: "",
    probate_tier: "",
    parent_category: "",
    sub_category: [],
    min_qnty: "",
    min_qnty_txt: "",
    is_active: false,
    product_templates_id: [],
    mailing_date_limit: "",
    lockout_date: "",
    ordering: "",
    product_id: "",
    FullSizeImagePath: "",
    imagepathname: "",
    api_product: "0",
    reiprintmail_product: "0",
    probate_product: "0",
    attribute_api: [],
    attribute_default: [],
    attribute_probate: [],
    affiliate: [],
    is_micro_campaign: 0,
    micro_campaign_price: "0",
    is_all_affiliate: "0",
  };
  const priceInfo = {
    price_id: "",
    tier1_price: "",
    tier2_price: "",
    tier3_price: "",
    probate_tier: "",
  };
  const [product_info, setProductInfo] = useState(productInfo);
  const [price_info, setPriceInfo] = useState(priceInfo);
  const [errors, setErrors] = useState({});
  const [title, setTitle] = useState("Add Product");
  const [attrtypes, setAttrTypes] = useState([]);
  const [attrs, setAttrs] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [modalVal, setModalVal] = useState();
  const [selectedTemplate, setSelectedTemplate] = useState({
    value: 0,
    label: "",
  });

  useEffect(() => {
    fetchParentCategory();
    fetchAttributeTypes();
    fetchAttributes();
    fetchAffiliates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   fetchMasterTemplates();
  // }, []);

  useEffect(() => {
    if (props.match.params.hasOwnProperty("id")) {
      setLoading(true);
      fetchProducts(props.match.params.id);
      setTitle("Update Product");
    }
  }, []);

  const toggle = () => setModal(!modal);
  const fetchAffiliates = () => {
    props.callRequest("GET", API_GET_AFFILIATE, true).then((res) => {
      setAffiliates(res.data);
    });
  };

  const fetchParentCategory = () => {
    props.callRequest("GET", API_PARENT_CATEGORY, true).then((res) => {
      setParentCategory(res.data);
    });
  };
  const fetchChildCategory = (id) => {
    props
      .callRequest("GET", API_GET_CHILD_CATEGORY + id + "/", true)
      .then((res) => {
        setChildCategory(res.data);
      });
  };

  // const fetchMasterTemplates = () => {
  //   props.callRequest("GET", API_MASTER_TEMPLATES, true).then((res) => {
  //     setTemplates(res.data.data);
  //   });
  // };

  const fetchAttributeTypes = () => {
    props.callRequest("GET", API_ATTRIBUTE_TYPE, true).then((res) => {
      setAttrTypes(res.data);
    });
  };

  const fetchAttributes = () => {
    props.callRequest("GET", API_ATTRIBUTES, true).then((res) => {
      setAttrs(res.data);
    });
  };

  const fetchProducts = (id) => {
    props.callRequest("GET", API_GET_PRODUCT + id, true).then((res) => {
      fetchChildCategory(res.data.parent_category);
      var numeric_array = new Array();
      for (let items in res.data.sub_category) {
        numeric_array.push(res.data.sub_category[items]["category_id"]);
      }

      setProductInfo({
        ...product_info,
        ...res.data,
        sub_category: numeric_array,
        product_templates_id: res.data.template_id,
      });

      setLoading(false);
    });
  };
  // console.log(product_info, "product_info");
  const fetchProducts1 = (id) => {
    props
      .callRequest("GET", API_MASTER_TEMPLATES, true)
      .then((res) => {
        return res;
      })
      .then((res) => {
        let tempArr = res.data.data.map((item) => {
          return item.template_id;
        });

        props.callRequest("GET", API_GET_PRODUCT + id, true).then((res) => {
          fetchChildCategory(res.data.parent_category);
          var numeric_array = new Array();
          for (let items in res.data.sub_category) {
            numeric_array.push(res.data.sub_category[items]["category_id"]);
          }
          // let temp_arr = res.data.template.filter((item) => {
          //   if (tempArr.includes(item.template_id)) {
          //     return item.template_id;
          //   }
          // });

          setProductInfo({
            ...product_info,
            ...res.data,
            sub_category: numeric_array,
            product_templates_id: res.data.template_id,
          });
        });
        setLoading(false);
      });
  };

  const findAttributeIdList = (name) => {
    const attrTypeId = attrtypes.filter((type) => {
      return type.type_name.toLowerCase().match(name);
    });

    if (attrTypeId.length > 0) {
      const arrList = attrs.filter((attr) => {
        return attr.attributetype_id == attrTypeId[0].type_id;
      });

      const arrIdList = arrList.map((arr) => arr.attribute_id);
      return arrIdList;
    }
  };

  const findCommonElement = (arr1, arr2) => {
    return arr1.some((item) => arr2.includes(item));
  };

  const getCommon = (arr1, arr2) => {
    var common = [];
    for (var i = 0; i < arr1.length; ++i) {
      for (var j = 0; j < arr2.length; ++j) {
        if (arr1[i] == arr2[j]) {
          common.push(arr1[i]);
        }
      }
    }

    return common[0]; // Return the common elements
  };

  const loadOptions = async (search, prevOptions, { page }) => {
    const row_per_page = 10;
    const is_master = 1;
    const url =
      API_TEMPLATE_LIST +
      "?page_number=" +
      page +
      "&template_name=" +
      search +
      "&is_master=" +
      is_master;
    const res = await props.callRequest("GET", `${url}`, true);
    const data = await res.data.data;
    const total_records = await res.data.totla_records;
    const page_size = page * row_per_page;
    const hasMore = page_size < total_records;

    const templates = data.map((obj) => {
      obj.label = obj.template_name;
      obj.value = obj.template_id;
      delete obj.template_name;
      delete obj.template_id;
      return obj;
    });

    return {
      options: templates,
      hasMore,
      additional: {
        page: page + 1,
      },
    };
  };

  const onChangeTemplate = (e) => {
    setSelectedTemplate({ value: e.value, label: e.label });
    setProductInfo({ ...product_info, product_templates_id: [e.value] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "parent_category") {
      fetchChildCategory(value);
    }
    if (name === "sub_category") {
      let options = e.target.options;
      let val = [];
      for (let i = 0, l = options.length; i < l; i++) {
        if (options[i].selected) {
          val.push(options[i].value);
        }
      }

      setProductInfo({ ...product_info, [name]: val });
    }
    // else if (name === "product_templates_id") {
    //   let options = e.target.options;
    //   let val = [];
    //   for (let i = 0, l = options.length; i < l; i++) {
    //     if (options[i].selected) {
    //       val.push(options[i].value);
    //     }
    //   }
    //   setProductInfo({ ...product_info, [name]: val });
    // }
    else {
      setProductInfo({ ...product_info, [name]: value });
    }
  };

  const setThumbImage = (path) => {
    setProductInfo({ ...product_info, imagepathname: path });
  };

  const setImage = (path) => {
    setProductInfo({ ...product_info, FullSizeImagePath: path });
  };

  const validateForm = () => {
    let {
      parent_category,
      sub_category,
      product_name,
      description,
      ordering,
      lockout_date,
      mailing_date_limit,
      min_qnty,
      template_code,
      product_templates_id,
      price,
      tier2_price,
      tier3_price,
      probate_tier,
    } = product_info;

    let formIsValid = true;
    let errors = {};

    if (parent_category === "") {
      formIsValid = false;
      errors["parent_catgory"] = "This field is required";
    }
    if (sub_category.length === 0) {
      formIsValid = false;
      errors["sub_category"] = "This field is required";
    }
    if (product_name === "") {
      formIsValid = false;
      errors["product_name"] = "This field is required";
    }
    if (template_code === "") {
      formIsValid = false;
      errors["template_code"] = "This field is required";
    }

    if (description === "") {
      formIsValid = false;
      errors["description"] = "This field is required";
    }
    if (ordering === "") {
      formIsValid = false;
      errors["ordering"] = "This field is required";
    }
    if (lockout_date === "") {
      formIsValid = false;
      errors["lockout_date"] = "This field is required";
    }
    if (mailing_date_limit === "") {
      formIsValid = false;
      errors["mailing_date_limit"] = "This field is required";
    }
    if (min_qnty === "") {
      formIsValid = false;
      errors["min_qnty"] = "This field is required";
    }
    if (product_templates_id.length === 0) {
      formIsValid = false;
      errors["product_templates_id"] = "This field is required";
    }
    let price1_flag = true;
    if (price) {
      let price_arr = price.split("|");
      for (let i = 0; i < price_arr.length; i++) {
        if (!price_arr[i]) {
          price1_flag = false;
        }
      }
    } else {
      price1_flag = false;
    }

    if (!price1_flag) {
      formIsValid = false;
      errors["price_range1"] = "Tier1 prices must be filled";
    }
    setErrors(errors);

    return formIsValid;
  };

  const getSelectedValues = (cls) => {
    let api_selected_values = document.getElementsByClassName(cls);
    let selected = [];
    for (let i = 0; i < api_selected_values.length; i++) {
      let options = api_selected_values[i].options;
      for (let j = 0; j < options.length; j++) {
        if (options[j].selected && options[j].value != "a") {
          selected.push(options[j].value);
        }
      }
    }

    return selected;
  };
  const receivePriceRange = (priceRange) => {
    let currPrd = { ...product_info };
    currPrd.price = priceRange.tier1_price;
    currPrd.tier2_price = priceRange.tier2_price;
    currPrd.tier3_price = priceRange.tier3_price;
    currPrd.probate_tier = priceRange.probate_tier;
    setProductInfo(currPrd);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return false;
    }

    let post_data = {};
    post_data = { ...product_info };
    post_data["attribute_default"] = getSelectedValues("rei-select-cls");
    post_data["attribute_api"] = getSelectedValues("api-select-cls");
    post_data["attribute_probate"] = getSelectedValues("probate-select-cls");
    post_data["template"] = product_info.product_templates_id;
    post_data["is_active"] = Number(post_data.is_active);
    //console.log(post_data., "post");

    let post_url = API_PRODUCTS;
    let method = "POST";
    let prd_id = product_info.product_id;
    if (prd_id) {
      post_url = API_PRODUCTS + prd_id + "/";
      method = "PUT";
    }
    props
      .callRequest(method, post_url, true, post_data)
      .then((res) => {
        if (res.status) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Saved successfully",
          });
          setTimeout(() => {
            props.history.push("/products");
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response.data.is_active == `"False" is not a valid choice.`) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "Select active/inactive field.",
          });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: "something went wrong !!",
          });
        }
      });
  };

  const setAffiliateValues = (values) => {
    setProductInfo({ ...product_info, affiliate: values });
  };
  const affiliateToggle = () => {
    setModalVal(false);
    toggle();
  };

  const PriceToggle = () => {
    setModalVal(true);
    if (product_info.product_id !== "") {
      let currPrice = { ...price_info };
      currPrice.price_id = 1;
      currPrice.tier1_price = product_info.price;
      currPrice.tier2_price = product_info.tier2_price;
      currPrice.tier3_price = product_info.tier3_price;
      currPrice.probate_tier = product_info.probate_tier;
      setPriceInfo(currPrice);
    }
    toggle();
  };

  return (
    <>
      {loading ? (
        <div className="loader_main">
          <div className="loade_box">
            <div className="loader"></div>
            <p>Please Wait...</p>
          </div>
        </div>
      ) : (
        <main role="main" className="pt-3 px-4">
          <div className="main-heading-block">
            <BreadCrumb links={["Products", title]} />
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-bag-17"></i>
              </span>
              {title}
            </h1>
          </div>
          <div className="add-Product-block">
            <Row>
              <Col sm="8" lg="9">
                <Card>
                  <CardBody>
                    <Form onSubmit={handleSubmit}>
                      <div className="affiliate-block">
                        <Label className="affiliate-titel">Affiliate</Label>
                        <Button onClick={affiliateToggle}>
                          Select Affiliate
                        </Button>
                        {modalVal === false ? (
                          <AffilateModal
                            modal={modal}
                            toggle={toggle}
                            affiliates={affiliates}
                            setAffiliateValues={setAffiliateValues}
                            setProductInfo={setProductInfo}
                            product_info={product_info}
                            affiliate={product_info.affiliate}
                            is_all_affiliate={product_info.is_all_affiliate}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <Row>
                        <Col sm="5">
                          <div className="yes-no-redio-block">
                            <FormGroup>
                              <Label></Label>
                              <div className="yes-no-redio-item">
                                <span className="redio-box redio-titel">
                                  Yes
                                </span>
                                <span className="redio-box redio-titel">
                                  No
                                </span>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label>Api default Product</Label>
                              <div className="yes-no-redio-item">
                                <span className="redio-box">
                                  <CustomInput
                                    type="radio"
                                    id="apiProductYes"
                                    name="api_product"
                                    value="1"
                                    onChange={handleChange}
                                    checked={
                                      product_info.api_product === "1" &&
                                      "checked"
                                    }
                                  />
                                </span>
                                <span className="redio-box">
                                  <CustomInput
                                    type="radio"
                                    id="apiProductNo"
                                    name="api_product"
                                    value="0"
                                    onChange={handleChange}
                                    checked={
                                      product_info.api_product === "0" &&
                                      "checked"
                                    }
                                  />
                                </span>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label>REIprintmail default Product</Label>
                              <div className="yes-no-redio-item">
                                <span className="redio-box">
                                  <CustomInput
                                    type="radio"
                                    id="reiprintmailProductYes"
                                    name="reiprintmail_product"
                                    value="1"
                                    onChange={handleChange}
                                    checked={
                                      product_info.reiprintmail_product ===
                                        "1" && "checked"
                                    }
                                  />
                                </span>
                                <span className="redio-box">
                                  <CustomInput
                                    type="radio"
                                    id="reiprintmailProductNo"
                                    name="reiprintmail_product"
                                    value="0"
                                    onChange={handleChange}
                                    checked={
                                      product_info.reiprintmail_product ===
                                        "0" && "checked"
                                    }
                                  />
                                </span>
                              </div>
                            </FormGroup>

                            <FormGroup>
                              <Label>Probate Product</Label>
                              <div className="yes-no-redio-item">
                                <span className="redio-box">
                                  <CustomInput
                                    type="radio"
                                    id="probateProductYes"
                                    name="probate_product"
                                    value="1"
                                    onChange={handleChange}
                                    checked={
                                      product_info.probate_product === "1" &&
                                      "checked"
                                    }
                                  />
                                </span>
                                <span className="redio-box">
                                  <CustomInput
                                    type="radio"
                                    id="probateProductNo"
                                    name="probate_product"
                                    value="0"
                                    onChange={handleChange}
                                    checked={
                                      product_info.probate_product === "0" &&
                                      "checked"
                                    }
                                  />
                                </span>
                              </div>
                            </FormGroup>
                          </div>
                          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Button onClick={PriceToggle} className="mr-sm-2">
                              Add Price Range
                            </Button>
                            <label className="red_err">
                              {errors.price_range1}
                            </label>
                            <br />
                            <label className="red_err">
                              {errors.price_range2}
                            </label>
                            <br />
                            <label className="red_err">
                              {errors.price_range3}
                            </label>
                            {modalVal === true ? (
                              <Modal
                                isOpen={modal}
                                //toggle={toggle}
                                className="create-price-modal"
                                //className="create-user-modal"
                              >
                                <ProductPrice
                                  {...props}
                                  receivePriceRange={receivePriceRange}
                                  editPriceRange={price_info}
                                  modalToggle={toggle}
                                />
                              </Modal>
                            ) : (
                              ""
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="7">
                          <div className="add-product-rightside-form">
                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="parent_category">
                                    Parent Category:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="select"
                                    name="parent_category"
                                    id="parent_category"
                                    onChange={handleChange}
                                    value={product_info.parent_category}
                                  >
                                    <option value="">
                                      Select Parent Category
                                    </option>
                                    {parent_categories.map((cat, index) => {
                                      return (
                                        <option
                                          value={cat.category_id}
                                          key={index}
                                        >
                                          {cat.category_name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                  <label className="red_err">
                                    {errors.parent_catgory}
                                  </label>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="sub_category">
                                    Child Category:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="select"
                                    name="sub_category"
                                    id="sub_category"
                                    onChange={handleChange}
                                    value={product_info.sub_category}
                                    multiple
                                  >
                                    <option value="">Select Category</option>
                                    {child_categories.map((cat, index) => {
                                      return (
                                        <option
                                          value={cat.category_id}
                                          key={index}
                                        >
                                          {cat.category_name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                  <label className="red_err">
                                    {errors.sub_category}
                                  </label>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="productName">
                                    Product Name:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="text"
                                    name="product_name"
                                    id="productName"
                                    autoComplete="off"
                                    placeholder="Enter Product Name"
                                    onChange={handleChange}
                                    value={product_info.product_name}
                                  />
                                  <label className="red_err">
                                    {errors.product_name}
                                  </label>
                                </FormGroup>
                              </Col>
                            </Row>
                            {product_info.reiprintmail_product === "1" && (
                              <Card>
                                <CardHeader>
                                  Reiprintmail Default Products
                                </CardHeader>
                                <CardBody>
                                  <Row form>
                                    {attrtypes
                                      .filter((rs) =>
                                        rs.attribute_section
                                          .split(",")
                                          .includes("Reiprintmail_Default")
                                      )
                                      .map((type, index) => {
                                        return (
                                          <Col md={6} key={index}>
                                            <FormGroup>
                                              <Label for={type.type_name}>
                                                {type.type_name}
                                              </Label>

                                              <Input
                                                type="select"
                                                name={type.type_name}
                                                id={type.type_name}
                                                className="rei-select-cls"
                                                required
                                                multiple={
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("accutxt") ||
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("accupix") ||
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("ink") ||
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("paper")
                                                    ? false
                                                    : true
                                                }
                                                defaultValue={
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("envelope") &&
                                                  props.match.params.id !==
                                                    undefined &&
                                                  props.match.params.id > 0
                                                    ? findCommonElement(
                                                        findAttributeIdList(
                                                          "envelope"
                                                        ),
                                                        product_info.attribute_default
                                                      )
                                                      ? product_info.attribute_default
                                                      : "a"
                                                    : type.type_name
                                                        .toLowerCase()
                                                        .match("accupix") &&
                                                      props.match.params.id !==
                                                        undefined &&
                                                      props.match.params.id > 0
                                                    ? findCommonElement(
                                                        findAttributeIdList(
                                                          "accupix"
                                                        ),
                                                        product_info.attribute_default
                                                      )
                                                      ? product_info.attribute_default
                                                      : "a"
                                                    : type.type_name
                                                        .toLowerCase()
                                                        .match("accutxt") &&
                                                      props.match.params.id !==
                                                        undefined &&
                                                      props.match.params.id > 0
                                                    ? findCommonElement(
                                                        findAttributeIdList(
                                                          "accutxt"
                                                        ),
                                                        product_info.attribute_default
                                                      )
                                                      ? product_info.attribute_default
                                                      : "a"
                                                    : type.type_name
                                                        .toLowerCase()
                                                        .match("ink") &&
                                                      props.match.params.id !==
                                                        undefined &&
                                                      props.match.params.id > 0
                                                    ? getCommon(
                                                        findAttributeIdList(
                                                          "ink"
                                                        ),
                                                        product_info.attribute_default
                                                      )
                                                    : type.type_name
                                                        .toLowerCase()
                                                        .match("paper") &&
                                                      props.match.params.id !==
                                                        undefined &&
                                                      props.match.params.id > 0
                                                    ? getCommon(
                                                        findAttributeIdList(
                                                          "paper"
                                                        ),
                                                        product_info.attribute_default
                                                      )
                                                    : product_info.attribute_default
                                                }
                                              >
                                                {attrs
                                                  .filter(
                                                    (rs) =>
                                                      rs.attributetype_id ===
                                                      type.type_id
                                                  )
                                                  .map((attr, index) => {
                                                    return (
                                                      <option
                                                        value={
                                                          attr.attribute_id
                                                        }
                                                        key={index}
                                                      >
                                                        {attr.attribute_name}
                                                      </option>
                                                    );
                                                  })}
                                                {type.type_name
                                                  .toLowerCase()
                                                  .match("accutxt") ||
                                                type.type_name
                                                  .toLowerCase()
                                                  .match("accupix") ||
                                                type.type_name
                                                  .toLowerCase()
                                                  .match("envelope") ? (
                                                  <option value="a">
                                                    None
                                                  </option>
                                                ) : (
                                                  ""
                                                )}
                                              </Input>
                                            </FormGroup>
                                          </Col>
                                        );
                                      })}
                                  </Row>
                                </CardBody>
                              </Card>
                            )}

                            <div className="add-extraform-block">
                              {product_info.api_product === "1" && (
                                <Card>
                                  <CardHeader>
                                    Api Order Default Products
                                  </CardHeader>
                                  <CardBody>
                                    <Row form>
                                      {attrtypes
                                        .filter((rs) =>
                                          rs.attribute_section
                                            .split(",")
                                            .includes("Api_Order_Default")
                                        )
                                        .map((type, index) => {
                                          return (
                                            <Col md={6} key={index}>
                                              <FormGroup>
                                                <Label for={type.type_name}>
                                                  {type.type_name}
                                                </Label>
                                                <Input
                                                  type="select"
                                                  name={type.type_name}
                                                  id={type.type_name}
                                                  required
                                                  defaultValue={
                                                    props.match.params.id !==
                                                      undefined &&
                                                    props.match.params.id > 0
                                                      ? type.type_name
                                                          .toLowerCase()
                                                          .match("envelope")
                                                        ? findCommonElement(
                                                            findAttributeIdList(
                                                              "envelope"
                                                            ),
                                                            product_info.attribute_api
                                                          )
                                                          ? product_info.attribute_api
                                                          : "a"
                                                        : type.type_name
                                                            .toLowerCase()
                                                            .match("accupix")
                                                        ? findCommonElement(
                                                            findAttributeIdList(
                                                              "accupix"
                                                            ),
                                                            product_info.attribute_api
                                                          )
                                                          ? product_info.attribute_api
                                                          : "a"
                                                        : type.type_name
                                                            .toLowerCase()
                                                            .match("accutxt")
                                                        ? findCommonElement(
                                                            findAttributeIdList(
                                                              "accutxt"
                                                            ),
                                                            product_info.attribute_api
                                                          )
                                                          ? product_info.attribute_api
                                                          : "a"
                                                        : type.type_name
                                                            .toLowerCase()
                                                            .match("ink") &&
                                                          props.match.params
                                                            .id !== undefined &&
                                                          props.match.params
                                                            .id > 0
                                                        ? getCommon(
                                                            findAttributeIdList(
                                                              "ink"
                                                            ),
                                                            product_info.attribute_api
                                                          )
                                                        : type.type_name
                                                            .toLowerCase()
                                                            .match("paper") &&
                                                          props.match.params
                                                            .id !== undefined &&
                                                          props.match.params
                                                            .id > 0
                                                        ? getCommon(
                                                            findAttributeIdList(
                                                              "paper"
                                                            ),
                                                            product_info.attribute_api
                                                          )
                                                        : product_info.attribute_api
                                                      : product_info.attribute_api
                                                  }
                                                  multiple={
                                                    type.type_name
                                                      .toLowerCase()
                                                      .match("accutxt") ||
                                                    type.type_name
                                                      .toLowerCase()
                                                      .match("accupix") ||
                                                    type.type_name
                                                      .toLowerCase()
                                                      .match("ink") ||
                                                    type.type_name
                                                      .toLowerCase()
                                                      .match("paper")
                                                      ? false
                                                      : true
                                                  }
                                                  className="api-select-cls"
                                                >
                                                  {/* <option value="" disabled>
                                                  Please Select
                                                </option> */}
                                                  {attrs
                                                    .filter(
                                                      (rs) =>
                                                        rs.attributetype_id ===
                                                        type.type_id
                                                    )
                                                    .map((attr, index) => {
                                                      return (
                                                        <option
                                                          value={
                                                            attr.attribute_id
                                                          }
                                                          key={index}
                                                        >
                                                          {attr.attribute_name}
                                                        </option>
                                                      );
                                                    })}
                                                  {type.type_name
                                                    .toLowerCase()
                                                    .match("accutxt") ||
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("accupix") ||
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("envelope") ? (
                                                    <option value="a">
                                                      None
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Input>
                                              </FormGroup>
                                            </Col>
                                          );
                                        })}
                                    </Row>
                                  </CardBody>
                                </Card>
                              )}
                              {product_info.probate_product === "1" && (
                                <Card>
                                  <CardHeader>
                                    Probate Order Default Products
                                  </CardHeader>
                                  <CardBody>
                                    <Row form>
                                      {attrtypes
                                        .filter((rs) =>
                                          rs.attribute_section
                                            .split(",")
                                            .includes("Probate_Order_Default")
                                        )
                                        .map((type, index) => {
                                          return (
                                            <Col md={6} key={index}>
                                              <FormGroup>
                                                <Label for={type.type_name}>
                                                  {type.type_name}
                                                </Label>
                                                <Input
                                                  type="select"
                                                  name={type.type_name}
                                                  id={type.type_name}
                                                  className="probate-select-cls"
                                                  required
                                                  multiple={
                                                    type.type_name
                                                      .toLowerCase()
                                                      .match("accutxt") ||
                                                    type.type_name
                                                      .toLowerCase()
                                                      .match("accupix") ||
                                                    type.type_name
                                                      .toLowerCase()
                                                      .match("ink") ||
                                                    type.type_name
                                                      .toLowerCase()
                                                      .match("paper")
                                                      ? false
                                                      : true
                                                  }
                                                  defaultValue={
                                                    props.match.params.id !==
                                                      undefined &&
                                                    props.match.params.id > 0
                                                      ? type.type_name
                                                          .toLowerCase()
                                                          .match("envelope")
                                                        ? findCommonElement(
                                                            findAttributeIdList(
                                                              "envelope"
                                                            ),
                                                            product_info.attribute_probate
                                                          )
                                                          ? product_info.attribute_probate
                                                          : "a"
                                                        : type.type_name
                                                            .toLowerCase()
                                                            .match("accupix")
                                                        ? findCommonElement(
                                                            findAttributeIdList(
                                                              "accupix"
                                                            ),
                                                            product_info.attribute_probate
                                                          )
                                                          ? product_info.attribute_probate
                                                          : "a"
                                                        : type.type_name
                                                            .toLowerCase()
                                                            .match("accutxt")
                                                        ? findCommonElement(
                                                            findAttributeIdList(
                                                              "accutxt"
                                                            ),
                                                            product_info.attribute_probate
                                                          )
                                                          ? product_info.attribute_probate
                                                          : "a"
                                                        : type.type_name
                                                            .toLowerCase()
                                                            .match("ink") &&
                                                          props.match.params
                                                            .id !== undefined &&
                                                          props.match.params
                                                            .id > 0
                                                        ? getCommon(
                                                            findAttributeIdList(
                                                              "ink"
                                                            ),
                                                            product_info.attribute_probate
                                                          )
                                                        : type.type_name
                                                            .toLowerCase()
                                                            .match("paper") &&
                                                          props.match.params
                                                            .id !== undefined &&
                                                          props.match.params
                                                            .id > 0
                                                        ? getCommon(
                                                            findAttributeIdList(
                                                              "paper"
                                                            ),
                                                            product_info.attribute_probate
                                                          )
                                                        : product_info.attribute_probate
                                                      : product_info.attribute_probate
                                                  }
                                                >
                                                  {attrs
                                                    .filter(
                                                      (rs) =>
                                                        rs.attributetype_id ===
                                                        type.type_id
                                                    )
                                                    .map((attr, index) => {
                                                      return (
                                                        <option
                                                          value={
                                                            attr.attribute_id
                                                          }
                                                          key={index}
                                                        >
                                                          {attr.attribute_name}
                                                        </option>
                                                      );
                                                    })}
                                                  {type.type_name
                                                    .toLowerCase()
                                                    .match("accutxt") ||
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("accupix") ||
                                                  type.type_name
                                                    .toLowerCase()
                                                    .match("envelope") ? (
                                                    <option value="a">
                                                      None
                                                    </option>
                                                  ) : (
                                                    ""
                                                  )}
                                                </Input>
                                              </FormGroup>
                                            </Col>
                                          );
                                        })}
                                    </Row>
                                  </CardBody>
                                </Card>
                              )}
                            </div>
                            <FormGroup>
                              <Label for="description">
                                Description:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="textarea"
                                name="description"
                                id="description"
                                placeholder="Enter Description"
                                onChange={handleChange}
                                value={product_info.description}
                              />
                              <label className="red_err">
                                {errors.description}
                              </label>
                            </FormGroup>
                            <FormGroup>
                              <Label for="template_code">
                                Product Template Code:{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="text"
                                name="template_code"
                                id="template_code"
                                autoComplete="off"
                                placeholder="Enter Template Code"
                                onChange={handleChange}
                                value={product_info.template_code}
                              />
                              <label className="red_err">
                                {errors.template_code}
                              </label>
                            </FormGroup>
                            <Row form>
                              <Col md={12}>
                                <div className="form-group">
                                  <label>
                                    Selected Template:{" "}
                                    <span>
                                      {/* {product_info.template_name} */}
                                      {selectedTemplate &&
                                      selectedTemplate.label !== ""
                                        ? selectedTemplate.label
                                        : product_info.template_name}
                                    </span>
                                  </label>{" "}
                                </div>
                              </Col>
                            </Row>
                            <Row form>
                              <Col md={6}>
                                <div className="form-group">
                                  <div className="sample-csv-class">
                                    <label>
                                      Select Template:
                                      <span className="text-danger">
                                        *
                                      </span>{" "}
                                    </label>{" "}
                                  </div>
                                  <AsyncPaginate
                                    defaultValue={selectedTemplate.value}
                                    loadOptions={loadOptions}
                                    onChange={(e) => onChangeTemplate(e)}
                                    additional={{
                                      page: 1,
                                    }}
                                  />
                                  <label className="red_err">
                                    {errors.product_templates_id}
                                  </label>
                                </div>
                              </Col>
                              {/* <Col md={6}>
                                <FormGroup>
                                  <Label for="template">
                                    Template:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    // multiple
                                    type="select"
                                    name="product_templates_id"
                                    id="template"
                                    onChange={handleChange}
                                    value={product_info.product_templates_id}
                                  >
                                    <option value="" disabled>
                                      Select Template
                                    </option>
                                    {templates.map((template, index) => {
                                      return (
                                        <option
                                          value={template.template_id}
                                          key={index}
                                        >
                                          {template.template_name}
                                        </option>
                                      );
                                    })}
                                  </Input>
                                  <label className="red_err">
                                    {errors.product_templates_id}
                                  </label>
                                </FormGroup>
                              </Col> */}
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="sortOrder">
                                    Sort Order:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="ordering"
                                    id="productName"
                                    autoComplete="off"
                                    placeholder="Enter Sort Order"
                                    value={product_info.ordering}
                                    onChange={handleChange}
                                  />
                                  <label className="red_err">
                                    {errors.ordering}
                                  </label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <h4 className="titel">Desired Mailing Dates:</h4>
                            <Row form>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="mailingDateLimit">
                                    Mailing Date Limit:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="mailing_date_limit"
                                    id="mailingDateLimit"
                                    placeholder="5"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    value={product_info.mailing_date_limit}
                                  />
                                  <label className="red_err">
                                    {errors.mailing_date_limit}
                                  </label>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="lockoutDate">
                                    Lockout Date:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="lockout_date"
                                    id="lockoutDate"
                                    placeholder="3"
                                    autoComplete="off"
                                    onChange={handleChange}
                                    value={product_info.lockout_date}
                                  />
                                  <label className="red_err">
                                    {errors.lockout_date}
                                  </label>
                                </FormGroup>
                              </Col>
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="min_qnty">
                                    Minimum Quantity:{" "}
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Input
                                    type="number"
                                    name="min_qnty"
                                    id="min_qnty"
                                    placeholder=""
                                    autoComplete="off"
                                    onChange={handleChange}
                                    value={product_info.min_qnty}
                                  />
                                  <label className="red_err">
                                    {errors.min_qnty}
                                  </label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <FormGroup tag="fieldset">
                              <div className="radio-block">
                                <legend>Status:</legend>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="is_active"
                                      value={"1"}
                                      checked={
                                        product_info.is_active === "1"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />{" "}
                                    Active
                                  </Label>
                                </FormGroup>
                                <FormGroup check>
                                  <Label check>
                                    <Input
                                      type="radio"
                                      name="is_active"
                                      value={"0"}
                                      checked={
                                        product_info.is_active !== "1"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />{" "}
                                    Inactive
                                  </Label>
                                </FormGroup>
                              </div>
                            </FormGroup>
                          </div>
                        </Col>
                      </Row>

                      <div className="submit-button-block">
                        <Button type="submit">
                          Save Changes <i className="ni ni-bold-right"></i>
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="4" lg="3">
                <div id="scaleBoxLayout" className="">
                  <div className="design-thum-block">
                    <UploadImage
                      btn_name="Upload Photo"
                      url={API_PRODUCT_FULL_IMAGE}
                      original_img={product_info.FullSizeImagePath}
                      setImage={setImage}
                      {...props}
                    />
                    <UploadImage
                      btn_name="Upload Thumb Photo"
                      url={API_PRODUCT_THUMB_IMAGE}
                      original_img={product_info.imagepathname}
                      setImage={setThumbImage}
                      {...props}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </main>
      )}
    </>
  );
};

export default withRouter(AddProduct);
