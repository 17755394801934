import React, { Fragment, useEffect, useState } from "react";
import ResizableRect from "react-resizable-rotatable-draggable";

const ResizableNonEditableAccupix = (props) => {
  const [width, setWidth] = useState(Math.round(props.width));
  const [height, setHeight] = useState(Math.round(props.height));
  const [top, setTop] = useState(Math.round(props.top));
  const [left, setLeft] = useState(Math.round(props.left));
  const [rotateAngle, setRotateAngle] = useState(props.rotateAngle);
  const [backgroundColor, setBackgroundColor] = useState(props.backgroundColor);
  const [roundedCorner, setRoundedCorner] = useState(props.roundedCorner);
  const [margin_top, setMarginTop] = useState(props.margin_top);
  const [margin_left, setMarginLeft] = useState(props.margin_left);
  const [padding_top, setPaddingTop] = useState(props.padding_top);
  const [padding_right, setPaddingRight] = useState(props.padding_right);
  const [padding_bottom, setPaddingBottom] = useState(props.padding_bottom);
  const [padding_left, setPaddingLeft] = useState(props.padding_left);
  const [aspect_ratio_width, setAspectRatioWidth] = useState(
    props.aspect_ratio_width
  );
  const [aspect_ratio_height, setAspectRatioHeight] = useState(
    props.aspect_ratio_height
  );
  const [z_index, setZIndex] = useState(
    props.z_index
  );
  const [id] = useState(props.id);
  let topy;
  let leftx;

  useEffect(() => {
    if (props.aspectRatioFlag === true) {
      setWidth(Math.round(props.width));
      setHeight(Math.round(props.height));
    }
  }, [
    props.aspectRatioFlag,
    props.width,
    props.height,
    props.aspect_ratio_width,
    props.aspect_ratio_height,
  ]);

  useEffect(() => {
    setBackgroundColor(props.backgroundColor);
    setRoundedCorner(props.roundedCorner);
    setMarginTop(props.margin_top);
    setMarginLeft(props.margin_left);
    setPaddingTop(props.padding_top);
    setPaddingRight(props.padding_right);
    setPaddingBottom(props.padding_bottom);
    setPaddingLeft(props.padding_left);
    setAspectRatioWidth(props.aspect_ratio_width);
    setAspectRatioHeight(props.aspect_ratio_height);
    setZIndex(props.z_index);
  }, [
    props.backgroundColor,
    props.roundedCorner,
    props.margin_top,
    props.margin_left,
    props.padding_top,
    props.padding_right,
    props.padding_bottom,
    props.padding_left,
    props.aspect_ratio_width,
    props.aspect_ratio_height,
    props.z_index
  ]);

  const contentStyle = {
    top,
    left,
    width,
    height,
    position: "absolute",
    transform: `rotate(${rotateAngle}deg)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "0",
    margin: "0",
    zIndex: z_index,
    backgroundColor: backgroundColor,
    borderRadius: roundedCorner + "%",
  };

  const handleResize = (style, isShiftKey, type) => {
    const { top, left, width, height } = style;
    setWidth(Math.round(width));
    setHeight(Math.round(height));
    setTop(Math.round(top));
    setLeft(Math.round(left));

    props.parentSetNonEditableAccupixDesign(
      document.getElementById("editor").innerHTML,
      id,
      Math.round(left),
      Math.round(top),
      Math.round(width),
      Math.round(height),
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_ratio_width,
      aspect_ratio_height,
      z_index
    );
  };

  const handleRotate = (rotateAngle) => {
    setRotateAngle(rotateAngle);

    props.parentSetNonEditableAccupixDesign(
      document.getElementById("editor").innerHTML,
      id,
      Math.round(left),
      Math.round(top),
      Math.round(width),
      Math.round(height),
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_ratio_width,
      aspect_ratio_height,
      z_index
    );
  };

  const handleDrag = (deltaX, deltaY) => {
    leftx = left + deltaX;
    topy = top + deltaY;

    setTop(topy);
    setLeft(leftx);

    props.parentSetNonEditableAccupixDesign(
      document.getElementById("editor").innerHTML,
      id,
      Math.round(left),
      Math.round(top),
      Math.round(width),
      Math.round(height),
      rotateAngle,
      backgroundColor,
      roundedCorner,
      true,
      margin_top,
      margin_left,
      padding_top,
      padding_right,
      padding_bottom,
      padding_left,
      aspect_ratio_width,
      aspect_ratio_height,
      z_index
    );
  };

  return (
    <Fragment>
      <div style={contentStyle}>
        {props.children}

        <div className="align-element-position" style={{ display: "none" }}>
          <span>
            <i className="fa fa-arrow-down" aria-hidden="true"></i>{Math.round(props.top) + 26}
          </span>
          <span>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>{Math.round(props.left) + 26}
          </span>
        </div>
      </div>

      <ResizableRect
        top={top}
        rotatable
        left={left}
        aspectRatio={width / height}
        minWidth={10}
        width={width}
        minHeight={10}
        height={height}
        onDrag={!props.accupix_satellite_lock ? handleDrag : null}
        //onRotate={handleRotate}
        onResize={!props.accupix_satellite_lock ? handleResize : null}
        zoomable={!props.accupix_satellite_lock ? "n, w, s, e, nw, ne, se, sw" : ""}
        rotateAngle={rotateAngle}
      />
    </Fragment>
  );
};

export default ResizableNonEditableAccupix;
