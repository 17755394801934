import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavLink,
  Modal,
  ModalBody,
} from "reactstrap";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import textiumLogo from "../../assets/img/textiumLogo.png";

import { BreadCrumb } from "../../config/Components";
import {
  API_ORDER_DETAILS,
  API_ORDER_HOLD_OR_RESUME,
  API_ADD_TO_ORDER,
  API_GET_ORDER_DETAIL,
  API_DRAFT_DELETE,
  API_DRAFT_LIST,
} from "../../config/Api";

import DataTableSettings from "../../utils/DataTableSettings";
import { CartListDetails } from "../../config/Components";

import "../../assets/css/data-table.css";
import "./Orders.css";
import { useHistory } from "react-router-dom";
import moment from "moment";
import OrderSearch from "./OrderSearch";

const DraftOrders = (props) => {
  const history = useHistory();

  const [products, setProducts] = useState([]);
  const [modal, setModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [rows, setRows] = useState(10);
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const userType = useSelector((store) => store.getUserType);
  const [pageNo, setPageNo] = useState(1);
  const [orderSearchParam, setOrderSearchParam] = useState({
    username: "",
    orderid: "",
    campaign_name: "",
    campaign_type: "",
    campaign_target: "",
    order_status: "",
    start_date: "",
    end_date: "",
  });
  const [totalRows, setTotalRows] = useState(0);

  const orderStatus = [
    "Inactive",
    "Active",
    "Draft",
    "Pending Payment",
    "In Production",
    "Created",
    "Complete",
    "Cancelled order",
    "Batch Preview",
    "Awaiting Mailing List",
    "Rei cancel order",
  ];

  const searchParam = [
    "orderid",
    "campaign_name",
    "campaign_target",
    "campaign_type",
    "order_status",
    "AddedDate",
  ];
  const toggle = () => setModal(!modal);

  useEffect(() => {
    fetchDraft();
  }, []);

  useEffect(() => {
    fetchDraft();
  }, [pageNo]);

  useEffect(() => {
    fetchDraft();
  }, [orderSearchParam]);

  const dateformatter = (d) => {
    let dateSelected =
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2) +
      "/" +
      d.getFullYear();
    return dateSelected;
  };

  const getRequestParam = () => {
    let tempParam = "";
    Object.keys(orderSearchParam).map((item) => {
      if (orderSearchParam[item]) {
        if (item === "start_date") {
          tempParam += item + "=" + dateformatter(orderSearchParam[item]) + "&";
        } else if (item === "end_date") {
          tempParam += item + "=" + dateformatter(orderSearchParam[item]) + "&";
        } else {
          tempParam += item + "=" + orderSearchParam[item] + "&";
        }
      }
    });
    return tempParam;
  };

  const fetchDraft = () => {
    let reqParam = getRequestParam();
    props
      .callRequest(
        "GET",
        API_DRAFT_LIST +
          "?page_number=" +
          pageNo +
          "&page_size=" +
          rows +
          "&" +
          reqParam,
        true,
        null
      )
      .then((res) => {
        let tempData = [];
        setTotalRows(res.data.total_records);
        res.data &&
          res.data.orders.map((item) => {
            tempData.push({
              ...item,
              is_calendly: item && item.is_calendly ? item.is_calendly : "",
              user_name:
                item && item.user && item.user.username
                  ? item.user.username
                  : "",
              campaign_name:
                item && item.campaign && item.campaign.campaign_name
                  ? item.campaign.campaign_name
                  : "",
              campaign_target:
                item && item.campaign && item.campaign.campaign_target === "0"
                  ? "Seller"
                  : "Buyer",
              campaign_type:
                item && item.campaign && item.campaign.campaign_type == "0"
                  ? "Direct Mail"
                  : item && item.campaign && item.campaign.campaign_type == "1"
                  ? "Probate"
                  : "Micro-Campaigns",
              order_status:
                item.is_hold == 1
                  ? "Hold"
                  : item.status
                  ? orderStatus[parseInt(item.status)]
                  : parseInt(item.status) + "",
              AddedDate: newDateFormater(item.date_added),
            });
          });

        setProducts(tempData);
      });
  };
  useEffect(() => {
    fetchDraft();
  }, [rows]);
  const dateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return d.getMonth() + 1 + "-" + d.getDate() + "-" + d.getFullYear();
  };

  const newDateFormater = (inputValue) => {
    let d = new Date(inputValue * 1000);
    return (
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      ("0" + d.getDate()).slice(-2) +
      "/" +
      d.getFullYear()
    );
  };

  const changeRowPerPage = (perPage) => {
    setRows(perPage);
  };

  const handleCalendlyPopUp = (user) => {
    setOpen(true);
    setUserInfo({
      fname: user.firstname,
      lname: user.lastname,
    });
  };
  const closeCalendlyHandler = (user) => {
    setOpen(false);
  };

  // const listStatus = ["red", "green", "orange"];
  const listStatus = ["red", "orange", "#800080", "green"];

  const columns = [
    {
      name: "Order ID",
      selector: "orderid",
      sortable: false,
      maxWidth: 400,
      minWidth: 140,
      width: 200,
    },
    {
      name: "Campaign Name",
      selector: "campaign_name",
      sortable: true,
    },
    {
      name: "Campaign Type",
      selector: "campaign_type",
      sortable: true,
    },
    {
      name: "Campaign Target",
      selector: "campaign_target",
      sortable: true,
    },
    {
      name: "Grand Total",
      selector: "grand_total",
      sortable: true,
      cell: (row) =>
        Number(parseFloat(row.grand_total)).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
    },
    {
      name: "Payment Mode",
      selector: "payment_mode",
      sortable: true,
      cell: (row) => (row.order_status === "Draft" ? "--" : row.payment_mode),
    },
    {
      name: "Payment Status",
      selector: "paymentStatus",
      sortable: true,
      cell: (row) => (row.order_status === "Draft" ? "--" : row.paymentStatus),
    },
    {
      name: "Order Status",
      selector: "order_status",
      sortable: true,
    },
    {
      name: "Created Date",
      selector: "date_added",
      sortable: true,
      cell: (row) => {
        return newDateFormater(row.date_added);
      },
    },
    {
      name: "Proof",
      selector: "is_proof_approved",
      sortable: true,
      cell: (row) => {
        return (
          <>
            <i
              className="fas fa-circle"
              style={{ color: row.is_proof_approved == 1 ? "green" : "orange" }}
            ></i>
          </>
        );
      },
    },
    {
      name: "List",
      selector: "list_status",
      sortable: true,
      cell: (row) => {
        return (
          <>
            <i
              className="fas fa-circle"
              style={{
                color: listStatus[row.list_status],
              }}
            ></i>
          </>
        );
      },
    },

    {
      name: userType && userType == 2 ? null : "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) =>
        userType && userType == 2 ? null : (
          <div className="action-div">
            {row.is_accutxt_exist == 1 ? (
              <span>
                {" "}
                <img
                  src={textiumLogo}
                  alt="textium logo"
                  className="fas fa-comment"
                  style={{ height: "15px", width: "15px" }}
                />
              </span>
            ) : null}
            <span onClick={() => copyOrder(row.order_id)}>
              <i className="fas fa-copy"></i>
            </span>
            {row.is_order_lock_out == 0 && (
              <span
                title={row.is_hold == 1 ? "Resume" : "Hold"}
                onClick={() =>
                  holdResumeOrder(row.order_id, row.is_hold == 1 ? 0 : 10)
                }
              >
                <i
                  className={row.is_hold == 0 ? "fas fa-pause" : "fas fa-play"}
                ></i>
              </span>
            )}
            {(row.status == 2 || row.status == 5 || row.status == 3) &&
              row.is_order_lock_out != 1 && (
                <span
                  onClick={() => orderEdit(row.order_id, row.status)}
                  title="Edit Draft"
                >
                  <i className="fas fa-edit"></i>
                </span>
              )}

            {row.status == 2 && (
              <span onClick={() => orderDelete(row.order_id)} title="Delete">
                <i className="fas fa-trash"></i>
              </span>
            )}

            {row.is_calendly == 1 && (
              <span
                onClick={() => handleCalendlyPopUp(row.user)}
                title="is calendly"
              >
                <i className="fas fa-flag"></i>
              </span>
            )}
          </div>
        ),
    },
  ];

  const orderDelete = (order_id) => {
    if (order_id) {
      //
      Swal.fire({
        title: "Do you want to delete your draft order?",
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Ok",
        // denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          props
            .callRequest("POST", API_DRAFT_DELETE, true, { order_id: order_id })
            .then((res) => {
              fetchDraft();
              Swal.fire({
                icon: "success",
                title: "Success",
                text: res.data.Message,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    }
  };

  const orderEdit = (order_id, ordStatus) => {
    history.push("/edit-order/" + order_id + "/" + ordStatus + "/edit");
  };

  const export_column = [
    "orderid",
    "campaign_name",
    "campaign_target",
    "campaign_type",
    "payment_mode",
    "paymentStatus",
    "grand_total",
    "order_status",
    "AddedDate",
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <OrderSearch
          setOrderSearchParam={setOrderSearchParam}
          orderSearchParam={orderSearchParam}
          draftSearch={0}
        />
      </FormGroup>
    );
  }, [filterText]);

  const Export = ({ onExport, onExportView }) => (
    <div className="right-button-block">
      {userType && userType == 2 ? null : (
        <Button onClick={() => props.history.push("/create-order")}>
          <i className="ni ni-fat-add"></i> Create Order
        </Button>
      )}
      <UncontrolledDropdown>
        <DropdownToggle caret>
          <i className="ni ni-ungroup"></i> Export
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={(e) => onExportView(e.target.value)}>
            Export View
          </DropdownItem>
          <DropdownItem onClick={(e) => onExport(e.target.value)}>
            Export All
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {/* {userType && userType == 2 ? null : (
        <Button>
          <i className="bi-pencil-square"></i> Edit Columns
        </Button>
      )} */}
      {/* <Button
        disabled={products.length > 0 ? false : true}
        onClick={(e) => onExport(e.target.value)}
      >
        <i className="ni ni-ungroup"></i> Export
      </Button> */}
    </div>
  );

  const actionsExport = useMemo(
    () => (
      <Export
        onExport={() =>
          DataTableSettings.downloadCSV(
            products,
            export_column,
            props.generateUniqueId()
          )
        }
        onExportView={() =>
          DataTableSettings.downloadCSV(
            products.slice(0, rows),
            export_column,
            props.generateUniqueId()
          )
        }
      />
    ),
    [products]
  );

  const holdResumeOrder = (orderId, status) => {
    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // let tempStatus = status==0 ? 10 : 0;

    //API_ORDER_HOLD_OR_RESUME
    const formData = new FormData();
    formData.append("order_id", orderId);
    formData.append("status", status);

    props
      .callRequest("POST", API_ORDER_HOLD_OR_RESUME, true, formData)
      .then((res) => {
        Swal.close();
        Swal.fire("Success", "Status has been updated successfully", "success");
        fetchDraft();
      })
      .catch((e) => {
        Swal.close();
        let msg = e.response.data && e.response.data.join();
        Swal.fire("Error", msg, "error");
      });
  };

  const copyOrder = (orderId) => {
    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    props
      .callRequest("GET", API_GET_ORDER_DETAIL + orderId, true, null)
      .then((res) => {
        processCopy(res.data);
      });
  };

  const processCopy = (orderDetail) => {
    try {
      let products = orderDetail.Product.map((cartPrd, ind) => {
        let temp = {
          ...cartPrd,
          // date: moment(calculateMin(5)).unix().toString(),
          coupon_discount_price: Number(cartPrd.coupon_discount_price),
          is_send_without_return_address: Number(
            cartPrd.is_send_without_return_address
          ),
          is_proof_approved: Number(cartPrd.is_proof_approved),
        };

        if (temp.textResponse1 == "") {
          delete temp.textResponse1;
        }

        if (temp.accutext == "") {
          delete temp.accutext;
        }

        if (temp.accutext_id == 0) {
          delete temp.accutext_id;
        }

        if (temp.accupix_id == 0) {
          delete temp.accupix_id;
        }

        if (temp.accupix == "") {
          delete temp.accupix;
        }

        if (temp.envelope == "") {
          delete temp.envelope;
          delete temp.envelope_id;
        }

        return temp;
      });

      const post_data = {
        user: orderDetail.OrderDetails.user.user_id.toString(),
        campaign: orderDetail.OrderDetails.campaign.campaign_id,
        OrderProducts: products, //orderDetail.Product,
        UploadedFiles: [
          {
            filename: "",
            filepath: "",
          },
        ],
      };

      let APIAct = API_ADD_TO_ORDER;
      let mailinglist_attr_id =
        "Mailinglist" in orderDetail &&
        orderDetail.Mailinglist &&
        orderDetail.Mailinglist.length > 0
          ? orderDetail.Mailinglist[0]["mailinglist_attr_id"]
          : "";
      let desired_quantity =
        "Mailinglist" in orderDetail &&
        orderDetail.Mailinglist &&
        orderDetail.Mailinglist.length > 0
          ? orderDetail.Mailinglist[0]["mailingqty"]
          : "";

      if (mailinglist_attr_id != "") {
        post_data["mailinglist_attr_id"] = Number(mailinglist_attr_id);
      }

      if (desired_quantity != "") {
        post_data["mailingqty"] = Number(desired_quantity);
      }

      props
        .callRequest("POST", APIAct, true, post_data)
        .then((res) => {
          Swal.close();
          Swal.fire(
            "Success",
            "New duplicate order has been created as draft",
            "success"
          );
          fetchDraft();
        })
        .catch((err) => {
          Swal.close();
          let msg =
            "Error" in err.response.data && err.response.data["Error"]
              ? err.response.data["Error"]
              : "Something went wrong, Please try again";
          Swal.fire("Error!", msg, "error");
        });
    } catch (e) {
      console.log("Error", e);
    }
  };

  const calculateMin = (mailing_date_limit) => {
    let d = new Date();
    var startDate = d.toISOString();
    startDate = new Date(startDate);
    var endDate = "",
      noOfDaysToAdd = mailing_date_limit,
      count = 0;
    while (count < noOfDaysToAdd) {
      endDate = new Date(startDate.setDate(startDate.getDate() + 1));
      if (endDate.getDay() != 0 && endDate.getDay() != 6) {
        count++;
      }
    }

    return endDate;
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Drafts"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-cart"></i>
            </span>
            Draft Orders
          </h1>
        </div>

        <div className="listingtable-main-block order-tabile-block">
          <Card>
            <CardBody>
              <div className="list-filtered-table-block orders-class">
                <DataTable
                  className="table-min-height"
                  columns={columns}
                  data={DataTableSettings.filterItems(
                    products,
                    searchParam,
                    filterText
                  )}
                  pagination
                  paginationPerPage={DataTableSettings.paginationPerPage}
                  paginationRowsPerPageOptions={
                    DataTableSettings.paginationRowsPerPageOptions
                  }
                  actions={actionsExport}
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangePage={(e) => setPageNo(e)}
                  onChangeRowsPerPage={changeRowPerPage}
                  subHeader
                  subHeaderComponent={subHeaderComponentMemo}
                  persistTableHead
                  expandableRows
                  expandableRowsComponent={
                    <CartListDetails
                      {...props}
                      sectionType={"order"}
                      fetchDraft={fetchDraft}
                      isDraft={1}
                    />
                  }
                />
              </div>
              {/* <div className="status-infoblock">
                <div className="statusinfo-box">
                  <span className="status-round all-jobs"></span>
                  All Jobs
                </div>
                <div className="statusinfo-box">
                  <span className="status-round on-hold"></span>
                  On Hold
                </div>
                <div className="statusinfo-box">
                  <span className="status-round ready-to-post"></span>
                  Ready to Post
                </div>
              </div> */}
            </CardBody>
          </Card>
        </div>
      </main>

      <Modal isOpen={open} toggle={closeCalendlyHandler}>
        <div className="accutxt-info-modal">
          <h2>Calendly Information</h2>
          <span style={{ cursor: "pointer" }}>
            <i
              onClick={closeCalendlyHandler}
              className="fas fa-times-circle"
            ></i>
          </span>
        </div>
        <div style={{ borderRadius: "5px", overflow: "hidden" }}>
          <ModalBody>
            {userInfo.fname} {userInfo.lname} has booked for free consultation.
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default DraftOrders;
