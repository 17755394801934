import React, { Fragment, useState, useEffect } from 'react'
import ResizableRect from 'react-resizable-rotatable-draggable'

const ResizableImageComponent = props => {
  const [width, setWidth] = useState(props.width)
  const [height, setHeight] = useState(props.height)
  const [top, setTop] = useState(props.top)
  const [left, setLeft] = useState(props.left)
  const [rotateAngle] = useState(props.rotateAngle)
  const [id] = useState(props.id);
  let topy;
  let leftx;

  useEffect(() => {
    setWidth(props.width);
    setHeight(props.height);
  }, [props.width, props.height]);

  const contentStyle = {
    top,
    left,
    width,
    height,
    position: 'absolute',
    transform: `rotate(${rotateAngle}deg)`
  }

  const handleResize = (style, isShiftKey, type) => {
    const { top, left, width, height } = style
    setWidth(Math.round(width))
    setHeight(Math.round(height))
    setTop(Math.round(top))
    setLeft(Math.round(left))
    props.parentSetImageDesign(document.getElementById("editor").innerHTML, id, left, top, width, height);
  }

  /*const handleRotate = rotateAngle => {
     setRotateAngle(rotateAngle)
   }*/

  const handleDrag = (deltaX, deltaY) => {
    leftx = left + deltaX;
    topy = top + deltaY;
    setLeft(leftx)
    setTop(topy)
    props.parentSetImageDesign(
      document.getElementById("editor").innerHTML,
      id,
      left,
      top,
      width,
      height,
    );
  }

  return (
    <Fragment>
      <div className="custom-image-drag" style={contentStyle}>
        {props.children}

        <div className="align-element-position" style={{ display: "none" }}>
          <span>
            <i className="fa fa-arrow-down" aria-hidden="true"></i>{Math.round(props.top) + 26}
          </span>
          <span>
            <i className="fa fa-arrow-right" aria-hidden="true"></i>{Math.round(props.left) + 26}
          </span>
        </div>
      </div>

      <ResizableRect
        top={top}
        rotatable
        left={left}
        //aspectRatio={props.original_image_width / props.original_image_height}
        minWidth={10}
        width={width}
        minHeight={10}
        height={height}
        onDrag={!props.custom_image_lock ? handleDrag : null}
        //onRotate={handleRotate}
        onResize={!props.custom_image_lock ? handleResize : null}
        zoomable={!props.custom_image_lock ? 'n, w, s, e, nw, ne, se, sw' : ''}
      //rotateAngle={rotateAngle}
      />
    </Fragment>
  )
}

export default ResizableImageComponent;
