import React, { Component } from "react";
import axios from "axios";
import "./assets/css/TemplateDesign.css";
import DesignerModal from "./DesignerModal";
import Swal from "sweetalert2";
import { refresh } from "../../classes/auth";
import { withRouter } from "react-router-dom";
import accupix_img from "../../assets/img/accupix.png";
import accupix_streetview_img from "../../assets/img/streetview.png";
let input_font_style = {
  display: "block",
};

let width = "500in";
let height = "500in";
let bleed = "";
let is_bleed = "";
let margin = "";
let size_id = "";
let user_id = "";
let img_style = {
  display: "none",
};

let upload_font = {
  display: "block",
};

let new_id;
let png_front_url = "";

class Singlepannel extends Component {
  constructor(props) {
    super(props);
    width = this.props.myProps.width + "in";
    height = this.props.myProps.height + "in";
    bleed = this.props.myProps.bleed + "in";
    margin = this.props.myProps.margin + "in";
    size_id = this.props.myProps.size_id;
    user_id = this.props.myProps.user_id;
    is_bleed = this.props.myProps.is_bleed;
    this.state = {
      font_background: null,
      isAdvanceEdit: true,
      template_name: this.props.myProps.template_name,
      template_type: this.props.myProps.template_type,
      width: width,
      height: height,
      bleed: bleed,
      margin: margin,
      editor_data: "",
      size_id: size_id,
      user_id: user_id,
      accupix_type_front: 0,
      accupix_type_front_two: 0,
      accupix_aspect_ratio_front: "3:2",
      accupix_aspect_ratio_front_two: "3:2",
      is_bleed: is_bleed,
      is_front_img_transparent: "1",
      front: {
        template_background_color: "#FFFFFF",
        non_editable_text: [],
        non_editable_postage: [],
        non_editable_accupix: [],
        non_editable_accupix_streetview: [],

        image_customs_data: [
          {
            id: 0,
            img_box_title: "Background Image",
            img_src: "",
            top: 100,
            left: 100,
            width: 100,
            height: 100,
            order: 100,
            original_image_width: 100,
            original_image_height: 100,
            custom_image_lock: false,
          },
        ],
        customs_text_data: [],
        logo_uploader_component: [],
        data_sequence_component: [],
        data_matrix_component: [],
        imbarcode_component: [],
        ruler: {
          is_ruler: false,
          horizontal_line: [],
          vertical_line: [],
        },
        front_min_max: {},
        line_shape: [],
      },
    };

    input_font_style = {
      display: "block",
    };
    img_style = {
      display: "none",
    };
    upload_font = {
      display: "block",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    event.preventDefault();
    let imagesrc;
    const [file] = event.target.files;

    if (file) {
      const formData = new FormData();
      formData.append("uplodedFile", file, file.name);
      axios
        .post(process.env.REACT_APP_VDP_API + "api/saveFile", formData, {
          timeout: 0,
        })
        .then((res) => {
          imagesrc = process.env.REACT_APP_VDP_API + "api" + res.data;
          this.setState({
            font_background: imagesrc,
          });

          this.setState({
            isAdvanceEdit: true,
          });
          document.getElementById("front_img").src = imagesrc;
          document.getElementById("front_img").style.width = this.state.width;
          document.getElementById("front_img").style.height = this.state.height;
          document.getElementById("front_img").style.display = "block";
          if (document.getElementById("editor_background_color")) {
            document.getElementById(
              "editor_background_color"
            ).style.backgroundColor = "white";
          }
          if (document.getElementById("front_template_background_default")) {
            document.getElementById(
              "front_template_background_default"
            ).style.backgroundColor = "white";
          }

          if (this.state.editor_data !== "") {
            let preview_imagesrc;
            let html_data = document.getElementById("preview").innerHTML;

            const formData = new FormData();
            formData.append("html", html_data);
            formData.append("width", this.state.width.split("in")[0]);
            formData.append("height", this.state.height.split("in")[0]);

            axios
              .post(process.env.REACT_APP_API + "api/htmltoimage/", formData, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              })
              .then((res) => {
                preview_imagesrc = process.env.REACT_APP_API + "api" + res.data;

                setTimeout(
                  function () {
                    document.getElementById("front_img_back").src =
                      preview_imagesrc;
                    png_front_url = preview_imagesrc;
                  }.bind(),
                  500
                );
              })
              .catch((error) => {
                const status = error.response ? error.response.status : null;
                if (status === 403) {
                  let response = refresh(error);
                  response.then((res) => {
                    preview_imagesrc =
                      process.env.REACT_APP_API + "api" + res.data;

                    setTimeout(
                      function () {
                        document.getElementById("front_img_back").src =
                          preview_imagesrc;
                        png_front_url = preview_imagesrc;
                      }.bind(),
                      500
                    );
                  });
                }
              });
          } else {
            document.getElementById("front_img_back").src = imagesrc;
            document.getElementById("front_img_back").style.display = "block";
          }
          let template_background_color = "#FFFFFF";
          this.setState({
            front: { ...this.state.front, template_background_color },
          });

          this.setState({
            is_front_img_transparent: "0",
          });

          this.UpdateElementImg(imagesrc, 0, "front");
        })
        .catch((error) => {
          console.log(error);
        });
    }

    width = this.props.myProps.width + "in";
    height = this.props.myProps.height + "in";
    img_style = {
      display: "block",
    };
    upload_font = {
      display: "block",
    };
    input_font_style = {
      display: "block",
    };
  }

  setDesign = (data, img_data) => {
    setTimeout(
      function () {
        this.setState({ editor_data: data });
        document.getElementById("preview").innerHTML = this.state.editor_data;
        let img_str = '<img id="front_img_back" src="' + img_data + '">';
        document.getElementById("front_image_preview").innerHTML = img_str;
        png_front_url = img_data;
      }.bind(this),
      500
    );
  };

  setCustomDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    rotateAngle,
    backgroundColor,
    rounded_corner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state,
    font_min_size,
    font_max_size,
    scalling_class,
    transparency,
    text_autofit,
  ) => {
    if (type === "front") {
      let customs_text_data = this.state.front.customs_text_data.filter(
        (c) => c.id !== id
      );
      let temp_element = {};
      temp_element.id = id;
      temp_element.title = title;
      temp_element.top = top;
      temp_element.left = left;
      temp_element.width = width;
      temp_element.height = height;
      temp_element.double_click = 0;
      temp_element.rotateAngle = rotateAngle;
      temp_element.selected_text_element = 0;
      temp_element.backgroundColor = backgroundColor;
      temp_element.rounded_corner = rounded_corner;
      temp_element.margin_top = margin_top;
      temp_element.margin_left = margin_left;
      temp_element.padding_top = padding_top;
      temp_element.padding_right = padding_right;
      temp_element.padding_bottom = padding_bottom;
      temp_element.padding_left = padding_left;
      temp_element.custom_text_lock = lock_state;
      temp_element.font_min_size = font_min_size;
      temp_element.font_max_size = font_max_size;
      temp_element.scalling_class = scalling_class;
      temp_element.transparency = transparency;
      temp_element.text_autofit = text_autofit;
      customs_text_data = customs_text_data.concat(temp_element);
      this.setState({ front: { ...this.state.front, customs_text_data } });
    }
  };

  addCustom = (type) => {
    if (type === "front") {
      if (this.state.front.customs_text_data.length === 0) {
        new_id = 0;
      } else {
        const custom = this.state.front.customs_text_data.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = custom.id + 1;
      }
      let newelement = {
        id: new_id,
        title: "Custom text",
        top: 100,
        left: 100,
        width: 100,
        height: 100,
        rotateAngle: 0,
        selected_text_element: 0,
        backgroundColor: "",
        rounded_corner: 0,
        double_click: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        custom_text_lock: false,
        font_min_size: 7,
        font_max_size: 0,
        scalling_class: "",
        transparency: false,
        text_autofit: false,
      };
      let customs_text_data =
        this.state.front.customs_text_data.concat(newelement);
      this.setState((state) => {
        state.front.customs_text_data = customs_text_data;
        return state;
      });
    }
  };

  deleteCustom = (elementId, type) => {
    if (type === "front") {
      let customs_text_data = this.state.front.customs_text_data.filter(
        (c) => c.id !== elementId
      );
      this.setState((state) => {
        state.front.customs_text_data = customs_text_data;
        return state;
      });
    }
  };

  addNonEditableText = (type) => {
    if (type === "front") {
      if (this.state.front.non_editable_text.length === 0) {
        new_id = 0;
      } else {
        const nonEditableText = this.state.front.non_editable_text.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = nonEditableText.id + 1;
      }
      let newelement = {
        id: new_id,
        title: "Non editable custom text",
        top: 100,
        left: 240,
        width: 100,
        height: 100,
        rotateAngle: 0,
        selected_text: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_text_state: true,
        non_editable_text_lock: false,
        font_min_size: 7,
        font_max_size: 0,
        scalling_class: "",
        transparency: false,
        text_autofit: false,
      };
      let non_editable_text =
        this.state.front.non_editable_text.concat(newelement);
      this.setState((state) => {
        state.front.non_editable_text = non_editable_text;
        return state;
      });
    }
  };

  setNonEditableTextDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    rotateAngle,
    backgroundColor,
    rounded_corner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state,
    font_min_size,
    font_max_size,
    scalling_class,
    transparency,
    text_autofit,
  ) => {
    if (type === "front") {
      let non_editable_text = this.state.front.non_editable_text.filter(
        (c) => c.id !== id
      );
      let temp_element = {};
      temp_element.id = id;
      temp_element.title = title;
      temp_element.top = top;
      temp_element.left = left;
      temp_element.width = width;
      temp_element.height = height;
      temp_element.double_click_text = 0;
      temp_element.rotateAngle = rotateAngle;
      temp_element.selected_text = 0;
      temp_element.bgColor = backgroundColor;
      temp_element.rounded_corner = rounded_corner;
      temp_element.margin_top = margin_top;
      temp_element.margin_left = margin_left;
      temp_element.padding_top = padding_top;
      temp_element.padding_right = padding_right;
      temp_element.padding_bottom = padding_bottom;
      temp_element.padding_left = padding_left;
      temp_element.non_editable_text_lock = lock_state;
      temp_element.font_min_size = font_min_size;
      temp_element.font_max_size = font_max_size;
      temp_element.scalling_class = scalling_class;
      temp_element.transparency = transparency;
      temp_element.text_autofit = text_autofit;
      non_editable_text = non_editable_text.concat(temp_element);
      this.setState({ front: { ...this.state.front, non_editable_text } });
    }
  };

  deleteNonEditableText = (elementId, type) => {
    if (type === "front") {
      let non_editable_text = this.state.front.non_editable_text.filter(
        (c) => c.id !== elementId
      );
      this.setState((state) => {
        state.front.non_editable_text = non_editable_text;
        return state;
      });
    }
  };

  addNonEditablePostage = (type) => {
    if (type === "front") {
      if (this.state.front.non_editable_postage.length === 0) {
        new_id = 0;
      } else {
        const nonEditablePostage = this.state.front.non_editable_postage.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = nonEditablePostage.id + 1;
      }
      let newelement = {
        id: new_id,
        title: "{{postage}}",
        top: 180,
        left: 120,
        width: 100,
        height: 100,
        rotateAngle: 0,
        selected_postage: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_postage_state: true,
        non_editable_postage_lock: false,
      };
      let non_editable_postage =
        this.state.front.non_editable_postage.concat(newelement);
      this.setState((state) => {
        state.front.non_editable_postage = non_editable_postage;
        return state;
      });
    }
  };

  setNonEditablePostageDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    rotateAngle,
    backgroundColor,
    rounded_corner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state
  ) => {
    if (type === "front") {
      let non_editable_postage = this.state.front.non_editable_postage.filter(
        (c) => c.id !== id
      );
      let temp_element = {};
      temp_element.id = id;
      temp_element.title = title;
      temp_element.top = top;
      temp_element.left = left;
      temp_element.width = width;
      temp_element.height = height;
      temp_element.double_click_text = 0;
      temp_element.rotateAngle = rotateAngle;
      temp_element.selected_postage = 0;
      temp_element.bgColor = backgroundColor;
      temp_element.rounded_corner = rounded_corner;
      temp_element.margin_top = margin_top;
      temp_element.margin_left = margin_left;
      temp_element.padding_top = padding_top;
      temp_element.padding_right = padding_right;
      temp_element.padding_bottom = padding_bottom;
      temp_element.padding_left = padding_left;
      temp_element.non_editable_postage_lock = lock_state;
      non_editable_postage = non_editable_postage.concat(temp_element);
      this.setState({ front: { ...this.state.front, non_editable_postage } });
    }
  };

  deleteNonEditablePostage = (elementId, type) => {
    if (type === "front") {
      let non_editable_postage = this.state.front.non_editable_postage.filter(
        (c) => c.id !== elementId
      );
      this.setState((state) => {
        state.front.non_editable_postage = non_editable_postage;
        return state;
      });
    }
  };

  addNonEditableAccupix = (type) => {
    if (type === "front") {
      if (this.state.front.non_editable_accupix.length === 0) {
        new_id = 0;
      } else {
        const nonEditableAccupix = this.state.front.non_editable_accupix.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = nonEditableAccupix.id + 1;
      }
      let newelement = {
        id: new_id,
        title: accupix_img,
        top: 220,
        left: 120,
        width: 300,
        height: 200,
        rotateAngle: 0,
        selected_accupix: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_accupix_state: true,
        aspect_ratio_width: 3,
        aspect_ratio_height: 2,
        z_index: 1000,
        accupix_satellite_lock: false,
      };
      let non_editable_accupix =
        this.state.front.non_editable_accupix.concat(newelement);
      this.setState((state) => {
        state.front.non_editable_accupix = non_editable_accupix;
        return state;
      });
    }
  };

  setNonEditableAccupixDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    rotateAngle,
    backgroundColor,
    rounded_corner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    aspect_ratio_width,
    aspect_ratio_height,
    z_index
  ) => {
    if (type === "front") {
      let newelement = {
        id: id,
        title: title,
        top: top,
        left: left,
        width: width,
        height: height,
        rotateAngle: rotateAngle,
        bgColor: backgroundColor,
        rounded_corner: rounded_corner,
        margin_top: margin_top,
        margin_left: margin_left,
        padding_top: padding_top,
        padding_right: padding_right,
        padding_bottom: padding_bottom,
        padding_left: padding_left,
        non_editable_accupix_state: true,
        aspect_ratio_width: aspect_ratio_width,
        aspect_ratio_height: aspect_ratio_height,
        z_index: z_index,
      };
      let non_editable_accupix = this.state.front.non_editable_accupix.filter(
        (c) => c.id !== id
      );
      non_editable_accupix = non_editable_accupix.concat(newelement);
      this.setState({ front: { ...this.state.front, non_editable_accupix } });
    }
  };

  deleteNonEditableAccupix = (elementId, type) => {
    if (type === "front") {
      let non_editable_accupix = this.state.front.non_editable_accupix.filter(
        (c) => c.id !== elementId
      );
      this.setState((state) => {
        state.front.non_editable_accupix = non_editable_accupix;
        return state;
      });
    }
  };

  getAccupixTypeFront = (type_front) => {
    this.setState({ accupix_type_front: type_front });
  };

  getAccupixAspectRatioFront = (aspect_ratio_front) => {
    this.setState({ accupix_aspect_ratio_front: aspect_ratio_front });
  };

  addNonEditableAccupixStreeview = (type) => {
    if (type === "front") {
      if (this.state.front.non_editable_accupix_streetview.length === 0) {
        new_id = 0;
      } else {
        const nonEditableAccupixStreetview =
          this.state.front.non_editable_accupix_streetview.reduce(
            (prev, current) => (+prev.id > +current.id ? prev : current)
          );
        new_id = nonEditableAccupixStreetview.id + 1;
      }
      let newelementStreetview = {
        id: new_id,
        title: accupix_streetview_img,
        top: 150,
        left: 120,
        width: 300,
        height: 200,
        rotateAngle: 0,
        selected_accupix: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_accupix_state: true,
        aspect_ratio_width: 3,
        aspect_ratio_height: 2,
        z_index: 1000,
        accupix_streetview_lock: false,
      };
      let non_editable_accupix_streetview =
        this.state.front.non_editable_accupix_streetview.concat(
          newelementStreetview
        );
      this.setState((state) => {
        state.front.non_editable_accupix_streetview =
          non_editable_accupix_streetview;
        return state;
      });
    }
  };

  setNonEditableAccupixStreetviewDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    rotateAngle,
    backgroundColor,
    rounded_corner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    aspect_ratio_width,
    aspect_ratio_height,
    z_index
  ) => {
    if (type === "front") {
      let newelement = {
        id: id,
        title: title,
        top: top,
        left: left,
        width: width,
        height: height,
        rotateAngle: rotateAngle,
        bgColor: backgroundColor,
        rounded_corner: rounded_corner,
        margin_top: margin_top,
        margin_left: margin_left,
        padding_top: padding_top,
        padding_right: padding_right,
        padding_bottom: padding_bottom,
        padding_left: padding_left,
        non_editable_accupix_state: true,
        aspect_ratio_width: aspect_ratio_width,
        aspect_ratio_height: aspect_ratio_height,
        z_index: z_index,
      };
      let non_editable_accupix_streetview =
        this.state.front.non_editable_accupix_streetview.filter(
          (c) => c.id !== id
        );
      non_editable_accupix_streetview =
        non_editable_accupix_streetview.concat(newelement);
      this.setState({
        front: { ...this.state.front, non_editable_accupix_streetview },
      });
    }
  };

  deleteNonEditableStreetviewAccupix = (elementId, type) => {
    if (type === "front") {
      let non_editable_accupix_streetview =
        this.state.front.non_editable_accupix_streetview.filter(
          (c) => c.id !== elementId
        );
      this.setState((state) => {
        state.front.non_editable_accupix_streetview =
          non_editable_accupix_streetview;
        return state;
      });
    }
  };

  getAccupixStreetviewTypeFront = (type_front) => {
    this.setState({ accupix_type_front_two: type_front });
  };

  getAccupixStreetviewAspectRatioFront = (aspect_ratio_front) => {
    this.setState({ accupix_aspect_ratio_front_two: aspect_ratio_front });
  };

  UpdateElementImg = (img_src, id, type) => {
    let image_customs_data = [...this.state.front.image_customs_data];
    let temp_element = { ...image_customs_data[id] };
    if (this.state.font_background !== "") {
      temp_element.img_src = this.state.font_background;
    } else {
      temp_element.img_src = img_src;
    }
    image_customs_data[id] = temp_element;
    this.setState({ front: { ...this.state.front, image_customs_data } });
    this.UpdateImageCustomsDataElementImg(img_src, id, type);
  };

  UpdateImageCustomsDataElement = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    img_src,
    type,
    order
  ) => {
    if (type === "front") {
      let image_customs_data = [...this.state.front.image_customs_data];
      let temp_element = { ...image_customs_data[id] };
      temp_element.left = left;
      temp_element.top = top;
      temp_element.width = width;
      temp_element.height = height;
      temp_element.order = order;
      image_customs_data[id] = temp_element;
      this.setState({ front: { ...this.state.front, image_customs_data } });
    }
  };

  UpdateImageCustomsDataElementImg = (
    img_src,
    id,
    type,
    img_box_title,
    order,
    currentZIndex,
    image_upload = false,
    top,
    left
  ) => {
    if (type === "front") {
      let image_customs_data = [...this.state.front.image_customs_data];
      let temp_element = { ...image_customs_data[id] };
      temp_element.id = id;
      if (image_upload === true) {
        temp_element.width = currentZIndex[id].original_image_width;
        temp_element.height = currentZIndex[id].original_image_height;
        temp_element.top = top;
        temp_element.left = left;
      }

      if (typeof img_box_title !== "undefined") {
        temp_element.img_box_title = img_box_title;
      }
      temp_element.img_src = img_src;

      if (typeof order !== "undefined" && order > 0) {
        temp_element.order = order;
      }
      image_customs_data[id] = temp_element;
      if (typeof currentZIndex !== "undefined") {
        for (let i = 0; i < currentZIndex.length; i++) {
          if (typeof image_customs_data[i] !== "undefined") {
            if (id > 0) {
              image_customs_data[i].order = currentZIndex[i].order;
            }
            image_customs_data[i].original_image_width =
              currentZIndex[i].original_image_width;
            image_customs_data[i].original_image_height =
              currentZIndex[i].original_image_height;
          }
        }
      }
      this.setState({ front: { ...this.state.front, image_customs_data } });
    }
  };

  updateNonEditableAccupixZIndex = (z_index, id, type) => {
    if (type === "front") {
      let non_editable_accupix = [...this.state.front.non_editable_accupix];
      let temp_element = { ...non_editable_accupix[id] };
      temp_element.id = id;
      temp_element.z_index = z_index;
      non_editable_accupix[id] = temp_element;
      this.setState({ front: { ...this.state.front, non_editable_accupix } });
    }
  };

  updateNonEditableAccupixStreetviewZIndex = (z_index, id, type) => {
    if (type === "front") {
      let non_editable_accupix_streetview = [
        ...this.state.front.non_editable_accupix_streetview,
      ];
      let temp_element = { ...non_editable_accupix_streetview[id] };
      temp_element.id = id;
      temp_element.z_index = z_index;
      non_editable_accupix_streetview[id] = temp_element;
      this.setState({
        front: { ...this.state.front, non_editable_accupix_streetview },
      });
    }
  };

  setBackgroundColor = (
    type,
    template_background_color,
    is_transparent_delete
  ) => {
    let rgba = {};
    let split_color =
      template_background_color.toLowerCase() !== "#FFFFFF" &&
      template_background_color.split("rgba(")[1];
    if (split_color) {
      split_color = split_color.split(")")[0];
      split_color = split_color.split(",");
      rgba = {
        r: split_color[0],
        g: split_color[1],
        b: split_color[2],
        a: split_color[3],
      };
    }
    if (type === "front") {
      if (template_background_color.toLowerCase() === "#ffffff") {
        this.setState({
          is_front_img_transparent: "1",
          front: { ...this.state.front, template_background_color },
        });
      } else if (
        rgba.a == 0 ||
        (rgba.r == 255 && rgba.g == 255 && rgba.b == 255)
      ) {
        this.setState({
          is_front_img_transparent: "1",
          front: { ...this.state.front, template_background_color },
        });
      } else {
        this.setState({
          is_front_img_transparent: "0",
          front: { ...this.state.front, template_background_color },
        });
      }
      if (is_transparent_delete !== undefined && is_transparent_delete !== "") {
        this.setState({
          is_front_img_transparent: is_transparent_delete,
        });
      }
    }
  };

  addLogoUploader = (type) => {
    if (type === "front") {
      if (this.state.front.logo_uploader_component.length === 0) {
        new_id = 0;
      } else {
        const logoUploader = this.state.front.logo_uploader_component.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = logoUploader.id + 1;
      }
      let newelement = {
        id: new_id,
        title: "{{logo}}",
        top: 220,
        left: 240,
        width: 132,
        height: 48,
        rotateAngle: 0,
        selected_logo: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        non_editable_logo_state: true,
        logo_uploader_lock: false,
      };
      let logo_uploader_component =
        this.state.front.logo_uploader_component.concat(newelement);
      this.setState((state) => {
        state.front.logo_uploader_component = logo_uploader_component;
        return state;
      });
    }
  };

  setLogoUploaderDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    rotateAngle,
    backgroundColor,
    rounded_corner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left
  ) => {
    if (type === "front") {
      let newelement = {
        id: id,
        title: title,
        top: top,
        left: left,
        width: width,
        height: height,
        rotateAngle: rotateAngle,
        bgColor: backgroundColor,
        rounded_corner: rounded_corner,
        margin_top: margin_top,
        margin_left: margin_left,
        padding_top: padding_top,
        padding_right: padding_right,
        padding_bottom: padding_bottom,
        padding_left: padding_left,
        non_editable_logo_state: true,
      };
      let logo_uploader_component =
        this.state.front.logo_uploader_component.filter((c) => c.id !== id);
      logo_uploader_component = logo_uploader_component.concat(newelement);
      this.setState({
        front: { ...this.state.front, logo_uploader_component },
      });
    }
  };

  deleteLogoUploader = (elementId, type) => {
    if (type === "front") {
      let logo_uploader_component =
        this.state.front.logo_uploader_component.filter(
          (c) => c.id !== elementId
        );
      this.setState((state) => {
        state.front.logo_uploader_component = logo_uploader_component;
        return state;
      });
    }
  };

  addDataSequence = (type) => {
    if (type === "front") {
      if (this.state.front.data_sequence_component.length === 0) {
        new_id = 0;
      } else {
        const dataSqeuence = this.state.front.data_sequence_component.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = dataSqeuence.id + 1;
      }
      let newelement = {
        id: new_id,
        title: "{{sequence}}",
        top: 25,
        left: this.state.is_bleed === "1" ? 671 : 650,
        width: 14,
        height: 5,
        data_sequence_swap: false,
      };
      let data_sequence_component =
        this.state.front.data_sequence_component.concat(newelement);
      this.setState((state) => {
        state.front.data_sequence_component = data_sequence_component;
        return state;
      });
    }
  };

  setDataSequenceDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    data_sequence_swap
  ) => {
    if (type === "front") {
      let newelement = {
        id: id,
        title: title,
        top: top,
        left: left,
        width: width,
        height: height,
        data_sequence_swap: data_sequence_swap,
      };
      let data_sequence_component =
        this.state.front.data_sequence_component.filter((c) => c.id !== id);
      data_sequence_component = data_sequence_component.concat(newelement);
      this.setState({
        front: { ...this.state.front, data_sequence_component },
      });
    }
  };

  deleteDataSequence = (elementId, type) => {
    if (type === "front") {
      let data_sequence_component =
        this.state.front.data_sequence_component.filter(
          (c) => c.id !== elementId
        );
      this.setState((state) => {
        state.front.data_sequence_component = data_sequence_component;
        return state;
      });
    }
  };

  addDataMatrix = (type) => {
    if (type === "front") {
      if (this.state.front.data_matrix_component.length === 0) {
        new_id = 0;
      } else {
        const dataMatrix = this.state.front.data_matrix_component.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = dataMatrix.id + 1;
      }
      let newelement = {
        id: new_id,
        title: "{{data_matrix}}",
        top: 35,
        left: this.state.is_bleed === "1" ? 672 : 651,
        width: 12,
        height: 12,
        data_matrix_swap: false,
      };
      let data_matrix_component =
        this.state.front.data_matrix_component.concat(newelement);
      this.setState((state) => {
        state.front.data_matrix_component = data_matrix_component;
        return state;
      });
    }
  };

  setDataMatrixDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    data_matrix_swap
  ) => {
    if (type === "front") {
      let newelement = {
        id: id,
        title: title,
        top: top,
        left: left,
        width: width,
        height: height,
        data_matrix_swap: data_matrix_swap,
      };
      let data_matrix_component = this.state.front.data_matrix_component.filter(
        (c) => c.id !== id
      );
      data_matrix_component = data_matrix_component.concat(newelement);
      this.setState({
        front: { ...this.state.front, data_matrix_component },
      });
    }
  };

  deleteDataMatrix = (elementId, type) => {
    if (type === "front") {
      let data_matrix_component = this.state.front.data_matrix_component.filter(
        (c) => c.id !== elementId
      );
      this.setState((state) => {
        state.front.data_matrix_component = data_matrix_component;
        return state;
      });
    }
  };

  addImbarcode = (type) => {
    if (type === "front") {
      if (this.state.front.imbarcode_component.length === 0) {
        new_id = 0;
      } else {
        const imBarcode = this.state.front.imbarcode_component.reduce(
          (prev, current) => (+prev.id > +current.id ? prev : current)
        );
        new_id = imBarcode.id + 1;
      }
      let newelement = {
        id: new_id,
        title: "{{imbarcode}}",
        top: 180,
        left: 220,
        width: 300,
        height: 24,
        rotateAngle: 0,
        selected_imbarcode: 0,
        bgColor: "",
        rounded_corner: 0,
        double_click_text: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        imbarcode_state: true,
        imbarcode_lock: false,
      };
      let imbarcode_component =
        this.state.front.imbarcode_component.concat(newelement);
      this.setState((state) => {
        state.front.imbarcode_component = imbarcode_component;
        return state;
      });
    }
  };

  setImbarcodeDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    rotateAngle,
    backgroundColor,
    rounded_corner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state
  ) => {
    if (type === "front") {
      let imbarcode_component = this.state.front.imbarcode_component.filter(
        (c) => c.id !== id
      );
      let temp_element = {};
      temp_element.id = id;
      temp_element.title = title;
      temp_element.top = top;
      temp_element.left = left;
      temp_element.width = width;
      temp_element.height = height;
      temp_element.double_click_text = 0;
      temp_element.rotateAngle = rotateAngle;
      temp_element.selected_imbarcode = 0;
      temp_element.bgColor = backgroundColor;
      temp_element.rounded_corner = rounded_corner;
      temp_element.margin_top = margin_top;
      temp_element.margin_left = margin_left;
      temp_element.padding_top = padding_top;
      temp_element.padding_right = padding_right;
      temp_element.padding_bottom = padding_bottom;
      temp_element.padding_left = padding_left;
      temp_element.imbarcode_lock = lock_state;
      imbarcode_component = imbarcode_component.concat(temp_element);
      this.setState({ front: { ...this.state.front, imbarcode_component } });
    }
  };

  deleteImbarcode = (elementId, type) => {
    if (type === "front") {
      let imbarcode_component = this.state.front.imbarcode_component.filter(
        (c) => c.id !== elementId
      );
      this.setState((state) => {
        state.front.imbarcode_component = imbarcode_component;
        return state;
      });
    }
  };

  addLineShape = (type) => {
    if (type === "front") {
      if (this.state.front.line_shape.length === 0) {
        new_id = 0;
      } else {
        const line = this.state.front.line_shape.reduce((prev, current) =>
          +prev.id > +current.id ? prev : current
        );
        new_id = line.id + 1;
      }
      let newelement = {
        id: new_id,
        title: "",
        top: 220,
        left: 220,
        width: 250,
        height: 1,
        rotateAngle: 0,
        selected_line: 0,
        backgroundColor: "#000000",
        rounded_corner: 0,
        double_click: 0,
        margin_top: 0,
        margin_left: 0,
        padding_top: 0,
        padding_right: 0,
        padding_bottom: 0,
        padding_left: 0,
        line_state: true,
        line_lock: false,
      };
      let line_shape = this.state.front.line_shape.concat(newelement);
      this.setState((state) => {
        state.front.line_shape = line_shape;
        return state;
      });
    }
  };

  setLineShapeDesign = (
    data,
    id,
    left,
    top,
    width,
    height,
    title,
    type,
    rotateAngle,
    backgroundColor,
    rounded_corner,
    margin_top,
    margin_left,
    padding_top,
    padding_right,
    padding_bottom,
    padding_left,
    lock_state
  ) => {
    if (type === "front") {
      let line_shape = this.state.front.line_shape.filter((c) => c.id !== id);
      let temp_element = {};
      temp_element.id = id;
      temp_element.title = title;
      temp_element.top = top;
      temp_element.left = left;
      temp_element.width = width;
      temp_element.height = height;
      temp_element.double_click = 0;
      temp_element.rotateAngle = rotateAngle;
      temp_element.selected_line = 0;
      temp_element.backgroundColor = backgroundColor;
      temp_element.rounded_corner = rounded_corner;
      temp_element.margin_top = margin_top;
      temp_element.margin_left = margin_left;
      temp_element.padding_top = padding_top;
      temp_element.padding_right = padding_right;
      temp_element.padding_bottom = padding_bottom;
      temp_element.padding_left = padding_left;
      temp_element.line_lock = lock_state;
      line_shape = line_shape.concat(temp_element);
      this.setState({ front: { ...this.state.front, line_shape } });
    }
  };

  deleteLineShape = (elementId, type) => {
    if (type === "front") {
      let line_shape = this.state.front.line_shape.filter(
        (c) => c.id !== elementId
      );

      this.setState((state) => {
        state.front.line_shape = line_shape;
        return state;
      });
    }
  };

  toggleRuler = (type, isRuler) => {
    if (type === "front") {
      let ruler = { ...this.state.front.ruler };
      ruler.is_ruler = isRuler;
      this.setState({
        front: { ...this.state.front, ruler },
      });
    }
  };

  setHorizontalGuide = (type, guides) => {
    if (type === "front") {
      let ruler = { ...this.state.front.ruler };
      ruler.horizontal_line = guides;
      this.setState({
        front: { ...this.state.front, ruler },
      });
    }
  };

  setVerticalGuide = (type, guides) => {
    if (type === "front") {
      let ruler = { ...this.state.front.ruler };
      ruler.vertical_line = guides;
      this.setState({
        front: { ...this.state.front, ruler },
      });
    }
  };

  handleLockUnlock = (type, id, custom_text_lock) => {
    if (type === "front") {
      let customs_text_data = [...this.state.front.customs_text_data];
      for (let i = 0; i < this.state.front.customs_text_data.length; i++) {
        if (customs_text_data[i].id === id) {
          customs_text_data[i].custom_text_lock = custom_text_lock;
        }
      }
      this.setState({
        front: { ...this.state.front, customs_text_data },
      });
    }
  };

  handleImageLockUnlock = (type, id, custom_image_lock) => {
    if (type === "front") {
      let image_customs_data = [...this.state.front.image_customs_data];
      let temp_element = { ...image_customs_data[id] };
      temp_element.id = id;
      temp_element.custom_image_lock = custom_image_lock;
      image_customs_data[id] = temp_element;
      this.setState({
        front: { ...this.state.front, image_customs_data },
      });
    }
  };

  handleNonEditableTextLockUnlock = (type, id, non_editable_text_lock) => {
    if (type === "front") {
      let non_editable_text = [...this.state.front.non_editable_text];
      for (let i = 0; i < this.state.front.non_editable_text.length; i++) {
        if (non_editable_text[i].id === id) {
          non_editable_text[i].non_editable_text_lock = non_editable_text_lock;
        }
      }
      this.setState({
        front: { ...this.state.front, non_editable_text },
      });
    }
  };

  handleNonEditablePostageLockUnlock = (
    type,
    id,
    non_editable_postage_lock
  ) => {
    if (type === "front") {
      let non_editable_postage = [...this.state.front.non_editable_postage];
      for (let i = 0; i < this.state.front.non_editable_postage.length; i++) {
        if (non_editable_postage[i].id === id) {
          non_editable_postage[i].non_editable_postage_lock =
            non_editable_postage_lock;
        }
      }
      this.setState({
        front: { ...this.state.front, non_editable_postage },
      });
    }
  };

  handleImbarcodeLockUnlock = (type, id, imbarcode_lock) => {
    if (type === "front") {
      let imbarcode_component = [...this.state.front.imbarcode_component];
      for (let i = 0; i < this.state.front.imbarcode_component.length; i++) {
        if (imbarcode_component[i].id === id) {
          imbarcode_component[i].imbarcode_lock = imbarcode_lock;
        }
      }
      this.setState({
        front: { ...this.state.front, imbarcode_component },
      });
    }
  };

  handleLogoLockUnlock = (type, id, logo_uploader_lock) => {
    if (type === "front") {
      let logo_uploader_component = [
        ...this.state.front.logo_uploader_component,
      ];
      let temp_element = { ...logo_uploader_component[id] };
      temp_element.id = id;
      temp_element.logo_uploader_lock = logo_uploader_lock;
      logo_uploader_component[id] = temp_element;
      this.setState({
        front: { ...this.state.front, logo_uploader_component },
      });
    }
  };

  handleAccupixSatelliteLockUnlock = (type, id, accupix_satellite_lock) => {
    if (type === "front") {
      let non_editable_accupix = [...this.state.front.non_editable_accupix];
      let temp_element = { ...non_editable_accupix[id] };
      temp_element.id = id;
      temp_element.accupix_satellite_lock = accupix_satellite_lock;
      non_editable_accupix[id] = temp_element;
      this.setState({
        front: { ...this.state.front, non_editable_accupix },
      });
    }
  };

  handleAccupixStreetviewLockUnlock = (type, id, accupix_streetview_lock) => {
    if (type === "front") {
      let non_editable_accupix_streetview = [
        ...this.state.front.non_editable_accupix_streetview,
      ];
      let temp_element = { ...non_editable_accupix_streetview[id] };
      temp_element.id = id;
      temp_element.accupix_streetview_lock = accupix_streetview_lock;
      non_editable_accupix_streetview[id] = temp_element;
      this.setState({
        front: { ...this.state.front, non_editable_accupix_streetview },
      });
    }
  };

  handleLineShapeLockUnlock = (type, id, line_lock) => {
    if (type === "front") {
      let line_shape = [...this.state.front.line_shape];
      for (let i = 0; i < this.state.front.line_shape.length; i++) {
        if (line_shape[i].id === id) {
          line_shape[i].line_lock = line_lock;
        }
      }
      this.setState({
        front: { ...this.state.front, line_shape },
      });
    }
  };

  handleTextScalingClass = (type, new_min_max) => {
    if (type === "front") {
      if (Object.keys(new_min_max).length > 0) {
        let front_min_max = new_min_max;
        this.setState({
          front: { ...this.state.front, front_min_max },
        });
      } else {
        let front_min_max = {};
        this.setState({
          front: { ...this.state.front, front_min_max },
        });
      }
    }
  };

  handleImageTransparency = (type, transparency) => {
    if (type === "front") {
      this.setState({ is_front_img_transparent: transparency });
    }
  };

  handleCustomTextTransparency = (type, id, transparency) => {
    if (type === "front") {
      let customs_text_data = [...this.state.front.customs_text_data];
      for (let i = 0; i < this.state.front.customs_text_data.length; i++) {
        if (customs_text_data[i].id === id) {
          customs_text_data[i].transparency = transparency;
        }
      }
      this.setState({
        front: { ...this.state.front, customs_text_data },
      });
    }
  };

  handleNonEditableTextTransparency = (type, id, transparency) => {
    if (type === "front") {
      let non_editable_text = [...this.state.front.non_editable_text];
      for (let i = 0; i < this.state.front.non_editable_text.length; i++) {
        if (non_editable_text[i].id === id) {
          non_editable_text[i].transparency = transparency;
        }
      }
      this.setState({
        front: { ...this.state.front, non_editable_text },
      });
    }
  };

  handleCustomTextFit = (type, id, text_autofit) => {
    if (type === "front") {
      let customs_text_data = [...this.state.front.customs_text_data];
      for (let i = 0; i < this.state.front.customs_text_data.length; i++) {
        if (customs_text_data[i].id === id) {
          customs_text_data[i].text_autofit = text_autofit;
        }
      }
      this.setState({
        front: { ...this.state.front, customs_text_data },
      });
    }
  };

  handleNonEditableTextFit = (type, id, text_autofit) => {
    if (type === "front") {
      let non_editable_text = [...this.state.front.non_editable_text];
      for (let i = 0; i < this.state.front.non_editable_text.length; i++) {
        if (non_editable_text[i].id === id) {
          non_editable_text[i].text_autofit = text_autofit;
        }
      }
      this.setState({
        front: { ...this.state.front, non_editable_text },
      });
    }
  };

  saveChanges = (event) => {
    event.preventDefault();
    let html_front = document.getElementById("preview").innerHTML;
    let png_front = png_front_url;

    axios
      .post(
        process.env.REACT_APP_API + "api/templates/",
        {
          template_name: this.state.template_name,
          type: this.state.template_type,
          height: this.state.height,
          width: this.state.width,
          bleed: this.state.bleed,
          margin: this.state.margin,
          template_json: JSON.stringify(this.state),
          html_front: html_front,
          html_back: "",
          png_front: png_front,
          png_back: "",
          size: this.state.size_id,
          //user: this.state.user_id,
          accupix_type_front: this.state.accupix_type_front,
          accupix_type_front_two: this.state.accupix_type_front_two,
          accupix_aspect_ratio_front: this.state.accupix_aspect_ratio_front,
          accupix_aspect_ratio_front_two:
            this.state.accupix_aspect_ratio_front_two,
          is_bleed: this.state.is_bleed,
          is_front_img_transparent: this.state.is_front_img_transparent,
          is_back_img_transparent: this.state.is_front_img_transparent,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        if (res.status) {
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Saved successfully",
          });
          setTimeout(() => {
            this.props.history.push("/template-list");
            // this.props.history.push("/admin/template-list");
          }, 3000);
        }
      })
      .catch((error) => {
        const status = error.response ? error.response.status : null;
        if (status === 403) {
          let response = refresh(error);
          response
            .then((res) => {
              if (res.status) {
                const Toast = Swal.mixin({
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  didOpen: (toast) => {
                    toast.addEventListener("mouseenter", Swal.stopTimer);
                    toast.addEventListener("mouseleave", Swal.resumeTimer);
                  },
                });

                Toast.fire({
                  icon: "success",
                  title: "Saved successfully",
                });
                setTimeout(() => {
                  this.props.history.push("/template-list");
                }, 3000);
              }
            })
            .catch((error) => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "error",
                title: "Something Went Wrong!",
              });
            });
        } else {
          if (
            error.response.data.template_name[0] ===
            "template with this template name already exists."
          ) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Template with this template name already exists!",
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "error",
              title: "Something Went Wrong!",
            });
          }
        }
      });
  };

  componentDidMount() {
    this.props.onRef(this);
  }
  componentWillUnmount() {
    this.props.onRef(undefined);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.myProps.size_id !== this.props.myProps.size_id) {
      this.setState({
        width: this.props.myProps.width,
        height: this.props.myProps.height,
      });
    }
    if (prevProps.myProps.is_bleed !== this.props.myProps.is_bleed) {
      this.setState({
        is_bleed: this.props.myProps.is_bleed,
      });
    }
  }
  onChangeHandeller(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    return (
      <>
        <div className="design-thum-block">
          <div className="upload-btn-box">
            <div
              id="preview"
              className="thum-box single-box"
              style={{ width: width, height: height, display: "none" }}
            >
              <img
                alt="font_bak"
                style={img_style}
                id="front_img"
                src={this.state.font_background}
              />
            </div>
            <div
              id="front_image_preview"
              className="thum-box single-box"
              style={{ width: width, height: height }}
            >
              <img
                alt="front_bak"
                style={img_style}
                id="front_img_back"
                src=""
              />
            </div>
            <div className="upload-btn-wrapper">
              {/* <div className="upload-button">
                <button className="btn" style={upload_font}>
                  Upload a file
                </button>
                <input
                  type="file"
                  id="font_background"
                  style={input_font_style}
                  accept="image/png, image/jpeg, .pdf"
                  onChange={this.handleChange}
                  className="custom-file-input"
                ></input>
              </div> */}
              {this.state.isAdvanceEdit ||
                this.state.front.image_customs_data[0].img_src !== "" ? (
                <DesignerModal
                  {...this.props}
                  myProps={this.state}
                  non_editable_text={this.state.front.non_editable_text}
                  non_editable_postage={this.state.front.non_editable_postage}
                  non_editable_accupix={this.state.front.non_editable_accupix}
                  non_editable_accupix_streetview={
                    this.state.front.non_editable_accupix_streetview
                  }
                  image_customs_data={this.state.front.image_customs_data}
                  customs_text_data={this.state.front.customs_text_data}
                  template_background_color={
                    this.state.front.template_background_color
                  }
                  logo_uploader_component={
                    this.state.front.logo_uploader_component
                  }
                  data_sequence_component={
                    this.state.front.data_sequence_component
                  }
                  data_matrix_component={this.state.front.data_matrix_component}
                  imbarcode_component={this.state.front.imbarcode_component}
                  ruler={this.state.front.ruler}
                  min_max={this.state.front.front_min_max}
                  line_shape={this.state.front.line_shape}
                  imageType="front"
                  panelType="single"
                  parentCallback={this.setDesign}
                  parentUpdateImageCustomsDataElement={
                    this.UpdateImageCustomsDataElement
                  }
                  parentUpdateImageCustomsDataElementImg={
                    this.UpdateImageCustomsDataElementImg
                  }
                  parentsetCustomDesign={this.setCustomDesign}
                  parentaddCustom={this.addCustom}
                  parentdeleteCustom={this.deleteCustom}
                  parentAddNonEditableText={this.addNonEditableText}
                  parentSetNonEditableTextDesign={this.setNonEditableTextDesign}
                  parentDeleteNonEditableText={this.deleteNonEditableText}
                  parentAddNonEditablePostage={this.addNonEditablePostage}
                  parentSetNonEditablePostageDesign={
                    this.setNonEditablePostageDesign
                  }
                  parentDeleteNonEditablePostage={this.deleteNonEditablePostage}
                  parentAddNonEditableAccupix={this.addNonEditableAccupix}
                  parentSetNonEditableAccupixDesign={
                    this.setNonEditableAccupixDesign
                  }
                  parentAddNonEditableStreetviewAccupix={
                    this.addNonEditableAccupixStreeview
                  }
                  parentSetNonEditableStreetviewAccupixDesign={
                    this.setNonEditableAccupixStreetviewDesign
                  }
                  parentDeleteNonEditableAccupix={this.deleteNonEditableAccupix}
                  parentDeleteNonEditableStreetviewAccupix={
                    this.deleteNonEditableStreetviewAccupix
                  }
                  parentGetAccupixTypeFront={this.getAccupixTypeFront}
                  parentGetAccupixStreetviewTypeFront={
                    this.getAccupixStreetviewTypeFront
                  }
                  parentGetAccupixAspectRatioFront={
                    this.getAccupixAspectRatioFront
                  }
                  parentGetAccupixStreetviewAspectRatioFront={
                    this.getAccupixStreetviewAspectRatioFront
                  }
                  parentSetBackgroundColor={this.setBackgroundColor}
                  parentUpdateNonEditableAccupixZIndex={
                    this.updateNonEditableAccupixZIndex
                  }
                  parentUpdateNonEditableAccupixStreetviewZIndex={
                    this.updateNonEditableAccupixStreetviewZIndex
                  }
                  parentAddLogoUploader={this.addLogoUploader}
                  parentSetLogoUploaderDesign={this.setLogoUploaderDesign}
                  parentDeleteLogoUploader={this.deleteLogoUploader}
                  parentAddDataSequence={this.addDataSequence}
                  parentSetDataSequenceDesign={this.setDataSequenceDesign}
                  parentDeleteDataSequence={this.deleteDataSequence}
                  parentAddDataMatrix={this.addDataMatrix}
                  parentSetDataMatrixDesign={this.setDataMatrixDesign}
                  parentDeleteDataMatrix={this.deleteDataMatrix}
                  parentAddImbarcode={this.addImbarcode}
                  parentSetImbarcodeDesign={this.setImbarcodeDesign}
                  parentDeleteImbarcode={this.deleteImbarcode}
                  toggleRuler={this.toggleRuler}
                  setHorizontalGuide={this.setHorizontalGuide}
                  setVerticalGuide={this.setVerticalGuide}
                  handleLockUnlock={this.handleLockUnlock}
                  handleImageLockUnlock={this.handleImageLockUnlock}
                  handleNonEditableTextLockUnlock={
                    this.handleNonEditableTextLockUnlock
                  }
                  handleNonEditablePostageLockUnlock={
                    this.handleNonEditablePostageLockUnlock
                  }
                  handleImbarcodeLockUnlock={this.handleImbarcodeLockUnlock}
                  handleLogoLockUnlock={this.handleLogoLockUnlock}
                  handleAccupixSatelliteLockUnlock={
                    this.handleAccupixSatelliteLockUnlock
                  }
                  handleAccupixStreetviewLockUnlock={
                    this.handleAccupixStreetviewLockUnlock
                  }
                  handleTextScalingClass={this.handleTextScalingClass}
                  addLineShape={this.addLineShape}
                  setLineShapeDesign={this.setLineShapeDesign}
                  deleteLineShape={this.deleteLineShape}
                  handleLineShapeLockUnlock={this.handleLineShapeLockUnlock}
                  handleImageTransparency={this.handleImageTransparency}
                  handleCustomTextTransparency={this.handleCustomTextTransparency}
                  handleNonEditableTextTransparency={this.handleNonEditableTextTransparency}
                  handleCustomTextFit={this.handleCustomTextFit}
                  handleNonEditableTextFit={this.handleNonEditableTextFit}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          {this.state.editor_data !== "" ? (
            <button
              className="btn btn-primary button-scale"
              onClick={this.saveChanges}
            >
              Save Changes
            </button>
          ) : null}
        </div>
      </>
    );
  }
}

export default withRouter(Singlepannel);
