import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";

import {
  ContactInformation,
  SocialInformation,
  PrintProfileInformation,
  ProfileUserDetails,
  ProfileSubscriptions,
  ProfileWalletBalance,
} from "../../config/Components";
import WalletBalanceBox from "./../Billing/WalletBalanceBox";

import { useSelector } from "react-redux";

import "./Profile.css";
import {
  API_GET_WALLET_BALANCE,
  API_GET_ALL_SAVED_CARD,
  API_GET_USER,
} from "../../config/Api";

const ProfileInformation = (props) => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [savedCardList, setSavedCardList] = useState(null);

  const [user, setUser] = useState({});
  const [userDetail, setUserDetail] = useState();

  const loggedInUser = useSelector((state) => state.setProfile);
  const userApp = useSelector((state) => state.getUserApp);
  let appDate = "";

  if (userApp) {
    let dt_obj = props.getDateObj(userApp.date_added);
    appDate =
      dt_obj.year +
      "-" +
      dt_obj.month +
      "-" +
      dt_obj.date +
      "@" +
      dt_obj.hrs +
      ":" +
      dt_obj.min +
      ":" +
      dt_obj.sec;
  }

  useEffect(() => {
    loggedInUser && setUser(loggedInUser);
  }, [loggedInUser]);
  useEffect(() => {
    getWalletBalance();
    getAllSavedCard();
    getUserDetail();
  }, [walletBalance]);

  const getWalletBalance = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_WALLET_BALANCE}?user_id=${props.getUserInfo().user_id}`,
        true
      )
      .then((res) => {
        setWalletBalance(res.data[0]["wallet_balance"]);
      })
      .catch((error) => {});
  };

  const getAllSavedCard = () => {
    props
      .callRequest(
        "GET",
        `${API_GET_ALL_SAVED_CARD}?user_id=${props.getUserInfo().user_id}`,
        true
      )
      .then((res) => {
        setSavedCardList(res.data);
      })
      .catch((error) => {});
  };

  const getUserDetail = () => {
    props
      .callRequest("GET", API_GET_USER + props.getUserInfo().user_id, true)
      .then((res) => {
        setUserDetail(res.data);
      })
      .catch((error) => {});
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-badge"></i>
            </span>
            Profile
          </h1>
        </div>

        <div className="profile-information-block">
          <div className="listhilight-block">
            <Card>
              <CardHeader>App Details</CardHeader>
              <CardBody>
                <ul className="listhilight-list">
                  <li>
                    <label>Name:</label>
                    <span>{userApp && userApp.name}</span>
                  </li>
                  <li>
                    <label>Key:</label>
                    <span>{userApp && userApp.api_key}</span>
                  </li>
                  <li>
                    <label>Date Created:</label>
                    <span>{appDate}</span>
                  </li>
                </ul>
              </CardBody>
            </Card>
          </div>
          <Row>
            <Col sm="7" lg="8">
              <div className="information-form-block contact-information">
                <ContactInformation {...props} userDetails={user} />
              </div>
              <div className="information-form-block social-information">
                <SocialInformation {...props} userDetails={user} />
              </div>
              <div className="information-form-block PrintProfile-information">
                <PrintProfileInformation {...props} userDetails={user} />
              </div>
            </Col>
            <Col sm="5" lg="4">
              <div className="profile-right-box profile-user-details">
                <ProfileUserDetails {...props} userDetails={user} />
              </div>
              {/* <div className="profile-right-box profile-subscriptions-box">
                <ProfileSubscriptions {...props} />
              </div> */}
              <div className="profile-right-box profile-wallet-balance-box">
                {/* <ProfileWalletBalance {...props} /> */}
                {userDetail && userDetail.user_type == "1" && (
                  <WalletBalanceBox
                    {...props}
                    walletBalance={walletBalance}
                    savedCardList={savedCardList}
                    getWalletBalance={getWalletBalance}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </main>
    </>
  );
};

export default withRouter(ProfileInformation);
