import React, { useState, useEffect, useMemo } from "react";
import "../../assets/css/data-table.css";
import {
  Card,
  CardBody,
  Button,
  Input,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableSettings from "../../utils/DataTableSettings";
import { BreadCrumb } from "../../config/Components";

import { API_GET_CMS, API_GET_SECTION_BY_CMS } from "../../config/Api";
import CmsForm from "./CmsForm";
import Swal from "sweetalert2";

const ContentManagement = (props) => {
  const { className } = props;
  const [cms, setCms] = useState([]);
  const [cmsId, setCmsId] = useState();
  //const [cmsList, setCmsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [modal, setModal] = useState(false);
  //const [rows, setRows] = useState(10);
  const toggle = () => {
    setModal(!modal);
  };

  const searchParam = [
    "pattern",
    "page_title",
    "seo_title",
    "meta_tag",
    "meta_desc",
  ];

  useEffect(() => {
    fetchCms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCms = () => {
    props.callRequest("GET", API_GET_CMS, true).then((res) => {
      setCms(res.data);
      setLoading(false);
    });
  };

  const handleEdit = (id) => {
    setCmsId(id);
    toggle();
  };
  const handleDetailsPage = (id) => {
    return props.history.push("/sections/" + id + "/");
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "question",
      title: "Are you sure?",
      showDenyButton: true,
      confirmButtonText: `<i class = "fas fa-check"></i>`,
      denyButtonText: `<i class = "fas fa-times"></i>`,
    }).then((result) => {
      if (result.isConfirmed) {
        props
          .callRequest("DELETE", API_GET_CMS + id + "/", true)
          .then((res) => {
            fetchCms();
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "CMS cannot be deleted, It has sections attached to it.",
            });
            console.log(error);
          });
      }
    });
  };

  const columns = [
    {
      name: "Pattern",
      selector: "pattern",
      sortable: true,
    },
    {
      name: "Page Title",
      selector: "page_title",
      sortable: true,
    },
    {
      name: "SEO Title",
      selector: "seo_title",
      sortable: true,
    },
    {
      name: "Meta Tag",
      selector: "meta_tag",
      sortable: true,
    },
    {
      name: "Meta Description",
      selector: "meta_desc",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "actions",
      sortable: false,
      cell: (row) => (
        <UncontrolledDropdown>
          <DropdownToggle>
            <i className="fas fa-ellipsis-v"></i>
          </DropdownToggle>
          <DropdownMenu positionFixed={true}>
            <DropdownItem onClick={() => handleEdit(row.cms_id)}>
              Edit
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                handleDetailsPage(row.cms_id);
              }}
            >
              Section Details
            </DropdownItem>
            <DropdownItem onClick={() => handleDelete(row.cms_id)}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      ),
    },
  ];

  const subHeaderComponentMemo = useMemo(() => {
    return (
      <FormGroup>
        <Input
          id="search"
          type="text"
          placeholder="Search"
          aria-label="Search Input"
          value={filterText}
          autoComplete="off"
          onChange={(e) => setFilterText(e.target.value)}
        />
      </FormGroup>
    );
  }, [filterText]);
  return (
    <main role="main" className="pt-3 px-4">
      <div className="main-heading-block">
        <BreadCrumb links={["Content Management", "CMS"]} />
        <h1 className="header-title">
          <span className="header-icon">
            <i className="ni ni-circle-08"></i>
          </span>
          CMS Details
        </h1>
      </div>
      <div className="listingtable-main-block">
        <Card>
          <CardBody>
            <div className="list-filtered-table-block">
              {loading === true ? <h3>Loading...</h3> : null}
              {loading === false ? (
                <div className="list-filtered-table-block">
                  <DataTable
                    columns={columns}
                    data={DataTableSettings.filterItems(
                      cms,
                      searchParam,
                      filterText
                    )}
                    pagination
                    paginationPerPage={DataTableSettings.paginationPerPage}
                    paginationRowsPerPageOptions={
                      DataTableSettings.paginationRowsPerPageOptions
                    }
                    //actions={actionsExport}
                    //onChangeRowsPerPage={changeRowPerPage}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                  />
                </div>
              ) : null}
            </div>
            <Modal isOpen={modal} toggle={toggle} className={className}>
              <ModalHeader toggle={toggle}>Edit CMS</ModalHeader>
              <ModalBody>
                <CmsForm
                  {...props}
                  cms_id={cmsId}
                  fetchCms={fetchCms}
                  modalToggle={toggle}
                />
              </ModalBody>
            </Modal>
          </CardBody>
        </Card>
      </div>
    </main>
  );
};

export default ContentManagement;
