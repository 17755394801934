import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import ReactHtmlParser from "react-html-parser";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  CardHeader,
} from "reactstrap";
import { BreadCrumb } from "../../config/Components";
import { Editor } from "@tinymce/tinymce-react";

import {
  API_GET_SECTION,
  API_GET_CMS,
  API_GET_CMS_PATTERN,
  API_GET_SECTION_BY_CMS,
} from "../../config/Api";
import { withRouter } from "react-router-dom";

const AddSection = (props) => {
  const initUserInfo = {
    section_id: "",
    cms: 0,
    section_name: "",
    content: "",
    ordering: 0,
  };
  // console.log(props.match.params.id, "prps");
  const [section, setSection] = useState(initUserInfo);
  const [pattern, setPattern] = useState([]);
  const [errors, setErrors] = useState({});
  const editorRef = useRef(null);

  useEffect(() => {
    fetchCms();
    if (
      typeof props.match.params.id !== "undefined" &&
      props.match.params.id > 0
    ) {
      fetchSectionById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const fetchCms = () => {
    props.callRequest("GET", API_GET_CMS, true).then((res) => {
      setPattern(res.data);
    });
  };
  const fetchSectionById = () => {
    props
      .callRequest("GET", API_GET_SECTION + props.match.params.id, true)
      .then((res) => {
        setSection(res.data);
      });
  };

  const validateForm = () => {
    let formIsValid = true;
    let errors = {};
    if (section.section_name == "") {
      formIsValid = false;
      errors["err_section_name"] = "Please provide section name";
    }
    if (section.content == "") {
      formIsValid = false;
      errors["err_content"] = "Please enter some content";
    }
    // if (section.ordering == "" && section.ordering !== 0) {
    //   formIsValid = false;
    //   errors["err_ordering"] = "Please provide section ordering";
    // }

    if (section.cms == 0) {
      formIsValid = false;
      errors["err_pattern"] = "Please choose a cms pattern";
    }
    setErrors(errors);
    return formIsValid;
  };
  // const fetchCmsPattern = () => {
  //   props.callRequest("GET", API_GET_CMS_PATTERN, true).then((res) => {
  //     setPattern(res.data);
  //   });
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setSection((prevState) => {
      return { ...prevState, [name]: value };
    });
  };
  // const handleContentChange = (e, editor) => {
  //   const data = editor.getData();
  //   setSection((prevState) => {
  //     return { ...prevState, content: data };
  //   });
  // };
  const handleContentChange = () => {
    if (editorRef.current) {
      let data = editorRef.current.getContent();
      setSection((prevState) => {
        return { ...prevState, content: data };
      });
    }
  };

  // console.log(section.content, "section");

  const handleSubmit = (event) => {
    if (!validateForm()) {
      return false;
    }
    event.preventDefault();
    let method = "POST";
    let url = API_GET_SECTION;
    const post_data = section;
    // post_data.content = ReactHtmlParser(post_data.content);
    // console.log(post_data, "post_data");
    if (props.match.params.id > 0) {
      method = "PUT";
      url = API_GET_SECTION + props.match.params.id + "/";
    }
    props
      .callRequest(method, url, true, post_data)
      .then((res) => {
        if (res.status && method == "POST") {
          Swal.fire({
            icon: "success",
            title: "Section created successfully",
            showConfirmButton: false,
            timer: 1000,
          });
          props.history.push("/sections/:id");
        } else if (res.status && method == "PUT") {
          Swal.fire({
            icon: "success",
            title: "Section updated successfully",
            showConfirmButton: false,
            timer: 1000,
          });
          props.history.push("/sections/:id");
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Something went wrong!!",
        });
      });
  };

  const handleClose = () => {
    props.history.goBack();
  };

  // const uploadImage = () => {
  //   var editor = tinymce.activeEditor;
  //   // create input element, call modal dialog w
  //   var fileInput = document.createElement("input");
  //   fileInput.setAttribute("type", "file");
  //   fileInput.setAttribute(
  //     "accept",
  //     "image/png, image/gif, image/jpeg, image/bmp, image/x-icon"
  //   );
  //   // if file is submitted run our key code
  //   fileInput.addEventListener("change", () => {
  //     if (fileInput.files != null && fileInput.files[0] != null) {
  //       // create instance of FileReader()
  //       let reader = new FileReader();
  //       // create event triggered after successful reading operation
  //       reader.onload = (e) => {
  //         // insert content in TinyMCE
  //         editor.insertContent('<img src="' + e.target.result + '">');
  //         fileInput.value = "";
  //       };
  //       reader.readAsDataURL(fileInput.files[0]);
  //     }
  //   });
  //   fileInput.click();
  // };
  // tinymce.init({
  //   selector: `#${this.props.id}`,

  //   toolbar: "... uploadimage ...",
  //   paste_data_images: true,
  //   setup: editor.addButton("uploadimage", {
  //     text: "",
  //     icon: "image",
  //     onclick: this.uploadImage,
  //   }),
  // });
  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          {props.match.params.id > 0 ? (
            <BreadCrumb links={["Section List", "Edit Section"]} />
          ) : (
            <BreadCrumb links={["Section List", "Create Section"]} />
          )}
          {props.match.params.id > 0 ? (
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-circle-08"></i>
              </span>
              Edit Section
            </h1>
          ) : (
            <h1 className="header-title">
              <span className="header-icon">
                <i className="ni ni-circle-08"></i>
              </span>
              Create Section
            </h1>
          )}
        </div>
        <div>
          <Card>
            <CardHeader>Section Details</CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="cms">Choose CMS pattern:</Label>
                      <Input
                        type="select"
                        name="cms"
                        id="cms"
                        value={section.cms}
                        onChange={handleChange}
                      >
                        <option value="">Select pattern</option>
                        {pattern.map((pttrn) => {
                          return (
                            <option key={pttrn.cms_id} value={pttrn.cms_id}>
                              {pttrn.pattern}
                            </option>
                          );
                        })}
                      </Input>
                      <label className="red_err">{errors.err_pattern}</label>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label for="section_name">Section Name:</Label>
                      <Input
                        type="text"
                        name="section_name"
                        id="section_name"
                        placeholder="Section name"
                        value={section.section_name}
                        onChange={handleChange}
                      />
                      <label className="red_err">
                        {errors.err_section_name}
                      </label>
                    </FormGroup>
                  </Col>
                  {/* <Col>
                    <FormGroup>
                      <Label for="ordering">Section Order:</Label>
                      <Input
                        type="number"
                        name="ordering"
                        id="ordering"
                        placeholder="Enter section order"
                        value={section.ordering}
                        onChange={handleChange}
                      />
                      <label className="red_err">{errors.err_ordering}</label>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="content">Enter Content:</Label>
                      <Editor
                        //apiKey={process.env.TINY_MCE_API_KEY}
                        apiKey="r9wt0brrkl9gdm41phrz6zbl5q0nn8dbmkpg06xsmc40tisi"
                        onInit={(evt, editor) => (editorRef.current = editor)}
                        initialValue={section.content}
                        onEditorChange={handleContentChange}
                        init={{
                          height: 250,
                          menubar: false,
                          selector: "textarea",
                          images_upload_handler: function (
                            blobInfo,
                            success,
                            failure
                          ) {
                            var reader = new FileReader();
                            reader.readAsDataURL(blobInfo.blob());
                            reader.onload = function () {
                              success(this.result);
                            };
                          },
                          plugins: [
                            "advlist autolink lists link charmap print preview anchor",
                            "searchreplace code insertdatetime media paste",
                            "directionality visualblocks visualchars fullscreen image imagetools template",
                            "codesample table hr pagebreak nonbreaking wordcount",
                            "textpattern help emoticons autosave fontawesome ",
                          ],
                          toolbar:
                            "code codesample | undo redo | formatselect fontselect fontsizeselect | " +
                            "bold italic backcolor forecolor underline strikethrough link image anchor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent |" +
                            "removeformat | restoredraft | cut copy paste pastetext |" +
                            "blockquote subscript superscript | " +
                            "table media charmap emoticons hr pagebreak insertdatetime print preview | fullscreen | fontawesome | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",

                          // formats: {
                          //   // Changes the default format for the underline button to produce a span with a class and not merge that underline into parent spans
                          //   underline: {
                          //     inline: "span",
                          //     styles: { "text-decoration": "underline" },
                          //     exact: true,
                          //   },
                          //   strikethrough: {
                          //     inline: "span",
                          //     styles: { "text-decoration": "line-through" },
                          //     exact: true,
                          //   },
                          // },
                        }}
                      />
                      {/* <Editor
                        //apiKey="Get your free API key at tiny.cloud and paste it here"
                        plugins="wordcount"
                        
                      /> */}
                      <label className="red_err">{errors.err_content}</label>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="m-auto">
                    <Button onClick={handleClose}>Back</Button>
                    <Button onClick={handleSubmit}>Save Changes</Button>
                  </div>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </div>
      </main>
    </>
  );
};

export default withRouter(AddSection);
