import React, { useEffect, useState } from "react";
import "./assets/css/TemplateDesign.css";
import {
  BsPinMapFill,
} from 'react-icons/bs';

const LeftNonEditableAccupixFields = (props) => {
  const [count, setCount] = useState(
    props.nonEditableAccupix.length + props.nonEditableAccupixStreetview.length
  );

  useEffect(() => {
    setCount(
      props.nonEditableAccupix.length +
      props.nonEditableAccupixStreetview.length
    );
  }, [props.nonEditableAccupix, props.nonEditableAccupixStreetview]);

  const onTrigger = (event) => {
    if (props.accupixImageFlag == "satellite") {
      props.parentNonEditableAccupixCallback();
    }
    if (props.accupixImageFlag == "streetview") {
      props.ParentAddNonEditableAccupixStreeview();
    }
  };

  return (
    <div className="template-fill-menu-item">
      <button
        className="menu-item-btn"
        value="Add Accupix"
        hidden={count >= 2 ? true : false}
        onClick={onTrigger}
      >
        <div className='menu-item'>
          <span className='icon'><BsPinMapFill /></span>
          <span className='name'>Add Accupix</span>
        </div>
      </button>
    </div>
  );
};

export default LeftNonEditableAccupixFields;
