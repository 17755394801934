import React, { useState, useEffect } from "react";
import { Button, Form, FormGroup, Label, Input } from "reactstrap";
import { API_ATTRIBUTE_TYPE, API_ATTRIBUTES } from "../../config/Api";

const AddMailingList = (props) => {
  const [attrtypes, setAttrTypes] = useState([]);
  const [attrs, setAttrs] = useState([]);
  const [attribute, setAttribute] = useState(0);
  const [mailingList, setMailingList] = useState(props.addMailListData);

  useEffect(() => {
    fetchAttributeTypes();
    fetchAttributes();
  }, []);

  useEffect(() => {
    let filteredAttr = attrtypes
      .filter((rs) => rs.type_name == "Mailing List")
      .map((type) => {
        return type.type_id;
      });
    setAttribute(filteredAttr);
  }, [attrtypes]);
  useEffect(() => {
    if (mailingList.mailinglist_attr_id !== "") {
      fetchAttrName(mailingList.mailinglist_attr_id);
    }
  }, [mailingList.mailinglist_attr_id]);

  const fetchAttributeTypes = () => {
    props.callRequest("GET", API_ATTRIBUTE_TYPE, true).then((res) => {
      setAttrTypes(res.data);
    });
  };
  const fetchAttrName = (id) => {
    let attr = attrs && attrs.filter((rs) => rs.attribute_id == id)[0];
    setMailingList({
      ...mailingList,
      mailing_list_type:
        attr && "attribute_name" in attr && attr.attribute_name
          ? attr.attribute_name
          : "",
      price_amount: attr && "price" in attr && attr.price ? attr.price : "",
    });
    return attr;
  };
  const fetchAttributes = () => {
    props.callRequest("GET", API_ATTRIBUTES, true).then((res) => {
      setAttrs(res.data);
    });
  };

  const onChangeAction = (event, eleName) => {
    let mailingListDetail = {
      ...mailingList,
      [eleName]: event.target.value,
    };
    setMailingList({ ...mailingListDetail });
  };
  const addMailingList = () => {
    let tempVal = attrs.filter(
      (rs) => rs.attribute_id == mailingList.mailinglist_attr_id
    )[0];
    let temState = { ...mailingList };
    if (tempVal) {
      temState.price_amount = tempVal.price;
    }

    props.addMailingList(temState);
  };

  useEffect(() => {
    console.log(attribute, "attributeattribute")
    if (attribute && attribute.length > 0 && attrs) {
      let temp = attrs.filter((rs) => rs.attributetype_id == attribute[0]);
      let attribute_id = temp && temp.length > 0 ? temp[0].attribute_id : "";

      attribute_id != "" &&
        setMailingList({
          ...mailingList,
          mailinglist_attr_id: attribute_id,
        });
    }
  }, [mailingList.desired_quantity]);

  return (
    <div className="add-mailing-list-box">
      <FormGroup>
        <Label for="desired-quantity">Desired Quantity</Label>
        <Input
          type="number"
          name="desired_quantity"
          id="desired_quantity"
          value={mailingList.desired_quantity}
          placeholder="Enter Desired Quantity"
          onChange={(e) => onChangeAction(e, "desired_quantity")}
        />
      </FormGroup>
      {/* <FormGroup>
        <Label for="mailingListType">Mailing List Type:</Label>
        <Input
          type="select"
          name="mailinglist_attr_id"
          id="mailinglist_attr_id"
          value={mailingList.mailinglist_attr_id}
          onChange={(e) => onChangeAction(e, "mailinglist_attr_id")}
        >
          <option value="">Mailing List Type</option>
          {attrs
            .filter((rs) => rs.attributetype_id === attribute[0])
            .map((attr, index) => {
              return (
                <option value={attr.attribute_id} key={index}>
                  {attr.attribute_name}
                </option>
              );
            })}
        </Input>
      </FormGroup> */}
      <div className="modal-button-block">
        <Button
          color="secondary"
          onClick={() => addMailingList()}
          disabled={
            !mailingList ||
            !"desired_quantity" in mailingList ||
            mailingList.desired_quantity < 1
              ? true
              : false
          }
        >
          Add Mailing List
        </Button>
      </div>
    </div>
  );
};

export default AddMailingList;
