import React, { useState, useEffect } from "react";
import { BreadCrumb } from "../../config/Components";
import classnames from "classnames";
import "../../assets/css/importTemplate.css";
import "./selectSearch.css";

import {
  PropertyInformation,
  PrimaryContact,
  SecondaryContact,
} from "../../config/Components";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Row,
  Col,
  CardTitle,
  CardSubtitle,
  Form,
  FormGroup,
  Label,
  Input,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
} from "reactstrap";
import Swal from "sweetalert2";

import {
  API_USERS,
  API_POST_CAMPAIGN,
  API_GET_USER_PERMISSION,
  API_UPLOAD_CAMPAIGN_CSV,
  API_GET_SALES_REP,
} from "../../config/Api";
import CrmContacts from "./../CRM/CrmContacts";
import campaignData from "./campaignData";
import UserFrom from "../Users/UserForm";
import SelectSearch, { fuzzySearch } from "react-select-search";
import SelectUserForm from "../Subscriptions/SelectUserForm";

const CreateCampaign = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [createcampaignModal, setcreatecampaignModal] = useState(false);
  const [createcampaignModal1, setcreatecampaignModal1] = useState(false);
  const [createcampaignModal2, setcreatecampaignModal2] = useState(false);
  const [value, setValue] = useState("");

  const [userList, setUserList] = useState();
  const [campaignDetail, setCampaignDetail] = useState({
    uplodedFile: null,
    campaign_name: null,
    campaign_target: null,
    campaign_type: null,
    Probate: 2,
    selectedUser: null,
    user: null,
  });
  const [selectedUserDetail, setSelectedUserDetail] = useState();
  const [savedCampaignData, setSavedCampaignData] = useState();
  const [createUserModal, setCreateUserModal] = useState(false);
  const [permissions, setPermissions] = useState();
  const [campaignIdForCSVPopUp, setCampaignIdForCSVPopUp] = useState(null);
  const [salesRep, setSalesRep] = useState([]);
  useEffect(() => {
    validateCsvUploadEditCampaign();
    getUserList();
    fetchUserRolePermission();
  }, []);

  const validateCsvUploadEditCampaign = () => {
    let params = new URLSearchParams(props.location.search.substring(1));
    let campaign_id = params.get("campaign_id");
    if (campaign_id) {
      setCampaignIdForCSVPopUp(campaign_id);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const handleNext = () => {
    //props.history.push('/admin/crm-contacts');
    props.history.push("/crm-contacts");
  };

  const popupCreateCampaign = () =>
    setcreatecampaignModal(!createcampaignModal);
  const popupCreateCampaign1 = () =>
    setcreatecampaignModal1(!createcampaignModal1);
  const popupCreateCampaign2 = () =>
    setcreatecampaignModal2(!createcampaignModal2);

  const oppupCreateUser = () => setCreateUserModal(!createUserModal);

  const handleNext1 = () => {
    popupCreateCampaign();
    popupCreateCampaign1();
  };

  const handleNext2 = () => {
    popupCreateCampaign1();
    popupCreateCampaign2();
  };

  const upload_csv = (event) => {
    event.preventDefault();

    let fileTypeArr = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];
    let type = event.target.files[0]["type"];

    if (fileTypeArr.indexOf(type) < 0) {
      Swal.fire(
        "Validation!",
        "Please select a valid csv or excel file",
        "error"
      );
      return false;
    }

    let campDetail = { ...campaignDetail };
    campDetail.uplodedFile = event.target.files[0];
    setCampaignDetail({ ...campDetail });
  };

  const onChangeAction = (event) => {
    let campDetail = {
      ...campaignDetail,
      [event.target.name]: event.target.value,
    };
    setCampaignDetail({ ...campDetail });
  };

  const createCampaign = () => {
    if (validateForm() != true) return false;

    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });

    const formData = new FormData();
    campaignDetail.uplodedFile &&
      campaignDetail.uplodedFile.name &&
      formData.append(
        "uplodedFile",
        campaignDetail.uplodedFile,
        campaignDetail.uplodedFile.name
      );
    formData.append("campaign_name", campaignDetail.campaign_name);
    formData.append("campaign_target", campaignDetail.campaign_target);
    formData.append("campaign_type", campaignDetail.campaign_type);
    formData.append("Probate", campaignDetail.Probate);
    formData.append("user", campaignDetail.user);

    props.callRequest("POST", API_POST_CAMPAIGN, true, formData).then((res) => {
      Swal.close();
      if (res.data && "csv_file" in res.data && res.data.csv_file) {
        setSavedCampaignData(res.data); //with CSV
      } else {
        Swal.fire({
          position: "top-end",
          toast: true,
          timerProgressBar: true,
          title: "Campaign has been created successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        props.history.push("/campaigns");
        // props.history.push("/admin/campaigns");
      }
    });
  };

  const validateForm = () => {
    let returnVal = true;
    let formEle = {
      campaign_name: "Campaign name is required",
      campaign_target: "Campaign target is required",
      campaign_type: "Campaign type is required",
      //uplodedFile:"Please Select a valid CSV file",
      user: "Please select a valid user",
    };

    for (var it in formEle) {
      if (campaignDetail[it] == null) {
        Swal.fire("Validation!", formEle[it], "error");
        returnVal = false;
        break;
      }
    }

    return returnVal;
  };

  const getUserList = () => {
    props.callRequest("GET", API_USERS, true, null).then((res) => {
      let tempData = [];
      if (res.data.length > 0) {
        res.data.map((item) => {
          tempData.push({ ...item, name: item.username, value: item.user_id });
        });
      }
      setUserList(tempData);
      if (selectedUserDetail && selectedUserDetail.user_id) {
        let userDetail = tempData.filter(
          (ele) => ele.user_id == selectedUserDetail.user_id
        )[0];
        setSelectedUserDetail({ ...userDetail });
      }
    });
  };

  const confirmUser = (e) => {
    if (value && userList) {
      let userDetail = userList.filter((ele) => ele.value == value)[0];
      setSelectedUserDetail({ ...userDetail });
      setCampaignDetail({ ...campaignDetail, user: value });
    }
  };

  const changeSelectedUser = () => {
    setSelectedUserDetail(null);
    setCampaignDetail({ ...campaignDetail, user: null, selectedUser: null });
    setValue();
  };

  const resetSavedCampaign = () => {
    setSavedCampaignData(null);
    setSelectedUserDetail(null);
    setCampaignDetail({
      uplodedFile: null,
      campaign_name: null,
      campaign_target: null,
      campaign_type: null,
      Probate: 2,
      selectedUser: null,
      user: null,
    });
    setcreatecampaignModal(false);
    setcreatecampaignModal1(false);
    setcreatecampaignModal2(false);
    setValue();
  };

  const refreshList = () => {
    getUserList();
    oppupCreateUser();
  };

  const fetchUserRolePermission = () => {
    props
      .callRequest(
        "GET",
        API_GET_USER_PERMISSION + props.getUserInfo().user_id,
        true
      )
      .then((res) => {
        setPermissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editUserPop = () => {
    oppupCreateUser();
  };

  const receiveUserId = (selectUsr) => {
    setSelectedUserDetail({ ...selectUsr });
    setCampaignDetail({ ...campaignDetail, user: selectUsr.user_id });
  };

  const mapNewCSVWithCampaign = (e) => {
    e.preventDefault();

    Swal.fire({
      title: "Request is processing...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      },
    });
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("campaign_id", campaignIdForCSVPopUp);
    formData.append("uplodedFile", file);

    props
      .callRequest("POST", API_UPLOAD_CAMPAIGN_CSV, true, formData)
      .then((res) => {
        Swal.close();
        setSavedCampaignData(res.data);
        setCampaignIdForCSVPopUp(null);
      })
      .catch((e) => {
        Swal.close();
        Swal.fire(
          "Error",
          "Something went wrong, Please try again later",
          "error"
        );
      });
  };

  const cancelCSVUpload = () => {
    setCampaignIdForCSVPopUp(null);
    props.history.push("/campaigns");
    // props.history.push("/admin/campaigns");
  };

  useEffect(() => {
    fetchSalesRep();
  }, []);

  const fetchSalesRep = () => {
    props.callRequest("GET", API_GET_SALES_REP, true).then((res) => {
      setSalesRep(res.data);
    });
  };

  const fetchSalesRepName = (id) => {
    const salesRepName = salesRep.filter((item) => {
      return item.user_id == id;
    });
    if (salesRepName.length > 0) {
      return salesRepName[0].name;
    }
  };

  return (
    <>
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <BreadCrumb links={["Order History"]} />

          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-money-coins"></i>
            </span>
            Create Campaign
          </h1>
        </div>
        <div className="create-order-main-block">
          {savedCampaignData && (
            <CrmContacts
              {...props}
              csvData={savedCampaignData}
              resetSavedCampaign={resetSavedCampaign}
            />
          )}

          {!savedCampaignData && campaignIdForCSVPopUp == null && (
            <Row>
              <Col sm="8">
                <Form>
                  <div className="create-order-block">
                    <Card>
                      <CardBody>
                        <div className="form-box">
                          <div className="header-title-block">
                            <CardTitle tag="h5">Create New Campaign</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">
                              Name your Campaign, then choose its target and
                              type
                            </CardSubtitle>
                          </div>

                          <FormGroup>
                            <Label for="campaign_name">
                              Campaign Name:{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="text"
                              name="campaign_name"
                              id="campaign_name"
                              autoComplete="off"
                              value={campaignDetail.campaign_name}
                              placeholder="Enter Campaign Name"
                              onChange={(e) => onChangeAction(e)}
                            />
                          </FormGroup>

                          <Row form>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="campaign_target">
                                  Campaign Target:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="select"
                                  name="campaign_target"
                                  id="campaign_target"
                                  onChange={(e) => onChangeAction(e)}
                                >
                                  <option value="">
                                    Select Campaign Target
                                  </option>
                                  <option value="0">Seller</option>
                                  <option value="1">Buyer</option>
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup>
                                <Label for="campaign_type">
                                  Campaign Type:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  type="select"
                                  name="campaign_type"
                                  id="campaign_type"
                                  onChange={(e) => onChangeAction(e)}
                                >
                                  <option value="">Select Campaign Type</option>
                                  <option value="0">Direct Mail</option>
                                  <option value="1">Probate</option>
                                  <option value="2">Micro-Campaigns</option>
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>

                        <div className="form-box">
                          <div className="header-title-block">
                            <CardTitle tag="h5">Upload Mailing List</CardTitle>
                            <CardSubtitle tag="h6" className="mb-2 text-muted">
                              Choose from the options bellow to provide a
                              mailing list.
                            </CardSubtitle>
                          </div>

                          <div className="form-item upload-map-block">
                            <h4>
                              Upload and Map{" "}
                              <span
                                class="d-inline-block"
                                tabindex="0"
                                data-toggle="tooltip"
                                title="Description of the hovericon to explain something specific that is not currently common knowledge."
                              >
                                tooltip
                              </span>
                              <span className="text-danger">*</span>
                            </h4>
                            <p>
                              Click the button to upload a mailing list(CSV
                              format only)
                            </p>
                            <div className="upload-button-block">
                              <div className="form-group upload-block">
                                <div className="upload-button">
                                  <button className="btn btn-danger">
                                    Upload{" "}
                                    <i className="ni ni-cloud-upload-96"></i>
                                  </button>

                                  <input
                                    type="file"
                                    id="uplodedFile"
                                    name="uplodedFile"
                                    accept=".csv,.xls,.xlsx"
                                    className="btn custom-file-input"
                                    onChange={(e) => upload_csv(e)}
                                  ></input>
                                  <span>
                                    {campaignDetail.uplodedFile &&
                                      campaignDetail.uplodedFile.name}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="button-block">
                          <Button
                            type="button"
                            onClick={(e) => createCampaign(e)}
                            color="primary"
                            className="float-right"
                          >
                            Create New Campaign
                          </Button>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </Form>
              </Col>
              <Col sm="4">
                <div className="right-panel">
                  <div className="select-user-box">
                    {!selectedUserDetail && (
                      <Card>
                        <CardHeader>Search User </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <SelectUserForm
                              {...props}
                              receiveUserId={receiveUserId}
                              permissions={permissions}
                            />
                            {/* <Label for="selectedUser">Search<span className="text-danger">*</span></Label>
                                                    <SelectSearch search options={userList} value={value} onChange={(de)=>{console.log(de)}}  name="user" placeholder="Select User" />

                                                    <div className="modal-button-block confirm-button">
                                                        <Button type="button" onClick={(e)=>confirmUser(e)} disabled={value ? false:true} color="danger">Confirm</Button>
                                                    </div>

                                                    {permissions && permissions.filter(item=>item.role_name=="User_Create").length>0 &&<><div className="or-block">
                                                        <span>OR</span>
                                                    </div>
                                                    <div className="modal-button-block">
                                                        <Button type="button" color="warning" onClick={oppupCreateUser}>
                                                            Create New User <i className="ni ni-bold-right"></i>
                                                        </Button>
                                                    </div></>} */}
                          </FormGroup>
                        </CardBody>
                      </Card>
                    )}

                    {selectedUserDetail && (
                      <Card>
                        <CardHeader>
                          User
                          <UncontrolledDropdown>
                            <DropdownToggle>
                              <i className="fas fa-ellipsis-v"></i>
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => changeSelectedUser()}
                              >
                                Change User
                              </DropdownItem>
                              <DropdownItem onClick={() => editUserPop()}>
                                Edit User Profile
                              </DropdownItem>
                              {/* <DropdownItem onClick={() => {}}>
                                Commissions Override
                              </DropdownItem> */}
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </CardHeader>
                        <CardBody>
                          <FormGroup>
                            <div className="details-text-block">
                              <p>
                                <strong>Name:</strong>{" "}
                                {selectedUserDetail &&
                                  selectedUserDetail.username}
                              </p>
                              <p>
                                <strong>Email:</strong>{" "}
                                {selectedUserDetail && selectedUserDetail.email}
                              </p>
                              <p>
                                <strong>Phone:</strong>{" "}
                                {selectedUserDetail && selectedUserDetail.phone}
                              </p>
                              <p>
                                <strong>Direct Marketing Coach 1:</strong>{" "}
                                {selectedUserDetail &&
                                  fetchSalesRepName(
                                    selectedUserDetail.sales_rep_1
                                  )}
                              </p>
                              <p>
                                <strong>Direct Marketing Coach 2:</strong>{" "}
                                {selectedUserDetail &&
                                  fetchSalesRepName(
                                    selectedUserDetail.sales_rep_2
                                  )}
                              </p>
                              <p>
                                <strong>CSR:</strong>{" "}
                                {selectedUserDetail && selectedUserDetail.csr}
                              </p>
                              {/* <p><strong>Affiliate:</strong>&nbsp;{selectedUserDetail && selectedUserDetail.username}</p> */}
                            </div>
                          </FormGroup>
                        </CardBody>
                      </Card>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}

          {/* CSV POP UP */}
          <Modal
            isOpen={campaignIdForCSVPopUp == null ? false : true}
            size="sm"
          >
            <ModalHeader>
              <Label for="listSearch">CSV Upload</Label>
            </ModalHeader>
            <ModalBody>
              <div className="form-box">
                <div className="form-item upload-map-block">
                  <h4>
                    Upload and Map{" "}
                    <span
                      class="d-inline-block"
                      tabindex="0"
                      data-toggle="tooltip"
                      title="Description of the hovericon to explain something specific that is not currently common knowledge."
                    >
                      tooltip
                    </span>
                    <span className="text-danger">*</span>
                  </h4>
                  <p>
                    Click the button to upload a mailing list(CSV format only)
                  </p>
                  <div
                    className="upload-button-block"
                    style={{ marginTop: "5px" }}
                  >
                    <div className="form-group upload-block">
                      <div className="upload-button">
                        <button className="btn btn-danger">
                          Upload <i className="ni ni-cloud-upload-96"></i>
                        </button>

                        <input
                          type="file"
                          id="uplodedFile"
                          name="uplodedFile"
                          accept=".csv"
                          className="btn custom-file-input"
                          onChange={(e) => mapNewCSVWithCampaign(e)}
                        ></input>
                        <span>
                          {campaignDetail.uplodedFile &&
                            campaignDetail.uplodedFile.name}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => cancelCSVUpload()}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          {/* End of CSV POp up */}

          {/* Create user */}
          <Modal
            isOpen={createUserModal}
            toggle={oppupCreateUser}
            className="create-campaign-modal"
          >
            <ModalHeader toggle={oppupCreateUser}>
              <div className="search-box">
                <FormGroup>
                  <Label for="listSearch">New User</Label>
                </FormGroup>
              </div>
            </ModalHeader>
            <ModalBody>
              <UserFrom
                {...props}
                isInPopUp={true}
                refreshList={refreshList}
                userId={selectedUserDetail && selectedUserDetail.user_id}
              />
            </ModalBody>
          </Modal>
          {/* End of create user */}
          <Modal
            isOpen={createcampaignModal}
            toggle={popupCreateCampaign}
            className="create-campaign-modal"
          >
            <ModalHeader toggle={popupCreateCampaign}>
              <div className="search-box">
                <FormGroup>
                  <Label for="listSearch">
                    <i class="fas fa-search"></i>
                  </Label>
                  <Input
                    type="search"
                    name="search"
                    id="listSearch"
                    autoComplete="off"
                    placeholder="Search"
                  />
                </FormGroup>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="searchfile-list-block">
                <ul className="searchfile-list">
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                  <li>
                    <Button color="link">
                      <i class="ni ni-folder-17"></i>
                      <h5>File Name</h5>
                    </Button>
                  </li>
                </ul>
              </div>

              <div className="file-open-footer">
                <div className="filename-block">
                  <FormGroup row>
                    <Label for="filename" sm={3}>
                      File Name
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="text"
                        name="email"
                        id="filename"
                        autoComplete="off"
                        placeholder="Enter File Name"
                      />
                    </Col>
                  </FormGroup>
                </div>
                <div className="button-block">
                  <Button color="primary" onClick={handleNext}>
                    Open
                  </Button>{" "}
                  <Button color="secondary">Cancel</Button>{" "}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </main>
    </>
  );
};

export default CreateCampaign;
