import React, { Component } from "react";
import "./Dashboard.css";

import Header from "../Headers/Header";

export default class Dashboard extends Component {
  render() {
    return (
      <main role="main" className="pt-3 px-4">
        <div className="main-heading-block">
          <h1 className="header-title">
            <span className="header-icon">
              <i className="ni ni-tv-2"></i>
            </span>
            Dashboard
          </h1>
        </div>
        <Header />
      </main>
    );
  }
}
