import React, { useState, useEffect } from "react";
import "./Subscriptions.css";
import Swal from "sweetalert2";
import { Button, Form, FormGroup, Input, Modal } from "reactstrap";
import UserFrom from "../Users/UserForm";

import { API_GET_USER, API_SEARCH_USER, API_USERS } from "../../config/Api";
// import DataTable from "react-data-table-component";
// import DataTableSettings from "../../utils/DataTableSettings";

const SelectUserForm = (props) => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedtUser] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [modal, setModal] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);

  // const oppupCreateUser = () => setCreateUserModal(!createUserModal);

  const toggle = () => setModal(!modal);
  useEffect(() => {
    fetchUsers();
    if (filterText === "") {
      setSelectedtUser([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText]);

  // const getUserList = () => {
  //   props.callRequest("GET", API_USERS, true, null).then((res) => {
  //     let tempData = [];
  //     if (res.data.length > 0) {
  //       res.data.map((item) => {
  //         tempData.push({ ...item, name: item.username, value: item.user_id });
  //       });
  //     }
  //     setUserList(tempData);
  //     if (selectedUserDetail && selectedUserDetail.user_id) {
  //       let userDetail = tempData.filter(
  //         (ele) => ele.user_id == selectedUserDetail.user_id
  //       )[0];
  //       setSelectedUserDetail({ ...userDetail });
  //     }
  //   });
  // };

  // const getUserList = () => {
  //   props.callRequest("GET", API_USERS, true, null).then((res) => {
  // }
  const refreshList = () => {
    fetchUsers();
    toggle();
  };

  const fetchUsers = () => {
    props
      .callRequest("GET", API_SEARCH_USER, true)
      .then((res) => {
        setUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const fetchUserById = (id) => {
    props.callRequest("GET", API_GET_USER + id, true).then((res) => {
      setSelectedtUser(res.data);
      setFilterText(res.data.username + " - " + res.data.email);
    });
  };
  let selectUser = (id) => {
    fetchUserById(id);
  };

  const receiveUserId = (selectedUser) => {
    props.receiveUserId(selectedUser);
  };
  const sendEdittedUser = (user) => {
    fetchUsers();
  };
  //console.log(selectedUser);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (selectedUser.length !== 0) {
      receiveUserId(selectedUser);
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "customer selected",
      });
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "error",
        title: "Something Went Wrong!",
      });
    }
  };
  return (
    <>
      <div className="popup-form-block">
        <Form>
          <FormGroup>
            <div className="box">
              <input
                required
                id="search"
                type="search"
                placeholder="Email/Name/Phone"
                aria-label="Search User"
                name="filterText"
                value={filterText}
                autoComplete="off"
                onChange={(e) => setFilterText(e.target.value)}
                style={{ width: "90%" }}
              />
              <span>
                <i className="fas fa-search float-right"></i>
              </span>
            </div>

            {filterText === "" ? (
              ""
            ) : selectedUser.length !== 0 ? (
              <div className="modal-button-block confirm-button">
                <Button color="danger" onClick={handleSubmit}>
                  Confirm
                </Button>
              </div>
            ) : (
              <div className="result-box">
                {users
                  .filter((usr) => {
                    if (
                      usr.username
                        .toLowerCase()
                        .includes(filterText.toLowerCase()) |
                      usr.email
                        .toLowerCase()
                        .includes(filterText.toLowerCase()) |
                      usr.phone.toLowerCase().includes(filterText.toLowerCase())
                    ) {
                      return usr;
                    } else return "";
                  })
                  .map((val, index) => {
                    return (
                      <div key={index}>
                        <p
                          className="select_user"
                          onClick={selectUser.bind(this, val.user_id)}
                        >
                          {val.username} - {val.email}
                        </p>
                      </div>
                    );
                  })}
              </div>
            )}
          </FormGroup>
          {/* {selectedUser.length !== 0 ? (
            <div className="modal-button-block confirm-button">
              <Button color="danger" onClick={handleSubmit}>
                Confirm
              </Button>
            </div>
          ) : (
            ""
          )} */}
          {props.permissions !== undefined &&
          props.permissions.filter((per) => {
            return per.role_name === "User";
          })[0] !== undefined ? (
            <div>
              <div className="or-block">
                <span>OR</span>
              </div>
              <div className="submit-button-block">
                <Button className="m-auto" color="warning" onClick={toggle}>
                  Create New User
                </Button>
              </div>
              <Modal
                isOpen={modal}
                toggle={toggle}
                className="create-user-modal"
              >
                <UserFrom
                  {...props}
                  // modalToggle={toggle}
                  sendEdittedUser={sendEdittedUser}
                  isInPopUp={true}
                  refreshList={refreshList}
                />
              </Modal>
            </div>
          ) : (
            ""
          )}
        </Form>
      </div>
    </>
  );
};

export default SelectUserForm;
